/* tslint:disable */
/* eslint-disable */
import * as validators from './validators';

export type Event = ContextProperties &
  (
    | AddedCollectionToFavouritesProperties
    | AddedHashtagsToCollectionProperties
    | AddedSuggestedHashtagsProperties
    | AdvSearchedProperties
    | AnsweredOnBoardingQuestionsProperties
    | AppliedFiltersProperties
    | AuditedHashtagsProperties
    | ClearedSelectionProperties
    | ClickedNotificationProperties
    | ClickedOnboardingChecklistItemProperties
    | CreatedCollectionProperties
    | CreatedFlickAccountProperties
    | CreatedSavedFilterProperties
    | DeletedSavedFilterProperties
    | DismissedSuggestedHashtagProperties
    | ExpandedHashtagProperties
    | ExpandedMetricProperties
    | ExportedHashtagsProperties
    | LinkedIGAccountErrorProperties
    | LinkedIGAccountsProperties
    | OpenedSuggestedHashtagsProperties
    | PreviewHashtagProperties
    | RedirectedFromReportsProperties
    | RemovedCollectionFromFavouritesProperties
    | SearchedHashtagProperties
    | SelectedHashtagsProperties
    | SelectedNumAccountsProperties
    | SelectedOnboardingAimsProperties
    | SelectedPlanProperties
    | SelectedTimePeriodProperties
    | SelectedUseCaseProperties
    | ToggledAnalyticsContentTypeProperties
    | ToggledPositionGroupProperties
    | ToggledSelectedHashtagsProperties
    | ToggledSidebarProperties
    | ToggledSuggestFiltersProperties
    | ToggledWeeklyReportsProperties
    | UnlinkedIGAccountProperties
    | UpdatedSavedFilterProperties
    | UsedShortcutProperties
    | ViewedCollectionProperties
    | ViewedRankedHashtagsProperties
    | VisitedAppProperties
    | SwitchedWorkspaceProperties
  );
export interface ContextProperties {
  desktopHashtagsView: string;
  sourceUrl: string;
  device: 'mobile' | 'desktop' | 'app';
  sourcePathname: string;
}
export interface IdentifyProperties {
  /**
   * Whether or not a Flick user has set his password
   */
  hasSetPassword?: boolean;
  utm_source?: string;
  utm_term?: string;
  /**
   * The active addon's a user has
   */
  activeAddons?: string[];
  /**
   * How often a user posts on Instagram outlined during on-boarding.
   */
  PostingFrequency?: string;
  Plan?: string;
  referrerUrl?: string;
  'initial_utm_content '?: string;
  SelectedHashtagsExperiment?: string;
  /**
   * The timestamp at which the user last received a notification
   */
  lastReceivedNotification?: string;
  /**
   * The amount of trial conversions people referred have had
   */
  ReferralTrialConversions?: string;
  utm_campaign?: string;
  initial_utm_source?: string;
  keyAction?: 'export' | 'add_to_collection';
  lastName?: string;
  /**
   * The user id
   */
  id?: string;
  /**
   * Number of accounts the user indicated that they manage during onboarding
   */
  OnboardingNumAccounts?: string;
  firstName?: string;
  /**
   * The ID of the affiliate they signed up with
   */
  affiliateId?: string;
  LinkedIGAccounts?: string[];
  LTV?: string;
  userId?: string;
  litePlanExperiment?: 'control' | 'experiment';
  /**
   * The user's email
   */
  email?: string;
  initial_utm_medium?: string;
  /**
   * If the user is subscribed to browser notifications
   */
  isSubscribedToBrowserNotifications?: boolean;
  /**
   * The date a user's trial will end
   */
  trialEndDate?: string;
  utm_medium?: string;
  /**
   * The features the user has access to
   */
  features?: string[];
  /**
   * The user's full name
   */
  FullName?: string;
  /**
   * When a user was first seen
   */
  FirstSeen?: string;
  /**
   * The number of IG accounts the user has linked to Flick
   */
  NumLinkedIGAccounts?: number;
  /**
   * Link to the user's profile picture
   */
  ProfilePicture?: string;
  /**
   * Whether the user has access to analytics
   */
  hasAnalytics?: boolean;
  timezone?: string;
  initial_referring_domain?: string;
  pricingExperiment?: 'experiment' | 'control';
  'initial_utm_campaign '?: string;
  /**
   * The amount of hashtags stored by a user across all collections and accounts
   */
  TotalHashtagsStored?: number;
  /**
   * The amount of IG accounts a user has
   */
  NumIGAccounts?: number;
  isOnNewPlan?: boolean;
  /**
   * The number of hashtags stored in a user's smart collections
   */
  numTopPerformingHashtags?: number;
  PaymentMethod?: string;
  referrer?: string;
  plan?: string;
  /**
   * Whether or not the user has linked his Flick account to google
   */
  hasLinkedGoogle?: boolean;
  referring_domain?: string;
  numRecentlyRankedHashtags?: number;
  SubscriptionStatus?: string;
  /**
   * The date a user started his trial
   */
  trialStartDate?: string;
  /**
   * The amount of trials started through a user's referral link
   */
  ReferralTrials?: number;
  isFreemium?: boolean;
  'initial_utm_term '?: string;
  /**
   * The use case selected during onboarding
   */
  UseCase?: string;
  /**
   * The amount of clicks a user has on his referral link
   */
  ReferralClicks?: string;
  /**
   * The amount of days a user has remaining in his trial
   */
  TrialDaysRemaining?: number;
  /**
   * The first name of a user
   */
  name?: string;
  initial_referrer?: string;
  /**
   * Whether the user had been grandfathered from an old plan to new
   */
  wasGrandfathered?: boolean;
  LastSeen?: string;
  /**
   * The aim of what they would like to get out of Flick the user outlined during onboarding
   */
  OnboardingAims?: string[];
}
export interface AddedCollectionToFavouritesProperties {
  /**
   * Collection name
   */
  name: string;
}
export interface AddedHashtagsToCollectionProperties {
  /**
   * The view the action was made of
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search, collections, content, hashtagInsights, collection-suggestions, post-import, past-export, paste-import |
   */
  view:
    | 'search'
    | 'collections'
    | 'content'
    | 'hashtagInsights'
    | 'collection-suggestions'
    | 'post-import'
    | 'past-export'
    | 'paste-import';
}
export interface AddedSuggestedHashtagsProperties {
  /**
   * whether the users mass added all the suggestions or not
   */
  addedAll: boolean;
}
export interface AdvSearchedProperties {
  /**
   * The fields filtered by in the search
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filteredBy: string[];
  /**
   * The keywords excluded
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  excludedKeywords: string[];
  /**
   * True if the suggested filters were activated
   */
  isSuggested: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  limit: number;
  /**
   * The keywords included
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  includedKeywords: string[];
  /**
   * Is as of a result of an automatic refresh due to filter changes
   */
  isRefresh: boolean;
  /**
   * Whether a keyword filter is used
   */
  isKeyword: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  skip: number;
}
export interface AnsweredOnBoardingQuestionsProperties {
  numOfAccounts: string;
  useCase: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aims: string[];
}
export interface AppliedFiltersProperties {
  /**
   * The keywords excluded
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  excludedKeywords: string[];
  /**
   * are the current filters suggested?
   */
  isSuggested: boolean;
  /**
   * The fields filtered by
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  filteredBy: string[];
  /**
   * Includes a keyword filter
   */
  isKeyword: boolean;
  /**
   * The method of application
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | custom, suggestion, saved |
   */
  type: 'custom' | 'suggestion' | 'saved';
  /**
   * The keywords included
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  includedKeywords?: string[];
}
export interface AuditedHashtagsProperties {
  /**
   * A list of hashtags audited
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  hashtags: string[];
  /**
   * Percentage of hashtags which were suitable
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  percentageSuitable: number;
  /**
   * Percentage of hashtags which were banned
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  percentageBanned: number;
  /**
   * Percentage of hashtags which were unsuitable
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  percentageUnsuitable: number;
  /**
   * The number of hashtags audited
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfHashtags: number;
}
export interface ClearedSelectionProperties {
  /**
   * the view this action is made from
   */
  view: string;
}
export interface ClickedNotificationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | browser, mobile, email, web-feed |
   */
  channel: 'browser' | 'mobile' | 'email' | 'web-feed';
  /**
   * The type of the notification (e.g. PostRankedSummary)
   */
  type: string;
}
export interface ClickedOnboardingChecklistItemProperties {
  type: string;
}
export interface CreatedCollectionProperties {
  /**
   * The emoji used
   */
  emoji?: string;
  /**
   * The name of the collection
   */
  name: string;
}
export interface CreatedFlickAccountProperties {
  experimentName?: string;
  /**
   * The auth method of register
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, google, facebook, apple |
   */
  method: 'email' | 'google' | 'facebook' | 'apple';
  device: string;
  variantName?: string;
  source: 'invite' | 'sign-up';
}
export interface CreatedSavedFilterProperties {
  /**
   * The name of the saved filter
   */
  name: string;
}
export interface DeletedSavedFilterProperties {
  /**
   * The name of the saved filter
   */
  name: string;
}
export interface DismissedSuggestedHashtagProperties {}
export interface ExpandedHashtagProperties {
  /**
   * Is as of a result of an automatic refresh due to filter changes
   */
  isRefresh: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  limit: number;
  /**
   * True if the suggested filters were activated
   */
  isSuggested: boolean;
  /**
   * The name of the hashtag
   */
  hashtag: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  skip: number;
}
export interface ExpandedMetricProperties {
  name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | lastPeriod, similarAccounts |
   */
  comparisonType: 'lastPeriod' | 'similarAccounts';
}
export interface ExportedHashtagsProperties {
  /**
   * yes, no
   */
  random: boolean;
  /**
   * The number of hashtags exported
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numHashtags: number;
  /**
   * collection, search, content, hashtagInsights
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search, collections, content, hashtagInsights, dashboard |
   */
  view: 'search' | 'collections' | 'content' | 'hashtagInsights' | 'dashboard';
}
export interface LinkedIGAccountErrorProperties {
  /**
   * Error message
   */
  message: string;
}
export interface LinkedIGAccountsProperties {
  /**
   * Number of accounts linked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numAccounts: number;
  /**
   * A list of comma separated usernames linked
   */
  usernames: string;
}
export interface OpenedSuggestedHashtagsProperties {}
export interface PreviewHashtagProperties {
  /**
   * Hashtag name
   */
  hashtag: string;
}
export interface RedirectedFromReportsProperties {
  destination: string;
}
export interface RemovedCollectionFromFavouritesProperties {
  /**
   * Collection name
   */
  name: string;
}
export interface SearchedHashtagProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  limit: number;
  /**
   * The name of the hashtag
   */
  hashtag: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  skip: number;
  /**
   * Is as of a result of an automatic refresh due to filter changes
   */
  isRefresh: boolean;
  /**
   * Only set when a user makes a "manual" search
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Manual, Recent, Suggested, None |
   */
  queryType?: 'Manual' | 'Recent' | 'Suggested' | 'None';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | basic, expand, advanced |
   */
  type: 'basic' | 'expand' | 'advanced';
  /**
   * True if the suggested filters were activated
   */
  isSuggested: boolean;
}
export interface SelectedHashtagsProperties {
  /**
   * Number of hashtags selected
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numHashtags: number;
  /**
   * Where in the app this was made: Search, Collection, Analytics
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search, collection, analytics, hashtags-insights, content, smart-collection, pre-filled-collection |
   */
  view:
    | 'search'
    | 'collection'
    | 'analytics'
    | 'hashtags-insights'
    | 'content'
    | 'smart-collection'
    | 'pre-filled-collection';
  /**
   * Type of select: "all", "single", "random"
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | all, did not rank, high competition, low competition, medium competition, random, single, ranked hashtags |
   */
  actionType:
    | 'all'
    | 'did not rank'
    | 'high competition'
    | 'low competition'
    | 'medium competition'
    | 'random'
    | 'single'
    | 'ranked hashtags';
}
export interface SelectedNumAccountsProperties {
  /**
   * The number of ig accounts selected (range)
   */
  numAccounts: string;
}
export interface SelectedOnboardingAimsProperties {
  /**
   * The aim selected
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aims: string[];
}
export interface SelectedPlanProperties {
  plan: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  addons: string[];
}
export interface SelectedTimePeriodProperties {
  /**
   * The page the event was triggered on
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | content, hashtags, overview, audience |
   */
  name: 'content' | 'hashtags' | 'overview' | 'audience';
  /**
   * The time period the user selected
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 14_days, 180_days, 30_days, 365_days, 7_days, 90_days, custom |
   */
  timePeriod:
    | '14_days'
    | '180_days'
    | '30_days'
    | '365_days'
    | '7_days'
    | '90_days'
    | 'custom';
}
export interface SelectedUseCaseProperties {
  /**
   * The use case selected
   */
  name: string;
}
export interface ToggledAnalyticsContentTypeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | feed, igtv, stories |
   */
  type: 'feed' | 'igtv' | 'stories';
}
export interface ToggledPositionGroupProperties {
  /**
   * The group that they viewed eg: top 9, top 10-25, top 25-50
   */
  positionGroup: string;
}
export interface ToggledSelectedHashtagsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | open, closed |
   */
  nextState: 'open' | 'closed';
}
export interface ToggledSidebarProperties {
  /**
   * The state that the user toggled to. [opened, closed]
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | opened, closed |
   */
  nextState: 'opened' | 'closed';
}
export interface ToggledSuggestFiltersProperties {
  /**
   * The username of the ig account used
   */
  username: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | active, inactive |
   */
  nextState: 'active' | 'inactive';
}
export interface ToggledWeeklyReportsProperties {
  nextState: boolean;
  username: string;
}
export interface UnlinkedIGAccountProperties {
  /**
   * The username of the account linked
   */
  username: string;
}
export interface UpdatedSavedFilterProperties {
  /**
   * The name of the filter
   */
  name: string;
}
export interface UsedShortcutProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | exported, added to collection, toggled sidebar, cleared selection, audited |
   */
  action:
    | 'exported'
    | 'added to collection'
    | 'toggled sidebar'
    | 'cleared selection'
    | 'audited';
}
export interface ViewedCollectionProperties {
  /**
   * What type of collection is being viewed
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Normal, Smart, Pre-filled |
   */
  type: 'Normal' | 'Smart' | 'Pre-filled';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collections, dashboard, navigation |
   */
  source: 'collections' | 'dashboard' | 'navigation';
  /**
   * Name of the collection
   */
  name: string;
}
export interface ViewedRankedHashtagsProperties {}
export interface VisitedAppProperties {
  device?: string;
  referrer?: string;
  /**
   * The name of the page the user is visiting
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Analytics Audience, Analytics Content, Analytics Hashtags, Analytics Overview, Analytics Reports, Analytics Stories, Collections, Explore, Hashtag Insights, Home, Login, No Card Register, Privacy Policy, Profile, Referrals, Register, Resource, Resources, Viral Posts, Schedule |
   */
  name:
    | 'Analytics Audience'
    | 'Analytics Content'
    | 'Analytics Hashtags'
    | 'Analytics Overview'
    | 'Analytics Reports'
    | 'Analytics Stories'
    | 'Collections'
    | 'Explore'
    | 'Hashtag Insights'
    | 'Home'
    | 'Login'
    | 'No Card Register'
    | 'Privacy Policy'
    | 'Profile'
    | 'Referrals'
    | 'Register'
    | 'Resource'
    | 'Resources'
    | 'Viral Posts'
    | 'Schedule'
    | 'Feed Preview';
  utmCampaign?: string;
  utmMedium?: string;
  referrerUrl?: string;
  utmSource?: string;
  utmTerm?: string;
  utmContent?: string;
}
export interface SwitchedWorkspaceProperties {
  /**
   * The name of the active workspace
   */
  workspace: string;
}

export interface ItlyOptions {
  /**
   * The current environment (development or production). Default is development.
   */
  environment?: 'development' | 'production';
  /**
   * Whether calls to the Itly SDK should be no-ops. Default is false.
   */
  disabled?: boolean;
  /**
   * Analytics provider-specific configuration. Default is null.
   */
  destinations?: { segment?: { config?: {} } };
  /**
   * Additional context properties to add to all events. Set to object or an object resolver. Default is none.
   */
  context: ContextProperties | (() => ContextProperties);
  /**
   * Custom logger to use for debug, info, warn, and error messages. Default is console.
   */
  logger?: {
    debug: (message: string) => void;
    info: (message: string) => void;
    warn: (message: string) => void;
    error: (message: string) => void;
  };
}

export type ItlyProperties = {
  [name: string]: any;
};
export interface ItlyDestination {
  init(): void;
  alias(userId: string, previousId: string | undefined): void;
  identify(
    userId: string | undefined,
    properties: ItlyProperties | undefined,
  ): void;
  group(
    userId: string | undefined,
    groupId: string,
    properties: ItlyProperties | undefined,
  ): void;
  page(
    userId: string | undefined,
    category: string | undefined,
    name: string | undefined,
    properties: ItlyProperties | undefined,
  ): void;
  track(
    userId: string | undefined,
    eventName: string,
    properties: ItlyProperties,
    eventId: string,
    eventVersion: string,
  ): void;
  reset(): void;
}

class SegmentBrowserAdapter implements ItlyDestination {
  private get segment(): any {
    const s: any = typeof self === 'object' && self.self === self && self;
    return s && s.analytics;
  }

  constructor(options: ItlyOptions, apiKey: string) {
    if (!this.segment) {
      // Segment (https://segment.com/docs/sources/website/analytics.js/quickstart/)
      // @ts-ignore
      !(function () {
        var analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize) {
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error('Segment snippet included twice.');
          else {
            analytics.invoked = !0;
            analytics.methods = [
              'trackSubmit',
              'trackClick',
              'trackLink',
              'trackForm',
              'pageview',
              'identify',
              'reset',
              'group',
              'track',
              'ready',
              'alias',
              'debug',
              'page',
              'once',
              'off',
              'on',
            ];
            analytics.factory = function (t) {
              return function () {
                var e = Array.prototype.slice.call(arguments);
                e.unshift(t);
                analytics.push(e);
                return analytics;
              };
            };
            for (var t = 0; t < analytics.methods.length; t++) {
              var e = analytics.methods[t];
              analytics[e] = analytics.factory(e);
            }
            analytics.load = function (t, e) {
              var n = document.createElement('script');
              n.type = 'text/javascript';
              n.async = !0;
              n.src =
                'https://cdn.segment.com/analytics.js/v1/' +
                t +
                '/analytics.min.js';
              var a = document.getElementsByTagName('script')[0];
              a.parentNode.insertBefore(n, a);
              analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = '4.1.0';
          }
        }
      })();
    }
    this.segment.load(
      apiKey,
      options.destinations &&
        options.destinations.segment &&
        options.destinations.segment.config,
    );
  }

  init() {
    // N/A for Segment
  }

  alias(userId: string, previousId: string | undefined) {
    this.segment.alias({ userId, previousId });
  }

  identify(userId: string | undefined, properties: ItlyProperties | undefined) {
    if (userId) {
      this.segment.identify(userId, properties);
    } else {
      this.segment.identify(properties);
    }
  }

  group(
    userId: string | undefined,
    groupId: string,
    properties: ItlyProperties | undefined,
  ) {
    this.segment.group(groupId, properties);
  }

  page(
    userId: string | undefined,
    category: string | undefined,
    name: string | undefined,
    properties: ItlyProperties | undefined,
  ) {
    this.segment.page(category, name, properties);
  }

  track(
    userId: string | undefined,
    eventName: string,
    properties: ItlyProperties,
  ) {
    this.segment.track(eventName, properties);
  }

  reset() {
    this.segment.reset();
  }
}

class Itly {
  private options: ItlyOptions | undefined = undefined;
  private adapters: ItlyDestination[] = [];

  init(options: ItlyOptions) {
    if (this.options) {
      throw new Error('Itly is already initialized.');
    }

    this.options = options;

    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.adapters.push(
      new SegmentBrowserAdapter(
        options,
        this.options.environment === 'production'
          ? 'YwrV81x5pcZe6oOvAy1NIUm02MbDQ6so'
          : '0TeNY5jMfsTHnFlhZfJOmrt3d8IyRnLA',
      ),
    );

    this.adapters.forEach((d) => d.init());
  }

  alias(userId: string, previousId?: string) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.adapters.forEach((adapter) => adapter.alias(userId, previousId));
  }

  /**
   * Identify a user and set or update that user's properties.
   * @param properties Required and optional user properties.
   */
  identify(properties: IdentifyProperties): void;
  /**
   * Identify a user and set or update that user's properties.
   * @param userId The user's ID.
   * @param properties Required and optional user properties.
   */
  identify(userId: string, properties: IdentifyProperties): void;
  identify(
    userIdOrProperties: string | IdentifyProperties,
    maybeProperties?: IdentifyProperties,
  ) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const userId =
      typeof userIdOrProperties === 'string' ? userIdOrProperties : undefined;
    const properties =
      userId === undefined
        ? (userIdOrProperties as IdentifyProperties)
        : maybeProperties;

    this.validateObject('user', validators.IdentifyValidator, properties);

    this.adapters.forEach((adapter) => adapter.identify(userId, properties));
  }

  group(groupId: string) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.adapters.forEach((adapter) =>
      adapter.group(undefined, groupId, undefined),
    );
  }

  page(category: string, name: string) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.adapters.forEach((adapter) =>
      adapter.page(undefined, category, name, undefined),
    );
  }

  /**
   * When a users adds a collection to favourites
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. name)
   */
  trackAddedCollectionToFavourites(
    properties: AddedCollectionToFavouritesProperties,
  ) {
    this.trackEvent(
      undefined,
      'Added Collection To Favourites',
      properties,
      'ee003dff-52b8-478b-830a-0b1003e67e6e',
      '1.0.0',
      validators.AddedCollectionToFavouritesValidator,
    );
  }

  /**
   * When a user adds one or more hashtags to a collection
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. view)
   */
  trackAddedHashtagsToCollection(
    properties: AddedHashtagsToCollectionProperties,
  ) {
    this.trackEvent(
      undefined,
      'Added Hashtags to Collection',
      properties,
      '3837382f-1bb3-4a73-8755-841f51e39c15',
      '5.0.0',
      validators.AddedHashtagsToCollectionValidator,
    );
  }

  /**
   * Triggered when a user adds a suggestion from the suggested hashtag modal to their colleciton
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. addedAll)
   */
  trackAddedSuggestedHashtags(properties: AddedSuggestedHashtagsProperties) {
    this.trackEvent(
      undefined,
      'Added Suggested Hashtags',
      properties,
      '899a8d2e-3d7c-49b3-bccc-3bc22ea49800',
      '2.0.0',
      validators.AddedSuggestedHashtagsValidator,
    );
  }

  /**
   * The user made a a search with filters applied
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. filteredBy)
   */
  trackAdvSearched(properties: AdvSearchedProperties) {
    this.trackEvent(
      undefined,
      'Adv Searched',
      properties,
      '5930ce31-a59d-4fc2-a8e0-81e9e7769fd0',
      '4.0.0',
      validators.AdvSearchedValidator,
    );
  }

  /**
   * Completed when a user Answers the on-boarding questions
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. numOfAccounts)
   */
  trackAnsweredOnBoardingQuestions(
    properties: AnsweredOnBoardingQuestionsProperties,
  ) {
    this.trackEvent(
      undefined,
      'Answered On-boarding Questions',
      properties,
      '3c2e9b55-7628-4220-b1b6-a59f46cbd9d1',
      '3.0.0',
      validators.AnsweredOnBoardingQuestionsValidator,
    );
  }

  /**
   * Called when a user applies search filters
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. excludedKeywords)
   */
  trackAppliedFilters(properties: AppliedFiltersProperties) {
    this.trackEvent(
      undefined,
      'Applied Filters',
      properties,
      '6e662d29-eb95-4685-ad14-1e2987e8f085',
      '3.0.0',
      validators.AppliedFiltersValidator,
    );
  }

  /**
   * When a user checks clicks on the audit button
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. hashtags)
   */
  trackAuditedHashtags(properties: AuditedHashtagsProperties) {
    this.trackEvent(
      undefined,
      'Audited Hashtags',
      properties,
      '150df99a-22c0-4858-9768-2abb5cbb6dd0',
      '3.0.0',
      validators.AuditedHashtagsValidator,
    );
  }

  /**
   * When a user clears their list of selected hashtags
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. view)
   */
  trackClearedSelection(properties: ClearedSelectionProperties) {
    this.trackEvent(
      undefined,
      'Cleared Selection',
      properties,
      '91a01028-9158-4750-b397-dd19e600b087',
      '2.0.0',
      validators.ClearedSelectionValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. channel)
   */
  trackClickedNotification(properties: ClickedNotificationProperties) {
    this.trackEvent(
      undefined,
      'Clicked Notification',
      properties,
      '3916d745-b8b6-4ca6-96de-299a4a6492f4',
      '1.0.0',
      validators.ClickedNotificationValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. type)
   */
  trackClickedOnboardingChecklistItem(
    properties: ClickedOnboardingChecklistItemProperties,
  ) {
    this.trackEvent(
      undefined,
      'Clicked Onboarding Checklist Item',
      properties,
      'ccdea265-d14f-4025-aea8-a84f37f4e304',
      '1.0.0',
      validators.ClickedOnboardingChecklistItemValidator,
    );
  }

  /**
   * When a user creates a collection of hashtags
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. emoji)
   */
  trackCreatedCollection(properties: CreatedCollectionProperties) {
    this.trackEvent(
      undefined,
      'Created Collection',
      properties,
      '5c3cd61e-6b0c-4ba2-bb66-5e30a9d43b1b',
      '1.0.0',
      validators.CreatedCollectionValidator,
    );
  }

  /**
   * Called when a user created their Flick account (email and password
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. experimentName)
   */
  trackCreatedFlickAccount(properties: CreatedFlickAccountProperties) {
    this.trackEvent(
      undefined,
      'Created Flick Account',
      properties,
      '0482f86d-220d-433b-923c-ea5564242bd3',
      '4.0.0',
      validators.CreatedFlickAccountValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. name)
   */
  trackCreatedSavedFilter(properties: CreatedSavedFilterProperties) {
    this.trackEvent(
      undefined,
      'Created Saved Filter',
      properties,
      '48154d2f-2b02-46a5-88f1-05eade121357',
      '1.0.0',
      validators.CreatedSavedFilterValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. name)
   */
  trackDeletedSavedFilter(properties: DeletedSavedFilterProperties) {
    this.trackEvent(
      undefined,
      'Deleted Saved Filter',
      properties,
      '36c3eba2-c46e-41e4-8ae3-472ee7f1d0e8',
      '1.0.0',
      validators.DeletedSavedFilterValidator,
    );
  }

  /**
   * Triggered when a user successfully rejects a suggested hashtag in the the suggest hashtags modal
   *
   * Owner: Loic Alix-Brown
   */
  trackDismissedSuggestedHashtag() {
    this.trackEvent(
      undefined,
      'Dismissed Suggested Hashtag',
      undefined,
      'd31a1925-ece5-4716-bc6b-eb714ecce8cd',
      '1.0.0',
      validators.DismissedSuggestedHashtagValidator,
    );
  }

  /**
   * Called when a user clicks the expand button
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. isRefresh)
   */
  trackExpandedHashtag(properties: ExpandedHashtagProperties) {
    this.trackEvent(
      undefined,
      'Expanded Hashtag',
      properties,
      'dfc19d8f-e3fd-4046-a814-36a1c5a3e7a6',
      '2.0.0',
      validators.ExpandedHashtagValidator,
    );
  }

  /**
   * When a user expands hero metric in analytics
   *
   * Owner: Piotr Monwid-Olechnowicz
   * @param properties The event's properties (e.g. name)
   */
  trackExpandedMetric(properties: ExpandedMetricProperties) {
    this.trackEvent(
      undefined,
      'Expanded Metric',
      properties,
      '3d9cfaa0-e97d-4c47-8ce5-f673d369df0a',
      '1.0.0',
      validators.ExpandedMetricValidator,
    );
  }

  /**
   * Called when a user exports one or more hashtags
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. random)
   */
  trackExportedHashtags(properties: ExportedHashtagsProperties) {
    this.trackEvent(
      undefined,
      'Exported Hashtags',
      properties,
      '542c1d31-3294-48cf-a767-216d8a31e10c',
      '2.0.0',
      validators.ExportedHashtagsValidator,
    );
  }

  /**
   * When a user has an error with linking IG accounts
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. message)
   */
  trackLinkedIGAccountError(properties: LinkedIGAccountErrorProperties) {
    this.trackEvent(
      undefined,
      'Linked IG Account Error',
      properties,
      'b70f4200-a4bc-44d3-be4c-5e5d24450c16',
      '1.0.0',
      validators.LinkedIGAccountErrorValidator,
    );
  }

  /**
   * When a user links one or many IG accounts to Flick
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. numAccounts)
   */
  trackLinkedIGAccounts(properties: LinkedIGAccountsProperties) {
    this.trackEvent(
      undefined,
      'Linked IG Accounts',
      properties,
      '61840321-c944-4f15-887b-3f0b2974468e',
      '1.0.0',
      validators.LinkedIGAccountsValidator,
    );
  }

  /**
   * Triggered when a user successfully opens suggested hashtags
   *
   * Owner: Loic Alix-Brown
   */
  trackOpenedSuggestedHashtags() {
    this.trackEvent(
      undefined,
      'Opened Suggested Hashtags',
      undefined,
      '8afd1ff1-0c59-4302-ab70-adf13acd61a4',
      '1.0.0',
      validators.OpenedSuggestedHashtagsValidator,
    );
  }

  /**
   * When a user previews a hashtag in Instagram
   *
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. hashtag)
   */
  trackPreviewHashtag(properties: PreviewHashtagProperties) {
    this.trackEvent(
      undefined,
      'Preview Hashtag',
      properties,
      'e28c2190-79d4-432e-b679-f9929c4ab504',
      '1.0.0',
      validators.PreviewHashtagValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. destination)
   */
  trackRedirectedFromReports(properties: RedirectedFromReportsProperties) {
    this.trackEvent(
      undefined,
      'Redirected from Reports',
      properties,
      'e3bc31a1-b8f9-410f-8f01-44129fb3e4d7',
      '1.0.0',
      validators.RedirectedFromReportsValidator,
    );
  }

  /**
   * When a users removes a collection to favourites
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. name)
   */
  trackRemovedCollectionFromFavourites(
    properties: RemovedCollectionFromFavouritesProperties,
  ) {
    this.trackEvent(
      undefined,
      'Removed Collection From Favourites',
      properties,
      '3ac96fba-1eb7-4e34-a49f-03c4dae6a5d4',
      '1.0.0',
      validators.RemovedCollectionFromFavouritesValidator,
    );
  }

  /**
   * Called when a user makes a hashtag search of any type
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. limit)
   */
  trackSearchedHashtag(properties: SearchedHashtagProperties) {
    this.trackEvent(
      undefined,
      'Searched Hashtag',
      properties,
      '54a08d56-476a-4394-9653-bedc8279226f',
      '5.0.0',
      validators.SearchedHashtagValidator,
    );
  }

  /**
   * Called when a user selects one or multiple hashtags
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. numHashtags)
   */
  trackSelectedHashtags(properties: SelectedHashtagsProperties) {
    this.trackEvent(
      undefined,
      'Selected Hashtags',
      properties,
      '3457678f-367e-43eb-8d90-d5135876d0e2',
      '6.0.0',
      validators.SelectedHashtagsValidator,
    );
  }

  /**
   * When a user selects the number of ig accounts they manage during onboarding
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. numAccounts)
   */
  trackSelectedNumAccounts(properties: SelectedNumAccountsProperties) {
    this.trackEvent(
      undefined,
      'Selected Num Accounts',
      properties,
      '089c4b87-7a7e-4135-bb1b-f27326f86ac1',
      '1.0.0',
      validators.SelectedNumAccountsValidator,
    );
  }

  /**
   * When a user selects their aim during onboarding
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. aims)
   */
  trackSelectedOnboardingAims(properties: SelectedOnboardingAimsProperties) {
    this.trackEvent(
      undefined,
      'Selected Onboarding Aims',
      properties,
      'dc172b1a-c95b-417e-a2b9-01585fc0532f',
      '1.0.0',
      validators.SelectedOnboardingAimsValidator,
    );
  }

  /**
   * Called when a user has successfully selected his plans and addons
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. plan)
   */
  trackSelectedPlan(properties: SelectedPlanProperties) {
    this.trackEvent(
      undefined,
      'Selected Plan',
      properties,
      'e9e8b235-c50b-4c38-a791-57e6c9383880',
      '2.0.0',
      validators.SelectedPlanValidator,
    );
  }

  /**
   * Called when a user applies a time period on any of the analytics pages. This should not trigger when the page is loaded with the default time period.
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. name)
   */
  trackSelectedTimePeriod(properties: SelectedTimePeriodProperties) {
    this.trackEvent(
      undefined,
      'Selected Time Period',
      properties,
      '8fbe641f-0a1c-477c-bb44-3b0d60b42f6e',
      '4.0.0',
      validators.SelectedTimePeriodValidator,
    );
  }

  /**
   * When a user selects a use case during onboarding
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. name)
   */
  trackSelectedUseCase(properties: SelectedUseCaseProperties) {
    this.trackEvent(
      undefined,
      'Selected Use Case',
      properties,
      'c4b0911e-0011-4f03-a8e0-638e72a49df9',
      '1.0.0',
      validators.SelectedUseCaseValidator,
    );
  }

  /**
   * Called when a user toggles between content type on the content page
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. type)
   */
  trackToggledAnalyticsContentType(
    properties: ToggledAnalyticsContentTypeProperties,
  ) {
    this.trackEvent(
      undefined,
      'Toggled Analytics Content Type',
      properties,
      'ca6f0155-31ac-4ea6-aa21-59d6e38321d3',
      '2.0.0',
      validators.ToggledAnalyticsContentTypeValidator,
    );
  }

  /**
   * When a user toggles between the different "Position Groups" in the content section eg: top 9, top 10-25...
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. positionGroup)
   */
  trackToggledPositionGroup(properties: ToggledPositionGroupProperties) {
    this.trackEvent(
      undefined,
      'Toggled Position Group',
      properties,
      '280b5086-f0d7-4b25-b80d-fc49754cbf4a',
      '1.0.0',
      validators.ToggledPositionGroupValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. nextState)
   */
  trackToggledSelectedHashtags(properties: ToggledSelectedHashtagsProperties) {
    this.trackEvent(
      undefined,
      'Toggled Selected Hashtags',
      properties,
      '7016d6cc-981c-41b8-be17-2fe251e73bf0',
      '1.0.0',
      validators.ToggledSelectedHashtagsValidator,
    );
  }

  /**
   * Called when a user opens / closes the sidebar to view hashtags
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. nextState)
   */
  trackToggledSidebar(properties: ToggledSidebarProperties) {
    this.trackEvent(
      undefined,
      'Toggled Sidebar',
      properties,
      '94f03a5e-4125-4a7f-ae7d-c77021765c50',
      '3.0.0',
      validators.ToggledSidebarValidator,
    );
  }

  /**
   * Called when a user toggles suggested filters
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. username)
   */
  trackToggledSuggestFilters(properties: ToggledSuggestFiltersProperties) {
    this.trackEvent(
      undefined,
      'Toggled Suggest Filters',
      properties,
      '6c14595d-437a-447e-aa1a-a762cc68fd65',
      '2.0.0',
      validators.ToggledSuggestFiltersValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. nextState)
   */
  trackToggledWeeklyReports(properties: ToggledWeeklyReportsProperties) {
    this.trackEvent(
      undefined,
      'Toggled Weekly Reports',
      properties,
      'cee8982f-0473-4ac0-a909-917215bb5d4d',
      '1.0.0',
      validators.ToggledWeeklyReportsValidator,
    );
  }

  /**
   * When a user un-links an IG account from Flick
   *
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. username)
   */
  trackUnlinkedIGAccount(properties: UnlinkedIGAccountProperties) {
    this.trackEvent(
      undefined,
      'Unlinked IG Account',
      properties,
      'e92f4ba5-f90c-4698-a6ee-cb68eccad198',
      '1.0.0',
      validators.UnlinkedIGAccountValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. name)
   */
  trackUpdatedSavedFilter(properties: UpdatedSavedFilterProperties) {
    this.trackEvent(
      undefined,
      'Updated Saved Filter',
      properties,
      '00b6b6a4-1ffb-486b-9024-6d7426e41989',
      '1.0.0',
      validators.UpdatedSavedFilterValidator,
    );
  }

  /**
   * Made an action using a keyboard shortcut
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. action)
   */
  trackUsedShortcut(properties: UsedShortcutProperties) {
    this.trackEvent(
      undefined,
      'Used Shortcut',
      properties,
      'ebd47417-23e4-44c0-b949-fdeb722be3c8',
      '1.0.0',
      validators.UsedShortcutValidator,
    );
  }

  /**
   * Owner: Andy Asprou
   * @param properties The event's properties (e.g. type)
   */
  trackViewedCollection(properties: ViewedCollectionProperties) {
    this.trackEvent(
      undefined,
      'Viewed Collection',
      properties,
      '0f65ef61-7997-4241-8a76-d1c4634f05ad',
      '4.0.0',
      validators.ViewedCollectionValidator,
    );
  }

  /**
   * When a user opens the the "view ranked hashtags" dropdown in the content page
   *
   *
   * Owner: Loic Alix-Brown
   */
  trackViewedRankedHashtags() {
    this.trackEvent(
      undefined,
      'Viewed Ranked Hashtags',
      undefined,
      '1b9b89fd-6670-4d6a-9694-55cb6aeddb50',
      '1.0.0',
      validators.ViewedRankedHashtagsValidator,
    );
  }

  /**
   * When a user visits Flick's webapp
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. device)
   */
  trackVisitedApp(properties: VisitedAppProperties) {
    this.trackEvent(
      undefined,
      'Visited App',
      properties,
      '0abcfd0d-c89c-461c-9db8-29a406a24a88',
      '4.0.1',
      validators.VisitedAppValidator,
    );
  }

  /**
   * When a user swithces workspaces
   *
   * Owner: Loic Alix-Brown
   * @param properties The event's properties (e.g. workspace)
   */
  // trackSwitchedWorkspace(properties: SwitchedWorkspaceProperties) {
  //   this.trackEvent(
  //     undefined,
  //     'Switched Workspace',
  //     properties,
  //     '',
  //     '4.0.1',
  //     validators.SwitchedWorkspaceValidator,
  //   );
  // }

  reset() {
    this.adapters.forEach((adapter) => adapter.reset());
  }

  private isInitializedAndEnabled() {
    if (!this.options) {
      throw new Error(
        'Itly is not yet initialized. Have you called `itly.init()` on app start?',
      );
    }

    return !this.options.disabled;
  }

  private validateObject(
    name: string,
    validator: any,
    object: ItlyProperties | undefined,
  ) {
    if (object && !validator(object)) {
      const errors = validator.errors
        .map((e: any) => {
          let extra = '';
          if (e.keyword === 'additionalProperties') {
            extra = ` (${e.params.additionalProperty})`;
          }
          return `\`properties${e.dataPath}\` ${e.message}${extra}.`;
        })
        .join(' ');
      this.handleValidationError(
        `Passed in ${name} properties did not validate against your tracking plan. ${errors}`,
      );
    }
  }

  private trackEvent(
    userId: string | undefined,
    eventName: string,
    properties: any | undefined,
    eventId: string,
    eventVersion: string,
    eventValidator: any,
  ) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    if (this.options!.logger && this.options!.logger.debug) {
      this.options!.logger.debug(`[Itly] Tracking event "${eventName}"`);
    }

    const context =
      typeof this.options!.context === 'object'
        ? this.options!.context
        : this.options!.context();

    this.adapters.forEach((adapter) =>
      adapter.track(
        userId,
        eventName,
        { ...context, ...properties },
        eventId,
        eventVersion,
      ),
    );
  }

  private handleValidationError(message: string) {
    if (this.options!.environment === 'production') {
      if (this.options!.logger && this.options!.logger.error) {
        this.options!.logger.error(message);
      } else {
        console.error(message);
      }
    } else {
      throw new Error(message);
    }
  }
}

export default new Itly();
