import { cloneElement, forwardRef } from 'react';
import * as React from 'react';

import { AsProp, FC, PropsOf } from '@flick-tech/shared-types';
import {
  __DEV__,
  getValidChildren,
  mapResponsive,
} from '@flick-tech/shared-utils';
import {
  chakra,
  ChakraProps,
  css,
  Prop,
  SystemProps,
} from '@flick-tech/theme-new';

import { Box, BoxProps } from './Box';
import { FlexOptions } from './Flex';

export type StackDirection = Prop<'row' | 'column'>;

type StackOptions = Pick<FlexOptions, 'align' | 'justify' | 'wrap'> & {
  /**
   * The space between each stack item
   */
  spacing?: SystemProps['margin'];
  /**
   * The direction to stack the items.
   */
  direction?: StackDirection;
  /**
   * If `true`, each stack item will show a divider
   */
  divider?: React.ReactElement;
};

export interface StackProps extends BoxProps, StackOptions {}

export interface StackDividerProps
  extends Omit<PropsOf<'hr'>, 'color'>,
    ChakraProps,
    AsProp {}

export const StackDivider: FC<StackDividerProps> = chakra('hr', {
  baseStyle: { border: 0, alignSelf: 'stretch' },
});

/**
 * Stacks help you easily create flexible and automatically distributed layouts
 *
 * You can stack elements in the horizontal or vertical direction,
 * and apply a space or/and divider between each element.
 *
 * It uses `display: flex` internally and renders a `div`.
 *
 * @see Docs https://chakra-ui.com/stack
 *
 */
export const Stack: FC<StackProps> = forwardRef(
  (props: StackProps, ref: React.Ref<any>) => {
    const {
      direction = 'column',
      justify = 'flex-start',
      align = 'center',
      spacing = 2,
      wrap,
      children,
      divider,
      ...rest
    } = props;

    const styles = {
      flexDirection: mapResponsive(direction, (value) =>
        value === 'row' ? 'row' : 'column',
      ),
      SIBLING_SELECTOR: mapResponsive(direction, (value) => ({
        [value === 'column' ? 'marginTop' : 'marginLeft']: spacing,
        [value === 'column' ? 'marginLeft' : 'marginTop']: 0,
      })),
    };

    const validChildren = getValidChildren(children);

    const dividerStyles = mapResponsive(direction, (value) => {
      if (value === 'row') {
        return {
          marginX: spacing,
          marginY: 0,
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderBottom: 0,
          width: 'auto',
        };
      }
      return {
        marginX: 0,
        marginY: spacing,
        borderLeft: 0,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        width: '100%',
      };
    });

    const hasDivider = !!divider;

    const clones = validChildren.map((child, index) => {
      if (!hasDivider) return child;

      const isLast = index + 1 === validChildren.length;

      if (!isLast) {
        return (
          <React.Fragment key={index}>
            {child}
            {cloneElement(divider as React.ReactElement<any>, {
              css: css({ '&': dividerStyles }),
            })}
          </React.Fragment>
        );
      }

      return child;
    });

    /**
     * @see https://medium.com/@emmenko/patching-lobotomized-owl-selector-for-emotion-ssr-5a582a3c424c
     */
    const sx = hasDivider
      ? undefined
      : {
          '> *:not(style) ~ *': styles.SIBLING_SELECTOR,
          '> * ~ *:not(style)': styles.SIBLING_SELECTOR,
          '> *:not(style) ~ *:not(style)': styles.SIBLING_SELECTOR,
        };

    return (
      <Box
        ref={ref}
        display="flex"
        alignItems={align}
        justifyContent={justify}
        flexDirection={styles.flexDirection}
        flexWrap={wrap}
        {...rest}
        sx={{ ...sx, ...rest.sx }}
      >
        {clones}
      </Box>
    );
  },
);

if (__DEV__) {
  Stack.displayName = 'Stack';
}

/**
 * Layout component that stacks its children horizontally
 */
export const HStack = (props: Omit<StackProps, 'direction'>) => (
  <Stack align="center" {...props} direction="row" />
);

if (__DEV__) {
  HStack.displayName = 'HStack';
}

/**
 * Layout component that stacks its children vertically
 */
export const VStack = (props: Omit<StackProps, 'direction'>) => (
  <Stack align="center" {...props} direction="column" />
);

if (__DEV__) {
  VStack.displayName = 'VStack';
}
