import { ComponentTheme, mode } from './utils';

const sizes: ComponentTheme['sizes'] = {
  sm: {
    Track: {
      width: '1.375rem',
      height: '0.75rem',
    },
    Thumb: {
      width: '0.75rem',
      height: '0.75rem',
      _checked: {
        transform: 'translateX(0.625rem)',
      },
    },
  },
  md: {
    Track: {
      width: '1.875rem',
      height: '1rem',
    },
    Thumb: {
      width: '1rem',
      height: '1rem',
      _checked: {
        transform: 'translateX(0.875rem)',
      },
    },
  },
  lg: {
    Track: {
      width: '2.775rem',
      height: '1rem',

      position: 'relative',
    },
    Thumb: {
      width: '1.5rem',
      height: '1.5rem',

      position: 'absolute',
      top: '-0.25rem',

      color: 'blackAlpha.200',
      boxShadow: 'inset 0 0 0 2px currentColor',

      _focus: {
        boxShadow: 'inset 0 0 0 2px currentColor',
      },
      _checked: {
        transform: 'translateX(1.175rem)',
      },
    },
  },
};

const SwitchTheme: ComponentTheme = {
  defaultProps: {
    size: 'md',
    colorScheme: 'brand',
  },
  baseStyle: (props) => ({
    Track: {
      borderRadius: 'full',
      transition: 'all 120ms',
      bg: mode(`${props.colorScheme}.300`, 'whiteAlpha.400')(props),
      _focus: {
        boxShadow: 'outline',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _checked: {
        bg: mode(`${props.colorScheme}.300`, `${props.colorScheme}.200`)(props),
      },
    },
    Thumb: {
      bg: 'white',
      transition: 'transform 250ms',
      borderRadius: 'full',
      transform: 'translateX(0)',
      _checked: {
        bg: mode(
          // fixme? this isn't the most elegant code, but it leads to best looking design
          '#3776cb',
          // `${props.colorScheme}.500`
          `${props.colorScheme}.400`,
        )(props),
      },
    },
  }),
  sizes,
};

export const SwitchSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

export default SwitchTheme;
