import {
  Area,
  AreaProps,
  Bar,
  BarProps,
  Line,
  ReferenceLine,
  ReferenceLineProps,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
} from 'recharts';

import { colors, primaryShades } from '@flick-tech/theme-new';

import { tooltipWrapperStyles } from './Tooltip';

export const colorPrimary = '#3174e0';
export const colorSecondary = '#9CC5FF';
export const colorBackground = '#EEEEEE';

// All charts
export const transparent = '#00000000';
export const tickBackground = colorBackground;

// BarChart
export const graphStatLabel = '#6E7986';
export const gridColor = '#dddddd';
export const chartPalette3 = [colorPrimary, colorSecondary, colorBackground];
// When there are only 2 bars use colorBackground as the second color for better looks
export const chartPalette2 = [colorPrimary, colorBackground];

// Choropleth
export const choroplethBorder = 'rgb(141,140,160)';
export const choroplethPalette = primaryShades;

// TODO 3275e0 -> 3174e0

// Palettes
export const heatMapPalette = primaryShades;
export const barChartPalette = [...primaryShades].reverse();

// Charts config
export const maxBarSize = 20;
export const axisColor = colors.text.lighter;
export const tickTextStyle = {
  fontWeight: 400,
  fontSize: 14,
  fill: colors.text.light,
  width: 33,
};

const isAnimationActive = false;

const barOverrides: Partial<BarProps> = {
  isAnimationActive,
};

const areaLineOverrides: Partial<AreaProps> = {
  isAnimationActive,
  dot: false,
  type: 'monotoneX',
  strokeWidth: 2,
};

const axisOverrides: XAxisProps = {
  tickLine: false,
  tick: tickTextStyle,
  stroke: axisColor,
};

const tooltipOverrides: TooltipProps<never, never> = {
  cursor: {
    stroke: '#000000',
    strokeWidth: '2px',
    strokeOpacity: 0.8,
  },
  wrapperStyle: tooltipWrapperStyles,
  animationDuration: 100,
};

const referenceLineOverrides: ReferenceLineProps = {
  stroke: axisColor,
};

// TODO: Really ugly. And brittle. We're literally mutating their private field.
// A bit ugly but most lean way to override the defaults
Object.assign((Bar as any).defaultProps, barOverrides);
Object.assign((Line as any).defaultProps, areaLineOverrides);
Object.assign((Area as any).defaultProps, areaLineOverrides);
Object.assign((XAxis as any).defaultProps, axisOverrides);
Object.assign((YAxis as any).defaultProps, axisOverrides);
Object.assign((Tooltip as any).defaultProps, tooltipOverrides);
Object.assign((ReferenceLine as any).defaultProps, referenceLineOverrides);
