import mapValues from 'lodash/mapValues';

import { merge } from '@flick-tech/shared-utils';

import { SystemProps } from '../../parser';

import ButtonTheme from './ButtonTheme';
import { ComponentTheme, copy } from './utils';

const defaultProps = ButtonTheme.defaultProps;

type Sizes = keyof typeof ButtonTheme.sizes;

const chipButtonSizes = copy(
  // paddingRight to account for the X button
  mapValues(ButtonTheme.sizes, (v) => ({ ...v, paddingRight: v.minW })),
  'ChipButton',
);

const chipRemoveButtonWrapperSizes = mapValues(
  ButtonTheme.sizes,
  ({ minW: size, fontSize }) => {
    return {
      width: size,
      height: '100%',
      fontSize,
    };
  },
);

const chipRemoveButtonSizes: Record<Sizes, { width: number; height: number }> =
  mapValues(
    {
      xs: 3,
      sm: 4,
      md: 5,
      lg: 6,
    },
    (value) => ({ width: value, height: value }),
  );

const removeButtonHover = {
  opacity: 0.75,
};
const removeButtonActive = {
  ...removeButtonHover,
  transform: 'scale(0.9)',
};

const ChipTheme: ComponentTheme = {
  defaultProps,
  baseStyle: ({ theme }) => {
    return {
      ChipWrapper: {
        transition: 'all 250ms',
      },
      ChipButton: {
        ...(ButtonTheme.baseStyle as SystemProps),
        _active: {
          transform: 'none',
        },
        borderRadius: 'full',
      },
      ChipRemoveButton: {
        transition: 'all 250ms',
        _hover: removeButtonHover,
        _active: removeButtonActive,
        _focus: {
          boxShadow: `0 0 0 3px ${theme.colors!['whiteAlpha'][500]}`,
        },
        _selected: removeButtonActive,
      },
    };
  },
  variants: {
    solid: (props) => {
      const { colorScheme: c } = props;
      return {
        ChipButton: ButtonTheme.variants.solid(props),
        ChipRemoveButton: {
          color: `${c}.500`,
        },
      };
    },
    subdued: (props) => {
      const { theme, colorScheme: c } = props;

      const removeButtonHover = {
        bg: `${c}.100`,
        color: `${c}.500`,
      };
      return {
        ChipButton: {
          ...ButtonTheme.variants.subdued(props),
          color: 'gray.500',
        },
        ChipRemoveButton: {
          color: 'gray.500',
          _hover: removeButtonHover,
          _active: removeButtonActive,
          _focus: {
            boxShadow: `0 0 0 3px ${theme.colors![c][300]}`,
          },
        },
      };
    },
  },
  // TODO: We need better merge
  sizes: merge(
    merge(
      chipButtonSizes,
      copy(chipRemoveButtonWrapperSizes, 'ChipRemoveButtonWrapper'),
    ),
    copy(chipRemoveButtonSizes, 'ChipRemoveButton'),
  ),
};

export default ChipTheme;
