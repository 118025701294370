import { memo, ReactNode } from 'react';

import { WithLaunchDarkly } from '@flick-tech/client-tracking';
import { useInitializeSocialGroupsOnAppLoad } from '@flick-tech/social-group-hooks';

import { InitializeLoggedInUser, useInitialiseApp } from './hooks';

const Content = memo(function Content({ children }: { children: ReactNode }) {
  return <>{children}</>;
});

interface WithUserProps {
  children: ReactNode;
  skeletonLoader?: ReactNode;
}

export function WithUser({ children, skeletonLoader }: WithUserProps) {
  const { loading: isLoadingUser, user } = useInitialiseApp();
  const { isLoading: isLoadingSocialGroups } =
    useInitializeSocialGroupsOnAppLoad();

  // Both queries are required by all aspects of Flick.
  // This same result (with a small refactor) can be achieved by making both have suspense=true
  const isLoading = isLoadingUser || isLoadingSocialGroups;

  if (isLoading) {
    // In most pages, this will be generated statically
    return <>{skeletonLoader}</>;
  }

  return (
    <WithLaunchDarkly
      user={{
        id: user?.id,
        email: user?.email.toLowerCase(),
        name: user?.name,
        profilePicture: user?.profilePicture,
        createdAt: user?.createdAt,
      }}
      loader={skeletonLoader}
    >
      {/* Initialize the user once launch darkly has initialized */}
      <InitializeLoggedInUser />
      <Content>{children}</Content>
    </WithLaunchDarkly>
  );
}
