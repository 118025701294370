/*
 * This file is part of the nivo project.
 *
 * Copyright 2016-present, Raphaël Benitte.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { DOMAttributes } from 'react';
import * as React from 'react';

import { secondaryColorRaw } from '@flick-tech/theme-new';

const style = { cursor: 'pointer' };

interface Data {
  key: string;
  xKey: string;
  yKey: string;
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
  color: string;
  labelTextColor: string;
  opacity: number;
}

export interface HeatMapCellBaseProps {
  data: Data;
  value: number;
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  opacity: number;
  borderWidth: number;
  borderColor: string;
  enableLabel: boolean;
  textColor: string;
  theme: object;
  onHover?: DOMAttributes<SVGGElement>['onMouseMove'];
  onLeave?: DOMAttributes<SVGGElement>['onMouseLeave'];
  onClick: (
    data: Data,
    event: React.MouseEvent<SVGGElement, MouseEvent>,
  ) => void;
}

export interface HeatMapCellProps extends HeatMapCellBaseProps {
  radius?: number;
  highlight: boolean;
  highlightColor?: string;
  missingData?: 'fully' | 'partially';
}

// There is padding option but it affects spacing in all directions,
// this on the other hand just decresases height
const reduceHeight = 6;

export const HeatMapCell = (props: HeatMapCellProps) => {
  const {
    data,
    x,
    width,
    color,
    borderWidth,
    borderColor,
    onHover,
    onLeave,
    onClick,
    radius = 6,
    highlight,
    highlightColor = secondaryColorRaw,
    missingData,
  } = props;

  const y = props.y + reduceHeight / 2;
  const height = props.height - reduceHeight;

  let { opacity } = props;
  if (!highlight && opacity > 0.6) {
    // Elevate the highlighted cells by fading others
    opacity = 0.6;
  }

  const patterId =
    missingData && `cell-stripes-${color.replace(/[\(\)\,\.\#]/g, '-')}`;

  const strokeProps = {
    strokeWidth: highlight ? 4 : borderWidth,
    stroke: highlight ? highlightColor : borderColor,
    strokeOpacity: 1,
  };
  if (highlight) {
    strokeProps['data-intercom-id'] =
      'analytics--audience--activity-slot-highlighted';
  }

  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      opacity={opacity}
      onClick={(e) => {
        onClick(data, e);
      }}
      style={style}
    >
      {missingData && (
        // TODO: abstract that out if needed as it is used also in BarItem
        <defs>
          <pattern
            id={patterId}
            patternUnits="userSpaceOnUse"
            width="18"
            height="18"
            patternTransform="rotate(45)"
          >
            <line x1="0" y="0" x2="0" y2="18" stroke={color} strokeWidth="20" />
          </pattern>
        </defs>
      )}
      <rect
        x={width * -0.5}
        y={height * -0.5}
        height={height}
        rx={radius}
        ry={radius}
        // If fully missing data than stripe a full width rect
        fill={missingData === 'fully' ? `url(#${patterId})` : color}
        width={missingData === 'partially' ? width / 2 : width}
        // If partially missing data then the full-width striped rect will
        // take care of drawing outline since this will have just 50% width
        {...(missingData !== 'partially' ? strokeProps : {})}
      />
      {missingData === 'partially' && (
        <rect
          x={width * -0.5}
          y={height * -0.5}
          width={width}
          height={height}
          fill={`url(#${patterId})`}
          rx={radius}
          ry={radius}
          // Even tho it is striped it still displays 'highest' outline properly
          {...strokeProps}
        />
      )}
    </g>
  );
};
