import isMobile from 'ismobilejs';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

/**
 * @example
 * openMobileApp('analytics/home')
 * openMobileApp(`analytics/post/${shortcode}`)
 */
export function redirectToMobileApp(path = '') {
  window.open(`flick://${path}`, '_self');

  setTimeout(function () {
    // Link to the App Store should go here -- only fires if deep link fails
    window.open('https://lghim.app.link/ViarbLPJLab', '_self');
  }, 500);
}

export function useRedirectToApp(paths: { web: string; mobile: string }) {
  const router = useRouter();

  return useCallback(() => {
    if (isMobile().any) {
      redirectToMobileApp(paths.mobile);
    } else if (paths.web) {
      router.push(paths.web);
    }
  }, [router]);
}
