export function GenericErrorIllustration() {
  return (
    <svg width="339px" height="222px" viewBox="0 0 339 222" fill="none">
      <path
        d="M157.26 218.758C170.889 221.371 307.561 241.688 292.285 101.126C277.009 -39.4364 177.161 31.1489 117.973 7.71326C58.7849 -15.7223 10.4606 97.8058 46.0853 152.856C77.8395 201.925 128.368 213.223 157.26 218.758Z"
        fill="#4173E0"
      />
      <path
        d="M262.056 165.861C262.056 165.861 260.181 162.111 262.056 157.447C263.931 152.782 261.919 148.895 265.666 147.935C265.666 147.935 267.357 147.661 267.175 149.444C266.992 151.227 268.235 153.528 268.235 153.528C268.235 153.528 271.451 152.881 275.596 154.348C275.596 154.348 273.121 148.942 275.081 145.788C275.081 145.788 276.636 144.233 277.322 146.748C277.755 148.302 278.42 149.782 279.293 151.139C279.293 151.139 281.33 143.645 288.961 143.109C288.961 143.109 290.466 139.752 291.61 139.751C292.107 139.751 293.604 142.242 293.383 143.534C293.383 143.534 296.434 144.812 298.373 148.242C298.373 148.242 301.518 147.461 303.031 148.88C303.031 148.88 302.271 150.989 300.076 152.497C300.076 152.497 299.997 160.513 291.864 162.48C291.864 162.48 288.215 173.45 272.975 171.676C265.66 170.825 263.465 168.059 263.465 168.059C263.465 168.059 256.039 171.022 251.739 162.284C247.438 153.545 255.279 148.299 259.533 150.266C259.559 150.277 259.585 150.289 259.61 150.304C260.463 150.781 260.176 152.083 259.204 152.178C258.907 152.202 258.608 152.188 258.315 152.134C255.444 151.65 253.309 153.475 252.701 156.853C252.095 160.272 255.791 168.173 262.056 165.861Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M267.292 164.604C267.292 164.604 265.668 156.586 269.212 155.755C269.212 155.755 270.656 155.275 270.722 157.367C270.788 159.46 270.997 162.237 271.341 163.095"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M287.071 152.275C287.204 152.075 287.074 151.755 286.781 151.56C286.488 151.365 286.142 151.369 286.009 151.57C285.876 151.77 286.006 152.09 286.299 152.285C286.592 152.48 286.938 152.476 287.071 152.275Z"
        fill="#1D1D1D"
      />
      <path
        d="M290.274 155.357C290.274 155.357 292.652 155.929 294.55 159.084"
        stroke="#1D1D1D"
        strokeWidth="0.309394"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M290.635 154.703C290.635 154.703 293.81 154.692 295.604 157.086"
        stroke="#1D1D1D"
        strokeWidth="0.309394"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M289.993 156.308C289.993 156.308 291.946 157.789 292.65 159.762"
        stroke="#1D1D1D"
        strokeWidth="0.309394"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M283.048 149.591C283.048 149.591 281.495 147.002 277.848 145.837"
        stroke="#1D1D1D"
        strokeWidth="0.309394"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M283.471 148.95C283.471 148.95 282.089 145.237 279.167 143.935"
        stroke="#1D1D1D"
        strokeWidth="0.309394"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M282.319 150.238C282.319 150.238 280.143 148.449 278.072 148.285"
        stroke="#1D1D1D"
        strokeWidth="0.309394"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M239.346 84.8453C239.346 84.8453 254.254 80.487 253.119 111.135C251.983 141.782 262.105 162.288 304.478 160.328"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M310.82 157.981H313.69"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M310.82 160.511H313.69"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M310.82 162.847H313.69"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M281.539 165.465C281.539 165.465 278.796 158.914 280.476 156.788C280.476 156.788 281.334 155.621 282.157 157.303C282.98 158.985 283.632 160.871 285.381 161.763"
        fill="white"
      />
      <path
        d="M281.539 165.465C281.539 165.465 278.796 158.914 280.476 156.788C280.476 156.788 281.334 155.621 282.157 157.303C282.98 158.985 283.632 160.871 285.381 161.763"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M263.303 153.201C263.303 153.201 263.373 151.86 263.588 150.505C263.588 150.505 264 147.982 266.269 148.266C266.269 148.266 266.9 148.298 266.9 149.276C266.9 150.253 266.963 151.53 268.035 153.847L263.303 153.201Z"
        fill="white"
      />
      <path
        d="M299.019 149.918C299.019 149.918 300.012 149.137 303.041 148.878L299.019 149.918Z"
        fill="white"
      />
      <path
        d="M299.019 149.918C299.019 149.918 300.012 149.137 303.041 148.878"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M291.401 143.816C291.401 143.816 292.183 141.497 291.615 139.748L291.401 143.816Z"
        fill="white"
      />
      <path
        d="M291.401 143.816C291.401 143.816 292.183 141.497 291.615 139.748"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M283.988 151.938C284.776 153.794 286.416 152.07 286.416 152.07C286.416 152.07 284.681 154.616 287.361 154.142"
        stroke="#1D1D1D"
        strokeWidth="0.309394"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M310.908 157.081V163.947C310.908 164.043 310.882 164.137 310.833 164.22C310.784 164.302 310.713 164.37 310.629 164.416C310.544 164.462 310.449 164.484 310.353 164.481C310.257 164.477 310.164 164.448 310.083 164.396L303.87 160.35L310.101 156.622C310.182 156.574 310.274 156.548 310.369 156.547C310.463 156.546 310.556 156.571 310.638 156.617C310.72 156.664 310.788 156.731 310.835 156.812C310.883 156.894 310.908 156.986 310.908 157.081Z"
        fill="#1D1D1D"
      />
      <path
        d="M324.63 132.667L327.347 131.739L338 132.418V153.48L336.549 154.329L324.63 132.667Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M324.63 132.667L336.549 133.472V154.329L324.63 152.936V132.667Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M337.999 132.418L336.549 133.472"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M330.697 136.996C331.024 136.996 331.289 136.731 331.289 136.404C331.289 136.077 331.024 135.812 330.697 135.812C330.37 135.812 330.105 136.077 330.105 136.404C330.105 136.731 330.37 136.996 330.697 136.996Z"
        fill="#1D1D1D"
      />
      <path
        d="M328.478 139.715C328.948 139.715 329.329 139.334 329.329 138.865C329.329 138.395 328.948 138.014 328.478 138.014C328.008 138.014 327.627 138.395 327.627 138.865C327.627 139.334 328.008 139.715 328.478 139.715Z"
        fill="#1D1D1D"
      />
      <path
        d="M332.29 140.117C332.759 140.117 333.14 139.736 333.14 139.266C333.14 138.796 332.759 138.415 332.29 138.415C331.82 138.415 331.439 138.796 331.439 139.266C331.439 139.736 331.82 140.117 332.29 140.117Z"
        fill="#1D1D1D"
      />
      <path
        d="M330.697 145.769C331.024 145.769 331.289 145.504 331.289 145.177C331.289 144.85 331.024 144.585 330.697 144.585C330.37 144.585 330.105 144.85 330.105 145.177C330.105 145.504 330.37 145.769 330.697 145.769Z"
        fill="#1D1D1D"
      />
      <path
        d="M328.478 148.489C328.948 148.489 329.329 148.108 329.329 147.638C329.329 147.168 328.948 146.787 328.478 146.787C328.008 146.787 327.627 147.168 327.627 147.638C327.627 148.108 328.008 148.489 328.478 148.489Z"
        fill="#1D1D1D"
      />
      <path
        d="M332.29 148.89C332.759 148.89 333.14 148.509 333.14 148.039C333.14 147.569 332.759 147.188 332.29 147.188C331.82 147.188 331.439 147.569 331.439 148.039C331.439 148.509 331.82 148.89 332.29 148.89Z"
        fill="#1D1D1D"
      />
      <path
        d="M284.955 148.295C284.955 148.295 286.562 147.761 286.719 149.248L284.955 148.295Z"
        fill="white"
      />
      <path
        d="M284.955 148.295C284.955 148.295 286.562 147.761 286.719 149.248"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M289.685 151.179C289.685 151.179 291.367 150.982 291.219 152.47L289.685 151.179Z"
        fill="white"
      />
      <path
        d="M289.685 151.179C289.685 151.179 291.367 150.982 291.219 152.47"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M163.702 104.44L220.315 108.839V157.365L163.702 150.039V104.44Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M220.315 108.839L240.321 100.751V146.439L220.315 157.365V108.839Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M240.321 100.751L195.2 97.3458L163.702 104.44L220.315 108.839L240.321 100.751Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M220.011 57.7847L236.755 67.2439C236.755 67.2439 242.241 82.2846 237.322 96.0949L219.539 104.75L220.011 57.7847Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M171.096 60.86C171.3 59.9562 171.789 59.1419 172.491 58.5374C173.193 57.9329 174.071 57.5703 174.996 57.5031C184.424 56.8255 217.802 55.3198 220.011 57.7847C222.471 60.528 220.768 103.189 219.539 104.75C218.31 106.31 172.086 103.322 171.047 99.5385C170.161 96.3166 168.976 70.2326 171.096 60.86Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M175.997 63.5877C176.142 63.1396 176.417 62.7451 176.788 62.4551C177.159 62.165 177.608 61.9927 178.078 61.9603C185.355 61.4446 202.211 60.0245 208.824 61.9706C211.717 62.8225 210.317 97.5241 209.32 98.7885C208.322 100.053 176.118 97.3447 175.677 95.9968C174.796 93.2937 173.918 70.054 175.997 63.5877Z"
        fill="#1D1D1D"
      />
      <path
        d="M215.443 70.5482C216.59 70.5482 217.521 69.6178 217.521 68.4701C217.521 67.3224 216.59 66.392 215.443 66.392C214.295 66.392 213.365 67.3224 213.365 68.4701C213.365 69.6178 214.295 70.5482 215.443 70.5482Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M214.681 70.3069C215.759 70.3069 216.633 69.4328 216.633 68.3546C216.633 67.2764 215.759 66.4023 214.681 66.4023C213.602 66.4023 212.728 67.2764 212.728 68.3546C212.728 69.4328 213.602 70.3069 214.681 70.3069Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M215.443 78.312C216.59 78.312 217.521 77.3816 217.521 76.2339C217.521 75.0861 216.59 74.1558 215.443 74.1558C214.295 74.1558 213.365 75.0861 213.365 76.2339C213.365 77.3816 214.295 78.312 215.443 78.312Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M214.681 78.0704C215.759 78.0704 216.633 77.1964 216.633 76.1182C216.633 75.04 215.759 74.1659 214.681 74.1659C213.602 74.1659 212.728 75.04 212.728 76.1182C212.728 77.1964 213.602 78.0704 214.681 78.0704Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M211.688 81.2677L219.303 81.575"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M211.688 82.9991L219.303 83.3065"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M211.688 84.7308L219.303 85.0392"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M211.688 56.347L174.718 42.2716"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M2.45045 155.232C2.45045 155.232 22.1248 218.78 110.853 187.944C110.853 187.944 160.608 174.611 151.148 155.354C141.689 136.098 109.717 143.288 109.717 143.288L10.4019 139.111C10.4019 139.111 -3.41463 136.518 2.45045 155.232Z"
        fill="#4173E0"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M101.63 161.364C101.63 161.364 61.156 199.294 22.1548 166.187C22.1548 166.187 11.9572 156.87 21.1802 154.08L101.63 161.364Z"
        fill="#1D1D1D"
      />
      <path
        d="M77.8601 47.2848C77.8601 47.2848 81.9534 50.1364 83.8789 57.2886C83.8789 57.2886 85.0597 59.5234 87.4658 60.0329C89.8718 60.5424 90.5401 62.0729 90.2421 62.54C89.944 63.0072 85.6362 64.3118 85.6929 65.2813C85.7528 66.3126 87.7411 74.2537 82.1029 75.878C76.3007 77.5539 75.2313 77.8179 75.2313 77.8179C75.2313 77.8179 75.7634 87.3762 76.5895 88.4766L87.441 91.9737C87.441 91.9737 76.0254 107.499 66.9869 105.188C57.9485 102.877 41.2319 89.6626 41.2319 89.6626L54.639 88.229C54.639 88.229 58.228 77.9458 59.0458 71.1556C61.9954 46.6557 77.8601 47.2848 77.8601 47.2848Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M75.3181 77.4126C75.3181 77.4126 71.4197 78.3408 70.0584 78.316C69.1075 78.2954 67.8318 79.863 71.43 83.2932C75.0283 86.7233 76.2318 87.8887 76.2318 87.8887L75.3181 77.4126Z"
        fill="#1D1D1D"
      />
      <path
        d="M81.8133 58.8359C82.1766 58.7461 82.376 58.2888 82.2588 57.8143C82.1416 57.3399 81.752 57.0281 81.3887 57.1179C81.0255 57.2076 80.826 57.665 80.9432 58.1394C81.0605 58.6139 81.45 58.9257 81.8133 58.8359Z"
        fill="#1D1D1D"
      />
      <path
        d="M69.4333 63.6187C69.4333 63.6187 67.908 63.0092 67.2954 62.278"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M67.8482 61.0013C67.7718 60.9456 66.5188 62.2956 67.512 64.1664"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M79.7402 54.4173C79.7268 54.3235 77.8838 54.3389 77.2073 56.3469"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M77.4238 69.1651C77.4238 69.1651 80.8447 66.8003 84.2532 68.4978"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M80.291 49.3629C80.291 49.3629 77.8437 50.9099 73.2141 50.8356C73.2141 50.8356 69.2477 50.9862 69.5344 53.3603C69.8211 55.7344 73.7979 61.817 71.86 61.4509C69.9222 61.0848 70.2873 58.9046 67.8637 59.6606C65.4401 60.4165 64.9451 63.8364 66.1022 65.1699C67.2593 66.5033 63.0083 72.7675 58.7922 73.111C54.5762 73.4544 42.7605 58.8077 57.3298 46.0905C66.7715 37.8524 77.72 41.8188 80.291 49.3629Z"
        fill="#1D1D1D"
      />
      <path
        d="M115.489 129.202C115.489 129.202 119.523 130.117 119.367 132.103C119.21 134.089 105.959 137.569 105.959 137.569C105.959 137.569 116.198 129.817 115.489 129.202Z"
        fill="#1D1D1D"
      />
      <path
        d="M138.379 125.323C138.379 118.04 129.771 109.81 114.349 100.351C98.9268 90.8919 80.8314 89.0953 80.8314 89.0953C60.0535 100.636 51.4462 87.109 51.4462 87.109C33.6663 87.8887 7.27392 103.904 2.02452 117.662C-3.22487 131.419 21.1802 148.593 21.1802 148.593V154.079C45.5842 196.387 101.63 161.364 101.63 161.364L100.92 153.319C132.991 146.134 138.379 132.608 138.379 125.323ZM23.3552 134.926C23.3552 134.926 16.497 124.851 17.9161 123.81C17.9161 123.81 18.4441 121.857 26.5873 121.851L23.3552 134.926ZM101.582 140.928L103.475 123.808C103.475 123.808 115.583 128.916 115.488 129.672C115.393 130.428 101.582 140.928 101.582 140.928Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M103.509 123.499L97.8936 120.974C97.8936 120.974 99.8799 124.616 99.2652 127.738C98.6505 130.86 101.583 140.939 101.583 140.939L103.509 123.499Z"
        fill="#1D1D1D"
      />
      <path
        d="M101.583 140.928L97.0891 144.617C97.0205 144.966 96.9713 145.319 96.9416 145.674C96.7663 147.613 96.8571 151.673 100.393 153.13C100.561 153.199 100.737 153.263 100.92 153.319"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M96.9415 145.677C96.8538 145.693 91.4755 146.618 90.9176 148.026C90.3596 149.433 87.867 153.492 88.6487 154.694C89.4304 155.897 90.833 156.854 91.6209 156.475C92.3841 156.108 93.9177 155.151 94.0136 155.091C96.0236 154.84 98.605 154.299 100.395 153.132C96.8569 151.676 96.7662 147.616 96.9415 145.677ZM90.9599 155.309C91.9512 155.294 92.941 155.225 93.9249 155.102C93.4526 155.174 91.3765 155.477 90.9568 155.309H90.9599Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M21.1802 148.593C21.1802 148.593 24.0173 123.999 29.8824 113.973L21.1802 148.593Z"
        fill="#F5F5F5"
      />
      <path
        d="M21.1802 148.593C21.1802 148.593 24.0173 123.999 29.8824 113.973"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
      />
      <path
        d="M26.5873 121.849C26.5873 121.849 22.8591 121.175 18.3306 123.318C18.3306 123.318 18.8029 121.914 22.1547 120.637C25.5064 119.36 30.5444 112.902 30.5444 112.902L26.5873 121.849Z"
        fill="#1D1D1D"
      />
      <path
        d="M112.667 150.039C112.667 150.039 124.313 147.093 136.387 149.161C136.387 149.161 138.942 148.168 133.881 147.316C128.82 146.464 123.902 145.06 123.902 145.06L112.667 150.039Z"
        fill="#1D1D1D"
      />
      <path
        d="M134.32 143.493C134.32 143.493 152.095 151.62 149.919 161.644C147.743 171.669 136.724 177.378 136.724 177.378C136.724 177.378 149.919 170.866 151.827 162.369C153.753 153.794 144.106 145.633 134.32 143.493Z"
        fill="white"
      />
      <path
        d="M312.615 83.8335C312.615 83.8335 309.95 -15.5223 213.228 3.39512"
        stroke="#1D1D1D"
        strokeWidth="0.618789"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
