const timezonesList = [
  ['Abidjan', 'Africa/Abidjan'],
  ['Accra', 'Africa/Accra'],
  ['Nairobi', 'Africa/Nairobi'],
  ['Algiers', 'Africa/Algiers'],
  ['Lagos', 'Africa/Lagos'],
  ['Bissau', 'Africa/Bissau'],
  ['Maputo', 'Africa/Maputo'],
  ['Cairo', 'Africa/Cairo'],
  ['Casablanca', 'Africa/Casablanca'],
  ['Ceuta', 'Africa/Ceuta'],
  ['El Aaiun', 'Africa/El_Aaiun'],
  ['Johannesburg', 'Africa/Johannesburg'],
  ['Juba', 'Africa/Juba'],
  ['Khartoum', 'Africa/Khartoum'],
  ['Monrovia', 'Africa/Monrovia'],
  ['Ndjamena', 'Africa/Ndjamena'],
  ['Sao Tome', 'Africa/Sao_Tome'],
  ['Tripoli', 'Africa/Tripoli'],
  ['Tunis', 'Africa/Tunis'],
  ['Windhoek', 'Africa/Windhoek'],
  ['Adak', 'America/Adak'],
  ['Anchorage', 'America/Anchorage'],
  ['Port of Spain', 'America/Port_of_Spain'],
  ['Araguaina', 'America/Araguaina'],
  ['Buenos Aires', 'America/Argentina/Buenos_Aires'],
  ['Catamarca', 'America/Argentina/Catamarca'],
  ['Cordoba', 'America/Argentina/Cordoba'],
  ['Jujuy', 'America/Argentina/Jujuy'],
  ['La Rioja', 'America/Argentina/La_Rioja'],
  ['Mendoza', 'America/Argentina/Mendoza'],
  ['Rio Gallegos', 'America/Argentina/Rio_Gallegos'],
  ['Salta', 'America/Argentina/Salta'],
  ['San Juan', 'America/Argentina/San_Juan'],
  ['San Luis', 'America/Argentina/San_Luis'],
  ['Tucuman', 'America/Argentina/Tucuman'],
  ['Ushuaia', 'America/Argentina/Ushuaia'],
  ['Curacao', 'America/Curacao'],
  ['Asuncion', 'America/Asuncion'],
  ['Atikokan', 'America/Atikokan'],
  ['Bahia Banderas', 'America/Bahia_Banderas'],
  ['Bahia', 'America/Bahia'],
  ['Barbados', 'America/Barbados'],
  ['Belem', 'America/Belem'],
  ['Belize', 'America/Belize'],
  ['Blanc-Sablon', 'America/Blanc-Sablon'],
  ['Boa Vista', 'America/Boa_Vista'],
  ['Bogota', 'America/Bogota'],
  ['Boise', 'America/Boise'],
  ['Cambridge Bay', 'America/Cambridge_Bay'],
  ['Campo Grande', 'America/Campo_Grande'],
  ['Cancun', 'America/Cancun'],
  ['Caracas', 'America/Caracas'],
  ['Cayenne', 'America/Cayenne'],
  ['Panama', 'America/Panama'],
  ['Chicago', 'America/Chicago'],
  ['Chihuahua', 'America/Chihuahua'],
  ['Costa Rica', 'America/Costa_Rica'],
  ['Creston', 'America/Creston'],
  ['Cuiaba', 'America/Cuiaba'],
  ['Danmarkshavn', 'America/Danmarkshavn'],
  ['Dawson Creek', 'America/Dawson_Creek'],
  ['Dawson', 'America/Dawson'],
  ['Denver', 'America/Denver'],
  ['Detroit', 'America/Detroit'],
  ['Edmonton', 'America/Edmonton'],
  ['Eirunepe', 'America/Eirunepe'],
  ['El Salvador', 'America/El_Salvador'],
  ['Tijuana', 'America/Tijuana'],
  ['Fort Nelson', 'America/Fort_Nelson'],
  ['Fort Wayne', 'America/Fort_Wayne'],
  ['Fortaleza', 'America/Fortaleza'],
  ['Glace Bay', 'America/Glace_Bay'],
  ['Godthab', 'America/Godthab'],
  ['Goose Bay', 'America/Goose_Bay'],
  ['Grand Turk', 'America/Grand_Turk'],
  ['Guatemala', 'America/Guatemala'],
  ['Guayaquil', 'America/Guayaquil'],
  ['Guyana', 'America/Guyana'],
  ['Halifax', 'America/Halifax'],
  ['Havana', 'America/Havana'],
  ['Hermosillo', 'America/Hermosillo'],
  ['Knox', 'America/Indiana/Knox'],
  ['Marengo', 'America/Indiana/Marengo'],
  ['Petersburg', 'America/Indiana/Petersburg'],
  ['Tell City', 'America/Indiana/Tell_City'],
  ['Vevay', 'America/Indiana/Vevay'],
  ['Vincennes', 'America/Indiana/Vincennes'],
  ['Winamac', 'America/Indiana/Winamac'],
  ['Inuvik', 'America/Inuvik'],
  ['Iqaluit', 'America/Iqaluit'],
  ['Jamaica', 'America/Jamaica'],
  ['Juneau', 'America/Juneau'],
  ['Louisville', 'America/Kentucky/Louisville'],
  ['Monticello', 'America/Kentucky/Monticello'],
  ['La Paz', 'America/La_Paz'],
  ['Lima', 'America/Lima'],
  ['Los Angeles', 'America/Los_Angeles'],
  ['Maceio', 'America/Maceio'],
  ['Managua', 'America/Managua'],
  ['Manaus', 'America/Manaus'],
  ['Martinique', 'America/Martinique'],
  ['Matamoros', 'America/Matamoros'],
  ['Mazatlan', 'America/Mazatlan'],
  ['Menominee', 'America/Menominee'],
  ['Merida', 'America/Merida'],
  ['Metlakatla', 'America/Metlakatla'],
  ['Mexico City', 'America/Mexico_City'],
  ['Miquelon', 'America/Miquelon'],
  ['Moncton', 'America/Moncton'],
  ['Monterrey', 'America/Monterrey'],
  ['Montevideo', 'America/Montevideo'],
  ['Toronto', 'America/Toronto'],
  ['Nassau', 'America/Nassau'],
  ['New York', 'America/New_York'],
  ['Nipigon', 'America/Nipigon'],
  ['Nome', 'America/Nome'],
  ['Noronha', 'America/Noronha'],
  ['Beulah', 'America/North_Dakota/Beulah'],
  ['Center', 'America/North_Dakota/Center'],
  ['New Salem', 'America/North_Dakota/New_Salem'],
  ['Ojinaga', 'America/Ojinaga'],
  ['Pangnirtung', 'America/Pangnirtung'],
  ['Paramaribo', 'America/Paramaribo'],
  ['Phoenix', 'America/Phoenix'],
  ['Port-au-Prince', 'America/Port-au-Prince'],
  ['Rio Branco', 'America/Rio_Branco'],
  ['Porto Velho', 'America/Porto_Velho'],
  ['Puerto Rico', 'America/Puerto_Rico'],
  ['Punta Arenas', 'America/Punta_Arenas'],
  ['Rainy River', 'America/Rainy_River'],
  ['Rankin Inlet', 'America/Rankin_Inlet'],
  ['Recife', 'America/Recife'],
  ['Regina', 'America/Regina'],
  ['Resolute', 'America/Resolute'],
  ['Santarem', 'America/Santarem'],
  ['Santiago', 'America/Santiago'],
  ['Santo Domingo', 'America/Santo_Domingo'],
  ['Sao Paulo', 'America/Sao_Paulo'],
  ['Scoresbysund', 'America/Scoresbysund'],
  ['Sitka', 'America/Sitka'],
  ['St Johns', 'America/St_Johns'],
  ['Swift Current', 'America/Swift_Current'],
  ['Tegucigalpa', 'America/Tegucigalpa'],
  ['Thule', 'America/Thule'],
  ['Thunder Bay', 'America/Thunder_Bay'],
  ['Vancouver', 'America/Vancouver'],
  ['Whitehorse', 'America/Whitehorse'],
  ['Winnipeg', 'America/Winnipeg'],
  ['Yakutat', 'America/Yakutat'],
  ['Yellowknife', 'America/Yellowknife'],
  ['Auckland', 'Pacific/Auckland'],
  ['Oslo', 'Europe/Oslo'],
  ['Riyadh', 'Asia/Riyadh'],
  ['Almaty', 'Asia/Almaty'],
  ['Amman', 'Asia/Amman'],
  ['Anadyr', 'Asia/Anadyr'],
  ['Aqtau', 'Asia/Aqtau'],
  ['Aqtobe', 'Asia/Aqtobe'],
  ['Ashgabat', 'Asia/Ashgabat'],
  ['Atyrau', 'Asia/Atyrau'],
  ['Baghdad', 'Asia/Baghdad'],
  ['Qatar', 'Asia/Qatar'],
  ['Baku', 'Asia/Baku'],
  ['Bangkok', 'Asia/Bangkok'],
  ['Barnaul', 'Asia/Barnaul'],
  ['Beirut', 'Asia/Beirut'],
  ['Bishkek', 'Asia/Bishkek'],
  ['Brunei', 'Asia/Brunei'],
  ['Kolkata', 'Asia/Kolkata'],
  ['Chita', 'Asia/Chita'],
  ['Choibalsan', 'Asia/Choibalsan'],
  ['Shanghai', 'Asia/Shanghai'],
  ['Colombo', 'Asia/Colombo'],
  ['Dhaka', 'Asia/Dhaka'],
  ['Damascus', 'Asia/Damascus'],
  ['Dili', 'Asia/Dili'],
  ['Dubai', 'Asia/Dubai'],
  ['Dushanbe', 'Asia/Dushanbe'],
  ['Famagusta', 'Asia/Famagusta'],
  ['Gaza', 'Asia/Gaza'],
  ['Hebron', 'Asia/Hebron'],
  ['Ho Chi Minh', 'Asia/Ho_Chi_Minh'],
  ['Hong Kong', 'Asia/Hong_Kong'],
  ['Hovd', 'Asia/Hovd'],
  ['Irkutsk', 'Asia/Irkutsk'],
  ['Istanbul', 'Europe/Istanbul'],
  ['Jakarta', 'Asia/Jakarta'],
  ['Jayapura', 'Asia/Jayapura'],
  ['Jerusalem', 'Asia/Jerusalem'],
  ['Kabul', 'Asia/Kabul'],
  ['Kamchatka', 'Asia/Kamchatka'],
  ['Karachi', 'Asia/Karachi'],
  ['Urumqi', 'Asia/Urumqi'],
  ['Kathmandu', 'Asia/Kathmandu'],
  ['Khandyga', 'Asia/Khandyga'],
  ['Krasnoyarsk', 'Asia/Krasnoyarsk'],
  ['Kuala Lumpur', 'Asia/Kuala_Lumpur'],
  ['Kuching', 'Asia/Kuching'],
  ['Macau', 'Asia/Macau'],
  ['Magadan', 'Asia/Magadan'],
  ['Makassar', 'Asia/Makassar'],
  ['Manila', 'Asia/Manila'],
  ['Nicosia', 'Asia/Nicosia'],
  ['Novokuznetsk', 'Asia/Novokuznetsk'],
  ['Novosibirsk', 'Asia/Novosibirsk'],
  ['Omsk', 'Asia/Omsk'],
  ['Oral', 'Asia/Oral'],
  ['Pontianak', 'Asia/Pontianak'],
  ['Pyongyang', 'Asia/Pyongyang'],
  ['Qostanay', 'Asia/Qostanay'],
  ['Qyzylorda', 'Asia/Qyzylorda'],
  ['Rangoon', 'Asia/Rangoon'],
  ['Sakhalin', 'Asia/Sakhalin'],
  ['Samarkand', 'Asia/Samarkand'],
  ['Seoul', 'Asia/Seoul'],
  ['Srednekolymsk', 'Asia/Srednekolymsk'],
  ['Taipei', 'Asia/Taipei'],
  ['Tashkent', 'Asia/Tashkent'],
  ['Tbilisi', 'Asia/Tbilisi'],
  ['Tehran', 'Asia/Tehran'],
  ['Thimphu', 'Asia/Thimphu'],
  ['Tokyo', 'Asia/Tokyo'],
  ['Tomsk', 'Asia/Tomsk'],
  ['Ulaanbaatar', 'Asia/Ulaanbaatar'],
  ['Ust-Nera', 'Asia/Ust-Nera'],
  ['Vladivostok', 'Asia/Vladivostok'],
  ['Yakutsk', 'Asia/Yakutsk'],
  ['Yekaterinburg', 'Asia/Yekaterinburg'],
  ['Yerevan', 'Asia/Yerevan'],
  ['Azores', 'Atlantic/Azores'],
  ['Bermuda', 'Atlantic/Bermuda'],
  ['Canary', 'Atlantic/Canary'],
  ['Cape Verde', 'Atlantic/Cape_Verde'],
  ['Faroe', 'Atlantic/Faroe'],
  ['Madeira', 'Atlantic/Madeira'],
  ['Reykjavik', 'Atlantic/Reykjavik'],
  ['South Georgia', 'Atlantic/South_Georgia'],
  ['Stanley', 'Atlantic/Stanley'],
  ['Sydney', 'Australia/Sydney'],
  ['Adelaide', 'Australia/Adelaide'],
  ['Brisbane', 'Australia/Brisbane'],
  ['Broken Hill', 'Australia/Broken_Hill'],
  ['Currie', 'Australia/Currie'],
  ['Darwin', 'Australia/Darwin'],
  ['Eucla', 'Australia/Eucla'],
  ['Hobart', 'Australia/Hobart'],
  ['Lord Howe', 'Australia/Lord_Howe'],
  ['Lindeman', 'Australia/Lindeman'],
  ['Melbourne', 'Australia/Melbourne'],
  ['Perth', 'Australia/Perth'],
  ['Easter', 'Pacific/Easter'],
  ['Dublin', 'Europe/Dublin'],
  ['Port Moresby', 'Pacific/Port_Moresby'],
  ['Tarawa', 'Pacific/Tarawa'],
  ['Christmas', 'Indian/Christmas'],
  ['Palau', 'Pacific/Palau'],
  ['UTC', 'Etc/UTC'],
  ['Amsterdam', 'Europe/Amsterdam'],
  ['Andorra', 'Europe/Andorra'],
  ['Astrakhan', 'Europe/Astrakhan'],
  ['Athens', 'Europe/Athens'],
  ['London', 'Europe/London'],
  ['Belgrade', 'Europe/Belgrade'],
  ['Berlin', 'Europe/Berlin'],
  ['Prague', 'Europe/Prague'],
  ['Brussels', 'Europe/Brussels'],
  ['Bucharest', 'Europe/Bucharest'],
  ['Budapest', 'Europe/Budapest'],
  ['Zurich', 'Europe/Zurich'],
  ['Chisinau', 'Europe/Chisinau'],
  ['Copenhagen', 'Europe/Copenhagen'],
  ['Gibraltar', 'Europe/Gibraltar'],
  ['Helsinki', 'Europe/Helsinki'],
  ['Kaliningrad', 'Europe/Kaliningrad'],
  ['Kiev', 'Europe/Kiev'],
  ['Kirov', 'Europe/Kirov'],
  ['Lisbon', 'Europe/Lisbon'],
  ['Luxembourg', 'Europe/Luxembourg'],
  ['Madrid', 'Europe/Madrid'],
  ['Malta', 'Europe/Malta'],
  ['Minsk', 'Europe/Minsk'],
  ['Monaco', 'Europe/Monaco'],
  ['Moscow', 'Europe/Moscow'],
  ['Paris', 'Europe/Paris'],
  ['Riga', 'Europe/Riga'],
  ['Rome', 'Europe/Rome'],
  ['Samara', 'Europe/Samara'],
  ['Saratov', 'Europe/Saratov'],
  ['Simferopol', 'Europe/Simferopol'],
  ['Sofia', 'Europe/Sofia'],
  ['Stockholm', 'Europe/Stockholm'],
  ['Tallinn', 'Europe/Tallinn'],
  ['Tirane', 'Europe/Tirane'],
  ['Ulyanovsk', 'Europe/Ulyanovsk'],
  ['Uzhgorod', 'Europe/Uzhgorod'],
  ['Vienna', 'Europe/Vienna'],
  ['Vilnius', 'Europe/Vilnius'],
  ['Volgograd', 'Europe/Volgograd'],
  ['Warsaw', 'Europe/Warsaw'],
  ['Zaporozhye', 'Europe/Zaporozhye'],
  ['Chagos', 'Indian/Chagos'],
  ['Cocos', 'Indian/Cocos'],
  ['Kerguelen', 'Indian/Kerguelen'],
  ['Mahe', 'Indian/Mahe'],
  ['Maldives', 'Indian/Maldives'],
  ['Mauritius', 'Indian/Mauritius'],
  ['Reunion', 'Indian/Reunion'],
  ['Kwajalein', 'Pacific/Kwajalein'],
  ['Chatham', 'Pacific/Chatham'],
  ['Apia', 'Pacific/Apia'],
  ['Bougainville', 'Pacific/Bougainville'],
  ['Chuuk', 'Pacific/Chuuk'],
  ['Efate', 'Pacific/Efate'],
  ['Enderbury', 'Pacific/Enderbury'],
  ['Fakaofo', 'Pacific/Fakaofo'],
  ['Fiji', 'Pacific/Fiji'],
  ['Galapagos', 'Pacific/Galapagos'],
  ['Gambier', 'Pacific/Gambier'],
  ['Guadalcanal', 'Pacific/Guadalcanal'],
  ['Guam', 'Pacific/Guam'],
  ['Honolulu', 'Pacific/Honolulu'],
  ['Kiritimati', 'Pacific/Kiritimati'],
  ['Kosrae', 'Pacific/Kosrae'],
  ['Majuro', 'Pacific/Majuro'],
  ['Marquesas', 'Pacific/Marquesas'],
  ['Pago Pago', 'Pacific/Pago_Pago'],
  ['Nauru', 'Pacific/Nauru'],
  ['Niue', 'Pacific/Niue'],
  ['Norfolk', 'Pacific/Norfolk'],
  ['Noumea', 'Pacific/Noumea'],
  ['Pitcairn', 'Pacific/Pitcairn'],
  ['Pohnpei', 'Pacific/Pohnpei'],
  ['Rarotonga', 'Pacific/Rarotonga'],
  ['Tahiti', 'Pacific/Tahiti'],
  ['Tongatapu', 'Pacific/Tongatapu'],
] as const;

/**
 * Standard timezone name of form {Area}/{Location} e.g. "Pacific/Fiji"
 * @see https://en.wikipedia.org/wiki/Tz_database
 */
export type TimezoneName = typeof timezonesList[number][1];
export interface TimezoneInfo {
  zoneName: TimezoneName;
  /**
   * offset from UTC in minutes
   */
  offset: number;
  city: string;
  label: string;
}

const locale = 'en-US';
const referenceDate = new Date();
const utc = new Date(referenceDate.toLocaleString(locale, { timeZone: 'UTC' }));

const doubleDigit = (value: number) => {
  return value > 9 ? String(value) : '0' + value;
};

const offsetToUTC = (offset: number) =>
  offset === 0
    ? 'UTC'
    : `UTC${offset > 0 ? '+' : '-'}${doubleDigit(
        Math.floor(Math.abs(offset / 60)),
      )}:${doubleDigit(Math.abs(offset % 60))}`;

const getTimezoneOffset = (timeZone: string) => {
  // Not all devices have up-to-date timezone database (looking at you android)
  try {
    const zoned = new Date(referenceDate.toLocaleString(locale, { timeZone }));
    const offset = (zoned.getTime() - utc.getTime()) / (1000 * 60);
    if (offset > 14 * 60) {
      return null;
    }
    return offset;
  } catch {}
  return null;
};

export const timezones: TimezoneInfo[] = timezonesList
  .map(([city, zoneName]) => ({
    city,
    zoneName,
    offset: getTimezoneOffset(zoneName),
  }))
  .filter(({ offset }) => offset !== null)
  .map((timezone) => {
    const { zoneName, city, offset } = timezone;
    const zoneParsed = zoneName.replace(/\_/g, ' ').replace(`/${city}`, '');

    return {
      ...timezone,
      label: `(${offsetToUTC(offset)}) ${zoneParsed}/${city}`,
    };
  })
  .sort((a, b) => {
    if (a.offset === b.offset) {
      return a.zoneName.localeCompare(b.zoneName);
    }
    return a.offset > b.offset ? 1 : -1;
  });
