import { memo, Suspense } from 'react';

import { useGetUser, useHasAccessToFeature } from '@flick-tech/core-hooks';
import {
  isRunningOnServerSide,
  LayoutConstants,
  ROUTES,
} from '@flick-tech/shared-common';
import { useFeatureFlags } from '@flick-tech/shared-hooks';
import { borderColorBase, hideScrollbarsStyles } from '@flick-tech/theme-new';
import { Box, Flex, Stack } from '@flick-tech/ui';
import { useMedia } from '@flick-tech/ui';

import { useShouldShowWorkspaceSwitcher } from './WorkspaceSwitcher/hooks/useShouldShowWorkspaceSwitcher';
import { WorkspaceSwitcherLoader } from './WorkspaceSwitcher/WorkspaceSwitcherAvatar';
import FlickLogoRoute from './FlickLogoRoute';
import { NavigationItem, NavigationItemProps } from './NavigationItem';
import {
  ProfileNavigationItem,
  ProfileNavigationItemProps,
} from './ProfileNavigationItem';
import {
  SocialGroupSwitcher,
  SocialGroupSwitcherProps,
} from './SocialGroupSwitcher';
import { WorkspaceSwitcher } from './WorkspaceSwitcher';

export interface NavigationProps
  extends Pick<ProfileNavigationItemProps, 'user'>,
    Pick<
      SocialGroupSwitcherProps,
      'hasAccessToHashtagTracking' | 'postsTrackedLimit'
    > {
  hasAccessToViralPosts: boolean;
  hasAccessToScheduling?: boolean;
  isLoading?: boolean;
  onChangeSocialGroup?: SocialGroupSwitcherProps['onChange'];
}

export const Navigation = memo(
  ({
    isLoading: isLoadingFromProps,
    onChangeSocialGroup,
  }: {
    isLoading?: boolean;
    onChangeSocialGroup?: () => void;
  }) => {
    const isLargeScreen = useMedia({ min: 'lg' });
    const isLarge = isRunningOnServerSide() ? true : isLargeScreen;

    const variant: NavigationItemProps['variant'] = isLarge ? 'button' : 'icon';

    const { user, isLoading: isUserLoading } = useGetUser();

    const isLoading = isLoadingFromProps || isUserLoading;

    const navigationItemProps = {
      variant,
      isLoading,
    };

    const hasAccessToViralPosts = useHasAccessToFeature('VIRAL_POSTS_FINDER');

    const hasAccessToScheduling = useFeatureFlags().schedule;

    const hasAccessToHashtagTracking = useHasAccessToFeature('AnalyticsPro');

    const shouldShowWorkspaceSwitcher = useShouldShowWorkspaceSwitcher({
      numberOfWorkspaces: user?.numberOfWorkspaces,
      myRole: user?.activeWorkspace?.myRole,
    });

    return (
      <Flex
        as="nav"
        position="relative"
        zIndex={3}
        bg="white"
        direction="column"
        borderRight="1px solid"
        borderRightColor={borderColorBase}
        data-testid="layout--left-nav-bar"
        // this constant is dangerous — check every place it's used and try
        // to rewrite it to flexbox
        width={LayoutConstants.sidebarWidth}
        flexShrink={0}
        {...(isLarge && {
          px: 4,
        })}
        pt={6}
      >
        <Box mb={6}>
          <FlickLogoRoute size={isLarge ? 'large' : 'small'} />
        </Box>
        <Flex mb={6} direction="column" align={isLarge ? 'start' : 'center'}>
          <Box
            borderY="1px solid"
            borderTopColor="bg.base"
            borderBottomColor="bg.base"
            paddingY={3}
            width={isLarge ? '100%' : 'auto'}
          >
            <SocialGroupSwitcher
              hasAccessToHashtagTracking={hasAccessToHashtagTracking}
              postsTrackedLimit={
                user?.activeWorkspace?.billing?.postsTrackedLimit
              }
              size="sm"
              isLoading={isLoading}
              onChange={onChangeSocialGroup}
            />
          </Box>
        </Flex>
        <Stack
          py="3px" // For overflow
          spacing={4}
          overflow="auto"
          {...({ overflowY: 'overlay' } as {})}
          {...(hideScrollbarsStyles as {})}
          {...(isLarge && {
            alignItems: 'stretch',
            overflow: 'visible', // for button outlines
          })}
        >
          <NavigationItem
            name="Home"
            icon="HomeRounded"
            route={ROUTES.Home}
            app="web"
            {...navigationItemProps}
          />
          <NavigationItem
            name="Find hashtags"
            shortName="Search"
            icon="SearchRounded"
            app="web"
            route={ROUTES.Explore}
            {...navigationItemProps}
          />
          {hasAccessToViralPosts && (
            <NavigationItem
              name="Find viral posts"
              shortName="Viral posts"
              app="web"
              icon="ImageSearchRounded"
              route={ROUTES.ViralPosts}
              {...navigationItemProps}
            />
          )}
          <NavigationItem
            name="Hashtag Collections"
            shortName="Collections"
            app="web"
            icon="FolderOpenOutlined"
            route={ROUTES.HashtagCollections.Home}
            {...navigationItemProps}
          />
          <NavigationItem
            name="Analytics"
            icon="InsertChartOutlined"
            app="web"
            route={ROUTES.Analytics.Home}
            {...navigationItemProps}
          />
          <NavigationItem
            name="Scheduling"
            icon="EventRounded"
            app={hasAccessToScheduling ? 'schedule' : 'web'}
            route={
              hasAccessToScheduling
                ? ROUTES.Scheduling.Calendar
                : ROUTES.Scheduling.WaitList
            }
            {...navigationItemProps}
          />
          <NavigationItem
            name="Training & Tips"
            icon="MenuBookRounded"
            app="web"
            route={ROUTES.Resources.Base}
            {...navigationItemProps}
          />
        </Stack>
        <Stack
          spacing={4}
          mt="auto"
          pb={4}
          alignItems={isLarge ? 'stretch' : 'center'}
          overflow="visible"
        >
          <ProfileNavigationItem
            isLoading={isLoading}
            user={user}
            isLarge={isLarge}
          />

          {shouldShowWorkspaceSwitcher && (
            <Suspense
              fallback={
                <WorkspaceSwitcherLoader showExpandedVersion={isLarge} />
              }
            >
              <WorkspaceSwitcher
                showExpandedVersion={isLarge}
                isLoading={isLoading}
                activeWorkspace={user?.activeWorkspace}
                borderTop="1px solid"
                borderTopColor="bg.base"
                paddingY={3}
                width={isLarge ? '100%' : 'auto'}
              />
            </Suspense>
          )}
        </Stack>
      </Flex>
    );
  },
);
