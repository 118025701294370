import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { __DEV__, cx, dataAttr, Omit } from '@flick-tech/shared-utils';
import { chakra, PropsOf, SystemProps } from '@flick-tech/theme-new';

import { FormControlWrapper, FormControlWrapperProps } from '../form-control';
import { SVGIconProps } from '../icon';
import { Spinner } from '../spinner';

import { useCheckbox, UseCheckboxProps } from './Checkbox.hook';
import { CheckboxIcon } from './Checkbox.icon';
import { useCheckboxGroupContext } from './CheckboxGroup';

/**
 * Checkbox - Theming
 *
 * To style the checkbox globally, change the styles in
 * `theme.components.Checkbox` under the `Control` key
 */
export const CheckboxControl = chakra('div', {
  themeKey: 'Checkbox.Control',
  baseStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'top',
    userSelect: 'none',
    flexShrink: 0,
    transition: 'transform 240ms, opacity 240ms',
  },
});

const StyledLabel = chakra('div', {
  themeKey: 'Checkbox.Label',
  baseStyle: {
    userSelect: 'none',
  },
});

export const CheckboxWrapper = chakra('label', {
  baseStyle: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    position: 'relative',
  },
});

type Omitted = Omit<
  PropsOf<typeof CheckboxControl>,
  'onChange' | 'defaultChecked'
>;

export type CheckboxProps = Omitted &
  Omit<PropsOf<'input'>, 'size'> &
  UseCheckboxProps & {
    /**
     * The color of the check icon
     */
    iconColor?: SVGIconProps['color'];
    /**
     * The size of the check icon
     * @default 0.75rem
     */
    iconSize?: SVGIconProps['size'];
    /**
     * The spacing between the checkbox and it's label text
     * @default 0.5rem
     */
    labelSpacing?: SystemProps['marginLeft'];

    isLoading?: boolean;
  };

/**
 * Checkbox
 *
 * React component used in forms when a user needs to select
 * multiple values from several options.
 *
 * @see Docs https://chakra-ui.com/checkbox
 */
export const Checkbox = React.forwardRef(
  (props: CheckboxProps, ref: React.Ref<HTMLInputElement>) => {
    const group = useCheckboxGroupContext();

    const {
      iconSize = '0.625rem',
      labelSpacing = '0.5rem',
      iconColor,
      variant = group?.variant,
      colorScheme = group?.colorScheme ?? 'brand',
      size = group?.size,
      className,
      children,
      isLoading,
      ...checkboxProps
    } = props;

    let isChecked = props.isChecked;
    if (group?.value && props.value) {
      isChecked = group.value.includes(props.value);
    }

    let onChange = props.onChange;
    if (group?.onChange && props.value) {
      onChange = group.onChange;
    }

    const { state, getInputProps, getCheckboxProps, getLabelProps, htmlProps } =
      useCheckbox({
        ...checkboxProps,
        isChecked,
        onChange,
      });

    const _className = cx('chakra-checkbox', className);

    const theming = { variant, size, colorScheme };

    return (
      <CheckboxWrapper
        {...htmlProps}
        data-active={dataAttr(state.isChecked)}
        className={_className}
      >
        <input className="chakra-checkbox__input" {...getInputProps({ ref })} />
        <CheckboxControl
          className="chakra-checkbox__control"
          {...theming}
          verticalAlign="top"
          {...getCheckboxProps()}
        >
          {isLoading ? (
            <Spinner
              color={state.isChecked ? 'white' : 'brand.500'}
              size="sm"
            />
          ) : (
            <CheckboxIcon
              className="chakra-checkbox__icon"
              transition="transform 240ms, opacity 240ms"
              isChecked={state.isChecked}
              isIndeterminate={state.isIndeterminate}
              boxSize={iconSize}
              color={iconColor}
            />
          )}
        </CheckboxControl>
        {children && (
          <StyledLabel
            className="chakra-checkbox__label"
            {...theming}
            {...getLabelProps()}
            marginLeft={labelSpacing}
            children={children}
          />
        )}
      </CheckboxWrapper>
    );
  },
);

if (__DEV__) {
  Checkbox.displayName = 'Checkbox';
}

interface FinalFormCheckboxProps
  extends FieldRenderProps<string, HTMLInputElement>,
    CheckboxProps {
  hideTouchedError?: boolean;
  helperText?: string;
  label?: string;
  hideHelperText?: boolean;
  fullWidth?: boolean;
  wrapperProps?: Partial<FormControlWrapperProps>;
}

export function FinalFormCheckbox({
  meta,
  input,
  hideTouchedError,
  helperText,
  label,
  hideHelperText,
  fullWidth,
  wrapperProps = {},
  ...rest
}: FinalFormCheckboxProps) {
  const { name, ...restInput } = input;
  const { error, submitFailed } = meta;
  const showError =
    ((meta.touched && !hideTouchedError) || submitFailed) && error;

  return (
    <FormControlWrapper
      showError={showError}
      error={error}
      helperText={hideHelperText ? undefined : helperText}
      label={label}
      name={name}
      {...wrapperProps}
    >
      <Checkbox {...restInput} name={name} {...rest} />
    </FormControlWrapper>
  );
}

if (__DEV__) {
  FinalFormCheckbox.displayName = 'FinalFormCheckbox';
}
