import { QueryClient } from 'react-query';
/* eslint-disable import/no-extraneous-dependencies */
import { QueryKey } from 'react-query/types/core/types';

import { getQueryKey } from './queryUtils';

type SetQueryDataReturn = Promise<void | ReturnType<
  QueryClient['invalidateQueries']
>>;

export function createQueryUtils() {
  function createSetQueryData<TParams, TData>(
    queryClient: QueryClient,
    path: string,
    params: TParams,
  ) {
    return function setQueryData(
      newData: TData | ((oldData: TData | undefined) => TData),
      options = { refetch: true },
    ): SetQueryDataReturn {
      if (typeof path === 'undefined') {
        throw new Error(
          'setQueryData is missing the first argument - it must be a path',
        );
      }

      const queryKey = getQueryKey(path, params);

      return new Promise((res) => {
        queryClient.setQueryData(queryKey, newData);
        let result: void | ReturnType<typeof queryClient.invalidateQueries>;

        if (options.refetch) {
          result = invalidateQuery(queryClient, path, params);
        }

        res(result);
      });
    };
  }

  function invalidateQuery<TParams>(
    queryClient: QueryClient,
    path: string,
    params?: TParams,
  ) {
    if (typeof path === 'undefined') {
      throw new Error(
        'invalidateQuery is missing the first argument - it must be a path',
      );
    }

    const fullQueryKey = getQueryKey(path, params);

    let queryKey: QueryKey;

    if (params) {
      queryKey = fullQueryKey;
    } else {
      // Params not provided, only use first query key item (url)
      queryKey = fullQueryKey[0];
    }

    return queryClient.invalidateQueries(queryKey);
  }

  return { createSetQueryData, invalidateQuery };
}

type CreateQueryUtils = typeof createQueryUtils;

export type CreateSetQueryData =
  ReturnType<CreateQueryUtils>['createSetQueryData'];
