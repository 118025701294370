import Cookies from 'js-cookie';

import {
  getFirstName,
  getLastName,
  getPersistedBasicUserInfo,
  getWebApiEndpoint,
} from '@flick-tech/shared-common';
import { Logger } from '@flick-tech/logger';

export interface FBPixelConversionAPIPayload {
  eventId: string;
  eventName: string;
  eventData?: Record<string, string | number>;
  fbp?: string;
  fbclid?: string;
  userAgent: string;
  url: string;
  user?: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
}

export const fBConversionsApiTrack = async (
  eventName: string,
  {
    eventData,
    user: initUser,
    eventId,
  }: {
    eventData?: Record<string, string | number | boolean>;
    user?: {
      email?: string;
    };
    eventId?: string;
  } = {},
) => {
  const basicUser = getPersistedBasicUserInfo();

  const user = {
    // Not ?? as initUser.email could be ''
    email: initUser?.email || basicUser?.email,
    firstName: getFirstName(basicUser?.name),
    lastName: getLastName(basicUser?.name),
  };

  try {
    await fetch(getWebApiEndpoint('FBConversionAPI'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventName,
        eventData,
        eventId,
        fbp: Cookies.get('_fbp'),
        fbclid: Cookies.get('flick_fbClickID') || Cookies.get('_fbc'),
        userAgent: navigator.userAgent,
        url: window.location.origin + window.location.pathname,
        user,
      } as FBPixelConversionAPIPayload),
      credentials: 'include',
    });
  } catch (error) {
    Logger.error(error);
  }
};
