import { ChakraIcon, ChakraIconProps } from './chakra-icon';

export function TriangleUpIcon(props: ChakraIconProps) {
  return (
    <ChakraIcon viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z"
      />
    </ChakraIcon>
  );
}
