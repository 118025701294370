import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { Constants } from '@flick-tech/shared-common';

import { FBPixel } from './fb-pixel';

export function useInitializeFBPixelTracking() {
  const router = useRouter();

  useEffect(() => {
    FBPixel.init(Constants.fbPixelId);
    FBPixel.pageView();

    router.events.on('routeChangeComplete', () => {
      FBPixel.pageView();
    });
  }, [router.events]);
}
