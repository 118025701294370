/**
 * @see https://crunchify.com/list-of-all-social-sharing-urls-for-handy-reference-social-media-sharing-buttons-without-javascript/
 */

import copy from 'copy-to-clipboard';
import omitBy from 'lodash/omitBy';
import { ComponentPropsWithoutRef } from 'react';

import { isUndefined } from '@flick-tech/shared-utils';
import { chakra, SystemProps } from '@flick-tech/theme-new';
import { useToast } from '../toast';

import { BufferIcon, FacebookIcon, LinkedInIcon, TwitterIcon } from './icons';
import { ClipboardIcon, FacebookRoundedIcon } from './icons';

interface CopyToClipboardLink extends SystemProps {
  url: string;
}
export function CopyToClipboardLink({ url, ...props }: CopyToClipboardLink) {
  const toast = useToast();
  const handleCopy = () => {
    copy(url);
    toast({
      title: `Link has been copied to clipboard`,
      status: 'success',
    });
  };

  return (
    <chakra.a onClick={handleCopy} {...props}>
      <ClipboardIcon w="100%" h="100%" />
    </chakra.a>
  );
}

interface SharingLinkProps extends ComponentPropsWithoutRef<typeof chakra.a> {
  endpoint: string;
  searchParams: object;
}

function SharingLink({ endpoint, searchParams, ...rest }: SharingLinkProps) {
  const onClick = () => {
    window.open(
      `${endpoint}?${new URLSearchParams(
        omitBy(searchParams, isUndefined) as Record<string, string>,
      ).toString()}`,
      '_blank',
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <chakra.a target="__blank" p="2" onClick={onClick} {...rest} />
  );
}

/**
 * https://twitter.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]
 */
export interface TwitterShareLinkProps extends SystemProps {
  url: string;
  text: string;
  /**
   * optional Twitter username of content author (don’t include “@”)
   */
  via?: string;
  /**
   * optional Hashtags appended onto the tweet (comma separated. don’t include “#”)
   */
  hashtags?: string;
}
export function TwitterShareLink({
  url,
  text,
  via,
  hashtags,
  ...rest
}: TwitterShareLinkProps) {
  return (
    <SharingLink
      endpoint="https://twitter.com/share"
      searchParams={{ url, text, via, hashtags }}
      aria-label="Share to Twitter"
      {...rest}
    >
      <TwitterIcon w="100%" h="100%" />
    </SharingLink>
  );
}

/**
 * https://bufferapp.com/add?text=[post-title]&url=[post-url]
 */
export interface BufferShareLinkProps extends SystemProps {
  url: string;
  text: string;
}
export function BufferShareLink({ url, text, ...rest }: BufferShareLinkProps) {
  return (
    <SharingLink
      endpoint="https://bufferapp.com/add"
      searchParams={{ url, text }}
      aria-label="Add to Buffer"
      {...rest}
    >
      <BufferIcon />
    </SharingLink>
  );
}

/**
 * https://www.linkedin.com/shareArticle?url=[post-url]&title=[post-title]
 */
export interface LinkedInShareLinkProps extends SystemProps {
  url: string;
  title: string;
}
export function LinkedInShareLink({
  url,
  title,
  ...rest
}: LinkedInShareLinkProps) {
  return (
    <SharingLink
      endpoint="https://www.linkedin.com/shareArticle"
      searchParams={{ url, title }}
      aria-label="Share to LinkedIn"
      {...rest}
    >
      <LinkedInIcon w="100%" h="100%" />
    </SharingLink>
  );
}

/**
 * https://www.facebook.com/sharer.php?u=[post-url]
 */
export interface BaseFacebookShareLinkProps extends FacebookShareLinkProps {
  children: any;
}
function BaseFacebookShareLink({
  u,
  children,
  ...rest
}: BaseFacebookShareLinkProps) {
  return (
    <SharingLink
      endpoint="https://www.facebook.com/sharer.php"
      searchParams={{ u }}
      aria-label="Share to Facebook"
      {...rest}
    >
      {children}
    </SharingLink>
  );
}

/**
 * https://www.facebook.com/sharer.php?u=[post-url]
 */
export interface FacebookShareLinkProps extends SystemProps {
  u: string;
}
export function FacebookShareLink(props: FacebookShareLinkProps) {
  return (
    <BaseFacebookShareLink {...props}>
      <FacebookIcon w="100%" h="100%" />
    </BaseFacebookShareLink>
  );
}
export function FacebookRoundedShareLink(props: FacebookShareLinkProps) {
  return (
    <BaseFacebookShareLink {...props}>
      <FacebookRoundedIcon w="100%" h="100%" />
    </BaseFacebookShareLink>
  );
}
