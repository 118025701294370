export function PlusIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4994 11.0553H11.3327V15.222C11.3327 15.6803 10.9577 16.0553 10.4993 16.0553C10.041 16.0553 9.66602 15.6803 9.66602 15.222V11.0553H5.49935C5.04102 11.0553 4.66602 10.6803 4.66602 10.222C4.66602 9.76367 5.04102 9.38867 5.49935 9.38867H9.66602V5.22201C9.66602 4.76367 10.041 4.38867 10.4993 4.38867C10.9577 4.38867 11.3327 4.76367 11.3327 5.22201V9.38867H15.4994C15.9577 9.38867 16.3327 9.76367 16.3327 10.222C16.3327 10.6803 15.9577 11.0553 15.4994 11.0553Z"
        fill="#C1C1C1"
      />
    </svg>
  );
}
