export interface ColorHues {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

/* @deprecated */
const gray = (v: number) => `rgb(${v}, ${v}, ${v})`;

const palette = {
  white: '#FFFFFF',
  orange: {
    10: '#FFF9F0',
    20: '#FFECD0',
    30: '#FFE1B0',
    40: '#FFC873',
    50: '#FCB13C',
    60: '#F59D0F',
    70: '#ED9100',
    80: '#D17F00',
    90: '#B86F00',
    100: '#9B5C00',
    110: '#7B4800',
    120: '#593400',
  },
  pink: {
    10: '#FFF2F3',
    20: '#FED6DA',
    30: '#FDBBC0',
    40: '#FA868F',
    50: '#F55764',
    60: '#F23A4A',
    70: '#E41325',
    80: '#CB0000',
    90: '#B40000',
    100: '#980000',
    110: '#790000',
    120: '#590000',
  },
  aqua: {
    10: '#EDFCFC',
    20: '#D9FCF9',
    30: '#CAF9F5',
    40: '#9FF2ED',
    50: '#79E9E3',
    60: '#66DDD7',
    70: '#4CD4CD',
    80: '#27BFB7',
    90: '#1AABA2',
    100: '#0F9289',
    110: '#06766E',
    120: '#005952',
  },
  blue: {
    10: '#F0F5FF',
    20: '#DDE9FF',
    30: '#ADC8F7',
    40: '#6093EA',
    50: '#266CDF',
    60: '#0854D6',
    70: '#004BCD',
    80: '#0044BD',
    90: '#003CAA',
    100: '#003291',
    110: '#002876',
    120: '#001E59',
  },
  purple: {
    10: '#F4F0FF',
    20: '#E3D8FE',
    30: '#CBB8FC',
    40: '#B382F8',
    50: '#9554F1',
    60: '#8A3FF2',
    70: '#6512D9',
    80: '#5300C8',
    90: '#4A00B1',
    100: '#3F0096',
    110: '#320078',
    120: '#250059',
  },
  green: {
    10: '#F4FFEE',
    20: '#DAFED3',
    30: '#C3FCB8',
    40: '#96F882',
    50: '#6EF154',
    60: '#4DE72E',
    70: '#33D912',
    80: '#25CC04',
    90: '#1EB100',
    100: '#199600',
    110: '#147800',
    120: '#0F5900',
  },
  whiteAlpha: {
    10: 'rgba(255, 255, 255, 0.02)',
    20: 'rgba(255, 255, 255, 0.04)',
    30: 'rgba(255, 255, 255, 0.06)',
    40: 'rgba(255, 255, 255, 0.08)',
    50: 'rgba(255, 255, 255, 0.12)',
    60: 'rgba(255, 255, 255, 0.16)',
    70: 'rgba(255, 255, 255, 0.24)',
    80: 'rgba(255, 255, 255, 0.36)',
    90: 'rgba(255, 255, 255, 0.48)',
    100: 'rgba(255, 255, 255, 0.64)',
    110: 'rgba(255, 255, 255, 0.8)',
    120: 'rgba(255, 255, 255, 0.92)',
  },
  lightGray: {
    10: '#FAFAFA',
    20: '#F9F9F9',
    30: '#F8F8F8',
    40: '#F4F4F4',
    50: '#EFEFEF',
    60: '#E8E8E8',
    70: '#DEDEDE',
    80: '#D1D1D1',
    90: '#C1C1C1',
    100: '#ADADAD',
    110: '#969696',
    120: '#808080',
  },
  darkGray: {
    10: '#828282',
    20: '#818181',
    30: '#808080',
    40: '#7C7C7C',
    50: '#777777',
    60: '#6F6F6F',
    70: '#646464',
    80: '#575757',
    90: '#454545',
    100: '#303030',
    110: '#181818',
    120: '#000000',
  },
  social: {
    facebook: '#1877F2',
    twitter: '#1D9BF0',
    linkedin: '#0A66C2',
  },
} as const;

export const colors = {
  transparent: 'transparent',
  current: 'currentColor',

  /* @deprecated */
  black: '#000',

  /* @deprecated */
  white: '#fff',

  palette,

  text: {
    white: palette.white,
    lighter: palette.lightGray[90],
    light: palette.lightGray[120],
    base: palette.darkGray[100],
    dark: palette.darkGray[110],
    darker: '#333333' /* @deprecated */,
    black: '#1D1D1D' /* @deprecated */,

    alpha: {
      lighter: palette.whiteAlpha[80],
      light: palette.whiteAlpha[100],
      base: palette.whiteAlpha[120],
    },
  },

  bg: {
    white: palette.white,
    lighter: palette.lightGray[10],
    light: palette.lightGray[40],
    base: palette.lightGray[60],
    dark: palette.darkGray[110],

    alpha: {
      lighter: palette.whiteAlpha[20],
      light: palette.whiteAlpha[40],
      base: palette.whiteAlpha[50],
    },
  },

  /* @deprecated */
  highlight: {
    lightRed: '#ffecec',
    red: '#c71414',
  },

  /* @deprecated */
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  /* @deprecated */
  blackAlpha: {
    25: 'rgba(0, 0, 0, 0.02)',
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  /* @deprecated */
  gray: {
    10: gray(250),
    50: gray(246),
    100: gray(238),
    200: gray(225),
    300: gray(202),
    400: gray(157),
    500: gray(110),
    600: gray(69),
    700: gray(46),
    800: gray(32),
    900: gray(26),
  },

  /* @deprecated */
  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#F44336',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },

  /* @deprecated */
  yellow: {
    50: '#FFFFF0',
    100: '#FEFCBF',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#FFEB3B',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
  },

  /* @deprecated */
  green: {
    50: '#F0FFF4' /* @deprecated */,
    100: '#C6F6D5' /* @deprecated */,
    200: '#9AE6B4' /* @deprecated */,
    300: '#68D391' /* @deprecated */,
    400: '#48BB78' /* @deprecated */,
    500: '#4CAF50' /* @deprecated */,
    600: '#2F855A' /* @deprecated */,
    700: '#276749' /* @deprecated */,
    800: '#22543D' /* @deprecated */,
    900: '#1C4532' /* @deprecated */,
  },

  aqua: {
    lighter: palette.aqua['10'],
    light: palette.aqua['50'],
    base: palette.aqua['60'],
    dark: palette.aqua['70'],
  },

  /* @deprecated */
  lightGreen: {
    500: '#8BC34A',
  },

  /* @deprecated */
  lime: {
    500: '#CDDC39',
  },

  /* @deprecated */
  teal: {
    500: '#009688',
  },

  primary: {
    lighter: palette.blue['10'],
    light: palette.blue['40'],
    base: palette.blue['50'],
    dark: palette.blue['60'],
  },

  /* @deprecated */
  blue: {
    50: '#F8FAFC',
    100: '#EDF2F7',
    200: '#90CDF4',
    300: '#63B3ED',
    400: '#4299E1',
    500: '#2196F3',
    600: '#2A69AC',
    700: '#1E4E8C',
    800: '#153E75',
    900: '#1A365D',
  },

  /* @deprecated */
  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#00BCD4',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },

  purple: {
    lighter: palette.purple['10'],
    light: palette.purple['40'],
    base: palette.purple['50'],
    dark: palette.purple['60'],
    50: '#FAF5FF' /* @deprecated */,
    100: '#E9D8FD' /* @deprecated */,
    200: '#D6BCFA' /* @deprecated */,
    300: '#B794F4' /* @deprecated */,
    400: '#9F7AEA' /* @deprecated */,
    500: '#9C27B0' /* @deprecated */,
    600: '#6B46C1' /* @deprecated */,
    700: '#553C9A' /* @deprecated */,
    800: '#44337A' /* @deprecated */,
    900: '#322659' /* @deprecated */,
  },

  /* @deprecated */
  deepPurple: {
    500: '#673AB7',
  },

  pink: {
    lighter: palette.pink['10'],
    light: palette.pink['40'],
    base: palette.pink['50'],
    dark: palette.pink['60'],
    50: '#FFF5F7' /* @deprecated */,
    100: '#FED7E2' /* @deprecated */,
    200: '#FBB6CE' /* @deprecated */,
    300: '#F687B3' /* @deprecated */,
    400: '#ED64A6' /* @deprecated */,
    500: '#E91E63' /* @deprecated */,
    600: '#B83280' /* @deprecated */,
    700: '#97266D' /* @deprecated */,
    800: '#702459' /* @deprecated */,
    900: '#521B41' /* @deprecated */,
  },

  /* @deprecated */
  secondary: {
    500: '#F0AF2B',
  },

  /* @deprecated */
  brand: {
    50: '#F5F9FF',
    100: '#EAF1FC',
    200: '#DAEAFC',
    300: '#A2C3F3',
    400: '#699BE9',
    500: '#3174E0',
    600: '#1E61CE',
    700: '#0F56A2',
    800: '#0A3769',
    900: '#07284C',
  },

  /* @deprecated */
  accent: {
    500: '#F0AF2B',
  },

  orange: {
    lighter: palette.orange['10'],
    light: palette.orange['50'],
    base: palette.orange['60'],
    dark: palette.orange['70'],
    50: '#FFFAF0' /* @deprecated */,
    100: '#FEEBC8' /* @deprecated */,
    200: '#FBD38D' /* @deprecated */,
    300: '#F6AD55' /* @deprecated */,
    400: '#ED8936' /* @deprecated */,
    500: '#DD6B20' /* @deprecated */,
    600: '#C05621' /* @deprecated */,
    700: '#9C4221' /* @deprecated */,
    800: '#7B341E' /* @deprecated */,
    900: '#652B19' /* @deprecated */,
  },

  /* @deprecated */
  deepOrange: {
    500: '#FF5722',
  },

  semantic: {
    success: palette.green['90'],
    error: palette.pink['70'],
    warning: palette.orange['70'],
    info: palette.blue['60'],
  },

  social: palette.social,

  facebook: {
    50: '#E8F4F9',
    100: '#D9DEE9',
    200: '#B7C2DA',
    300: '#6482C0',
    400: '#4267B2',
    500: '#385898',
    600: '#314E89',
    700: '#29487D',
    800: '#223B67',
    900: '#1E355B',
  },
} as const;

// This only handles 3 levels deep objects
// TypeScript doesn't allow defining arbitrarily deeply nested recursive types
type NestedObject = {
  [key in string]:
    | string
    | number
    | {
        [key in string]: string | number | { [key in string]: string | number };
      };
};

type NestedKeysObject<T extends NestedObject> = {
  [Property in keyof T]: T[Property] extends string | number
    ? Property
    : {
        [SubProperty in keyof T[Property]]: `${Property extends string | number
          ? Property
          : never}.${SubProperty extends string | number
          ? T[Property][SubProperty] extends string | number
            ? SubProperty
            : {
                [SubSubProperty in keyof T[Property][SubProperty]]: `${SubProperty}.${SubSubProperty extends
                  | string
                  | number
                  ? SubSubProperty
                  : never}`;
              }[keyof T[Property][SubProperty]]
          : never}`;
      }[keyof T[Property]];
};

type NestedKeys<T extends NestedObject> =
  NestedKeysObject<T>[keyof NestedKeysObject<T>];

export type Color = NestedKeys<typeof colors>;

declare module '../../parser/types' {
  export interface Injected {
    color: Color;
  }
}

export default colors;
