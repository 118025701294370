import { Button } from '../../button';
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ScaleFadeModal,
} from '../../modal';

import { AlertOptions } from '../types';

interface AlertDialogProps extends AlertOptions {
  open?: boolean;
  onClose: () => void;
  onExited: () => void;
}

function AlertDialog({
  open = false,
  onClose,
  onExited,
  title,
  message,
  ok = 'Ok',
}: AlertDialogProps) {
  return (
    <ScaleFadeModal
      isOpen={open}
      onClose={() => {
        onClose();
        onExited();
      }}
    >
      <>
        {title && (
          <ModalHeader>
            {title}
            <ModalCloseButton />
          </ModalHeader>
        )}
        <ModalBody lineHeight={1.5}>{message}</ModalBody>
        <ModalFooter>
          <Button onClick={() => onClose()}>{ok}</Button>
        </ModalFooter>
      </>
    </ScaleFadeModal>
  );
}

export default AlertDialog;
