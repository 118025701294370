import { useMemo } from 'react';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

import { __DEV__, createContext } from '@flick-tech/shared-utils';
import { ThemingProps } from '@flick-tech/theme-new';

import { FormControlWrapper, FormControlWrapperProps } from '../form-control';

import {
  useCheckboxGroup,
  UseCheckboxGroupProps,
  UseCheckboxGroupReturn,
} from './CheckboxGroup.hook';

export type CheckboxGroupProps = UseCheckboxGroupProps &
  Omit<ThemingProps, 'orientation'> & { children?: React.ReactNode };

export type CheckboxGroupContext = Pick<
  UseCheckboxGroupReturn,
  'onChange' | 'value'
> &
  Omit<ThemingProps, 'orientation'>;

const [CheckboxGroupContextProvider, useCheckboxGroupContext] =
  createContext<CheckboxGroupContext>({
    name: 'CheckboxGroupContext',
    strict: false,
  });

export { useCheckboxGroupContext };

/**
 * Used for multiple checkboxes which are bound in one group,
 * and it indicates whether one or more options are selected.
 *
 * @see Docs https://chakra-ui.com/checkbox
 */
export function CheckboxGroup(props: CheckboxGroupProps) {
  const { colorScheme, size, variant, children } = props;
  const { value, onChange } = useCheckboxGroup(props);

  const group = useMemo(
    () => ({
      size,
      onChange,
      colorScheme,
      value,
      variant,
    }),
    [size, onChange, colorScheme, value, variant],
  );

  return (
    <CheckboxGroupContextProvider value={group}>
      {children}
    </CheckboxGroupContextProvider>
  );
}

if (__DEV__) {
  CheckboxGroup.displayName = 'CheckboxGroup';
}

interface FinalFormCheckboxGroupProps
  extends FieldRenderProps<any, HTMLInputElement>,
    CheckboxGroupProps {
  hideTouchedError?: boolean;
  helperText?: string;
  label?: string;
  hideHelperText?: boolean;
  fullWidth?: boolean;
  wrapperProps?: Partial<FormControlWrapperProps>;
}

export function FinalFormCheckboxGroup({
  meta,
  input,
  hideTouchedError,
  helperText,
  label,
  hideHelperText,
  fullWidth,
  wrapperProps = {},
  ...rest
}: FinalFormCheckboxGroupProps) {
  const { name, value, ...restInput } = input;
  const { error, submitFailed } = meta;
  const showError =
    ((meta.touched && !hideTouchedError) || submitFailed) && error;

  return (
    <FormControlWrapper
      showError={showError}
      error={error}
      helperText={hideHelperText ? undefined : helperText}
      label={label}
      name={name}
      {...wrapperProps}
    >
      <CheckboxGroup {...rest} {...restInput} />
    </FormControlWrapper>
  );
}

if (__DEV__) {
  FinalFormCheckboxGroup.displayName = 'FinalFormCheckboxGroup';
}
