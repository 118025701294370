import { ReactNode } from 'react';

import {
  Box,
  BoxProps,
  Center,
  Heading__deprecated,
  Text,
} from '../layout';

interface EmptyStateProps extends Omit<BoxProps, 'title'> {
  title: string | ReactNode;
  description: string | ReactNode;
  image?: ReactNode;
  descriptionMaxW?: number | string;
}

export function EmptyState({
  title,
  description,
  children,
  image,
  descriptionMaxW = 400,
  ...rest
}: EmptyStateProps) {
  return (
    <Center flexDirection="column" maxW={500} {...rest}>
      {image && <Box mb={6}>{image}</Box>}
      <Heading__deprecated
        fontWeight={600}
        mb={2}
        size="xl"
        lineHeight={1.4}
        textAlign="center"
      >
        {title}
      </Heading__deprecated>
      <Text
        maxW={descriptionMaxW}
        mx="auto"
        textAlign="center"
        color="gray.500"
        fontSize="xl"
      >
        {description}
      </Text>
      {children && <Box mt={6}>{children}</Box>}
    </Center>
  );
}
