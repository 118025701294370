import { Logger } from '@flick-tech/logger';
import {
  Constants,
  isMobile,
  isRunningOnServerSide,
  PersistentStore,
  StoreKey,
} from '@flick-tech/shared-common';

import ItlyWeb, { ContextProperties } from './itly';

export * from './itly';

let hasInitialized = false;

const getDesktopHashtagsView = () => {
  const value = PersistentStore.get(StoreKey.DESKTOP_HASHTAGS_VIEW) as string;

  if ((value as any) === 'undefined') {
    return 'groups';
  }

  return value || 'none';
};

export const initItlyWeb = () => {
  if (!hasInitialized && !isRunningOnServerSide()) {
    try {
      ItlyWeb.init({
        environment: Constants.isProd ? 'production' : 'development',
        disabled: Constants.isTest,
        logger: {
          info: (message, ...rest) => {
            console.log(message, rest);
          },
          error: (message) => Logger.error(new Error(message)),
          debug: (message) => {
            console.log(message);
          },
          warn: (message) => {
            console.log(message);
          },
        },
        context: () => ({
          device: (isMobile()
            ? 'mobile'
            : 'desktop') as ContextProperties['device'],
          sourceUrl: window.location.href,
          sourcePathname: window.location.pathname,
          desktopHashtagsView: getDesktopHashtagsView(),
        }),
      });
    } catch (error) {
      console.error(error);
    }

    hasInitialized = true;
  }
};

export { ItlyWeb };
