import {
  documentToReactComponents,
  Options as RichTextRendererOptions,
} from '@contentful/rich-text-react-renderer';
import {
  Block,
  BLOCKS,
  Document,
  Inline,
  INLINES,
  MARKS,
  Text as ContentfulText,
} from '@contentful/rich-text-types';
import * as React from 'react';
import YouTube from 'react-youtube';

import { isRunningOnServerSide } from '@flick-tech/shared-common';
import { chakra } from '@flick-tech/theme-new';

import { Image } from '../../image';
import { Box, Link, LinkProps, Text } from '../../layout';
import { Skeleton } from '../../skeleton';

import { RteBody, RteH1, RteH2 } from './RTETypography';

const empty = () => <></>;

const YOUTUBE_URL_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

function isEmptyText(child: Block | Inline | ContentfulText): boolean {
  return child.nodeType === 'text' && child.value.trim() === '';
}

export function isEmptyRichText(
  node: Document | Block | Inline | ContentfulText,
): boolean {
  if ('value' in node) {
    return node.value.trim() === '';
  }
  if ('content' in node) {
    return node.content.every(isEmptyRichText);
  }

  return false;
}

function isYouTubeLink(child: Block | Inline | ContentfulText): boolean {
  return (
    (child.nodeType === 'text' && YOUTUBE_URL_REGEX.test(child.value)) ||
    (child.nodeType === INLINES.HYPERLINK &&
      typeof child.data.uri === 'string' &&
      YOUTUBE_URL_REGEX.test(child.data.uri) &&
      // we don't embed links to YouTube with text content
      (child.content[0] as ContentfulText)?.value === child.data.uri)
  );
}

function hasOnlyYoutubeHyperlinksAndEmptyTexts(paragraph: Block) {
  return paragraph.content.every(
    (child) => isEmptyText(child) || isYouTubeLink(child),
  );
}

// TODO: Use the generic version from help-center-sync
function transformSpecialParagraphs(
  node: Block | Inline,
  _children: React.ReactNode,
) {
  if (node.nodeType !== BLOCKS.PARAGRAPH) {
    console.warn('node has to be a paragraph');
    return null;
  }

  if (hasOnlyYoutubeHyperlinksAndEmptyTexts(node)) {
    const hyperlink = node.content.find(isYouTubeLink);

    // otherwise it's an empty paragraph
    if (hyperlink) {
      const uri =
        hyperlink.nodeType === 'text'
          ? hyperlink.value
          : (hyperlink.data.uri as string);

      const match = (uri || '').match(YOUTUBE_URL_REGEX);

      const videoId = match?.[5];

      return (
        videoId && (
          <Box my="8">
            <YouTube
              videoId={videoId}
              opts={{
                width: '100%',
                height: '400',
                playerVars: {
                  // https://developers.google.com/youtube/player_parameters
                  showinfo: 0,
                  modestbranding: 1,
                  rel: 0,
                  origin: isRunningOnServerSide() ? '' : window.location.origin,
                },
              }}
            />
          </Box>
        )
      );
    }
  }

  return null;
}

const Bold = ({ children }: { children: React.ReactNode }) => (
  <RteBody fontWeight={600} color="inherit" display="inline" as="b">
    {children}
  </RteBody>
);

const Italic = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize="inherit" as="i">
    {children}
  </Text>
);

const UnderlinedLink = (props: LinkProps) => (
  <Link _hover={{ textDecoration: 'underline' }} color="brand.500" {...props} />
);

const options: RichTextRendererOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => {
      return <UnderlinedLink href={data?.uri}>{children}</UnderlinedLink>;
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const transformed = transformSpecialParagraphs(node, children);

      return transformed || <RteBody>{children}</RteBody>;
    },
    [BLOCKS.HEADING_1]: (node, children) => <RteH1>{children}</RteH1>,
    [BLOCKS.HEADING_2]: (node, children) => (
      <RteH2 fontFamily="body">{children}</RteH2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <RteH1 as="h3" fontWeight={600} fontSize="2xl" fontFamily="body">
        {children}
      </RteH1>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <RteH1
        textTransform="uppercase"
        as="h4"
        fontSize="xl"
        color="brand.500"
        letterSpacing="1.3px"
        fontFamily="body"
      >
        {children}
      </RteH1>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <chakra.ul pl={4} listStyleType="disc" mb={4}>
        {children}
      </chakra.ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <chakra.ol pl={4} mb={4}>
        {children}
      </chakra.ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <chakra.li py={2}>{children}</chakra.li>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const contentType = node?.data?.target?.fields?.file?.contentType;
      const url = node?.data?.target?.fields?.file?.url;

      if (url && contentType?.includes('image')) {
        return <Image src={url} />;
      }

      if (node?.data?.asset) {
        const { url, description, width, height } = node.data
          .asset as EmbeddedAssetInfo;

        const MAX_WIDTH = 704;
        return (
          <Image
            fallback={
              <Skeleton
                width={width}
                height={
                  width > MAX_WIDTH ? (height * MAX_WIDTH) / width : height
                }
                maxWidth="100%"
                startColor="gray.50"
                endColor="gray.100"
              />
            }
            htmlWidth={width}
            htmlHeight={height}
            maxWidth="100%"
            height="auto"
            src={url}
            alt={description}
            my="5"
            mx="auto"
            objectFit="cover"
            borderRadius="12px"
            border="none"
          />
        );
      }

      if (process.env.NODE_ENV === 'development') {
        console.warn('embedded asset not handled', node);
      }

      return <></>;
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return <UnderlinedLink {...node.data.props}>{children}</UnderlinedLink>;
    },
    [INLINES.ASSET_HYPERLINK]: empty,
    [INLINES.EMBEDDED_ENTRY]: empty,
    [INLINES.EMBEDDED_ENTRY]: empty,
  },
  renderText: (text) => {
    return text
      .split('\n')
      .reduce((children: string[], textSegment: any, index: number) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
  },
};

export const renderRichText = (document: Document) =>
  documentToReactComponents(document, options);

export type EmbeddedAssetInfo = {
  width: number;
  height: number;
  url: string;
  description: string;
};
