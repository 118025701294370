import { RectangleProps } from 'recharts';

export interface RectShapeProps extends RectangleProps {
  value: number;
  _empty?: string[];
}

export const makeBarShape =
  ({ horizontal = false, key = '' } = {}) =>
  ({ fill, x, y, width, height, _empty }: RectShapeProps) => {
    const isMissingData = _empty?.includes(key);
    const opposite = horizontal ? width < 0 : height < 0;

    if (height < 0) {
      height = Math.abs(height);
      y -= height;
    }
    if (width < 0) {
      width = Math.abs(width);
      x -= width;
    }
    if (width === 0 || height === 0) {
      return null;
    }

    const maskId = `mask-${Math.round(width)}-${Math.round(height)}`;
    const missingPatterId =
      isMissingData && `bar-stripes-${fill.replace(/[\(\)\,\.\#]/g, '-')}`;

    const borderRadius = (horizontal ? height : width) / 2;

    let direction = '';
    if (horizontal) {
      direction = opposite ? 'left' : 'right';
    } else {
      direction = opposite ? 'bottom' : 'top';
    }

    const barRectProps = {
      x: 0,
      y: 0,
      rx: borderRadius,
      ...(horizontal
        ? {
            width: width + borderRadius,
            height,
          }
        : {
            width,
            height: height + borderRadius,
          }),
    };

    switch (direction) {
      case 'bottom':
        barRectProps.y = -borderRadius;
        break;
      case 'right':
        barRectProps.x = -borderRadius;
        break;
    }

    return (
      <g transform={`translate(${x}, ${y})`}>
        {isMissingData && (
          <defs>
            <pattern
              id={missingPatterId}
              patternUnits="userSpaceOnUse"
              width="13"
              height="13"
              patternTransform="rotate(45)"
            >
              <line
                x1="0"
                y="0"
                x2="0"
                y2="13"
                stroke={fill}
                strokeWidth="15"
              />
            </pattern>
          </defs>
        )}
        <defs>
          {/* Essentially overflow: hidden but for svg */}
          <mask id={maskId}>
            <rect width={width} height={height} fill="#fff" />
          </mask>
        </defs>
        <g mask={`url(#${maskId})`}>
          <rect
            {...barRectProps}
            fill={isMissingData ? `url(#${missingPatterId})` : fill}
            opacity={isMissingData ? 0.6 : 1}
          />
        </g>
      </g>
    );
  };
