import { AmplitudeClient } from 'amplitude-js';
import Cookies from 'js-cookie';

import { Logger } from '@flick-tech/logger';
import {
  Constants,
  getChromeVersion,
  isRunningOnServerSide,
  stripEmpties,
} from '@flick-tech/shared-common';

let amplitude;

const amplitudeDeviceIdCookieKey = 'flick_amplitudeDeviceId';

function onAmplitudeInit(instance) {
  const deviceIdFromCookies = Cookies.get(amplitudeDeviceIdCookieKey);

  if (!deviceIdFromCookies) {
    // Set the cookie so other flick subdomains
    Cookies.set(
      amplitudeDeviceIdCookieKey,
      instance.options.deviceId,
      Constants.isDev
        ? {}
        : {
            // IMPORTANT to be able to read this cookie on *.flick.tech
            domain: 'flick.tech',
          },
    );
  }
}

const IS_UNIT_TEST = !!process.env.JEST_WORKER_ID;

const getClient = (): AmplitudeClient => amplitude.getInstance();

export class Amplitude {
  static init() {
    if (isRunningOnServerSide() || IS_UNIT_TEST) return;

    try {
      const deviceIdFromCookies = Cookies.get(amplitudeDeviceIdCookieKey);

      const params: {
        includeReferrer: boolean;
        includeUtm: boolean;
        deviceId?: string;
      } = {
        includeReferrer: true,
        includeUtm: true,
      };

      if (deviceIdFromCookies) {
        params.deviceId = deviceIdFromCookies;
      }

      amplitude
        .getInstance()
        .init(Constants.amplitudeId, null, params, onAmplitudeInit);
    } catch (error) {
      Logger.error(error);
    }
  }

  static trackEvent(name: string, payload?: any, callback?: () => void) {
    if (isRunningOnServerSide()) return;

    try {
      if (payload && Object.values(stripEmpties(payload)).length > 0) {
        amplitude.getInstance().logEvent(name, stripEmpties(payload), callback);
        return;
      }

      amplitude.getInstance().logEvent(name, null, callback);
    } catch (error) {
      Logger.error(error);
    }
  }

  static setUserProperties(properties: any) {
    if (isRunningOnServerSide()) return;

    const payload = stripEmpties(properties);

    if (getChromeVersion()) {
      payload.chromeVersion = getChromeVersion();
    }

    if (payload.id && payload.id !== '') {
      try {
        amplitude.getInstance().setUserId(payload.id);
      } catch (error) {
        Logger.error(error);
      }
    }

    try {
      getClient().setUserProperties(payload);
    } catch (error) {
      Logger.error(error);
    }
  }

  static setUserPropertiesOnce(properties: any) {
    if (isRunningOnServerSide()) return;

    const payload = stripEmpties(properties);

    try {
      const identify = new amplitude.Identify();

      Object.entries(payload).forEach(([key, value]) =>
        identify.setOnce(key, value),
      );

      amplitude.getInstance().identify(identify);
    } catch (error) {
      Logger.error(error);
    }
  }

  // TODO: Rename identify.append -> identify.set safely
  static identify(props: object) {
    if (isRunningOnServerSide()) return;

    try {
      const identify = new amplitude.Identify();

      Object.entries(props).forEach(([k, v]) => identify.append(k, v));

      amplitude.getInstance().identify(identify);
    } catch (error) {
      Logger.error(error);
    }
  }

  static append(props: object) {
    if (isRunningOnServerSide()) return;

    try {
      const identify = new amplitude.Identify();

      Object.entries(props).forEach(([k, v]) => identify.append(k, v));

      amplitude.getInstance().identify(identify);
    } catch (error) {
      Logger.error(error);
    }
  }
}

if (!isRunningOnServerSide()) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  amplitude = require('amplitude-js');
  Amplitude.init();
}
