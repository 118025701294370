// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
// @ts-ignore
var fastDeepEqual = function equal(a, b) {
  if (a === b) return !0;
  if (a && b && 'object' == typeof a && 'object' == typeof b) {
    if (a.constructor !== b.constructor) return !1;
    var length, i, keys;
    if (Array.isArray(a)) {
      if ((length = a.length) != b.length) return !1;
      for (i = length; 0 != i--; ) if (!equal(a[i], b[i])) return !1;
      return !0;
    }
    if (a.constructor === RegExp)
      return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf)
      return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString)
      return a.toString() === b.toString();
    if ((length = (keys = Object.keys(a)).length) !== Object.keys(b).length)
      return !1;
    for (i = length; 0 != i--; )
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return !1;
    for (i = length; 0 != i--; ) {
      var key = keys[i];
      if (!equal(a[key], b[key])) return !1;
    }
    return !0;
  }
  return a != a && b != b;
};

// @ts-ignore

// @ts-ignore
var equal = fastDeepEqual;

// @ts-ignore (./Context)
var validate = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (
      'desktopHashtagsView' != key0 &&
      'sourceUrl' != key0 &&
      'device' != key0 &&
      'sourcePathname' != key0
    )
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.desktopHashtagsView)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'desktopHashtagsView' },
            message: "should have required property 'desktopHashtagsView'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('string' != typeof data.desktopHashtagsView)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.desktopHashtagsView',
            schemaPath: '#/properties/desktopHashtagsView/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.sourceUrl)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'sourceUrl' },
              message: "should have required property 'sourceUrl'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'string' != typeof data.sourceUrl))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.sourceUrl',
              schemaPath: '#/properties/sourceUrl/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.device)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'device' },
                message: "should have required property 'device'",
              },
            ]),
            !1
          );
        errs_1 = 0;
        var schema1 = validate.schema.properties.device.enum;
        valid1 = !1;
        for (var i1 = 0; i1 < schema1.length; i1++)
          if (equal(data.device, schema1[i1])) {
            valid1 = !0;
            break;
          }
        if (!valid1)
          return (
            (validate.errors = [
              {
                keyword: 'enum',
                dataPath: (dataPath || '') + '.device',
                schemaPath: '#/properties/device/enum',
                params: { allowedValues: schema1 },
                message: 'should be equal to one of the allowed values',
              },
            ]),
            !1
          );
        if ((valid1 = 0 === errs_1)) {
          if (void 0 === data.sourcePathname)
            return (
              (valid1 = !1),
              (validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: { missingProperty: 'sourcePathname' },
                  message: "should have required property 'sourcePathname'",
                },
              ]),
              !1
            );
          if (((errs_1 = 0), 'string' != typeof data.sourcePathname))
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.sourcePathname',
                  schemaPath: '#/properties/sourcePathname/type',
                  params: { type: 'string' },
                  message: 'should be string',
                },
              ]),
              !1
            );
          valid1 = 0 === errs_1;
        }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/context',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Context',
  description: '',
  type: 'object',
  properties: {
    desktopHashtagsView: { description: '', type: 'string' },
    sourceUrl: { description: '', type: 'string' },
    device: { description: '', enum: ['mobile', 'desktop', 'app'] },
    sourcePathname: { description: '', type: 'string' },
  },
  additionalProperties: !1,
  required: ['desktopHashtagsView', 'sourceUrl', 'device', 'sourcePathname'],
}),
  (validate.errors = null);

// @ts-ignore (./Identify)
var validate$1 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (!validate.schema.properties.hasOwnProperty(key0))
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.hasSetPassword) valid1 = !0;
    else {
      var errs_1 = 0;
      if ('boolean' != typeof data.hasSetPassword)
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.hasSetPassword',
              schemaPath: '#/properties/hasSetPassword/type',
              params: { type: 'boolean' },
              message: 'should be boolean',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
    if (valid1) {
      if (void 0 === data.utm_source) valid1 = !0;
      else {
        if (((errs_1 = 0), 'string' != typeof data.utm_source))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.utm_source',
                schemaPath: '#/properties/utm_source/type',
                params: { type: 'string' },
                message: 'should be string',
              },
            ]),
            !1
          );
        valid1 = 0 === errs_1;
      }
      if (valid1) {
        if (void 0 === data.utm_term) valid1 = !0;
        else {
          if (((errs_1 = 0), 'string' != typeof data.utm_term))
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.utm_term',
                  schemaPath: '#/properties/utm_term/type',
                  params: { type: 'string' },
                  message: 'should be string',
                },
              ]),
              !1
            );
          valid1 = 0 === errs_1;
        }
        if (valid1) {
          if (void 0 === (data1 = data.activeAddons)) valid1 = !0;
          else {
            if (((errs_1 = 0), !Array.isArray(data1)))
              return (
                (validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.activeAddons',
                    schemaPath: '#/properties/activeAddons/type',
                    params: { type: 'array' },
                    message: 'should be array',
                  },
                ]),
                !1
              );
            for (var i1 = 0; i1 < data1.length; i1++) {
              var errs_2 = 0;
              if ('string' != typeof data1[i1])
                return (
                  (validate.errors = [
                    {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.activeAddons[' + i1 + ']',
                      schemaPath: '#/properties/activeAddons/items/type',
                      params: { type: 'string' },
                      message: 'should be string',
                    },
                  ]),
                  !1
                );
              if (0 !== errs_2) break;
            }
            valid1 = 0 === errs_1;
          }
          if (valid1) {
            if (void 0 === data.PostingFrequency) valid1 = !0;
            else {
              if (((errs_1 = 0), 'string' != typeof data.PostingFrequency))
                return (
                  (validate.errors = [
                    {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.PostingFrequency',
                      schemaPath: '#/properties/PostingFrequency/type',
                      params: { type: 'string' },
                      message: 'should be string',
                    },
                  ]),
                  !1
                );
              valid1 = 0 === errs_1;
            }
            if (valid1) {
              if (void 0 === data.Plan) valid1 = !0;
              else {
                if (((errs_1 = 0), 'string' != typeof data.Plan))
                  return (
                    (validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.Plan',
                        schemaPath: '#/properties/Plan/type',
                        params: { type: 'string' },
                        message: 'should be string',
                      },
                    ]),
                    !1
                  );
                valid1 = 0 === errs_1;
              }
              if (valid1) {
                if (void 0 === data.referrerUrl) valid1 = !0;
                else {
                  if (((errs_1 = 0), 'string' != typeof data.referrerUrl))
                    return (
                      (validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.referrerUrl',
                          schemaPath: '#/properties/referrerUrl/type',
                          params: { type: 'string' },
                          message: 'should be string',
                        },
                      ]),
                      !1
                    );
                  valid1 = 0 === errs_1;
                }
                if (valid1) {
                  if (void 0 === data['initial_utm_content ']) valid1 = !0;
                  else {
                    if (
                      ((errs_1 = 0),
                      'string' != typeof data['initial_utm_content '])
                    )
                      return (
                        (validate.errors = [
                          {
                            keyword: 'type',
                            dataPath:
                              (dataPath || '') + "['initial_utm_content ']",
                            schemaPath:
                              '#/properties/initial_utm_content%20/type',
                            params: { type: 'string' },
                            message: 'should be string',
                          },
                        ]),
                        !1
                      );
                    valid1 = 0 === errs_1;
                  }
                  if (valid1) {
                    if (void 0 === data.SelectedHashtagsExperiment) valid1 = !0;
                    else {
                      if (
                        ((errs_1 = 0),
                        'string' != typeof data.SelectedHashtagsExperiment)
                      )
                        return (
                          (validate.errors = [
                            {
                              keyword: 'type',
                              dataPath:
                                (dataPath || '') +
                                '.SelectedHashtagsExperiment',
                              schemaPath:
                                '#/properties/SelectedHashtagsExperiment/type',
                              params: { type: 'string' },
                              message: 'should be string',
                            },
                          ]),
                          !1
                        );
                      valid1 = 0 === errs_1;
                    }
                    if (valid1) {
                      if (void 0 === data.lastReceivedNotification) valid1 = !0;
                      else {
                        if (
                          ((errs_1 = 0),
                          'string' != typeof data.lastReceivedNotification)
                        )
                          return (
                            (validate.errors = [
                              {
                                keyword: 'type',
                                dataPath:
                                  (dataPath || '') +
                                  '.lastReceivedNotification',
                                schemaPath:
                                  '#/properties/lastReceivedNotification/type',
                                params: { type: 'string' },
                                message: 'should be string',
                              },
                            ]),
                            !1
                          );
                        valid1 = 0 === errs_1;
                      }
                      if (valid1) {
                        if (void 0 === data.ReferralTrialConversions)
                          valid1 = !0;
                        else {
                          if (
                            ((errs_1 = 0),
                            'string' != typeof data.ReferralTrialConversions)
                          )
                            return (
                              (validate.errors = [
                                {
                                  keyword: 'type',
                                  dataPath:
                                    (dataPath || '') +
                                    '.ReferralTrialConversions',
                                  schemaPath:
                                    '#/properties/ReferralTrialConversions/type',
                                  params: { type: 'string' },
                                  message: 'should be string',
                                },
                              ]),
                              !1
                            );
                          valid1 = 0 === errs_1;
                        }
                        if (valid1) {
                          if (void 0 === data.utm_campaign) valid1 = !0;
                          else {
                            if (
                              ((errs_1 = 0),
                              'string' != typeof data.utm_campaign)
                            )
                              return (
                                (validate.errors = [
                                  {
                                    keyword: 'type',
                                    dataPath:
                                      (dataPath || '') + '.utm_campaign',
                                    schemaPath:
                                      '#/properties/utm_campaign/type',
                                    params: { type: 'string' },
                                    message: 'should be string',
                                  },
                                ]),
                                !1
                              );
                            valid1 = 0 === errs_1;
                          }
                          if (valid1) {
                            if (void 0 === data.initial_utm_source) valid1 = !0;
                            else {
                              if (
                                ((errs_1 = 0),
                                'string' != typeof data.initial_utm_source)
                              )
                                return (
                                  (validate.errors = [
                                    {
                                      keyword: 'type',
                                      dataPath:
                                        (dataPath || '') +
                                        '.initial_utm_source',
                                      schemaPath:
                                        '#/properties/initial_utm_source/type',
                                      params: { type: 'string' },
                                      message: 'should be string',
                                    },
                                  ]),
                                  !1
                                );
                              valid1 = 0 === errs_1;
                            }
                            if (valid1) {
                              if (void 0 === data.keyAction) valid1 = !0;
                              else {
                                errs_1 = 0;
                                var schema1 =
                                  validate.schema.properties.keyAction.enum;
                                for (
                                  valid1 = !1, i1 = 0;
                                  i1 < schema1.length;
                                  i1++
                                )
                                  if (equal(data.keyAction, schema1[i1])) {
                                    valid1 = !0;
                                    break;
                                  }
                                if (!valid1)
                                  return (
                                    (validate.errors = [
                                      {
                                        keyword: 'enum',
                                        dataPath:
                                          (dataPath || '') + '.keyAction',
                                        schemaPath:
                                          '#/properties/keyAction/enum',
                                        params: { allowedValues: schema1 },
                                        message:
                                          'should be equal to one of the allowed values',
                                      },
                                    ]),
                                    !1
                                  );
                                valid1 = 0 === errs_1;
                              }
                              if (valid1) {
                                if (void 0 === data.lastName) valid1 = !0;
                                else {
                                  if (
                                    ((errs_1 = 0),
                                    'string' != typeof data.lastName)
                                  )
                                    return (
                                      (validate.errors = [
                                        {
                                          keyword: 'type',
                                          dataPath:
                                            (dataPath || '') + '.lastName',
                                          schemaPath:
                                            '#/properties/lastName/type',
                                          params: { type: 'string' },
                                          message: 'should be string',
                                        },
                                      ]),
                                      !1
                                    );
                                  valid1 = 0 === errs_1;
                                }
                                if (valid1) {
                                  if (void 0 === data.id) valid1 = !0;
                                  else {
                                    if (
                                      ((errs_1 = 0), 'string' != typeof data.id)
                                    )
                                      return (
                                        (validate.errors = [
                                          {
                                            keyword: 'type',
                                            dataPath: (dataPath || '') + '.id',
                                            schemaPath: '#/properties/id/type',
                                            params: { type: 'string' },
                                            message: 'should be string',
                                          },
                                        ]),
                                        !1
                                      );
                                    valid1 = 0 === errs_1;
                                  }
                                  if (valid1) {
                                    if (void 0 === data.OnboardingNumAccounts)
                                      valid1 = !0;
                                    else {
                                      if (
                                        ((errs_1 = 0),
                                        'string' !=
                                          typeof data.OnboardingNumAccounts)
                                      )
                                        return (
                                          (validate.errors = [
                                            {
                                              keyword: 'type',
                                              dataPath:
                                                (dataPath || '') +
                                                '.OnboardingNumAccounts',
                                              schemaPath:
                                                '#/properties/OnboardingNumAccounts/type',
                                              params: { type: 'string' },
                                              message: 'should be string',
                                            },
                                          ]),
                                          !1
                                        );
                                      valid1 = 0 === errs_1;
                                    }
                                    if (valid1) {
                                      if (void 0 === data.firstName)
                                        valid1 = !0;
                                      else {
                                        if (
                                          ((errs_1 = 0),
                                          'string' != typeof data.firstName)
                                        )
                                          return (
                                            (validate.errors = [
                                              {
                                                keyword: 'type',
                                                dataPath:
                                                  (dataPath || '') +
                                                  '.firstName',
                                                schemaPath:
                                                  '#/properties/firstName/type',
                                                params: { type: 'string' },
                                                message: 'should be string',
                                              },
                                            ]),
                                            !1
                                          );
                                        valid1 = 0 === errs_1;
                                      }
                                      if (valid1) {
                                        if (void 0 === data.affiliateId)
                                          valid1 = !0;
                                        else {
                                          if (
                                            ((errs_1 = 0),
                                            'string' != typeof data.affiliateId)
                                          )
                                            return (
                                              (validate.errors = [
                                                {
                                                  keyword: 'type',
                                                  dataPath:
                                                    (dataPath || '') +
                                                    '.affiliateId',
                                                  schemaPath:
                                                    '#/properties/affiliateId/type',
                                                  params: { type: 'string' },
                                                  message: 'should be string',
                                                },
                                              ]),
                                              !1
                                            );
                                          valid1 = 0 === errs_1;
                                        }
                                        if (valid1) {
                                          if (
                                            void 0 ===
                                            (data1 = data.LinkedIGAccounts)
                                          )
                                            valid1 = !0;
                                          else {
                                            if (
                                              ((errs_1 = 0),
                                              !Array.isArray(data1))
                                            )
                                              return (
                                                (validate.errors = [
                                                  {
                                                    keyword: 'type',
                                                    dataPath:
                                                      (dataPath || '') +
                                                      '.LinkedIGAccounts',
                                                    schemaPath:
                                                      '#/properties/LinkedIGAccounts/type',
                                                    params: { type: 'array' },
                                                    message: 'should be array',
                                                  },
                                                ]),
                                                !1
                                              );
                                            for (
                                              i1 = 0;
                                              i1 < data1.length;
                                              i1++
                                            ) {
                                              if (
                                                ((errs_2 = 0),
                                                'string' != typeof data1[i1])
                                              )
                                                return (
                                                  (validate.errors = [
                                                    {
                                                      keyword: 'type',
                                                      dataPath:
                                                        (dataPath || '') +
                                                        '.LinkedIGAccounts[' +
                                                        i1 +
                                                        ']',
                                                      schemaPath:
                                                        '#/properties/LinkedIGAccounts/items/type',
                                                      params: {
                                                        type: 'string',
                                                      },
                                                      message:
                                                        'should be string',
                                                    },
                                                  ]),
                                                  !1
                                                );
                                              if (0 !== errs_2) break;
                                            }
                                            valid1 = 0 === errs_1;
                                          }
                                          if (valid1) {
                                            if (void 0 === data.LTV)
                                              valid1 = !0;
                                            else {
                                              if (
                                                ((errs_1 = 0),
                                                'string' != typeof data.LTV)
                                              )
                                                return (
                                                  (validate.errors = [
                                                    {
                                                      keyword: 'type',
                                                      dataPath:
                                                        (dataPath || '') +
                                                        '.LTV',
                                                      schemaPath:
                                                        '#/properties/LTV/type',
                                                      params: {
                                                        type: 'string',
                                                      },
                                                      message:
                                                        'should be string',
                                                    },
                                                  ]),
                                                  !1
                                                );
                                              valid1 = 0 === errs_1;
                                            }
                                            if (valid1) {
                                              if (void 0 === data.userId)
                                                valid1 = !0;
                                              else {
                                                if (
                                                  ((errs_1 = 0),
                                                  'string' !=
                                                    typeof data.userId)
                                                )
                                                  return (
                                                    (validate.errors = [
                                                      {
                                                        keyword: 'type',
                                                        dataPath:
                                                          (dataPath || '') +
                                                          '.userId',
                                                        schemaPath:
                                                          '#/properties/userId/type',
                                                        params: {
                                                          type: 'string',
                                                        },
                                                        message:
                                                          'should be string',
                                                      },
                                                    ]),
                                                    !1
                                                  );
                                                valid1 = 0 === errs_1;
                                              }
                                              if (valid1) {
                                                if (
                                                  void 0 ===
                                                  data.litePlanExperiment
                                                )
                                                  valid1 = !0;
                                                else {
                                                  for (
                                                    errs_1 = 0,
                                                      schema1 =
                                                        validate.schema
                                                          .properties
                                                          .litePlanExperiment
                                                          .enum,
                                                      valid1 = !1,
                                                      i1 = 0;
                                                    i1 < schema1.length;
                                                    i1++
                                                  )
                                                    if (
                                                      equal(
                                                        data.litePlanExperiment,
                                                        schema1[i1],
                                                      )
                                                    ) {
                                                      valid1 = !0;
                                                      break;
                                                    }
                                                  if (!valid1)
                                                    return (
                                                      (validate.errors = [
                                                        {
                                                          keyword: 'enum',
                                                          dataPath:
                                                            (dataPath || '') +
                                                            '.litePlanExperiment',
                                                          schemaPath:
                                                            '#/properties/litePlanExperiment/enum',
                                                          params: {
                                                            allowedValues:
                                                              schema1,
                                                          },
                                                          message:
                                                            'should be equal to one of the allowed values',
                                                        },
                                                      ]),
                                                      !1
                                                    );
                                                  valid1 = 0 === errs_1;
                                                }
                                                if (valid1) {
                                                  if (void 0 === data.email)
                                                    valid1 = !0;
                                                  else {
                                                    if (
                                                      ((errs_1 = 0),
                                                      'string' !=
                                                        typeof data.email)
                                                    )
                                                      return (
                                                        (validate.errors = [
                                                          {
                                                            keyword: 'type',
                                                            dataPath:
                                                              (dataPath || '') +
                                                              '.email',
                                                            schemaPath:
                                                              '#/properties/email/type',
                                                            params: {
                                                              type: 'string',
                                                            },
                                                            message:
                                                              'should be string',
                                                          },
                                                        ]),
                                                        !1
                                                      );
                                                    valid1 = 0 === errs_1;
                                                  }
                                                  if (valid1) {
                                                    if (
                                                      void 0 ===
                                                      data.initial_utm_medium
                                                    )
                                                      valid1 = !0;
                                                    else {
                                                      if (
                                                        ((errs_1 = 0),
                                                        'string' !=
                                                          typeof data.initial_utm_medium)
                                                      )
                                                        return (
                                                          (validate.errors = [
                                                            {
                                                              keyword: 'type',
                                                              dataPath:
                                                                (dataPath ||
                                                                  '') +
                                                                '.initial_utm_medium',
                                                              schemaPath:
                                                                '#/properties/initial_utm_medium/type',
                                                              params: {
                                                                type: 'string',
                                                              },
                                                              message:
                                                                'should be string',
                                                            },
                                                          ]),
                                                          !1
                                                        );
                                                      valid1 = 0 === errs_1;
                                                    }
                                                    if (valid1) {
                                                      if (
                                                        void 0 ===
                                                        data.isSubscribedToBrowserNotifications
                                                      )
                                                        valid1 = !0;
                                                      else {
                                                        if (
                                                          ((errs_1 = 0),
                                                          'boolean' !=
                                                            typeof data.isSubscribedToBrowserNotifications)
                                                        )
                                                          return (
                                                            (validate.errors = [
                                                              {
                                                                keyword: 'type',
                                                                dataPath:
                                                                  (dataPath ||
                                                                    '') +
                                                                  '.isSubscribedToBrowserNotifications',
                                                                schemaPath:
                                                                  '#/properties/isSubscribedToBrowserNotifications/type',
                                                                params: {
                                                                  type: 'boolean',
                                                                },
                                                                message:
                                                                  'should be boolean',
                                                              },
                                                            ]),
                                                            !1
                                                          );
                                                        valid1 = 0 === errs_1;
                                                      }
                                                      if (valid1) {
                                                        if (
                                                          void 0 ===
                                                          data.trialEndDate
                                                        )
                                                          valid1 = !0;
                                                        else {
                                                          if (
                                                            ((errs_1 = 0),
                                                            'string' !=
                                                              typeof data.trialEndDate)
                                                          )
                                                            return (
                                                              (validate.errors =
                                                                [
                                                                  {
                                                                    keyword:
                                                                      'type',
                                                                    dataPath:
                                                                      (dataPath ||
                                                                        '') +
                                                                      '.trialEndDate',
                                                                    schemaPath:
                                                                      '#/properties/trialEndDate/type',
                                                                    params: {
                                                                      type: 'string',
                                                                    },
                                                                    message:
                                                                      'should be string',
                                                                  },
                                                                ]),
                                                              !1
                                                            );
                                                          valid1 = 0 === errs_1;
                                                        }
                                                        if (valid1) {
                                                          if (
                                                            void 0 ===
                                                            data.utm_medium
                                                          )
                                                            valid1 = !0;
                                                          else {
                                                            if (
                                                              ((errs_1 = 0),
                                                              'string' !=
                                                                typeof data.utm_medium)
                                                            )
                                                              return (
                                                                (validate.errors =
                                                                  [
                                                                    {
                                                                      keyword:
                                                                        'type',
                                                                      dataPath:
                                                                        (dataPath ||
                                                                          '') +
                                                                        '.utm_medium',
                                                                      schemaPath:
                                                                        '#/properties/utm_medium/type',
                                                                      params: {
                                                                        type: 'string',
                                                                      },
                                                                      message:
                                                                        'should be string',
                                                                    },
                                                                  ]),
                                                                !1
                                                              );
                                                            valid1 =
                                                              0 === errs_1;
                                                          }
                                                          if (valid1) {
                                                            if (
                                                              void 0 ===
                                                              (data1 =
                                                                data.features)
                                                            )
                                                              valid1 = !0;
                                                            else {
                                                              if (
                                                                ((errs_1 = 0),
                                                                !Array.isArray(
                                                                  data1,
                                                                ))
                                                              )
                                                                return (
                                                                  (validate.errors =
                                                                    [
                                                                      {
                                                                        keyword:
                                                                          'type',
                                                                        dataPath:
                                                                          (dataPath ||
                                                                            '') +
                                                                          '.features',
                                                                        schemaPath:
                                                                          '#/properties/features/type',
                                                                        params:
                                                                          {
                                                                            type: 'array',
                                                                          },
                                                                        message:
                                                                          'should be array',
                                                                      },
                                                                    ]),
                                                                  !1
                                                                );
                                                              for (
                                                                i1 = 0;
                                                                i1 <
                                                                data1.length;
                                                                i1++
                                                              ) {
                                                                if (
                                                                  ((errs_2 = 0),
                                                                  'string' !=
                                                                    typeof data1[
                                                                      i1
                                                                    ])
                                                                )
                                                                  return (
                                                                    (validate.errors =
                                                                      [
                                                                        {
                                                                          keyword:
                                                                            'type',
                                                                          dataPath:
                                                                            (dataPath ||
                                                                              '') +
                                                                            '.features[' +
                                                                            i1 +
                                                                            ']',
                                                                          schemaPath:
                                                                            '#/properties/features/items/type',
                                                                          params:
                                                                            {
                                                                              type: 'string',
                                                                            },
                                                                          message:
                                                                            'should be string',
                                                                        },
                                                                      ]),
                                                                    !1
                                                                  );
                                                                if (
                                                                  0 !== errs_2
                                                                )
                                                                  break;
                                                              }
                                                              valid1 =
                                                                0 === errs_1;
                                                            }
                                                            if (valid1) {
                                                              if (
                                                                void 0 ===
                                                                data.FullName
                                                              )
                                                                valid1 = !0;
                                                              else {
                                                                if (
                                                                  ((errs_1 = 0),
                                                                  'string' !=
                                                                    typeof data.FullName)
                                                                )
                                                                  return (
                                                                    (validate.errors =
                                                                      [
                                                                        {
                                                                          keyword:
                                                                            'type',
                                                                          dataPath:
                                                                            (dataPath ||
                                                                              '') +
                                                                            '.FullName',
                                                                          schemaPath:
                                                                            '#/properties/FullName/type',
                                                                          params:
                                                                            {
                                                                              type: 'string',
                                                                            },
                                                                          message:
                                                                            'should be string',
                                                                        },
                                                                      ]),
                                                                    !1
                                                                  );
                                                                valid1 =
                                                                  0 === errs_1;
                                                              }
                                                              if (valid1) {
                                                                if (
                                                                  void 0 ===
                                                                  data.FirstSeen
                                                                )
                                                                  valid1 = !0;
                                                                else {
                                                                  if (
                                                                    ((errs_1 = 0),
                                                                    'string' !=
                                                                      typeof data.FirstSeen)
                                                                  )
                                                                    return (
                                                                      (validate.errors =
                                                                        [
                                                                          {
                                                                            keyword:
                                                                              'type',
                                                                            dataPath:
                                                                              (dataPath ||
                                                                                '') +
                                                                              '.FirstSeen',
                                                                            schemaPath:
                                                                              '#/properties/FirstSeen/type',
                                                                            params:
                                                                              {
                                                                                type: 'string',
                                                                              },
                                                                            message:
                                                                              'should be string',
                                                                          },
                                                                        ]),
                                                                      !1
                                                                    );
                                                                  valid1 =
                                                                    0 ===
                                                                    errs_1;
                                                                }
                                                                if (valid1) {
                                                                  if (
                                                                    void 0 ===
                                                                    data.NumLinkedIGAccounts
                                                                  )
                                                                    valid1 = !0;
                                                                  else {
                                                                    if (
                                                                      ((errs_1 = 0),
                                                                      'number' !=
                                                                        typeof data.NumLinkedIGAccounts)
                                                                    )
                                                                      return (
                                                                        (validate.errors =
                                                                          [
                                                                            {
                                                                              keyword:
                                                                                'type',
                                                                              dataPath:
                                                                                (dataPath ||
                                                                                  '') +
                                                                                '.NumLinkedIGAccounts',
                                                                              schemaPath:
                                                                                '#/properties/NumLinkedIGAccounts/type',
                                                                              params:
                                                                                {
                                                                                  type: 'number',
                                                                                },
                                                                              message:
                                                                                'should be number',
                                                                            },
                                                                          ]),
                                                                        !1
                                                                      );
                                                                    valid1 =
                                                                      0 ===
                                                                      errs_1;
                                                                  }
                                                                  if (valid1) {
                                                                    if (
                                                                      void 0 ===
                                                                      data.ProfilePicture
                                                                    )
                                                                      valid1 =
                                                                        !0;
                                                                    else {
                                                                      if (
                                                                        ((errs_1 = 0),
                                                                        'string' !=
                                                                          typeof data.ProfilePicture)
                                                                      )
                                                                        return (
                                                                          (validate.errors =
                                                                            [
                                                                              {
                                                                                keyword:
                                                                                  'type',
                                                                                dataPath:
                                                                                  (dataPath ||
                                                                                    '') +
                                                                                  '.ProfilePicture',
                                                                                schemaPath:
                                                                                  '#/properties/ProfilePicture/type',
                                                                                params:
                                                                                  {
                                                                                    type: 'string',
                                                                                  },
                                                                                message:
                                                                                  'should be string',
                                                                              },
                                                                            ]),
                                                                          !1
                                                                        );
                                                                      valid1 =
                                                                        0 ===
                                                                        errs_1;
                                                                    }
                                                                    if (
                                                                      valid1
                                                                    ) {
                                                                      if (
                                                                        void 0 ===
                                                                        data.hasAnalytics
                                                                      )
                                                                        valid1 =
                                                                          !0;
                                                                      else {
                                                                        if (
                                                                          ((errs_1 = 0),
                                                                          'boolean' !=
                                                                            typeof data.hasAnalytics)
                                                                        )
                                                                          return (
                                                                            (validate.errors =
                                                                              [
                                                                                {
                                                                                  keyword:
                                                                                    'type',
                                                                                  dataPath:
                                                                                    (dataPath ||
                                                                                      '') +
                                                                                    '.hasAnalytics',
                                                                                  schemaPath:
                                                                                    '#/properties/hasAnalytics/type',
                                                                                  params:
                                                                                    {
                                                                                      type: 'boolean',
                                                                                    },
                                                                                  message:
                                                                                    'should be boolean',
                                                                                },
                                                                              ]),
                                                                            !1
                                                                          );
                                                                        valid1 =
                                                                          0 ===
                                                                          errs_1;
                                                                      }
                                                                      if (
                                                                        valid1
                                                                      ) {
                                                                        if (
                                                                          void 0 ===
                                                                          data.timezone
                                                                        )
                                                                          valid1 =
                                                                            !0;
                                                                        else {
                                                                          if (
                                                                            ((errs_1 = 0),
                                                                            'string' !=
                                                                              typeof data.timezone)
                                                                          )
                                                                            return (
                                                                              (validate.errors =
                                                                                [
                                                                                  {
                                                                                    keyword:
                                                                                      'type',
                                                                                    dataPath:
                                                                                      (dataPath ||
                                                                                        '') +
                                                                                      '.timezone',
                                                                                    schemaPath:
                                                                                      '#/properties/timezone/type',
                                                                                    params:
                                                                                      {
                                                                                        type: 'string',
                                                                                      },
                                                                                    message:
                                                                                      'should be string',
                                                                                  },
                                                                                ]),
                                                                              !1
                                                                            );
                                                                          valid1 =
                                                                            0 ===
                                                                            errs_1;
                                                                        }
                                                                        if (
                                                                          valid1
                                                                        ) {
                                                                          if (
                                                                            void 0 ===
                                                                            data.initial_referring_domain
                                                                          )
                                                                            valid1 =
                                                                              !0;
                                                                          else {
                                                                            if (
                                                                              ((errs_1 = 0),
                                                                              'string' !=
                                                                                typeof data.initial_referring_domain)
                                                                            )
                                                                              return (
                                                                                (validate.errors =
                                                                                  [
                                                                                    {
                                                                                      keyword:
                                                                                        'type',
                                                                                      dataPath:
                                                                                        (dataPath ||
                                                                                          '') +
                                                                                        '.initial_referring_domain',
                                                                                      schemaPath:
                                                                                        '#/properties/initial_referring_domain/type',
                                                                                      params:
                                                                                        {
                                                                                          type: 'string',
                                                                                        },
                                                                                      message:
                                                                                        'should be string',
                                                                                    },
                                                                                  ]),
                                                                                !1
                                                                              );
                                                                            valid1 =
                                                                              0 ===
                                                                              errs_1;
                                                                          }
                                                                          if (
                                                                            valid1
                                                                          ) {
                                                                            if (
                                                                              void 0 ===
                                                                              data.pricingExperiment
                                                                            )
                                                                              valid1 =
                                                                                !0;
                                                                            else {
                                                                              for (
                                                                                errs_1 = 0,
                                                                                  schema1 =
                                                                                    validate
                                                                                      .schema
                                                                                      .properties
                                                                                      .pricingExperiment
                                                                                      .enum,
                                                                                  valid1 =
                                                                                    !1,
                                                                                  i1 = 0;
                                                                                i1 <
                                                                                schema1.length;
                                                                                i1++
                                                                              )
                                                                                if (
                                                                                  equal(
                                                                                    data.pricingExperiment,
                                                                                    schema1[
                                                                                      i1
                                                                                    ],
                                                                                  )
                                                                                ) {
                                                                                  valid1 =
                                                                                    !0;
                                                                                  break;
                                                                                }
                                                                              if (
                                                                                !valid1
                                                                              )
                                                                                return (
                                                                                  (validate.errors =
                                                                                    [
                                                                                      {
                                                                                        keyword:
                                                                                          'enum',
                                                                                        dataPath:
                                                                                          (dataPath ||
                                                                                            '') +
                                                                                          '.pricingExperiment',
                                                                                        schemaPath:
                                                                                          '#/properties/pricingExperiment/enum',
                                                                                        params:
                                                                                          {
                                                                                            allowedValues:
                                                                                              schema1,
                                                                                          },
                                                                                        message:
                                                                                          'should be equal to one of the allowed values',
                                                                                      },
                                                                                    ]),
                                                                                  !1
                                                                                );
                                                                              valid1 =
                                                                                0 ===
                                                                                errs_1;
                                                                            }
                                                                            if (
                                                                              valid1
                                                                            ) {
                                                                              if (
                                                                                void 0 ===
                                                                                data[
                                                                                  'initial_utm_campaign '
                                                                                ]
                                                                              )
                                                                                valid1 =
                                                                                  !0;
                                                                              else {
                                                                                if (
                                                                                  ((errs_1 = 0),
                                                                                  'string' !=
                                                                                    typeof data[
                                                                                      'initial_utm_campaign '
                                                                                    ])
                                                                                )
                                                                                  return (
                                                                                    (validate.errors =
                                                                                      [
                                                                                        {
                                                                                          keyword:
                                                                                            'type',
                                                                                          dataPath:
                                                                                            (dataPath ||
                                                                                              '') +
                                                                                            "['initial_utm_campaign ']",
                                                                                          schemaPath:
                                                                                            '#/properties/initial_utm_campaign%20/type',
                                                                                          params:
                                                                                            {
                                                                                              type: 'string',
                                                                                            },
                                                                                          message:
                                                                                            'should be string',
                                                                                        },
                                                                                      ]),
                                                                                    !1
                                                                                  );
                                                                                valid1 =
                                                                                  0 ===
                                                                                  errs_1;
                                                                              }
                                                                              if (
                                                                                valid1
                                                                              ) {
                                                                                if (
                                                                                  void 0 ===
                                                                                  data.TotalHashtagsStored
                                                                                )
                                                                                  valid1 =
                                                                                    !0;
                                                                                else {
                                                                                  if (
                                                                                    ((errs_1 = 0),
                                                                                    'number' !=
                                                                                      typeof data.TotalHashtagsStored)
                                                                                  )
                                                                                    return (
                                                                                      (validate.errors =
                                                                                        [
                                                                                          {
                                                                                            keyword:
                                                                                              'type',
                                                                                            dataPath:
                                                                                              (dataPath ||
                                                                                                '') +
                                                                                              '.TotalHashtagsStored',
                                                                                            schemaPath:
                                                                                              '#/properties/TotalHashtagsStored/type',
                                                                                            params:
                                                                                              {
                                                                                                type: 'number',
                                                                                              },
                                                                                            message:
                                                                                              'should be number',
                                                                                          },
                                                                                        ]),
                                                                                      !1
                                                                                    );
                                                                                  valid1 =
                                                                                    0 ===
                                                                                    errs_1;
                                                                                }
                                                                                if (
                                                                                  valid1
                                                                                ) {
                                                                                  if (
                                                                                    void 0 ===
                                                                                    data.NumIGAccounts
                                                                                  )
                                                                                    valid1 =
                                                                                      !0;
                                                                                  else {
                                                                                    if (
                                                                                      ((errs_1 = 0),
                                                                                      'number' !=
                                                                                        typeof data.NumIGAccounts)
                                                                                    )
                                                                                      return (
                                                                                        (validate.errors =
                                                                                          [
                                                                                            {
                                                                                              keyword:
                                                                                                'type',
                                                                                              dataPath:
                                                                                                (dataPath ||
                                                                                                  '') +
                                                                                                '.NumIGAccounts',
                                                                                              schemaPath:
                                                                                                '#/properties/NumIGAccounts/type',
                                                                                              params:
                                                                                                {
                                                                                                  type: 'number',
                                                                                                },
                                                                                              message:
                                                                                                'should be number',
                                                                                            },
                                                                                          ]),
                                                                                        !1
                                                                                      );
                                                                                    valid1 =
                                                                                      0 ===
                                                                                      errs_1;
                                                                                  }
                                                                                  if (
                                                                                    valid1
                                                                                  ) {
                                                                                    if (
                                                                                      void 0 ===
                                                                                      data.isOnNewPlan
                                                                                    )
                                                                                      valid1 =
                                                                                        !0;
                                                                                    else {
                                                                                      if (
                                                                                        ((errs_1 = 0),
                                                                                        'boolean' !=
                                                                                          typeof data.isOnNewPlan)
                                                                                      )
                                                                                        return (
                                                                                          (validate.errors =
                                                                                            [
                                                                                              {
                                                                                                keyword:
                                                                                                  'type',
                                                                                                dataPath:
                                                                                                  (dataPath ||
                                                                                                    '') +
                                                                                                  '.isOnNewPlan',
                                                                                                schemaPath:
                                                                                                  '#/properties/isOnNewPlan/type',
                                                                                                params:
                                                                                                  {
                                                                                                    type: 'boolean',
                                                                                                  },
                                                                                                message:
                                                                                                  'should be boolean',
                                                                                              },
                                                                                            ]),
                                                                                          !1
                                                                                        );
                                                                                      valid1 =
                                                                                        0 ===
                                                                                        errs_1;
                                                                                    }
                                                                                    if (
                                                                                      valid1
                                                                                    ) {
                                                                                      if (
                                                                                        void 0 ===
                                                                                        data.numTopPerformingHashtags
                                                                                      )
                                                                                        valid1 =
                                                                                          !0;
                                                                                      else {
                                                                                        if (
                                                                                          ((errs_1 = 0),
                                                                                          'number' !=
                                                                                            typeof data.numTopPerformingHashtags)
                                                                                        )
                                                                                          return (
                                                                                            (validate.errors =
                                                                                              [
                                                                                                {
                                                                                                  keyword:
                                                                                                    'type',
                                                                                                  dataPath:
                                                                                                    (dataPath ||
                                                                                                      '') +
                                                                                                    '.numTopPerformingHashtags',
                                                                                                  schemaPath:
                                                                                                    '#/properties/numTopPerformingHashtags/type',
                                                                                                  params:
                                                                                                    {
                                                                                                      type: 'number',
                                                                                                    },
                                                                                                  message:
                                                                                                    'should be number',
                                                                                                },
                                                                                              ]),
                                                                                            !1
                                                                                          );
                                                                                        valid1 =
                                                                                          0 ===
                                                                                          errs_1;
                                                                                      }
                                                                                      if (
                                                                                        valid1
                                                                                      ) {
                                                                                        if (
                                                                                          void 0 ===
                                                                                          data.PaymentMethod
                                                                                        )
                                                                                          valid1 =
                                                                                            !0;
                                                                                        else {
                                                                                          if (
                                                                                            ((errs_1 = 0),
                                                                                            'string' !=
                                                                                              typeof data.PaymentMethod)
                                                                                          )
                                                                                            return (
                                                                                              (validate.errors =
                                                                                                [
                                                                                                  {
                                                                                                    keyword:
                                                                                                      'type',
                                                                                                    dataPath:
                                                                                                      (dataPath ||
                                                                                                        '') +
                                                                                                      '.PaymentMethod',
                                                                                                    schemaPath:
                                                                                                      '#/properties/PaymentMethod/type',
                                                                                                    params:
                                                                                                      {
                                                                                                        type: 'string',
                                                                                                      },
                                                                                                    message:
                                                                                                      'should be string',
                                                                                                  },
                                                                                                ]),
                                                                                              !1
                                                                                            );
                                                                                          valid1 =
                                                                                            0 ===
                                                                                            errs_1;
                                                                                        }
                                                                                        if (
                                                                                          valid1
                                                                                        ) {
                                                                                          if (
                                                                                            void 0 ===
                                                                                            data.referrer
                                                                                          )
                                                                                            valid1 =
                                                                                              !0;
                                                                                          else {
                                                                                            if (
                                                                                              ((errs_1 = 0),
                                                                                              'string' !=
                                                                                                typeof data.referrer)
                                                                                            )
                                                                                              return (
                                                                                                (validate.errors =
                                                                                                  [
                                                                                                    {
                                                                                                      keyword:
                                                                                                        'type',
                                                                                                      dataPath:
                                                                                                        (dataPath ||
                                                                                                          '') +
                                                                                                        '.referrer',
                                                                                                      schemaPath:
                                                                                                        '#/properties/referrer/type',
                                                                                                      params:
                                                                                                        {
                                                                                                          type: 'string',
                                                                                                        },
                                                                                                      message:
                                                                                                        'should be string',
                                                                                                    },
                                                                                                  ]),
                                                                                                !1
                                                                                              );
                                                                                            valid1 =
                                                                                              0 ===
                                                                                              errs_1;
                                                                                          }
                                                                                          if (
                                                                                            valid1
                                                                                          ) {
                                                                                            if (
                                                                                              void 0 ===
                                                                                              data.plan
                                                                                            )
                                                                                              valid1 =
                                                                                                !0;
                                                                                            else {
                                                                                              if (
                                                                                                ((errs_1 = 0),
                                                                                                'string' !=
                                                                                                  typeof data.plan)
                                                                                              )
                                                                                                return (
                                                                                                  (validate.errors =
                                                                                                    [
                                                                                                      {
                                                                                                        keyword:
                                                                                                          'type',
                                                                                                        dataPath:
                                                                                                          (dataPath ||
                                                                                                            '') +
                                                                                                          '.plan',
                                                                                                        schemaPath:
                                                                                                          '#/properties/plan/type',
                                                                                                        params:
                                                                                                          {
                                                                                                            type: 'string',
                                                                                                          },
                                                                                                        message:
                                                                                                          'should be string',
                                                                                                      },
                                                                                                    ]),
                                                                                                  !1
                                                                                                );
                                                                                              valid1 =
                                                                                                0 ===
                                                                                                errs_1;
                                                                                            }
                                                                                            if (
                                                                                              valid1
                                                                                            ) {
                                                                                              if (
                                                                                                void 0 ===
                                                                                                data.hasLinkedGoogle
                                                                                              )
                                                                                                valid1 =
                                                                                                  !0;
                                                                                              else {
                                                                                                if (
                                                                                                  ((errs_1 = 0),
                                                                                                  'boolean' !=
                                                                                                    typeof data.hasLinkedGoogle)
                                                                                                )
                                                                                                  return (
                                                                                                    (validate.errors =
                                                                                                      [
                                                                                                        {
                                                                                                          keyword:
                                                                                                            'type',
                                                                                                          dataPath:
                                                                                                            (dataPath ||
                                                                                                              '') +
                                                                                                            '.hasLinkedGoogle',
                                                                                                          schemaPath:
                                                                                                            '#/properties/hasLinkedGoogle/type',
                                                                                                          params:
                                                                                                            {
                                                                                                              type: 'boolean',
                                                                                                            },
                                                                                                          message:
                                                                                                            'should be boolean',
                                                                                                        },
                                                                                                      ]),
                                                                                                    !1
                                                                                                  );
                                                                                                valid1 =
                                                                                                  0 ===
                                                                                                  errs_1;
                                                                                              }
                                                                                              if (
                                                                                                valid1
                                                                                              ) {
                                                                                                if (
                                                                                                  void 0 ===
                                                                                                  data.referring_domain
                                                                                                )
                                                                                                  valid1 =
                                                                                                    !0;
                                                                                                else {
                                                                                                  if (
                                                                                                    ((errs_1 = 0),
                                                                                                    'string' !=
                                                                                                      typeof data.referring_domain)
                                                                                                  )
                                                                                                    return (
                                                                                                      (validate.errors =
                                                                                                        [
                                                                                                          {
                                                                                                            keyword:
                                                                                                              'type',
                                                                                                            dataPath:
                                                                                                              (dataPath ||
                                                                                                                '') +
                                                                                                              '.referring_domain',
                                                                                                            schemaPath:
                                                                                                              '#/properties/referring_domain/type',
                                                                                                            params:
                                                                                                              {
                                                                                                                type: 'string',
                                                                                                              },
                                                                                                            message:
                                                                                                              'should be string',
                                                                                                          },
                                                                                                        ]),
                                                                                                      !1
                                                                                                    );
                                                                                                  valid1 =
                                                                                                    0 ===
                                                                                                    errs_1;
                                                                                                }
                                                                                                if (
                                                                                                  valid1
                                                                                                ) {
                                                                                                  if (
                                                                                                    void 0 ===
                                                                                                    data.numRecentlyRankedHashtags
                                                                                                  )
                                                                                                    valid1 =
                                                                                                      !0;
                                                                                                  else {
                                                                                                    if (
                                                                                                      ((errs_1 = 0),
                                                                                                      'number' !=
                                                                                                        typeof data.numRecentlyRankedHashtags)
                                                                                                    )
                                                                                                      return (
                                                                                                        (validate.errors =
                                                                                                          [
                                                                                                            {
                                                                                                              keyword:
                                                                                                                'type',
                                                                                                              dataPath:
                                                                                                                (dataPath ||
                                                                                                                  '') +
                                                                                                                '.numRecentlyRankedHashtags',
                                                                                                              schemaPath:
                                                                                                                '#/properties/numRecentlyRankedHashtags/type',
                                                                                                              params:
                                                                                                                {
                                                                                                                  type: 'number',
                                                                                                                },
                                                                                                              message:
                                                                                                                'should be number',
                                                                                                            },
                                                                                                          ]),
                                                                                                        !1
                                                                                                      );
                                                                                                    valid1 =
                                                                                                      0 ===
                                                                                                      errs_1;
                                                                                                  }
                                                                                                  if (
                                                                                                    valid1
                                                                                                  ) {
                                                                                                    if (
                                                                                                      void 0 ===
                                                                                                      data.SubscriptionStatus
                                                                                                    )
                                                                                                      valid1 =
                                                                                                        !0;
                                                                                                    else {
                                                                                                      if (
                                                                                                        ((errs_1 = 0),
                                                                                                        'string' !=
                                                                                                          typeof data.SubscriptionStatus)
                                                                                                      )
                                                                                                        return (
                                                                                                          (validate.errors =
                                                                                                            [
                                                                                                              {
                                                                                                                keyword:
                                                                                                                  'type',
                                                                                                                dataPath:
                                                                                                                  (dataPath ||
                                                                                                                    '') +
                                                                                                                  '.SubscriptionStatus',
                                                                                                                schemaPath:
                                                                                                                  '#/properties/SubscriptionStatus/type',
                                                                                                                params:
                                                                                                                  {
                                                                                                                    type: 'string',
                                                                                                                  },
                                                                                                                message:
                                                                                                                  'should be string',
                                                                                                              },
                                                                                                            ]),
                                                                                                          !1
                                                                                                        );
                                                                                                      valid1 =
                                                                                                        0 ===
                                                                                                        errs_1;
                                                                                                    }
                                                                                                    if (
                                                                                                      valid1
                                                                                                    ) {
                                                                                                      if (
                                                                                                        void 0 ===
                                                                                                        data.trialStartDate
                                                                                                      )
                                                                                                        valid1 =
                                                                                                          !0;
                                                                                                      else {
                                                                                                        if (
                                                                                                          ((errs_1 = 0),
                                                                                                          'string' !=
                                                                                                            typeof data.trialStartDate)
                                                                                                        )
                                                                                                          return (
                                                                                                            (validate.errors =
                                                                                                              [
                                                                                                                {
                                                                                                                  keyword:
                                                                                                                    'type',
                                                                                                                  dataPath:
                                                                                                                    (dataPath ||
                                                                                                                      '') +
                                                                                                                    '.trialStartDate',
                                                                                                                  schemaPath:
                                                                                                                    '#/properties/trialStartDate/type',
                                                                                                                  params:
                                                                                                                    {
                                                                                                                      type: 'string',
                                                                                                                    },
                                                                                                                  message:
                                                                                                                    'should be string',
                                                                                                                },
                                                                                                              ]),
                                                                                                            !1
                                                                                                          );
                                                                                                        valid1 =
                                                                                                          0 ===
                                                                                                          errs_1;
                                                                                                      }
                                                                                                      if (
                                                                                                        valid1
                                                                                                      ) {
                                                                                                        if (
                                                                                                          void 0 ===
                                                                                                          data.ReferralTrials
                                                                                                        )
                                                                                                          valid1 =
                                                                                                            !0;
                                                                                                        else {
                                                                                                          if (
                                                                                                            ((errs_1 = 0),
                                                                                                            'number' !=
                                                                                                              typeof data.ReferralTrials)
                                                                                                          )
                                                                                                            return (
                                                                                                              (validate.errors =
                                                                                                                [
                                                                                                                  {
                                                                                                                    keyword:
                                                                                                                      'type',
                                                                                                                    dataPath:
                                                                                                                      (dataPath ||
                                                                                                                        '') +
                                                                                                                      '.ReferralTrials',
                                                                                                                    schemaPath:
                                                                                                                      '#/properties/ReferralTrials/type',
                                                                                                                    params:
                                                                                                                      {
                                                                                                                        type: 'number',
                                                                                                                      },
                                                                                                                    message:
                                                                                                                      'should be number',
                                                                                                                  },
                                                                                                                ]),
                                                                                                              !1
                                                                                                            );
                                                                                                          valid1 =
                                                                                                            0 ===
                                                                                                            errs_1;
                                                                                                        }
                                                                                                        if (
                                                                                                          valid1
                                                                                                        ) {
                                                                                                          if (
                                                                                                            void 0 ===
                                                                                                            data.isFreemium
                                                                                                          )
                                                                                                            valid1 =
                                                                                                              !0;
                                                                                                          else {
                                                                                                            if (
                                                                                                              ((errs_1 = 0),
                                                                                                              'boolean' !=
                                                                                                                typeof data.isFreemium)
                                                                                                            )
                                                                                                              return (
                                                                                                                (validate.errors =
                                                                                                                  [
                                                                                                                    {
                                                                                                                      keyword:
                                                                                                                        'type',
                                                                                                                      dataPath:
                                                                                                                        (dataPath ||
                                                                                                                          '') +
                                                                                                                        '.isFreemium',
                                                                                                                      schemaPath:
                                                                                                                        '#/properties/isFreemium/type',
                                                                                                                      params:
                                                                                                                        {
                                                                                                                          type: 'boolean',
                                                                                                                        },
                                                                                                                      message:
                                                                                                                        'should be boolean',
                                                                                                                    },
                                                                                                                  ]),
                                                                                                                !1
                                                                                                              );
                                                                                                            valid1 =
                                                                                                              0 ===
                                                                                                              errs_1;
                                                                                                          }
                                                                                                          if (
                                                                                                            valid1
                                                                                                          ) {
                                                                                                            if (
                                                                                                              void 0 ===
                                                                                                              data[
                                                                                                                'initial_utm_term '
                                                                                                              ]
                                                                                                            )
                                                                                                              valid1 =
                                                                                                                !0;
                                                                                                            else {
                                                                                                              if (
                                                                                                                ((errs_1 = 0),
                                                                                                                'string' !=
                                                                                                                  typeof data[
                                                                                                                    'initial_utm_term '
                                                                                                                  ])
                                                                                                              )
                                                                                                                return (
                                                                                                                  (validate.errors =
                                                                                                                    [
                                                                                                                      {
                                                                                                                        keyword:
                                                                                                                          'type',
                                                                                                                        dataPath:
                                                                                                                          (dataPath ||
                                                                                                                            '') +
                                                                                                                          "['initial_utm_term ']",
                                                                                                                        schemaPath:
                                                                                                                          '#/properties/initial_utm_term%20/type',
                                                                                                                        params:
                                                                                                                          {
                                                                                                                            type: 'string',
                                                                                                                          },
                                                                                                                        message:
                                                                                                                          'should be string',
                                                                                                                      },
                                                                                                                    ]),
                                                                                                                  !1
                                                                                                                );
                                                                                                              valid1 =
                                                                                                                0 ===
                                                                                                                errs_1;
                                                                                                            }
                                                                                                            if (
                                                                                                              valid1
                                                                                                            ) {
                                                                                                              if (
                                                                                                                void 0 ===
                                                                                                                data.UseCase
                                                                                                              )
                                                                                                                valid1 =
                                                                                                                  !0;
                                                                                                              else {
                                                                                                                if (
                                                                                                                  ((errs_1 = 0),
                                                                                                                  'string' !=
                                                                                                                    typeof data.UseCase)
                                                                                                                )
                                                                                                                  return (
                                                                                                                    (validate.errors =
                                                                                                                      [
                                                                                                                        {
                                                                                                                          keyword:
                                                                                                                            'type',
                                                                                                                          dataPath:
                                                                                                                            (dataPath ||
                                                                                                                              '') +
                                                                                                                            '.UseCase',
                                                                                                                          schemaPath:
                                                                                                                            '#/properties/UseCase/type',
                                                                                                                          params:
                                                                                                                            {
                                                                                                                              type: 'string',
                                                                                                                            },
                                                                                                                          message:
                                                                                                                            'should be string',
                                                                                                                        },
                                                                                                                      ]),
                                                                                                                    !1
                                                                                                                  );
                                                                                                                valid1 =
                                                                                                                  0 ===
                                                                                                                  errs_1;
                                                                                                              }
                                                                                                              if (
                                                                                                                valid1
                                                                                                              ) {
                                                                                                                if (
                                                                                                                  void 0 ===
                                                                                                                  data.ReferralClicks
                                                                                                                )
                                                                                                                  valid1 =
                                                                                                                    !0;
                                                                                                                else {
                                                                                                                  if (
                                                                                                                    ((errs_1 = 0),
                                                                                                                    'string' !=
                                                                                                                      typeof data.ReferralClicks)
                                                                                                                  )
                                                                                                                    return (
                                                                                                                      (validate.errors =
                                                                                                                        [
                                                                                                                          {
                                                                                                                            keyword:
                                                                                                                              'type',
                                                                                                                            dataPath:
                                                                                                                              (dataPath ||
                                                                                                                                '') +
                                                                                                                              '.ReferralClicks',
                                                                                                                            schemaPath:
                                                                                                                              '#/properties/ReferralClicks/type',
                                                                                                                            params:
                                                                                                                              {
                                                                                                                                type: 'string',
                                                                                                                              },
                                                                                                                            message:
                                                                                                                              'should be string',
                                                                                                                          },
                                                                                                                        ]),
                                                                                                                      !1
                                                                                                                    );
                                                                                                                  valid1 =
                                                                                                                    0 ===
                                                                                                                    errs_1;
                                                                                                                }
                                                                                                                if (
                                                                                                                  valid1
                                                                                                                ) {
                                                                                                                  if (
                                                                                                                    void 0 ===
                                                                                                                    data.TrialDaysRemaining
                                                                                                                  )
                                                                                                                    valid1 =
                                                                                                                      !0;
                                                                                                                  else {
                                                                                                                    if (
                                                                                                                      ((errs_1 = 0),
                                                                                                                      'number' !=
                                                                                                                        typeof data.TrialDaysRemaining)
                                                                                                                    )
                                                                                                                      return (
                                                                                                                        (validate.errors =
                                                                                                                          [
                                                                                                                            {
                                                                                                                              keyword:
                                                                                                                                'type',
                                                                                                                              dataPath:
                                                                                                                                (dataPath ||
                                                                                                                                  '') +
                                                                                                                                '.TrialDaysRemaining',
                                                                                                                              schemaPath:
                                                                                                                                '#/properties/TrialDaysRemaining/type',
                                                                                                                              params:
                                                                                                                                {
                                                                                                                                  type: 'number',
                                                                                                                                },
                                                                                                                              message:
                                                                                                                                'should be number',
                                                                                                                            },
                                                                                                                          ]),
                                                                                                                        !1
                                                                                                                      );
                                                                                                                    valid1 =
                                                                                                                      0 ===
                                                                                                                      errs_1;
                                                                                                                  }
                                                                                                                  if (
                                                                                                                    valid1
                                                                                                                  ) {
                                                                                                                    if (
                                                                                                                      void 0 ===
                                                                                                                      data.name
                                                                                                                    )
                                                                                                                      valid1 =
                                                                                                                        !0;
                                                                                                                    else {
                                                                                                                      if (
                                                                                                                        ((errs_1 = 0),
                                                                                                                        'string' !=
                                                                                                                          typeof data.name)
                                                                                                                      )
                                                                                                                        return (
                                                                                                                          (validate.errors =
                                                                                                                            [
                                                                                                                              {
                                                                                                                                keyword:
                                                                                                                                  'type',
                                                                                                                                dataPath:
                                                                                                                                  (dataPath ||
                                                                                                                                    '') +
                                                                                                                                  '.name',
                                                                                                                                schemaPath:
                                                                                                                                  '#/properties/name/type',
                                                                                                                                params:
                                                                                                                                  {
                                                                                                                                    type: 'string',
                                                                                                                                  },
                                                                                                                                message:
                                                                                                                                  'should be string',
                                                                                                                              },
                                                                                                                            ]),
                                                                                                                          !1
                                                                                                                        );
                                                                                                                      valid1 =
                                                                                                                        0 ===
                                                                                                                        errs_1;
                                                                                                                    }
                                                                                                                    if (
                                                                                                                      valid1
                                                                                                                    ) {
                                                                                                                      if (
                                                                                                                        void 0 ===
                                                                                                                        data.initial_referrer
                                                                                                                      )
                                                                                                                        valid1 =
                                                                                                                          !0;
                                                                                                                      else {
                                                                                                                        if (
                                                                                                                          ((errs_1 = 0),
                                                                                                                          'string' !=
                                                                                                                            typeof data.initial_referrer)
                                                                                                                        )
                                                                                                                          return (
                                                                                                                            (validate.errors =
                                                                                                                              [
                                                                                                                                {
                                                                                                                                  keyword:
                                                                                                                                    'type',
                                                                                                                                  dataPath:
                                                                                                                                    (dataPath ||
                                                                                                                                      '') +
                                                                                                                                    '.initial_referrer',
                                                                                                                                  schemaPath:
                                                                                                                                    '#/properties/initial_referrer/type',
                                                                                                                                  params:
                                                                                                                                    {
                                                                                                                                      type: 'string',
                                                                                                                                    },
                                                                                                                                  message:
                                                                                                                                    'should be string',
                                                                                                                                },
                                                                                                                              ]),
                                                                                                                            !1
                                                                                                                          );
                                                                                                                        valid1 =
                                                                                                                          0 ===
                                                                                                                          errs_1;
                                                                                                                      }
                                                                                                                      if (
                                                                                                                        valid1
                                                                                                                      ) {
                                                                                                                        if (
                                                                                                                          void 0 ===
                                                                                                                          data.wasGrandfathered
                                                                                                                        )
                                                                                                                          valid1 =
                                                                                                                            !0;
                                                                                                                        else {
                                                                                                                          if (
                                                                                                                            ((errs_1 = 0),
                                                                                                                            'boolean' !=
                                                                                                                              typeof data.wasGrandfathered)
                                                                                                                          )
                                                                                                                            return (
                                                                                                                              (validate.errors =
                                                                                                                                [
                                                                                                                                  {
                                                                                                                                    keyword:
                                                                                                                                      'type',
                                                                                                                                    dataPath:
                                                                                                                                      (dataPath ||
                                                                                                                                        '') +
                                                                                                                                      '.wasGrandfathered',
                                                                                                                                    schemaPath:
                                                                                                                                      '#/properties/wasGrandfathered/type',
                                                                                                                                    params:
                                                                                                                                      {
                                                                                                                                        type: 'boolean',
                                                                                                                                      },
                                                                                                                                    message:
                                                                                                                                      'should be boolean',
                                                                                                                                  },
                                                                                                                                ]),
                                                                                                                              !1
                                                                                                                            );
                                                                                                                          valid1 =
                                                                                                                            0 ===
                                                                                                                            errs_1;
                                                                                                                        }
                                                                                                                        if (
                                                                                                                          valid1
                                                                                                                        ) {
                                                                                                                          if (
                                                                                                                            void 0 ===
                                                                                                                            data.LastSeen
                                                                                                                          )
                                                                                                                            valid1 =
                                                                                                                              !0;
                                                                                                                          else {
                                                                                                                            if (
                                                                                                                              ((errs_1 = 0),
                                                                                                                              'string' !=
                                                                                                                                typeof data.LastSeen)
                                                                                                                            )
                                                                                                                              return (
                                                                                                                                (validate.errors =
                                                                                                                                  [
                                                                                                                                    {
                                                                                                                                      keyword:
                                                                                                                                        'type',
                                                                                                                                      dataPath:
                                                                                                                                        (dataPath ||
                                                                                                                                          '') +
                                                                                                                                        '.LastSeen',
                                                                                                                                      schemaPath:
                                                                                                                                        '#/properties/LastSeen/type',
                                                                                                                                      params:
                                                                                                                                        {
                                                                                                                                          type: 'string',
                                                                                                                                        },
                                                                                                                                      message:
                                                                                                                                        'should be string',
                                                                                                                                    },
                                                                                                                                  ]),
                                                                                                                                !1
                                                                                                                              );
                                                                                                                            valid1 =
                                                                                                                              0 ===
                                                                                                                              errs_1;
                                                                                                                          }
                                                                                                                          var data1;
                                                                                                                          if (
                                                                                                                            valid1
                                                                                                                          )
                                                                                                                            if (
                                                                                                                              void 0 ===
                                                                                                                              (data1 =
                                                                                                                                data.OnboardingAims)
                                                                                                                            )
                                                                                                                              valid1 =
                                                                                                                                !0;
                                                                                                                            else {
                                                                                                                              if (
                                                                                                                                ((errs_1 = 0),
                                                                                                                                !Array.isArray(
                                                                                                                                  data1,
                                                                                                                                ))
                                                                                                                              )
                                                                                                                                return (
                                                                                                                                  (validate.errors =
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        keyword:
                                                                                                                                          'type',
                                                                                                                                        dataPath:
                                                                                                                                          (dataPath ||
                                                                                                                                            '') +
                                                                                                                                          '.OnboardingAims',
                                                                                                                                        schemaPath:
                                                                                                                                          '#/properties/OnboardingAims/type',
                                                                                                                                        params:
                                                                                                                                          {
                                                                                                                                            type: 'array',
                                                                                                                                          },
                                                                                                                                        message:
                                                                                                                                          'should be array',
                                                                                                                                      },
                                                                                                                                    ]),
                                                                                                                                  !1
                                                                                                                                );
                                                                                                                              for (
                                                                                                                                i1 = 0;
                                                                                                                                i1 <
                                                                                                                                data1.length;
                                                                                                                                i1++
                                                                                                                              ) {
                                                                                                                                if (
                                                                                                                                  ((errs_2 = 0),
                                                                                                                                  'string' !=
                                                                                                                                    typeof data1[
                                                                                                                                      i1
                                                                                                                                    ])
                                                                                                                                )
                                                                                                                                  return (
                                                                                                                                    (validate.errors =
                                                                                                                                      [
                                                                                                                                        {
                                                                                                                                          keyword:
                                                                                                                                            'type',
                                                                                                                                          dataPath:
                                                                                                                                            (dataPath ||
                                                                                                                                              '') +
                                                                                                                                            '.OnboardingAims[' +
                                                                                                                                            i1 +
                                                                                                                                            ']',
                                                                                                                                          schemaPath:
                                                                                                                                            '#/properties/OnboardingAims/items/type',
                                                                                                                                          params:
                                                                                                                                            {
                                                                                                                                              type: 'string',
                                                                                                                                            },
                                                                                                                                          message:
                                                                                                                                            'should be string',
                                                                                                                                        },
                                                                                                                                      ]),
                                                                                                                                    !1
                                                                                                                                  );
                                                                                                                                if (
                                                                                                                                  0 !==
                                                                                                                                  errs_2
                                                                                                                                )
                                                                                                                                  break;
                                                                                                                              }
                                                                                                                              valid1 =
                                                                                                                                0 ===
                                                                                                                                errs_1;
                                                                                                                            }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$1.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/identify',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Identify',
  description: '',
  type: 'object',
  properties: {
    hasSetPassword: {
      description: 'Whether or not a Flick user has set his password ',
      type: 'boolean',
    },
    utm_source: { description: '', type: 'string' },
    utm_term: { description: '', type: 'string' },
    activeAddons: {
      description: "The active addon's a user has ",
      type: 'array',
      items: { type: 'string' },
      uniqueItems: !1,
    },
    PostingFrequency: {
      description:
        'How often a user posts on Instagram outlined during on-boarding. ',
      type: 'string',
    },
    Plan: { description: '', type: 'string' },
    referrerUrl: { description: '', type: 'string' },
    'initial_utm_content ': { description: '', type: 'string' },
    SelectedHashtagsExperiment: { description: '', type: 'string' },
    lastReceivedNotification: {
      description:
        'The timestamp at which the user last received a notification',
      type: 'string',
    },
    ReferralTrialConversions: {
      description: 'The amount of trial conversions people referred have had ',
      type: 'string',
    },
    utm_campaign: { description: '', type: 'string' },
    initial_utm_source: { description: '', type: 'string' },
    keyAction: { description: '', enum: ['export', 'add_to_collection'] },
    lastName: { description: '', type: 'string' },
    id: { description: 'The user id', type: 'string' },
    OnboardingNumAccounts: {
      description:
        'Number of accounts the user indicated that they manage during onboarding',
      type: 'string',
    },
    firstName: { description: '', type: 'string' },
    affiliateId: {
      description: 'The ID of the affiliate they signed up with',
      type: 'string',
    },
    LinkedIGAccounts: {
      description: '',
      type: 'array',
      items: { type: 'string' },
    },
    LTV: { description: '', type: 'string' },
    userId: { description: '', type: 'string' },
    litePlanExperiment: { description: '', enum: ['control', 'experiment'] },
    email: { description: "The user's email", type: 'string' },
    initial_utm_medium: { description: '', type: 'string' },
    isSubscribedToBrowserNotifications: {
      description: 'If the user is subscribed to browser notifications',
      type: 'boolean',
    },
    trialEndDate: {
      description: "The date a user's trial will end ",
      type: 'string',
    },
    utm_medium: { description: '', type: 'string' },
    features: {
      description: 'The features the user has access to',
      type: 'array',
      items: { type: 'string' },
    },
    FullName: { description: "The user's full name", type: 'string' },
    FirstSeen: { description: 'When a user was first seen', type: 'string' },
    NumLinkedIGAccounts: {
      description: 'The number of IG accounts the user has linked to Flick ',
      type: 'number',
    },
    ProfilePicture: {
      description: "Link to the user's profile picture",
      type: 'string',
    },
    hasAnalytics: {
      description: 'Whether the user has access to analytics',
      type: 'boolean',
    },
    timezone: { description: '', type: 'string' },
    initial_referring_domain: { description: '', type: 'string' },
    pricingExperiment: { description: '', enum: ['experiment', 'control'] },
    'initial_utm_campaign ': { description: '', type: 'string' },
    TotalHashtagsStored: {
      description:
        'The amount of hashtags stored by a user across all collections and accounts',
      type: 'number',
    },
    NumIGAccounts: {
      description: 'The amount of IG accounts a user has ',
      type: 'number',
    },
    isOnNewPlan: { description: '', type: 'boolean' },
    numTopPerformingHashtags: {
      description:
        "The number of hashtags stored in a user's smart collections",
      type: 'number',
    },
    PaymentMethod: { description: '', type: 'string' },
    referrer: { description: '', type: 'string' },
    plan: { description: '', type: 'string' },
    hasLinkedGoogle: {
      description:
        'Whether or not the user has linked his Flick account to google ',
      type: 'boolean',
    },
    referring_domain: { description: '', type: 'string' },
    numRecentlyRankedHashtags: { description: '', type: 'number' },
    SubscriptionStatus: { description: '', type: 'string' },
    trialStartDate: {
      description: 'The date a user started his trial ',
      type: 'string',
    },
    ReferralTrials: {
      description:
        "The amount of trials started through a user's referral link ",
      type: 'number',
    },
    isFreemium: { description: '', type: 'boolean' },
    'initial_utm_term ': { description: '', type: 'string' },
    UseCase: {
      description: 'The use case selected during onboarding',
      type: 'string',
    },
    ReferralClicks: {
      description: 'The amount of clicks a user has on his referral link ',
      type: 'string',
    },
    TrialDaysRemaining: {
      description: 'The amount of days a user has remaining in his trial',
      type: 'number',
    },
    name: { description: 'The first name of a user', type: 'string' },
    initial_referrer: { description: '', type: 'string' },
    wasGrandfathered: {
      description:
        'Whether the user had been grandfathered from an old plan to new ',
      type: 'boolean',
    },
    LastSeen: { description: '', type: 'string' },
    OnboardingAims: {
      description:
        'The aim of what they would like to get out of Flick the user outlined during onboarding',
      type: 'array',
      items: { type: 'string' },
    },
  },
  additionalProperties: !1,
  required: [],
}),
  (validate$1.errors = null);

// @ts-ignore (./AddedCollectionToFavourites)
var validate$2 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('name' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.name)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'name' },
            message: "should have required property 'name'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.name)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.name',
            schemaPath: '#/properties/name/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$2.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Added%20Collection%20To%20Favourites/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Added Collection To Favourites',
  description: 'When a users adds a collection to favourites',
  type: 'object',
  properties: { name: { description: 'Collection name', type: 'string' } },
  additionalProperties: !1,
  required: ['name'],
}),
  (validate$2.errors = null);

// @ts-ignore (./AddedHashtagsToCollection)
var validate$3 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('view' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.view)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'view' },
            message: "should have required property 'view'",
          },
        ]),
        !1
      );
    var schema1 = validate.schema.properties.view.enum;
    valid1 = !1;
    for (var i1 = 0; i1 < schema1.length; i1++)
      if (equal(data.view, schema1[i1])) {
        valid1 = !0;
        break;
      }
    if (!valid1)
      return (
        (validate.errors = [
          {
            keyword: 'enum',
            dataPath: (dataPath || '') + '.view',
            schemaPath: '#/properties/view/enum',
            params: { allowedValues: schema1 },
            message: 'should be equal to one of the allowed values',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$3.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Added%20Hashtags%20to%20Collection/version/5.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Added Hashtags to Collection',
  description: 'When a user adds one or more hashtags to a collection',
  type: 'object',
  properties: {
    view: {
      description:
        'The view the action was made of\n\n| Rule | Value |\n|---|---|\n| Enum Values | search, collections, content, hashtagInsights, collection-suggestions, post-import, past-export, paste-import |',
      enum: [
        'search',
        'collections',
        'content',
        'hashtagInsights',
        'collection-suggestions',
        'post-import',
        'past-export',
        'paste-import',
      ],
    },
  },
  additionalProperties: !1,
  required: ['view'],
}),
  (validate$3.errors = null);

// @ts-ignore (./AddedSuggestedHashtags)
var validate$4 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('addedAll' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.addedAll)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'addedAll' },
            message: "should have required property 'addedAll'",
          },
        ]),
        !1
      );
    if ('boolean' != typeof data.addedAll)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.addedAll',
            schemaPath: '#/properties/addedAll/type',
            params: { type: 'boolean' },
            message: 'should be boolean',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$4.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Added%20Suggested%20Hashtags/version/2.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Added Suggested Hashtags',
  description:
    'Triggered when a user adds a suggestion from the suggested hashtag modal to their colleciton',
  type: 'object',
  properties: {
    addedAll: {
      description: 'whether the users mass added all the suggestions or not ',
      type: 'boolean',
    },
  },
  additionalProperties: !1,
  required: ['addedAll'],
}),
  (validate$4.errors = null);

// @ts-ignore (./AdvSearched)
var validate$5 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (
      'filteredBy' != key0 &&
      'excludedKeywords' != key0 &&
      'isSuggested' != key0 &&
      'limit' != key0 &&
      'includedKeywords' != key0 &&
      'isRefresh' != key0 &&
      'isKeyword' != key0 &&
      'skip' != key0
    )
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === (data1 = data.filteredBy))
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'filteredBy' },
            message: "should have required property 'filteredBy'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if (!Array.isArray(data1))
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.filteredBy',
            schemaPath: '#/properties/filteredBy/type',
            params: { type: 'array' },
            message: 'should be array',
          },
        ]),
        !1
      );
    for (var i1 = 0; i1 < data1.length; i1++) {
      var errs_2 = 0;
      if ('string' != typeof data1[i1])
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.filteredBy[' + i1 + ']',
              schemaPath: '#/properties/filteredBy/items/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      if (0 !== errs_2) break;
    }
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === (data1 = data.excludedKeywords))
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'excludedKeywords' },
              message: "should have required property 'excludedKeywords'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), !Array.isArray(data1)))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.excludedKeywords',
              schemaPath: '#/properties/excludedKeywords/type',
              params: { type: 'array' },
              message: 'should be array',
            },
          ]),
          !1
        );
      for (i1 = 0; i1 < data1.length; i1++) {
        if (((errs_2 = 0), 'string' != typeof data1[i1]))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.excludedKeywords[' + i1 + ']',
                schemaPath: '#/properties/excludedKeywords/items/type',
                params: { type: 'string' },
                message: 'should be string',
              },
            ]),
            !1
          );
        if (0 !== errs_2) break;
      }
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.isSuggested)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'isSuggested' },
                message: "should have required property 'isSuggested'",
              },
            ]),
            !1
          );
        if (((errs_1 = 0), 'boolean' != typeof data.isSuggested))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.isSuggested',
                schemaPath: '#/properties/isSuggested/type',
                params: { type: 'boolean' },
                message: 'should be boolean',
              },
            ]),
            !1
          );
        if ((valid1 = 0 === errs_1)) {
          if (void 0 === data.limit)
            return (
              (valid1 = !1),
              (validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: { missingProperty: 'limit' },
                  message: "should have required property 'limit'",
                },
              ]),
              !1
            );
          if (((errs_1 = 0), 'number' != typeof data.limit))
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.limit',
                  schemaPath: '#/properties/limit/type',
                  params: { type: 'number' },
                  message: 'should be number',
                },
              ]),
              !1
            );
          if ((valid1 = 0 === errs_1)) {
            var data1;
            if (void 0 === (data1 = data.includedKeywords))
              return (
                (valid1 = !1),
                (validate.errors = [
                  {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '',
                    schemaPath: '#/required',
                    params: { missingProperty: 'includedKeywords' },
                    message: "should have required property 'includedKeywords'",
                  },
                ]),
                !1
              );
            if (((errs_1 = 0), !Array.isArray(data1)))
              return (
                (validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.includedKeywords',
                    schemaPath: '#/properties/includedKeywords/type',
                    params: { type: 'array' },
                    message: 'should be array',
                  },
                ]),
                !1
              );
            for (i1 = 0; i1 < data1.length; i1++) {
              if (((errs_2 = 0), 'string' != typeof data1[i1]))
                return (
                  (validate.errors = [
                    {
                      keyword: 'type',
                      dataPath:
                        (dataPath || '') + '.includedKeywords[' + i1 + ']',
                      schemaPath: '#/properties/includedKeywords/items/type',
                      params: { type: 'string' },
                      message: 'should be string',
                    },
                  ]),
                  !1
                );
              if (0 !== errs_2) break;
            }
            if ((valid1 = 0 === errs_1)) {
              if (void 0 === data.isRefresh)
                return (
                  (valid1 = !1),
                  (validate.errors = [
                    {
                      keyword: 'required',
                      dataPath: (dataPath || '') + '',
                      schemaPath: '#/required',
                      params: { missingProperty: 'isRefresh' },
                      message: "should have required property 'isRefresh'",
                    },
                  ]),
                  !1
                );
              if (((errs_1 = 0), 'boolean' != typeof data.isRefresh))
                return (
                  (validate.errors = [
                    {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.isRefresh',
                      schemaPath: '#/properties/isRefresh/type',
                      params: { type: 'boolean' },
                      message: 'should be boolean',
                    },
                  ]),
                  !1
                );
              if ((valid1 = 0 === errs_1)) {
                if (void 0 === data.isKeyword)
                  return (
                    (valid1 = !1),
                    (validate.errors = [
                      {
                        keyword: 'required',
                        dataPath: (dataPath || '') + '',
                        schemaPath: '#/required',
                        params: { missingProperty: 'isKeyword' },
                        message: "should have required property 'isKeyword'",
                      },
                    ]),
                    !1
                  );
                if (((errs_1 = 0), 'boolean' != typeof data.isKeyword))
                  return (
                    (validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.isKeyword',
                        schemaPath: '#/properties/isKeyword/type',
                        params: { type: 'boolean' },
                        message: 'should be boolean',
                      },
                    ]),
                    !1
                  );
                if ((valid1 = 0 === errs_1)) {
                  if (void 0 === data.skip)
                    return (
                      (valid1 = !1),
                      (validate.errors = [
                        {
                          keyword: 'required',
                          dataPath: (dataPath || '') + '',
                          schemaPath: '#/required',
                          params: { missingProperty: 'skip' },
                          message: "should have required property 'skip'",
                        },
                      ]),
                      !1
                    );
                  if (((errs_1 = 0), 'number' != typeof data.skip))
                    return (
                      (validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.skip',
                          schemaPath: '#/properties/skip/type',
                          params: { type: 'number' },
                          message: 'should be number',
                        },
                      ]),
                      !1
                    );
                  valid1 = 0 === errs_1;
                }
              }
            }
          }
        }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$5.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Adv%20Searched/version/4.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Adv Searched',
  description: 'The user made a a search with filters applied ',
  type: 'object',
  properties: {
    filteredBy: {
      description:
        'The fields filtered by in the search\n\n| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
    excludedKeywords: {
      description:
        'The keywords excluded\n\n| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
    isSuggested: {
      description: 'True if the suggested filters were activated',
      type: 'boolean',
    },
    limit: {
      description: '| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    includedKeywords: {
      description:
        'The keywords included\n\n| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
    isRefresh: {
      description:
        'Is as of a result of an automatic refresh due to filter changes',
      type: 'boolean',
    },
    isKeyword: {
      description: 'Whether a keyword filter is used',
      type: 'boolean',
    },
    skip: {
      description: '| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
  },
  additionalProperties: !1,
  required: [
    'filteredBy',
    'excludedKeywords',
    'isSuggested',
    'limit',
    'includedKeywords',
    'isRefresh',
    'isKeyword',
    'skip',
  ],
}),
  (validate$5.errors = null);

// @ts-ignore (./AnsweredOnBoardingQuestions)
var validate$6 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('numOfAccounts' != key0 && 'useCase' != key0 && 'aims' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.numOfAccounts)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'numOfAccounts' },
            message: "should have required property 'numOfAccounts'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('string' != typeof data.numOfAccounts)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.numOfAccounts',
            schemaPath: '#/properties/numOfAccounts/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.useCase)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'useCase' },
              message: "should have required property 'useCase'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'string' != typeof data.useCase))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.useCase',
              schemaPath: '#/properties/useCase/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        var data1 = data.aims;
        if (void 0 === data1)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'aims' },
                message: "should have required property 'aims'",
              },
            ]),
            !1
          );
        if (((errs_1 = 0), !Array.isArray(data1)))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.aims',
                schemaPath: '#/properties/aims/type',
                params: { type: 'array' },
                message: 'should be array',
              },
            ]),
            !1
          );
        for (var i1 = 0; i1 < data1.length; i1++)
          if ('string' != typeof data1[i1])
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.aims[' + i1 + ']',
                  schemaPath: '#/properties/aims/items/type',
                  params: { type: 'string' },
                  message: 'should be string',
                },
              ]),
              !1
            );
        valid1 = 0 === errs_1;
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$6.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Answered%20On-boarding%20Questions/version/3.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Answered On-boarding Questions',
  description: 'Completed when a user Answers the on-boarding questions',
  type: 'object',
  properties: {
    numOfAccounts: { description: '', type: 'string' },
    useCase: { description: '', type: 'string' },
    aims: {
      description: '| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
  },
  additionalProperties: !1,
  required: ['numOfAccounts', 'useCase', 'aims'],
}),
  (validate$6.errors = null);

// @ts-ignore (./AppliedFilters)
var validate$7 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (
      'excludedKeywords' != key0 &&
      'isSuggested' != key0 &&
      'filteredBy' != key0 &&
      'isKeyword' != key0 &&
      'type' != key0 &&
      'includedKeywords' != key0
    )
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === (data1 = data.excludedKeywords))
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'excludedKeywords' },
            message: "should have required property 'excludedKeywords'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if (!Array.isArray(data1))
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.excludedKeywords',
            schemaPath: '#/properties/excludedKeywords/type',
            params: { type: 'array' },
            message: 'should be array',
          },
        ]),
        !1
      );
    for (var errs__1 = 0, i1 = 0; i1 < data1.length; i1++) {
      var errs_2 = 0;
      if ('string' != typeof data1[i1])
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.excludedKeywords[' + i1 + ']',
              schemaPath: '#/properties/excludedKeywords/items/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      if (0 !== errs_2) break;
    }
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.isSuggested)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'isSuggested' },
              message: "should have required property 'isSuggested'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'boolean' != typeof data.isSuggested))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.isSuggested',
              schemaPath: '#/properties/isSuggested/type',
              params: { type: 'boolean' },
              message: 'should be boolean',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === (data1 = data.filteredBy))
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'filteredBy' },
                message: "should have required property 'filteredBy'",
              },
            ]),
            !1
          );
        if (((errs_1 = 0), !Array.isArray(data1)))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.filteredBy',
                schemaPath: '#/properties/filteredBy/type',
                params: { type: 'array' },
                message: 'should be array',
              },
            ]),
            !1
          );
        for (errs__1 = 0, i1 = 0; i1 < data1.length; i1++) {
          if (((errs_2 = 0), 'string' != typeof data1[i1]))
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.filteredBy[' + i1 + ']',
                  schemaPath: '#/properties/filteredBy/items/type',
                  params: { type: 'string' },
                  message: 'should be string',
                },
              ]),
              !1
            );
          if (0 !== errs_2) break;
        }
        if (0 == errs__1) {
          var j,
            i = data1.length,
            valid1 = !0;
          if (i > 1)
            for (var itemIndices = {}; i--; ) {
              var item;
              if ('string' == typeof (item = data1[i])) {
                if ('number' == typeof itemIndices[item]) {
                  (valid1 = !1), (j = itemIndices[item]);
                  break;
                }
                itemIndices[item] = i;
              }
            }
          if (!valid1)
            return (
              (validate.errors = [
                {
                  keyword: 'uniqueItems',
                  dataPath: (dataPath || '') + '.filteredBy',
                  schemaPath: '#/properties/filteredBy/uniqueItems',
                  params: { i: i, j: j },
                  message:
                    'should NOT have duplicate items (items ## ' +
                    j +
                    ' and ' +
                    i +
                    ' are identical)',
                },
              ]),
              !1
            );
        }
        if ((valid1 = 0 === errs_1)) {
          if (void 0 === data.isKeyword)
            return (
              (valid1 = !1),
              (validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: { missingProperty: 'isKeyword' },
                  message: "should have required property 'isKeyword'",
                },
              ]),
              !1
            );
          if (((errs_1 = 0), 'boolean' != typeof data.isKeyword))
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.isKeyword',
                  schemaPath: '#/properties/isKeyword/type',
                  params: { type: 'boolean' },
                  message: 'should be boolean',
                },
              ]),
              !1
            );
          if ((valid1 = 0 === errs_1)) {
            if (void 0 === data.type)
              return (
                (valid1 = !1),
                (validate.errors = [
                  {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '',
                    schemaPath: '#/required',
                    params: { missingProperty: 'type' },
                    message: "should have required property 'type'",
                  },
                ]),
                !1
              );
            errs_1 = 0;
            var data1,
              schema1 = validate.schema.properties.type.enum;
            for (valid1 = !1, i1 = 0; i1 < schema1.length; i1++)
              if (equal(data.type, schema1[i1])) {
                valid1 = !0;
                break;
              }
            if (!valid1)
              return (
                (validate.errors = [
                  {
                    keyword: 'enum',
                    dataPath: (dataPath || '') + '.type',
                    schemaPath: '#/properties/type/enum',
                    params: { allowedValues: schema1 },
                    message: 'should be equal to one of the allowed values',
                  },
                ]),
                !1
              );
            if ((valid1 = 0 === errs_1))
              if (void 0 === (data1 = data.includedKeywords)) valid1 = !0;
              else {
                if (((errs_1 = 0), !Array.isArray(data1)))
                  return (
                    (validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.includedKeywords',
                        schemaPath: '#/properties/includedKeywords/type',
                        params: { type: 'array' },
                        message: 'should be array',
                      },
                    ]),
                    !1
                  );
                for (errs__1 = 0, i1 = 0; i1 < data1.length; i1++) {
                  if (((errs_2 = 0), 'string' != typeof data1[i1]))
                    return (
                      (validate.errors = [
                        {
                          keyword: 'type',
                          dataPath:
                            (dataPath || '') + '.includedKeywords[' + i1 + ']',
                          schemaPath:
                            '#/properties/includedKeywords/items/type',
                          params: { type: 'string' },
                          message: 'should be string',
                        },
                      ]),
                      !1
                    );
                  if (0 !== errs_2) break;
                }
                valid1 = 0 === errs_1;
              }
          }
        }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$7.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Applied%20Filters/version/3.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Applied Filters',
  description: 'Called when a user applies search filters ',
  type: 'object',
  properties: {
    excludedKeywords: {
      description:
        'The keywords excluded\n\n| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
    isSuggested: {
      description: 'are the current filters suggested? ',
      type: 'boolean',
    },
    filteredBy: {
      description:
        'The fields filtered by\n\n| Rule | Value |\n|---|---|\n| Unique Items | true |\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
      uniqueItems: !0,
    },
    isKeyword: { description: 'Includes a keyword filter', type: 'boolean' },
    type: {
      description:
        'The method of application\n\n| Rule | Value |\n|---|---|\n| Enum Values | custom, suggestion, saved |',
      enum: ['custom', 'suggestion', 'saved'],
    },
    includedKeywords: {
      description:
        'The keywords included\n\n| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
  },
  additionalProperties: !1,
  required: [
    'excludedKeywords',
    'isSuggested',
    'filteredBy',
    'isKeyword',
    'type',
  ],
}),
  (validate$7.errors = null);

// @ts-ignore (./AuditedHashtags)
var validate$8 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (
      'hashtags' != key0 &&
      'percentageSuitable' != key0 &&
      'percentageBanned' != key0 &&
      'percentageUnsuitable' != key0 &&
      'numberOfHashtags' != key0
    )
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    var data1 = data.hashtags;
    if (void 0 === data1)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'hashtags' },
            message: "should have required property 'hashtags'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if (!Array.isArray(data1))
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.hashtags',
            schemaPath: '#/properties/hashtags/type',
            params: { type: 'array' },
            message: 'should be array',
          },
        ]),
        !1
      );
    for (var i1 = 0; i1 < data1.length; i1++)
      if ('string' != typeof data1[i1])
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.hashtags[' + i1 + ']',
              schemaPath: '#/properties/hashtags/items/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.percentageSuitable)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'percentageSuitable' },
              message: "should have required property 'percentageSuitable'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'number' != typeof data.percentageSuitable))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.percentageSuitable',
              schemaPath: '#/properties/percentageSuitable/type',
              params: { type: 'number' },
              message: 'should be number',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.percentageBanned)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'percentageBanned' },
                message: "should have required property 'percentageBanned'",
              },
            ]),
            !1
          );
        if (((errs_1 = 0), 'number' != typeof data.percentageBanned))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.percentageBanned',
                schemaPath: '#/properties/percentageBanned/type',
                params: { type: 'number' },
                message: 'should be number',
              },
            ]),
            !1
          );
        if ((valid1 = 0 === errs_1)) {
          if (void 0 === data.percentageUnsuitable)
            return (
              (valid1 = !1),
              (validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: { missingProperty: 'percentageUnsuitable' },
                  message:
                    "should have required property 'percentageUnsuitable'",
                },
              ]),
              !1
            );
          if (((errs_1 = 0), 'number' != typeof data.percentageUnsuitable))
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.percentageUnsuitable',
                  schemaPath: '#/properties/percentageUnsuitable/type',
                  params: { type: 'number' },
                  message: 'should be number',
                },
              ]),
              !1
            );
          if ((valid1 = 0 === errs_1)) {
            if (void 0 === data.numberOfHashtags)
              return (
                (valid1 = !1),
                (validate.errors = [
                  {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '',
                    schemaPath: '#/required',
                    params: { missingProperty: 'numberOfHashtags' },
                    message: "should have required property 'numberOfHashtags'",
                  },
                ]),
                !1
              );
            if (((errs_1 = 0), 'number' != typeof data.numberOfHashtags))
              return (
                (validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.numberOfHashtags',
                    schemaPath: '#/properties/numberOfHashtags/type',
                    params: { type: 'number' },
                    message: 'should be number',
                  },
                ]),
                !1
              );
            valid1 = 0 === errs_1;
          }
        }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$8.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Audited%20Hashtags/version/3.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Audited Hashtags',
  description: 'When a user checks clicks on the audit button ',
  type: 'object',
  properties: {
    hashtags: {
      description:
        'A list of hashtags audited\n\n| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
    percentageSuitable: {
      description:
        'Percentage of hashtags which were suitable\n\n| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    percentageBanned: {
      description:
        'Percentage of hashtags which were banned\n\n| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    percentageUnsuitable: {
      description:
        'Percentage of hashtags which were unsuitable\n\n| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    numberOfHashtags: {
      description:
        'The number of hashtags audited\n\n| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
  },
  additionalProperties: !1,
  required: [
    'hashtags',
    'percentageSuitable',
    'percentageBanned',
    'percentageUnsuitable',
    'numberOfHashtags',
  ],
}),
  (validate$8.errors = null);

// @ts-ignore (./ClearedSelection)
var validate$9 = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('view' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.view)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'view' },
            message: "should have required property 'view'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.view)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.view',
            schemaPath: '#/properties/view/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$9.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Cleared%20Selection/version/2.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Cleared Selection',
  description: 'When a user clears their list of selected hashtags',
  type: 'object',
  properties: {
    view: { description: 'the view this action is made from', type: 'string' },
  },
  additionalProperties: !1,
  required: ['view'],
}),
  (validate$9.errors = null);

// @ts-ignore (./ClickedNotification)
var validate$a = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('channel' != key0 && 'type' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.channel)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'channel' },
            message: "should have required property 'channel'",
          },
        ]),
        !1
      );
    var errs_1 = 0,
      schema1 = validate.schema.properties.channel.enum;
    valid1 = !1;
    for (var i1 = 0; i1 < schema1.length; i1++)
      if (equal(data.channel, schema1[i1])) {
        valid1 = !0;
        break;
      }
    if (!valid1)
      return (
        (validate.errors = [
          {
            keyword: 'enum',
            dataPath: (dataPath || '') + '.channel',
            schemaPath: '#/properties/channel/enum',
            params: { allowedValues: schema1 },
            message: 'should be equal to one of the allowed values',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.type)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'type' },
              message: "should have required property 'type'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'string' != typeof data.type))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.type',
              schemaPath: '#/properties/type/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
  }
  return (validate.errors = null), !0;
};
(validate$a.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Clicked%20Notification/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Clicked Notification',
  description: '',
  type: 'object',
  properties: {
    channel: {
      description:
        '| Rule | Value |\n|---|---|\n| Enum Values | browser, mobile, email, web-feed |',
      enum: ['browser', 'mobile', 'email', 'web-feed'],
    },
    type: {
      description: 'The type of the notification (e.g. PostRankedSummary)',
      type: 'string',
    },
  },
  additionalProperties: !1,
  required: ['channel', 'type'],
}),
  (validate$a.errors = null);

// @ts-ignore (./ClickedOnboardingChecklistItem)
var validate$b = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('type' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.type)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'type' },
            message: "should have required property 'type'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.type)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.type',
            schemaPath: '#/properties/type/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$b.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Clicked%20Onboarding%20Checklist%20Item/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Clicked Onboarding Checklist Item',
  description: '',
  type: 'object',
  properties: { type: { description: '', type: 'string' } },
  additionalProperties: !1,
  required: ['type'],
}),
  (validate$b.errors = null);

// @ts-ignore (./CreatedCollection)
var validate$c = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('emoji' != key0 && 'name' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.emoji) valid1 = !0;
    else {
      var errs_1 = 0;
      if ('string' != typeof data.emoji)
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.emoji',
              schemaPath: '#/properties/emoji/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
    if (valid1) {
      if (void 0 === data.name)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'name' },
              message: "should have required property 'name'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'string' != typeof data.name))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.name',
              schemaPath: '#/properties/name/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
  }
  return (validate.errors = null), !0;
};
(validate$c.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Created%20Collection/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Created Collection',
  description: 'When a user creates a collection of hashtags',
  type: 'object',
  properties: {
    emoji: { description: 'The emoji used', type: 'string' },
    name: { description: 'The name of the collection', type: 'string' },
  },
  additionalProperties: !1,
  required: ['name'],
}),
  (validate$c.errors = null);

// @ts-ignore (./CreatedFlickAccount)
var validate$d = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (
      'experimentName' != key0 &&
      'method' != key0 &&
      'device' != key0 &&
      'source' != key0 &&
      'variantName' != key0
    )
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.experimentName) valid1 = !0;
    else {
      var errs_1 = 0;
      if ('string' != typeof data.experimentName)
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.experimentName',
              schemaPath: '#/properties/experimentName/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
    if (valid1) {
      if (void 0 === data.method)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'method' },
              message: "should have required property 'method'",
            },
          ]),
          !1
        );
      errs_1 = 0;
      var schema1 = validate.schema.properties.method.enum;
      valid1 = !1;
      for (var i1 = 0; i1 < schema1.length; i1++)
        if (equal(data.method, schema1[i1])) {
          valid1 = !0;
          break;
        }
      if (!valid1)
        return (
          (validate.errors = [
            {
              keyword: 'enum',
              dataPath: (dataPath || '') + '.method',
              schemaPath: '#/properties/method/enum',
              params: { allowedValues: schema1 },
              message: 'should be equal to one of the allowed values',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.device)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'device' },
                message: "should have required property 'device'",
              },
            ]),
            !1
          );
        if (((errs_1 = 0), 'string' != typeof data.device))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.device',
                schemaPath: '#/properties/device/type',
                params: { type: 'string' },
                message: 'should be string',
              },
            ]),
            !1
          );
        if ((valid1 = 0 === errs_1))
          if (void 0 === data.variantName) valid1 = !0;
          else {
            if (((errs_1 = 0), 'string' != typeof data.variantName))
              return (
                (validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.variantName',
                    schemaPath: '#/properties/variantName/type',
                    params: { type: 'string' },
                    message: 'should be string',
                  },
                ]),
                !1
              );
            valid1 = 0 === errs_1;
          }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$d.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Created%20Flick%20Account/version/4.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Created Flick Account',
  description:
    'Called when a user created their Flick account (email and password ',
  type: 'object',
  properties: {
    experimentName: { description: '', type: 'string' },
    method: {
      description:
        'The auth method of register\n\n| Rule | Value |\n|---|---|\n| Enum Values | email, google, facebook, apple |',
    },
    device: { description: '', type: 'string' },
    variantName: { description: '', type: 'string' },
    source: { description: '' },
  },
  additionalProperties: !1,
  required: ['method', 'device', 'source'],
}),
  (validate$d.errors = null);

// @ts-ignore (./CreatedSavedFilter)
var validate$e = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('name' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.name)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'name' },
            message: "should have required property 'name'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.name)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.name',
            schemaPath: '#/properties/name/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$e.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Created%20Saved%20Filter/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Created Saved Filter',
  description: '',
  type: 'object',
  properties: {
    name: { description: 'The name of the saved filter', type: 'string' },
  },
  additionalProperties: !1,
  required: ['name'],
}),
  (validate$e.errors = null);

// @ts-ignore (./DeletedSavedFilter)
var validate$f = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('name' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.name)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'name' },
            message: "should have required property 'name'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.name)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.name',
            schemaPath: '#/properties/name/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$f.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Deleted%20Saved%20Filter/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Deleted Saved Filter',
  description: '',
  type: 'object',
  properties: {
    name: { description: 'The name of the saved filter', type: 'string' },
  },
  additionalProperties: !1,
  required: ['name'],
}),
  (validate$f.errors = null);

// @ts-ignore (./DismissedSuggestedHashtag)
var validate$g = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  for (var key0 in data)
    return (
      (validate.errors = [
        {
          keyword: 'additionalProperties',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: '' + key0 },
          message: 'should NOT have additional properties',
        },
      ]),
      !1
    );
  return (validate.errors = null), !0;
};
(validate$g.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Dismissed%20Suggested%20Hashtag/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Dismissed Suggested Hashtag',
  description:
    'Triggered when a user successfully rejects a suggested hashtag in the the suggest hashtags modal ',
  type: 'object',
  properties: {},
  additionalProperties: !1,
  required: [],
}),
  (validate$g.errors = null);

// @ts-ignore (./ExpandedHashtag)
var validate$h = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (
      'isRefresh' != key0 &&
      'limit' != key0 &&
      'isSuggested' != key0 &&
      'hashtag' != key0 &&
      'skip' != key0
    )
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.isRefresh)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'isRefresh' },
            message: "should have required property 'isRefresh'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('boolean' != typeof data.isRefresh)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.isRefresh',
            schemaPath: '#/properties/isRefresh/type',
            params: { type: 'boolean' },
            message: 'should be boolean',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.limit)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'limit' },
              message: "should have required property 'limit'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'number' != typeof data.limit))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.limit',
              schemaPath: '#/properties/limit/type',
              params: { type: 'number' },
              message: 'should be number',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.isSuggested)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'isSuggested' },
                message: "should have required property 'isSuggested'",
              },
            ]),
            !1
          );
        if (((errs_1 = 0), 'boolean' != typeof data.isSuggested))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.isSuggested',
                schemaPath: '#/properties/isSuggested/type',
                params: { type: 'boolean' },
                message: 'should be boolean',
              },
            ]),
            !1
          );
        if ((valid1 = 0 === errs_1)) {
          if (void 0 === data.hashtag)
            return (
              (valid1 = !1),
              (validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: { missingProperty: 'hashtag' },
                  message: "should have required property 'hashtag'",
                },
              ]),
              !1
            );
          if (((errs_1 = 0), 'string' != typeof data.hashtag))
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.hashtag',
                  schemaPath: '#/properties/hashtag/type',
                  params: { type: 'string' },
                  message: 'should be string',
                },
              ]),
              !1
            );
          if ((valid1 = 0 === errs_1)) {
            if (void 0 === data.skip)
              return (
                (valid1 = !1),
                (validate.errors = [
                  {
                    keyword: 'required',
                    dataPath: (dataPath || '') + '',
                    schemaPath: '#/required',
                    params: { missingProperty: 'skip' },
                    message: "should have required property 'skip'",
                  },
                ]),
                !1
              );
            if (((errs_1 = 0), 'number' != typeof data.skip))
              return (
                (validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.skip',
                    schemaPath: '#/properties/skip/type',
                    params: { type: 'number' },
                    message: 'should be number',
                  },
                ]),
                !1
              );
            valid1 = 0 === errs_1;
          }
        }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$h.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Expanded%20Hashtag/version/2.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Expanded Hashtag',
  description: 'Called when a user clicks the expand button',
  type: 'object',
  properties: {
    isRefresh: {
      description:
        'Is as of a result of an automatic refresh due to filter changes',
      type: 'boolean',
    },
    limit: {
      description: '| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    isSuggested: {
      description: 'True if the suggested filters were activated',
      type: 'boolean',
    },
    hashtag: { description: 'The name of the hashtag', type: 'string' },
    skip: {
      description: '| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
  },
  additionalProperties: !1,
  required: ['isRefresh', 'limit', 'isSuggested', 'hashtag', 'skip'],
}),
  (validate$h.errors = null);

// @ts-ignore (./ExpandedMetric)
var validate$i = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('name' != key0 && 'comparisonType' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.name)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'name' },
            message: "should have required property 'name'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('string' != typeof data.name)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.name',
            schemaPath: '#/properties/name/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.comparisonType)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'comparisonType' },
              message: "should have required property 'comparisonType'",
            },
          ]),
          !1
        );
      errs_1 = 0;
      var schema1 = validate.schema.properties.comparisonType.enum;
      valid1 = !1;
      for (var i1 = 0; i1 < schema1.length; i1++)
        if (equal(data.comparisonType, schema1[i1])) {
          valid1 = !0;
          break;
        }
      if (!valid1)
        return (
          (validate.errors = [
            {
              keyword: 'enum',
              dataPath: (dataPath || '') + '.comparisonType',
              schemaPath: '#/properties/comparisonType/enum',
              params: { allowedValues: schema1 },
              message: 'should be equal to one of the allowed values',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
  }
  return (validate.errors = null), !0;
};
(validate$i.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Expanded%20Metric/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Expanded Metric',
  description: 'When a user expands hero metric in analytics',
  type: 'object',
  properties: {
    name: { description: '', type: 'string' },
    comparisonType: {
      description:
        '| Rule | Value |\n|---|---|\n| Enum Values | lastPeriod, similarAccounts |',
      enum: ['lastPeriod', 'similarAccounts'],
    },
  },
  additionalProperties: !1,
  required: ['name', 'comparisonType'],
}),
  (validate$i.errors = null);

// @ts-ignore (./ExportedHashtags)
var validate$j = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('random' != key0 && 'numHashtags' != key0 && 'view' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.random)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'random' },
            message: "should have required property 'random'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('boolean' != typeof data.random)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.random',
            schemaPath: '#/properties/random/type',
            params: { type: 'boolean' },
            message: 'should be boolean',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.numHashtags)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'numHashtags' },
              message: "should have required property 'numHashtags'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'number' != typeof data.numHashtags))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.numHashtags',
              schemaPath: '#/properties/numHashtags/type',
              params: { type: 'number' },
              message: 'should be number',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.view)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'view' },
                message: "should have required property 'view'",
              },
            ]),
            !1
          );
        errs_1 = 0;
        var schema1 = validate.schema.properties.view.enum;
        valid1 = !1;
        for (var i1 = 0; i1 < schema1.length; i1++)
          if (equal(data.view, schema1[i1])) {
            valid1 = !0;
            break;
          }
        if (!valid1)
          return (
            (validate.errors = [
              {
                keyword: 'enum',
                dataPath: (dataPath || '') + '.view',
                schemaPath: '#/properties/view/enum',
                params: { allowedValues: schema1 },
                message: 'should be equal to one of the allowed values',
              },
            ]),
            !1
          );
        valid1 = 0 === errs_1;
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$j.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Exported%20Hashtags/version/2.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Exported Hashtags',
  description: 'Called when a user exports one or more hashtags',
  type: 'object',
  properties: {
    random: { description: 'yes, no', type: 'boolean' },
    numHashtags: {
      description:
        'The number of hashtags exported \n\n| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    view: {
      description:
        'collection, search, content, hashtagInsights\n\n| Rule | Value |\n|---|---|\n| Enum Values | search, collections, content, hashtagInsights, dashboard |',
      enum: [
        'search',
        'collections',
        'content',
        'hashtagInsights',
        'dashboard',
      ],
    },
  },
  additionalProperties: !1,
  required: ['random', 'numHashtags', 'view'],
}),
  (validate$j.errors = null);

// @ts-ignore (./LinkedIGAccountError)
var validate$k = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('message' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.message)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'message' },
            message: "should have required property 'message'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.message)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.message',
            schemaPath: '#/properties/message/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$k.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Linked%20IG%20Account%20Error/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Linked IG Account Error',
  description: 'When a user has an error with linking IG accounts',
  type: 'object',
  properties: { message: { description: 'Error message', type: 'string' } },
  additionalProperties: !1,
  required: ['message'],
}),
  (validate$k.errors = null);

// @ts-ignore (./LinkedIGAccounts)
var validate$l = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('numAccounts' != key0 && 'usernames' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.numAccounts)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'numAccounts' },
            message: "should have required property 'numAccounts'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('number' != typeof data.numAccounts)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.numAccounts',
            schemaPath: '#/properties/numAccounts/type',
            params: { type: 'number' },
            message: 'should be number',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.usernames)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'usernames' },
              message: "should have required property 'usernames'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'string' != typeof data.usernames))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.usernames',
              schemaPath: '#/properties/usernames/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
  }
  return (validate.errors = null), !0;
};
(validate$l.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Linked%20IG%20Accounts/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Linked IG Accounts',
  description: 'When a user links one or many IG accounts to Flick',
  type: 'object',
  properties: {
    numAccounts: {
      description:
        'Number of accounts linked\n\n| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    usernames: {
      description: 'A list of comma separated usernames linked',
      type: 'string',
    },
  },
  additionalProperties: !1,
  required: ['numAccounts', 'usernames'],
}),
  (validate$l.errors = null);

// @ts-ignore (./OpenedSuggestedHashtags)
var validate$m = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  for (var key0 in data)
    return (
      (validate.errors = [
        {
          keyword: 'additionalProperties',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: '' + key0 },
          message: 'should NOT have additional properties',
        },
      ]),
      !1
    );
  return (validate.errors = null), !0;
};
(validate$m.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Opened%20Suggested%20Hashtags/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Opened Suggested Hashtags',
  description: 'Triggered when a user successfully opens suggested hashtags ',
  type: 'object',
  properties: {},
  additionalProperties: !1,
  required: [],
}),
  (validate$m.errors = null);

// @ts-ignore (./PreviewHashtag)
var validate$n = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('hashtag' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.hashtag)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'hashtag' },
            message: "should have required property 'hashtag'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.hashtag)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.hashtag',
            schemaPath: '#/properties/hashtag/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$n.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Preview%20Hashtag/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Preview Hashtag',
  description: 'When a user previews a hashtag in Instagram\n',
  type: 'object',
  properties: { hashtag: { description: 'Hashtag name', type: 'string' } },
  additionalProperties: !1,
  required: ['hashtag'],
}),
  (validate$n.errors = null);

// @ts-ignore (./RedirectedFromReports)
var validate$o = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('destination' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.destination)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'destination' },
            message: "should have required property 'destination'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.destination)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.destination',
            schemaPath: '#/properties/destination/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$o.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Redirected%20from%20Reports/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Redirected from Reports',
  description: '',
  type: 'object',
  properties: { destination: { description: '', type: 'string' } },
  additionalProperties: !1,
  required: ['destination'],
}),
  (validate$o.errors = null);

// @ts-ignore (./RemovedCollectionFromFavourites)
var validate$p = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('name' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.name)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'name' },
            message: "should have required property 'name'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.name)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.name',
            schemaPath: '#/properties/name/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$p.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Removed%20Collection%20From%20Favourites/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Removed Collection From Favourites',
  description: 'When a users removes a collection to favourites',
  type: 'object',
  properties: { name: { description: 'Collection name', type: 'string' } },
  additionalProperties: !1,
  required: ['name'],
}),
  (validate$p.errors = null);

// @ts-ignore (./SearchedHashtag)
var validate$q = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (
      'limit' != key0 &&
      'hashtag' != key0 &&
      'skip' != key0 &&
      'isRefresh' != key0 &&
      'queryType' != key0 &&
      'type' != key0 &&
      'isSuggested' != key0
    )
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.limit)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'limit' },
            message: "should have required property 'limit'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('number' != typeof data.limit)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.limit',
            schemaPath: '#/properties/limit/type',
            params: { type: 'number' },
            message: 'should be number',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.hashtag)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'hashtag' },
              message: "should have required property 'hashtag'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'string' != typeof data.hashtag))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.hashtag',
              schemaPath: '#/properties/hashtag/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.skip)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'skip' },
                message: "should have required property 'skip'",
              },
            ]),
            !1
          );
        if (((errs_1 = 0), 'number' != typeof data.skip))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.skip',
                schemaPath: '#/properties/skip/type',
                params: { type: 'number' },
                message: 'should be number',
              },
            ]),
            !1
          );
        if ((valid1 = 0 === errs_1)) {
          if (void 0 === data.isRefresh)
            return (
              (valid1 = !1),
              (validate.errors = [
                {
                  keyword: 'required',
                  dataPath: (dataPath || '') + '',
                  schemaPath: '#/required',
                  params: { missingProperty: 'isRefresh' },
                  message: "should have required property 'isRefresh'",
                },
              ]),
              !1
            );
          if (((errs_1 = 0), 'boolean' != typeof data.isRefresh))
            return (
              (validate.errors = [
                {
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.isRefresh',
                  schemaPath: '#/properties/isRefresh/type',
                  params: { type: 'boolean' },
                  message: 'should be boolean',
                },
              ]),
              !1
            );
          if ((valid1 = 0 === errs_1)) {
            if (void 0 === data.queryType) valid1 = !0;
            else {
              errs_1 = 0;
              var schema1 = validate.schema.properties.queryType.enum;
              valid1 = !1;
              for (var i1 = 0; i1 < schema1.length; i1++)
                if (equal(data.queryType, schema1[i1])) {
                  valid1 = !0;
                  break;
                }
              if (!valid1)
                return (
                  (validate.errors = [
                    {
                      keyword: 'enum',
                      dataPath: (dataPath || '') + '.queryType',
                      schemaPath: '#/properties/queryType/enum',
                      params: { allowedValues: schema1 },
                      message: 'should be equal to one of the allowed values',
                    },
                  ]),
                  !1
                );
              valid1 = 0 === errs_1;
            }
            if (valid1) {
              if (void 0 === data.type)
                return (
                  (valid1 = !1),
                  (validate.errors = [
                    {
                      keyword: 'required',
                      dataPath: (dataPath || '') + '',
                      schemaPath: '#/required',
                      params: { missingProperty: 'type' },
                      message: "should have required property 'type'",
                    },
                  ]),
                  !1
                );
              for (
                errs_1 = 0,
                  schema1 = validate.schema.properties.type.enum,
                  valid1 = !1,
                  i1 = 0;
                i1 < schema1.length;
                i1++
              )
                if (equal(data.type, schema1[i1])) {
                  valid1 = !0;
                  break;
                }
              if (!valid1)
                return (
                  (validate.errors = [
                    {
                      keyword: 'enum',
                      dataPath: (dataPath || '') + '.type',
                      schemaPath: '#/properties/type/enum',
                      params: { allowedValues: schema1 },
                      message: 'should be equal to one of the allowed values',
                    },
                  ]),
                  !1
                );
              if ((valid1 = 0 === errs_1)) {
                if (void 0 === data.isSuggested)
                  return (
                    (valid1 = !1),
                    (validate.errors = [
                      {
                        keyword: 'required',
                        dataPath: (dataPath || '') + '',
                        schemaPath: '#/required',
                        params: { missingProperty: 'isSuggested' },
                        message: "should have required property 'isSuggested'",
                      },
                    ]),
                    !1
                  );
                if (((errs_1 = 0), 'boolean' != typeof data.isSuggested))
                  return (
                    (validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.isSuggested',
                        schemaPath: '#/properties/isSuggested/type',
                        params: { type: 'boolean' },
                        message: 'should be boolean',
                      },
                    ]),
                    !1
                  );
                valid1 = 0 === errs_1;
              }
            }
          }
        }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$q.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Searched%20Hashtag/version/5.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Searched Hashtag',
  description: 'Called when a user makes a hashtag search of any type ',
  type: 'object',
  properties: {
    limit: {
      description: '| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    hashtag: { description: 'The name of the hashtag', type: 'string' },
    skip: {
      description: '| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    isRefresh: {
      description:
        'Is as of a result of an automatic refresh due to filter changes',
      type: 'boolean',
    },
    queryType: {
      description:
        'Only set when a user makes a "manual" search\n\n| Rule | Value |\n|---|---|\n| Enum Values | Manual, Recent, Suggested, None |',
      enum: ['Manual', 'Recent', 'Suggested', 'None'],
    },
    type: {
      description:
        '| Rule | Value |\n|---|---|\n| Enum Values | basic, expand, advanced |',
      enum: ['basic', 'expand', 'advanced'],
    },
    isSuggested: {
      description: 'True if the suggested filters were activated',
      type: 'boolean',
    },
  },
  additionalProperties: !1,
  required: ['limit', 'hashtag', 'skip', 'isRefresh', 'type', 'isSuggested'],
}),
  (validate$q.errors = null);

// @ts-ignore (./SelectedHashtags)
var validate$r = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('numHashtags' != key0 && 'view' != key0 && 'actionType' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.numHashtags)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'numHashtags' },
            message: "should have required property 'numHashtags'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('number' != typeof data.numHashtags)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.numHashtags',
            schemaPath: '#/properties/numHashtags/type',
            params: { type: 'number' },
            message: 'should be number',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.view)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'view' },
              message: "should have required property 'view'",
            },
          ]),
          !1
        );
      errs_1 = 0;
      var schema1 = validate.schema.properties.view.enum;
      valid1 = !1;
      for (var i1 = 0; i1 < schema1.length; i1++)
        if (equal(data.view, schema1[i1])) {
          valid1 = !0;
          break;
        }
      if (!valid1)
        return (
          (validate.errors = [
            {
              keyword: 'enum',
              dataPath: (dataPath || '') + '.view',
              schemaPath: '#/properties/view/enum',
              params: { allowedValues: schema1 },
              message: 'should be equal to one of the allowed values',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.actionType)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'actionType' },
                message: "should have required property 'actionType'",
              },
            ]),
            !1
          );
        for (
          errs_1 = 0,
            schema1 = validate.schema.properties.actionType.enum,
            valid1 = !1,
            i1 = 0;
          i1 < schema1.length;
          i1++
        )
          if (equal(data.actionType, schema1[i1])) {
            valid1 = !0;
            break;
          }
        if (!valid1)
          return (
            (validate.errors = [
              {
                keyword: 'enum',
                dataPath: (dataPath || '') + '.actionType',
                schemaPath: '#/properties/actionType/enum',
                params: { allowedValues: schema1 },
                message: 'should be equal to one of the allowed values',
              },
            ]),
            !1
          );
        valid1 = 0 === errs_1;
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$r.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Selected%20Hashtags/version/6.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Selected Hashtags',
  description: 'Called when a user selects one or multiple hashtags ',
  type: 'object',
  properties: {
    numHashtags: {
      description:
        'Number of hashtags selected\n\n| Rule | Value |\n|---|---|\n| Type | number |',
      type: 'number',
    },
    view: {
      description:
        'Where in the app this was made: Search, Collection, Analytics \n\n| Rule | Value |\n|---|---|\n| Enum Values | search, collection, analytics, hashtags-insights, content, smart-collection, pre-filled-collection |',
      enum: [
        'search',
        'collection',
        'analytics',
        'hashtags-insights',
        'content',
        'smart-collection',
        'pre-filled-collection',
      ],
    },
    actionType: {
      description:
        'Type of select: "all", "single", "random"\n\n| Rule | Value |\n|---|---|\n| Enum Values | all, did not rank, high competition, low competition, medium competition, random, single, ranked hashtags |',
      enum: [
        'all',
        'did not rank',
        'high competition',
        'low competition',
        'medium competition',
        'random',
        'single',
        'ranked hashtags',
      ],
    },
  },
  additionalProperties: !1,
  required: ['numHashtags', 'view', 'actionType'],
}),
  (validate$r.errors = null);

// @ts-ignore (./SelectedNumAccounts)
var validate$s = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('numAccounts' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.numAccounts)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'numAccounts' },
            message: "should have required property 'numAccounts'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.numAccounts)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.numAccounts',
            schemaPath: '#/properties/numAccounts/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$s.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Selected%20Num%20Accounts/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Selected Num Accounts',
  description:
    'When a user selects the number of ig accounts they manage during onboarding',
  type: 'object',
  properties: {
    numAccounts: {
      description: 'The number of ig accounts selected (range)',
      type: 'string',
    },
  },
  additionalProperties: !1,
  required: ['numAccounts'],
}),
  (validate$s.errors = null);

// @ts-ignore (./SelectedOnboardingAims)
var validate$t = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('aims' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    var data1 = data.aims;
    if (void 0 === data1)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'aims' },
            message: "should have required property 'aims'",
          },
        ]),
        !1
      );
    if (!Array.isArray(data1))
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.aims',
            schemaPath: '#/properties/aims/type',
            params: { type: 'array' },
            message: 'should be array',
          },
        ]),
        !1
      );
    for (var i1 = 0; i1 < data1.length; i1++)
      if ('string' != typeof data1[i1])
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.aims[' + i1 + ']',
              schemaPath: '#/properties/aims/items/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$t.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Selected%20Onboarding%20Aims/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Selected Onboarding Aims',
  description: 'When a user selects their aim during onboarding',
  type: 'object',
  properties: {
    aims: {
      description:
        'The aim selected\n\n| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
  },
  additionalProperties: !1,
  required: ['aims'],
}),
  (validate$t.errors = null);

// @ts-ignore (./SelectedPlan)
var validate$u = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('plan' != key0 && 'addons' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.plan)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'plan' },
            message: "should have required property 'plan'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('string' != typeof data.plan)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.plan',
            schemaPath: '#/properties/plan/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      var data1 = data.addons;
      if (void 0 === data1)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'addons' },
              message: "should have required property 'addons'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), !Array.isArray(data1)))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.addons',
              schemaPath: '#/properties/addons/type',
              params: { type: 'array' },
              message: 'should be array',
            },
          ]),
          !1
        );
      for (var i1 = 0; i1 < data1.length; i1++)
        if ('string' != typeof data1[i1])
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.addons[' + i1 + ']',
                schemaPath: '#/properties/addons/items/type',
                params: { type: 'string' },
                message: 'should be string',
              },
            ]),
            !1
          );
      valid1 = 0 === errs_1;
    }
  }
  return (validate.errors = null), !0;
};
(validate$u.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Selected%20Plan/version/2.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Selected Plan',
  description:
    'Called when a user has successfully selected his plans and addons ',
  type: 'object',
  properties: {
    plan: { description: '', type: 'string' },
    addons: {
      description: '| Rule | Value |\n|---|---|\n| Item Type | string |',
      type: 'array',
      items: { type: 'string' },
    },
  },
  additionalProperties: !1,
  required: ['plan', 'addons'],
}),
  (validate$u.errors = null);

// @ts-ignore (./SelectedTimePeriod)
var validate$v = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('name' != key0 && 'timePeriod' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.name)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'name' },
            message: "should have required property 'name'",
          },
        ]),
        !1
      );
    var errs_1 = 0,
      schema1 = validate.schema.properties.name.enum;
    valid1 = !1;
    for (var i1 = 0; i1 < schema1.length; i1++)
      if (equal(data.name, schema1[i1])) {
        valid1 = !0;
        break;
      }
    if (!valid1)
      return (
        (validate.errors = [
          {
            keyword: 'enum',
            dataPath: (dataPath || '') + '.name',
            schemaPath: '#/properties/name/enum',
            params: { allowedValues: schema1 },
            message: 'should be equal to one of the allowed values',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.timePeriod)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'timePeriod' },
              message: "should have required property 'timePeriod'",
            },
          ]),
          !1
        );
      for (
        errs_1 = 0,
          schema1 = validate.schema.properties.timePeriod.enum,
          valid1 = !1,
          i1 = 0;
        i1 < schema1.length;
        i1++
      )
        if (equal(data.timePeriod, schema1[i1])) {
          valid1 = !0;
          break;
        }
      if (!valid1)
        return (
          (validate.errors = [
            {
              keyword: 'enum',
              dataPath: (dataPath || '') + '.timePeriod',
              schemaPath: '#/properties/timePeriod/enum',
              params: { allowedValues: schema1 },
              message: 'should be equal to one of the allowed values',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
  }
  return (validate.errors = null), !0;
};
(validate$v.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Selected%20Time%20Period/version/4.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Selected Time Period',
  description:
    'Called when a user applies a time period on any of the analytics pages. This should not trigger when the page is loaded with the default time period.',
  type: 'object',
  properties: {
    name: {
      description:
        'The page the event was triggered on\n\n| Rule | Value |\n|---|---|\n| Enum Values | content, hashtags, overview, audience |',
      enum: ['content', 'hashtags', 'overview', 'audience'],
    },
    timePeriod: {
      description:
        'The time period the user selected \n\n| Rule | Value |\n|---|---|\n| Enum Values | 14_days, 180_days, 30_days, 365_days, 7_days, 90_days, custom |',
      enum: [
        '14_days',
        '180_days',
        '30_days',
        '365_days',
        '7_days',
        '90_days',
        'custom',
      ],
    },
  },
  additionalProperties: !1,
  required: ['name', 'timePeriod'],
}),
  (validate$v.errors = null);

// @ts-ignore (./SelectedUseCase)
var validate$w = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('name' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.name)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'name' },
            message: "should have required property 'name'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.name)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.name',
            schemaPath: '#/properties/name/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$w.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Selected%20Use%20Case/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Selected Use Case',
  description: 'When a user selects a use case during onboarding',
  type: 'object',
  properties: {
    name: { description: 'The use case selected', type: 'string' },
  },
  additionalProperties: !1,
  required: ['name'],
}),
  (validate$w.errors = null);

// @ts-ignore (./ToggledAnalyticsContentType)
var validate$x = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('type' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.type)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'type' },
            message: "should have required property 'type'",
          },
        ]),
        !1
      );
    var schema1 = validate.schema.properties.type.enum;
    valid1 = !1;
    for (var i1 = 0; i1 < schema1.length; i1++)
      if (equal(data.type, schema1[i1])) {
        valid1 = !0;
        break;
      }
    if (!valid1)
      return (
        (validate.errors = [
          {
            keyword: 'enum',
            dataPath: (dataPath || '') + '.type',
            schemaPath: '#/properties/type/enum',
            params: { allowedValues: schema1 },
            message: 'should be equal to one of the allowed values',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$x.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Toggled%20Analytics%20Content%20Type/version/2.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Toggled Analytics Content Type',
  description:
    'Called when a user toggles between content type on the content page',
  type: 'object',
  properties: {
    type: {
      description:
        '| Rule | Value |\n|---|---|\n| Enum Values | feed, igtv, stories |',
      enum: ['feed', 'igtv', 'stories'],
    },
  },
  additionalProperties: !1,
  required: ['type'],
}),
  (validate$x.errors = null);

// @ts-ignore (./ToggledPositionGroup)
var validate$y = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('positionGroup' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.positionGroup)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'positionGroup' },
            message: "should have required property 'positionGroup'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.positionGroup)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.positionGroup',
            schemaPath: '#/properties/positionGroup/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$y.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Toggled%20Position%20Group/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Toggled Position Group',
  description:
    'When a user toggles between the different "Position Groups" in the content section eg: top 9, top 10-25...',
  type: 'object',
  properties: {
    positionGroup: {
      description: 'The group that they viewed eg: top 9, top 10-25, top 25-50',
      type: 'string',
    },
  },
  additionalProperties: !1,
  required: ['positionGroup'],
}),
  (validate$y.errors = null);

// @ts-ignore (./ToggledSelectedHashtags)
var validate$z = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('nextState' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.nextState)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'nextState' },
            message: "should have required property 'nextState'",
          },
        ]),
        !1
      );
    var schema1 = validate.schema.properties.nextState.enum;
    valid1 = !1;
    for (var i1 = 0; i1 < schema1.length; i1++)
      if (equal(data.nextState, schema1[i1])) {
        valid1 = !0;
        break;
      }
    if (!valid1)
      return (
        (validate.errors = [
          {
            keyword: 'enum',
            dataPath: (dataPath || '') + '.nextState',
            schemaPath: '#/properties/nextState/enum',
            params: { allowedValues: schema1 },
            message: 'should be equal to one of the allowed values',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$z.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Toggled%20Selected%20Hashtags/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Toggled Selected Hashtags',
  description: '',
  type: 'object',
  properties: {
    nextState: {
      description:
        '| Rule | Value |\n|---|---|\n| Enum Values | open, closed |',
      enum: ['open', 'closed'],
    },
  },
  additionalProperties: !1,
  required: ['nextState'],
}),
  (validate$z.errors = null);

// @ts-ignore (./ToggledSidebar)
var validate$A = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('nextState' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.nextState)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'nextState' },
            message: "should have required property 'nextState'",
          },
        ]),
        !1
      );
    var schema1 = validate.schema.properties.nextState.enum;
    valid1 = !1;
    for (var i1 = 0; i1 < schema1.length; i1++)
      if (equal(data.nextState, schema1[i1])) {
        valid1 = !0;
        break;
      }
    if (!valid1)
      return (
        (validate.errors = [
          {
            keyword: 'enum',
            dataPath: (dataPath || '') + '.nextState',
            schemaPath: '#/properties/nextState/enum',
            params: { allowedValues: schema1 },
            message: 'should be equal to one of the allowed values',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$A.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Toggled%20Sidebar/version/3.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Toggled Sidebar',
  description:
    'Called when a user opens / closes the sidebar to view hashtags ',
  type: 'object',
  properties: {
    nextState: {
      description:
        'The state that the user toggled to. [opened, closed]\n\n| Rule | Value |\n|---|---|\n| Enum Values | opened, closed |',
      enum: ['opened', 'closed'],
    },
  },
  additionalProperties: !1,
  required: ['nextState'],
}),
  (validate$A.errors = null);

// @ts-ignore (./ToggledSuggestFilters)
var validate$B = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('username' != key0 && 'nextState' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.username)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'username' },
            message: "should have required property 'username'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('string' != typeof data.username)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.username',
            schemaPath: '#/properties/username/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.nextState)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'nextState' },
              message: "should have required property 'nextState'",
            },
          ]),
          !1
        );
      errs_1 = 0;
      var schema1 = validate.schema.properties.nextState.enum;
      valid1 = !1;
      for (var i1 = 0; i1 < schema1.length; i1++)
        if (equal(data.nextState, schema1[i1])) {
          valid1 = !0;
          break;
        }
      if (!valid1)
        return (
          (validate.errors = [
            {
              keyword: 'enum',
              dataPath: (dataPath || '') + '.nextState',
              schemaPath: '#/properties/nextState/enum',
              params: { allowedValues: schema1 },
              message: 'should be equal to one of the allowed values',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
  }
  return (validate.errors = null), !0;
};
(validate$B.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Toggled%20Suggest%20Filters/version/2.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Toggled Suggest Filters',
  description: 'Called when a user toggles suggested filters ',
  type: 'object',
  properties: {
    username: {
      description: 'The username of the ig account used',
      type: 'string',
    },
    nextState: {
      description:
        '| Rule | Value |\n|---|---|\n| Enum Values | active, inactive |',
      enum: ['active', 'inactive'],
    },
  },
  additionalProperties: !1,
  required: ['username', 'nextState'],
}),
  (validate$B.errors = null);

// @ts-ignore (./ToggledWeeklyReports)
var validate$C = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('nextState' != key0 && 'username' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.nextState)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'nextState' },
            message: "should have required property 'nextState'",
          },
        ]),
        !1
      );
    var errs_1 = 0;
    if ('boolean' != typeof data.nextState)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.nextState',
            schemaPath: '#/properties/nextState/type',
            params: { type: 'boolean' },
            message: 'should be boolean',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.username)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'username' },
              message: "should have required property 'username'",
            },
          ]),
          !1
        );
      if (((errs_1 = 0), 'string' != typeof data.username))
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.username',
              schemaPath: '#/properties/username/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
  }
  return (validate.errors = null), !0;
};
(validate$C.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Toggled%20Weekly%20Reports/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Toggled Weekly Reports',
  description: '',
  type: 'object',
  properties: {
    nextState: { description: '', type: 'boolean' },
    username: { description: '', type: 'string' },
  },
  additionalProperties: !1,
  required: ['nextState', 'username'],
}),
  (validate$C.errors = null);

// @ts-ignore (./UnlinkedIGAccount)
var validate$D = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('username' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.username)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'username' },
            message: "should have required property 'username'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.username)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.username',
            schemaPath: '#/properties/username/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$D.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Unlinked%20IG%20Account/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Unlinked IG Account',
  description: 'When a user un-links an IG account from Flick',
  type: 'object',
  properties: {
    username: {
      description: 'The username of the account linked',
      type: 'string',
    },
  },
  additionalProperties: !1,
  required: ['username'],
}),
  (validate$D.errors = null);

// @ts-ignore (./UpdatedSavedFilter)
var validate$E = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('name' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.name)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'name' },
            message: "should have required property 'name'",
          },
        ]),
        !1
      );
    if ('string' != typeof data.name)
      return (
        (validate.errors = [
          {
            keyword: 'type',
            dataPath: (dataPath || '') + '.name',
            schemaPath: '#/properties/name/type',
            params: { type: 'string' },
            message: 'should be string',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$E.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Updated%20Saved%20Filter/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Updated Saved Filter',
  description: '',
  type: 'object',
  properties: {
    name: { description: 'The name of the filter', type: 'string' },
  },
  additionalProperties: !1,
  required: ['name'],
}),
  (validate$E.errors = null);

// @ts-ignore (./UsedShortcut)
var validate$F = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('action' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.action)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'action' },
            message: "should have required property 'action'",
          },
        ]),
        !1
      );
    var schema1 = validate.schema.properties.action.enum;
    valid1 = !1;
    for (var i1 = 0; i1 < schema1.length; i1++)
      if (equal(data.action, schema1[i1])) {
        valid1 = !0;
        break;
      }
    if (!valid1)
      return (
        (validate.errors = [
          {
            keyword: 'enum',
            dataPath: (dataPath || '') + '.action',
            schemaPath: '#/properties/action/enum',
            params: { allowedValues: schema1 },
            message: 'should be equal to one of the allowed values',
          },
        ]),
        !1
      );
    valid1 = !0;
  }
  return (validate.errors = null), !0;
};
(validate$F.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Used%20Shortcut/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Used Shortcut',
  description: 'Made an action using a keyboard shortcut ',
  type: 'object',
  properties: {
    action: {
      description:
        '| Rule | Value |\n|---|---|\n| Enum Values | exported, added to collection, toggled sidebar, cleared selection, audited |',
      enum: [
        'exported',
        'added to collection',
        'toggled sidebar',
        'cleared selection',
        'audited',
      ],
    },
  },
  additionalProperties: !1,
  required: ['action'],
}),
  (validate$F.errors = null);

// @ts-ignore (./ViewedCollection)
var validate$G = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if ('type' != key0 && 'source' != key0 && 'name' != key0)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.type)
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'required',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/required',
            params: { missingProperty: 'type' },
            message: "should have required property 'type'",
          },
        ]),
        !1
      );
    var errs_1 = 0,
      schema1 = validate.schema.properties.type.enum;
    valid1 = !1;
    for (var i1 = 0; i1 < schema1.length; i1++)
      if (equal(data.type, schema1[i1])) {
        valid1 = !0;
        break;
      }
    if (!valid1)
      return (
        (validate.errors = [
          {
            keyword: 'enum',
            dataPath: (dataPath || '') + '.type',
            schemaPath: '#/properties/type/enum',
            params: { allowedValues: schema1 },
            message: 'should be equal to one of the allowed values',
          },
        ]),
        !1
      );
    if ((valid1 = 0 === errs_1)) {
      if (void 0 === data.source)
        return (
          (valid1 = !1),
          (validate.errors = [
            {
              keyword: 'required',
              dataPath: (dataPath || '') + '',
              schemaPath: '#/required',
              params: { missingProperty: 'source' },
              message: "should have required property 'source'",
            },
          ]),
          !1
        );
      for (
        errs_1 = 0,
          schema1 = validate.schema.properties.source.enum,
          valid1 = !1,
          i1 = 0;
        i1 < schema1.length;
        i1++
      )
        if (equal(data.source, schema1[i1])) {
          valid1 = !0;
          break;
        }
      if (!valid1)
        return (
          (validate.errors = [
            {
              keyword: 'enum',
              dataPath: (dataPath || '') + '.source',
              schemaPath: '#/properties/source/enum',
              params: { allowedValues: schema1 },
              message: 'should be equal to one of the allowed values',
            },
          ]),
          !1
        );
      if ((valid1 = 0 === errs_1)) {
        if (void 0 === data.name)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'name' },
                message: "should have required property 'name'",
              },
            ]),
            !1
          );
        if (((errs_1 = 0), 'string' != typeof data.name))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.name',
                schemaPath: '#/properties/name/type',
                params: { type: 'string' },
                message: 'should be string',
              },
            ]),
            !1
          );
        valid1 = 0 === errs_1;
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$G.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Viewed%20Collection/version/4.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Viewed Collection',
  description: '',
  type: 'object',
  properties: {
    type: {
      description:
        'What type of collection is being viewed\n\n| Rule | Value |\n|---|---|\n| Enum Values | Normal, Smart, Pre-filled |',
      enum: ['Normal', 'Smart', 'Pre-filled'],
    },
    source: {
      description:
        '| Rule | Value |\n|---|---|\n| Enum Values | collections, dashboard, navigation |',
      enum: ['collections', 'dashboard', 'navigation'],
    },
    name: { description: 'Name of the collection', type: 'string' },
  },
  additionalProperties: !1,
  required: ['type', 'source', 'name'],
}),
  (validate$G.errors = null);

// @ts-ignore (./ViewedRankedHashtags)
var validate$H = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  for (var key0 in data)
    return (
      (validate.errors = [
        {
          keyword: 'additionalProperties',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/additionalProperties',
          params: { additionalProperty: '' + key0 },
          message: 'should NOT have additional properties',
        },
      ]),
      !1
    );
  return (validate.errors = null), !0;
};
(validate$H.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Viewed%20Ranked%20Hashtags/version/1.0.0',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Viewed Ranked Hashtags',
  description:
    'When a user opens the the "view ranked hashtags" dropdown in the content page \n',
  type: 'object',
  properties: {},
  additionalProperties: !1,
  required: [],
}),
  (validate$H.errors = null);

// @ts-ignore (./VisitedApp)
var validate$I = function validate(
  data,
  dataPath,
  parentData,
  parentDataProperty,
  rootData,
) {
  if (!data || 'object' != typeof data || Array.isArray(data))
    return (
      (validate.errors = [
        {
          keyword: 'type',
          dataPath: (dataPath || '') + '',
          schemaPath: '#/type',
          params: { type: 'object' },
          message: 'should be object',
        },
      ]),
      !1
    );
  var valid1 = !0;
  for (var key0 in data)
    if (!validate.schema.properties.hasOwnProperty(key0))
      return (
        (valid1 = !1),
        (validate.errors = [
          {
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + '',
            schemaPath: '#/additionalProperties',
            params: { additionalProperty: '' + key0 },
            message: 'should NOT have additional properties',
          },
        ]),
        !1
      );
  if (valid1) {
    if (void 0 === data.device) valid1 = !0;
    else {
      var errs_1 = 0;
      if ('string' != typeof data.device)
        return (
          (validate.errors = [
            {
              keyword: 'type',
              dataPath: (dataPath || '') + '.device',
              schemaPath: '#/properties/device/type',
              params: { type: 'string' },
              message: 'should be string',
            },
          ]),
          !1
        );
      valid1 = 0 === errs_1;
    }
    if (valid1) {
      if (void 0 === data.referrer) valid1 = !0;
      else {
        if (((errs_1 = 0), 'string' != typeof data.referrer))
          return (
            (validate.errors = [
              {
                keyword: 'type',
                dataPath: (dataPath || '') + '.referrer',
                schemaPath: '#/properties/referrer/type',
                params: { type: 'string' },
                message: 'should be string',
              },
            ]),
            !1
          );
        valid1 = 0 === errs_1;
      }
      if (valid1) {
        if (void 0 === data.name)
          return (
            (valid1 = !1),
            (validate.errors = [
              {
                keyword: 'required',
                dataPath: (dataPath || '') + '',
                schemaPath: '#/required',
                params: { missingProperty: 'name' },
                message: "should have required property 'name'",
              },
            ]),
            !1
          );
        errs_1 = 0;
        var schema1 = validate.schema.properties.name.enum;
        valid1 = !1;
        for (var i1 = 0; i1 < schema1.length; i1++)
          if (equal(data.name, schema1[i1])) {
            valid1 = !0;
            break;
          }
        if (!valid1)
          return (
            (validate.errors = [
              {
                keyword: 'enum',
                dataPath: (dataPath || '') + '.name',
                schemaPath: '#/properties/name/enum',
                params: { allowedValues: schema1 },
                message: 'should be equal to one of the allowed values',
              },
            ]),
            !1
          );
        if ((valid1 = 0 === errs_1)) {
          if (void 0 === data.utmCampaign) valid1 = !0;
          else {
            if (((errs_1 = 0), 'string' != typeof data.utmCampaign))
              return (
                (validate.errors = [
                  {
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.utmCampaign',
                    schemaPath: '#/properties/utmCampaign/type',
                    params: { type: 'string' },
                    message: 'should be string',
                  },
                ]),
                !1
              );
            valid1 = 0 === errs_1;
          }
          if (valid1) {
            if (void 0 === data.utmMedium) valid1 = !0;
            else {
              if (((errs_1 = 0), 'string' != typeof data.utmMedium))
                return (
                  (validate.errors = [
                    {
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.utmMedium',
                      schemaPath: '#/properties/utmMedium/type',
                      params: { type: 'string' },
                      message: 'should be string',
                    },
                  ]),
                  !1
                );
              valid1 = 0 === errs_1;
            }
            if (valid1) {
              if (void 0 === data.referrerUrl) valid1 = !0;
              else {
                if (((errs_1 = 0), 'string' != typeof data.referrerUrl))
                  return (
                    (validate.errors = [
                      {
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.referrerUrl',
                        schemaPath: '#/properties/referrerUrl/type',
                        params: { type: 'string' },
                        message: 'should be string',
                      },
                    ]),
                    !1
                  );
                valid1 = 0 === errs_1;
              }
              if (valid1) {
                if (void 0 === data.utmSource) valid1 = !0;
                else {
                  if (((errs_1 = 0), 'string' != typeof data.utmSource))
                    return (
                      (validate.errors = [
                        {
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.utmSource',
                          schemaPath: '#/properties/utmSource/type',
                          params: { type: 'string' },
                          message: 'should be string',
                        },
                      ]),
                      !1
                    );
                  valid1 = 0 === errs_1;
                }
                if (valid1) {
                  if (void 0 === data.utmTerm) valid1 = !0;
                  else {
                    if (((errs_1 = 0), 'string' != typeof data.utmTerm))
                      return (
                        (validate.errors = [
                          {
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.utmTerm',
                            schemaPath: '#/properties/utmTerm/type',
                            params: { type: 'string' },
                            message: 'should be string',
                          },
                        ]),
                        !1
                      );
                    valid1 = 0 === errs_1;
                  }
                  if (valid1)
                    if (void 0 === data.utmContent) valid1 = !0;
                    else {
                      if (((errs_1 = 0), 'string' != typeof data.utmContent))
                        return (
                          (validate.errors = [
                            {
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.utmContent',
                              schemaPath: '#/properties/utmContent/type',
                              params: { type: 'string' },
                              message: 'should be string',
                            },
                          ]),
                          !1
                        );
                      valid1 = 0 === errs_1;
                    }
                }
              }
            }
          }
        }
      }
    }
  }
  return (validate.errors = null), !0;
};
(validate$I.schema = {
  $id: 'https://iterative.ly/company/07cc6232-5d61-463b-aae8-2546ec033204/event/Visited%20App/version/4.0.1',
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Visited App',
  description: "When a user visits Flick's webapp",
  type: 'object',
  properties: {
    device: { description: '', type: 'string' },
    referrer: { description: '', type: 'string' },
    name: {
      description:
        'The name of the page the user is visiting\n\n| Rule | Value |\n|---|---|\n| Enum Values | Analytics Audience, Analytics Content, Analytics Hashtags, Analytics Overview, Analytics Reports, Analytics Stories, Collections, Explore, Hashtag Insights, Home, Login, No Card Register, Privacy Policy, Profile, Referrals, Register, Resource, Resources, Viral Posts, Schedule |',
      enum: [
        'Analytics Audience',
        'Analytics Content',
        'Analytics Hashtags',
        'Analytics Overview',
        'Analytics Reports',
        'Analytics Stories',
        'Collections',
        'Explore',
        'Hashtag Insights',
        'Home',
        'Login',
        'No Card Register',
        'Privacy Policy',
        'Profile',
        'Referrals',
        'Register',
        'Resource',
        'Resources',
        'Viral Posts',
        'Schedule',
      ],
    },
    utmCampaign: { description: '', type: 'string' },
    utmMedium: { description: '', type: 'string' },
    referrerUrl: { description: '', type: 'string' },
    utmSource: { description: '', type: 'string' },
    utmTerm: { description: '', type: 'string' },
    utmContent: { description: '', type: 'string' },
  },
  additionalProperties: !1,
  required: ['name'],
}),
  (validate$I.errors = null);

export {
  validate$2 as AddedCollectionToFavouritesValidator,
  validate$3 as AddedHashtagsToCollectionValidator,
  validate$4 as AddedSuggestedHashtagsValidator,
  validate$5 as AdvSearchedValidator,
  validate$6 as AnsweredOnBoardingQuestionsValidator,
  validate$7 as AppliedFiltersValidator,
  validate$8 as AuditedHashtagsValidator,
  validate$9 as ClearedSelectionValidator,
  validate$a as ClickedNotificationValidator,
  validate$b as ClickedOnboardingChecklistItemValidator,
  validate as ContextValidator,
  validate$c as CreatedCollectionValidator,
  validate$d as CreatedFlickAccountValidator,
  validate$e as CreatedSavedFilterValidator,
  validate$f as DeletedSavedFilterValidator,
  validate$g as DismissedSuggestedHashtagValidator,
  validate$h as ExpandedHashtagValidator,
  validate$i as ExpandedMetricValidator,
  validate$j as ExportedHashtagsValidator,
  validate$1 as IdentifyValidator,
  validate$k as LinkedIGAccountErrorValidator,
  validate$l as LinkedIGAccountsValidator,
  validate$m as OpenedSuggestedHashtagsValidator,
  validate$n as PreviewHashtagValidator,
  validate$o as RedirectedFromReportsValidator,
  validate$p as RemovedCollectionFromFavouritesValidator,
  validate$q as SearchedHashtagValidator,
  validate$r as SelectedHashtagsValidator,
  validate$s as SelectedNumAccountsValidator,
  validate$t as SelectedOnboardingAimsValidator,
  validate$u as SelectedPlanValidator,
  validate$v as SelectedTimePeriodValidator,
  validate$w as SelectedUseCaseValidator,
  validate$x as ToggledAnalyticsContentTypeValidator,
  validate$y as ToggledPositionGroupValidator,
  validate$z as ToggledSelectedHashtagsValidator,
  validate$A as ToggledSidebarValidator,
  validate$B as ToggledSuggestFiltersValidator,
  validate$C as ToggledWeeklyReportsValidator,
  validate$D as UnlinkedIGAccountValidator,
  validate$E as UpdatedSavedFilterValidator,
  validate$F as UsedShortcutValidator,
  validate$G as ViewedCollectionValidator,
  validate$H as ViewedRankedHashtagsValidator,
  validate$I as VisitedAppValidator,
};
