import { useEffect } from 'react';

import { useMyActiveSocialGroup } from './useMyActiveSocialGroup';
import { useSetActiveSocialGroup } from './useSetActiveSocialGroup';
import { useSocialGroups } from './useSocialGroups';

export function useInitializeSocialGroupsOnAppLoad() {
  const { isLoading, isFetching, linkedIGAccounts } = useSocialGroups();
  const myActiveSocialGroup = useMyActiveSocialGroup();
  const { setActiveSocialGroup, isLoading: isLoadingSetActiveSocialGroup } =
    useSetActiveSocialGroup();

  const hasActiveSocialGroup = Boolean(myActiveSocialGroup.activeSocialGroup);

  const isLoadingViaFirstFetch =
    (isLoading && !isFetching) ||
    (myActiveSocialGroup.isLoading && !myActiveSocialGroup.isFetched) ||
    isLoadingSetActiveSocialGroup;

  const firstLinkedSocialGroupId = linkedIGAccounts?.[0]?.socialGroupId;

  useEffect(() => {
    if (
      isLoadingViaFirstFetch ||
      hasActiveSocialGroup ||
      !firstLinkedSocialGroupId
    ) {
      return;
    }

    setActiveSocialGroup({ socialGroupId: firstLinkedSocialGroupId });
  }, [
    isLoadingViaFirstFetch,
    hasActiveSocialGroup,
    firstLinkedSocialGroupId,
    setActiveSocialGroup,
  ]);

  return {
    isLoading: isLoadingViaFirstFetch,
  };
}
