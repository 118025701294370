import { isArray, isObject } from './assertion';
import { objectKeys } from './object';
import { Dict } from './types';

export function mapResponsive<T>(
  prop: T | T[] | Dict<T>,
  mapper: (val: T) => any,
) {
  if (isArray(prop)) {
    return prop.map(mapper);
  }

  if (isObject(prop)) {
    return objectKeys(prop).reduce((result: Dict, key) => {
      result[key] = mapper(prop[key]);
      return result;
    }, {});
  }

  if (prop != null) {
    return mapper(prop);
  }

  return null;
}
