import { EventHint } from '@sentry/types';

import { Constants } from '@flick-tech/shared-common';

/**
 * Split the URL into different parts
 * taken from https://stackoverflow.com/questions/736513/how-do-i-parse-a-url-into-hostname-and-path-in-javascript
 * @param {string} url
 */
const splitUrlIntoParts = (url: string) => {
  const reURLInformation = new RegExp(
    [
      '^(https?:)//', // protocol
      '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
      '(/{0,1}[^?#]*)', // pathname
      '(\\?[^#]*|)', // search
      '(#.*|)$', // hash
    ].join(''),
  );
  return url.match(reURLInformation);
};

/**
 * Get the project ID from a Sentry DSN
 * @param {string} dsn
 */
const getProjectIdFromSentryDsn = (dsn: string) => {
  const parts = splitUrlIntoParts(dsn);
  if (!parts) {
    throw new Error('Cannot parse DSN');
  }
  return parts[5].replace('/', '');
};

export const getSentryUrlForEventId = (eventId: string) => {
  if (Constants.isCypress || Constants.isTest) {
    return '';
  }

  try {
    const dsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

    if (!dsn) {
      console.error('No DSN');
      return 'Could not retrieve url';
    }

    const projectId = getProjectIdFromSentryDsn(dsn);

    return `https://sentry.io/organizations/flick-tech/issues/?project=${projectId}&query=${eventId}`;
  } catch (err) {
    console.error('Error retrieving project ID from DSN', err);
    //TODO: Could put link to a help here
    return 'Could not retrieve url';
  }
};

const backendSkipMessages = [
  'Your password is incorrect',
  'No account exists',
  'No account with email',
  'run out of hashtag storage',
  'An account already exists under the email',
  'You have not set a password',
  'already exists. Please choose another name',
  'Access denied! You need to be authorized to perform this action!',
  'You do not have access to this resource.',
  'An unknown error occurred',
  'Unknown Facebook API Error',
  'A batch entry id can only contain',
  'User does not have sufficient administrative permission',
  'You need to enter a valid VAT number',
  'Your card number is incorrect.',
  'An unexpected error has occurred. Please retry your request later',
  'network timeout at: ',
  'A batch entry id can only contain alphanumeric characters',
  'Unable to find billing information.',
  'self signed certificate in certificate chain',
  'Looks like this email is already in use, try logging in.',
  'An unexpected error has occurred. Please retry your request later.',
  'network timeout at: https://www.instagram.com/explore',
  '?__a=1 failed, reason: socket hang up',
  'ags: connect EHOSTUNREACH',
  'network timeout at: https://www.instagram.com/accounts/login/',
  'Your card was declined',
  'You have not authorized Flick',
  'Client network socket disconnected before secure TLS connection was established',
  'GET /accounts/login/ ',
];

const sentrySkipMessages = [
  'No account exists with this',
  ' extensions, locations, message, path',
  'Your password is incorrect',
  'Hashtag must contain no spaces',
  'Please search another hashtag or adjust your filters',
  'No results',
  'Failed to fetch',
  'Origin',

  'please widen your search criteria or search another hashtag',
  'Please reload your Instagram accounts',
  'Cannot query field',
  'Loading chunk',
  'ResizeObserver loop limit exceeded',
  "Identifier 'token' has already been declared",

  "duplicate variable: 'token'",
  "'token' has already been declared",
  ...backendSkipMessages,
];

export const shouldSkipErrorInSentry = (error: Error | SkipSentryError) => {
  if (!error || (error as SkipSentryError).shouldSkipSentry) {
    return true;
  }

  for (const message of sentrySkipMessages) {
    if (error?.message?.toLowerCase()?.includes(message?.toLowerCase())) {
      return true;
    }
  }

  return false;
};

export class SkipSentryError extends Error {
  shouldSkipSentry = true;

  constructor(message: string) {
    super(message);
  }
}

const isError = (exception: string | Error): exception is Error => {
  return (exception as Error).message !== undefined;
};

/**
 * Get the message and name properties from the original exception
 * @param {EventHint} hint
 */
export const getOriginalExceptionProperties = (hint?: EventHint) => {
  if (hint && hint.originalException && isError(hint.originalException)) {
    const originalException = hint.originalException;
    const { name, message } = originalException;
    return { name, message };
  }

  return {};
};
