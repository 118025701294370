import { differenceInDays, isToday } from 'date-fns';
import { useCallback, useState } from 'react';
import DayPicker, { DateUtils, DayPickerProps } from 'react-day-picker';
import { RangeModifier } from 'react-day-picker/types';

import { Button } from '../button';
import { Box, Flex } from '../layout';
import { useMedia } from '../media-query';
import { FlickPopover } from '../popover';

import { DateRangePickerStylesContainer } from './DateRangePicker.styles';

interface DatePickerRange {
  from?: Date;
  to?: Date;
}

interface DateRangePickerProps {
  initialValues?: RangeModifier;
  onSubmit: (range: RangeModifier) => void;
  onCancel?: () => void;
  pastDatesOnly?: boolean;
  numberOfMonths?: number;
  submitText?: string;
  fromDay?: Date;
}

const getInitialState = () => ({
  from: undefined,
  to: undefined,
});

export function getDisabledDates(
  pastDatesOnly: boolean,
  fromDay: Date | undefined,
) {
  const disabledDates: Pick<
    DayPickerProps,
    'toMonth' | 'fromMonth' | 'disabledDays'
  > = {};

  if (pastDatesOnly) {
    const now = new Date();
    disabledDates.toMonth = now;
    disabledDates.disabledDays = {
      after: now,
    };
  }

  if (fromDay) {
    disabledDates.fromMonth = fromDay;
    disabledDates.disabledDays = {
      ...disabledDates.disabledDays,
      before: fromDay,
    };
  }

  return disabledDates;
}

export function DateRangePicker({
  initialValues,
  onSubmit,
  onCancel,
  pastDatesOnly = true,
  numberOfMonths = 2,
  submitText = 'Apply Filter',
  fromDay,
}: DateRangePickerProps) {
  const [selectedRange, setSelectedRange] = useState<DatePickerRange>(
    initialValues ?? getInitialState(),
  );

  const { from, to } = selectedRange;

  if (!useMedia({ min: 'lg' })) {
    numberOfMonths = 1;
  }

  const handleDayClick = useCallback(
    (day) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const range = DateUtils.addDayToRange(
        day,
        selectedRange as RangeModifier,
      );
      setSelectedRange(range);
    },
    [selectedRange],
  );

  const handleResetClick = useCallback(() => {
    setSelectedRange(getInitialState());
  }, []);

  const modifiers = { start: from, end: to };

  const isValid = Boolean(from && to);

  const selectedRangeLength = differenceInDays(to, from);
  const selectedRangeIsTooShort =
    selectedRangeLength < 1 || (selectedRangeLength === 1 && isToday(to));

  const disabledDates = getDisabledDates(pastDatesOnly, fromDay);

  return (
    <DateRangePickerStylesContainer selectedRangeLength={selectedRangeLength}>
      <Flex direction="column">
        <DayPicker
          month={from}
          numberOfMonths={numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={handleDayClick}
          {...disabledDates}
        />
        <Flex mt="auto" justify="space-between" align="center">
          <div>
            {isValid && (
              <Button colorScheme="white" size="sm" onClick={handleResetClick}>
                Reset
              </Button>
            )}
          </div>
          <Box>
            {onCancel && (
              <Button colorScheme="white" size="sm" onClick={onCancel} mr={2}>
                Cancel
              </Button>
            )}
            <FlickPopover
              position="bottom"
              content="Date range must include at least two days excluding today."
              enabled={selectedRangeIsTooShort}
            >
              <Button
                isDisabled={!isValid || selectedRangeIsTooShort}
                onClick={() => onSubmit(selectedRange as RangeModifier)}
                filter="drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.06))"
                size="sm"
              >
                {submitText}
              </Button>
            </FlickPopover>
          </Box>
        </Flex>
      </Flex>
    </DateRangePickerStylesContainer>
  );
}
