import { Constants } from './constants';
import { isRunningOnServerSide } from './shared';

const getPathnameWithQueryString = (url_: string) => {
  let url = url_.replace(window.location.origin, '');

  if (!url.startsWith(Constants.basePath)) {
    url = `${Constants.basePath}${url}`;
  }

  return url;
};

const routingState = (url: string) => {
  return {
    // if __N is falsy, Next will skip the state, effectively disabling back button
    __N: true,
    options: { shallow: true },
    url: getPathnameWithQueryString(url),
    as: getPathnameWithQueryString(url),
  };
};

/**
 * Pushes state to browser history in a way compatible with Next's onPopState
 * listener, so the back button behaviour isn't broken.
 *
 * Use this only to navigate inside a page
 * (e.g. changing category in /blog/[category].tsx).
 *
 * @see https://nextjs.org/docs/api-reference/next/router#routerpush
 */
export function pushState(url: string): void {
  window.history.pushState(routingState(url), '', url);
}

export function replaceState(url: string): void {
  window.history.replaceState(routingState(url), '', url);
}

const getSearch = () => {
  if (isRunningOnServerSide()) {
    return '';
  }

  return window.location.search;
}

export const changeRouteKeepParams = (
  newPath: string,
  search?:string,
): string => {
  const params = new URLSearchParams(search ?? getSearch());
  const paramsStr = params.toString() !== '' ? `?${params.toString()}` : '';
  return `${newPath}${paramsStr}`;
};
