const emojisByCategory: { [category: string]: string[] } = {
  'face-smiling': [
    'grinning',
    'grin',
    'joy',
    'rolling_on_the_floor_laughing',
    'smiley',
    'smile',
    'sweat_smile',
    'laughing',
    'wink',
    'blush',
    'slightly_smiling_face',
    'upside_down_face',
    'wink',
    'blush',
    'innocent',
  ],
  'face-tongue': [
    'yum',
    'stuck_out_tongue',
    'stuck_out_tongue_winking_eye',
    'zany_face',
    'stuck_out_tongue_closed_eyes',
    'money_mouth_face',
  ],
  'face-hat': ['face_with_cowboy_hat'],
  'face-glasses': ['sunglasses', 'nerd_face', 'face_with_monocle'],
  'face-costume': [
    'hankey',
    'clown_face',
    'japanese_ogre',
    'japanese_goblin',
    'ghost',
    'alien',
    'space_invader',
    'robot_face',
  ],
  'person-fantasy': [
    'angel',
    'santa',
    'mrs_claus',
    'supervillain',
    'supervillain_man',
    'supervillain_woman',
    'mage',
    'female_mage',
    'male_mage',
    'fairy',
    'female_fairy',
    'male_fairy',
    'vampire',
    'female_vampire',
    'male_vampire',
    'merperson',
    'mermaid',
    'merman',
    'elf',
    'female_elf',
    'male_elf',
    'genie',
    'female_genie',
    'male_genie',
    'zombie',
    'female_zombie',
    'male_zombie',
  ],
  'animal-marine': [
    'whale',
    'whale2',
    'dolphin',
    'fish',
    'tropical_fish',
    'blowfish',
    'shark',
    'octopus',
    'shell',
  ],
  'animal-bug': [
    'snail',
    'butterfly',
    'bug',
    'ant',
    'bee',
    'beetle',
    'cricket',
    'spider',
    'spider_web',
    'scorpion',
  ],
  'food-fruit': [
    'grapes',
    'melon',
    'watermelon',
    'tangerine',
    'lemon',
    'banana',
    'pineapple',
    'apple',
    'green_apple',
    'pear',
    'peach',
    'cherries',
    'strawberry',
    'kiwifruit',
    'tomato',
    'coconut',
  ],
  'food-sweet': [
    'icecream',
    'shaved_ice',
    'ice_cream',
    'doughnut',
    'cookie',
    'birthday',
    'cake',
    'pie',
    'chocolate_bar',
    'candy',
    'lollipop',
    'custard',
    'honey_pot',
  ],
  'transport-air': [
    'airplane',
    'small_airplane',
    'airplane_departure',
    'airplane_arriving',
    'seat',
    'helicopter',
    'suspension_railway',
    'mountain_cableway',
    'aerial_tramway',
    'satellite',
    'rocket',
    'flying_saucer',
  ],
  'sky&weather': [
    'new_moon',
    'waxing_crescent_moon',
    'first_quarter_moon',
    'moon',
    'full_moon',
    'waning_gibbous_moon',
    'last_quarter_moon',
    'waning_crescent_moon',
    'crescent_moon',
    'new_moon_with_face',
    'first_quarter_moon_with_face',
    'last_quarter_moon_with_face',
    'thermometer',
    'sunny',
    'full_moon_with_face',
    'sun_with_face',
    'star',
    'star2',
    'stars',
    'cloud',
    'partly_sunny',
    'thunder_cloud_and_rain',
    'mostly_sunny',
    'barely_sunny',
    'partly_sunny_rain',
    'rain_cloud',
    'snow_cloud',
    'lightning',
    'tornado',
    'fog',
    'wind_blowing_face',
    'cyclone',
    'rainbow',
    'closed_umbrella',
    'umbrella',
    'umbrella_with_rain_drops',
    'umbrella_on_ground',
    'zap',
    'snowflake',
    'snowman',
    'snowman_without_snow',
    'comet',
    'fire',
    'droplet',
    'ocean',
  ],
  game: [
    'dart',
    'golf',
    'ice_skate',
    'fishing_pole_and_fish',
    'running_shirt_with_sash',
    'ski',
    'sled',
    'curling_stone',
    'video_game',
    'joystick',
    'game_die',
    'spades',
    'hearts',
    'diamonds',
    'clubs',
    'black_joker',
    'mahjong',
    'flower_playing_cards',
  ],
  'arts&crafts': ['performing_arts', 'frame_with_picture', 'art'],
  'light&video': [
    'movie_camera',
    'film_frames',
    'film_projector',
    'clapper',
    'tv',
    'camera',
    'camera_with_flash',
    'video_camera',
    'vhs',
    'mag',
    'mag_right',
    'microscope',
    'telescope',
    'satellite_antenna',
    'candle',
    'bulb',
    'flashlight',
    'izakaya_lantern',
  ],
  science: ['alembic'],
  event: [
    'jack_o_lantern',
    'christmas_tree',
    'fireworks',
    'sparkler',
    'sparkles',
    'balloon',
    'tada',
    'confetti_ball',
    'tanabata_tree',
    'bamboo',
    'dolls',
    'flags',
    'wind_chime',
    'rice_scene',
    'ribbon',
    'gift',
    'reminder_ribbon',
    'admission_tickets',
    'ticket',
  ],
};

const allEmojis = Object.values(emojisByCategory).reduce(
  (acc, currentEmojis) => {
    acc.push(...currentEmojis);
    return acc;
  },
  [],
);

export function getRandomEmoji(): string {
  const randomIndex = Math.floor(Math.random() * allEmojis.length);
  return allEmojis[randomIndex];
}
