import { useHasAccessToTeamsFeatureFlag } from '@flick-tech/core-hooks';
import { IWorkspaceRole } from '@flick-tech/shared-types';

interface Props {
  numberOfWorkspaces: number | undefined;
  myRole: IWorkspaceRole | undefined;
}

export function useShouldShowWorkspaceSwitcher({
  numberOfWorkspaces,
  myRole,
}: Props) {
  const hasAccessToTeams = useHasAccessToTeamsFeatureFlag();

  if (!hasAccessToTeams) {
    return false;
  }

  if (numberOfWorkspaces === 1) {
    return myRole !== 'Owner';
  }

  return true;
}
