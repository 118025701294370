import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import { Logger, Sentry } from '@flick-tech/logger';
import {
  Constants,
  extractQueryParameters,
  isMobile,
  isRunningOnServerSide,
  PersistentStore,
  StoreKey,
  stripEmpties,
} from '@flick-tech/shared-common';

import { Amplitude } from './amplitude';
import { FBPixel, useInitializeFBPixelTracking } from './fb-pixel';
import { Intercom } from './intercom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  IdentifyProperties,
  initItlyWeb,
  ItlyWeb,
  VisitedAppProperties,
} from './itly-web';

declare global {
  interface Window {
    gtag?: any;
    Cypress?: any;
  }
}

export const getFirstPromoterId = (): string | undefined => {
  if (isRunningOnServerSide()) {
    return undefined;
  }
};

interface SetUserPropertiesParams
  extends IdentifyProperties,
    Record<string, any> {
  skipIntercom?: boolean;
  profilePicture?: string;
}

export class Tracking {
  private static gtagCompleteRegistration() {
    if (window.Cypress) return;

    try {
      window.gtag('event', 'conversion', {
        send_to: 'AW-706676293/YLyZCOeO-7MBEMWM_NAC',
      });
    } catch (error) {
      Logger.error(error);
    }
  }

  static trackEvent(name: string, params: any = {}) {
    if (window.Cypress) return;

    const payload = stripEmpties({
      ...params,
      device: isMobile() ? 'mobile' : 'desktop',
      sourceUrl: window.location.href,
      sourcePathname: window.location.pathname,
      desktopHashtagsView: PersistentStore.get(
        StoreKey.DESKTOP_HASHTAGS_VIEW,
        'groups',
      ),
    });

    Logger.info(`TRACKING: ${name} ${JSON.stringify(payload, null, 2)}`);

    try {
      Amplitude.trackEvent(name, payload);
    } catch (error) {
      Logger.error(error);
    }

    try {
      FBPixel.trackCustom(name.replace(/-/g, ' '), payload);
    } catch (error) {
      Logger.error(error);
    }

    if (Constants.isWeb || Constants.isSchedule) {
      datadogRum.addAction(name, payload);
    }
  }

  static setUserProperties({
    skipIntercom,
    ...params
  }: SetUserPropertiesParams) {
    if (window.Cypress) return;

    const payload = stripEmpties(params);

    try {
      if (payload.email && payload.id) {
        Sentry.setUser({
          id: payload.id,
          email: payload.email,
        });
      }
    } catch (error) {
      Logger.error(error);
    }

    try {
      Amplitude.setUserProperties(payload);
    } catch (error) {
      Logger.error(error);
    }

    if (!skipIntercom) {
      try {
        Intercom.setUserProperties(payload);
      } catch (error) {
        Logger.error(error);
      }
    }
  }

  static trackUserRegistered() {
    if (window.Cypress) return;

    const name = 'Complete Registration';

    try {
      Amplitude.trackEvent(name);
    } catch (error) {
      Logger.error(error);
    }

    try {
      Amplitude.setUserPropertiesOnce({
        trialStartDate: new Date().toISOString(),
      });
    } catch (error) {
      Logger.info(error);
    }

    try {
      Tracking.gtagCompleteRegistration();
    } catch (error) {
      Logger.error(error);
    }

    try {
      Intercom.trackEvent(name);
    } catch (error) {
      Logger.error(error);
    }

    try {
      FBPixel.track('CompleteRegistration');
    } catch (error) {
      Logger.error(error);
    }
  }

  static trackPageView(pathnameWithBasePath: string, search: string) {
    const pathnameWithoutBasePath = pathnameWithBasePath?.replace(
      Constants.basePath,
      '',
    );

    const queryParams = extractQueryParameters(search);

    type Params = {
      referrer: string;
      utmSource: string;
      utmMedium: string;
      utmCampaign: string;
      utmTerm: string;
      utmContent: string;
      device?: string;
      referrerUrl?: string;
    };

    const params: Params = stripEmpties({
      referrer: queryParams.ref || queryParams.referrer || queryParams.referer,
      utmSource: queryParams.utm_source,
      utmMedium: queryParams.utm_medium,
      utmCampaign: queryParams.utm_campaign,
      utmTerm: queryParams.utm_term,
      utmContent: queryParams.utm_content,
    });

    let name: VisitedAppProperties['name'];

    if (pathnameWithoutBasePath === '/home') {
      name = 'Home';
      Tracking.setUserPropertiesFromPageView(queryParams);
      Intercom.trackEvent('Visited Home');
    } else if (pathnameWithoutBasePath === '/explore') {
      name = 'Explore';
      Tracking.setUserPropertiesFromPageView(queryParams);
    } else if (pathnameWithoutBasePath.includes('/register')) {
      name = 'Register';
      Tracking.setUserPropertiesFromPageView(queryParams);
    } else if (pathnameWithoutBasePath === '/login') {
      name = 'Login';
      Tracking.setUserPropertiesFromPageView(queryParams);
    } else if (pathnameWithoutBasePath === '/viral-posts') {
      name = 'Viral Posts';
    } else if (pathnameWithoutBasePath === '/privacy-policy') {
      name = 'Privacy Policy';
    } else if (pathnameWithoutBasePath === '/referrals') {
      name = 'Referrals';
    } else if (pathnameWithoutBasePath.includes('/profile')) {
      name = 'Profile';
    } else if (pathnameWithoutBasePath === '/resources') {
      name = 'Resources';
    } else if (pathnameWithoutBasePath.includes('/resource/')) {
      name = 'Resource';
    } else if (pathnameWithoutBasePath.includes('/hashtag-collections')) {
      name = 'Collections';
    } else if (pathnameWithoutBasePath === '/signup') {
      name = 'No Card Register';
    } else if (pathnameWithoutBasePath.includes('/analytics')) {
      if (pathnameWithoutBasePath.includes('content')) {
        name = 'Analytics Content';
      } else if (pathnameWithoutBasePath.includes('reports')) {
        name = 'Analytics Reports';
      } else if (pathnameWithoutBasePath.includes('hashtags')) {
        name = 'Analytics Hashtags';
      } else if (pathnameWithoutBasePath.includes('audience')) {
        name = 'Analytics Audience';
      } else if (pathnameWithoutBasePath.includes('overview')) {
        name = 'Analytics Overview';
      }
    } else if (pathnameWithBasePath.includes('feed-preview')) {
      name = 'Feed Preview';
    } else if (pathnameWithBasePath.includes('schedule')) {
      name = 'Schedule';
    }

    const mobile = isMobile();

    const payload = {
      name: name ?? pathnameWithoutBasePath,
      ...stripEmpties(params),
    };

    if (document.referrer) payload.referrerUrl = document.referrer;

    payload.device = mobile ? 'mobile' : 'desktop';

    if (name === 'Explore') {
      if (payload.device === 'mobile') {
        Intercom.trackEvent('Mobile Hashtags Page');
      } else {
        Intercom.trackEvent('Desktop Hashtags Page');
      }
    }

    // We don't want to track users in some places (e.g. Intercom) as we'd end up paying a lot for the tracking services for non active users.
    // E.g. we get A LOT of traffic on the first stage of the register flow, but not many convert to paying customers. To mitigate this, we only track them once they're in app
    const isOnAuthPath =
      name && ['Register', 'Login', 'Academy'].includes(name);

    if (name && !isOnAuthPath) {
      Intercom.trackEvent(`Visited ${name}`);
    }

    if (!isOnAuthPath && name) {
      try {
        ItlyWeb.trackVisitedApp({
          ...payload,
          name,
        });
      } catch (error) {
        Logger.error(error);
      }
    } else {
      Tracking.trackEvent('Visited App', payload);
    }
  }

  static setUserPropertiesFromPageView(queryParams: any) {
    const userProperties = stripEmpties({
      UtmSource: queryParams.utm_source,
      UtmMedium: queryParams.utm_medium,
      UtmCampaign: queryParams.utm_campaign,
      UtmTerm: queryParams.utm_term,
      UtmContent: queryParams.utm_content,
    });

    try {
      this.setUserProperties({ ...userProperties, skipIntercom: true });
    } catch (error) {
      Logger.error(error);
    }

    try {
      Amplitude.identify(
        stripEmpties({
          UtmSources: queryParams.utm_source,
          UtmMediums: queryParams.utm_medium,
          UtmCampaigns: queryParams.utm_campaign,
          UtmTerms: queryParams.utm_term,
          UtmContents: queryParams.utm_content,
        }),
      );
    } catch (error) {
      Logger.error(error);
    }
  }
}

export function useInitializeTracking() {
  useEffect(() => {
    Intercom.init();
    initItlyWeb();
  }, []);

  useInitializeFBPixelTracking();
}
