import { CSSObject } from '@emotion/react';
import { uniqueId } from 'lodash';
import { ReactNode, useMemo } from 'react';
import * as React from 'react';
import {
  Area,
  AreaProps,
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  ResponsiveContainerProps,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';

import { formatNumber } from '@flick-tech/shared-formatters';
import { useConst } from '@flick-tech/shared-hooks';
import type { StringKey } from '@flick-tech/shared-types';
import { primaryShades } from '@flick-tech/theme-new';

import {
  AreaFillGradients,
  computeAreaFillGradients,
  FillGradientConfig,
} from './components/AreaFillGradients';
import { makeBarShape } from './components/BarShape';
import { DateTick } from './components/DateTick';
import {
  DescriptionListLegend,
  DescriptionListLegendProps,
} from './components/DescriptionListLegend';
import { DotWhenAlone } from './components/DotWhenAlone';
import {
  EmptyStateWrapper,
  EmptyStateWrapperProps,
} from './components/EmptyStateWrapper';
import { activeDotProps } from './activeDotProps';
import { aggregateLongDataPeriods } from './aggregateLongDataPeriods';
import { maxBarSize } from './const';
import { rechartsTypes } from './types';
import { useChart, UseChartParams } from './useChart';

/**
 * Recharts parses children, so we can't wrap them in a component.
 */
export function renderFlickXAxis(props: XAxisProps) {
  return <XAxis tick={DateTick} height={30} minTickGap={0} {...props} />;
}

/**
 * Recharts parses children, so we can't wrap them in a component.
 */
export function renderFlickYAxis(props: YAxisProps = {}) {
  return (
    <YAxis tickFormatter={formatValueTick} allowDecimals={false} {...props} />
  );
}

export const legendHeight = 58;

export type ChartType = 'line' | 'bar';

export interface FlickCommonChartProps<T extends object>
  extends Omit<UseChartParams<T>, 'rawData'> {
  height?: number;
  indexBy?: StringKey<T>;
  showLegend?: boolean;
}

export interface FlickChartProps<T extends object>
  extends FlickCommonChartProps<T>,
    Pick<FlickBaseChartProps, 'yAxis' | 'legendProps' | 'wrapperProps'> {
  type?: ChartType;
  aggregateData?: boolean | ((a: T, b: T) => T);
}
export function FlickChart<T extends object>({
  keys,
  colors,
  data,
  type = 'bar',
  aggregateData = true,
  ...rest
}: FlickChartProps<T>) {
  const uuid = useConst(() => uniqueId());

  const aggregatedData = useMemo(() => {
    const firstDatum = data.find((x) => !!x);

    if (aggregateData && firstDatum && 'timestamp' in firstDatum) {
      return aggregateLongDataPeriods<T & { timestamp?: Date }>(
        data,
        (datum) => datum && datum.timestamp && new Date(datum.timestamp),
        typeof aggregateData === 'function'
          ? aggregateData
          : makeSumOnAllKeys<T>(keys),
      );
    }

    return data;
    // We're most often passing these deps once, but inline.
    // They're config, not state.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!colors) {
    colors = primaryShades.slice(-keys.length);
  }

  let gradients: FillGradientConfig<T>[] = [];

  interface RenderConfig<TProps = {}> {
    Component: React.ComponentType<TProps>;
    dataKey: StringKey<T>;
    fill: string;
    stroke?: string;
    mainChartProps?: Partial<TProps>;
  }

  let renderConfig: RenderConfig[];
  let chartOverrides: rechartsTypes.ComposedChartProps;

  if (type === 'line') {
    chartOverrides = {
      barGap: 0,
      data,
    };

    gradients = computeAreaFillGradients({
      data: aggregatedData,
      keys,
      uuid,
      colors,
    });

    renderConfig = keys.map((key) => {
      const gradient = gradients.find((x) => x.key === key);

      const mainChartProps: Partial<AreaProps> = {
        dot: <DotWhenAlone fill={gradient!.color} />,
        connectNulls: false,
        activeDot: activeDotProps(gradient!.color),
      };

      return {
        Component: Area,
        dataKey: key,
        fill: `url(#${gradient!.id})`,
        stroke: gradient!.color,
        mainChartProps,
      };
    });
  } else {
    chartOverrides = { barGap: 0 };

    renderConfig = keys.map((key, i) => {
      return {
        Component: Bar,
        dataKey: key,
        fill: colors[i],
        shape: makeBarShape({ key: key as string }),
      };
    });
  }

  return (
    <>
      <AreaFillGradients gradients={gradients} />
      <FlickBaseChart
        {...rest}
        rawData={data}
        data={aggregatedData}
        chartProps={chartOverrides}
        colors={colors}
        keys={keys as string[]}
      >
        {renderConfig.map(({ Component, mainChartProps, ...props }) => (
          <Component {...props} {...mainChartProps} key={props.dataKey} />
        ))}
      </FlickBaseChart>
    </>
  );
}

export const styledRechartClassnames: CSSObject = {
  '.recharts-cartesian-axis-line': {
    display: 'none',
  },
  '.recharts-brush': {
    transform: 'translateY(16px)',
  },
  '.recharts-surface': {
    overflow: 'visible',
  },
  '.recharts-cartesian-grid-vertical > .flick-cartesian-grid-line:nth-of-type(2n)':
    {
      display: 'none',
    },
};

type WithTimestamp<T extends object> = T & { timestamp?: Date };

function makeSumOnAllKeys<T extends object>(
  keys: Exclude<keyof T, number | symbol>[],
): (a: T, b: T) => WithTimestamp<T> {
  return function sumOnAllKeys(a, b) {
    if (!a || !b) {
      return a || b;
    }

    const res = { ...b };

    for (const key of keys) {
      // hack: we're not enforcing all y-values to be numbers, but they are in practice.
      res[key] = ((a[key] as any as number) +
        (b[key] as any as number)) as any as T[typeof key];
    }

    return res;
  };
}

const formatValueTick: YAxisProps['tickFormatter'] = (value) =>
  Math.abs(value) >= 10000 ? formatNumber(value) : String(value);

/**
 * @internal
 */
export interface FlickBaseChartProps
  extends FlickCommonChartProps<Record<string, any>>,
    Pick<UseChartParams<Record<string, any>>, 'rawData'> {
  children: ReactNode;
  chartProps?: rechartsTypes.ComposedChartProps;
  containerProps?: Partial<ResponsiveContainerProps>;
  yAxis?: YAxisProps;
  legendProps?: DescriptionListLegendProps;
  wrapperProps?: Partial<EmptyStateWrapperProps>;
}

/**
 * @internal
 */
export function FlickBaseChart(props: FlickBaseChartProps) {
  const {
    height,
    children,
    chartProps = {},
    showLegend = true,
    containerProps,
    legendProps: legendPropsOverride,
    wrapperProps,
  } = props;

  const { parsedData, legendProps, tooltipProps, isDataMissing } =
    useChart(props);

  const dataLength = parsedData.length;
  let xTickFormatter: XAxisProps['tickFormatter'] =
    DateTick.skipTicks(dataLength);

  // TODO: Charts based on this one need rethinking from the ground-up and
  //       a huge refactor. We're reaching critical mass.
  let indexBy = props.indexBy || 'timestamp';
  if (parsedData[0] && indexBy === 'timestamp' && 'dateFrom' in parsedData[0]) {
    indexBy = 'dateFrom';

    // HACK
    if (
      'dateFormat' in parsedData[0] &&
      (parsedData[0] as { dateFormat: Intl.DateTimeFormatOptions }).dateFormat
    ) {
      xTickFormatter = (date: Date | null | undefined) => {
        if (!date) {
          return '';
        }

        return date.toLocaleDateString(
          'en-US',
          (parsedData[0] as { dateFormat: Intl.DateTimeFormatOptions })
            .dateFormat,
        );
      };
    }
  }

  return (
    <EmptyStateWrapper
      active={isDataMissing}
      sx={styledRechartClassnames}
      {...wrapperProps}
    >
      <ResponsiveContainer
        height={showLegend ? height - legendHeight : height}
        {...containerProps}
      >
        <ComposedChart
          {...chartProps}
          maxBarSize={maxBarSize}
          data={parsedData}
          margin={{
            top: 28,
            right: 20,
            left: -20,
            bottom: 0,
          }}
        >
          <CartesianGrid
            stroke="rgba(0, 0, 0, 0.06)"
            strokeDasharray="5"
            className={
              dataLength === 30 ? 'flick-cartesian-grid-line' : undefined
            }
          />
          <Tooltip {...tooltipProps} />
          {children}
          {renderFlickXAxis({
            dataKey: indexBy,
            tickFormatter: xTickFormatter,
          })}
          {renderFlickYAxis(props.yAxis)}
        </ComposedChart>
      </ResponsiveContainer>
      {showLegend && (
        <DescriptionListLegend {...legendProps} {...legendPropsOverride} />
      )}
    </EmptyStateWrapper>
  );
}
