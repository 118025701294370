import { useRouter } from 'next/router';
import { atom, useRecoilState } from 'recoil';

import {
  clearAccessTokenInCookies,
  Constants,
  getAccessTokensFromCookies,
  isRunningOnServerSide,
  ROUTES,
  setAccessTokenInCookies,
} from '@flick-tech/shared-common';

import { setRecoil } from '../recoil';

import { GraphQLError, graphqlFetch } from './graphql-fetch';
import { refreshAccessToken } from './refresh-token';

const getAccessTokenFromPersistentStore = () => {
  if (isRunningOnServerSide() || Constants.isReactNative) {
    return undefined;
  }

  const token = getAccessTokensFromCookies();

  const accessToken = token?.accessToken;

  if (!accessToken || typeof accessToken !== 'string') {
    return undefined;
  }

  return accessToken;
};

const flickAccessTokenState = atom({
  key: 'auth/access-token',
  default: getAccessTokenFromPersistentStore(),
});

export const clearFlickAccessToken = () => {
  setRecoil(flickAccessTokenState, undefined);

  if (!Constants.isReactNative) {
    clearAccessTokenInCookies();
  }
};

export function useFlickAccessToken() {
  const [accessToken, setAccessToken] = useRecoilState(flickAccessTokenState);

  return {
    accessToken: accessToken || getAccessTokenFromPersistentStore(),
    setAccessToken,
  };
}

const hasTriedToRefreshTokenAtom = atom({
  key: 'auth/is-refreshing-token',
  default: false,
});

const useHandleSessionExpired = () => {
  const { setAccessToken } = useFlickAccessToken();
  const router = useRouter();

  const [hasTriedToRefreshToken, setHasTriedToRefresh] = useRecoilState(
    hasTriedToRefreshTokenAtom,
  );

  const expireSession = () => router.push(ROUTES.Logout);

  return async () => {
    // This runs on every token expired graphql call, so we can have many calls in parallel.
    // We want to ensure it only runs once
    if (hasTriedToRefreshToken) {
      return;
    }

    setHasTriedToRefresh(true);

    let success = false;

    try {
      const tokens = await refreshAccessToken();

      setAccessTokenInCookies(tokens);
      setAccessToken(tokens.accessToken);

      success = true;
    } catch (_) {
      await expireSession();
    }

    return success;
  };
};

export function useGraphqlFetch<TData, TVariables>(
  query: string,
): (variables?: TVariables) => Promise<TData> {
  const { accessToken } = useFlickAccessToken();

  const handleSessionExpired = useHandleSessionExpired();

  return async (variables) => {
    const tryFetch = () =>
      graphqlFetch<TData, TVariables>({
        query,
        accessToken,
        variables,
      });

    try {
      return await tryFetch();
    } catch (error) {
      const hasToken = Boolean(accessToken);
      const usedTokenIsInvalid =
        (error as GraphQLError).code === 'UNAUTHENTICATED';
      const shouldResetSession = usedTokenIsInvalid && hasToken;

      if ((error as GraphQLError).code === 'AccessTokenExpired') {
        const didHandleSuccessfully = await handleSessionExpired();

        if (didHandleSuccessfully) {
          return await tryFetch();
        } else {
          throw new Error('Session expired. Please login again.');
        }
      } else if (shouldResetSession) {
        window.location.replace(
          `${Constants.webBase}${ROUTES.Logout}${window.location.search}`,
        );
      }

      throw error;
    }
  };
}
