export function addItem<T>(array: T[], item: T) {
  return [...array, item];
}

export function removeItem<T>(array: T[], item: T) {
  return array.filter((eachItem) => eachItem !== item);
}

/**
 * Get the next index based on the current index and step.
 *
 * @param currentIndex the current index
 * @param length the total length or count of items
 * @param step the number of steps
 * @param loop whether to circle back once `currentIndex` is at the start/end
 */
export function getNextIndex(
  currentIndex: number,
  length: number,
  step = 1,
  loop = true,
) {
  const lastIndex = length - 1;

  if (currentIndex === -1) {
    return step > 0 ? 0 : lastIndex;
  }

  const nextIndex = currentIndex + step;

  if (nextIndex < 0) {
    return loop ? lastIndex : 0;
  }

  if (nextIndex >= length) {
    if (loop) return 0;
    return currentIndex > length ? length : currentIndex;
  }

  return nextIndex;
}

/**
 * Get's the previous index based on the current index.
 * Mostly used for keyboard navigation.
 *
 * @param index - the current index
 * @param count - the length or total count of items in the array
 * @param loop - whether we should circle back to the
 * first/last once `currentIndex` is at the start/end
 */
export function getPrevIndex(currentIndex: number, count: number, loop = true) {
  return getNextIndex(currentIndex, count, -1, loop);
}

/**
 * Converts an array into smaller chunks or groups.
 *
 * @param array the array to chunk into group
 * @param size the length of each chunk
 */
export function chunk<T>(array: T[], size: number): T[][] {
  return array.reduce((rows: T[][], currentValue: T, index: number) => {
    if (index % size === 0) {
      rows.push([currentValue]);
    } else {
      rows[rows.length - 1].push(currentValue);
    }
    return rows;
  }, [] as T[][]);
}

/**
 * Gets the next item based on a search string
 *
 * @param items array of items
 * @param searchString the search string
 * @param itemToString resolves an item to string
 * @param currentItem the current selected item
 */
export function getNextItemFromSearch<T>(
  items: T[],
  searchString: string,
  itemToString: (item: T) => string,
  currentItem: T,
) {
  if (!searchString) return null;

  // If current value doesn't exist, find the item that match the search string
  if (!currentItem) {
    const foundItem = items.find((item) =>
      itemToString(item).toLowerCase().startsWith(searchString.toLowerCase()),
    );
    return foundItem || currentItem;
  }

  // Filter items for ones that match the search string (case insensitive)
  const searchResults = items.filter((item) =>
    itemToString(item).toLowerCase().startsWith(searchString.toLowerCase()),
  );

  // If there's a match, let's get the next item to select
  if (searchResults.length) {
    let nextIndex: number;

    // If the currentItem is in the available items, we move to the next available option
    if (searchResults.includes(currentItem)) {
      const currentIndex = searchResults.indexOf(currentItem);
      nextIndex = currentIndex + 1;
      if (nextIndex === searchResults.length) {
        nextIndex = 0;
      }
      return searchResults[nextIndex];
    } else {
      // Else, we pick the first item in the available items
      nextIndex = items.indexOf(searchResults[0]);
      return items[nextIndex];
    }
  }

  // a decent fallback to the currentItem
  return currentItem;
}

/**
 * Starting from the end of the `xs` array, applies its elements to `access`
 * function and returns the first result that's not weakly equal to null.
 */
export function getLast<T, R>(xs: T[], access: (_: T) => R): R | undefined {
  for (let i = xs.length - 1; i >= 0; --i) {
    const x = xs[i];
    if (x != null) {
      const res = access(x);
      if (res != null) return res;
    }
  }

  return undefined;
}

/**
 * Starting from the beginning of the `xs` array, applies its elements to
 * `access` function and returns first the result that's not weakly equal to
 * null.
 */
// This is interchangable with `xs.map(x => access(x)).filter(Boolean)[0]`.
export function getFirst<T, R>(xs: T[], access: (_: T) => R): R | undefined {
  for (let i = 0; i < xs.length; ++i) {
    const x = xs[i];
    if (x != null) {
      const res = access(x);
      if (res != null) return res;
    }
  }

  return undefined;
}
