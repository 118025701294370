import * as components from './components';
import foundations, { Foundations } from './foundations';
import styles from './styles';

export * from './foundations/breakpoints';
export * from './foundations/colors';
export * from './foundations/theme.constants';

export const theme: Foundations & {
  components: typeof components;
  styles: typeof styles;
} = {
  ...foundations,
  components,
  styles,
};

type _Theme = typeof theme;
export interface Theme extends _Theme {}

type Components = Theme['components'];

declare module '../parser/utils/types' {
  export interface InjectedTheme extends Theme {}
}

export type ThemeProps = {
  [K in keyof Components]: {
    variant?: Exclude<keyof Components[K]['variants'], 'unstyled'>;
  };
};
