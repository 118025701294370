import deepEqual from 'fast-deep-equal';
import { useReducer, useRef } from 'react';
import {
  RecoilValueReadOnly,
  useRecoilCallback,
  useRecoilTransactionObserver_UNSTABLE,
} from 'recoil';

// https://github.com/facebookexperimental/Recoil/issues/522#issuecomment-674567123
export function useMemoizedRecoilValue<T>(
  recoilValue: RecoilValueReadOnly<T>,
): T {
  const [, forceRender] = useReducer((s: number) => s + 1, 0);
  const lastVal = useRef<T>();
  const lastRecoilValue = useRef<RecoilValueReadOnly<T>>();
  const init = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        lastVal.current = snapshot.getLoadable(recoilValue).contents as T;
        lastRecoilValue.current = recoilValue;
      },
    [recoilValue],
  );

  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    const cur = snapshot.getLoadable(recoilValue).contents as T;
    if (
      !deepEqual(cur, lastVal.current) ||
      !deepEqual(lastRecoilValue.current, recoilValue)
    ) {
      lastVal.current = cur;
      forceRender();
    }
  });

  if (lastRecoilValue.current !== recoilValue) {
    init();
  }

  return lastVal.current as T;
}
