import { datadogRum } from '@datadog/browser-rum';

import { initializeDataDogSentryPlugin } from '@flick-tech/logger';
import { Constants } from '@flick-tech/shared-common';

export const initDatadogRUM = () => {
  if (Constants.isCypress || Constants.isTest) {
    return;
  }

  datadogRum.init({
    applicationId: 'de2756b2-cfd9-477b-8a3d-14fa49a59236',
    clientToken: 'pub6ae21b0b528b12792ce6209929c394f5',
    site: 'datadoghq.eu',
    service: Constants.flickAppID,
    env: Constants.nodeEnv?.toLowerCase(),
    version:
      process.env.COMMIT_SHA ||
      process.env.NEXT_PUBLIC_COMMIT_SHA ||
      process.env.VERCEL_GIT_COMMIT_SHA,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
  });
};

export const startSessionRecording = () => {
  if (Constants.isCypress || Constants.isTest) {
    return;
  }

  datadogRum.startSessionReplayRecording();

  setTimeout(() => {
    initializeDataDogSentryPlugin();
  }, 1000);
};

export { datadogRum };
