import { useId } from '@reach/auto-id';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import { formatNumberLong } from '@flick-tech/shared-formatters';
import { Box, BoxProps, Flex, FlexProps, Text } from '../../layout';
import { Skeleton } from '../../skeleton';

import type { StatInfo } from '../types';

const descriptionListCtx = createContext({
  loading: false,
});

export interface DescriptionListProps extends BoxProps {
  loading?: boolean;
}

export function DescriptionList({
  loading = false,
  ...rest
}: DescriptionListProps) {
  const ctxValue = useMemo(() => ({ loading }), [loading]);

  return (
    <descriptionListCtx.Provider value={ctxValue}>
      <Box as="dl" {...rest} />
    </descriptionListCtx.Provider>
  );
}

export interface DescriptionListItemProps extends FlexProps {
  term: ReactNode;
  details: ReactNode;
}
function DescriptionListItem({
  term,
  details,
  ...rest
}: DescriptionListItemProps) {
  const { loading } = useContext(descriptionListCtx);

  const id = `dt::${useId()}`;

  return (
    <Flex
      py={2}
      borderBottom="1px solid"
      borderBottomColor="blackAlpha.50"
      justify="space-between"
      fontSize="sm"
      lineHeight={1.5}
      {...rest}
    >
      {loading ? (
        <Skeleton height={4} width="100%" />
      ) : (
        <>
          <Text as="dt" color="blackAlpha.600" id={id}>
            {term}
          </Text>
          <Text as="dd" color="#1D1D1D" aria-labelledby={id}>
            {details}
          </Text>
        </>
      )}
    </Flex>
  );
}

DescriptionList.Item = DescriptionListItem;

export interface DotProps extends Omit<BoxProps, 'size'> {
  color?: string;
  size?: number;
}

const getSize = (size = 10) => `${size}px`;

export const Dot = ({ size, color, ...rest }: DotProps) => (
  <Box
    w={getSize(size)}
    h={getSize(size)}
    bg={color}
    borderRadius="50%"
    display="inline-block"
    flexShrink={0}
    {...rest}
  />
);

export interface DescriptionListLegendProps extends DescriptionListProps {
  values?: object;
  stats: StatInfo[];
}

export function DescriptionListLegend({
  stats,
  values = {},
  ...rest
}: DescriptionListLegendProps) {
  return (
    <DescriptionList pt={4} px={2} {...rest}>
      {stats.map(
        ({ key, color, text, valueTransform, value: statValue }, i) => {
          let value = key ? values[key] : statValue;
          if (typeof valueTransform === 'function') {
            value = valueTransform(value);
          }

          value = typeof value === 'number' ? formatNumberLong(value) : value;

          const description =
            typeof text === 'function' ? text(values[key], values) : text;

          return (
            <DescriptionList.Item
              key={`${key}-${i}`}
              term={description}
              details={
                <>
                  {color && <Dot mr="8px" color={color} />}
                  {value}
                </>
              }
            />
          );
        },
      )}
    </DescriptionList>
  );
}
