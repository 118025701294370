import { Property } from 'csstype';

import { get, isNumber } from '@flick-tech/shared-utils';

import { Config, Length, Prop } from '../utils';

function transform(value: any, scale: any) {
  const defaultValue =
    !isNumber(value) || value > 1 ? value : value * 100 + '%';
  return get(scale, value, defaultValue);
}

export const layoutConfig: Config = {
  width: {
    property: 'width',
    scale: 'sizes',
    transform,
  },
  w: {
    property: 'width',
    scale: 'sizes',
    transform,
  },
  height: {
    property: 'height',
    scale: 'sizes',
  },
  h: {
    property: 'height',
    scale: 'sizes',
  },
  boxSize: {
    properties: ['width', 'height'],
    scale: 'sizes',
  },
  minWidth: {
    property: 'minWidth',
    scale: 'sizes',
  },
  minW: {
    property: 'minWidth',
    scale: 'sizes',
  },
  minHeight: {
    property: 'minHeight',
    scale: 'sizes',
  },
  minH: {
    property: 'minHeight',
    scale: 'sizes',
  },
  maxWidth: {
    property: 'maxWidth',
    scale: 'sizes',
  },
  maxW: {
    property: 'maxWidth',
    scale: 'sizes',
  },
  maxHeight: {
    property: 'maxHeight',
    scale: 'sizes',
  },
  maxH: {
    property: 'maxHeight',
    scale: 'sizes',
  },
  overflow: true,
  overflowX: true,
  overflowY: true,
  display: true,
  verticalAlign: true,
  boxSizing: true,
  tableLayout: true,
};

/**
 * Types for layout related CSS properties
 */
export interface LayoutProps {
  /**
   * The CSS `display` property
   */
  display?: Prop<Property.Display>;
  /**
   * The CSS `width` property
   */
  width?: Prop<Property.Width<Length>>;
  /**
   * The CSS `width` property
   */
  w?: Prop<Property.Width<Length>>;
  /**
   * The CSS `width` and `height` property
   */
  boxSize?: Prop<Property.Width<Length>>;
  /**
   * The CSS `max-width` property
   */
  maxWidth?: Prop<Property.MaxWidth<Length>>;
  /**
   * The CSS `max-width` property
   */
  maxW?: Prop<Property.MaxWidth<Length>>;
  /**
   * The CSS `min-width` property
   */
  minWidth?: Prop<Property.MinWidth<Length>>;
  /**
   * The CSS `min-width` property
   */
  minW?: Prop<Property.MinWidth<Length>>;
  /**
   * The CSS `height` property
   */
  height?: Prop<Property.Height<Length>>;
  /**
   * The CSS `height` property
   */
  h?: Prop<Property.Height<Length>>;
  /**
   * The CSS `max-height` property
   */
  maxHeight?: Prop<Property.MaxHeight<Length>>;
  /**
   * The CSS `max-height` property
   */
  maxH?: Prop<Property.MaxHeight<Length>>;
  /**
   * The CSS `min-height` property
   */
  minHeight?: Prop<Property.MinHeight<Length>>;
  /**
   * The CSS `min-height` property
   */
  minH?: Prop<Property.MinHeight<Length>>;
  /**
   * The CSS `vertical-align` property
   */
  verticalAlign?: Prop<Property.VerticalAlign<Length>>;
  /**
   * The CSS `overflow` property
   */
  overflow?: Prop<Property.Overflow>;
  /**
   * The CSS `overflow-x` property
   */
  overflowX?: Prop<Property.OverflowX>;
  /**
   * The CSS `overflow-y` property
   */
  overflowY?: Prop<Property.OverflowY>;
  /**
   * The CSS `box-sizing` property
   */
  boxSizing?: Property.BoxSizing;

  /**
   * The CSS `table-layout` property
   */
  tableLayout?: Property.TableLayout;
}
