import { ThemeContext } from '@emotion/react';
import { ReactNode } from 'react';
import * as React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { ColorModeProvider } from './color-mode';
import { CSSReset } from './CSSReset';
import { GlobalStyle } from './global';
import { Theme, theme } from './preset-base';

export type ThemeProviderProps = {
  children?: React.ReactNode;
  theme: object;
};

export function ThemeProvider(props: ThemeProviderProps) {
  const { children, theme } = props;
  const outerTheme = React.useContext(ThemeContext);
  const mergedTheme = { ...outerTheme, ...theme };

  return (
    <ThemeContext.Provider value={mergedTheme}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme<T extends object = object>() {
  const theme = React.useContext(
    ThemeContext as unknown as React.Context<T | undefined>,
  );
  if (!theme) {
    throw Error('useTheme must be within a ThemeProvider');
  }

  return theme as Theme;
}

interface FlickThemeProvider {
  children: ReactNode;
}

export function FlickThemeProvider({ children }: FlickThemeProvider) {
  return (
    <StyledThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <ColorModeProvider>
          <GlobalStyle />
          <CSSReset theme={theme} />
          {children}
        </ColorModeProvider>
      </ThemeProvider>
    </StyledThemeProvider>
  );
}
