// This has to be raw due to its usage in FlickHeatmap
export const primaryShades = ['#EAF1FC', '#BFD8FF', '#7EAAEF', '#3275e0'];
export const primaryShadesText = ['dark', 'dark', 'light', 'light'];

export const primaryColor = () => (theme) => {
  return theme?.colors?.brand[500] ?? '#3174e0';
};

export const textColorRaw = 'rgba(0, 0, 0, .85)';
export const textColor = (props?: any) => 'rgba(0, 0, 0, .85)';
export const secondaryTextColor = (props?: any) => '#868e96';

// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=3275e0&primary.text.color=000000&secondary.color=ff6378
export const secondaryColorRaw = '#ff6584';

export const secondaryColor = (props: any) => '#ff6584';
