import { useCallback, useEffect, useState } from 'react';

import { formatNumber } from '@flick-tech/shared-formatters';
import { chakra } from '@flick-tech/theme-new';
import { Button } from '../button';
import { Card } from '../card';
import { Chip, ChipProps } from '../chip';
import { usePopper } from '../popper';
import { RangeSlider, RangeValue } from '../range-slider';

const chipId = 'search-filter-chip';

type RangeFilterValue = {
  min?: number;
  max?: number;
};

type RangeFilterChipProps = {
  onChipClick: ChipProps['onClick'];
  isOpen: boolean;
  onApply: (value: RangeValue) => void;
  onClose: () => void;
  onRemove: (hasNoValue?: boolean) => void;
  // Also used as an initial value for the slider each time the filter is opened
  chipValue: RangeFilterValue;
  name: string;
  valueSet: number[];
  isDisabled?: boolean;
};

export const RangeFilterChip = ({
  onApply,
  onRemove,
  onClose,
  onChipClick,
  isOpen,
  name,
  chipValue,
  valueSet,
  isDisabled,
}: RangeFilterChipProps) => {
  const range = {
    min: valueSet[0],
    max: valueSet[valueSet.length - 1],
  };
  const initialSliderValue: RangeValue = [
    chipValue.min ?? range.min,
    chipValue.max ?? range.max,
  ];
  const [sliderValue, setSliderValue] =
    useState<RangeValue>(initialSliderValue);
  const [min, max] = sliderValue;
  const sliderHasNoValue = min === range.min && max === range.max && isOpen;

  const { popper, reference } = usePopper({
    placement: 'bottom-start',
    forceUpdate: isOpen,
    fixed: true,
  });

  const cardRef = popper.ref;

  const isChipMinAny =
    chipValue.min === range.min || chipValue.min === undefined;
  const isChipMaxAny =
    chipValue.max === range.max || chipValue.max === undefined;

  const chipHasNoValue = isChipMinAny && isChipMaxAny;

  const handleApply = () => {
    onApply(sliderValue);
  };

  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // if the card is not open, don't do anything
      if (!isOpen) return;

      const isChipButton =
        target.getAttribute('data-id') === chipId ||
        target.parentElement.getAttribute('data-id') === chipId;

      // if the click is within the card, don't do anything
      if (isChipButton || cardRef.current?.contains(target)) {
        return;
      }

      if (chipHasNoValue) {
        onRemove(true);
      } else {
        onClose();
      }
    },
    [cardRef, chipHasNoValue, isOpen, onClose, onRemove],
  );

  // Effect to close this filter card on outside click
  useEffect(() => {
    // add the event listener for click
    document.addEventListener('click', handleDocumentClick, {
      capture: true,
    });

    return () => {
      // remove the event listener, to avoid memory leak
      document.removeEventListener('click', handleDocumentClick, {
        capture: true,
      });
    };
  }, [handleDocumentClick]);

  const [valueMin, valueMax] = sliderValue;
  const valuesEqual = Number.isFinite(valueMax) && valueMin === valueMax;

  return (
    <chakra.div>
      <Chip
        onRemove={onRemove}
        onClick={onChipClick}
        variant={isOpen ? 'solid' : 'subdued'}
        isDisabled={isDisabled}
        data-id={chipId}
        {...reference}
      >
        {name}:{' '}
        {chipHasNoValue
          ? ''
          : `${formatNumber(isChipMinAny ? range.min || 0 : chipValue.min)}
           - ${isChipMaxAny ? 'any' : formatNumber(chipValue.max)}`}
      </Chip>
      <Card
        width="360px"
        zIndex="docked"
        hidden={!isOpen}
        {...popper}
        data-intercom-id="range-filter"
      >
        <chakra.p color="blackAlpha.600">
          Hashtags that do not match your specified range will be hidden from
          the table.
        </chakra.p>
        <chakra.div
          my={3}
          p={3}
          border="solid 1px"
          borderRadius="md"
          borderColor="blackAlpha.50"
        >
          {/* This way it will reset to initial value each time without having to control it */}
          {isOpen && (
            <RangeSlider
              withInputs
              valueSet={valueSet}
              range={range}
              initialValue={initialSliderValue}
              debounceMS={50}
              onChange={setSliderValue}
            />
          )}
        </chakra.div>
        <chakra.div display="flex" justifyContent="flex-end">
          <Button
            variant="ghost"
            onClick={handleApply}
            isDisabled={sliderHasNoValue || valuesEqual}
            data-intercom-id="range-filter--apply-btn"
          >
            Apply
          </Button>
        </chakra.div>
      </Card>
    </chakra.div>
  );
};
