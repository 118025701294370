import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import { Event, EventHint } from '@sentry/types';

import { isRunningOnServerSide } from '@flick-tech/shared-common';

import {
  getOriginalExceptionProperties,
  getSentryUrlForEventId,
} from './sentry.utils';

/**
 * This integration creates a link from the Sentry Error to the DataDog session replay.
 * It also creates a link from the DataDog event to the Sentry error.
 */

let hasInitialised = false;

export const initializeDataDogSentryPlugin = () => {
  if (isRunningOnServerSide() || hasInitialised) {
    return;
  }

  hasInitialised = true;

  Sentry.addGlobalEventProcessor((event: Event, hint?: EventHint) => {
    const getDataDogSessionURL = (): string => {
      try {
        const sessionId = datadogRum.getInternalContext()?.session_id;

        if (!sessionId) {
          return 'Current session URL API not ready';
        }

        return `https://app.datadoghq.eu/rum/replay/sessions/${sessionId}`;
      } catch (e) {
        const reason = e instanceof Error ? e.message : String(e);
        return `Unable to get url: ${reason}`;
      }
    };

    if (event.type !== 'transaction') {
      event.contexts = {
        ...event.contexts,
        dataDog: {
          dataDogSessionUrl: getDataDogSessionURL(),
        },
      };
      try {
        datadogRum.addAction('Sentry Error', {
          sentryUrl: getSentryUrlForEventId(String(event.event_id)),
          ...getOriginalExceptionProperties(hint),
        });
      } catch (e) {
        console.debug('Unable to report sentry error details to DataDog');
      }
    }

    return event;
  });
};
