import { Property } from 'csstype';

import { ThemedProperty } from '../types';
import { Config, Prop } from '../utils';

/**
 * The parser configuration for common border properties
 */
export const colorConfig: Config = {
  color: {
    property: 'color',
    scale: 'colors',
  },
  textColor: {
    property: 'color',
    scale: 'colors',
  },
  opacity: true,
  fill: {
    property: 'fill',
    scale: 'colors',
  },
  stroke: {
    property: 'stroke',
    scale: 'colors',
  },
};

export interface ColorProps {
  /**
   * The CSS `color` property
   */
  textColor?: Prop<ThemedProperty.Color>;
  /**
   * The CSS `color` property
   */
  color?: Prop<ThemedProperty.Color>;
  /**
   * The CSS `fill` property for icon svgs and paths
   */
  fill?: Prop<ThemedProperty.Fill>;
  /**
   * The CSS `stroke` property for icon svgs and paths
   */
  stroke?: Prop<ThemedProperty.Stroke>;
  /**
   * The CSS `opacity` property
   */
  opacity?: Prop<Property.Opacity>;
}
