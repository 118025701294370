import { useRouter as useNextRouter } from 'next/router';
import { useCallback } from 'react';

import {
  Constants,
  isRunningOnServerSide,
  pushState,
  replaceState,
} from '@flick-tech/shared-common';

export const addQuery = (name: string, value: string): string => {
  const query = new URLSearchParams(window.location.search);
  query.set(name, value);
  return query.toString();
};

const getSearchPart = () => {
  if (isRunningOnServerSide()) return '';

  return window.location.search;
};

export const removeQuery = (name: string, search = getSearchPart()): string => {
  const query = new URLSearchParams(search);
  query.delete(name);
  return query.toString();
};

export const shallowRemoveQueryFromHistory = (key: string) => {
  const url = new URL(window.location.href);
  url.search = `${removeQuery(key)}`;
  pushState(url.toString());
};

export const shallowRemoveUrlParams = (keysToRemove: string[] = []) => {
  if (isRunningOnServerSide()) return;

  const newUrl = window.location.pathname;
  let queryString = '';

  if (keysToRemove.length > 0) {
    let search = window.location.search;

    keysToRemove.forEach((key) => {
      search = removeQuery(key, search);
    });

    queryString = search;
  }

  pushState(`${newUrl}?${queryString}`);
};

export const addQueryParamsToUrl = (
  url: string,
  urlParams?: Record<string, string | number>,
) => {
  const paramEntries = Object.entries(urlParams ?? {});

  if (paramEntries.length > 0) {
    const params = new URLSearchParams();

    paramEntries.forEach(([k, v]) => {
      params.set(k, v.toString());
    });

    return `${url}?${params.toString()}`;
  }

  return url;
};

interface NavigatePayload {
  urlParams?: Record<string, number | string>;
}

export function useNavigate() {
  const router = useNextRouter();

  return useCallback(
    (route_: string, { urlParams }: NavigatePayload = {}) => {
      const route = addQueryParamsToUrl(route_, urlParams);

      if (!Constants.isWeb) {
        window.open(`${Constants.webBase}${route}`, '_self');
        return;
      }

      return router.push(route);
    },
    [router],
  );
}

export function usePathname() {
  const router = useNextRouter();

  return router.pathname;
}
