import * as React from 'react';

import { __DEV__, createContext, cx } from '@flick-tech/shared-utils';
import { chakra, PropsOf, ThemingProps } from '@flick-tech/theme-new';

import {
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioGroupReturn,
} from './RadioGroup.hook';

export type RadioGroupContext = Pick<
  UseRadioGroupReturn,
  'onChange' | 'value' | 'name'
> &
  Omit<ThemingProps, 'orientation'>;

const [RadioGroupContextProvider, useRadioGroupContext] =
  createContext<RadioGroupContext>({
    name: 'RadioGroupContext',
    strict: false,
  });

export { useRadioGroupContext };

export type RadioGroupProps = UseRadioGroupProps &
  Omit<PropsOf<typeof chakra.div>, 'onChange' | 'value' | 'defaultValue'> &
  Omit<ThemingProps, 'orientation'> & { children: React.ReactNode };

/**
 * Used for multiple radios which are bound in one group,
 * and it indicates which option is selected.
 *
 * @see Docs https://chakra-ui.com/radio
 */
export const RadioGroup = React.forwardRef(
  (props: RadioGroupProps, ref: React.Ref<any>) => {
    const {
      colorScheme,
      size,
      variant,
      children,
      className,
      ...radioGroupProps
    } = props;

    const {
      value,
      onChange,
      getRootProps,
      name,
      htmlProps: rest,
    } = useRadioGroup(radioGroupProps);

    const group = React.useMemo(
      () => ({
        name,
        size,
        onChange,
        colorScheme,
        value,
        variant,
      }),
      [size, name, onChange, colorScheme, value, variant],
    );

    const groupProps = getRootProps({ ref, ...rest });
    const _className = cx('chakra-radio-group', className);

    return (
      <RadioGroupContextProvider value={group}>
        <chakra.div {...groupProps} className={_className}>
          {children}
        </chakra.div>
      </RadioGroupContextProvider>
    );
  },
);

if (__DEV__) {
  RadioGroup.displayName = 'RadioGroup';
}
