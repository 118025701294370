import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <>
    <path
      d="M8.89175 11.6118C8.70841 11.6034 8.52508 11.5951 8.33341 11.5951C6.31675 11.5951 4.43341 12.1534 2.82508 13.1118C2.09175 13.5451 1.66675 14.3618 1.66675 15.2201L1.66675 16.5951C1.66675 17.0534 2.04175 17.4284 2.50008 17.4284H9.38341C8.72508 16.4868 8.33341 15.3368 8.33341 14.0951C8.33341 13.2034 8.54175 12.3701 8.89175 11.6118Z"
      fill="white"
    />
    <path
      d="M8.33333 10.7618C10.1743 10.7618 11.6667 9.26938 11.6667 7.42843C11.6667 5.58748 10.1743 4.09509 8.33333 4.09509C6.49238 4.09509 5 5.58748 5 7.42843C5 9.26938 6.49238 10.7618 8.33333 10.7618Z"
      fill="white"
    />
    <path
      d="M17.2916 14.0951C17.2916 13.9118 17.2667 13.7451 17.2417 13.5701L17.9416 12.9618C18.0917 12.8284 18.125 12.6118 18.025 12.4368L17.5333 11.5868C17.4333 11.4118 17.225 11.3368 17.0417 11.4034L16.1583 11.7034C15.8916 11.4784 15.5916 11.3034 15.2583 11.1784L15.075 10.2701C15.0333 10.0784 14.8667 9.93677 14.6667 9.93677H13.6833C13.4833 9.93677 13.3167 10.0784 13.275 10.2701L13.0916 11.1784C12.7583 11.3034 12.4583 11.4784 12.1917 11.7034L11.3083 11.4034C11.1166 11.3368 10.9166 11.4201 10.8166 11.5868L10.325 12.4368C10.225 12.6118 10.2583 12.8284 10.4083 12.9618L11.1083 13.5701C11.0833 13.7451 11.0583 13.9118 11.0583 14.0951C11.0583 14.2784 11.0833 14.4451 11.1083 14.6201L10.4083 15.2284C10.2583 15.3618 10.225 15.5784 10.325 15.7534L10.8166 16.6034C10.9166 16.7784 11.125 16.8534 11.3083 16.7868L12.1917 16.4868C12.4583 16.7118 12.7583 16.8868 13.0916 17.0118L13.275 17.9201C13.3167 18.1118 13.4833 18.2534 13.6833 18.2534H14.6667C14.8667 18.2534 15.0333 18.1118 15.075 17.9201L15.2583 17.0118C15.5916 16.8868 15.8916 16.7118 16.1583 16.4868L17.0417 16.7868C17.2333 16.8534 17.4333 16.7701 17.5333 16.6034L18.025 15.7534C18.125 15.5784 18.0917 15.3618 17.9416 15.2284L17.2417 14.6201C17.2667 14.4451 17.2916 14.2784 17.2916 14.0951ZM14.1666 15.7618C13.25 15.7618 12.5 15.0118 12.5 14.0951C12.5 13.1784 13.25 12.4284 14.1666 12.4284C15.0833 12.4284 15.8333 13.1784 15.8333 14.0951C15.8333 15.0118 15.0833 15.7618 14.1666 15.7618Z"
      fill="white"
    />
  </>,
  'ManageAccounts',
);
