import Input from './InputTheme';
import { mode } from './utils';

const { sizes, defaultProps, variants } = Input;

const parts = ['field', 'icon'];

function baseStyleField(props: Record<string, any>) {
  return {
    appearance: 'none',
    paddingBottom: '1px',
    lineHeight: 'normal',
    '> option': {
      bg: mode('white', 'gray.700')(props as any),
    },
  };
}

const baseStyleInput = {
  color: 'currentColor',
  fontSize: '1.25rem',
  _disabled: { opacity: 0.5 },
  width: '100%',
  outline: 0,
  position: 'relative',
  appearance: 'none',
  transition: 'all 0.2s',
};

const baseStyle = (props: Record<string, any>) => ({
  field: baseStyleField(props),
  icon: baseStyleInput,
});

const SelectTheme = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default SelectTheme;
