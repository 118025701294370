import { cloneElement, forwardRef, Ref } from 'react';
import * as React from 'react';

import { AsProp, FC } from '@flick-tech/shared-types';
import { __DEV__, getValidChildren } from '@flick-tech/shared-utils';
import { chakra, ChakraProps, PropsOf } from '@flick-tech/theme-new';
import { Icon, IconProps } from '../icon';

import { BoxProps } from './Box';

interface ListOptions {
  /**
   * Short hand prop for `listStyleType`
   */
  styleType?: ChakraProps['listStyleType'];
  /**
   * Short hand prop for `listStylePosition`
   */
  stylePosition?: ChakraProps['listStylePosition'];
  /**
   * The space between each list item
   */
  spacing?: ChakraProps['margin'];
}

export interface ListProps
  extends Omit<PropsOf<'ul'>, 'color'>,
    ChakraProps,
    AsProp,
    ListOptions {}

/**
 * List is used to display list items, it renders a `<ul>` by default.
 *
 * @see Docs https://chakra-ui.com/list
 */
export const List: FC<ListProps> = forwardRef(
  (props: ListProps, ref: Ref<any>) => {
    const {
      children,
      styleType = 'none',
      stylePosition = 'inside',
      spacing,
      ...rest
    } = props;

    const validChildren = getValidChildren(children);

    return (
      <chakra.ul
        ref={ref}
        listStyleType={styleType}
        listStylePosition={stylePosition}
        {...rest}
        color={rest.color as string}
      >
        {validChildren.map((child, index) => {
          const isLast = index + 1 === validChildren.length;
          if (isLast) return child;
          return spacing ? cloneElement(child, { mb: spacing }) : child;
        })}
      </chakra.ul>
    );
  },
);

if (__DEV__) {
  List.displayName = 'List';
}

export interface ListItemProps extends PropsOf<typeof ListItem> {}

/**
 * ListItem
 *
 * Used to render a list item
 */
export const ListItem = (props: PropsOf<typeof chakra['li']>) => (
  <chakra.li fontWeight={400} fontSize="18px" lineHeight="160%" {...props} />
);

/**
 * ListIcon
 *
 * Used to render an icon beside the list item text
 */
export const ListIcon = (props: IconProps) => (
  <Icon mr={2} display="inline" verticalAlign="text-bottom" {...props} />
);
if (__DEV__) {
  ListIcon.displayName = 'ListIcon';
}

export const OrderedList = forwardRef(function OrderedList(
  props: ListProps,
  ref: React.Ref<any>,
) {
  return (
    <List ref={ref} as="ol" styleType="decimal" marginLeft="1em" {...props} />
  );
});

if (__DEV__) {
  OrderedList.displayName = 'OrderedList';
}
