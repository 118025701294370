import AccessTimeRounded from './icons/AccessTimeRounded';
import AccountBalanceRounded from './icons/AccountBalanceRounded';
import AccountBoxRounded from './icons/AccountBoxRounded';
import AccountCircle from './icons/AccountCircle';
import AddBoxRounded from './icons/AddBoxRounded';
import AddCommentRounded from './icons/AddCommentRounded';
import AddReaction from './icons/AddReaction';
import AddRounded from './icons/AddRounded';
import AddShoppingCartRounded from './icons/AddShoppingCartRounded';
import ArrowBack from './icons/ArrowBack';
import ArrowBackIosRounded from './icons/ArrowBackIosRounded';
import ArrowDownwardRounded from './icons/ArrowDownwardRounded';
import ArrowDropDownRounded from './icons/ArrowDropDownRounded';
import ArrowForwardIosRounded from './icons/ArrowForwardIosRounded';
import AssessmentRounded from './icons/AssessmentRounded';
import BarChart from './icons/BarChart';
import BoltRounded from './icons/BoltRounded';
import BookmarkRounded from './icons/BookmarkRounded';
import BubbleChart from './icons/BubbleChart';
import CachedRounded from './icons/CachedRounded';
import CalendarTodayRounded from './icons/CalendarTodayRounded';
import ChatRounded from './icons/ChatRounded';
import CheckBoxRounded from './icons/CheckBoxRounded';
import CheckCircleOutlineRounded from './icons/CheckCircleOutlineRounded';
import CheckRounded from './icons/CheckRounded';
import ChevronLeftRounded from './icons/ChevronLeftRounded';
import ChevronRightRounded from './icons/ChevronRightRounded';
import ChevronUpDown from './icons/ChevronUpDown';
import ClearRounded from './icons/ClearRounded';
import CloseRounded from './icons/CloseRounded';
import CommentRounded from './icons/CommentRounded';
import ConeMenu from './icons/ConeMenu';
import CreateNewFolder from './icons/CreateNewFolder';
import CreateNewFolderOutlined from './icons/CreateNewFolderOutlined';
import CreateNewFolderRounded from './icons/CreateNewFolderRounded';
import CreditCardRounded from './icons/CreditCardRounded';
import CropRounded from './icons/CropRounded';
import DeleteForeverRounded from './icons/DeleteForeverRounded';
import DeleteRounded from './icons/DeleteRounded';
import DeleteSweep from './icons/DeleteSweep';
import DescriptionRounded from './icons/DescriptionRounded';
import DoneAllRounded from './icons/DoneAllRounded';
import DoneRounded from './icons/DoneRounded';
import EditRounded from './icons/EditRounded';
import EmailRounded from './icons/EmailRounded';
import Error from './icons/Error';
import ErrorOutlineRounded from './icons/ErrorOutlineRounded';
import EventRounded from './icons/EventRounded';
import ExitToAppRounded from './icons/ExitToAppRounded';
import ExpandLessRounded from './icons/ExpandLessRounded';
import ExpandMoreRounded from './icons/ExpandMoreRounded';
import FavoriteRounded from './icons/FavoriteRounded';
import FiberManualRecordRounded from './icons/FiberManualRecordRounded';
import FileCopyRounded from './icons/FileCopyRounded';
import FileDownload from './icons/FileDownload';
import FilterListRounded from './icons/FilterListRounded';
import FlashIconRounded from './icons/FlashIconRounded';
import FolderOpenOutlined from './icons/FolderOpenOutlined';
import FolderRounded from './icons/FolderRounded';
import FolderSpecialRounded from './icons/FolderSpecialRounded';
import FormatBold from './icons/FormatBold';
import FormatStrikethrough from './icons/FormatStrikethrough';
import GetAppRounded from './icons/GetAppRounded';
import Hashtag from './icons/Hashtag';
import HelpOutlineRounded from './icons/HelpOutlineRounded';
import HelpRounded from './icons/HelpRounded';
import HighlightOffRounded from './icons/HighlightOffRounded';
import Home from './icons/Home';
import HomeRounded from './icons/HomeRounded';
import HowToRegRounded from './icons/HowToRegRounded';
import Image from './icons/Image';
import ImageRounded from './icons/ImageRounded';
import ImageSearchRounded from './icons/ImageSearchRounded';
import ImportExport from './icons/ImportExport';
import Info from './icons/Info';
import InsertChartOutlined from './icons/InsertChartOutlined';
import Instagram from './icons/Instagram';
import KeyboardArrowDownRounded from './icons/KeyboardArrowDownRounded';
import KeyboardArrowLeftRounded from './icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRounded from './icons/KeyboardArrowRightRounded';
import KeyboardArrowUpRounded from './icons/KeyboardArrowUpRounded';
import KeyboardReturn from './icons/KeyboardReturn';
import LaunchRounded from './icons/LaunchRounded';
import LayersRounded from './icons/LayersRounded';
import LightbulbRounded from './icons/LightbulbRounded';
import LinkRounded from './icons/LinkRounded';
import ListRounded from './icons/ListRounded';
import LocationOnRounded from './icons/LocationOnRounded';
import LockOpen from './icons/LockOpen';
import LockRounded from './icons/LockRounded';
import LongArrowForward from './icons/LongArrowForward';
import LoyaltyRounded from './icons/LoyaltyRounded';
import MagicalTimelineRounded from './icons/MagicalTimelineRounded';
import Mail from './icons/Mail';
import ManageAccounts from './icons/ManageAccounts';
import ManageAccountsRounded from './icons/ManageAccountsRounded';
import MenuBookRounded from './icons/MenuBookRounded';
import MenuRounded from './icons/MenuRounded';
import MessageRounded from './icons/MessageRounded';
import MoreHoriz from './icons/MoreHoriz';
import MoreHorizRounded from './icons/MoreHorizRounded';
import NewReleases from './icons/NewReleases';
import NotificationsActive from './icons/NotificationsActive';
import NotificationsNone from './icons/NotificationsNone';
import OfflineBoltRounded from './icons/OfflineBoltRounded';
import OndemandVideoRounded from './icons/OndemandVideoRounded';
import OpenInFull from './icons/OpenInFull';
import OpenInNewRounded from './icons/OpenInNewRounded';
import PageviewRounded from './icons/PageviewRounded';
import People from './icons/People';
import PeopleAltRounded from './icons/PeopleAltRounded';
import PeopleOutlineRounded from './icons/PeopleOutlineRounded';
import Person from './icons/Person';
import PersonAddRounded from './icons/PersonAddRounded';
import PhotoLibrary from './icons/PhotoLibrary';
import PieChartRounded from './icons/PieChartRounded';
import PlayCircleFilled from './icons/PlayCircleFilled';
import PlayCircleOutlineRounded from './icons/PlayCircleOutlineRounded';
import PlaylistAddCheckRounded from './icons/PlaylistAddCheckRounded';
import PostAddRounded from './icons/PostAddRounded';
import PublishRounded from './icons/PublishRounded';
import ReceiptRounded from './icons/ReceiptRounded';
import RecentRestore from './icons/RecentRestore';
import RefreshRounded from './icons/RefreshRounded';
import RemoveCircleRounded from './icons/RemoveCircleRounded';
import RemoveRounded from './icons/RemoveRounded';
import RemoveShoppingCartRounded from './icons/RemoveShoppingCartRounded';
import RepeatOneRounded from './icons/RepeatOneRounded';
import RestoreRounded from './icons/RestoreRounded';
import RuleRounded from './icons/RuleRounded';
import SaveRounded from './icons/SaveRounded';
import SchoolRounded from './icons/SchoolRounded';
import SearchRounded from './icons/SearchRounded';
import SettingsRounded from './icons/SettingsRounded';
import Share from './icons/Share';
import ShowChart from './icons/ShowChart';
import SmartphoneRounded from './icons/SmartphoneRounded';
import SortRounded from './icons/SortRounded';
import SpeakerNotesOffRounded from './icons/SpeakerNotesOffRounded';
import StarBorderRounded from './icons/StarBorderRounded';
import StarRounded from './icons/StarRounded';
import SubjectRounded from './icons/SubjectRounded';
import Tent from './icons/Tent';
import Timelapse from './icons/Timelapse';
import TouchAppRounded from './icons/TouchAppRounded';
import TrendingUp from './icons/TrendingUp';
import TrendingUpRounded from './icons/TrendingUpRounded';
import UndoRounded from './icons/UndoRounded';
import UpdateRounded from './icons/UpdateRounded';
import Videocam from './icons/Videocam';
import ViewModuleRounded from './icons/ViewModuleRounded';
import VisibilityOffRounded from './icons/VisibilityOffRounded';
import VisibilityRounded from './icons/VisibilityRounded';
import WarningRounded from './icons/WarningRounded';
import WhatsHotFilled from './icons/WhatsHotFilled';

export const materialIcons = {
  AccessTimeRounded,
  AccountBalanceRounded,
  AccountBoxRounded,
  AccountCircle,
  AddBoxRounded,
  AddCommentRounded,
  AddRounded,
  AddShoppingCartRounded,
  ArrowBack,
  ArrowBackIosRounded,
  ArrowDownwardRounded,
  ArrowDropDownRounded,
  ArrowForwardIosRounded,
  AssessmentRounded,
  BarChart,
  BoltRounded,
  BookmarkRounded,
  BubbleChart,
  CachedRounded,
  ChatRounded,
  CheckBoxRounded,
  CheckCircleOutlineRounded,
  CheckRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  ChevronUpDown,
  ClearRounded,
  CloseRounded,
  CommentRounded,
  ConeMenu,
  CreateNewFolder,
  CreateNewFolderOutlined,
  CreateNewFolderRounded,
  CreditCardRounded,
  DeleteForeverRounded,
  DeleteRounded,
  DeleteSweep,
  DescriptionRounded,
  DoneAllRounded,
  DoneRounded,
  EditRounded,
  EmailRounded,
  Error,
  ErrorOutlineRounded,
  ExitToAppRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  FavoriteRounded,
  FileDownload,
  FiberManualRecordRounded,
  FileCopyRounded,
  FilterListRounded,
  FlashIconRounded,
  FolderOpenOutlined,
  FolderRounded,
  FolderSpecialRounded,
  GetAppRounded,
  Hashtag,
  HelpOutlineRounded,
  HelpRounded,
  HighlightOffRounded,
  Home,
  HomeRounded,
  HowToRegRounded,
  Image,
  ImageRounded,
  ImageSearchRounded,
  ImportExport,
  Info,
  InsertChartOutlined,
  Instagram,
  KeyboardArrowDownRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  KeyboardArrowUpRounded,
  KeyboardReturn,
  LaunchRounded,
  LayersRounded,
  LinkRounded,
  ListRounded,
  LockOpen,
  LockRounded,
  LongArrowForward,
  LoyaltyRounded,
  MagicalTimelineRounded,
  Mail,
  ManageAccounts,
  MenuBookRounded,
  MenuRounded,
  MessageRounded,
  MoreHoriz,
  MoreHorizRounded,
  NewReleases,
  NotificationsActive,
  NotificationsNone,
  OfflineBoltRounded,
  OndemandVideoRounded,
  OpenInFull,
  OpenInNewRounded,
  PageviewRounded,
  People,
  PeopleAltRounded,
  PeopleOutlineRounded,
  Person,
  PersonAddRounded,
  PhotoLibrary,
  PieChartRounded,
  PlayCircleFilled,
  PlayCircleOutlineRounded,
  PlaylistAddCheckRounded,
  PostAddRounded,
  PublishRounded,
  ReceiptRounded,
  RecentRestore,
  RefreshRounded,
  RemoveCircleRounded,
  RemoveRounded,
  RemoveShoppingCartRounded,
  RepeatOneRounded,
  RestoreRounded,
  RuleRounded,
  SaveRounded,
  SchoolRounded,
  SearchRounded,
  SettingsRounded,
  Share,
  ShowChart,
  SortRounded,
  SpeakerNotesOffRounded,
  StarBorderRounded,
  StarRounded,
  SubjectRounded,
  Tent,
  Timelapse,
  TouchAppRounded,
  TrendingUp,
  TrendingUpRounded,
  UndoRounded,
  UpdateRounded,
  Videocam,
  ViewModuleRounded,
  VisibilityOffRounded,
  VisibilityRounded,
  WarningRounded,
  WhatsHotFilled,
  AddReaction,
  FormatStrikethrough,
  FormatBold,
  EventRounded,
  LocationOnRounded,
  CropRounded,
  SmartphoneRounded,
  CalendarTodayRounded,
  LightbulbRounded,
  ManageAccountsRounded,
};
