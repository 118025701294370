import { AsProp, FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, ChakraProps, PropsOf } from '@flick-tech/theme-new';

export interface BadgeProps
  extends Omit<PropsOf<'span'>, 'color'>,
    ChakraProps,
    AsProp {}

/**
 * React component used to display notifications, messages, or
 * statuses in different shapes and sizes.
 *
 * @see Docs https://chakra-ui.com/badge
 */
export const Badge: FC<BadgeProps> = chakra('span', {
  themeKey: 'Badge',
  baseStyle: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
  },
});

if (__DEV__) {
  Badge.displayName = 'Badge';
}

const _ = <Badge as="form" />;
