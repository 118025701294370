import { forwardRef, useState } from 'react';
import * as React from 'react';

import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, PropsOf } from '@flick-tech/theme-new';

import { Button } from '../button';
import { Icon } from '../icon';

export type ChipProps = Omit<PropsOf<typeof ChipButton>, 'variant'> & {
  variant?: 'solid' | 'subdued';
  onRemove?: () => void;
  ['data-id']?: string;
};

const ChipWrapper = chakra('span', {
  themeKey: 'Chip.ChipWrapper',
  baseStyle: {
    display: 'inline-flex',
    position: 'relative',
    maxWidth: '100%',
  },
});

const ChipButton = chakra(Button, {
  themeKey: 'Chip.ChipButton',
  baseStyle: {
    appearance: 'none',
    alignItems: 'center',
    outline: 0,
    transition: 'all 250ms',
    borderRadius: '100px',
  },
  pure: true,
});

const ChipRemoveButtonWrapper = chakra('div', {
  themeKey: 'Chip.ChipRemoveButtonWrapper',
  baseStyle: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
  },
});

const ChipRemoveButton = chakra('button', {
  themeKey: 'Chip.ChipRemoveButton',
  baseStyle: {
    // Basic button styles?
    appearance: 'none',
    transition: 'all 250ms',
    outline: 0,
    borderRadius: 'full',
    overflow: 'hidden',
    bg: 'white',
    color: 'brand.500',
  },
  pure: true,
});

export const Chip = forwardRef(
  (
    { children, onRemove, colorScheme, ...props }: ChipProps,
    ref: React.Ref<any>,
  ) => {
    const [overIcon, setOverIcon] = useState(false);
    if (props.hasError) {
      colorScheme = 'red';
    }
    const {
      isDisabled,
      isLoading,
      isActive,
      isFullWidth,
      isFocused,
      leftIcon,
      rightIcon,
      loadingText,
      iconSpacing,
      type,
      spinner,
      size,
      variant,
      hasError,
      onClick,
      tabIndex,
      'data-id': dataId,
      ...wrapperProps
    } = props;

    const buttonProps = {
      isDisabled,
      isLoading,
      isActive,
      isFullWidth,
      isFocused,
      children,
      leftIcon,
      rightIcon,
      loadingText,
      iconSpacing,
      type,
      spinner,
      size,
      variant,
      hasError,
      onClick,
      tabIndex,
      'data-id': dataId,
    };

    return (
      <ChipWrapper
        // Wrapper gets all the spacing props etc
        {...wrapperProps}
        _active={
          !overIcon && {
            transform: 'scale(0.97)',
          }
        }
      >
        <ChipButton {...buttonProps} colorScheme={colorScheme} ref={ref}>
          {children}
        </ChipButton>
        {/* Nesting ChipRemoveButton within ChipButton causes multiple style & functional issues */}
        <ChipRemoveButtonWrapper size={props.size}>
          <ChipRemoveButton
            size={props.size}
            variant={props.variant}
            colorScheme={colorScheme}
            onClick={() => (isDisabled ? null : onRemove())}
            tabIndex={tabIndex}
            onPointerEnter={() => setOverIcon(true)}
            onPointerLeave={() => setOverIcon(false)}
            _hover={{
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
          >
            <Icon
              icon="ClearRounded"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          </ChipRemoveButton>
        </ChipRemoveButtonWrapper>
      </ChipWrapper>
    );
  },
);

if (__DEV__) {
  Chip.displayName = 'Chip';
}
