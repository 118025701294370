import { combineParsers } from './combine-parser';
import {
  backgroundConfig,
  borderConfig,
  colorConfig,
  flexboxConfig,
  gridConfig,
  layoutConfig,
  othersConfig,
  outlineConfig,
  positionConfig,
  shadowConfig,
  spaceConfigs,
  typographyConfig,
} from './configs';
import { createParser } from './create-parser';
import { pseudoPropNames } from './pseudo';

export const color = createParser(colorConfig);
export const background = createParser(backgroundConfig);
export const border = createParser(borderConfig);
export const flexbox = createParser(flexboxConfig);
export const grid = createParser(gridConfig);
export const others = createParser(othersConfig);
export const position = createParser(positionConfig);
export const shadow = createParser(shadowConfig);
export const space = createParser(spaceConfigs);
export const typography = createParser(typographyConfig);
export const layout = createParser(layoutConfig);
export const outline = createParser(outlineConfig);

export const parser = combineParsers(
  background,
  border,
  color,
  flexbox,
  layout,
  outline,
  grid,
  others,
  position,
  shadow,
  space,
  typography,
);

const layoutParser = combineParsers(space, layout, flexbox, grid, position);

export const layoutPropNames = layoutParser.propNames;

export const propNames = [...parser.propNames, ...pseudoPropNames];
