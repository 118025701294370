import { PropsWithChildren } from 'react';
import { RecoilRoot, RecoilRootProps } from 'recoil';

import { RecoilExternalStatePortal } from './RecoilExternalStatePortal';

export function RecoilProvider({
  children,
  ...rest
}: PropsWithChildren<RecoilRootProps>) {
  return (
    <RecoilRoot {...rest}>
      <RecoilExternalStatePortal />
      {children}
    </RecoilRoot>
  );
}
