import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Breadcrumb, CaptureContext } from '@sentry/types';

import { Constants, isRunningOnServerSide } from '@flick-tech/shared-common';
import { isObject } from '@flick-tech/shared-utils';

import { shouldSkipErrorInSentry } from './sentry/sentry.utils';
import { ILogger } from './logger.types';
import { Sentry } from './sentry';

const loggerFunction = console;

const nativeLog = (...args: any[]) => {
  if (isRunningOnServerSide()) return;

  // WARNING: do not assign window.ReactNativeWebView.postMessage
  // to a local variable, it breaks the android build...
  if (window.ReactNativeWebView?.postMessage) {
    const stringArgs = args.map((e) =>
      typeof e === 'string' || typeof e === 'number'
        ? String(e)
        : JSON.stringify(e),
    );
    window.ReactNativeWebView?.postMessage?.(
      stringArgs.length === 1 ? stringArgs[0] : stringArgs.join(', '),
    );
  }
};

declare global {
  interface Window {
    FS?: any;
  }
}

const info = (...props: any[]) => {
  const data = props[0];

  const breadcrumb: Breadcrumb = {
    level: 'info',
    type: 'log',
  };

  if (isObject(data)) {
    breadcrumb.data = data;
  } else {
    breadcrumb.message = JSON.stringify(data);
  }

  Sentry.addBreadcrumb(breadcrumb);

  loggerFunction.log(...props);

  if (Constants.isProd) {
    const message = typeof data === 'string' ? data : JSON.stringify(data);

    try {
      datadogLogs.logger.info(message, {
        arguments: props,
      });
    } catch (e) {
      console.error(e);
    }
  }

  nativeLog(...props);
};

type ErrorOptions = {
  skipSentry?: boolean;
  sentry?: CaptureContext;
  [key: string]: any;
};

const error = (
  error: Error,
  { skipSentry, sentry: sentryContext, ...rest }: ErrorOptions = {},
) => {
  if (isRunningOnServerSide()) {
    console.warn('Trying to use a browser logger for a client side error', {
      error,
    });
    return;
  }

  if (
    !skipSentry &&
    !shouldSkipErrorInSentry(error) &&
    Constants.isProdNotStaging
  ) {
    Sentry.captureException(error, sentryContext);
  }

  const message = typeof error === 'string' ? error : error?.message;

  try {
    datadogLogs.logger.error(message, {
      arguments: rest,
      error,
    });
  } catch (e) {
    console.error(e, rest);
  }

  try {
    datadogRum.addError(error, rest);
  } catch (e) {
    console.error(e);
  }

  if (!Constants.isProd) {
    loggerFunction.error(error, rest);
  }

  nativeLog('error:', error.message);
};

export const Logger: ILogger = {
  debug: console.debug,
  trace: console.trace,
  info,
  warn: console.warn,
  error,
  getChildLogger: () => {
    Logger.warn('getChildLogger is not supported in the client yet.');

    return Logger;
  },
};

export * from './logger.shared';

export default Logger;
