import isEqual from 'lodash/isEqual';
import {
  forwardRef,
  Fragment,
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import useControlled from './hooks/useControlled';
import useEventCallback from './hooks/useEventCallback';
import useForkRef from './hooks/useForkRef';
import useIsFocusVisible from './hooks/useIsFocusVisible';
import {
  SliderMark,
  SliderMarkLabel,
  SliderRail,
  SliderRoot,
  SliderThumb,
  SliderTrack,
  SliderValueLabel,
} from './Slider.components';
import * as util from './Slider.utils';

const axisProps = {
  horizontal: {
    offset: (percent: number) => ({ left: `${percent}%` }),
    leap: (percent: number) => ({ width: `${percent}%` }),
  },
  'horizontal-reverse': {
    offset: (percent: number) => ({ right: `${percent}%` }),
    leap: (percent: number) => ({ width: `${percent}%` }),
  },
  vertical: {
    offset: (percent: number) => ({ bottom: `${percent}%` }),
    leap: (percent: number) => ({ height: `${percent}%` }),
  },
};

const identity = (x: any) => x;

type Mark = {
  value: number;
  label?: string;
};

export type SliderValue = number | number[];

// Need to get rid of withStyles for generics to work
export interface SliderProps<TValue extends SliderValue = SliderValue> {
  /**
   * The label of the slider.
   * Warning: You need to use the `getAriaLabel` prop instead of `aria-label` when using a range slider.
   */
  'aria-label'?: string;
  /**
   * The id of the element containing a label for the slider.
   */
  'aria-labelledby'?: string;
  /**
   * A string value that provides a user-friendly name for the current value of the slider.
   * Warning: You need to use the `getAriaValueText` prop instead of `aria-valuetext` when using a range slider.
   */
  'aria-valuetext'?: string;
  /**
   * @ignore
   */
  className?: string;
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: 'primary' | 'secondary';
  /**
   * The default element value. Use when the component is not controlled.
   */
  defaultValue?: TValue;
  /**
   * If `true`, the slider will be disabled.
   */
  disabled?: boolean;
  /**
   * Accepts a function which returns a string value that provides a user-friendly name for the thumb labels of the slider.
   *
   * @param {number} index The thumb label's index to format.
   * @returns {string}
   */
  getAriaLabel?: (index: number) => string;
  /**
   * Accepts a function which returns a string value that provides a user-friendly name for the current value of the slider.
   *
   * @param {number} value The thumb label's value to format.
   * @param {number} index The thumb label's index to format.
   * @returns {string}
   */
  getAriaValueText?: (value: number, index: number) => string;
  /**
   * Marks indicate predetermined values to which the user can move the slider.
   * If `true` the marks will be spaced according the value of the `step` prop.
   * If an array, it should contain objects with `value` and an optional `label` keys.
   */
  marks?: boolean | Mark[];
  /**
   * If you pass `valueSet` prop, the `step`, `min` & `max` are ignored & controlled by the component itself.
   * The `defaultValue` still works & passing `value` still makes the component controlled.
   * The values in `defaultValue` & `value` props are translated to the closest one in the `valueSet`.
   */
  valueSet?: number[];
  /**
   * The maximum allowed value of the slider.
   * Should not be equal to min.
   */
  max?: number;
  /**
   * The minimum allowed value of the slider.
   * Should not be equal to max.
   */
  min?: number;
  /**
   * Name attribute of the hidden `input` element.
   */
  name?: string;
  /**
   * Callback function that is fired when the slider's value changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number | number[]} value The new value.
   */
  onChange?: (e: SyntheticEvent<any> | Event, v: TValue) => void;
  /**
   * Callback function that is fired when the `mouseup` is triggered.
   *
   * @param {object} event The event source of the callback.
   * @param {number | number[]} value The new value.
   */
  onChangeCommitted?: (e: SyntheticEvent<any>, v: TValue) => void;
  /**
   * @ignore
   */
  onMouseDown?: (e: any) => void;
  /**
   * The slider orientation.
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * A transformation function, to change the scale of the slider.
   */
  scale?: () => TValue;
  /**
   * The granularity with which the slider can step through values. (A "discrete" slider.)
   * The `min` prop serves as the origin for the valid values.
   * We recommend (max - min) to be evenly divisible by the step.
   *
   * When step is `null`, the thumb can only be slid onto marks provided with the `marks` prop.
   */
  step?: number;
  /**
   * The track presentation:
   *
   * - `normal` the track will render a bar representing the slider value.
   * - `inverted` the track will render a bar representing the remaining slider value.
   * - `false` the track will render without a bar.
   */
  track?: 'normal' | false | 'inverted';
  /**
   * The value of the slider.
   * For ranged sliders, provide an array with two values.
   */
  value?: TValue;
  /**
   * Controls when the value label is displayed:
   *
   * - `auto` the value label will display when the thumb is hovered or focused.
   * - `on` will display persistently.
   * - `off` will never display.
   */
  valueLabelMode?: 'on' | 'auto' | 'off';
  /**
   * The format function the value label's value.
   *
   * When a function is provided, it should have the following signature:
   *
   * - {number} value The value label's value to format
   * - {number} index The value label's index to format
   */
  valueLabelFormat?: string | ((value: TValue, index: number) => string);
}

export const Slider = forwardRef<HTMLSpanElement, SliderProps>(function Slider(
  props,
  ref,
) {
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    'aria-valuetext': ariaValuetext,
    className,
    disabled = false,
    getAriaLabel,
    getAriaValueText,
    marks: marksProp = false,
    name,
    onChange,
    onChangeCommitted,
    onMouseDown,
    orientation = 'horizontal',
    scale = identity,
    track = 'normal',
    valueLabelMode = 'off',
    valueLabelFormat = identity,
    valueSet,
    ...rest
  } = props;
  let {
    max = 100,
    min = 0,
    step = 1,
    value: valueProp,
    defaultValue,
    // eslint-disable-next-line prefer-const
    ...other
  } = rest;

  if (valueSet) {
    step = 1;
    min = 0;
    max = valueSet.length - 1;

    // Supporting range of 2 only for now
    if (
      !defaultValue ||
      typeof defaultValue === 'number' ||
      defaultValue?.length < 2
    ) {
      defaultValue = [0, valueSet.length - 1];
    }

    const isControlled = valueProp !== undefined;

    // Supporting range of 2 only for now.
    // TODO: in future derive the num of values from initial valueProp/defaultValue length
    if (Array.isArray(valueProp) && valueProp?.length === 2) {
      const [vmin, vmax] = valueProp;
      valueProp = [
        util.findClosest(valueSet, vmin) ?? min,
        util.findClosest(valueSet, vmax) ?? max,
      ];
    } else if (isControlled) {
      valueProp = [min, max];
    }
  }

  const touchId = useRef<number | undefined>();
  // We can't use the :active browser pseudo-classes.
  // - The active state isn't triggered when clicking on the rail.
  // - The active state isn't transfered when inversing a range slider.
  const [active, setActive] = useState(-1);
  const [open, setOpen] = useState(-1);

  const [valueDerived, setValueState] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: 'Slider',
  });

  const isRange = Array.isArray(valueDerived);
  let values: number[] = Array.isArray(valueDerived)
    ? valueDerived.slice().sort(util.asc)
    : [valueDerived];
  values = values.map((value) => util.clamp(value, min, max));
  const marks: Mark[] =
    marksProp === true
      ? step !== null
        ? [...Array(Math.floor((max - min) / step) + 1)].map((_, index) => ({
            value: min + step * index,
          }))
        : []
      : marksProp || [];

  const {
    isFocusVisible,
    onBlurVisible,
    ref: focusVisibleRef,
  } = useIsFocusVisible();
  const [focusVisible, setFocusVisible] = useState(-1);

  const sliderRef = useRef<HTMLSpanElement>();
  const handleFocusRef = useForkRef<HTMLSpanElement>(
    focusVisibleRef,
    sliderRef,
  );
  const handleRef = useForkRef<HTMLSpanElement>(ref, handleFocusRef);

  const handleFocus = useEventCallback((event) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    if (isFocusVisible(event)) {
      setFocusVisible(index);
    }
    setOpen(index);
  });
  const handleBlur = useEventCallback(() => {
    if (focusVisible !== -1) {
      setFocusVisible(-1);
      onBlurVisible();
    }
    setOpen(-1);
  });
  const handleMouseOver = useEventCallback((event) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    setOpen(index);
  });
  const handleMouseLeave = useEventCallback(() => {
    setOpen(-1);
  });

  const isRtl = false; // theme.direction === 'rtl';

  const handleChange: typeof onChange = valueSet
    ? (event, newValue) => {
        if (!Array.isArray(newValue) || newValue.length < 2) return;
        const [min, max] = newValue;
        // TODO: should be only called once the value has changed!
        onChange(event, [
          valueSet[min] ?? valueSet[0],
          valueSet[max] ?? valueSet[valueSet.length - 1],
        ]);
      }
    : onChange;

  const handleKeyDown = useEventCallback((event: KeyboardEvent) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    const value = values[index];
    const tenPercents = (max - min) / 10;
    const marksValues = marks.map((mark) => mark.value);
    const marksIndex = marksValues.indexOf(value);
    let newNumberValue: number;
    const increaseKey = isRtl ? 'ArrowLeft' : 'ArrowRight';
    const decreaseKey = isRtl ? 'ArrowRight' : 'ArrowLeft';

    switch (event.key) {
      case 'Home':
        newNumberValue = min;
        break;
      case 'End':
        newNumberValue = max;
        break;
      case 'PageUp':
        if (step) {
          newNumberValue = value + tenPercents;
        }
        break;
      case 'PageDown':
        if (step) {
          newNumberValue = value - tenPercents;
        }
        break;
      case increaseKey:
      case 'ArrowUp':
        if (step) {
          newNumberValue = value + step;
        } else {
          newNumberValue =
            marksValues[marksIndex + 1] || marksValues[marksValues.length - 1];
        }
        break;
      case decreaseKey:
      case 'ArrowDown':
        if (step) {
          newNumberValue = value - step;
        } else {
          newNumberValue = marksValues[marksIndex - 1] || marksValues[0];
        }
        break;
      default:
        return;
    }

    // Prevent scroll of the page
    event.preventDefault();

    if (step) {
      newNumberValue = util.roundValueToStep(newNumberValue, step, min);
    }

    newNumberValue = util.clamp(newNumberValue, min, max);

    let newRangeValue: number[];
    // TODO: if (isRange) {... when TS allows
    if (Array.isArray(valueDerived)) {
      const previousValue = newNumberValue;
      newRangeValue = util
        .setValueIndex({
          values,
          source: valueDerived,
          newValue: newNumberValue,
          index,
        })
        .sort(util.asc);
      util.focusThumb({
        sliderRef,
        activeIndex: newRangeValue.indexOf(previousValue),
      });
    }

    const finalValue: SliderValue = newRangeValue ?? newNumberValue;

    setValueState(finalValue);
    setFocusVisible(index);

    if (handleChange) {
      handleChange(event, finalValue);
    }
    if (onChangeCommitted) {
      onChangeCommitted(event, finalValue);
    }
  });

  const previousIndex = useRef<number>();
  let axis = orientation;
  if (isRtl && orientation === 'horizontal') {
    axis += '-reverse';
  }

  const getFingerNewValue = ({
    finger,
    move = false,
    values: values2,
    source,
  }) => {
    const { current: slider } = sliderRef;
    const { width, height, bottom, left } = slider.getBoundingClientRect();
    let percent;

    if (axis.indexOf('vertical') === 0) {
      percent = (bottom - finger.y) / height;
    } else {
      percent = (finger.x - left) / width;
    }

    if (axis.indexOf('-reverse') !== -1) {
      percent = 1 - percent;
    }

    let newNumberValue: number = util.percentToValue(percent, min, max);
    if (step) {
      newNumberValue = util.roundValueToStep(newNumberValue, step, min);
    } else {
      const marksValues = marks.map((mark) => mark.value);
      const closestIndex = util.findClosest(marksValues, newNumberValue);
      newNumberValue = marksValues[closestIndex];
    }

    newNumberValue = util.clamp(newNumberValue, min, max);
    let activeIndex = 0;

    let newRangeValue: number[];
    if (isRange) {
      if (!move) {
        activeIndex = util.findClosest(values2, newNumberValue);
      } else {
        activeIndex = previousIndex.current;
      }

      const previousValue = newNumberValue;
      newRangeValue = util
        .setValueIndex({
          values: values2,
          source,
          newValue: newNumberValue,
          index: activeIndex,
        })
        .sort(util.asc);
      activeIndex = newRangeValue.indexOf(previousValue);
      previousIndex.current = activeIndex;
    }

    const finalValue = newRangeValue ?? newNumberValue;

    return { newValue: finalValue, activeIndex };
  };

  const handleTouchMove = useEventCallback((event) => {
    const finger = util.trackFinger(event, touchId);

    if (!finger) {
      return;
    }

    const { newValue, activeIndex } = getFingerNewValue({
      finger,
      move: true,
      values,
      source: valueDerived,
    });

    if (isEqual(newValue, valueDerived)) return;

    util.focusThumb({ sliderRef, activeIndex, setActive });
    setValueState(newValue);

    if (handleChange) {
      handleChange(event, newValue);
    }
  });

  const handleTouchEnd = useEventCallback((event) => {
    const finger = util.trackFinger(event, touchId);

    if (!finger) {
      return;
    }

    const { newValue } = getFingerNewValue({
      finger,
      values,
      source: valueDerived,
    });

    setActive(-1);
    if (event.type === 'touchend') {
      setOpen(-1);
    }

    if (onChangeCommitted) {
      onChangeCommitted(event, newValue);
    }

    touchId.current = undefined;

    const doc = util.ownerDocument(sliderRef.current);
    doc.removeEventListener('mousemove', handleTouchMove);
    doc.removeEventListener('mouseup', handleTouchEnd);
    doc.removeEventListener('touchmove', handleTouchMove);
    doc.removeEventListener('touchend', handleTouchEnd);
  });

  const handleTouchStart = useEventCallback((event: TouchEvent) => {
    // Workaround as Safari has partial support for touchAction: 'none'.
    event.preventDefault();
    const touch = event.changedTouches[0];
    if (touch != null) {
      // A number that uniquely identifies the current finger in the touch session.
      touchId.current = touch.identifier;
    }
    const finger = util.trackFinger(event, touchId);
    const { newValue, activeIndex } = getFingerNewValue({
      finger,
      values,
      source: valueDerived,
    });
    util.focusThumb({ sliderRef, activeIndex, setActive });

    setValueState(newValue);

    if (onChange) {
      handleChange(event, newValue);
    }

    const doc = util.ownerDocument(sliderRef.current);
    doc.addEventListener('touchmove', handleTouchMove);
    doc.addEventListener('touchend', handleTouchEnd);
  });

  useEffect(() => {
    const { current: slider } = sliderRef;
    slider.addEventListener('touchstart', handleTouchStart);
    const doc = util.ownerDocument(slider);

    return () => {
      slider.removeEventListener('touchstart', handleTouchStart);
      doc.removeEventListener('mousemove', handleTouchMove);
      doc.removeEventListener('mouseup', handleTouchEnd);
      doc.removeEventListener('touchmove', handleTouchMove);
      doc.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchEnd, handleTouchMove, handleTouchStart]);

  const handleMouseDown = useEventCallback((event) => {
    if (onMouseDown) {
      onMouseDown(event);
    }

    event.preventDefault();
    const finger = util.trackFinger(event, touchId);
    const { newValue, activeIndex } = getFingerNewValue({
      finger,
      values,
      source: valueDerived,
    });
    util.focusThumb({ sliderRef, activeIndex, setActive });

    setValueState(newValue);

    if (onChange) {
      handleChange(event, newValue);
    }

    const doc = util.ownerDocument(sliderRef.current);
    doc.addEventListener('mousemove', handleTouchMove);
    doc.addEventListener('mouseup', handleTouchEnd);
  });

  const trackOffset = util.valueToPercent(isRange ? values[0] : min, min, max);
  const trackLeap =
    util.valueToPercent(values[values.length - 1], min, max) - trackOffset;
  const trackStyle = {
    ...axisProps[axis].offset(trackOffset),
    ...axisProps[axis].leap(trackLeap),
  };

  const isVertical = orientation === 'vertical';

  return (
    <SliderRoot
      ref={handleRef}
      isDisabled={disabled}
      isMarked={marks.length > 0 && marks.some((mark) => mark.label)}
      isVertical={isVertical}
      onMouseDown={handleMouseDown}
      {...other}
    >
      <SliderRail isVertical={isVertical} />
      {track !== false && (
        <SliderTrack isVertical={isVertical} style={trackStyle} />
      )}
      <input value={values.join(',')} name={name} type="hidden" />
      {marks.map((mark, index) => {
        const percent = util.valueToPercent(mark.value, min, max);
        const style = axisProps[axis].offset(percent);

        let markActive;
        if (track === false) {
          markActive = values.indexOf(mark.value) !== -1;
        } else {
          markActive =
            (track === 'normal' &&
              (isRange
                ? mark.value >= values[0] &&
                  mark.value <= values[values.length - 1]
                : mark.value <= values[0])) ||
            (track === 'inverted' &&
              (isRange
                ? mark.value <= values[0] ||
                  mark.value >= values[values.length - 1]
                : mark.value >= values[0]));
        }

        return (
          <Fragment key={mark.value}>
            <SliderMark
              style={style}
              data-index={index}
              isActive={markActive}
            />
            {mark.label != null ? (
              <SliderMarkLabel
                aria-hidden
                data-index={index}
                style={style}
                isActive={markActive}
                isVertical={isVertical}
              >
                {mark.label}
              </SliderMarkLabel>
            ) : null}
          </Fragment>
        );
      })}
      {values.map((value, index) => {
        const percent = util.valueToPercent(value, min, max);
        const style = axisProps[axis].offset(percent);

        return (
          <SliderValueLabel
            key={index}
            valueLabelMode={valueLabelMode}
            value={
              typeof valueLabelFormat === 'function'
                ? valueLabelFormat(scale(value), index)
                : valueLabelFormat
            }
            // index={index}
            isOpen={
              open === index || active === index || valueLabelMode === 'on'
            }
            isDisabled={disabled}
          >
            <SliderThumb
              isVertical={isVertical}
              isActive={active === index}
              isDisabled={disabled}
              isFocusVisible={focusVisible === index}
              tabIndex={disabled ? null : 0}
              role="slider"
              style={style}
              data-index={index}
              aria-label={getAriaLabel ? getAriaLabel(index) : ariaLabel}
              aria-labelledby={ariaLabelledby}
              aria-orientation={orientation}
              aria-valuemax={scale(max)}
              aria-valuemin={scale(min)}
              aria-valuenow={scale(value)}
              aria-valuetext={
                getAriaValueText
                  ? getAriaValueText(scale(value), index)
                  : ariaValuetext
              }
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
            />
          </SliderValueLabel>
        );
      })}
    </SliderRoot>
  );
});
