import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

import { Constants } from '@flick-tech/shared-common';

import { useNewFlagsHandler } from '../useNewFlagsHandler';

const state = atom({
  key: 'ld/has-initialized-events',
  default: false,
});

export function useInitializeFlagsHandler() {
  const ldClient = useLDClient();

  const handleNewFlags = useNewFlagsHandler();

  const [hasInitializedEvents, setHasInitializedEvents] = useRecoilState(state);

  return useCallback(async () => {
    if (
      hasInitializedEvents ||
      !ldClient ||
      Constants.isTest ||
      Constants.isCypress
    ) {
      return;
    }

    if (!ldClient?.allFlags) {
      throw new Error('Launch dakrly not initialized yet.');
    }

    setHasInitializedEvents(true);

    // On subsequent flag changes, handle the flags
    ldClient.on('change', () => {
      handleNewFlags(ldClient.allFlags());
    });

    // On first load, handle the flags
    await handleNewFlags(ldClient.allFlags());
  }, [handleNewFlags, hasInitializedEvents, ldClient, setHasInitializedEvents]);
}
