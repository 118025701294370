import InputTheme, { InputThemeProps } from './InputTheme';

const TextareaTheme: InputThemeProps = {
  ...InputTheme,
  baseStyle: {
    ...InputTheme.baseStyle,
    paddingY: '8px',
    minHeight: '80px',
    lineHeight: 'short',
  },
};

export default TextareaTheme;
