import { Property } from 'csstype';

import { ThemedProperty } from '../types';
import { Config, Length, Prop } from '../utils';

/**
 * The parser configuration for common background properties
 */
export const backgroundConfig: Config = {
  bg: {
    property: 'background',
    scale: 'colors',
  },
  bgColor: {
    property: 'backgroundColor',
    scale: 'colors',
  },
  background: {
    property: 'background',
    scale: 'colors',
  },
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
  },
  backgroundImage: true,
  backgroundSize: true,
  backgroundPosition: true,
  backgroundRepeat: true,
  backgroundAttachment: true,
  bgImage: {
    property: 'backgroundImage',
  },
  bgSize: {
    property: 'backgroundSize',
  },
  bgPosition: {
    property: 'backgroundPosition',
  },
  bgRepeat: {
    property: 'backgroundRepeat',
  },
  bgAttachment: {
    property: 'backgroundAttachment',
  },
};

/**
 * The prop types for background properties listed above
 */
export interface BackgroundProps {
  /**
   * The CSS `background` property
   */
  bg?: Prop<Property.Background<Length>>;
  /**
   * The CSS `background` property
   */
  background?: Prop<Property.Background<Length>>;
  /**
   * The CSS `background-color` property
   */
  bgColor?: Prop<ThemedProperty.BackgroundColor>;
  /**
   * The CSS `background-color` property
   */
  backgroundColor?: Prop<ThemedProperty.BackgroundColor>;
  /**
   * The CSS `background-image` property
   */
  backgroundImage?: Prop<Property.BackgroundImage>;
  /**
   * The CSS `background-size` property
   */
  backgroundSize?: Prop<Property.BackgroundSize<Length>>;
  /**
   * The CSS `background-position` property
   */
  backgroundPosition?: Prop<Property.BackgroundPosition<Length>>;
  /**
   * The CSS `background-image` property
   */
  bgImage?: Prop<Property.BackgroundImage>;
  /**
   * The CSS `background-repeat` property
   */
  bgRepeat?: Prop<Property.BackgroundRepeat>;
  /**
   * The CSS `background-repeat` property
   */
  backgroundRepeat?: Prop<Property.BackgroundRepeat>;
  /**
   * The CSS `background-size` property
   */
  bgSize?: Prop<Property.BackgroundSize<Length>>;
  /**
   * The CSS `background-attachment` property
   */
  bgAttachment?: Prop<Property.BackgroundAttachment>;
  /**
   * The CSS `background-attachment` property
   */
  backgroundAttachment?: Prop<Property.BackgroundAttachment>;
  /**
   * The CSS `background-position` property
   */
  bgPosition?: Prop<Property.BackgroundPosition<Length>>;
}
