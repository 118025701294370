import { mapValues } from 'lodash';
import capitalize from 'lodash/capitalize';
import { FunctionComponent, useMemo } from 'react';

import {
  makeRechartTooltip,
  TooltipContentProps,
} from './components/RechartTooltip';
import { barChartPalette } from './const';
import { TextMap, TextTransform, ValueTransforms } from './types';

const minIndexSlot = 5;
const maxIndexSlot = 30;

const getDataInfo = (data: object[], keys: string[]) => {
  if (!data || data.length === 0) {
    return {
      sumPerKey: {},
      isDataMissing: true,
      minValue: 0,
      maxValue: 0,
      valuesStartIndex: 0,
      valuesEndIndex: 0,
    };
  }
  let maxValue = -Infinity;
  let minValue = 0;
  let isDataMissing = true;
  let valuesStartIndex = 0;
  let valuesEndIndex = 0;

  // Sum all data for each relevant key
  const sumPerKey = (data?.reduce(
    (acc, dataItem, i) => {
      let isRowEmpty = true;

      keys.forEach((key) => {
        const value = dataItem && dataItem[key];
        const isValueMissing = value === null || value === undefined;
        if (!isValueMissing) {
          isDataMissing = false;
        }
        const isValueEmpty = isValueMissing || value === 0;
        if (!isValueEmpty) {
          isRowEmpty = false;
        }

        if (value != null) {
          acc[key] += value;
        }
        if (value > maxValue) {
          maxValue = value;
        }
        if (value < minValue) {
          minValue = value;
        }
      });

      if (isRowEmpty) {
        if (valuesStartIndex === i) {
          valuesStartIndex++;
        }
      } else {
        valuesEndIndex = i;
      }
      return acc;
    },
    keys?.reduce((acc, key) => ({ ...acc, [key]: 0 }), {}),
  ) || {}) as Record<string, number>;
  // Happens when data is empty all the way through
  if (valuesEndIndex < valuesStartIndex) {
    valuesEndIndex = valuesStartIndex;
  }
  const maxIndex = data.length - 1;
  valuesStartIndex = Math.max(valuesStartIndex - 1, 0);
  valuesEndIndex = Math.min(valuesEndIndex + 1, maxIndex);

  const indicesDiff = valuesEndIndex - valuesStartIndex;
  // We don't want the initial brush to be smaller than 7 days
  if (indicesDiff < minIndexSlot) {
    const indicesOffset = Math.ceil(minIndexSlot - indicesDiff);
    valuesStartIndex -= indicesOffset;
    valuesEndIndex += indicesOffset;

    // Clamp indices to not overflow the brush
    if (valuesStartIndex < 0) {
      valuesEndIndex += Math.abs(valuesStartIndex);
    }
    if (valuesEndIndex > maxIndex) {
      valuesStartIndex -= valuesEndIndex - maxIndex;
    }
  } else if (indicesDiff > maxIndexSlot) {
    // Clamp down indices to increase page performance
    valuesStartIndex = valuesEndIndex - maxIndexSlot;
  }

  return {
    sumPerKey,
    isDataMissing,
    minValue,
    maxValue,
    valuesStartIndex: Math.max(0, valuesStartIndex),
    valuesEndIndex: Math.min(maxIndex, valuesEndIndex),
  };
};

interface ProcessDataParams {
  data: object[];
  rawData: object[];
  fallbackData: object[];
  keys: string[];
  fillGaps?: boolean | string[];
  removeFlatKeys?: boolean;
  negativeKey?: string;
}

export const processData = ({
  data,
  rawData,
  keys,
  fallbackData = [],
  fillGaps,
  removeFlatKeys,
  negativeKey,
}: ProcessDataParams) => {
  let parsedData = data;
  let info = getDataInfo(rawData, keys);

  const { sumPerKey, isDataMissing } = info;

  let parsedKeys = !removeFlatKeys
    ? keys
    : Object.entries(sumPerKey)
        // TODO: fix it by filtering isKeyNullMap or sth.
        // This was to remove a key that is 0 all the way through
        // but it would also remove null all the way which is not desired
        .filter(([_, value]) => value !== 0)
        .map(([key]) => key);

  // This assumes that fallbackData works with given keys
  if (isDataMissing) {
    // Data was present but completely empty, using fallback and reverting the empty keys
    // Eg. data would look like that [{date: '09 Jan', followersGained: null, ...: 0, ...}]
    parsedData = fallbackData;
    parsedKeys = keys;
    info = getDataInfo(fallbackData, keys);
    info.isDataMissing = true;
  } else if (fillGaps) {
    const averages = mapValues(sumPerKey, (value) => {
      // If value / length is even closest to zero positive / negative number (eg. 0.1 / -0.1)
      // then it will be ceiled / floored to 1 / -1 respectively to appear in the bar chart
      // If key's sum for the period is equal 0 is removed anyway, so the average should never result in 0
      return Math[value > 0 ? 'ceil' : 'floor'](value / (data.length - 1));
    });
    const gapsKeys = Array.isArray(fillGaps) ? fillGaps : keys;
    parsedData = data.map((row, i) => {
      const previousRow = data[i - 1];
      const nextRow = data[i + 1];
      row = { ...row };
      // TODO(chris): put _empty into types properly
      // @ts-ignore
      row._empty = [];
      gapsKeys.forEach((key) => {
        const value = row[key];
        if (value === null) {
          // @ts-ignore
          row._empty.push(key);
          // Here is where magic happens. Fill the empty value with an average of previous & next
          // value for that key. In case of absence of either - use average of all non null values
          // Either way we should get something that will not look out of place in the graph
          const surroundingAverage =
            ((previousRow?.[key] ?? averages[key]) +
              (nextRow?.[key] ?? averages[key])) /
            2;
          let newValue =
            Math[surroundingAverage > 0 ? 'ceil' : 'floor'](surroundingAverage);
          // This is very rare case where we have a stacked chart but there is no way to tell
          // if the 'fake' missing data bar is supposed to be positve or negative
          if (newValue === 0 && sumPerKey[key] !== 0) {
            if (key === negativeKey) {
              newValue = -1;
            } else {
              newValue = 1;
            }
          }
          row[key] = newValue;
        }
      });
      return row;
    });
  }

  return {
    parsedData,
    parsedKeys,
    ...info,
  };
};

export interface UseChartParams<T extends object> {
  // used to compute legend
  rawData: T[];
  data: T[];
  fallbackData?: T[];
  textMap?: TextMap<keyof T>;
  valueTransforms?: ValueTransforms<keyof T>;
  tooltipPostfix?: TextTransform;
  tooltipContent?: FunctionComponent<TooltipContentProps<T>>;
  colors?: string[];
  keys: Exclude<keyof T, number | symbol>[];
  negativeKey?: keyof T & string;
  fillGaps?: boolean | (keyof T & string)[];
}

export const useChart = <T extends Record<string, any>>({
  data,
  rawData,
  fallbackData,
  keys,
  fillGaps = false,
  negativeKey,
  colors = barChartPalette,
  textMap = {},
  valueTransforms = {},
  tooltipContent,
  tooltipPostfix,
}: UseChartParams<T>) => {
  return useMemo(() => {
    const { sumPerKey, ...rest } = processData({
      data,
      rawData,
      keys,
      fallbackData,
      fillGaps,
      negativeKey,
    });

    const stats = keys.map((key, i) => ({
      key,
      color: colors[i],
      text: textMap[key] ?? capitalize(key),
      valueTransform: valueTransforms[key],
    }));

    const tooltipProps: TooltipContentProps<T> = {
      content:
        tooltipContent ||
        makeRechartTooltip(stats, {
          postfix: tooltipPostfix,
        }),
    };

    return {
      legendProps: { stats, values: sumPerKey },
      tooltipProps,
      ...rest,
    };
  }, [
    data,
    keys,
    fallbackData,
    fillGaps,
    negativeKey,
    tooltipContent,
    tooltipPostfix,
    colors,
    textMap,
    valueTransforms,
    rawData,
  ]);
};
