const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail = (value: string) => emailRegex.test(value.toLowerCase())

export class FieldValidators {
  static required = (value) => (value ? undefined : 'Required')
  static arrayRequired = (value: any[]) => (value?.length > 0 ? undefined : 'Required')

  static isEmail = (value) => (isValidEmail(value) ? undefined : 'Valid email required')

  static password(value: string) {
    if (value.length < 8) {
      return 'Your password must be at least 8 characters'
    }

    if (value.search(/[a-z]/i) < 0) {
      return 'Your password must contain at least one letter.'
    }

    if (value.search(/[A-Z]/i) < 0) {
      return 'Your password must contain at least one upper case letter.'
    }

    if (value.search(/[0-9]/) < 0) {
      return 'Your password must contain at least one digit.'
    }

    return undefined
  }

  static minLength(length: number) {
    return (value: string | undefined) => {
      if (!value) return 'Required'

      if (value.length <= length) return `Must be greater than ${length} characters`

      return undefined
    }
  }

  static maxLength(length: number) {
    return (value: string | undefined) => {
      if (!value) return 'Required'

      if (value.length >= length) return `Must be less than ${length} characters`

      return undefined
    }
  }

  static isFullName = (value = '') => (value.trim().split(' ').length >= 2 ? undefined : 'Please enter your full name')

  static composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined)
}
