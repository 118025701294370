import { Property } from 'csstype';

import { Config, Length, Prop } from '../utils';

export const othersConfig: Config = {
  animation: true,
  appearance: true,
  transform: true,
  transformOrigin: true,
  visibility: true,
  userSelect: true,
  pointerEvents: true,
  cursor: true,
  resize: true,
  transition: true,
  objectFit: true,
  objectPosition: true,
  float: true,
  willChange: true,
  listStyleType: true,
  listStylePosition: true,
  listStyleImage: true,
};

export interface OtherProps {
  /**
   * The CSS `animation` property
   */
  animation?: Prop<Property.Animation>;
  /**
   * The CSS `appearance` property
   */
  appearance?: Prop<Property.Appearance>;
  /**
   * The CSS `transform` property
   */
  transform?: Prop<Property.Transform>;
  /**
   * The CSS `transform-origin` property
   */
  transformOrigin?: Prop<Property.TransformOrigin<Length>>;
  /**
   * The CSS `visibility` property
   */
  visibility?: Prop<Property.Visibility>;
  /**
   * The CSS `user-select` property
   */
  userSelect?: Prop<Property.UserSelect>;
  /**
   * The CSS `pointer-events` property
   */
  pointerEvents?: Prop<Property.PointerEvents>;
  /**
   * The CSS `cursor` property
   */
  cursor?: Prop<Property.Cursor>;
  /**
   * The CSS `resize` property
   */
  resize?: Prop<Property.Resize>;
  /**
   * The CSS `transition` property
   */
  transition?: Prop<Property.Transition>;
  /**
   * The CSS `object-fit` property
   */
  objectFit?: Prop<Property.ObjectFit>;
  /**
   * The CSS `object-psition` property
   */
  objectPosition?: Prop<Property.ObjectPosition<Length>>;
  /**
   * The CSS `float` property
   */
  float?: Prop<Property.Float>;
  /**
   * The CSS `will-change` property
   */
  willChange?: Prop<Property.WillChange>;
  /**
   * The CSS `list-style-type` property
   */
  listStyleType?: Prop<Property.ListStyleType>;
  /**
   * The CSS `list-style-position` property
   */
  listStylePosition?: Prop<Property.ListStylePosition>;
  /**
   * The CSS `list-style-image` property
   */
  listStyleImage?: Prop<Property.ListStyleImage>;
}
