import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useIsAdminOrOwnerOfActiveWorkspace } from '../useGetUser';

import { useGetBillingQuery } from './useGetBillingQuery.generated';

export type { IBillingFieldsFragment } from './useGetBillingQuery.generated';

const variables = {};

export function useGetBilling() {
  const { data, isLoading: loading } = useGetBillingQuery(variables);

  return {
    billing: data?.me?.billing,
    loading,
  };
}

export function useRefetchBiling() {
  const client = useQueryClient();

  return useCallback(
    () =>
      client.invalidateQueries({
        queryKey: useGetBillingQuery.getKey(variables),
      }),
    [client],
  );
}

export function useCanManageBilling() {
  const isAdminOrOwner = useIsAdminOrOwnerOfActiveWorkspace();

  return isAdminOrOwner;
}
