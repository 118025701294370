import useEventListener from '@use-it/event-listener';
import { useEffect, useRef, useState } from 'react';

import { useSafeLayoutEffect } from '../useSafeLayoutEffect';

import { createGlobalState } from './createGlobalState';

type Storage<T> = {
  get: (key: string, initialState: T) => T;
  set: (key: string, value: T) => void;
};

const usePersistedState = <T>(
  initialState: T,
  key: string,
  { get, set }: Storage<T>,
) => {
  const globalState = useRef<ReturnType<typeof createGlobalState> | null>(null);
  const [state, setState] = useState(() => get(key, initialState));

  // subscribe to `storage` change events
  useEventListener(
    'storage',
    // @ts-ignore
    ({ key: k, newValue }: { key: string; newValue: string }) => {
      if (k === key) {
        try {
          const newState = JSON.parse(newValue);
          if (state !== newState) {
            setState(newState);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
  );

  // only called on mount
  useSafeLayoutEffect(() => {
    // register a listener that calls `setState` when another instance emits
    globalState.current = createGlobalState(key, setState, initialState);

    return () => {
      globalState.current?.deregister();
    };
  }, []);

  // Only persist to storage if state changes.
  useEffect(() => {
    // persist to localStorage
    set(key, state);

    // inform all of the other instances in this tab
    globalState.current?.emit(state);
  }, [key, set, state]);

  return [state, setState] as const;
};

export default usePersistedState;
