import { memo, useCallback } from 'react';

import { IWorkspace } from '@flick-tech/shared-types';
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useMutationHook,
} from '@flick-tech/ui';

import { useSetActiveWorkspaceMutation } from './hooks/useSetActiveWorkspace.generated';
import { WorkspaceWithImage } from './types';
import { WorkspaceCard } from './WorkspaceCard';

interface WorkspaceListProps {
  onSelect: (workspace: Pick<IWorkspace, 'id'>) => Promise<boolean>;
  workspaces: WorkspaceWithImage[];
  activeWorkspaceId: string;
}

export const LIST_WIDTH = 304;

export const WorkspaceList = memo(function WorkspaceList({
  onSelect,
  workspaces,
  activeWorkspaceId,
}: WorkspaceListProps) {
  const { mutateAsync } = useSetActiveWorkspaceMutation();

  const { handler } = useMutationHook({
    func: async (workspaceId: string) => {
      const result = await mutateAsync({ workspaceId });

      if (result) {
        if (onSelect) {
          await onSelect(result.setActiveWorkspace);
        }
      }
    },
  });

  const setActiveWorkspace = useCallback(
    (workspaceId: string) => {
      return handler(workspaceId);
    },
    [handler],
  );

  const isOwnerOfAWorkspace = workspaces.find(
    (workspace) => workspace.myRole === 'Owner',
  );
  const canCreateWorkspace = !isOwnerOfAWorkspace;

  return (
    <Stack
      p="12px"
      maxH="443px"
      minW={LIST_WIDTH}
      overflowY="auto"
      data-testid="layout--workspace-switcher--list"
    >
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Heading
          paddingY="0px"
          paddingX="0px"
          variant="h4"
          fontSize="18px"
          fontWeight="medium"
        >
          Workspaces
        </Heading>
        <Text fontSize="18px" fontWeight="medium" as="div" color="text.lighter">
          {workspaces.length}
        </Text>
      </Flex>
      {workspaces.map((workspaceItem) => (
        <WorkspaceCard
          image={workspaceItem?.image}
          isActive={activeWorkspaceId === workspaceItem.id}
          onClick={setActiveWorkspace}
          key={workspaceItem.name}
          {...workspaceItem}
        />
      ))}
      {canCreateWorkspace && (
        <Button
          minH="40px"
          variant="text"
          rightIcon="AddRounded"
          display="flex"
          justifyContent="space-around"
          disabled
          w="100%"
          data-testid="create-workspace"
        >
          Create new workspace
        </Button>
      )}
    </Stack>
  );
});
