import retry from 'async-retry';
import { useCallback, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

import { ItlyWeb } from '@flick-tech/client-tracking';
import { Logger } from '@flick-tech/logger';
import { Constants } from '@flick-tech/shared-common';
import { IUser } from '@flick-tech/shared-types';

const MAX_RETRIES = 4;

export function useInitialiseNotifications(
  user: Pick<IUser, 'id' | 'email'> | undefined,
) {
  const [hasInitialized, setHasInitialized] = useState(false);
  const isLoggedIn = !!user?.id;

  const userId = user?.id;

  const setupNotificationTracking = async () => {
    const setSubscriptionStatus = async () => {
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        ItlyWeb.identify({
          isSubscribedToBrowserNotifications: Boolean(isSubscribed),
        });
      });

      const isSubscribed = await OneSignal.isPushNotificationsEnabled();

      ItlyWeb.identify({
        isSubscribedToBrowserNotifications: Boolean(isSubscribed),
      });
    };

    await Promise.all(
      [
        () => OneSignal.setExternalUserId(user.id),
        () =>
          OneSignal.sendTags({
            email: user.email,
            userId: user.id,
          }),
        () => OneSignal.registerForPushNotifications(),
        () => setSubscriptionStatus(),
      ].map((asyncFunc: () => Promise<void>) =>
        retry(asyncFunc, {
          retries: MAX_RETRIES,
          // Do not throw if retry fails
          // TODO: fix
        } as any).catch(Logger.error),
      ),
    );
  };

  const initialize = useCallback(async () => {
    setHasInitialized(true);

    const initialize = () =>
      OneSignal.init({
        appId: Constants.oneSignal.appId,
        allowLocalhostAsSecureOrigin: Constants.isDev,
        safari_web_id: Constants.oneSignal.safariWebId,
        autoResubscribe: true,
      });

    try {
      await retry(initialize, {
        retries: MAX_RETRIES,
        // TODO: fix
      } as any);

      await retry(setupNotificationTracking, {
        retries: MAX_RETRIES,
        // TODO: fix
      } as any);
    } catch (error) {
      Logger.info('Failed to init OneSignal');
      Logger.error(error);
    }
  }, [userId]);

  useEffect(() => {
    if (!hasInitialized || !isLoggedIn) {
      return;
    }

    initialize();
  }, [hasInitialized, isLoggedIn]);
}

export { OneSignal };
