import { LDProvider } from 'launchdarkly-react-client-sdk';
import {
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Logger } from '@flick-tech/logger';
import { Constants } from '@flick-tech/shared-common';
import { useBoolean } from '@flick-tech/shared-hooks';

import {
  getLaunchDarklyUserProperties,
  LaunchDarklyUser,
} from '../useSetLaunchDarklyUser';

import { LaunchDarklyFlagsHandler } from './LaunchDarklyFlagsHandler';

interface WithLaunchDarklyProps {
  user: LaunchDarklyUser;
  loader: ReactNode;
}

export const WithLaunchDarkly = memo(
  ({ user, children, loader }: PropsWithChildren<WithLaunchDarklyProps>) => {
    const [hasInitialized, { on }] = useBoolean();
    const ref = useRef<LDProvider | undefined>();

    const initLaunchDarkly = useCallback(async () => {
      try {
        await ref.current?.initLDClient();
      } catch (error) {
        Logger.error(error);
      }

      on();
    }, [on]);

    useEffect(() => {
      initLaunchDarkly();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!user?.id) {
      return <>{children}</>;
    }

    return (
      <LDProvider
        ref={ref}
        clientSideID={
          Constants.isProd && !Constants.isCypress
            ? '60cf0bdc61ee920ce673897f'
            : '60cf21eef68b450bc580f620'
        }
        options={{
          // Disable streaming in Cypress so we're able to mock GET calls without
          // them being overridden by the streaming api
          streaming: !Constants.isCypress && !Constants.isTest,
          // Important to get "reason" when evaluating feature flags to track in Amplitude
          // https://www.notion.so/flicktech/Experimentation-tracking-framework-8c1a458c3ace42a3a6d2312f1cc5b3de
          evaluationReasons: true,
        }}
        user={getLaunchDarklyUserProperties(user)}
        // We are initializing manually above
        deferInitialization={false}
      >
        {hasInitialized ? (
          <>
            <LaunchDarklyFlagsHandler />
            {children}
          </>
        ) : (
          <>{loader}</>
        )}
      </LDProvider>
    );
  },
);
