import { useContext } from 'react';

import { Button, ButtonProps } from '../../button';
import { Icon } from '../../icon';

import { __FloatingActionsContext as FloatingActionsContext } from '../FloatingActionsContext';

export interface ActionsToggleProps extends Omit<ButtonProps, 'onClick'> {}
export const ActionsToggle = (buttonProps: ActionsToggleProps) => {
  const { isOpen, onToggle } = useContext(FloatingActionsContext);

  return (
    <Button
      onClick={onToggle}
      justifyContent="space-between"
      fontWeight="medium"
      borderRadius="12px"
      fontSize="lg"
      boxShadow="1px 1px 12px rgba(0, 0, 0, 0.25)"
      minWidth="275px"
      rightIcon={
        <Icon
          ml="10"
          boxSize="1.5rem"
          bgColor="whiteAlpha.200"
          borderRadius="md"
          icon={isOpen ? 'KeyboardArrowDownRounded' : 'KeyboardArrowUpRounded'}
        />
      }
      {...buttonProps}
      pointerEvents="initial"
    />
  );
};
