import { ReactNode } from 'react';

import { isMobile, plural } from '@flick-tech/shared-common';
import { formatNumberLong } from '@flick-tech/shared-formatters';
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '../../layout';

import { ChartDot } from '../ChartDot';
import { TextTransform } from '../types';

import { tooltipStatSpacing } from './Tooltip.constants';

export const TooltipHeading = (props: BoxProps) => (
  <Box mb={2} fontSize="sm" {...props} />
);

const TooltipStatWrapper = (props: FlexProps) => (
  <Flex
    justify="space-between"
    w="100%"
    flexShrink={0}
    /* This is due to overflow: hidden clipping text otherwise */
    lineHeight={`calc(1em + ${tooltipStatSpacing}px`}
    fontSize="sm"
    {...props}
  />
);

const TooltipStatLabel = (props: TextProps) => (
  <Text
    textOverflow="ellipsis"
    maxW="40vw"
    overflow="hidden"
    display="inline-block"
    {...props}
  />
);

interface TooltipStatProps {
  label: ReactNode;
  value: ReactNode;
  color?: string;
  highlight?: boolean;
  postfix?: TextTransform;
  vertical?: boolean;
}

export const TooltipStat = ({
  label,
  value,
  color,
  highlight,
  vertical,
  postfix = plural('follower'),
}: TooltipStatProps) => (
  <TooltipStatWrapper style={vertical ? { flexDirection: 'column' } : {}}>
    <Flex whiteSpace="nowrap" align="center">
      {color && (
        <ChartDot
          color={color}
          style={{
            marginRight: 8,
          }}
        />
      )}
      <TooltipStatLabel>{label}: </TooltipStatLabel>
    </Flex>
    <div style={{ whiteSpace: 'nowrap' }}>
      <span
        style={{
          fontWeight: highlight ? 900 : 600,
          marginLeft: vertical ? 18 : 12,
          color: highlight ? color : undefined,
        }}
      >
        {typeof value === 'number' ? formatNumberLong(value) : value}
      </span>
      {postfix &&
        !isMobile() &&
        ' ' + (typeof postfix === 'function' ? postfix(value, {}) : postfix)}
    </div>
  </TooltipStatWrapper>
);

// There were important styles here before, keeping it in case it'll change
export const TooltipStatsWrapper = (props: BoxProps) => <Box {...props} />;
