import { Property } from 'csstype';

import { Config, Prop } from '../utils';

export const shadowConfig: Config = {
  boxShadow: {
    property: 'boxShadow',
    scale: 'shadows',
  },
  textShadow: {
    property: 'textShadow',
    scale: 'shadows',
  },
  filter: {
    property: 'filter',
  },
};

/**
 * Types for box and text shadow properties
 */
export interface ShadowProps {
  /**
   * The `box-shadow` property
   */
  boxShadow?: Prop<Property.BoxShadow | number>;
  /**
   * The `text-shadow` property
   */
  textShadow?: Prop<Property.BoxShadow | number>;
}
