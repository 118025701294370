import Head from 'next/head';
import React, { ReactNode } from 'react';

export interface FlickSEOProps {
  title: string;
  description?: string;
  url?: string;
  image?: string;
  children?: ReactNode;
}

export function FlickSEO({
  title,
  description,
  url,
  image = 'https://www.flick.tech/og-image.jpeg',
  children,
}: FlickSEOProps) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} />
      {description ? <meta name="description" content={description} /> : <></>}

      {url ? <link ref="canonical" href={url} /> : <></>}
      <meta property="og:type" content="website" />
      {url ? <meta property="og:url" content={url} /> : <></>}
      <meta property="og:title" content={title} />
      {description ? (
        <meta property="og:description" content={description} />
      ) : (
        <></>
      )}
      {image ? <meta property="og:image" content={image} /> : <></>}

      <meta name="twitter:site" content="@TryFlick" />
      <meta name="twitter:creator" content="@TryFlick" />
      <meta property="twitter:card" content="summary_large_image" />
      {url ? <meta property="twitter:url" content={url} /> : <></>}
      <meta property="twitter:title" content={title} />
      {description ? (
        <meta property="twitter:description" content={description} />
      ) : (
        <></>
      )}
      {image ? <meta property="twitter:image" content={image} /> : <></>}
      {children ?? <></>}
    </Head>
  );
}
