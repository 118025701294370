import { forwardRef } from 'react';

import { FC } from '@flick-tech/shared-types';
import { useMedia, UseMediaBreakpoints } from '../media-query';

import { Box, BoxProps } from './Box';

const sizes: Partial<Record<UseMediaBreakpoints, number>> = {
  xs: 444,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export interface ContainerProps extends Omit<BoxProps, 'size'> {
  size?: UseMediaBreakpoints;
}

export const Container: FC<ContainerProps> = forwardRef<
  HTMLDivElement,
  ContainerProps
>(function Container(props, ref) {
  const { size, ...other } = props;
  const isOverThreshold = useMedia({ min: size });

  return (
    <Box
      w="100%"
      boxSizing="border-box"
      display="block"
      mx="auto"
      px={{ base: 4, sm: 6 }}
      ref={ref}
      {...(isOverThreshold && size ? { maxWidth: sizes[size] } : {})}
      {...other}
    />
  );
});
