import get from 'lodash/get';
import type { DeepRequired } from 'ts-essentials';
import { Builtin } from 'ts-essentials';
import type { Function, Object } from 'ts-toolbelt';
import { String } from 'ts-toolbelt';

export type DeepRequiredButNotNonNullable<T> = T extends Builtin
  ? T
  : T extends Map<infer K, infer V>
  ? Map<DeepRequiredButNotNonNullable<K>, DeepRequiredButNotNonNullable<V>>
  : T extends ReadonlyMap<infer K, infer V>
  ? ReadonlyMap<
      DeepRequiredButNotNonNullable<K>,
      DeepRequiredButNotNonNullable<V>
    >
  : T extends WeakMap<infer K, infer V>
  ? WeakMap<DeepRequiredButNotNonNullable<K>, DeepRequiredButNotNonNullable<V>>
  : T extends Set<infer U>
  ? Set<DeepRequiredButNotNonNullable<U>>
  : T extends ReadonlySet<infer U>
  ? ReadonlySet<DeepRequiredButNotNonNullable<U>>
  : T extends WeakSet<infer U>
  ? WeakSet<DeepRequiredButNotNonNullable<U>>
  : T extends Promise<infer U>
  ? Promise<DeepRequiredButNotNonNullable<U>>
  : T extends {}
  ? {
      [K in keyof T]-?: DeepRequiredButNotNonNullable<T[K]>;
    }
  : T;

export type AtPath<O, P extends string> = Object.Path<
  DeepRequiredButNotNonNullable<O>,
  String.Split<P, '.'>
>;

export const isFinite = Number.isFinite as (x: unknown) => x is number;

export function average(...numbers: (number | null | undefined)[]) {
  const xs = numbers.filter(isFinite);

  return xs.reduce((a, b) => a + b, 0) / xs.length;
}

/**
 * Shallow clones to path.length depth and sets clone[path[0]][path[1]][...] to given value.
 */
export function unsafeSet<T extends object>(
  obj: T,
  path: Object.Paths<T>,
  // This function could be safer, but it's much slower on the text editor.
  // `unknown` here is a reasonable trade-off between safety and IDE performance
  value: unknown,
): T {
  const res = { ...obj };
  let current = res;

  for (let i = 0; i < path.length - 1; ++i) {
    current[path[i]] = { ...current[path[i]] };
    current = current[path[i]];
  }

  current[path[path.length - 1]] = value;

  return res;
}

export function mergeOn<T extends object>() {
  return <P extends string>(
    path: Function.AutoPath<DeepRequired<T>, P>,
    operation: (x: AtPath<T, P>, y: AtPath<T, P>) => AtPath<T, P>,
  ) => {
    return (a: T, b: T) => {
      const value = operation(get(a, path), get(b, path));

      return unsafeSet(
        b,
        // This assertion is always safe due to Function.AutoPath above.
        path.split('.') as unknown as Object.Paths<T>,
        value,
      );
    };
  };
}
