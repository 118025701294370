import { FieldRenderProps } from 'react-final-form';

import { DropdownInput } from './DropdownInput';
import { OptionField, SelectProps } from './Select.types';

interface FinalFormMultiSelectFieldProps
  // TODO: fix any
  extends FieldRenderProps<any, HTMLInputElement>,
    Omit<SelectProps, 'onChange' | 'value'> {
  defaultOptions?: OptionField[];
}

export function FinalFormMultiSelectField({
  options,
  input,
  ...rest
}: FinalFormMultiSelectFieldProps) {
  return <DropdownInput options={options} {...input} {...rest} isMulti />;
}
