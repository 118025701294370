import numeral from 'numeral';

export const formatPercentage = (
  num: number | null | undefined,
  numDecimalPlaces: number | 'auto' = 0,
): string => {
  let format = '0';

  num || (num = 0);

  if (numDecimalPlaces === 'auto') {
    if (num > 1 || num === 0) {
      numDecimalPlaces = 0;
    } else {
      numDecimalPlaces = Math.ceil(Math.log10(0.01 / Math.abs(num)));
    }
  }

  if (numDecimalPlaces > 0) {
    format += `.${new Array(numDecimalPlaces).fill('0').join('')}`;
  }

  return numeral(num).format(`${format}%`);
};

export const EMPTY_TEXT = 'n/a';

export const makeFormatNumber =
  (format = '0.[0]a', emptyText = EMPTY_TEXT) =>
  (num: number | string | undefined): string => {
    if ((typeof num !== 'string' && isNaN(num)) || num === null) {
      return emptyText;
    }
    const numParsed = typeof num === 'string' ? parseInt(num, 10) : num;

    if (Math.abs(numParsed) < 1000) {
      return String(Math.round(numParsed * 100) / 100);
    }

    return numeral(numParsed).format(format);
  };

export const formatOrdinal = (number: number) =>
  numeral(Math.abs(number)).format('0o');

// E.g. "12321" -> "12.3k"
export const formatNumber = makeFormatNumber();
// E.g. "12321" -> "12k"
export const formatNumberShort = makeFormatNumber('0a');
// E.g "12321" -> "12,321"
//     "123219" -> "123.22k"
export const formatNumberLong = (num: number | string) => {
  if ((typeof num !== 'string' && isNaN(num)) || num === null) {
    return EMPTY_TEXT;
  }
  const numParsed = typeof num === 'string' ? parseInt(num, 10) : num;

  if (num < 100000) return num.toLocaleString();

  return numeral(numParsed).format('0.[00]a');
};

export const formatSignedNumber = (num: number, format?: string): string => {
  const changedPrefix = num > 0 ? '+' : '';

  return `${changedPrefix}${makeFormatNumber(format)(num)}`;
};

const MAX_SAFE_INTEGER = 9007199254740991; // Math.pow(2, 53) - 1;

const _isSafeInteger = (value: number) => {
  return Number.isInteger(value) && Math.abs(value) <= MAX_SAFE_INTEGER;
};

export const isInteger = Number.isSafeInteger || _isSafeInteger;

export const formatCurrency = (
  num?: number | string | null,
  format = '0.00',
) => {
  if (!num) return 0;

  const numParsed = typeof num === 'string' ? parseFloat(num) : num;

  return numeral(numParsed).format(format);
};
