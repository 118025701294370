import { RecoilState, RecoilValue, useRecoilCallback } from 'recoil';

interface RecoilExternalStatePortalAPI {
  get?: <T>(atom: RecoilValue<T>) => T;
  getAsync?: <T>(atom: RecoilValue<T>) => Promise<T>;
  set?: <T>(
    atom: RecoilState<T>,
    valOrUpdater: T | ((currVal: T) => T),
  ) => void;
  reset?: (atom: RecoilState<any>) => void;
}

const api: RecoilExternalStatePortalAPI = {};

export function RecoilExternalStatePortal() {
  api.get = useRecoilCallback<[atom: RecoilValue<any>], any>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getLoadable(atom).contents;
      },
    [],
  );

  api.getAsync = useRecoilCallback<[atom: RecoilValue<any>], Promise<any>>(
    ({ snapshot }) =>
      function <T>(atom: RecoilValue<T>) {
        return snapshot.getPromise(atom);
      },
    [],
  );

  api.set = useRecoilCallback(({ set }) => set, []);

  api.reset = useRecoilCallback(({ reset }) => reset, []);

  return <></>;
}

export function getRecoil<T>(atom: RecoilValue<T>): T {
  return api.get!(atom);
}

export function getRecoilPromise<T>(atom: RecoilValue<T>): Promise<T> {
  return api.getAsync!(atom);
}

export function setRecoil<T>(
  atom: RecoilState<T>,
  valOrUpdater: T | ((currVal: T) => T),
) {
  api.set!(atom, valOrUpdater);
}

export function resetRecoil(atom: RecoilState<any>) {
  api.reset!(atom);
}
