import StandardSelect from 'react-select';
import AsyncSelectLib from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { StateManager } from 'react-select/src/stateManager';

import { SelectProps } from '../Select.types';

import { useSelectCommonProps } from './utils';

export function Select<T>({
  size,
  label,
  options,
  selectRef,
  hasError,
  error,
  dataTestId,
  isCreatable,
  loadOptions,
  ...rest
}: SelectProps<T> & {
  isCreatable?: boolean;
  minWidth?: number;
}) {
  const props = useSelectCommonProps<T>({
    ...rest,
    hasError,
    size,
  });

  const SelectLib: any = isCreatable ? CreatableSelect : StandardSelect;

  if (loadOptions) {
    return (
      // @ts-ignore
      <AsyncSelectLib
        createOptionPosition="first"
        allowCreateWhileLoading
        loadOptions={loadOptions as any}
        cacheOptions
        {...props}
      />
    );
  }

  return (
    <SelectLib
      {...props}
      ref={(ref: StateManager) => {
        if (ref && selectRef) {
          selectRef.current = ref;
        }
      }}
      options={options}
    />
  );
}

export default Select;
