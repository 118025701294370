import {
  Bar,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { formatNumberShort } from '@flick-tech/shared-formatters';

import { makeBarShape } from './components/BarShape';
import { DescriptionListLegend } from './components/DescriptionListLegend';
import { EmptyStateWrapper } from './components/EmptyStateWrapper';
import { barChartPalette, maxBarSize } from './const';
import {
  FlickCommonChartProps,
  legendHeight,
  styledRechartClassnames,
} from './FlickCharts';
import { rechartsTypes } from './types';
import { useChart } from './useChart';
import { getTickTextWidth } from './utils';
import { memo } from 'react';

export const VerticalBarShape = makeBarShape();
export const HorizontalBarShape = makeBarShape({ horizontal: true });

export interface FlickBarChartProps<T extends object>
  extends FlickCommonChartProps<T> {
  horizontal?: boolean;
  margin?: rechartsTypes.ComposedChartProps['margin'];
  numberFormatter?: (value: string | number) => string;
}

export const FlickBarChart = memo((props: FlickBarChartProps<Record<string, any>>) => {
  const {
    data,
    height,
    keys,
    colors = barChartPalette,
    showLegend = true,
    indexBy = 'date',
    margin = {},
    horizontal,
    numberFormatter = formatNumberShort,
  } = props;

  const { parsedData, minValue, legendProps, tooltipProps, isDataMissing } =
    useChart({
      ...props,
      rawData: data,
    });

  // Yes, recharts got the names wrong...
  const layout = horizontal ? 'vertical' : 'horizontal';

  const numberAxisProps = {
    type: 'number',
    tickFormatter: numberFormatter,
  } as const;

  const categoryAxisProps = {
    type: 'category',
    dataKey: indexBy,
  } as const;

  let longestWord = 30;
  if (horizontal) {
    data.forEach((row) => {
      row[indexBy].split(' ').forEach((word) => {
        const wordWidth = getTickTextWidth(word);
        if (longestWord < wordWidth) {
          longestWord = wordWidth;
        }
      });
    });
  }

  return (
    <EmptyStateWrapper
      active={isDataMissing}
      sx={{
        ...styledRechartClassnames,
        '.recharts-cartesian-axis-tick-value': {
          fontSize: 14,
        },
      }}
    >
      <ResponsiveContainer height={showLegend ? height - legendHeight : height}>
        <ComposedChart
          stackOffset="sign"
          maxBarSize={maxBarSize}
          data={parsedData}
          margin={{
            top: 0,
            right: 0,
            left: Math.ceil(longestWord - 50),
            bottom: 0,
            ...margin,
          }}
          layout={layout}
        >
          <Tooltip {...tooltipProps} />
          {keys.map((key, i) => (
            <Bar
              key={key}
              dataKey={key}
              fill={colors[i]}
              stackId="same"
              shape={horizontal ? HorizontalBarShape : VerticalBarShape}
            />
          ))}
          <XAxis {...(horizontal ? numberAxisProps : categoryAxisProps)} />
          <YAxis {...(horizontal ? categoryAxisProps : numberAxisProps)} />
          {minValue < 0 && (
            <ReferenceLine {...{ [horizontal ? 'x' : 'y']: 0 }} />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      {showLegend && <DescriptionListLegend {...legendProps} />}
    </EmptyStateWrapper>
  );
});
