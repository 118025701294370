import { GetStaticPaths } from 'next';

import { Constants } from './constants';

export const createGetStaticPaths = (func: GetStaticPaths): GetStaticPaths => {
  if (Constants.next.disableGSP) {
    return async () => ({
      paths: [],
      fallback: 'blocking',
    });
  }

  return func;
};
