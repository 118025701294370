import { Button } from '../../button';
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ScaleFadeModal,
} from '../../modal';

import { PromptOptions } from '../types';

interface PromptDialogProps extends PromptOptions {
  open: boolean;
  onClose: () => void;
}

function PromptDialog({ open, onClose, title, content }: PromptDialogProps) {
  return (
    <ScaleFadeModal isOpen={open} onClose={onClose}>
      <>
        {title && (
          <ModalHeader>
            {title}
            <ModalCloseButton />
          </ModalHeader>
        )}
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </>
    </ScaleFadeModal>
  );
}

export default PromptDialog;
