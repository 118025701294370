import { useQuery as useReactQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { serialize } from 'superjson';

import { useFlickAccessToken } from '../useGraphqlFetch';

import { CreateBlitzClientParams } from './createBlitzClient.types';
import { CreateSetQueryData } from './createQueryUtils';
import { fetchBlitzAPI } from './fetchBlitzAPI';
import { getQueryKey } from './queryUtils';

interface CreateUseQueryParams extends CreateBlitzClientParams {
  createSetQueryData: CreateSetQueryData;
}

export function createUseQuery({
  baseUrl,
  createSetQueryData,
}: CreateUseQueryParams) {
  return function useQuery<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
  >(
    path: string,
    params: TQueryFnData,
    options: UseQueryOptions<TQueryFnData, TError, TData, [string, any]> = {},
  ) {
    const paramsSerialized = serialize(params);

    const client = useQueryClient()

    const fullPath = `${baseUrl}${path}`;

    const { accessToken } = useFlickAccessToken();

    const { data, ...queryRest } = useReactQuery<TQueryFnData, TError, TData>(
      getQueryKey(path, paramsSerialized),
      () =>
        fetchBlitzAPI({
          path: fullPath,
          paramsSerialized,
          accessToken,
        }),
      {
        enabled: Boolean(accessToken),
        ...options,
      },
    );

    const rest = {
      ...queryRest,
      setQueryData: createSetQueryData<TQueryFnData, TData>(client, path, params),
    };

    return [data, rest] as const;
  };
}
