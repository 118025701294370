import { __DEV__ } from '@flick-tech/shared-utils';
import {
  BorderProps,
  chakra,
  forwardRef,
  PropsOf,
  SpaceProps,
} from '@flick-tech/theme-new';

import { Box, BoxProps } from './Box';

interface DividerOptions {
  orientation?: 'horizontal' | 'vertical';
}

export interface DividerProps
  extends PropsOf<'hr'>,
    DividerOptions,
    BorderProps,
    SpaceProps {}

/**
 * Layout component used to visually separate content in a list or group.
 * It display a thin horizontal or vertical line, and renders a `hr` tag.
 *
 * @see Docs https://chakra-ui.com/components/divider
 */
export const Divider = forwardRef<DividerProps, 'hr'>(function Divider(
  props,
  ref,
) {
  const {
    className,
    orientation = 'horizontal',
    sx,
    borderColor,
    ...rest
  } = props;

  return (
    <chakra.hr
      ref={ref}
      role="separator"
      aria-orientation={orientation}
      borderColor={borderColor || 'gray.200'}
      {...rest}
      sx={{
        border: '0',
        opacity: 0.6,
        borderStyle: 'solid',
        ...(orientation === 'vertical'
          ? { borderLeftWidth: '1px', height: '100%' }
          : { borderBottomWidth: '1px', width: '100%' }),
        ...sx,
      }}
      className={props.className}
    />
  );
});

export function BaiscDivider(props: BoxProps) {
  return <Box h="1px" bg="gray.100" w="100%" {...props} />;
}

if (__DEV__) {
  Divider.displayName = 'Divider';
}
