import { datadogRum } from '@datadog/browser-rum';
import { memo, useCallback, useEffect } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import {
  IdentifyProperties,
  identifyUser,
  ItlyWeb,
  useSetLaunchDarklyUser,
} from '@flick-tech/client-tracking';
import { useGetUser } from '@flick-tech/core-hooks';
import { Logger } from '@flick-tech/logger';
import { useDeepCompareEffect } from '@flick-tech/shared-hooks';
import { isNewPlan } from '@flick-tech/shared-common'
import { isNumber } from '@flick-tech/shared-utils';
import { useGetSocialGroups } from '@flick-tech/social-group-hooks';
import { useInitialiseNotifications } from '@flick-tech/web-notifications';

declare const window: {
  profitwell: (
    command: 'start',
    params: {
      // CHARGEBEE USER ID
      user_id: string;
    },
  ) => void;
};

function useTrackNumIGAccounts() {
  const { socialGroupIGAccounts: igAccounts, linkedIGAccounts } =
    useGetSocialGroups();

  const numIGAccounts = igAccounts?.length;
  const numLinkedIGAccounts = linkedIGAccounts?.length;

  useEffect(() => {
    // Wait for both to load to batch identify
    if (!isNumber(numIGAccounts) || !isNumber(numLinkedIGAccounts)) {
      return;
    }

    ItlyWeb.identify({
      NumIGAccounts: numIGAccounts,
      NumLinkedIGAccounts: numLinkedIGAccounts,
    });
  }, [numIGAccounts, numLinkedIGAccounts]);
}

function useSetupTracking() {
  const { user } = useGetUser();

  useTrackNumIGAccounts();

  const setLaunchDarklyUser = useSetLaunchDarklyUser();

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    setLaunchDarklyUser({
      user: {
        id: user.id,
        email: user.email.toLowerCase(),
        name: user.name,
        profilePicture: user.profilePicture,
        createdAt: new Date(user.createdAt),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setLaunchDarklyUser,
    user?.id,
    user?.email,
    user?.name,
    user?.profilePicture,
  ]);

  // Initialize user to object so we are not deep-comparing null
  const deps = [user ?? {}];

  useDeepCompareEffect(() => {
    if (!user) {
      return;
    }

    Logger.info(user);

    const additionalProps: IdentifyProperties = {};

    const planId = user?.activeWorkspace?.billing?.plan?.id;

    if (planId) {
      additionalProps.isOnNewPlan = isNewPlan(planId);
    }

    identifyUser(user, additionalProps);

    datadogRum.setUser({
      id: user.id,
      email: user.email,
      name: user.name,
    });

    const customerId = user?.activeWorkspace?.billing?.customer?.id;

    if (typeof window.profitwell === 'function' && customerId) {
      window.profitwell('start', { user_id: customerId });
    } else {
      Logger.info(`Failed to initialize profitwell`, window.profitwell);
    }
  }, deps);

  useInitialiseNotifications(user);
}

const reInitializeUserState = atom<number>({
  key: 'app/reInitializeUserState',
  default: Math.random(),
});

export function useReInitializeUser() {
  const set = useSetRecoilState(reInitializeUserState);

  return useCallback(() => set(Math.random()), [set]);
}

export function useInitializeLoggedInUser() {
  // A hook to allow us to manually re-trigger initializing the user
  useRecoilState(reInitializeUserState);

  useSetupTracking();
}

export const InitializeLoggedInUser = memo(() => {
  useInitializeLoggedInUser();

  return <></>;
});
