import React, { ReactNode } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';

import { __DEV__, cx, split } from '@flick-tech/shared-utils';
import {
  chakra,
  ChakraProps,
  layoutPropNames,
  PropsOf,
  useComponentStyle,
  useCss,
} from '@flick-tech/theme-new';
import {
  FormControlOptions,
  FormControlWrapper,
  FormControlWrapperProps,
  useFormControl,
} from '../form-control';
import { InputProps } from '../input';

interface SelectOptions extends FormControlOptions {
  /**
   * The border color when the select is focused. Use color keys in `theme.colors`
   * @example
   * focusBorderColor = "blue.500"
   */
  focusBorderColor?: string;
  /**
   * The border color when the select is invalid. Use color keys in `theme.colors`
   * @example
   * errorBorderColor = "red.500"
   */
  errorBorderColor?: string;
  /**
   * If `true`, the select element will span the full width of it's parent
   */
  isFullWidth?: boolean;
  /**
   * The placeholder for the select. We render an `<option/>` element that has
   * empty value.
   *
   * ```jsx
   * <option value="">{placeholder}</option>
   * ```
   */
  placeholder?: string;
}

const StyledSelect = chakra<'select', SelectOptions>('select', {
  themeKey: 'Select',
  shouldForwardProp: (prop) =>
    !['focusBorderColor', 'errorBorderColor'].includes(prop),
});

type Omitted = 'disabled' | 'required' | 'readOnly' | 'size';

export type NativeSelectFieldProps = Omit<
  PropsOf<'select'>,
  'color' & Omitted
> &
  ChakraProps & {
    size?: string;
    multiple?: boolean;
  };

/**
 * The native `select` element enhanced for accessibility and validation.
 */
export const NativeSelectField = React.forwardRef<NativeSelectFieldProps>(
  function NativeSelectField(
    props: NativeSelectFieldProps,
    ref: React.Ref<any>,
  ) {
    const { children, placeholder, className, ...rest } = props;
    const fieldProps = useFormControl<HTMLSelectElement>(props);

    return (
      <StyledSelect
        ref={ref}
        className={cx('chakra-select', className)}
        {...(rest as any)}
        {...fieldProps}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {children}
      </StyledSelect>
    );
  },
);

if (__DEV__) {
  NativeSelectField.displayName = 'NativeSelectField';
}

type RootProps = Omit<PropsOf<typeof chakra.div>, 'color'>;

export interface NativeSelectProps
  extends Omit<NativeSelectFieldProps, 'size'> {
  /**
   * Props to forward to the root `div` element
   */
  rootProps?: RootProps;
  /**
   * The icon element to use in the select
   */
  icon?: React.ReactElement<any>;
  /**
   * The size of the select dropdown icon
   */
  iconSize?: any;
  /**
   * The color of the select dropdown icon
   */
  iconColor?: string;

  isDisabled?: boolean;

  size?: InputProps['size'];
}

/**
 * React component used to select one item from a list of options.
 */
export const NativeSelect = React.forwardRef(function Select(
  props: NativeSelectProps,
  ref: React.Ref<any>,
) {
  const {
    rootProps,
    placeholder,
    icon,
    iconSize = '1.25rem',
    iconColor,
    color,
    ...rest
  } = props;

  const opacity = props.isDisabled ? 0.5 : undefined;
  const [layoutProps, otherProps] = split(rest, layoutPropNames as any[]);
  const styles = useComponentStyle({ themeKey: 'Select', ...props });

  return (
    <chakra.div
      position="relative"
      width="100%"
      className="chakra-select__wrapper"
      color={color}
      {...layoutProps}
      {...rootProps}
    >
      <NativeSelectField
        ref={ref}
        placeholder={placeholder}
        w="100%"
        {...otherProps}
        {...(styles.field as any)}
        data-testid={
          rest['data-testid'] ? `${rest['data-testid']}-input` : undefined
        }
        mr={
          {
            sm: '8px',
            md: '12px',
          }[props.size ?? 'md']
        }
      >
        {props.children}
      </NativeSelectField>

      <NativeSelectIcon
        opacity={opacity}
        iconSize={iconSize}
        iconColor={iconColor || styles?.color}
        children={icon}
      />
    </chakra.div>
  );
});

if (__DEV__) {
  NativeSelect.displayName = 'Select';
}

export const DefaultIcon = (props: PropsOf<'svg'>) => (
  <svg
    viewBox="0 0 24 24"
    {...props}
    className={cx('chakra-select__icon', props.className)}
  >
    <path
      fill="currentColor"
      d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
    />
  </svg>
);

const NativeSelectIocnWrapper = chakra('div', {
  baseStyle: {
    position: 'absolute',
    display: 'inline-flex',
    width: '1.5rem',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0.5rem',
    pointerEvents: 'none',
    zIndex: 2,
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

type NativeSelectIocnProps = PropsOf<typeof NativeSelectIocnWrapper> & {
  iconColor?: string;
  iconSize?: string | number;
};

function NativeSelectIcon(props: NativeSelectIocnProps) {
  const {
    children = <DefaultIcon />,
    iconColor = 'inherit',
    iconSize,
    ...rest
  } = props;

  const style = useCss({
    color: iconColor,
    width: iconSize,
    height: iconSize,
    fontSize: '1em',
  });

  const clone = React.cloneElement(children as any, {
    role: 'presentation',
    focusable: false,
    'aria-hidden': true,
    style,
  });

  return (
    <NativeSelectIocnWrapper
      {...rest}
      className={'chakra-select__icon-wrapper'}
      children={clone}
    />
  );
}

if (__DEV__) {
  NativeSelectIcon.displayName = 'NativeSelectIocn';
}

export interface FinalFormNativeSelectProps<FieldValue = string>
  extends Omit<NativeSelectProps, 'onChange' | 'value'> {
  isCreatable?: boolean;
  hideTouchedError?: boolean;
  helperText?: string;
  label?: FormControlWrapperProps['label'];
  hideHelperText?: boolean;
  fullWidth?: boolean;
  wrapperProps?: Partial<FormControlWrapperProps>;
  fieldConfig?: UseFieldConfig<FieldValue>;
  name: string;
  options: {
    value: string;
    label: string;
  }[];
  placeholder?: string;
}

export function FinalFormNativeSelect({
  hideTouchedError,
  helperText,
  label,
  hideHelperText,
  fullWidth,
  name,
  wrapperProps = {},
  fieldConfig = {},
  options,
  variant = 'filled',
  placeholder = 'Select...',
  ...rest
}: FinalFormNativeSelectProps) {
  const { meta, input } = useField(name, fieldConfig);

  const { error, submitFailed } = meta;
  const showError =
    ((meta.touched && !hideTouchedError) || submitFailed) && error;

  return (
    <FormControlWrapper
      {...wrapperProps}
      width={fullWidth ? '100%' : 'auto'}
      showError={showError}
      error={error}
      helperText={hideHelperText ? undefined : helperText}
      label={label}
      name={name}
    >
      <NativeSelect
        {...rest}
        {...input}
        placeholder={placeholder}
        variant={variant}
        opacity={input.value ? 1 : 0.6}
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </NativeSelect>
    </FormControlWrapper>
  );
}
