import * as React from 'react';

import { SystemProps } from '@flick-tech/theme-new';

import { Box, Center, Heading__deprecated, Text, TextProps } from '../layout';

export interface ErrorScreenProps extends SystemProps {
  title: string;
  body: React.ReactNode;
  footerText?: React.ReactNode;
  illustration?: React.ReactNode;
}
export function ErrorScreen({
  title,
  body,
  footerText,
  illustration,
  ...rest
}: ErrorScreenProps) {
  return (
    <Box w="100%" h="100%" bg="#F5F5F5">
      <Center
        flexDirection="column"
        w="100%"
        minH="100%"
        p={{
          base: '20px',
          md: '80px 20px',
        }}
        position="relative"
        {...rest}
      >
        {illustration}
        <Heading__deprecated mt={6} mb={2} textAlign="center">
          {title}
        </Heading__deprecated>
        <Center flexDirection="column" maxW={550} mx="auto">
          {body}
        </Center>
        {footerText && (
          <Text
            color="blackAlpha.500"
            position="fixed"
            bottom={6}
            w="100%"
            textAlign="center"
            fontSize="lg"
          >
            {footerText}
          </Text>
        )}
      </Center>
    </Box>
  );
}

ErrorScreen.BodyText = (props: TextProps) => (
  <Text
    textAlign="center"
    fontSize="22px"
    lineHeight={1.4}
    color="blackAlpha.700"
    {...props}
  />
);

ErrorScreen.ErrorText = (props: TextProps) => (
  <Text
    textAlign="center"
    fontSize="sm"
    lineHeight={1.4}
    color="blackAlpha.700"
    {...props}
  />
);
