import { useContext } from 'react';

import AsyncDialogContext from './AsyncDialogContext';
import { AsyncDialog } from './types';

function useAsyncDialog(): AsyncDialog {
  const { dialog } = useContext(AsyncDialogContext);

  return dialog as AsyncDialog;
}

export default useAsyncDialog;
