import { PropsOf } from '@flick-tech/shared-types';
import { chakra } from '@flick-tech/theme-new';

export function ErrorIconSvg(props: PropsOf<typeof chakra['svg']>) {
  return (
    <chakra.svg viewBox="0 0 21 21" {...props}>
      <path d="M10.0036 0.221802C4.48402 0.221802 0.00439453 4.70143 0.00439453 10.221C0.00439453 15.7405 4.48402 20.2201 10.0036 20.2201C15.5231 20.2201 20.0027 15.7405 20.0027 10.221C20.0027 4.70143 15.5231 0.221802 10.0036 0.221802ZM10.0036 11.2209C9.45361 11.2209 9.00365 10.7709 9.00365 10.221V6.2213C9.00365 5.67135 9.45361 5.22139 10.0036 5.22139C10.5535 5.22139 11.0035 5.67135 11.0035 6.2213V10.221C11.0035 10.7709 10.5535 11.2209 10.0036 11.2209ZM11.0035 15.2206H9.00365V13.2207H11.0035V15.2206Z" />
    </chakra.svg>
  );
}
