import { useFlags } from 'launchdarkly-react-client-sdk';

import { Constants } from '@flick-tech/shared-common';

export interface FeatureFlags {
  onboardingChecklistV1?: boolean;
  onboardingChecklistBannerColorV1?: 'control' | 'blue' | 'red';
  schedule?: boolean;
  teams?: boolean;
  socialGroups?: boolean;
  keywordSearchFilter?: boolean;
  newMobileCheckout?: boolean;
  managingSocialGroupUsers?: boolean;
}

export function useFeatureFlags(): FeatureFlags {
  return useFlags();
}

export function useHasFeatureFlag(key: keyof FeatureFlags) {
  const flags = useFeatureFlags();

  return (
    flags[key] || Constants.isDev || Constants.isTest || Constants.isCypress
  );
}
