import { PersistentStore } from './persistent-store';

interface BasicUserInfo {
  id?: string;
  email?: string;
  name?: string;
}

const key = 'basic-user-info';

export const getPersistedBasicUserInfo = (): BasicUserInfo | undefined =>
  PersistentStore.get(key);

export const clearPersistedBasicUserInfo = () => PersistentStore.remove(key);

export function setPersistedBasicUserInfo(info: BasicUserInfo) {
  const existing = getPersistedBasicUserInfo() ?? {};

  const data: BasicUserInfo = { ...existing, ...info };

  PersistentStore.set(key, data);
}
