import { Property } from 'csstype';

import { ThemedProperty } from '../types';
import { Config, Length, Prop } from '../utils';

export const typographyConfig: Config = {
  fontFamily: {
    property: 'fontFamily',
    scale: 'fonts',
  },
  fontSize: {
    property: 'fontSize',
    scale: 'fontSizes',
  },
  fontWeight: {
    property: 'fontWeight',
    scale: 'fontWeights',
  },
  lineHeight: {
    property: 'lineHeight',
    scale: 'lineHeights',
  },
  letterSpacing: {
    property: 'letterSpacing',
    scale: 'letterSpacings',
  },
  textAlign: true,
  fontStyle: true,
  wordBreak: true,
  overflowWrap: true,
  textOverflow: true,
  textTransform: true,
  whiteSpace: true,
  textDecoration: { property: 'textDecoration' },
  textDecor: { property: 'textDecoration' },
  backgroundClip: true,
  WebkitTextFillColor: true,
};

/**
 * Types for typography related CSS properties
 */
export interface TypographyProps {
  /**
   * The CSS `font-weight` property
   */
  fontWeight?: Prop<
    Property.FontWeight | 'medium' | 'light' | 'semibold' | 'extrabold'
  >;
  /**
   * The CSS `line-height` property
   */
  lineHeight?: Prop<Property.LineHeight<Length>>;
  /**
   * The CSS `line-height` property
   */
  letterSpacing?: Prop<Property.LetterSpacing<Length>>;
  /**
   * The CSS `font-size` property
   */
  fontSize?: Prop<Property.FontSize<Length>>;
  /**
   * The CSS `font-family` property
   */
  fontFamily?: Prop<ThemedProperty.FontFamily>;
  /**
   * The CSS `text-align` property
   */
  textAlign?: Prop<Property.TextAlign>;
  /**
   * The CSS `font-style` property
   */
  fontStyle?: Prop<Property.FontStyle>;
  /**
   * The CSS `word-break` property
   */
  wordBreak?: Prop<Property.WordBreak>;
  /**
   * The CSS `overflow-wrap` property
   */
  overflowWrap?: Prop<Property.OverflowWrap>;
  /**
   * The CSS `text-overflow` property
   */
  textOverflow?: Prop<Property.TextOverflow>;
  /**
   * The CSS `text-transform` property
   */
  textTransform?: Prop<Property.TextTransform>;
  /**
   * The CSS `white-space` property
   */
  whiteSpace?: Prop<Property.WhiteSpace>;
  /**
   * The CSS `text-decoration` property
   */
  textDecoration?: Prop<Property.TextDecoration<Length>>;
  /**
   * The CSS `text-decoration` property
   */
  textDecor?: Prop<Property.TextDecoration<Length>>;

  backgroundClip?: Prop<Property.BackgroundClip>;

  WebkitTextFillColor?: Prop<Property.WebkitTextFillColor>;
}
