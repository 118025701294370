import NextLink from 'next/link';
import React from 'react';

import { ROUTES } from '@flick-tech/shared-common';
import { useHasFeatureFlag } from '@flick-tech/shared-hooks';

import { Button } from '../../button';
import { Icon } from '../../icon';
import { Image } from '../../image';
import { Center, Flex, Link, Stack, Text } from '../../layout';

export interface LinkIGAccountsBenefitsEmptyStateProps {
  benefits: string[];
  image: string;
  title: string;
}

export function LinkIGAccountsBenefitsEmptyState({
  benefits,
  image,
  title,
}: LinkIGAccountsBenefitsEmptyStateProps) {
  const hasAccessToSocialGroups = useHasFeatureFlag('socialGroups');

  return (
    <Center p={4} h="100%" flexDir="column" w="100%" bg="white">
      <Image w="100%" maxW="661px" mx="auto" mb={10} src={image} />
      <Text
        textAlign="center"
        letterSpacing="0.5%"
        lineHeight="33.89px"
        fontSize="28px"
        fontWeight={700}
        mb={1}
      >
        {title}
      </Text>
      <Text color="text.light" letterSpacing="0.5%" fontSize="22px" mt={5}>
        Once your business/creator profile is linked you'll be able to:
      </Text>
      <Stack maxW="674px" mx="auto" mt={3} spacing={3}>
        {benefits.map((benefit, index) => (
          <Flex w="100%" align="center" key={index}>
            <Icon fontSize="16px" icon="CheckRounded" color="brand.500" />
            <Text ml={2} color="text.darker">
              {benefit}
            </Text>
          </Flex>
        ))}
      </Stack>
      <NextLink
        href={
          hasAccessToSocialGroups
            ? ROUTES.Profile.AccountSettings
            : ROUTES.Profile.InstagramAccounts
        }
        passHref
      >
        <Button
          textDecoration="none"
          size="lg"
          leftIcon="LinkRounded"
          as="a"
          mt={8}
        >
          Link Instagram Account
        </Button>
      </NextLink>
      <Text textAlign="center" color="gray.500" w="100%" mt={4}>
        Having trouble getting your account linked?{' '}
        <Link
          fontWeight={400}
          href="http://help.flick.tech/en/articles/3924276-troubleshooting-linking-your-instagram-account-to-flick"
        >
          Read troubleshooting guide.
        </Link>
      </Text>
    </Center>
  );
}
