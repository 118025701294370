import { useGetUser } from './useGetUser';

export function useCurrentPlan() {
  const { user, loading } = useGetUser();

  return {
    loading,
    plan: user?.activeWorkspace?.billing?.plan?.id,
  };
}
