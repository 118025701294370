import { useCallback } from 'react';
import { useQueryClient } from 'react-query';


import {
  ITeamMembersQueryVariables,
  useTeamMembersQuery,
} from './useTeamMembersQuery.generated';

const useVariables = (): ITeamMembersQueryVariables => {
  return {};
};

export function useTeamMembersCache() {
  const variables = useVariables();
  const client = useQueryClient();

  const invalidate = useCallback(() => {
    return client.invalidateQueries(useTeamMembersQuery.getKey(variables));
  }, [variables, client]);

  return {
    invalidate,
  };
}

export function useRefetchTeamMembers() {
  const variables = useVariables();
  const client = useQueryClient();

  return useCallback(
    () =>
      client.refetchQueries({
        queryKey: useTeamMembersQuery.getKey(variables),
      }),
    [client, variables],
  );
}

export function useTeamMembers() {
  const variables = useVariables();
  const { data, isLoading, refetch } = useTeamMembersQuery(variables, {
    suspense: false,
  });

  const teamMembers = data?.me?.activeWorkspace?.members ?? [];
  const teamMembersLimit = data?.me?.activeWorkspace?.teamMembersLimit ?? 0;
  const owner = teamMembers.find((member) => member.role === 'Owner');

  return {
    isLoading,
    refetch,
    teamMembers,
    teamMembersLimit,
    owner,
    // - 1 as we don't count the owner
    slotsUsed: teamMembers.length - 1,
  };
}

export type TeamMember = ReturnType<
  typeof useTeamMembers
>['teamMembers'][number];
