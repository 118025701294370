import { useCallback } from 'react';

import { Logger } from '@flick-tech/logger';
import { useBoolean } from '@flick-tech/shared-hooks';
import { useToast } from '@flick-tech/ui';

import { useGetSocialGroups } from './useGetSocialGroups';
import { useMyActiveSocialGroup } from './useMyActiveSocialGroup';
import { useSetActiveSocialGroup } from './useSetActiveSocialGroup';

export function useActiveIGUsername() {
  const { activeSocialGroup, ...rest } = useMyActiveSocialGroup();

  return {
    activeIGUsername: activeSocialGroup?.igAccount?.data?.username,
    ...rest,
  };
}

export function useSetActiveIGUsername() {
  const { socialGroups } = useGetSocialGroups();
  const { setActiveSocialGroup } = useSetActiveSocialGroup();

  const toast = useToast();

  const [isLoading, { on, off }] = useBoolean();

  const handler = useCallback(
    async (newIGUsername: string) => {
      on();

      try {
        const socialGroup = socialGroups.find(
          (sg) => sg.igAccount?.data?.username === newIGUsername,
        );

        if (!socialGroup) {
          throw new Error(
            'Failed to set active IG account as you do not have access to it. Please contact support.',
          );
        }

        await setActiveSocialGroup({
          socialGroupId: socialGroup.id,
        });
      } catch (error) {
        Logger.error(error);
        toast({
          title: 'Error',
          status: 'error',
          description: error.message,
        });
      }

      off();
    },
    [off, on, setActiveSocialGroup, socialGroups, toast],
  );

  return {
    setActiveIGUsername: handler,
    isLoading,
  };
}
