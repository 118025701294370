import { CreateBlitzClientParams } from './createBlitzClient.types';
import { createQueryUtils } from './createQueryUtils';
import { createUseMutation } from './createUseMutation';
import { createUseQuery } from './createUseQuery';

export const createBlitzClient = (params: CreateBlitzClientParams) => {
  const { invalidateQuery, createSetQueryData } = createQueryUtils();

  return {
    useQuery: createUseQuery({
      ...params,
      createSetQueryData,
    }),
    useMutation: createUseMutation(params),
    invalidateQuery,
  };
};
