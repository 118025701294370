export const breakpoints = {
  xs: 0,
  s: '375px',
  sm: '600px',
  mobile: '769px',
  md: '960px',
  desktop: '1024px',
  lg: '1280px',
  xl: '1920px',
};

Object.assign(breakpoints, Object.values(breakpoints));

export type Breakpoints = keyof typeof breakpoints;

export type BreakpointsMap = typeof breakpoints;

export default breakpoints;
