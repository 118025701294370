import { IPostsTrackedLimit } from '@flick-tech/shared-types';
import { Box, BoxProps, Flex, Text } from '@flick-tech/ui';

export interface PostsTrackedProgressProps {
  postsTrackedThisPeriod: number;
  postsTrackedLimit:
    | Pick<IPostsTrackedLimit, 'limit' | 'isUnlimited'>
    | undefined;
}

export function PostsTrackedProgress({
  postsTrackedThisPeriod,
  postsTrackedLimit,
  ...rest
}: PostsTrackedProgressProps & BoxProps) {
  const isUnlimited = postsTrackedLimit?.isUnlimited ?? false;
  const limit = postsTrackedLimit?.limit ?? 0;

  const trackedText = isUnlimited
    ? postsTrackedThisPeriod
    : `${postsTrackedThisPeriod}/${limit}`;

  const percentageTracked =
    isUnlimited || limit === 0 ? 1 : postsTrackedThisPeriod / limit;

  return (
    <Box pt={2} {...rest}>
      <Flex align="center">
        <Text fontSize="sm" mr={1} fontWeight={600}>
          {trackedText}
        </Text>{' '}
        <Text fontSize="sm" color="gray.500">
          posts tracked this month
        </Text>
      </Flex>
      {!isUnlimited && (
        <Box
          mt={2}
          position="relative"
          w="100%"
          h={1}
          borderRadius="md"
          overflow="hidden"
          bg="gray.200"
        >
          <Box bg="brand.500" width={`${100 * percentageTracked}%`} h="100%" />
        </Box>
      )}
    </Box>
  );
}
