import { NoTypename } from '@flick-tech/shared-types';

import { isObject } from './assertion';

export const removeTypename = <T extends {}>(
  value?: T | null,
): NoTypename<T> | null | undefined => {
  if (isObject(value)) {
    const newValue = { ...value };
    delete newValue['__typename'];
    Object.keys(newValue).forEach((key) => {
      // This is needed to correctly deeply compare those later (`areFiltersEqual`)
      if (newValue[key] === null || newValue[key] === undefined) {
        delete newValue[key];
      } else {
        newValue[key] = removeTypename(newValue[key]);
      }
    });
    return newValue as any;
  }
  return value;
};

export const diffInDaysRoundedUp = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
