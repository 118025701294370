import getUnixTime from 'date-fns/getUnixTime';

import { Logger } from '@flick-tech/logger';
import { Constants, getFirstName } from '@flick-tech/shared-common';

import { IdentifyProperties } from './itly';

const shouldSkip = !Constants.isProd;

type CIO = {
  identify: (properties: Record<string, any>) => void;
};

declare global {
  interface Window {
    _cio?: CIO;
  }
}

export class CustomerIOBrowser {
  static identify(userId: string, properties: IdentifyProperties) {
    const isValidUser = userId && properties.email;

    if (shouldSkip || !isValidUser) {
      return;
    }

    if (!window._cio) {
      Logger.error(
        new Error('Failed to identify CIO user as the SDK is not loaded.'),
      );
      return;
    }

    const formattedProperties: {
      created_at?: number;
    } & IdentifyProperties = {
      ...properties,
      id: userId,
      userId,
      email: 'user@domain.com', // Email of the currently signed in user.
    };

    if (properties.FirstSeen) {
      formattedProperties.created_at = getUnixTime(
        new Date(properties.FirstSeen),
      );
    }

    if (properties.name) {
      formattedProperties.name = properties.name;
      formattedProperties.firstName = getFirstName(properties.name);
      formattedProperties.lastName = getFirstName(properties.name);
    }

    window._cio?.identify(formattedProperties);
  }
}
