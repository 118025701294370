import { memo, ReactNode } from 'react';

import { Box, Flex, Grid } from '@flick-tech/ui';
import { Skeleton } from '@flick-tech/ui';

import { AppSkeletonLoader } from './AppSkeletonLoader';

interface AppShellSkeletonProps {
  body?: ReactNode;
  navbarContent?: ReactNode;
}

export const AppShellSkeleton = memo(
  ({ navbarContent, body }: AppShellSkeletonProps) => {
    return (
      <AppSkeletonLoader>
        <Grid templateRows="72px 1fr" w="100%" h="100vh">
          <Flex
            align="center"
            borderBottom="1px solid"
            borderBottomColor="gray.100"
            px="20px"
          >
            {navbarContent ?? <Box />}
            <Box ml="auto" w="40px" h="40px" borderRadius="50%" bg="gray.100" />
          </Flex>
          <Box w="100%" h="100%">
            {body ?? (
              <Skeleton speed={1.2} w="100%" h="100%" borderRadius={0} />
            )}
          </Box>
        </Grid>
      </AppSkeletonLoader>
    );
  },
);

export function AppShellSkeletonLoaderWithSearch({
  children,
}: {
  children?: ReactNode;
}) {
  return (
    <AppShellSkeleton
      body={children}
      navbarContent={
        <Skeleton
          speed={1.2}
          w={{
            base: '100px',
            md: '400px',
          }}
          h="40px"
          borderRadius="12px"
        />
      }
    />
  );
}
