import styled from '@emotion/styled';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import {
  ComponentProps,
  forwardRef,
  MutableRefObject,
  ReactNode,
  useState,
} from 'react';

import { Box, BoxProps } from '../layout';

import { HeatMapCell } from './components/HeatMapCell';
import { useTickAxis } from './components/Tick';
import { heatMapPalette, transparent } from './const';
import { tooltipWrapperStyles } from './Tooltip';

const Wrapper = forwardRef<HTMLDivElement | null | undefined, BoxProps>(
  (props, ref) => <Box ref={ref} position="relative" w="100%" {...props} />,
);

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  svg {
    overflow: visible;
  }
`;

interface FlickHeatMapProps extends ComponentProps<typeof ResponsiveHeatMap> {
  data: any[];
  keys: string[];
  indexBy: string;
  height?: number;
  tooltip?: ReactNode;
  className?: string;
  marginLeft?: number;
}

export const FlickHeatMap = forwardRef(
  (
    {
      data,
      keys,
      indexBy,
      padding = 10,
      height = 460,
      marginLeft = 0,
      className,
      ...rest
    }: FlickHeatMapProps,
    ref: MutableRefObject<HTMLDivElement>,
  ) => {
    const [leftTickWidth, setLeftTickWidth] = useState(68); // This value is just what it usually end up being, gets recalculated later
    const [bottomTickHeight, setBottomTickHeight] = useState(20);

    const onLeftTickSizeChange = ({ width }) => {
      setLeftTickWidth(width);
    };

    const onBottomTickSizeChange = ({ height }) => {
      setBottomTickHeight(height);
    };

    const margin = {
      top: -padding,
      right: -padding,
      bottom: bottomTickHeight,
      left: leftTickWidth + marginLeft,
    };

    return (
      <Wrapper ref={ref} style={{ height }} className={className}>
        <AbsoluteWrapper>
          <ResponsiveHeatMap
            data={data}
            keys={keys}
            indexBy={indexBy}
            colors={heatMapPalette}
            margin={margin}
            axisBottom={useTickAxis({
              position: 'bottom',
              offset: { y: 0 },
              onSizeChange: onBottomTickSizeChange,
            })}
            axisLeft={useTickAxis({
              position: 'left',
              offset: { x: 0 },
              onSizeChange: onLeftTickSizeChange,
            })}
            axisRight={null}
            axisTop={null}
            theme={{
              tooltip: {
                container: {
                  ...tooltipWrapperStyles,
                  transform: 'translateY(-118%)',
                },
              },
            }}
            padding={padding}
            cellShape={HeatMapCell}
            labelTextColor={transparent}
            animate={false}
            hoverTarget="cell"
            cellHoverOthersOpacity={0.33}
            {...rest}
          />
        </AbsoluteWrapper>
      </Wrapper>
    );
  },
);
