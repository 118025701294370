export const tooltipStatSpacing = 4;

// The way a tooltip is styled for barchart and choropleth is very different
// so to keep things centralised we need to do this
export const tooltipWrapperStyles = {
  borderRadius: 7,
  padding: '10px 14px',
  paddingBottom: 10 - tooltipStatSpacing,
  backgroundColor: '#fff',
  boxShadow: '0px 0 4px rgba(0,0,0,0.25)',
};
