import { Logger, SkipSentryError } from '@flick-tech/logger';
import { Constants } from '@flick-tech/shared-common';

interface GQLError {
  message: string;
  path: string[];
  extensions: {
    shouldSkipSentry?: boolean;
    code?: string;
    exception?: {
      shouldSkipSentry?: boolean;
    };
  };
}

interface GQLResult<TData> {
  errors?: GQLError[];
  data?: TData;
}

const getAuthHeaders = (
  accessToken: string | undefined,
): Record<string, string> => {
  if (!accessToken || typeof accessToken !== 'string') {
    return {};
  }

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

interface GraphqlFetchPayload<TVariables> {
  variables: TVariables;
  accessToken?: string;
  query: string;
}

export class GraphQLError extends SkipSentryError {
  code?: string;
}

export async function graphqlFetch<TData, TVariables>({
  accessToken,
  variables,
  query,
}: GraphqlFetchPayload<TVariables>): Promise<TData> {
  try {
    const { data, errors } = (await fetch(`${Constants.apiBase}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeaders(accessToken),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    }).then((res) => res.json())) as GQLResult<TData>;

    if (errors) {
      const { message, extensions } = errors[0];

      const error = new GraphQLError(message || 'An unknown error occurred');

      error.shouldSkipSentry = Boolean(extensions?.shouldSkipSentry);

      error.code = errors[0]?.extensions?.code;

      throw error;
    }

    return data;
  } catch (error) {
    Logger.error(error);
    throw error;
  }
}
