import { AsProp, FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, ChakraProps, PropsOf } from '@flick-tech/theme-new';

export interface KbdProps
  extends Omit<PropsOf<'kbd'>, 'color'>,
    ChakraProps,
    AsProp {}

/**
 * Semantic component to render a keyboard shortcut
 * within an application.
 *
 * @example
 *
 * ```jsx
 * <Kbd>⌘ + T</Kbd>
 * ```
 *
 * @see Docs https://chakra-ui.com/kbd
 */
export const Kbd: FC<KbdProps> = chakra('kbd', { themeKey: 'Kbd' });

if (__DEV__) {
  Kbd.displayName = 'Kbd';
}
