import {
  ChoroplethTooltip as ChoroplethTooltipType,
  ResponsiveChoropleth,
} from '@nivo/geo';
import { MutableRefObject, useRef } from 'react';

import { useComponentSize } from '@flick-tech/shared-hooks';
import { textColorRaw } from '@flick-tech/theme-new';

import { choroplethBorder, choroplethPalette, colorBackground } from './const';
import { countryFeatures } from './countryFeatures';
import {
  TooltipStat,
  TooltipStatsWrapper,
  tooltipWrapperStyles,
} from './Tooltip';

export const ChoroplethTooltip: ChoroplethTooltipType = ({
  feature: { data, color, label },
}) => {
  if (!data) {
    // Do not display tooltip for countries with no data
    return null;
  }
  return (
    <TooltipStatsWrapper style={tooltipWrapperStyles}>
      <TooltipStat label={label} color={color} value={data.value} />
    </TooltipStatsWrapper>
  );
};

// when width == 727 then scale: 98 & marginTop: 84 (of 400 height)
// when width == 628 then scale: 98 & marginTop: 84 (of 400 height)
// when width == 552 then scale: 87 & marginTop: 72 (of 400 height)
// when width == 320 then scale: 50 & marginTop: 40 (of 400 height)
const dist = 628 - 320;

function useSizeProps(ref: MutableRefObject<HTMLDivElement>) {
  const { width } = useComponentSize(ref);

  const distPerc = (628 - width) / dist;
  const scale = Math.min(98, Math.round(98 - 48 * distPerc));
  const marginTop = Math.min(84, Math.round(84 - 44 * distPerc));

  return { scale, marginTop };
}

const legendPadding = 8;

interface FlickChoroplethProps {
  height: number;
  data: any[];
  maxValue?: number;
}

export function FlickChoropleth({
  data,
  height,
  maxValue = 150,
}: FlickChoroplethProps) {
  const wrapperRef = useRef<HTMLDivElement>();
  const sizeProps = useSizeProps(wrapperRef);

  return (
    <div style={{ height }} ref={wrapperRef}>
      <ResponsiveChoropleth
        data={data}
        features={countryFeatures}
        margin={{ top: sizeProps.marginTop, right: 0, bottom: 0, left: 0 }}
        colors={choroplethPalette}
        domain={[0, maxValue]}
        unknownColor={colorBackground}
        label="properties.name"
        valueFormat=".2s"
        projectionScale={sizeProps.scale}
        projectionTranslation={[0.5, 0.5]}
        projectionRotation={[-10, 0, 0]}
        borderWidth={0.5}
        borderColor={choroplethBorder}
        tooltip={ChoroplethTooltip}
        // @ts-expect-error @types/nivo__geo is outdated, and this prop missing
        legends={[
          {
            anchor: 'bottom-left',
            direction: 'column',
            justify: true,
            translateX: legendPadding,
            translateY: -sizeProps.marginTop - legendPadding,
            itemsSpacing: 0,
            itemWidth: 94,
            itemHeight: 18,
            itemDirection: 'left-to-right',
            itemTextColor: textColorRaw,
            itemOpacity: 0.85,
            symbolSize: 18,
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: textColorRaw,
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
