import {
  AVAILABLE_SOCIALS,
  getNumberOfLinkedSocials,
} from '@flick-tech/shared-common';
import { useBoolean, useHasFeatureFlag } from '@flick-tech/shared-hooks';
import { ISocialGroupFieldsFragment } from '@flick-tech/shared-types';
import { useSocialGroupIGAccountAuthState } from '@flick-tech/social-group-hooks';
import { Box, BoxProps, Flex, Text } from '@flick-tech/ui';
import { Skeleton, SkeletonCircle, SkeletonText } from '@flick-tech/ui';

import { SocialGroupsSummaryCardAvatars } from '../SocialGroupsSummaryCardAvatars';

import { LostConnectionInfoCard } from './LostConnectionInfoCard';
import {
  PostsTrackedProgress,
  PostsTrackedProgressProps,
} from './PostsTrackedProgress';

export interface SocialGroupCardProps extends Omit<BoxProps, 'onClick'> {
  isActive?: boolean;
  isInteractive?: boolean;
  hideUntrackedIndicator?: boolean;
  hasAccessToHashtagTracking: boolean;
  postsTrackedLimit: PostsTrackedProgressProps['postsTrackedLimit'] | undefined;
  socialGroup: ISocialGroupFieldsFragment;
  onClick?: () => Promise<void> | void;
}

export function SocialGroupCardSkeleton(props: BoxProps) {
  return (
    <Box
      w="100%"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.100"
      p={3}
      {...props}
    >
      <Flex align="center">
        <SkeletonCircle w="48px" h="48px" />
        <Box ml={2}>
          <SkeletonText mb={1} w="60%" h={2} />
          <SkeletonText w="30%" h={2} />
        </Box>
      </Flex>
      <Box mt={3}>
        <Skeleton w="60%" h={2} mt={3} />
        <Skeleton w="100%" h={2} mt={1} />
      </Box>
    </Box>
  );
}

export function SocialGroupCard({
  isActive,
  socialGroup,
  isInteractive,
  hideUntrackedIndicator,
  hasAccessToHashtagTracking,
  postsTrackedLimit,
  onClick,
  ...rest
}: SocialGroupCardProps) {
  const { authState, isLoading: isLoadingAuthState } =
    useSocialGroupIGAccountAuthState({
      socialGroupId: socialGroup.id,
    });
  const hasLostConnection = !isLoadingAuthState && !authState?.isValid;
  const hasAccessToSocialGroups = useHasFeatureFlag('socialGroups');

  let bg = 'white';

  if (isActive) {
    bg = 'rgba(245, 249, 255, 0.5)';
  }

  const igAccount = socialGroup.igAccount?.data;
  const fbAccount = socialGroup.facebook;

  const linkedSocialsNumber = getNumberOfLinkedSocials(socialGroup);

  const [isLoading, { on, off }] = useBoolean();
  const handleClick = async () => {
    if (isLoading || !onClick) {
      return;
    }

    on();

    try {
      await onClick();
    } finally {
      off();
    }
  };

  return (
    <Box
      data-active={!!isActive}
      data-testid="social-group-card"
      w="100%"
      borderRadius="md"
      border="1px solid"
      borderColor={isActive ? 'brand.500' : 'blackAlpha.200'}
      bg={bg}
      maxW={350}
      _hover={
        isInteractive
          ? {
              bg: isActive ? 'brand.50' : 'gray.50',
              cursor: isLoading ? 'wait' : 'pointer',
            }
          : {}
      }
      p="12px"
      onClick={handleClick}
      {...rest}
    >
      <Flex align="center" justifyContent="space-between">
        <Box overflow="hidden">
          <Text
            textAlign="left"
            color={isActive ? 'brand.500' : 'gray.900'}
            fontWeight={600}
            lineHeight="1.25rem"
            mb={1}
            ellipsisOverflow
          >
            {hasAccessToSocialGroups
              ? socialGroup.name
              : socialGroup.igAccount?.data?.name}
          </Text>
          <Text
            textAlign="left"
            color="gray.500"
            lineHeight="1.25rem"
            ellipsisOverflow
          >
            {hasAccessToSocialGroups
              ? `${linkedSocialsNumber}/${AVAILABLE_SOCIALS.length} Socials`
              : socialGroup.igAccount?.data?.username}
          </Text>
        </Box>
        {(igAccount || hasAccessToSocialGroups) && (
          <SocialGroupsSummaryCardAvatars
            igAccount={
              igAccount
                ? {
                    username: igAccount.username,
                    profilePicture: igAccount.profilePicture || undefined,
                    lostConnection: hasLostConnection,
                  }
                : undefined
            }
            facebook={
              fbAccount
                ? {
                    profilePicture: fbAccount.profilePicture,
                    username: fbAccount.username,
                  }
                : undefined
            }
          />
        )}
      </Flex>
      {!hasAccessToSocialGroups && hasLostConnection && (
        <LostConnectionInfoCard mt={4} mb={2} />
      )}
      {!hasAccessToHashtagTracking && !hideUntrackedIndicator && (
        <Text
          mt={2}
          w="100%"
          textAlign="center"
          color="red.500"
          opacity={hasLostConnection ? 0.5 : 1}
        >
          Not tracked
        </Text>
      )}
      {igAccount?.postsTrackedThisPeriod !== null &&
        igAccount?.postsTrackedThisPeriod !== undefined &&
        hasAccessToHashtagTracking && (
          <PostsTrackedProgress
            mt="auto"
            opacity={hasLostConnection ? 0.5 : 1}
            postsTrackedThisPeriod={igAccount?.postsTrackedThisPeriod}
            postsTrackedLimit={postsTrackedLimit}
          />
        )}
    </Box>
  );
}
