import { serialize } from 'superjson';

export const getQueryKeyFromUrlAndParams = (url: string, params: unknown) => {
  const queryKey = [url];

  const args = typeof params === 'function' ? (params as Function)() : params;

  queryKey.push(serialize(args) as any);

  return queryKey as [string, any];
};

export function getQueryKey<TInput>(path: string, params?: TInput) {
  return getQueryKeyFromUrlAndParams(path, params);
}
