import { __DEV__, createContext } from '@flick-tech/shared-utils';
import { chakra, PropsOf, ThemingProps } from '@flick-tech/theme-new';

export type ButtonGroupProps = PropsOf<typeof chakra.div>;

const [ButtonGroupCtxProvider, useButtonGroup] = createContext<ThemingProps>({
  strict: false,
  name: 'ButtonGroupContext',
});

export { useButtonGroup };

export const ButtonGroup = (props: ButtonGroupProps) => {
  const { size, colorScheme, variant, ...rest } = props;

  const css = {
    '> *:first-of-type:not(:last-of-type)': { borderRightRadius: 0 },
    '> *:not(:first-of-type):not(:last-of-type)': { borderRadius: 0 },
    '> *:not(:first-of-type):last-of-type': { borderLeftRadius: 0 },
  };

  return (
    <ButtonGroupCtxProvider value={{ size, colorScheme, variant }}>
      <chakra.div
        display="flex"
        role="group"
        whiteSpace="nowrap"
        sx={css as any}
        {...rest}
      />
    </ButtonGroupCtxProvider>
  );
};

if (__DEV__) {
  ButtonGroup.displayName = 'ButtonGroup';
}
