import { memo, useCallback } from 'react';

import { Tracking } from '@flick-tech/client-tracking';
import { ProfilePageSection } from '@flick-tech/shared-common';
import {
  useHasFeatureFlag,
  useNavigateToProfileSection,
} from '@flick-tech/shared-hooks';
import { ISocialGroupFieldsFragment } from '@flick-tech/shared-types';
import { useSocialGroups } from '@flick-tech/social-group-hooks';
import {
  Flex,
  Heading,
  Icon,
  SkeletonText,
  Spacer,
  Stack,
  Text,
} from '@flick-tech/ui';

import {
  SocialGroupCard,
  SocialGroupCardProps,
  SocialGroupCardSkeleton,
} from '../../SocialGroupCard';

import {
  SOCIAL_ACCOUNTS_LIST_WIDTH,
  SocialGroupListEmptyState,
} from './SocialGroupListEmptyState';

interface SocialGroupListProps {
  onSelect: (socialGroup: ISocialGroupFieldsFragment) => Promise<void> | void;
  hasAccessToHashtagTracking: boolean;
  postsTrackedLimit: SocialGroupCardProps['postsTrackedLimit'] | undefined;
  onNavigateToWorkspace?: () => void;
  onNavigateToLinkSocial?: () => void;
}

const iconSize = {
  base: 5,
  xl: 6,
};

export const SocialGroupList = memo(function SocialGroupList({
  onSelect,
  hasAccessToHashtagTracking,
  postsTrackedLimit,
  onNavigateToLinkSocial,
  onNavigateToWorkspace,
}: SocialGroupListProps) {
  const hasAccessToSocialGroups = useHasFeatureFlag('socialGroups');

  const { activeSocialGroup, socialGroups, loading } = useSocialGroups({
    linkedOnly: !hasAccessToSocialGroups,
    sortBy: 'name',
  });

  const navigate = useNavigateToProfileSection();

  const manageSocialGroups = useCallback(() => {
    Tracking.trackEvent('Clicked Button', {
      type: 'Profile Switcher Manage Accounts',
    });

    navigate(
      hasAccessToSocialGroups
        ? ProfilePageSection.AccountSettings
        : ProfilePageSection.InstagramAccounts,
    );

    onNavigateToWorkspace && onNavigateToWorkspace();
  }, [navigate, onNavigateToWorkspace, hasAccessToSocialGroups]);

  const editLinkedSocials = useCallback(() => {
    Tracking.trackEvent('Clicked Button', {
      type: 'Profile Switcher Link Accounts',
    });

    navigate(ProfilePageSection.AccountSettings);
    onNavigateToLinkSocial && onNavigateToLinkSocial();
  }, [navigate, onNavigateToLinkSocial]);

  const manageSocialGroupsButton = (
    <Flex
      as="button"
      role="group"
      direction="row"
      onClick={manageSocialGroups}
      align="center"
      p="10px"
      borderRadius="8px"
      w="100%"
      sx={{ '&:hover, &:focus-visible': { backgroundColor: 'bg.base' } }}
    >
      <>
        <Text
          color="text.light"
          fontSize={{
            base: '16px',
            xl: '18px',
          }}
          lineHeight={1.3}
          fontWeight="medium"
          whiteSpace="pre"
        >
          Manage {hasAccessToSocialGroups ? 'Social Groups' : 'IG Accounts'}
        </Text>
        <Spacer />
        <Icon
          icon="SettingsRounded"
          h={iconSize}
          w={iconSize}
          flexShrink={0}
          _groupHover={{ color: 'text.light' }}
        />
      </>
    </Flex>
  );

  if (loading) {
    return (
      <Stack p="12px" maxH="443px" minW={SOCIAL_ACCOUNTS_LIST_WIDTH}>
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <Heading
            paddingY="0px"
            paddingX="0px"
            variant="h4"
            fontSize="18px"
            fontWeight="medium"
          >
            Social Groups
          </Heading>
          <SkeletonText w="20px" noOfLines={1} />
        </Flex>
        <SocialGroupCardSkeleton w={SOCIAL_ACCOUNTS_LIST_WIDTH} />
        <SocialGroupCardSkeleton w={SOCIAL_ACCOUNTS_LIST_WIDTH} />
        {manageSocialGroupsButton}
      </Stack>
    );
  }

  const hasLinkedSocialGroups = socialGroups?.length > 0;

  if (
    !hasLinkedSocialGroups ||
    (hasAccessToSocialGroups &&
      socialGroups?.length === 1 &&
      ![activeSocialGroup.igAccount, activeSocialGroup.facebook].some(Boolean))
  ) {
    return (
      <SocialGroupListEmptyState
        onLinkingSocials={editLinkedSocials}
        renderBottomContent={() => manageSocialGroupsButton}
        numberOfSocialGroups={socialGroups?.length}
      />
    );
  }

  return (
    <Stack
      p="12px"
      maxH="443px"
      minW={SOCIAL_ACCOUNTS_LIST_WIDTH}
      overflowY="auto"
      data-testid="layout--ig-switcher--list"
    >
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Heading
          paddingY="0px"
          paddingX="0px"
          variant="h4"
          fontSize="18px"
          fontWeight="medium"
        >
          {hasAccessToSocialGroups ? 'Social Groups' : 'IG Accounts'}
        </Heading>
        <Text fontSize="18px" fontWeight="medium" as="div" color="text.lighter">
          {socialGroups.length}
        </Text>
      </Flex>
      {socialGroups.map((sg) => {
        return (
          <SocialGroupCard
            postsTrackedLimit={postsTrackedLimit}
            hasAccessToHashtagTracking={hasAccessToHashtagTracking}
            isActive={activeSocialGroup.id === sg.id}
            onClick={() => onSelect(sg as ISocialGroupFieldsFragment)}
            key={sg.id}
            socialGroup={sg}
            isInteractive
          />
        );
      })}
      {manageSocialGroupsButton}
    </Stack>
  );
});
