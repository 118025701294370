import { useCallback, useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { StateManager } from 'react-select/src/stateManager';
import { ValueType } from 'react-select/src/types';

import { __DEV__ } from '@flick-tech/shared-utils';
import { FormControlWrapper } from '../form-control';
import { BoxProps } from '../layout';

import { Select } from './Select';
import { DropdownInputProps, OptionField } from './Select.types';

export function DropdownInput<T>({
  name,
  fieldProps,
  options,
  hasError,
  fullWidth = true,
  selectRef,
  ...rest
}: DropdownInputProps<T>) {
  const ref = useRef<StateManager | null>();

  return (
    <Select
      {...rest}
      options={options}
      selectRef={selectRef || ref}
      menuShouldScrollIntoView={false}
      creatableMulti={(item) => item}
      fullWidth={fullWidth}
      openMenuOnFocus
    />
  );
}

export interface FinalFormDropdownInput
  extends DropdownInputProps,
    FieldRenderProps<HTMLInputElement> {
  transformValueOnChange?: (option: OptionField) => OptionField | string;
}

interface GetValueProps {
  options: DropdownInputProps['options'];
  isMulti?: DropdownInputProps['isMulti'];
  value: OptionField | OptionField[] | string | string[];
  wrapperProps?: BoxProps;
}

const defaultTransform = (option: OptionField) => option.value;

const extractValue = (option: OptionField | string) => {
  if (typeof option === 'string') return option;

  return option.value;
};

export function FinalFormDropdownInput({
  meta,
  input,
  hideTouchedError,
  helperText,
  label,
  hideHelperText,
  fullWidth,
  isMulti,
  mb, // TODO: Extract spacing props
  mt, // TODO: Extract spacing props
  options,
  transformValueOnChange = defaultTransform,
  wrapperProps = {},
  ...rest
}: FinalFormDropdownInput) {
  const { name, onChange } = input;
  const { error, submitFailed } = meta;
  const showError =
    ((meta.touched && !hideTouchedError) || submitFailed) && error;

  const getValueFormatted = useCallback(
    ({ options, isMulti, value: valueUnformatted }: GetValueProps) => {
      if (isMulti) {
        // @ts-ignore
        if (!valueUnformatted || valueUnformatted?.length === 0) return null;

        const value = (valueUnformatted as string[]).map(extractValue);

        return options.filter((option) => value.includes(option.value));
      }

      return options.filter(
        (option) =>
          option.value ===
          extractValue(valueUnformatted as OptionField | string),
      )[0];
    },
    [],
  );

  const value = getValueFormatted({
    value: input.value as any,
    isMulti,
    options,
  });

  const handleOnChange = useCallback(
    (newValue: OptionField | OptionField[]) => {
      if (isMulti) {
        const newMultiValue = newValue as OptionField[];

        if (!newMultiValue || newMultiValue.length === 0) {
          onChange(undefined);
        } else {
          onChange(newMultiValue.map(transformValueOnChange));
        }
      } else {
        onChange(transformValueOnChange(newValue as OptionField));
      }
    },
    [isMulti, onChange, transformValueOnChange],
  );

  return (
    <FormControlWrapper
      width={fullWidth ? '100%' : 'auto'}
      showError={showError}
      error={error}
      helperText={hideHelperText ? undefined : helperText}
      label={label}
      name={name}
      mb={mb}
      mt={mt}
      {...wrapperProps}
    >
      <DropdownInput
        {...rest}
        {...input}
        options={options}
        value={value as ValueType<OptionField, false> | OptionField}
        onChange={handleOnChange}
      />
    </FormControlWrapper>
  );
}

if (__DEV__) {
  FinalFormDropdownInput.displayName = 'FinalFormDropdownInput';
}
