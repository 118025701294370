import { Box, BoxProps } from '../layout';
import { Fade } from '../transition';

export interface BackdropProps extends BoxProps {
  isInvisible?: boolean;
  isCentered?: boolean;
  isOpen: boolean;
}

export function Backdrop({
  isOpen,
  isCentered,
  isInvisible,
  ...rest
}: BackdropProps) {
  return (
    <Fade in={isOpen}>
      {(styles) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems={isCentered ? 'center' : 'flex-start'}
          position="fixed"
          left={0}
          zIndex="overlay"
          top={0}
          bottom={0}
          right={0}
          width="100%"
          height="100%"
          backgroundColor={isInvisible ? 'transparent' : 'rgba(0,0,0,0.4)'}
          _hover={{
            cursor: rest.onClick ? 'pointer' : 'auto',
          }}
          style={{
            ...styles,
            WebkitTapHighlightColor: 'transparent',
          }}
          {...rest}
        />
      )}
    </Fade>
  );
}
