import { chakra } from '@flick-tech/theme-new';

import { Button } from '../../../button';
import { Image } from '../../../image';
import { Box, Flex, FlexProps, Text } from '../../../layout';
import { useMedia } from '../../../media-query';

import LeftCard from './LeftCard.webp';
import MiddleCard from './MiddleCard.webp';
import RightCard from './RightCard.webp';

const CrossIcon = () => (
  <Flex justify="center" align="center" width="20px" height="20px">
    <chakra.svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7501 0.520028C10.4251 0.195028 9.9001 0.195028 9.5751 0.520028L5.5001 4.58669L1.4251 0.511694C1.1001 0.186694 0.575098 0.186694 0.250098 0.511694C-0.0749023 0.836694 -0.0749023 1.36169 0.250098 1.68669L4.3251 5.76169L0.250098 9.83669C-0.0749023 10.1617 -0.0749023 10.6867 0.250098 11.0117C0.575098 11.3367 1.1001 11.3367 1.4251 11.0117L5.5001 6.93669L9.5751 11.0117C9.9001 11.3367 10.4251 11.3367 10.7501 11.0117C11.0751 10.6867 11.0751 10.1617 10.7501 9.83669L6.6751 5.76169L10.7501 1.68669C11.0668 1.37003 11.0668 0.836694 10.7501 0.520028Z"
        fill="#E53E3E"
      />
    </chakra.svg>
  </Flex>
);

interface FeatureDisabled extends FlexProps {
  text: string;
}
const FeatureDisabled = ({ text, ...props }: FeatureDisabled) => (
  <Flex alignItems="center" {...props}>
    <CrossIcon />
    <Text
      pl={2}
      fontWeight={400}
      fontSize="16px"
      textAlign="center"
      lineHeight="130%"
    >
      {text}
    </Text>
  </Flex>
);

export function RequiresSubscriptionReactivationEmptyState({
  goToBilling,
}: {
  goToBilling: () => void;
}) {
  const isAboveMedium = useMedia({ min: 'md' });
  return (
    <Flex
      direction="column"
      height="100%"
      width="100%"
      align="center"
      justify="center"
    >
      <Flex>
        <Image height="300px" src={LeftCard} />
        {isAboveMedium ? <Image height="300px" src={MiddleCard} /> : null}
        <Image height="300px" src={RightCard} />
      </Flex>
      <Box width={3 / 4}>
        <Flex flexDir="column" justify="center" align="center">
          <Text
            fontWeight={700}
            fontSize="24px"
            color="text.black"
            textAlign="center"
            pb={1}
          >
            Page temporarily locked due to inactive subscription 🔒
          </Text>
          <Text
            fontWeight={400}
            fontSize="20px"
            lineHeight="130%"
            textAlign="center"
            color="text.light"
            mb="2px"
          >
            This is because your payment method has failed or because you
            cancelled your account and are yet to reactivate it.
          </Text>
          <Box padding={4}>
            <FeatureDisabled text="Hashtag performance is not being tracked" />
            <FeatureDisabled
              text="Access to feed, story and IGTV analytics disabled"
              mt={2}
            />
            <FeatureDisabled
              text="Best times to post cannot be accessed"
              mt={2}
            />
          </Box>
          <Button
            mt={2}
            size="lg"
            leftIcon="ManageAccounts"
            onClick={goToBilling}
          >
            Re-activate Subscription
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}
