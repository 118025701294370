import React, { useEffect } from 'react';

import { Tracking } from '@flick-tech/client-tracking';
import {
  useRefetchBiling,
  useRefetchTeamMembers,
  useRefetchUser,
} from '@flick-tech/core-hooks';
import { useDisclosure } from '@flick-tech/shared-hooks';
import {
  Box,
  BoxProps,
  Portal,
  useMutationHook,
  usePopper,
} from '@flick-tech/ui';

import {
  IWorkspace,
  useGetMyWorkspacesQuery,
} from './hooks/useGetMyWorkspaces.generated';
import { w0, w1, w2, w3, w4, w5 } from './avatars';
import { WorkspaceList } from './WorkspaceList';
import { WorkspaceSwitcherAvatar } from './WorkspaceSwitcherAvatar';

const avatarsList = [w0, w1, w2, w3, w4, w5];

interface WorkspaceSwitcherProps extends BoxProps {
  activeWorkspace: Pick<IWorkspace, 'id' | 'name'> | undefined | null;
  onSwitch?: (workspace: IWorkspace) => Promise<void>;
  isLoading?: boolean;
  showExpandedVersion: boolean;
}

const getWorkspaceAvatar = (id, availableWorkspaces): string => {
  const workspace = availableWorkspaces.find((w) => w.id === id);
  return workspace ? workspace.image : '';
};

export function WorkspaceSwitcher({
  activeWorkspace,
  onSwitch,
  isLoading: isLoadingFromProps,
  showExpandedVersion,
  ...rest
}: WorkspaceSwitcherProps) {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const {
    isLoading: isLoadingWorkspaces,
    data,
    refetch: refetchWorkspaces,
  } = useGetMyWorkspacesQuery();

  const isLoading = isLoadingFromProps || isLoadingWorkspaces;

  const workspaces = (data?.me?.workspaces as IWorkspace[]) || [];

  const workspacesWithImages = workspaces.map((workspace, i: number) => {
    const avatarIndex = i % avatarsList.length;

    return {
      ...workspace,
      image: avatarsList[avatarIndex],
    };
  });

  const refetchUser = useRefetchUser();
  const refetchBiling = useRefetchBiling();
  const refetchTeamMembers = useRefetchTeamMembers();

  const { popper, reference } = usePopper({
    placement: 'right-end',
    forceUpdate: isOpen,
  });

  const cardRef = popper.ref;

  // Effect to close this filter card on outside click
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // if the card is not open, don't do anything
      if (!isOpen) return;

      // if the click is within the card, don't do anything
      if (
        cardRef.current?.contains(target) ||
        reference.ref.current?.contains(target)
      ) {
        return;
      }

      onClose();
    };
    // add the event listener for click
    document.addEventListener('click', handleDocumentClick, {
      capture: true,
    });

    return () => {
      // remove the event listener, to avoid memory leak
      document.removeEventListener('click', handleDocumentClick, {
        capture: true,
      });
    };
    // Missing deps are refs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef, isOpen, onClose]);

  const { handler: onSelect } = useMutationHook({
    func: async (workspace: IWorkspace) => {
      const promises: Promise<any>[] = [refetchWorkspaces()];

      if (onSwitch) {
        promises.push(onSwitch(workspace));
      }

      promises.push(refetchBiling(), refetchUser(), refetchTeamMembers());

      Tracking.trackEvent('Switched Workspace', {
        workspace: workspace.name,
        workspaceId: workspace.id,
        userRole: workspace.myRole,
      });

      await Promise.all(promises);

      onClose();
    },
  });

  return (
    <>
      <Portal>
        <Box
          hidden={!isOpen}
          boxShadow="0 0 20px 4px rgba(154, 161, 177, 0.15),
      0 4px 80px -8px rgba(36, 40, 47, 0.25),
      0 4px 4px -2px rgba(91, 94, 105, 0.15)"
          zIndex="popover"
          bg="white"
          borderRadius="md"
          {...popper}
        >
          {isOpen && (
            <WorkspaceList
              onSelect={onSelect}
              workspaces={workspacesWithImages}
              activeWorkspaceId={activeWorkspace?.id}
            />
          )}
        </Box>
      </Portal>
      {workspacesWithImages.length > 0 && (
        <WorkspaceSwitcherAvatar
          {...rest}
          showExpandedVersion={showExpandedVersion}
          avatar={`url(${getWorkspaceAvatar(
            activeWorkspace?.id,
            workspacesWithImages,
          )})`}
          isLoading={isLoading}
          activeWorkspace={activeWorkspace}
          triggerProps={{
            onClick: onToggle,
            ref: reference.ref,
          }}
        />
      )}
    </>
  );
}
