import { Navigation } from './Navigation';

export type {
  IGetMyWorkspacesQuery,
  IGetMyWorkspacesQueryVariables,
} from './WorkspaceSwitcher/hooks/useGetMyWorkspaces.generated';
export { useGetMyWorkspacesQuery } from './WorkspaceSwitcher/hooks/useGetMyWorkspaces.generated';

export default Navigation;

export * from './Navigation';
