import isMobileJs from 'ismobilejs';

import { Box, Kbd, KbdProps } from '../../layout';

const CMD = (
  <Box as="span" mr="px">
    ⌘
  </Box>
);
const CTRL = 'Ctrl+';

export const ShortcutKeys = ({ children, ...rest }: KbdProps) => {
  if (
    typeof window === 'undefined' ||
    isMobileJs(window.navigator.userAgent).any
  ) {
    return null;
  }

  const isMac = window.navigator.platform.toUpperCase().includes('MAC');
  const modifierKey = isMac ? CMD : CTRL;

  return (
    <Kbd
      bgColor="transparent"
      border="none"
      color="whiteAlpha.600"
      fontSize={isMac ? 'sm' : 'xs'}
      fontWeight="unset"
      {...rest}
    >
      {modifierKey}
      {children}
    </Kbd>
  );
};
