const topbarPadding = 16;

export const LayoutConstants = {
  sidebarWidth: {
    base: `80px`,
    lg: '232px',
  },
  topbar: {
    padding: topbarPadding,
    height: 40 + 2 * topbarPadding,
  },
};
