import { useDebugValue, useEffect, useState } from 'react';

import { Theme, useTheme } from '@flick-tech/theme-new';

/**
 * useMedia
 *
 * React hook for dynamicwidth based layouts
 */

// You can pass false to avoid breakpoint
export type UseMediaBreakpoints = keyof Theme['breakpoints'];

type UseMediaParams = {
  min?: UseMediaBreakpoints;
  max?: UseMediaBreakpoints;
};

export function useMedia(params: UseMediaParams) {
  const theme = useTheme();

  const query = Object.entries(params)
    .map(([key, breakpoint]) =>
      theme.breakpoints[breakpoint]
        ? `(${key}-width:${theme.breakpoints[breakpoint]})`
        : '',
    )
    .join(' and ');

  // Wait for jsdom to support the match media feature.
  // This defensive check is here for simplicity.
  // Most of the time, the match media logic isn't central to people tests.
  const matchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'
      ? window.matchMedia
      : null;

  const [match, setMatch] = useState(() => {
    if (matchMedia) {
      // console.log('try to match');
      return matchMedia(query).matches;
    }

    // Once the component is mounted, we rely on the
    // event listeners to return the correct matches value.
    return false;
  });

  useEffect(() => {
    let active = true;

    if (!matchMedia) {
      return undefined;
    }

    const queryList = matchMedia(query);
    const updateMatch = () => {
      // Workaround Safari wrong implementation of matchMedia
      // TODO can we remove it?
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active) {
        setMatch(queryList.matches);
      }
    };
    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      active = false;
      queryList.removeListener(updateMatch);
    };
  }, [query, matchMedia]);

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDebugValue({ query, match });
  }

  return match;
}
