import { useMutation, useQuery } from 'react-query';
import {
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query/types/react/types';

import { Logger } from '@flick-tech/logger';
import { isString } from '@flick-tech/shared-utils';

import { useToast } from './toast';

interface FetchJSON<D> {
  url: string;
  body?: D;
  method?: 'POST' | 'PATCH' | 'GET' | 'DELETE';
}

const createErrorFromResponse = async (error: Response) => {
  let message = error.statusText;

  try {
    const errorJsonData = await error.json();

    if (isString(errorJsonData.message)) {
      message = errorJsonData.message;
    } else if (isString(errorJsonData.error)) {
      message = errorJsonData.error;
    }
  } catch (_) {}

  return new Error(message);
};

function fetchJSON<T, D>({ url, method, body }: FetchJSON<D>): Promise<T> {
  return fetch(url, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(async (response) => {
    if (!response.ok) {
      throw await createErrorFromResponse(response);
    }

    return response.json() as Promise<T>;
  });
}

interface UseFetchMutation<Vars, Data> {
  url: string;
  options?: UseMutationOptions<Data, Error, Vars>;
  disableErrorToast?: boolean;
}

export function useFetchMutation<Vars, Data>({
  url,
  options = {},
  disableErrorToast,
}: UseFetchMutation<Vars, Data>) {
  const toast = useToast();

  return useMutation<Data, Error, Vars>(
    (variables) =>
      fetchJSON({
        url,
        method: 'POST',
        body: variables,
      }),
    {
      ...options,
      onError: (error: Error) => {
        Logger.error(error);

        if (disableErrorToast) {
          return;
        }

        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
        });
      },
    },
  );
}

interface UseFetchQuery<Vars, Data> {
  url: string;
  options?: UseQueryOptions<Vars, Error, Data>;
  disableErrorToast?: boolean;
  variables?: Vars;
}

export function useFetchQuery<Vars, Data>({
  url,
  options = {},
  disableErrorToast,
  variables,
}: UseFetchQuery<Vars, Data>) {
  const toast = useToast();

  const queryKey = url + ':' + JSON.stringify(variables ?? {});

  return useQuery<Vars, Error, Data>(
    queryKey,
    () =>
      fetchJSON({
        url,
        method: 'POST',
        body: variables,
      }),
    {
      ...options,
      onError: (error: Error) => {
        Logger.error(error);

        if (disableErrorToast) {
          return;
        }

        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
        });
      },
    },
  );
}
