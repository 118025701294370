import { Component, forwardRef } from 'react';

import { SkipSentryError } from '@flick-tech/logger';

import Process from './Process';

export const Fields = [
  'id',
  'first_name',
  'last_name',
  'middle_name',
  'name',
  'name_format',
  'picture',
  'short_name',
  'email',
];

declare const window: {
  FB: any;
};

interface Props {
  children: any;
  handleProcess: (props: any, callback: (props: any) => void) => void;
  loading: boolean;
  data: any;
  error: Error;
  scope?: string;
  fields?: string[];
  rerequest?: boolean;
  reauthorize?: boolean;
  onCompleted?: (props: any) => void;
  onSuccess?: (props: any) => void;
  onError?: (error: Error) => void;
  eventKey?: any;
}

class LoginWithContext extends Component<Props> {
  static defaultProps = {
    scope: '',
    fields: Fields,
    rerequest: true,
    reauthorize: false,
    onCompleted: undefined,
    onError: undefined,
    eventKey: undefined,
  };

  handleClick = async (evn) => {
    evn.preventDefault();

    const { handleProcess, onCompleted, onError, onSuccess } = this.props;

    try {
      await handleProcess(async (api) => {
        if (!api) {
          onError(
            new SkipSentryError(
              "We can't access Facebook Login at the moment. Please refresh the page and make sure you have no browser extensions blocking it.",
            ),
          );
          return;
        }

        const { scope, fields, rerequest, reauthorize, eventKey } = this.props;
        const loginQpts: any = { scope, return_scopes: true };
        const authType = [];

        if (rerequest) {
          authType.push('rerequest');
        }

        if (reauthorize) {
          authType.push('reauthenticate');
        }

        if (authType.length) {
          loginQpts.auth_type = authType.join(',');
        }

        const loginResponse = await api.login(loginQpts);

        if (loginResponse.status !== 'connected') {
          throw new Error('Unauthorized user');
        }

        const data = await api.getTokenDetailWithProfile(
          { fields },
          loginResponse,
        );

        if (onCompleted) {
          await onCompleted({
            ...data,
            eventKey,
          });
        }

        return data;
      }, onSuccess);
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

  render() {
    const { children, loading, error, data } = this.props;

    return children({
      loading,
      handleClick: this.handleClick,
      error,
      data,
    });
  }
}

const Login = forwardRef((props: any, ref) => (
  <Process>
    {({ loading, handleProcess, data, error }) => (
      <LoginWithContext
        {...props}
        loading={loading}
        handleProcess={handleProcess}
        data={data}
        error={error}
        ref={ref as any}
      />
    )}
  </Process>
));

export default Login;
