import { useToast } from '@flick-tech/ui';

import { useMyActiveSocialGroup } from '../useMyActiveSocialGroup';

import { useSetActiveSocialGroupMutation } from './useSetActiveSocialGroup.generated';

export function useSetActiveSocialGroup() {
  const toast = useToast();
  const { setActiveSocialGroupInCache } = useMyActiveSocialGroup();

  const { mutateAsync: setActiveSocialGroup, ...rest } =
    useSetActiveSocialGroupMutation({
      onError: (error) => {
        toast({
          description: (error as Error).message,
          title: 'Error',
          status: 'error',
        });
      },
      onSuccess: async (data) => {
        const newSocialGroup = data?.setActiveSocialGroup?.activeSocialGroup;

        if (!newSocialGroup) {
          toast({
            description: 'Something went wrong. Please contact support.',
            title: 'Error',
            status: 'error',
          });
          return;
        }

        setActiveSocialGroupInCache(newSocialGroup);
      },
    });

  return {
    setActiveSocialGroup,
    ...rest,
  };
}
