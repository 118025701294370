import { get, runIfFn } from '@flick-tech/shared-utils';

import { ChakraOptions, ModifierStyleProps } from './types';
import { isSubcomponent } from './utils';

/**
 * Gets the base styles of a component based on the
 * theme key in `options`
 */
export function getBaseStyle(
  props: ModifierStyleProps,
  options: ChakraOptions,
) {
  if (!options.themeKey) return undefined;

  const [component, subComponent] = options.themeKey.split('.');

  const styleObjectOrFn = get(props.theme, `components.${component}.baseStyle`);

  if (!styleObjectOrFn) return undefined;

  let style = runIfFn(styleObjectOrFn, props);

  if (isSubcomponent(options.themeKey)) {
    style = style[subComponent];
  }

  return style;
}
