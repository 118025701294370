import {
  extractQueryParameters,
  isRunningOnServerSide,
} from './shared';

declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}

// TODO: replace with user agents as query params are unstable
export const isRunningInMobileWebView = () => {
  if (isRunningOnServerSide()) {
    return false;
  }

  const queryParams = extractQueryParameters(window.location.search);

  // https://github.com/Flick-Tech/mobile-app/blob/93b02b09b358fb9693838f4d62449dfeff565228/src/shared/hooks/useOpenInAppBrowser.tsx#L25
  const isRunningInMobileWebView = queryParams.type === 'mobile-app';

  const key = 'isRunningInMobileWebView';

  if (isRunningInMobileWebView) {
    window.localStorage.setItem(key, 'true');
  }

  return (
    Boolean(window.ReactNativeWebView) ||
    isRunningInMobileWebView ||
    !!window.localStorage.getItem(key)
  );
};
