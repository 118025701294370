const shadows = {
  // sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  // md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  // lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  // xl:
  //   '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  // '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  sm: '0 2px 3px 0 rgba(0, 0, 0, 0.1)',
  md: 'rgb(5 0 56 / 12%) 0px 4px 16px 0px',
  // lg: '0 2px 9px 2px rgba(0, 0, 0, 0.1)',
  lg: '0 2px 12px 4px rgba(0, 0, 0, 0.1)',
  xl: '0 2px 12px 4px rgba(0, 0, 0, 0.1)',
  '2xl': '0 2px 12px 6px rgba(0, 0, 0, 0.1)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  none: 'none',
};

export type Shadows = typeof shadows;

export default shadows;
