import { IAddonId_PRICING_LIB, IPlanId_PRICING_LIB } from '@flick-tech/pricing';
import { isNewPlan } from '@flick-tech/shared-common';
import { IAddonId, IPlanId } from '@flick-tech/shared-types';

import { useGetUser } from './useGetUser';

const grandfartheredPlans: IPlanId[] = [
  'BASIC',
  'STARTER',
  'PROFESSIONAL',
  'PERFORMANCE',
  'LiteGBP',
  'StarterGBP',
  'GrowthGBP',
  'ProfessionalGBP',
];

const isGrandfathered = (planId: IPlanId) =>
  grandfartheredPlans.includes(planId);

/* ------ */

// We have the same enum inside @flick-tech/pricing to ensure
// that it doesn't import IPlanId from @flick-tech/data as we don't want to publish
// @flick-tech/data

export const convertIPlanIdToPricingPlanId = (planId: IPlanId) =>
  planId as any as IPlanId_PRICING_LIB;

export const convertPricingPlanIdToIPlanId = (planId: IPlanId_PRICING_LIB) =>
  planId as any as IPlanId;

export const convertIAddonIdToPricingAddonId = (planId: IAddonId) =>
  planId as any as IAddonId_PRICING_LIB;

/* ------ */

export function getAnalyticsPrice(planId: IPlanId): number {
  if (planId === 'Complimentary') {
    return 1499;
  }

  return 999;
}

export function getAddonUnitPrice(addonId: IAddonId): number {
  switch (addonId) {
    case 'ViralPostsFinderGBP':
      return 1000;
    case 'ViralPostsFinderGBPYearly':
      return 9600;
    case 'IGAccountGBP':
      return 500;
    case 'IGAccountGBPYearly':
      return 4800;
    case 'HashtagStorageGBP':
      return 500;
    case 'HashtagStorageGBPYearly':
      return 4800;
    default:
      return 0;
  }
}
export function getAddonPriceInPence(
  addonId: IAddonId,
  planId: IPlanId,
): number {
  switch (addonId) {
    case 'AnalyticsSuiteGBP':
      return this.getAnalyticsPrice(planId);
    default:
      return getAddonUnitPrice(addonId);
  }
}

export function useHasAccessToNewPlans() {
  const { user } = useGetUser();

  const planId = user?.activeWorkspace?.billing?.plan?.id;

  const isOnNewPlan = isNewPlan(planId);
  const isOnOldPlan = isGrandfathered(planId);

  return {
    newPlansActive: planId !== 'LiteGBP',
    hasOldPlan: isOnOldPlan,
    isGrandfathered: isOnOldPlan,
    isOnNewPlan,
  };
}
