import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, PropsOf } from '@flick-tech/theme-new';
import { Icon, IconProps } from '../icon';

/**
 * The tag component is used to label or categorize UI elements.
 *
 * To style the tag globally, change the styles in `theme.components.Tag`
 *
 * @see Docs https://chakra-ui.com/components/tag
 */
export const Tag = chakra('span', {
  themeKey: 'Tag',
  baseStyle: {
    display: 'inline-flex',
    verticalAlign: 'top',
    alignItems: 'center',
    maxWidth: '100%',
    fontWeight: 'medium',
    lineHeight: 1,
    py: 1,
    px: 3,
  },
});

export type TagProps = PropsOf<typeof Tag>;

if (__DEV__) {
  Tag.displayName = 'Tag';
}

export const TagLabel = chakra('span', {
  baseStyle: { lineHeight: 1.2 },
});

export type TagLabelProps = PropsOf<typeof TagLabel>;

TagLabel.defaultProps = {
  isTruncated: true,
};

if (__DEV__) {
  TagLabel.displayName = 'TagLabel';
}

export const TagLeftIcon = (props: IconProps) => (
  <Icon verticalAlign="top" marginRight="0.5rem" {...props} />
);

if (__DEV__) {
  TagLeftIcon.displayName = 'TagLeftIcon';
}

export const TagRightIcon = (props: IconProps) => (
  <Icon verticalAlign="top" marginLeft="0.5rem" {...props} />
);

if (__DEV__) {
  TagRightIcon.displayName = 'TagRightIcon';
}

const TagCloseIcon = (props: Omit<IconProps, 'icon'>) => (
  <Icon verticalAlign="inherit" icon="CloseRounded" {...props} />
);

if (__DEV__) {
  TagCloseIcon.displayName = 'TagCloseIcon';
}

export type TagCloseButtonProps = Omit<
  PropsOf<typeof chakra.button>,
  'disabled'
> & {
  isDisabled?: boolean;
};

const StyledButton = chakra('button', {
  baseStyle: {
    fontSize: '1em',
    width: '1.25rem',
    height: '1.25rem',
    borderRadius: 'sm',
    marginLeft: '0.375rem',
    marginRight: '-1',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.2s',
    opacity: 0.5,
    _disabled: { opacity: 0.4 },
    outline: '0',
    _focus: {
      boxShadow: 'outline',
      bg: 'rgba(0, 0, 0, 0.14)',
    },
    _hover: { opacity: 0.8 },
    _active: { opacity: 1 },
  },
  attrs: {
    type: 'button',
  },
});

/**
 * TagCloseButton
 *
 * The tag close button. This is used to close "remove" the tag
 *
 * @see Docs https://chakra-ui.com/components/tag
 */
export const TagCloseButton = (props: TagCloseButtonProps) => {
  const { isDisabled, children = <TagCloseIcon />, ...rest } = props;
  return <StyledButton disabled={isDisabled} children={children} {...rest} />;
};

if (__DEV__) {
  TagCloseButton.displayName = 'TagCloseButton';
}
