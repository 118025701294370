const setup = () => {
  require('dd-trace').init({
    logInjection: true,
    runtimeMetrics:  true,
    env: process.env.NODE_ENV,
    service: process.env.APP_NAME ?? 'api',
    enabled: true,
    hostname: process.env.DD_AGENT_HOST,
  });
}

if (process.env.NODE_ENV === 'production') {
  setup()
}
