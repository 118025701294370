import { focusManager } from 'react-query';

import { isRunningOnServerSide } from '@flick-tech/shared-common'

// https://react-query.tanstack.com/guides/window-focus-refetching#custom-window-focus-event
// We don't want the default behaviour where a "window focus" is when the window is clicked
// Instead we define "window focus" as when the window is returned to, via visibilitychange
// https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event

let hasSetup = false;

// USE CASE: https://www.loom.com/share/9c6e84f8d9524ab68ef5b24ddb073a60
export const setupReactQueryFocusManager = () => {
  if (isRunningOnServerSide() || hasSetup) {
    return;
  }

  focusManager.setEventListener((handleFocus: any) => {
    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('visibilitychange', handleFocus, false);
    }

    return () => {
      window.removeEventListener('visibilitychange', handleFocus);
    };
  });

  hasSetup = true;
};
