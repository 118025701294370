import { FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra } from '@flick-tech/theme-new';

import { BoxProps } from './Box';

export interface CodeProps extends BoxProps {}

/**
 * React component to render inline code snippets.
 *
 * @see Docs https://chakra-ui.com/code
 */
export const Code: FC<BoxProps> = chakra('code', {
  themeKey: 'Code',
  baseStyle: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
  },
});

if (__DEV__) {
  Code.displayName = 'Code';
}
