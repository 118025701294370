import { ReactNode } from 'react';

import { Box, BoxProps, Flex, FlexProps } from '../../layout';

export function EmptyStateMessage(props: FlexProps) {
  return (
    <Flex
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      align="center"
      justify="center"
      textAlign="center"
      background="whiteAlpha.800"
      zIndex={3}
      color="rgba(0, 0, 0, .85)"
      p={5}
      fontSize="24px"
      {...props}
    />
  );
}

export function EmptyStateBlur(props: BoxProps) {
  return (
    <Box
      sx={{ filter: 'blur(4px)' }}
      position="relative"
      h="100%"
      pointerEvents="none"
      {...props}
    />
  );
}

export interface EmptyStateWrapperProps extends BoxProps {
  active?: boolean;
  content?: ReactNode;
  children?: ReactNode;
}
export function EmptyStateWrapper({
  children,
  content = 'There is no data to be shown here for the selected time frame',
  active,
  ...rest
}: EmptyStateWrapperProps) {
  if (!active) {
    return <Box {...rest}>{children}</Box>;
  }

  return (
    <Box position="relative" {...rest}>
      <EmptyStateMessage>{content}</EmptyStateMessage>
      <EmptyStateBlur>{children}</EmptyStateBlur>
    </Box>
  );
}
