import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { useNavigateToBilling } from '@flick-tech/shared-hooks';
import { Button } from '../button';
import { Box, Flex } from '../layout';

import { FlickPopover } from './FlickPopover';
import { FlickPopoverPropTypes } from './types';

const FlickPopoverStyled = styled(({ fillContainer, ...rest }: any) => (
  <FlickPopover {...rest} />
))<{ fillContainer?: boolean }>`
  ${(props) =>
    props.fillContainer &&
    `
    width: 100%;
    height: 100%;
  `};
`;

export interface MissingFeaturePopoverPropTypes extends FlickPopoverPropTypes {
  text: ReactNode | string;
  children: string | ReactNode | ReactNode[];
  enabled: boolean;
  fillContainer?: boolean;
}

export function MissingFeaturePopover({
  text,
  children,
  enabled,
  fillContainer,
  theme,
  ...rest
}: MissingFeaturePopoverPropTypes) {
  const navigateToBilling = useNavigateToBilling();

  return (
    <FlickPopoverStyled
      fillContainer={fillContainer}
      boundary="window"
      enabled={enabled}
      theme={theme as any}
      interactive
      {...rest}
      title={
        <Flex
          direction="column"
          align="center"
          justify="center"
          maxW={180}
          py={1}
        >
          <Box fontSize="lg" mt={0} mb={2}>
            {text}
          </Box>
          <Button
            size="sm"
            leftIcon="TrendingUpRounded"
            onClick={() => navigateToBilling({ editing: true })}
          >
            Upgrade my plan
          </Button>
        </Flex>
      }
    >
      {children}
    </FlickPopoverStyled>
  );
}
