import { ReactNode, useCallback } from 'react';
import * as React from 'react';
import { FieldRenderProps, useForm } from 'react-final-form';

import { __DEV__ } from '@flick-tech/shared-utils';
import {
  chakra,
  forwardRef,
  PropsOf,
  useComponentStyle,
} from '@flick-tech/theme-new';

import {
  FormControlOptions,
  FormControlWrapper,
  FormControlWrapperProps,
  useFormControl,
} from '../form-control';
import { Icon, IconName } from '../icon';

import { InputLeftElement, InputRightElement } from './Input.element';
import { InputGroup, useInputGroup } from './Input.group';

type OmittedTypes = 'disabled' | 'required' | 'readOnly' | 'size';

interface InputOptions {
  /**
   * The border color when the input is focused. Use color keys in `theme.colors`
   * @example
   * focusBorderColor = "blue.500"
   */
  focusBorderColor?: string;
  /**
   * The border color when the input is invalid. Use color keys in `theme.colors`
   * @example
   * errorBorderColor = "red.500"
   */
  errorBorderColor?: string;
  /**
   * If `true`, the input element will span the full width of it's parent
   */
  isFullWidth?: boolean;

  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
}

export interface InputProps
  extends Omit<PropsOf<typeof StyledInput>, OmittedTypes>,
    Omit<FormControlOptions, 'size'> {
  size?: string;
}

/**
 * Input - Theming
 *
 * To style the input globally, change the styles in
 * `theme.components.Input`
 */

const StyledInput = chakra<'input', InputOptions>('input', {
  themeKey: 'Input',
  shouldForwardProp: (prop) =>
    !['focusBorderColor', 'errorBorderColor'].includes(prop),
});

/**
 * Input
 *
 * Element that allows users enter single valued data.
 */

export const Input = React.forwardRef(
  (props: InputProps, ref: React.Ref<HTMLInputElement>) => {
    const inputProps = useFormControl<HTMLInputElement>(props);
    const group = useInputGroup();

    const variant = group?.variant || props.variant;
    // @ts-ignore
    const size = group?.size || props.size;

    const inputStyle = useComponentStyle({
      themeKey: 'Input',
      variant,
      size,
    });

    const themingProps = { variant, size } as any;

    return (
      <StyledInput
        ref={ref}
        {...(group?.hasRightElement && { paddingRight: inputStyle?.height })}
        {...(group?.hasLeftElement && { paddingLeft: inputStyle?.height })}
        {...inputProps}
        {...themingProps}
      />
    );
  },
);

if (__DEV__) {
  Input.displayName = 'Input';
}

interface FinalFormInputProps
  extends FieldRenderProps<string, HTMLInputElement>,
    InputProps {
  hideTouchedError?: boolean;
  helperText?: string;
  label?: string;
  hideHelperText?: boolean;
  fullWidth?: boolean;
  wrapperProps?: Partial<FormControlWrapperProps>;
  leftIcon?: IconName | ReactNode;
}

export function PasswordInput({
  size,
  leftElement,
  ...rest
}: InputProps & {
  leftElement?: ReactNode;
}) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  if (leftElement) {
    rest.pl = '2.5rem';
  }

  return (
    <InputGroup size={size}>
      {leftElement}
      <Input {...rest} pr="4.5rem" type={show ? 'text' : 'password'} />
      <InputRightElement width="4.5rem">
        <chakra.button
          color="blackAlpha.600"
          type="button"
          h="1.75rem"
          onClick={handleClick}
        >
          {show ? 'Hide' : 'Show'}
        </chakra.button>
      </InputRightElement>
    </InputGroup>
  );
}

export const FinalFormInput = forwardRef<FinalFormInputProps, 'input'>(
  (
    {
      meta,
      input,
      hideTouchedError,
      helperText,
      label,
      hideHelperText,
      fullWidth,
      wrapperProps = {},
      onKeyPress,
      mb, // TODO: Extract spacing props
      mt, // TODO: Extract spacing props
      leftIcon,
      ...rest
    },
    ref,
  ) => {
    const { name, type } = input;
    const { error, submitFailed } = meta;
    const showError =
      ((meta.touched && !hideTouchedError) || submitFailed) && error;

    const { submit } = useForm();

    const handleKeyPress = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          submit();
        }

        if (onKeyPress) {
          onKeyPress(e);
        }
      },
      [onKeyPress, submit],
    );

    const isPassword = type === 'password';

    const Comp = isPassword ? PasswordInput : Input;

    const leftElement = leftIcon ? (
      <InputLeftElement
        pointerEvents="none"
        children={
          typeof leftIcon === 'string' ? (
            <Icon fontSize="lg" icon={leftIcon as IconName} color="gray.400" />
          ) : (
            leftIcon
          )
        }
      />
    ) : null;

    let content = (
      <FormControlWrapper
        width={fullWidth ? '100%' : 'auto'}
        showError={showError}
        error={error}
        helperText={hideHelperText ? undefined : helperText}
        label={label}
        name={name}
        mb={mb}
        mt={mt}
        {...wrapperProps}
      >
        <Comp
          {...input}
          {...rest}
          ref={ref}
          leftElement={leftElement}
          onKeyPress={handleKeyPress}
        />
      </FormControlWrapper>
    );

    if (leftIcon && !isPassword) {
      content = (
        <InputGroup w={fullWidth ? '100%' : 'auto'} size={rest.size}>
          {leftElement}
          {content}
        </InputGroup>
      );
    }

    return content;
  },
);

if (__DEV__) {
  FinalFormInput.displayName = 'FinalFormInput';
}
