import { useNavigate } from './useRouter';

export function useClientsideLink(path: string) {
  const navigate = useNavigate();

  return {
    href: path,
    onClick: (e: React.SyntheticEvent) => {
      e.preventDefault();
      navigate(path);
    },
  };
}
