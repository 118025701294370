import InputTheme, { InputProps } from './InputTheme';
import { ComponentTheme } from './utils';

const PinInputTheme: ComponentTheme<InputProps> = {
  defaultProps: InputTheme.defaultProps,
  baseStyle: InputTheme.baseStyle,
  variants: InputTheme.variants,
  sizes: {
    lg: {
      fontSize: 'lg',
      width: 12,
      height: 12,
      borderRadius: 'md',
    },
    md: {
      fontSize: 'md',
      width: 10,
      height: 10,
      borderRadius: 'md',
    },
    sm: {
      fontSize: 'sm',
      width: 8,
      height: 8,
      borderRadius: 'sm',
    },
  },
};

export default PinInputTheme;
