import { Dispatch, SetStateAction, useState } from 'react';

import { isRunningOnServerSide } from '@flick-tech/shared-common';

import { createStorage } from './createStorage';
import usePersistedState from './usePersistedState';

type UseStateOfType<S> = {
  (initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>];
  (): [S, Dispatch<SetStateAction<S>>];
};

export const createPersistedState = (
  key: string,
  initialProvider?: Pick<Storage, 'getItem' | 'setItem'>,
) => {
  const provider =
    initialProvider ?? (isRunningOnServerSide() ? null : localStorage);

  if (provider) {
    const storage = createStorage(provider);
    return <T>(initialState: T) =>
      usePersistedState<T>(initialState, key, storage);
  }

  return useState;
};

export const createPersistedStateOfType = createPersistedState as <S>(
  key: string,
  provider?: Pick<Storage, 'getItem' | 'setItem'>,
) => UseStateOfType<S>;
