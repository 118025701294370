import { Fade, ScaleFade } from '../transition';

import {
  Modal,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps,
} from './Modal';

type AnimatedModalProps = ModalProps & {
  zIndex?: number;
  contentProps?: Partial<ModalContentProps>;
};

export function ScaleFadeModal({
  isOpen,
  children,
  zIndex,
  contentProps = {},
  ...rest
}: AnimatedModalProps) {
  return (
    <Fade timeout={300} in={isOpen}>
      {(styles) => (
        <Modal isOpen={true} {...rest}>
          <ModalOverlay zIndex={zIndex ?? 'overlay'} style={styles}>
            <ScaleFade timeout={150} in={isOpen} unmountOnExit={false}>
              {(styles) => (
                <ModalContent {...contentProps} style={styles}>
                  {children}
                </ModalContent>
              )}
            </ScaleFade>
          </ModalOverlay>
        </Modal>
      )}
    </Fade>
  );
}
