import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { ItlyWeb } from '@flick-tech/client-tracking';
import { Amplitude } from '@flick-tech/client-tracking';
import {
  useGetUser,
  useHasAccessToNewPlans,
  useHasCompletedRegistration,
} from '@flick-tech/core-hooks';
import { Logger } from '@flick-tech/logger';
import { useBoolean, useGraphqlFetch } from '@flick-tech/shared-hooks';
import {
  extractReferrer,
  PersistentStore,
  StoreKey,
} from '@flick-tech/shared-common'

import {
  HandleReferrerLinkClickDocument,
  IHandleReferrerLinkClickMutation,
  IHandleReferrerLinkClickMutationVariables,
} from './useHandleReferrerLinkClickMutation.generated';

// TODO: Deprecate this when migrating to First Promoter for referrals (chat to Sam & Loic about this)
export function useHandleLinkClick() {
  const fetcher = useGraphqlFetch<
    IHandleReferrerLinkClickMutation,
    IHandleReferrerLinkClickMutationVariables
  >(HandleReferrerLinkClickDocument);

  return async (referredUserId: string) => {
    const { referrer, referrerUrl } = extractReferrer(window.location.search);

    PersistentStore.set(StoreKey.REFERRER, referrer);
    PersistentStore.set(StoreKey.REFERRER_URL, referrerUrl);

    if (!referrer) return;

    try {
      Amplitude.setUserPropertiesOnce({
        referrer,
        referrerUrl,
      });
    } catch (error) {
      Logger.error(error);
    }

    try {
      await fetcher({
        referrerId: referrer,
        referredUserId,
        referrerUrl,
      });
    } catch (error) {
      Logger.error(error);
    }
  };
}

export function useInitialiseApp() {
  const [hasInitialized, { on }] = useBoolean(false);

  const { loading: userLoading, user } = useGetUser();
  const userId = user?.id;

  const { isGrandfathered } = useHasAccessToNewPlans();
  const { hasCompletedRegistration, loading: registrationStateLoading } =
    useHasCompletedRegistration();

  useEffect(() => {
    if (!hasCompletedRegistration || registrationStateLoading) return;

    if (isGrandfathered) {
      ItlyWeb.identify({
        wasGrandfathered: true,
      });
    }
  }, [hasCompletedRegistration, registrationStateLoading, isGrandfathered]);

  const queryClient = useQueryClient();

  const handleLinkClick = useHandleLinkClick();

  useEffect(() => {
    if (hasInitialized || userLoading) {
      return;
    }

    void handleLinkClick(userId);

    document.body.classList.add('client-loaded');

    on();
  }, [hasInitialized, on, userLoading, userId, queryClient, handleLinkClick]);

  return { loading: !hasInitialized, isLoggedIn: !!userId, user };
}
