import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { atom, DefaultValue, useRecoilState } from 'recoil';

import {
  extractQueryParameters,
  isRunningOnServerSide,
  PersistentStore,
  replaceState,
  StoreKey,
} from '@flick-tech/shared-common';

import { addQuery } from './useRouter';
import { useRouterQuery } from './useRouterQuery';

export enum HashtagsView {
  TABLE = 'table',
  GROUPS = 'groups',
}

const getDefaultViewFromURL = () => {
  if (isRunningOnServerSide()) {
    return HashtagsView.GROUPS;
  }

  return extractQueryParameters(window.location.search)?.view as HashtagsView;
};

const defaultView = HashtagsView.GROUPS;

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet, node }) => {
    const savedValue = localStorage.getItem(key);
    const hasDefaultState = !!getDefaultViewFromURL();

    // Do not initialise with local storage if the state is initialized via url
    if (savedValue != null && !hasDefaultState) {
      try {
        setSelf(JSON.parse(savedValue));
      } catch (error) {
        setSelf(defaultView);
      }
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const activeHashtagsViewState = atom<HashtagsView>({
  key: 'explore-page/activeView',
  default: getDefaultViewFromURL() ?? defaultView,
  effects: [localStorageEffect('hashtagsView')],
});

export function useHashtagsView() {
  const [hashtagsView, _setHashtagsView] = useRecoilState(
    activeHashtagsViewState,
  );
  const router = useRouter();
  const pathname = router.pathname;
  const queryParams = useRouterQuery();

  const viewIdFromUrl = queryParams.view as string;

  const addViewToUrl = useCallback((view: HashtagsView) => {
    if (
      isRunningOnServerSide() ||
      !window.location?.pathname?.includes('explore')
    ) {
      return;
    }

    const url = new URL(window.location.href);
    url.search = addQuery('view', view);
    replaceState(url.toString());
  }, []);

  const setHashtagsView = useCallback(
    (view: HashtagsView) => {
      _setHashtagsView(view);
      PersistentStore.set(StoreKey.DESKTOP_HASHTAGS_VIEW, view);
      addViewToUrl(view);
    },
    [_setHashtagsView, addViewToUrl],
  );

  useEffect(() => {
    if (
      !viewIdFromUrl &&
      hashtagsView &&
      !isRunningOnServerSide() &&
      pathname?.includes('explore')
    ) {
      addViewToUrl(hashtagsView);
    }
  }, [hashtagsView, viewIdFromUrl, addViewToUrl, pathname?.includes]);

  return {
    hashtagsView,
    setHashtagsView,
  };
}
