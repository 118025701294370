import dynamic from 'next/dynamic';

import { Box, BoxProps, Flex } from '@flick-tech/ui';

const Navigation = dynamic(() => import('../Navigation'), {
  ssr: false,
});

interface AppSkeletonLoaderProps extends BoxProps {}

export function AppSkeletonLoader({
  children,
  ...rest
}: AppSkeletonLoaderProps) {
  return (
    <Flex w="100vw" h="100vh" {...rest}>
      <Navigation isLoading />
      <Box w="100%">{children}</Box>
    </Flex>
  );
}
