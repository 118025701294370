import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, PropsOf } from '@flick-tech/theme-new';

import { Icon } from '../icon';

/**
 * CloseButton - Theming
 *
 * To style the input globally, change the styles in
 * `theme.components.CloseButton`
 */

const StyledButton = chakra('button', {
  themeKey: 'CloseButton',
  baseStyle: {
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
  attrs: {
    type: 'button',
    'aria-label': 'Close',
  },
});

export type CloseButtonProps = PropsOf<typeof StyledButton> & {
  /**
   * If `true`, the close button will be disabled.
   */
  isDisabled?: boolean;
};

/**
 * CloseButton
 *
 * A button with a close icon.
 *
 * It is used to handle the close functionality in feedback and overlay components
 * like Alerts, Toasts, Drawers and Modals.
 */

export const CloseButton = ({ size = 'xl', ...props }: CloseButtonProps) => {
  const {
    children = (
      <Icon icon="CloseRounded" fontSize={size} width="1em" height="1em" />
    ),
    isDisabled,
    ...rest
  } = props;
  return (
    <StyledButton fontSize={size} disabled={isDisabled} {...rest}>
      {children}
    </StyledButton>
  );
};

if (__DEV__) {
  CloseButton.displayName = 'CloseButton';
}
