import { extend } from '@flick-tech/shared-utils';
import { chakra, SystemProps } from '@flick-tech/theme-new';

import { Box, BoxProps } from './Box';
import { Flex } from './Flex';
import { LinkProps } from './Link';
import { Text, TextProps } from './Text';

export const baseTextStyles: SystemProps = {
  lineHeight: '160%',
  fontSize: '18px',
  color: 'text.darker',
};

export const BodyStylesByVariant = extend<Record<string, SystemProps>>()({
  md: { lineHeight: 1.3, fontSize: '18px' },
  sm: { lineHeight: 1.3, fontSize: '16px' },
  xs: { lineHeight: 1.3, fontSize: '14px' },
});

export type BodyVariant = keyof typeof BodyStylesByVariant;

export interface BodyProps extends TextProps {
  variant: BodyVariant;
}
export const Body = ({ variant, ...props }: BodyProps) => (
  <Text {...BodyStylesByVariant[variant]} {...props} />
);

export const BodyText = ({ fontWeight, ...props }: TextProps) => (
  <Text
    {...baseTextStyles}
    as="span"
    fontWeight={fontWeight ?? 400}
    {...props}
  />
);

export const BodyParagraph = ({ children, ...props }: BoxProps) => (
  <Box marginBottom={{ base: '16px', sm: '20px' }} {...props}>
    {children}
  </Box>
);

export const boldTextStyles: TextProps = {
  as: 'strong',
  fontWeight: 600,
};

export const italicTextStyles: TextProps = {
  as: 'em',
  fontStyle: 'italic',
};

export const BoldText = (props: TextProps) => (
  <Text {...baseTextStyles} {...boldTextStyles} {...props} />
);
export const ItalicText = (props: TextProps) => (
  <Text {...baseTextStyles} {...italicTextStyles} {...props} />
);

const commonHeadingStyles = {
  lineHeight: 1.3,
  color: 'text.black',
};

const large = {
  ...commonHeadingStyles,
  as: 'h3' as const,
  fontSize: { base: '20px', sm: '22px' },
  fontWeight: 700,
  paddingTop: '24px',
  paddingBottom: '16px',
};

export const HeadingStylesByVariant = extend<Record<string, SystemProps>>()({
  h1: {
    ...commonHeadingStyles,
    as: 'h1' as const,
    fontSize: { base: '30px', sm: '36px' },
    fontWeight: 800,
    paddingY: '8px',
  },
  h2: {
    ...commonHeadingStyles,
    as: 'h2' as const,
    fontSize: { base: '26px', sm: '28px' },
    fontWeight: 700,
    paddingTop: '24px',
    paddingBottom: '12px',
    borderBottom: '2px solid #EEEEEE',
    marginBottom: '16px',
  },
  h3: large,
  large,
  h4: {
    ...commonHeadingStyles,
    as: 'h4' as const,
    fontSize: { base: '18px', sm: '20px' },
    fontWeight: 'bold',
    paddingY: '8px',
  },
  xl: {
    as: 'h2' as const,
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '32px',
  },
  lg: {
    as: 'h2' as const,
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '28px',
  },
  md: {
    as: 'h4' as const,
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '28px',
  },
  sm: {
    as: 'h4' as const,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
  },
  xs: {
    as: 'h4' as const,
    fontWeight: 'semibold',
    fontSize: '14px',
    lineHeight: '20px',
  },
  callout: {
    as: 'p' as const,
    fontWeight: 'semibold',
    fontSize: '12px',
    lineHeight: '12px',
  },
});

export type HeadingVariant = keyof typeof HeadingStylesByVariant;

export interface HeadingProps extends TextProps {
  variant: HeadingVariant;
}
export const Heading = ({ variant, ...props }: HeadingProps) => {
  const headingStyles = HeadingStylesByVariant[variant];
  return <Text {...headingStyles} {...props} />;
};

export const linkStyles = {
  borderBottomWidth: '1px',
  borderBottomColor: 'text.darker',
  _hover: {
    cursor: 'pointer',
    color: 'brand.500',
    borderBottomColor: 'brand.500',
  },
};

export const LinkText = (props: LinkProps) => (
  <chakra.a {...baseTextStyles} {...linkStyles} fontWeight={400} {...props} />
);

export const QuoteBlock = (props: TextProps) => (
  <Flex backgroundColor="bg.lighter" borderRadius="16px" p="16px">
    <Box backgroundColor="brand.500" borderRadius="8px" w="8px" mr="16px" />
    <Text
      {...baseTextStyles}
      {...italicTextStyles}
      fontWeight={400}
      color="text.black"
      {...props}
    />
  </Flex>
);
