import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Theme } from '@flick-tech/theme-new';
import { Flex, FlexProps } from '../layout';

const dayPickerStyles = css`
  .DayPicker {
    display: inline-block;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;

    flex-direction: row;
    padding-bottom: 1em;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    height: 323px;
  }

  .DayPicker-NavBar {
    position: absolute;
    height: 24px;
    width: 100%;
  }

  .DayPicker-NavButton {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: var(--text-darker-color);
    cursor: pointer;

    background-size: auto;
    border-radius: 4px;

    &:hover {
      background-color: var(--hover-bg-color);
    }

    &.DayPicker-NavButton--prev {
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.21 6.71047C14.82 6.32047 14.19 6.32047 13.8 6.71047L9.21001 11.3005C8.82001 11.6905 8.82001 12.3205 9.21001 12.7105L13.8 17.3005C14.19 17.6905 14.82 17.6905 15.21 17.3005C15.6 16.9105 15.6 16.2805 15.21 15.8905L11.33 12.0005L15.21 8.12047C15.6 7.73047 15.59 7.09047 15.21 6.71047Z' fill='%23333333'/%3E%3C/svg%3E%0A");
    }

    &.DayPicker-NavButton--next {
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.79 6.70978C9.4 7.09978 9.4 7.72978 9.79 8.11978L13.67 11.9998L9.79 15.8798C9.4 16.2698 9.4 16.8998 9.79 17.2898C10.18 17.6798 10.81 17.6798 11.2 17.2898L15.79 12.6998C16.18 12.3098 16.18 11.6798 15.79 11.2898L11.2 6.69978C10.82 6.31978 10.18 6.31978 9.79 6.70978Z' fill='%23333333'/%3E%3C/svg%3E%0A");

      position: absolute;
      right: 0;
    }

    &.DayPicker-NavButton--interactionDisabled {
      display: none;
    }
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    height: 24px;

    & > div {
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 600;
      font-size: 1rem;
      line-height: 1.3;
    }
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8b9898;
    text-align: center;
    font-size: 0.875em;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Month {
    display: table;
    margin: 0 1em;
    user-select: none;
    border-collapse: separate;
    border-spacing: 0 4px;

    color: var(--text-darker-color);

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  .DayPicker-Day {
    display: table-cell;
    padding: calc(0.5em + 1px);
    vertical-align: middle;
    text-align: center;
    cursor: pointer;

    font-size: 14px;
    line-height: 1.3;
    width: 38px;
    height: 38px;
    border-radius: 8px;

    &.DayPicker-Day--today {
      font-weight: 700;
    }

    &.DayPicker-Day--outside {
      visibility: hidden;
    }

    &.DayPicker-Day--disabled {
      color: var(--disabled-color);
      cursor: default;
      pointer-events: none;
      user-select: none;
    }

    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
      background-color: var(--hover-bg-color);
    }

    &.DayPicker-Day--selected {
      background-color: var(--selected-inner-bg-color);
      color: var(--primary-color);

      border-radius: 0px;

      &.DayPicker-Day--start {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        --radius: calc(8px * var(--only-one-selected));
        border-top-right-radius: var(--radius);
        border-bottom-right-radius: var(--radius);
      }
      &.DayPicker-Day--end {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.DayPicker-Day--start,
      &.DayPicker-Day--end {
        background-color: var(--selected-boundary-bg-color);
      }
    }
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: calc(0.5em + 1px);
    min-width: 1em;
    border-right: 1px solid var(--week-number-border-color);
    color: var(--week-number-color);
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }
`;

const dayPickerInputStyles = css`
  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px var(--overlay-box-shadow-color);
  }
`;

export interface DateRangePickerStylesContainerProps
  extends Omit<FlexProps, 'sx'> {
  selectedRangeLength: number;
}
export const DateRangePickerStylesContainer = styled(
  ({ selectedRangeLength, ...rest }: DateRangePickerStylesContainerProps) => {
    return (
      <Flex
        sx={{
          backgroundColor: 'bg.lighter',
          p: 4,

          ...({
            '--primary-color': 'white',
            '--disabled-color': '#dce0e0',
            '--text-darker-color': (t: Theme) => t.colors.text.darker,
            '--week-number-color': '#8b9898',
            '--week-number-border-color': '#eaecec',
            '--overlay-box-shadow-color': 'rgba(0, 0, 0, 0.15)',
            '--selected-inner-bg-color': (t: Theme) => t.colors.brand[500],
            '--selected-boundary-bg-color': (t: Theme) => t.colors.brand[600],
            '--hover-bg-color': (t: Theme) => t.colors.blackAlpha[50],

            '--only-one-selected':
              selectedRangeLength === 0 || Number.isNaN(selectedRangeLength)
                ? 1
                : 0,
          } as {}),
        }}
        {...rest}
      />
    );
  },
)([dayPickerInputStyles, dayPickerStyles]);
