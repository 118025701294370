import getUnixTime from 'date-fns/getUnixTime';
import { LDUser } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

import { getFirstName, getLastName } from '@flick-tech/shared-common';

export interface LaunchDarklyUser {
  email: string;
  name: string;
  id: string;
  profilePicture?: string;
  createdAt: Date;
}

export const getLaunchDarklyUserProperties = (
  user: LaunchDarklyUser,
): LDUser => ({
  email: user.email,
  firstName: getFirstName(user.name),
  lastName: getLastName(user.name),
  key: user.id,
  avatar: user.profilePicture,
  custom: {
    userId: user.id,
    createdAt: new Date(user.createdAt) as any,
  },
});

export function useSetLaunchDarklyUser() {
  const client = useLDClient();

  return useCallback(
    ({ user }: { user: LaunchDarklyUser }) => {
      // Not initialized, but not to worry as we will follow this rough flow:
      //  1. User gets initialized, ld client might not exist
      //  2. LD initialises and identifies the user
      //  3. User gets updated, ld client will exist and identified with the new info
      if (!client) {
        return;
      }

      client?.identify(getLaunchDarklyUserProperties(user), undefined);
    },
    [client],
  );
}
