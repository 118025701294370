// Hashtags which the Instagram API return 0 media count and a positive
// average likes > 0 are always banned hashtags.

import type { Maybe } from '@flick-tech/shared-types';

import { isNullOrUndefined } from './assertion';

export const isHashtagBannedHeuristic = (hashtag: {
  averageLikes?: Maybe<number>;
  mediaCount?: Maybe<number>;
}) =>
  hashtag &&
  !isNullOrUndefined(hashtag.mediaCount) &&
  hashtag.mediaCount === 0 &&
  (hashtag.averageLikes as number) > 0;
