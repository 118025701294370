import { IBilling, ICustomer, IPlan } from '@flick-tech/shared-types';

type UseBillingStatusProps =
  | ({
      plan?: Pick<IPlan, 'id'>;
      customer?: Pick<ICustomer, 'cardStatus'>;
      isFreemium?: boolean;
    } & Pick<IBilling, 'hasActiveSubscription'>)
  | undefined;

export function getBillingStatus(billing: UseBillingStatusProps) {
  const isFreemium = billing?.isFreemium;
  const cardStatus = billing?.customer?.cardStatus;
  const isComplimentary = billing?.plan?.id === 'Complimentary';
  const cardExpired = cardStatus === 'EXPIRED';
  const requiresCardDetails = cardStatus === 'NO_CARD';

  const hasActiveSubscription = billing?.hasActiveSubscription;

  const requiresSubscriptionReactivation =
    !hasActiveSubscription &&
    !isComplimentary &&
    !cardExpired &&
    !requiresCardDetails;

  return {
    isComplimentary,
    isFreemium,
    cardExpired,
    requiresCardDetails,
    hasActiveSubscription: billing?.hasActiveSubscription,
    requiresSubscriptionReactivation,
  };
}
