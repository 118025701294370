import { Property } from 'csstype';

import {
  Config,
  InjectedTheme,
  Length,
  positiveOrNegative,
  Prop,
} from '../utils';

export const positionConfig: Config = {
  position: true,
  pos: {
    property: 'position',
  },
  zIndex: {
    property: 'zIndex',
    scale: 'zIndices',
  },
  inset: {
    properties: ['left', 'top', 'bottom', 'right'],
    scale: 'space',
    transform: positiveOrNegative,
  },
  insetX: {
    properties: ['left', 'right'],
    scale: 'space',
    transform: positiveOrNegative,
  },
  insetY: {
    properties: ['top', 'bottom'],
    scale: 'space',
    transform: positiveOrNegative,
  },

  top: {
    property: 'top',
    scale: 'space',
    transform: positiveOrNegative,
  },
  right: {
    property: 'right',
    scale: 'space',
    transform: positiveOrNegative,
  },
  bottom: {
    property: 'bottom',
    scale: 'space',
    transform: positiveOrNegative,
  },
  left: {
    property: 'left',
    scale: 'space',
    transform: positiveOrNegative,
  },
};

/**
 * Types for position CSS properties
 */
export interface PositionProps {
  /**
   * The CSS `z-index` property
   */
  zIndex?: Prop<Property.ZIndex | keyof InjectedTheme['zIndices']>;
  /**
   * The CSS `top` property
   */
  top?: Prop<Property.Top<Length>>;
  /**
   * The CSS `right` property
   */
  right?: Prop<Property.Right<Length>>;
  /**
   * The CSS `bottom` property
   */
  bottom?: Prop<Property.Bottom<Length>>;
  /**
   * The CSS `left` property
   */
  left?: Prop<Property.Left<Length>>;
  /**
   * The CSS `left`, `right`, `top`, `bottom` property
   */
  inset?: Prop<Property.Left<Length>>;
  /**
   * The CSS `left`, and `right` property
   */
  insetX?: Prop<Property.Left<Length>>;
  /**
   * The CSS `top`, and `bottom` property
   */
  insetY?: Prop<Property.Left<Length>>;
  /**
   * The CSS `position` property
   */
  pos?: Prop<Property.Position>;
  /**
   * The CSS `position` property
   */
  position?: Prop<Property.Position>;
}
