import { useCallback } from 'react';
import * as React from 'react';
import { FieldRenderProps, useForm } from 'react-final-form';

import { __DEV__, cx } from '@flick-tech/shared-utils';
import { chakra, forwardRef, PropsOf } from '@flick-tech/theme-new';
import {
  FormControlOptions,
  FormControlWrapper,
  FormControlWrapperProps,
  useFormControl,
} from '../form-control';

interface TextareaOptions {
  /**
   * The border color when the textarea is focused. Use color keys in `theme.colors`
   * @example
   * focusBorderColor = "blue.500"
   */
  focusBorderColor?: string;
  /**
   * The border color when the textarea is invalid. Use color keys in `theme.colors`
   * @example
   * errorBorderColor = "red.500"
   */
  errorBorderColor?: string;
  /**
   * If `true`, the textarea element will span the full width of it's parent
   */
  isFullWidth?: boolean;
}

/**
 * Textarea - Theming
 *
 * To style the textarea component globally, change the styles in
 * `theme.components.Textarea`
 */
const StyledTextarea = chakra<'textarea', TextareaOptions>('textarea', {
  themeKey: 'Textarea',
  shouldForwardProp: (prop) =>
    !['focusBorderColor', 'errorBorderColor'].includes(prop),
});

type Omitted = 'disabled' | 'required' | 'readOnly';

export type TextareaProps = Omit<PropsOf<typeof StyledTextarea>, Omitted> &
  FormControlOptions;

/**
 * Textarea
 *
 * React component used to enter an amount of text that's longer than a single line
 *
 * @see Docs https://chakra-ui.com/components/textarea
 */
export const Textarea = forwardRef<TextareaProps, 'textarea', Omitted>(
  function Textarea(props, ref) {
    const { className, ...htmlProps } = props;
    const fieldProps = useFormControl<HTMLTextAreaElement>(htmlProps);
    const _className = cx('chakra-textarea', className);
    return <StyledTextarea className={_className} ref={ref} {...fieldProps} />;
  },
);

if (__DEV__) {
  Textarea.displayName = 'Textarea';
}

type FinalFormTextareaProps = FieldRenderProps<HTMLTextAreaElement> &
  TextareaProps &
  FormControlWrapperProps & {
    hideTouchedError?: boolean;
    wrapperProps?: Partial<FormControlWrapperProps>;
  };

export const FinalFormTextarea = ({
  input,
  meta,
  hideTouchedError,
  helperText,
  label,
  wrapperProps = {},
  onKeyPress,
  ...rest
}: FinalFormTextareaProps) => {
  const { name } = input;
  const { error, submitFailed } = meta;
  const showError =
    ((meta.touched && !hideTouchedError) || submitFailed) && error;

  const { submit } = useForm();

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        submit();
      }

      if (onKeyPress) {
        onKeyPress(e);
      }
    },
    [onKeyPress, submit],
  );

  return (
    <FormControlWrapper
      showError={showError}
      error={error}
      helperText={helperText}
      label={label}
      name={name}
      {...wrapperProps}
    >
      <Textarea {...rest} {...(input as any)} onKeyPress={handleKeyPress} />
    </FormControlWrapper>
  );
};

if (__DEV__) {
  FinalFormTextarea.displayName = 'FinalFormTextarea';
}
