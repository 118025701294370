import {
  useCanManageBilling,
  useHasAccessToTeamsFeatureFlag,
} from '@flick-tech/core-hooks';
import { ProfilePageSection, ROUTES } from '@flick-tech/shared-common';
import { useHasFeatureFlag, useRouteChanged } from '@flick-tech/shared-hooks';
import { IUser, IWorkspace } from '@flick-tech/shared-types';
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  IconName,
  Stack,
} from '@flick-tech/ui';

import { CrossAppNavLink } from '../CrossAppNavLink';

type WorkspaceFeature = string | IWorkspace['features'][number];
export type MaybeElement = JSX.Element | undefined;

interface User
  extends Pick<IUser, 'name' | 'profilePicture' | 'numberOfWorkspaces'> {
  activeWorkspace?: Pick<
    IWorkspace,
    'id' | 'name' | 'features' | 'myRole' | 'numberOfTeamMemberLimit'
  >;
}

export interface ProfileNavigationProps {
  onNavigate: () => void;
  onRequestFeature: () => void;
  user: User | null | undefined;
}

interface MenuItems {
  title: string;
  route?: string;
  icon: IconName | MaybeElement;
  requiredFeature?: WorkspaceFeature;
  isHidden?: boolean;
}

function useMenuItems({ user }: { user: User }): MenuItems[] {
  const hasAccessToSocialGroups = useHasFeatureFlag('socialGroups');

  const hasAccessToTeams = useHasAccessToTeamsFeatureFlag();
  const canManageBilling = useCanManageBilling();

  const menuItems: MenuItems[] = [
    {
      title: 'Account settings',
      route: ROUTES.Profile.Home,
      icon: 'AccountBoxRounded',
    },
    {
      title: 'Team & Users',
      route: ROUTES.Profile.section(ProfilePageSection.TeamMembers),
      icon: 'PeopleAltRounded',
      isHidden: !Boolean(hasAccessToTeams),
    },
    {
      title: 'Manage IG Accounts',
      route: ROUTES.Profile.InstagramAccounts,
      icon: 'PeopleAltRounded',
      isHidden: Boolean(hasAccessToSocialGroups),
    },
    {
      title: 'Billing',
      route: ROUTES.Profile.Billing,
      icon: 'CreditCardRounded',
      isHidden: !canManageBilling,
    },
    {
      title: 'Referrals',
      route: ROUTES.Referrals,
      icon: 'AccountBalanceRounded',
    },
  ];

  return menuItems
    .filter((item) =>
      item.requiredFeature
        ? user.activeWorkspace?.features?.includes(
            item.requiredFeature as IWorkspace['features'][number],
          )
        : true,
    )
    .filter((item) => !item.isHidden);
}

const NavButton = ({ ...props }: ButtonProps) => (
  <Button
    p={2}
    py="6px"
    w="100%"
    justifyContent="flex-start"
    variant="ghost"
    colorScheme="gray"
    color="gray.500"
    height="auto"
    as="a"
    textDecoration="none"
    {...props}
  />
);

export function ProfileNavigation({
  onNavigate,
  onRequestFeature,
  user,
}: ProfileNavigationProps) {
  useRouteChanged(() => {
    onNavigate();
  });

  const menuItems = useMenuItems({ user });

  return (
    <Box
      p={2}
      w="100%"
      overflow="hidden"
      borderRadius="md"
      bg="white"
      boxShadow="0 0 20px 4px rgba(154, 161, 177, 0.07), 0 4px 80px -8px rgba(36, 40, 47, 0.09), 0 4px 4px -2px rgba(91, 94, 105, 0.031) !important"
    >
      <CrossAppNavLink
        route={ROUTES.Profile.section(ProfilePageSection.AccountSettings)}
      >
        <NavButton
          leftIcon={<Avatar size="2xs" mr={1} src={user.profilePicture} />}
        >
          {user.name}
        </NavButton>
      </CrossAppNavLink>
      <Box bg="gray.100" w="100%" h="1px" my={2} />
      <Stack spacing={1} w="100%" align="flex-start">
        {menuItems.map(({ title, route, icon }) => (
          <CrossAppNavLink route={route} key={route}>
            <NavButton leftIcon={icon}>{title}</NavButton>
          </CrossAppNavLink>
        ))}
        <NavButton leftIcon="AddCommentRounded" onClick={onRequestFeature}>
          Request a feature
        </NavButton>
        <NavButton
          leftIcon="NewReleases"
          onClick={() => {
            window.open(
              'https://www.notion.so/flicktech/Flick-Open-Wiki-28d8b1a833b5415991411c215e53f43a',
              '_blank',
            );

            onNavigate();
          }}
        >
          Flick Wiki
        </NavButton>
        <CrossAppNavLink route={ROUTES.Logout}>
          <NavButton leftIcon="ExitToAppRounded">Log out</NavButton>
        </CrossAppNavLink>
      </Stack>
    </Box>
  );
}
