import { SystemProps } from '../../parser';

import { ComponentTheme } from './utils';

const sharedProps: SystemProps = {
  fontSize: 'inherit',
  fontWeight: 'inherit',
  textAlign: 'inherit',
  bg: 'transparent',
  borderRadius: 'md',
  paddingX: '3px',
  marginX: '-3px',
};

const EditableTheme: ComponentTheme = {
  baseStyle: {
    Preview: {
      ...sharedProps,
      cursor: 'text',
      display: 'inline-block',
    },
    Input: {
      ...sharedProps,
      outline: 0,
      width: 'full',
      _focus: {
        boxShadow: 'outline',
      },
      _placeholder: {
        opacity: 0.6,
      },
    },
  },
};

export default EditableTheme;
