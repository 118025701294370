import { SystemProps } from '../../parser';
import { baseSizes } from '../foundations/sizes';

import ButtonTheme from './ButtonTheme';
import LinkTheme from './LinkTheme';
import { ComponentTheme, copy, mode, Props } from './utils';

const getMenuListStyle = (props: Props): SystemProps => {
  return {
    bg: mode('white', `gray.700`)(props),
    boxShadow: mode(`md`, `dark-lg`)(props),
    color: 'inherit',
    outline: 0,
    minWidth: '3xs',
    py: 2,
    zIndex: 'docked',
    borderRadius: 'md',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  };
};

const getMenuItemStyle = (props: Props): SystemProps => {
  return {
    outline: 0,
    textDecoration: 'none',
    py: 2,
    mx: 2,
    px: 3,
    borderRadius: 'md',
    transition: 'background 50ms ease-in 0s',
    bg: 'bg.white',
    color: 'text.base',
    _focus: {
      bg: 'bg.light',
    },
    _hover: {
      bg: 'bg.light',
    },
    _active: {
      bg: 'bg.base',
    },
    _expanded: {
      bg: 'bg.light',
      _active: {
        bg: 'bg.base',
      },
    },
    _disabled: {
      bg: 'bg.white',
      color: 'text.lighter',
      cursor: 'not-allowed',
    },
  };
};

const separatorColor = 'blackAlpha.50';

const defaultProps = ButtonTheme.defaultProps;

const groupTitleStyles: SystemProps = {
  fontWeight: 'semibold',
  fontSize: 'sm',
  mx: 3,
  p: 2,
  width: `calc(100% - ${baseSizes['6']})`,
};

const MenuTheme: ComponentTheme = {
  defaultProps,
  baseStyle: (_props) => {
    const props = { ...defaultProps, ..._props };
    return {
      MenuButton: ButtonTheme.baseStyle as SystemProps,
      MenuList: getMenuListStyle(props),
      MenuItem: getMenuItemStyle(props),
      MenuCollapsibleGroupTitle: groupTitleStyles,
      MenuGroupTitle: groupTitleStyles,
      MenuDivider: {
        borderColor: separatorColor,
        margin: 4,
        mt: 2,
        mb: 3,
      },
      MenuFooter: {
        py: 2,
        mt: 3,
        borderTop: '1px solid',
        borderColor: separatorColor,
        textAlign: 'center',
      },
      MenuFooterLink: {
        ...LinkTheme.baseStyle,
        color: 'gray.400',
        fontSize: 'xs',
        p: 1,
      } as SystemProps,
    };
  },
  variants: {
    /**
     * We're using `copy` function to copy all button variants
     * under the key `MenuButton`.
     *
     * You can ignore this copy and write your own variant styles
     * for the different sub-components.
     *
     * @example
     *
     * variants: {
     *   simple: {
     *     MenuButton: {...}
     *   },
     *   extended: {
     *      MenuButton: {...}
     *   }
     * }
     */
    ...copy(ButtonTheme.variants, 'MenuButton'),
  },
  sizes: {
    /**
     * We're using `copy` function to copy all button sizes
     * under the key `MenuButton`.
     */
    ...copy(ButtonTheme.sizes, 'MenuButton'),
  },
};

export default MenuTheme;
