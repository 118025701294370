import { useCallback, useReducer } from 'react';

/** @internal */
type DisclosureState = 'opened' | 'closed' | 'opening' | 'closing';

type DisclosureAction = 'open' | 'close' | 'animation-end';

const reducer = (
  state: DisclosureState,
  action: DisclosureAction,
): DisclosureState => {
  switch (state) {
    case 'opened':
      return action === 'close' ? 'closing' : state;
    case 'closed':
      return action === 'open' ? 'opening' : state;
    case 'closing':
      return action === 'animation-end' ? 'closed' : state;
    case 'opening':
      return action === 'animation-end' ? 'opened' : state;
  }
};

export function useFloatingActionsDisclosure() {
  const [state, dispatch] = useReducer(reducer, 'closed');

  return {
    state,
    dispatch,
    isOpen: state === 'opened' || state === 'opening',
    onClose: useCallback(() => dispatch('close'), []),
    onToggle: useCallback(() => {
      if (state === 'opened') {
        dispatch('close');
      } else if (state === 'closed') {
        dispatch('open');
      }
    }, [state]),
  };
}

export interface FloatingActionsState
  extends ReturnType<typeof useFloatingActionsDisclosure> {}

export type { DisclosureState as __DisclosureState };
