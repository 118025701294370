import { AsProp, FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, ChakraProps, PropsOf } from '@flick-tech/theme-new';

export interface InlineBoxProps
  extends Omit<PropsOf<'span'>, 'color'>,
    ChakraProps,
    AsProp {}

export const InlineBox: FC<InlineBoxProps> = chakra('span', {
  baseStyle: {
    display: 'inline-block',
  },
});

if (__DEV__) {
  InlineBox.displayName = 'InlineBox';
}
