import { createContext } from 'react';

/** @internal */
export interface __FloatingActionsContext {
  isOpen: boolean;
  onClose: () => void;
  onToggle: () => void;
}

/** @internal */
export const __FloatingActionsContext = createContext<__FloatingActionsContext>(
  {
    isOpen: false,
    onClose: () => {},
    onToggle: () => {},
  },
);
