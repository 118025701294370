import { useCallback } from 'react';

import { useBoolean } from '@flick-tech/shared-hooks';

import { FlickPopover } from './FlickPopover';
import { FlickPopoverPropTypes } from './types';

export function LazyPopover({
  onOpened,
  onClose,
  content,
  title,
  arrow,
  ...rest
}: FlickPopoverPropTypes) {
  const [visible, { on, off }] = useBoolean();

  const handleOpen = useCallback(() => {
    on();

    if (onOpened) {
      onOpened();
    }
  }, [on, onOpened]);

  const handleClose = useCallback(() => {
    off();

    if (onClose) {
      onClose();
    }
  }, [off, onClose]);

  return (
    <FlickPopover
      {...rest}
      noPadding
      arrow={arrow && visible}
      onOpened={handleOpen}
      onClose={handleClose}
      content={visible ? content ?? title : null}
    />
  );
}
