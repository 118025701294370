import { useCallback } from 'react';

import { ROUTES } from '@flick-tech/shared-common';

import { useNavigate } from './useRouter';

export function useNavigateToResource() {
  const navigate = useNavigate();

  return useCallback(
    (slug: string) => {
      navigate(ROUTES.Resources.resource(slug));
    },
    [navigate],
  );
}
