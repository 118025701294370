import { forwardRef, Ref } from 'react';
import * as React from 'react';

import { AsProp, FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, ChakraProps, PropsOf } from '@flick-tech/theme-new';
import { Icon, IconName } from '../icon';

export interface LinkOptions {
  /**
   *  If `true`, the link will open in new tab
   */
  isExternal?: boolean;
  /**
   * Function called when the link is clicked
   */
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  /**
   * If added, the link will show an icon before the link's text.
   */
  leftIcon?: IconName;
  /**
   * If added, the link will show an icon after the link's text.
   */
  rightIcon?: IconName;
  /**
   * The space between the button icon and label.
   */
  iconSpacing?: string | number;
}

export interface LinkProps
  extends Omit<PropsOf<'a'>, 'color'>,
    ChakraProps,
    AsProp,
    LinkOptions {}

const StyledLink: FC<LinkProps> = chakra<'a', LinkOptions>('a', {
  themeKey: 'Link',
  baseStyle: {
    // For focused style
    borderRadius: 'md',
  },
  attrs: (props) => ({
    tabIndex: props.isDisabled ? -1 : undefined,
    'aria-disabled': props.isDisabled || undefined,
    ...(props.isExternal && {
      target: '_blank',
      rel: 'noopener noreferrer',
    }),
  }),
});

const iconStyle = {
  position: 'relative',
  top: '0.16em',
} as const;

/**
 * Link
 *
 * Links are accessible elements used primarily for navigation.
 *
 * It integrates well with other routing libraries like
 * React Router, Reach Router and Next.js Link.
 *
 * @example
 *
 * ```jsx
 * <Link as={ReactRouterLink} to="/home">Home</Link>
 * ```
 *
 * @see Docs https://chakra-ui.com/link
 */
export const Link: FC<LinkProps> = forwardRef(
  (props: LinkProps, ref: Ref<any>) => {
    const {
      leftIcon,
      rightIcon,
      children,
      iconSpacing = '0.25em',
      ...rest
    } = props;

    return (
      <StyledLink ref={ref} {...rest}>
        {/* TODO: icon component that supports underline and chakra sizing */}
        {leftIcon && <Icon {...iconStyle} icon={leftIcon} mr={iconSpacing} />}
        {children}
        {rightIcon && <Icon {...iconStyle} icon={rightIcon} ml={iconSpacing} />}
      </StyledLink>
    );
  },
);

if (__DEV__) {
  Link.displayName = 'Link';
}
