import { diffInDaysRoundedUp } from '@flick-tech/shared-utils';

import { useGetUser } from '../useGetUser';
import { useIsOnNoCardPlan } from '../useHasActiveSubscription';

export function usePostsTrackedLimits() {
  const { billing, loading } = useGetUser();

  const isUnlimited = billing?.postsTrackedLimit?.isUnlimited ?? false;
  const limit = billing?.postsTrackedLimit?.limit;

  return {
    loading,
    isUnlimited,
    limit,
  };
}

export function useNoCardPlanTrialEnd() {
  const isOnNoCardPlan = useIsOnNoCardPlan();
  const { billing, loading } = useGetUser();

  const status = billing?.subscription?.status;
  const trialEnd = billing?.subscription?.trialEnd;
  const isTrialling = status === 'IN_TRIAL' && trialEnd && isOnNoCardPlan;

  const daysRemaining = isTrialling
    ? diffInDaysRoundedUp(new Date(trialEnd), new Date())
    : undefined;

  return {
    isTrialling,
    daysRemaining,
    loading,
    trialEnd: isTrialling ? new Date(trialEnd) : undefined,
  };
}
