import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

const useEnhancedEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 *
 * @param {function} fn
 */
export default function useEventCallback<T extends (...args: any[]) => any>(
  fn: T,
): T {
  const ref = useRef(fn);
  useEnhancedEffect(() => {
    ref.current = fn;
  });
  // This is some hook wizardy (from link above) to prevent unnecessary re-renders
  // added as any not to ts-ignore the whole line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args) => (0 as any, ref.current)(...args)) as T, []);
}
