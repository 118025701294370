import isAfter from 'date-fns/isAfter';

import { useGetUser } from '../useGetUser';

interface AnalyticsTrialInfo {
  hasStartedTrial: boolean;
  hasTrialEnded: boolean;
  loading: boolean;
  analyticsTrialEnd?: Date;
}

export function hasTrialEnded(trialEnd: Date | null): boolean {
  if (!trialEnd) {
    return true;
  }

  return isAfter(new Date(), trialEnd);
}

export function useHasActiveAnalyticsTrial(): AnalyticsTrialInfo {
  const { user, loading } = useGetUser();

  const analyticsTrialEnd =
    user?.activeWorkspace?.billing?.subscription?.analyticsTrialEnd;

  return {
    hasStartedTrial: !!analyticsTrialEnd,
    hasTrialEnded: hasTrialEnded(analyticsTrialEnd),
    loading,
  };
}
