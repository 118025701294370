import { Button } from '../../button';
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ScaleFadeModal,
} from '../../modal';

function ConfirmDialog(props) {
  const { open, onClose, onExited, title, message, ok, cancel } = props;

  return (
    <ScaleFadeModal
      isOpen={open}
      onClose={() => {
        onClose(false);
        onExited();
      }}
    >
      <>
        {title && (
          <ModalHeader>
            {title}
            <ModalCloseButton />
          </ModalHeader>
        )}
        <ModalBody lineHeight={1.5}>{message}</ModalBody>
        <ModalFooter>
          <Button mr={2} onClick={() => onClose(false)} colorScheme="gray">
            {cancel}
          </Button>
          <Button onClick={() => onClose(true)}>{ok}</Button>
        </ModalFooter>
      </>
    </ScaleFadeModal>
  );
}

ConfirmDialog.defaultProps = {
  open: false,
  title: '',
  ok: 'OK',
  cancel: 'Cancel',
};

export default ConfirmDialog;
