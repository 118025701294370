import { useCallback } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';

import { Logger } from '@flick-tech/logger';
import { isNullOrUndefined } from '@flick-tech/shared-utils';
import { defaultRequiredFacebookScopes } from '@flick-tech/ui';

import {
  IGetSocialGroupIgAccountAuthStateQuery,
  IGetSocialGroupIgAccountAuthStateQueryVariables,
  useGetSocialGroupIgAccountAuthStateQuery,
} from './useSocialGroupIGAccountAuthStateQuery.generated';

interface UseSocialGroupIGAccountAuthStateParams {
  socialGroupId: string | undefined;
  options?: UseQueryOptions<IGetSocialGroupIgAccountAuthStateQuery>;
}

export function useSocialGroupIGAccountAuthState({
  socialGroupId,
  options = {},
}: UseSocialGroupIGAccountAuthStateParams) {
  const enabledFromOptions = isNullOrUndefined(options?.enabled)
    ? true
    : options?.enabled;

  const { data, ...rest } = useGetSocialGroupIgAccountAuthStateQuery(
    {
      id: socialGroupId!,
    },
    {
      suspense: false,
      enabled: enabledFromOptions && Boolean(socialGroupId),
      ...options,
    },
  );

  const authState = data?.socialGroup?.igAccount?.authState;

  const hasRequiredScopes = defaultRequiredFacebookScopes.every(
    (requiredScope) => (authState?.scopes ?? []).includes(requiredScope),
  );

  return {
    ...rest,
    authState: authState
      ? {
          ...authState,
          isValid: authState.isValid && hasRequiredScopes,
        }
      : authState,
  };
}

export function useRefetchSocialGroupIGAccountAuthState() {
  const queryClient = useQueryClient();

  return useCallback(
    (variables: IGetSocialGroupIgAccountAuthStateQueryVariables) =>
      queryClient.refetchQueries(
        useGetSocialGroupIgAccountAuthStateQuery.getKey(variables),
      ),
    [queryClient],
  );
}
