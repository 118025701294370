import { SystemProps } from './parser';

/**
 * A place to put common style sets to use with chakra components
 */

export const hideScrollbarsStyles = {
  WebkitOverflowScrolling: 'touch',
  msOverflowStyle: '-ms-autohiding-scrollbar',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  sx: { overflowY: 'auto' },
  overflowY: 'overlay',
} as SystemProps;
