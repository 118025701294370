import {
  ComponentPropsWithoutRef,
  createContext,
  useContext,
  useMemo,
} from 'react';
import * as React from 'react';
import { atom, useRecoilState } from 'recoil';

import { chakra, SystemStyleObject } from '@flick-tech/theme-new';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  AccordionProps,
} from '../accordion';
import { IconButton } from '../button';
import {
  Box,
  BoxProps,
  Flex,
  Heading__deprecated,
  Stack,
  Text,
} from '../layout';
import { useMedia } from '../media-query';

const isSidenavOpenOnSmallerScreens = atom({
  key: 'isSidenavOpen',
  default: false,
});

interface ChildProps {
  style: {
    containerStyle: React.CSSProperties;
    sidenavStyle: React.CSSProperties;
  };
  iconButton: React.ReactNode;
}

interface SidenavResponsiveContainerProps extends React.CSSProperties {
  children: (value: ChildProps) => React.ReactNode;
  sidenavWidth?: string;
  iconButtonTop?: string;
}
export function SidenavResponsiveContainer({
  children,
  iconButtonTop,
  sidenavWidth = '0px',
}: SidenavResponsiveContainerProps) {
  const [state, setState] = useRecoilState(isSidenavOpenOnSmallerScreens);
  const isLarge = useMedia({ min: 'lg' });

  return (
    <>
      {children({
        style: isLarge
          ? {
              containerStyle: {},
              sidenavStyle: {
                transition: 'margin-left 750ms ease',
                marginLeft: 0,
              },
            }
          : state
          ? {
              containerStyle: {
                transition: 'all 900ms ease',
                transform: `translate3d(${sidenavWidth}, 0, 0)`,
              },
              sidenavStyle: {
                transition: 'margin-left 900ms ease',
                marginLeft: `-${sidenavWidth}`,
              },
            }
          : {
              containerStyle: {
                transition: 'all 750ms ease',
                transform: `translate3d(0, 0, 0)`,
              },
              sidenavStyle: {
                transition: 'margin-left 750ms ease',
                marginLeft: `-${sidenavWidth}`,
              },
            },
        iconButton: !isLarge && (
          <IconButton
            isRound
            onClick={() => setState(!state)}
            top={iconButtonTop ? `calc(5.5rem + ${iconButtonTop})` : '5.5rem'}
            ml={4}
            position="absolute"
            zIndex="docked"
            transition="transform 825ms ease"
            style={{
              filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16))',
            }}
            _directChildren={{ w: 6, h: 6 }}
            {...(state
              ? {
                  icon: 'ArrowBack',
                  'aria-label': 'Collapse side navigation menu',
                  transform: `translateX(${sidenavWidth})`,
                }
              : {
                  icon: 'ConeMenu',
                  'aria-label': 'Show side navigation menu',
                })}
          />
        ),
      })}
    </>
  );
}

const scrollbarStyles: SystemStyleObject = {
  '::-webkit-scrollbar': {
    width: '13px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'whiteAlpha.300',
    backgroundClip: 'padding-box',
    border: '3px solid rgba(0, 0, 0, 0)',
    borderRadius: '100px',
    '&:hover': {
      borderWidth: '3px',
      backgroundColor: 'whiteAlpha.400',
    },
  },
  '::-webkit-scrollbar-corner': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
};

interface SidenavCtxValue {
  onLinkClick?: (href: string) => void;
}

const sidenavCtx = createContext<SidenavCtxValue>({});

export interface SidenavProps extends BoxProps, SidenavCtxValue {}
export const Sidenav = ({ children, onLinkClick, ...rest }: SidenavProps) => {
  const ctxValue = useMemo(() => {
    return { onLinkClick };
  }, [onLinkClick]);

  return (
    <sidenavCtx.Provider value={ctxValue}>
      <Box
        py={2}
        px={4}
        borderRight="1px solid"
        borderRightColor="gray.100"
        overflowY={'overlay' as 'auto'}
        overflowX="auto"
        h="100%"
        sx={scrollbarStyles}
        {...rest}
      >
        {children}
      </Box>
    </sidenavCtx.Provider>
  );
};

const defaultIndex = 0;

export interface SidenavSectionProps extends Omit<BoxProps, 'onChange'> {
  heading: string;
  indexes?: AccordionProps['index'];
  onChange?: (expandedIndexes: number) => void;
}
export const SidenavSection = ({
  heading,
  children,
  indexes,
  onChange,
  ...rest
}: SidenavSectionProps) => {
  return (
    <Box as="section" pt="2" px="2" {...rest}>
      <Heading__deprecated
        as="h2"
        color="gray.500"
        py="2"
        fontWeight={700}
        fontSize="md"
        lineHeight="19px"
        letterSpacing="0.12em"
        textTransform="uppercase"
        fontFamily="body"
        mb={1}
      >
        {heading}
      </Heading__deprecated>
      <Accordion
        allowToggle
        defaultIndex={defaultIndex}
        index={indexes ?? -1}
        onChange={
          onChange &&
          ((value) => {
            if (typeof value === 'number') {
              onChange(value);
            }
          })
        }
      >
        {children}
      </Accordion>
    </Box>
  );
};

const onButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
  const target = event.currentTarget;
  switch (event.key) {
    case 'ArrowRight':
      if (target.getAttribute('aria-expanded') === 'false') {
        target.click();
      }
      break;
    case 'ArrowLeft':
      if (target.getAttribute('aria-expanded') === 'true') {
        target.click();
      }
      break;
  }
};

export interface SidenavGroupProps extends AccordionItemProps {
  title: string;
  isActive?: boolean;
}
export const SidenavGroup = ({
  title,
  children,
  isActive,
  ...rest
}: SidenavGroupProps) => {
  return (
    <AccordionItem pb="10px" pt="2px" {...rest}>
      <Flex as="h3">
        <AccordionButton
          fontWeight={600}
          fontFamily="body"
          lineHeight="short"
          color={isActive ? 'brand.500' : 'gray.700'}
          justifyContent="space-between"
          transition="all 250ms"
          textAlign="left"
          width="auto"
          flexGrow="1"
          py="2"
          px="2"
          mx="-2"
          _focusVisible={{ backgroundColor: 'blackAlpha.50' }}
          onKeyDown={onButtonKeyDown}
        >
          {title}
          <AccordionIcon />
        </AccordionButton>
      </Flex>
      <AccordionPanel>
        <Stack pt={2} spacing={2} align="flex-start">
          {children}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};

const onLinkKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
  const target = event.currentTarget;

  const previous = target.previousElementSibling;
  const next = target.nextElementSibling;
  const parentAccordion = target
    .closest('.chakra-accordion__item')
    ?.querySelector('button');

  switch (event.key) {
    case 'ArrowUp': {
      event.preventDefault();
      if (previous && 'focus' in previous) {
        (previous as HTMLElement).focus();
      } else {
        parentAccordion?.focus();
      }

      break;
    }
    case 'ArrowDown':
      if (next && 'focus' in next) {
        event.preventDefault();
        (next as HTMLElement).focus();
      }
      break;
  }
};

export interface SidenavLinkProps
  extends ComponentPropsWithoutRef<typeof chakra.a> {
  href: string;
  children: React.ReactNode;
  isActive?: boolean;
}
export const SidenavLink = ({
  href,
  children,
  isActive,
  ...rest
}: SidenavLinkProps) => {
  const { onLinkClick } = useContext(sidenavCtx);

  const onClick =
    onLinkClick &&
    ((event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      onLinkClick(href);
    });

  const focusHoverStyle = {
    backgroundColor: 'blackAlpha.50',
    color: 'gray.900',
  };

  return (
    <chakra.a
      role="group"
      onClick={onClick}
      href={href}
      textDecoration="none"
      color="gray.700"
      width="100%"
      px="2"
      py="1"
      transition="all 250ms"
      borderRadius="md"
      _focusVisible={focusHoverStyle}
      _hover={focusHoverStyle}
      onKeyDown={onLinkKeyDown}
      {...rest}
    >
      <Text
        as="span"
        display="block"
        pl={3}
        borderRadius="sm"
        transition="all 250ms"
        color={isActive ? 'brand.500' : 'gray.700'}
        fontSize="md"
        lineHeight="base"
      >
        {children}
      </Text>
    </chakra.a>
  );
};
