import { Constants } from '@flick-tech/shared-common';

import { getColor } from '../../color';

import { ComponentTheme, mode, Props, StyleProps } from './utils';

type VariantProps = Props & Required<InputProps>;

const getDefaults = (props: VariantProps) => {
  const colorScheme = props.colorScheme ?? Constants.defaultColorTheme;

  return {
    focusBorderColor:
      props.focusBorderColor ||
      mode(`${colorScheme}.500`, `${colorScheme}.300`)(props),
    errorBorderColor:
      props.errorBorderColor || mode('red.500', 'red.300')(props),
    colorScheme: props.colorScheme ?? 'gray',
  };
};

function getOutlineStyle(props: VariantProps): StyleProps {
  const { theme: t } = props;

  const {
    focusBorderColor: fc,
    errorBorderColor: ec,
    colorScheme: c,
  } = getDefaults({
    ...props,
    variant: 'outline',
  });

  return {
    border: '1px solid',
    borderColor: mode('inherit', 'whiteAlpha.50')(props),
    bg: mode('white', 'whiteAlpha.100')(props),
    _hover: {
      borderColor: mode(`${c}.300`, 'whiteAlpha.200')(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    _focus: {
      zIndex: 1,
      borderColor: getColor(t, fc),
      boxShadow: `0 0 0 1px ${getColor(t, fc)}`,
    },
    _invalid: {
      borderColor: getColor(t, ec),
      boxShadow: `0 0 0 1px ${getColor(t, ec)}`,
    },
    '::placeholder': {
      color: mode(`${c}.400`, 'whiteAlpha.400')(props),
    },
  };
}

function getFilledWhiteInputStyle(props: Props) {
  const hoverStyles = {
    bg: 'bg.lighter',
    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.06))',
  };

  return {
    bg: 'white',
    color: '#5C5C5C',
    border: '1px solid #EBEBEB',
    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.06))',
    _hover: hoverStyles,
    _focusVisible: hoverStyles,
    _active: {
      color: 'brand.500',
    },
    _focus: {},
  };
}

export function getFilledInputStyle(props: VariantProps): StyleProps {
  if (props.colorScheme === 'white') {
    return getFilledWhiteInputStyle(props);
  }

  const { theme: t } = props;

  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    border: '2px solid',
    borderColor: 'transparent',
    bg: mode(`gray.50`, 'whiteAlpha.50')(props),
    _hover: {
      bg: mode('gray.100', 'whiteAlpha.100')(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    _focus: {
      zIndex: 1,
      bg: 'white',
      borderColor: getColor(t, fc),
    },
    _invalid: {
      borderColor: getColor(t, ec),
    },
  };
}

function getFlushedStyle(props: VariantProps): StyleProps {
  const { theme: t } = props;

  const {
    focusBorderColor: fc,
    errorBorderColor: ec,
    colorScheme: c,
  } = getDefaults(props);

  return {
    borderBottom: '2px solid',
    borderColor: 'inherit',
    borderRadius: 0,
    paddingX: 0,
    bg: 'transparent',
    _focus: {
      zIndex: 1,
      borderColor: getColor(t, fc),
    },
    _invalid: {
      borderColor: getColor(t, ec),
    },
  };
}

const unstyled = {
  bg: 'transparent',
  paddingX: 0,
  height: 'auto',
};

const sizes: InputThemeProps['sizes'] = {
  lg: {
    fontSize: 'lg',
    paddingX: 4,
    height: 12,
    borderRadius: 'md',
  },
  md: {
    fontSize: 'md',
    paddingX: 4,
    height: 10,
    borderRadius: 'md',
  },
  sm: {
    fontSize: 'sm',
    paddingX: 3,
    height: 8,
    borderRadius: 'sm',
  },
};

const variants = {
  outline: getOutlineStyle,
  filled: getFilledInputStyle,
  flushed: getFlushedStyle,
  unstyled,
};

type InputVariant = keyof typeof variants;

export type InputProps = {
  focusBorderColor?: string;
  errorBorderColor?: string;
  variant?: InputVariant;
};

export type InputThemeProps = ComponentTheme<InputProps>;

const InputTheme: InputThemeProps = {
  defaultProps: {
    size: 'md',
    variant: 'filled',
  },
  baseStyle: {
    width: '100%',
    outline: 0,
    transition: 'all 0.2s',
  },
  sizes,
  variants,
};

export default InputTheme;
