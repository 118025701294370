import {
  preventPassThrough,
  ProfilePageSection,
} from '@flick-tech/shared-common';
import { useNavigateToProfileSection } from '@flick-tech/shared-hooks';
import { Icon } from '@flick-tech/ui';
import { Box, BoxProps, Link, Text } from '@flick-tech/ui';

interface LostConnectionInfoCardProps extends BoxProps {}

export function LostConnectionInfoCard({
  ...rest
}: LostConnectionInfoCardProps) {
  const navigate = useNavigateToProfileSection();

  return (
    <Box
      bg="white"
      borderRadius="md"
      border="1p solid"
      borderColor="gray.100"
      p={2}
      {...rest}
    >
      <Text color="gray.800">
        <Icon icon="WarningRounded" fontSize="inherit" display="inline" />{' '}
        <Text fontWeight={500} fontSize="inherit" display="inline" as="span">
          Connection Lost.
        </Text>{' '}
        To continue tracking your posts and analytics please{' '}
        <Link
          display="inline-block"
          fontSize="inherit"
          color="brand.500"
          textDecoration="underline"
          fontWeight={500}
          onClick={(e) => {
            // This is used inside of components with an onClick handler on the top level.
            // We don't want to trigger the top level click handler through this click
            preventPassThrough(e);
            navigate(ProfilePageSection.InstagramAccounts);
          }}
        >
          refresh your access token
        </Link>
        .
      </Text>
    </Box>
  );
}
