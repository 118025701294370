import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, keyframes, PropsOf } from '@flick-tech/theme-new';
import { VisuallyHidden } from '../visually-hidden';

const spin = keyframes`
  0% {  transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`;

/**
 * Spinner - Theming
 *
 * To style the spinner component globally, change the styles in
 * `theme.components.Spinner`
 */
const StyledSpinner = chakra('div', { themeKey: 'Spinner' });

export interface SpinnerOptions {
  /**
   * The color of the empty area in the spinner
   */
  emptyColor?: string;
  /**
   * The color of the spinner
   */
  color?: string;
  /**
   * The thickness of the spinner
   * @example
   * ```jsx
   * <Spinner thickness="4px"/>
   * ```
   */
  thickness?: string;
  /**
   * The speed of the spinner.
   * @example
   * ```jsx
   * <Spinner speed="0.2s"/>
   * ```
   */
  speed?: string;
  /**
   * For accessibility, it's important to add a fallback loading text.
   * This text will be visible to screen readers.
   */
  label?: string;
}

export type SpinnerProps = PropsOf<typeof StyledSpinner> & SpinnerOptions;

/**
 * Spinner
 *
 * React component used to indicate the loading state of a page or a component,
 *
 * It renders a `div` by default
 *
 * @see Docs https://chakra-ui.com/spinner
 */
export const Spinner = (props: SpinnerProps) => {
  const {
    label = 'Loading...',
    thickness = '2px',
    speed = '0.45s',
    color = 'brand.500',
    emptyColor = 'transparent',
    ...rest
  } = props;

  return (
    <StyledSpinner
      display="inline-block"
      borderColor="currentColor"
      borderStyle="solid"
      borderRadius="full"
      borderWidth={thickness}
      borderBottomColor={emptyColor}
      borderLeftColor={emptyColor}
      color={color}
      animation={`${spin} ${speed} linear infinite`}
      aria-busy={true}
      {...rest}
    >
      {label && <VisuallyHidden>{label}</VisuallyHidden>}
    </StyledSpinner>
  );
};

if (__DEV__) {
  Spinner.displayName = 'Spinner';
}
