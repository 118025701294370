import styled from '@emotion/styled';
import Tippy from '@tippy.js/react';

import { isMobile } from '@flick-tech/shared-common';
import { InlineBox } from '../layout';

import { FlickPopoverPropTypes } from './types';

const StyledTippy = styled(
  ({ noPadding, padding, tippyTheme, ...rest }: any) => (
    <Tippy {...rest} theme={tippyTheme} />
  ),
)<{
  noPadding?: boolean;
  padding?: string;
  tippyTheme?: FlickPopoverPropTypes['theme'];
}>`
  border-radius: 8px;

  .tippy-content {
    border-radius: 8px;
    overflow: hidden;
  }

  ${(props) => {
    if (props.noPadding) {
      return `
        padding: 0;

        > .tippy-content {
          padding: 0;
        }
      `;
    }

    if (props.padding) {
      return `
        padding: 0;

        > .tippy-content {
          padding: ${props.padding};
        }

        `;
    }
  }}
`;

const Content = styled.div`
  z-index: 999;
`;

const standardDelay: [number, number] = [500, 0];

const defaultFunction = () => {};

export function FlickPopover({
  enabled = !isMobile(),
  visible,
  lazy,
  title,
  children,
  distance,
  position = 'auto',
  theme = 'light',
  arrow = true,
  trigger = 'mouseenter',
  hideOnClick = true,
  interactive = false,
  maxWidth = 350,
  noPadding,
  padding = '4px 8px',
  boundary = 'scrollParent',
  onClose,
  onOpened,
  popperOptions,
  className,
  content,
  wrapperProps = {},
  delay,
  // We don't want to pass in an onClick, sometimes it's passed by accident
  // @ts-ignore
  onClick,
  ...rest
}: FlickPopoverPropTypes) {
  return (
    <>
      <StyledTippy
        noPadding={noPadding}
        padding={padding}
        onHidden={onClose || defaultFunction}
        onShown={onOpened || defaultFunction}
        distance={distance}
        boundary={boundary}
        enabled={enabled}
        visible={visible}
        interactive={interactive}
        a11y={false}
        isEnabled={!!title}
        content={<Content>{content ?? title ?? ''}</Content>}
        placement={position}
        trigger={trigger}
        animation="fade"
        duration={[500, 300]}
        delay={delay === 'standard' ? standardDelay : delay}
        tippyTheme={theme}
        animateFill={false}
        arrow={arrow}
        hideOnClick={hideOnClick}
        maxWidth={maxWidth}
        lazy={lazy}
        popperOptions={popperOptions}
        {...rest}
      >
        <InlineBox className={className} {...wrapperProps}>
          {children}
        </InlineBox>
      </StyledTippy>
    </>
  );
}

export default FlickPopover;
