import { isDark, randomColor } from '../../color';
import { SystemProps } from '../../parser/parser.types';
import sizes from '../foundations/sizes';

import { ComponentTheme, mode, Props } from './utils';

function getSize(size: string) {
  const themeSize = sizes[size as keyof typeof sizes];

  const styles: SystemProps = {
    width: size,
    height: size,
    fontSize: `calc(${themeSize ?? size} / 2.5)`,
  };

  if (size !== '100%') {
    styles.lineHeight = themeSize ?? size;
  }

  return {
    Root: styles,
    ExcessLabel: styles,
  };
}

function getRootStyle(props: Props & { name?: string }) {
  const { name, colorScheme = 'brand', theme: t } = props;

  const bg = name ? `${colorScheme}.500` : 'gray.400';
  const isBgDark = isDark(bg)(t);

  const color = name ? (isBgDark ? 'white' : 'gray.800') : 'white';
  const borderColor = mode('white', 'gray.800')(props);

  return {
    bg,
    color,
    borderColor,
  };
}

type AvatarProps = { name?: string };

const AvatarTheme: ComponentTheme<AvatarProps> = {
  defaultProps: {
    size: 'md',
  },
  baseStyle: (props) => ({
    Root: {
      verticalAlign: 'top',
      ...getRootStyle(props),
    },
    Badge: {
      transform: 'translate(25%, 25%)',
      borderRadius: 'full',
      border: '0.2em solid',
      borderColor: mode('white', 'gray.800')(props),
    },
    ExcessLabel: {
      bg: mode('gray.200', 'whiteAlpha.400')(props),
    },
  }),
  sizes: {
    '2xs': getSize('6'),
    xs: getSize('8'),
    sm: getSize('10'),
    md: getSize('12'),
    lg: getSize('16'),
    xl: getSize('24'),
    '2xl': getSize('32'),
    full: getSize('100%'),
  },
};

export const AvatarSizes = {
  '2xs': '2xs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  '2xl': '2xl',
  full: 'full',
};

export default AvatarTheme;
