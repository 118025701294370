import whyDidYouRender from '@welldone-software/why-did-you-render';
import * as React from 'react';

import {
  Constants,
  isRunningOnServerSide,
} from '@flick-tech/shared-common'

if (!isRunningOnServerSide() && Constants.isDev) {
  whyDidYouRender(React);
}
