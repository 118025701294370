import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import {
  useFlickAccessToken,
  useGraphqlFetch,
  useHasFeatureFlag,
} from '@flick-tech/shared-hooks';
import { IWorkspaceRole } from '@flick-tech/shared-types';

import {
  GetLoggedInUserDocument,
  IGetLoggedInUserQuery,
  IGetLoggedInUserQueryVariables,
  useGetLoggedInUserQuery,
} from './useGetUserQuery.generated';

export type { IGetLoggedInUserQuery };

const variables = {};

export function useRefetchUser() {
  const queryClient = useQueryClient();

  return useCallback(
    () =>
      queryClient.refetchQueries({
        queryKey: useGetLoggedInUserQuery.getKey(variables),
      }),
    [queryClient],
  );
}

export function useGetUser() {
  const { accessToken } = useFlickAccessToken();

  const { data, isLoading, ...rest } = useGetLoggedInUserQuery(variables, {
    enabled: Boolean(accessToken && accessToken?.length > 0),
  });

  const queryClient = useQueryClient();

  const fetchFn = useGraphqlFetch<
    IGetLoggedInUserQuery,
    IGetLoggedInUserQueryVariables
  >(GetLoggedInUserDocument);

  const activeWorkspace = data?.me?.activeWorkspace;
  const billing = activeWorkspace?.billing;

  return {
    ...rest,
    loading: isLoading,
    isLoading,
    user: data?.me,
    activeWorkspace,
    billing,
    getUserQuery: useCallback(() => {
      return queryClient.fetchQuery<
        IGetLoggedInUserQuery,
        IGetLoggedInUserQueryVariables
      >(useGetLoggedInUserQuery.getKey(), fetchFn.bind(null, variables));
    }, [fetchFn, queryClient]),
  };
}

export type LoggedInUser = IGetLoggedInUserQuery['me'];

useGetUser.getKey = () => useGetLoggedInUserQuery.getKey(variables);

export function useMyActiveWorkspace() {
  const { user } = useGetUser();

  return user?.activeWorkspace;
}

export function useMyRoleInActiveWorkspace(): IWorkspaceRole {
  const workspace = useMyActiveWorkspace();

  return workspace?.myRole;
}

const adminAndOwner: IWorkspaceRole[] = ['Admin', 'Owner'];

export function useIsAdminOrOwnerOfActiveWorkspace() {
  const myRole = useMyRoleInActiveWorkspace();

  return adminAndOwner.includes(myRole);
}

// See loom from 2:54 https://www.loom.com/share/bb9b5f6c237c4d0fbc0509c0c95c6891
// Should be used instead
export function useHasAccessToTeamsFeatureFlag() {
  const hasAccessToTeams = useHasFeatureFlag('teams');
  const { user } = useGetUser();
  // Implies that they should have access to teams
  const hasBeenInvitedToAnotherWorkspace = user?.numberOfWorkspaces > 1;

  const role = useMyRoleInActiveWorkspace();
  // They have been invited
  const isAMemberOfSomeoneElsesWorkspace = role === 'Member';

  // This implies they should have access, as they've been invited by someone who has access
  return (
    hasAccessToTeams ||
    isAMemberOfSomeoneElsesWorkspace ||
    hasBeenInvitedToAnotherWorkspace
  );
}
