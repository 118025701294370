import styled from '@emotion/styled';
import { forwardRef, Ref } from 'react';
import * as React from 'react';

import { FormLabel } from '../../../form-control';
import { Icon, IconName } from '../../../icon';
import { Input, InputProps } from '../../../input';
import { Spinner } from '../../../spinner';

const InputWrapper = styled.div<{
  fullWidth?: boolean;
}>`
  position: relative;

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `};
`;

interface ButtonProps {
  pointer?: boolean;
  iconPosition?: 'left' | 'right';
}
const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 100%;
  padding: 0;

  color: #3174e0;
  background-color: transparent;
  transition: box-shadow 0.3s ease 0s, background-color 0.2s ease 0s,
    color 0.2s ease 0s;
  ${({ pointer }) => pointer && `cursor: pointer;`};
  border: 0;

  position: absolute;
  top: 0;
  ${({ iconPosition }) =>
    iconPosition === 'left' ? { left: '14px' } : { right: '14px' }}

  &:focus,
  &:hover {
    color: rgb(0, 85, 255);
    outline: none;
  }
`;

export interface CustomInputTextProps {
  error?: string;
  icon?: IconName | Exclude<React.ReactNode, string>;
  onClickIcon?: () => void;
  loading?: boolean;
  noShadow?: boolean;
  fullWidth?: boolean;
  label?: string;
  large?: boolean;
  iconPosition?: 'left' | 'right';
}

interface BaseInputElementProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputProps> {}

export interface InputTextProps
  extends BaseInputElementProps,
    CustomInputTextProps,
    InputProps {}

const noop = () => {};

export const InputText = forwardRef(function InputText(
  {
    error,
    icon,
    loading,
    fullWidth,
    label,
    onClickIcon = noop,
    iconPosition = 'right',
    ...rest
  }: InputTextProps,
  ref: Ref<HTMLInputElement>,
) {
  const renderedIcon =
    typeof icon === 'string' ? (
      <Icon
        icon={icon as IconName}
        onClick={onClickIcon}
        height="18px"
        width="18px"
      />
    ) : (
      icon
    );

  return (
    <InputWrapper fullWidth={fullWidth}>
      {label && <FormLabel mb={2}>{label}</FormLabel>}
      <Input
        ref={ref}
        isInvalid={!!error}
        variant="filled"
        {...(iconPosition === 'left' && { pl: 12 })}
        {...rest}
      />
      {icon && (
        <Button
          type="submit"
          tabIndex={-1}
          iconPosition={iconPosition}
          pointer={typeof icon !== 'string' || onClickIcon !== noop}
        >
          {loading ? <Spinner ml="2px" size="xs" /> : renderedIcon}
        </Button>
      )}
    </InputWrapper>
  );
});
