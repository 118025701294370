import { MutableRefObject, ReactNode } from 'react';

import { Icon, IconName } from '../../../icon';
import { Box, BoxProps, Flex, Text } from '../../../layout';

type ContentProps = Pick<
  NavigationButtonProps<any>,
  'active' | 'size' | 'disabled'
> &
  BoxProps;

function Content({ active, disabled, ...props }: ContentProps) {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      transition="color, background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)"
      fontWeight={500}
      lineHeight="24px"
      color={active ? 'rgb(50, 117, 224)' : 'blackAlpha.600'}
      sx={{
        '&:hover:after, &:focus:after': {
          backgroundColor: 'currentColor',
          opacity: active ? 1 : 0.25,
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '2px',
          bottom: '1px',
          borderRadius: '1px',

          backgroundColor: active ? 'brand.500' : 'transparent',
          transform: 'translateY(1px)',
        },
        ...(props.size === 'small' ? { fontSize: '14px' } : {}),
      }}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      {...props}
    />
  );
}

type Size = 'small' | 'medium';

export interface BaseOption<T extends string | number = string> {
  label: string;
  value: T;
  component?: any;
  icon?: IconName;
  intercomId?: string;
}

interface NavigationButtonPropsOwnProps<T extends string | number> {
  text: ReactNode;
  active?: boolean;
  icon?: IconName;
  option?: BaseOption<T>;
  activeOption?: BaseOption<T>;
  onClick: (option?: BaseOption<T>) => void;
  popoverContent?: ReactNode;
  disabled?: boolean;
  wrapperRef?: MutableRefObject<HTMLDivElement>;
  size?: Size;
  suboptions?: BaseOption<T>[];
}

interface NavigationButtonProps<T extends string | number>
  extends NavigationButtonPropsOwnProps<T>,
    Omit<BoxProps, 'onClick' | 'size'> {}

function NavigationButton<T extends string | number>({
  disabled,
  popoverContent,
  text,
  active,
  onClick,
  icon,
  size = 'medium',
  wrapperRef,
  suboptions,
  activeOption,
  option,
  ...rest
}: NavigationButtonProps<T>) {
  const canClick = typeof onClick === 'function' && !disabled;

  const isActive =
    active ||
    (option && activeOption?.value === option.value) ||
    Boolean(suboptions?.find(({ value }) => value === activeOption?.value));

  return (
    <Box {...rest} ref={wrapperRef}>
      <Content
        borderRadius="md"
        fontSize="16px"
        fontWeight="medium"
        px={4}
        py={6}
        disabled={disabled}
        size={size}
        active={isActive}
        onClick={() => {
          if (!canClick) return;

          onClick(option);
        }}
      >
        {icon && (
          <Flex
            /* Without this component the button width 'jumps', looks ugly and Navbar can't properly determine dropdown/chips mode */
            minW="24px"
            mr="4px"
          >
            <Icon fontSize="20px" icon={icon} />
          </Flex>
        )}
        <Text
          w="100%"
          userSelect="none"
          position="relative"
          ellipsisOverflow
          color="inherit"
        >
          {text}
        </Text>
      </Content>
    </Box>
  );
}

export default NavigationButton;
