import { Constants } from '@flick-tech/shared-common';
import { useColorMode, useTheme } from '@flick-tech/theme-new';

import { AsyncSelectProps, SelectProps } from '../Select.types';

export function useSelectCommonProps<O>({
  hasError,
  size,
  colorScheme = Constants.defaultColorTheme,
  minWidth,
  fullWidth,
  ...rest
}: Partial<SelectProps<O> | AsyncSelectProps<O>> & { hasError?: boolean }) {
  const theme = useTheme();
  const [colorMode] = useColorMode();

  const { whiteAlpha, blackAlpha } = theme.colors;

  const themeColor = theme.colors[colorScheme];

  const bg = { light: blackAlpha[50], dark: whiteAlpha[50] }[colorMode];
  const hoverBg = { light: blackAlpha[100], dark: whiteAlpha[100] }[colorMode];
  const errorBorderColor = theme.colors.red[500];

  const focusedColor = hasError ? errorBorderColor : themeColor[500];

  return {
    ...rest,
    styles: {
      indicatorSeparator: (base) => ({ ...base, display: 'none' }),
      menuPortal: (base) => ({ ...base, zIndex: 9999, fontSize: 16 }),
      container: (base) => ({
        ...base,
        minWidth: minWidth ?? 150,
        fontSize: 16,
        width: fullWidth ? '100%' : 'auto',
      }),
      control: (base, { isFocused }) => ({
        ...base,
        borderWidth: 1,
        background: isFocused ? 'white' : bg,
        borderColor: isFocused ? focusedColor : 'transparent',
        '&:hover': {
          background: isFocused ? 'white' : hoverBg,
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: size === 'small' ? '2px 8px' : '6px 12px',
      }),
      placeholder: (base) => ({
        ...base,
        color: theme.colors.blackAlpha[500],
      }),
      multiValue: (base) => ({
        ...base,
        backgroundColor: theme.colors.blackAlpha[200],
        color: themeColor[900],
      }),
      singleValue: (base) => ({
        ...base,
        lineHeight: 1.4,
      }),
    },
    menuPortalTarget: document.body,
    // menuPosition="fixed"
    theme: (selectTheme) => ({
      ...selectTheme,
      fontSize: 16,
      borderRadius: theme.radii.base,
      colors: {
        ...selectTheme.colors,
        primary25: themeColor[50],
        primary50: themeColor[100],
        primary75: themeColor[300],
        primary: themeColor[500],
      },
    }),
  };
}
