import { PieSvgProps, ResponsivePie } from '@nivo/pie';
import * as React from 'react';

import { tooltipWrapperStyles } from '../Tooltip';

import { pieChartPalette } from './FlickPieChart.constants';
import { FlickPieChartDatum } from './FlickPieChart.types';

export interface FlickPieChartProps
  extends Omit<PieSvgProps, 'data' | 'tooltip'> {
  data: FlickPieChartDatum[];
  tooltip?: React.FC<FlickPieChartDatum>;
}

export const FlickPieChart = ({
  data,
  tooltip,
  ...rest
}: FlickPieChartProps) => {
  return (
    <ResponsivePie
      innerRadius={0.5}
      padAngle={3}
      cornerRadius={4}
      colors={pieChartPalette}
      enableRadialLabels={false}
      enableSlicesLabels={false}
      theme={{
        tooltip: {
          container: tooltipWrapperStyles,
        },
      }}
      data={data.map((datum) => ({
        ...datum,
        value: Number(datum.value),
      }))}
      tooltip={tooltip as PieSvgProps['tooltip']}
      {...rest}
    />
  );
};
