import { useRouter } from 'next/router';

import { use100vh } from '@flick-tech/shared-hooks';
import { isMobile } from '@flick-tech/shared-common'
import { Box, BoxProps } from '@flick-tech/ui';

// I'm suspicious about this component re-rendering it's children un-necessarily
// Maybe we should memo the children? https://gist.github.com/slikts/e224b924612d53c1b61f359cfb962c06
export function FullHeightAppWrapper(props: BoxProps) {
  const router = useRouter();
  const pathname = router.pathname;

  const hasOverflow =
    pathname.includes('login') || pathname.includes('register');

  const isMobDevice = isMobile();

  const ref = use100vh({
    isDisabled: !isMobDevice,
  });

  return (
    <Box
      ref={ref}
      w="100%"
      // Fallback for desktop (100vh is not needed non mobile devices)
      h={
        isMobDevice
          ? 'initial'
          : {
              base: 'initial',
              md: '100vh',
            }
      }
      bg="bg.lighter"
      {...props}
      overflowY={hasOverflow ? 'auto' : 'initial'}
    />
  );
}
