import { Property } from 'csstype';

import { Config, Length, positiveOrNegative, Prop } from '../utils';

export const spaceConfigs: Config = {
  margin: {
    property: 'margin',
    transform: positiveOrNegative,
    scale: 'space',
  },
  m: {
    property: 'margin',
    transform: positiveOrNegative,
    scale: 'space',
  },
  marginTop: {
    property: 'marginTop',
    transform: positiveOrNegative,
    scale: 'space',
  },
  mt: {
    property: 'marginTop',
    transform: positiveOrNegative,
    scale: 'space',
  },
  marginRight: {
    property: 'marginRight',
    transform: positiveOrNegative,
    scale: 'space',
  },
  mr: {
    property: 'marginRight',
    transform: positiveOrNegative,
    scale: 'space',
  },
  marginBottom: {
    property: 'marginBottom',
    transform: positiveOrNegative,
    scale: 'space',
  },
  mb: {
    property: 'marginBottom',
    transform: positiveOrNegative,
    scale: 'space',
  },
  marginLeft: {
    property: 'marginLeft',
    transform: positiveOrNegative,
    scale: 'space',
  },
  ml: {
    property: 'marginLeft',
    transform: positiveOrNegative,
    scale: 'space',
  },
  marginX: {
    properties: ['marginLeft', 'marginRight'],
    transform: positiveOrNegative,
    scale: 'space',
  },
  mx: {
    properties: ['marginLeft', 'marginRight'],
    transform: positiveOrNegative,
    scale: 'space',
  },
  marginY: {
    properties: ['marginTop', 'marginBottom'],
    transform: positiveOrNegative,
    scale: 'space',
  },
  my: {
    properties: ['marginTop', 'marginBottom'],
    transform: positiveOrNegative,
    scale: 'space',
  },
  padding: {
    property: 'padding',
    scale: 'space',
  },
  p: {
    property: 'padding',
    scale: 'space',
  },
  paddingTop: {
    property: 'paddingTop',
    scale: 'space',
  },
  pt: {
    property: 'paddingTop',
    scale: 'space',
  },
  paddingRight: {
    property: 'paddingRight',
    scale: 'space',
  },
  pr: {
    property: 'paddingRight',
    scale: 'space',
  },
  paddingBottom: {
    property: 'paddingBottom',
    scale: 'space',
  },
  pb: {
    property: 'paddingBottom',
    scale: 'space',
  },
  paddingLeft: {
    property: 'paddingLeft',
    scale: 'space',
  },
  pl: {
    property: 'paddingLeft',
    scale: 'space',
  },
  paddingX: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
  },
  px: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
  },
  paddingY: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
  },
  py: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
  },
};

/**
 * Types for space related CSS properties
 */
export interface SpaceProps {
  /**
   * Margin on top, left, bottom and right
   */
  m?: Prop<Property.Margin<Length>>;
  /**
   * Margin on top, left, bottom and right
   */
  margin?: Prop<Property.Margin<Length>>;
  /**
   * Margin on top
   */
  mt?: Prop<Property.Margin<Length>>;
  /**
   * Margin on top
   */
  marginTop?: Prop<Property.MarginTop<Length>>;
  /**
   * Margin on right
   */
  mr?: Prop<Property.MarginRight<Length>>;
  /**
   * Margin on right
   */
  marginRight?: Prop<Property.MarginRight<Length>>;
  /**
   * Margin on bottom
   */
  mb?: Prop<Property.MarginBottom<Length>>;
  /**
   * Margin on bottom
   */
  marginBottom?: Prop<Property.MarginBottom<Length>>;
  /**
   * Margin on left
   */
  ml?: Prop<Property.MarginLeft<Length>>;
  /**
   * Margin on left
   */
  marginLeft?: Prop<Property.MarginLeft<Length>>;
  /**
   * Margin on left and right
   */
  mx?: Prop<Property.Margin<Length>>;
  /**
   * Margin on left and right
   */
  marginX?: Prop<Property.Margin<Length>>;
  /**
   * Margin on top and bottom
   */
  my?: Prop<Property.Margin<Length>>;
  /**
   * Margin on top and bottom
   */
  marginY?: Prop<Property.Margin<Length>>;
  /**
   * Padding on top, left, bottom and right
   */
  p?: Prop<Property.Padding<Length>>;
  /**
   * Padding on top, left, bottom and right
   */
  padding?: Prop<Property.Padding<Length>>;
  /**
   * Padding on top
   */
  pt?: Prop<Property.PaddingTop<Length>>;
  /**
   * Padding on top
   */
  paddingTop?: Prop<Property.PaddingTop<Length>>;
  /**
   * Padding on right
   */
  pr?: Prop<Property.PaddingRight<Length>>;
  /**
   * Padding on right
   */
  paddingRight?: Prop<Property.PaddingRight<Length>>;
  /**
   * Padding on bottom
   */
  pb?: Prop<Property.PaddingBottom<Length>>;
  /**
   * Padding on bottom
   */
  paddingBottom?: Prop<Property.PaddingBottom<Length>>;
  /**
   * Padding on left
   */
  pl?: Prop<Property.PaddingLeft<Length>>;
  /**
   * Padding on left
   */
  paddingLeft?: Prop<Property.PaddingLeft<Length>>;
  /**
   * Padding on left and right
   */
  px?: Prop<Property.Padding<Length>>;
  /**
   * Padding on left and right
   */
  paddingX?: Prop<Property.Padding<Length>>;
  /**
   * Padding on top and bottom
   */
  py?: Prop<Property.Padding<Length>>;
  /**
   * Padding on top and bottom
   */
  paddingY?: Prop<Property.Padding<Length>>;
}
