import { Global } from '@emotion/react';

import { Theme } from './preset-base';

interface CSSResetProps {
  theme: Theme;
}

export function CSSReset({ theme }: CSSResetProps) {
  return (
    <Global
      styles={`
      html {
        scroll-behavior: smooth;
      }

      #__next {
        height: 100%;
        width: 100%;
      }

      body,
      html {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        text-rendering: optimizelegibility;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        text-decoration-skip-ink: auto;
        font-family: ${theme.fonts.body};
      }

      *,
      *::before,
      *::after {
        border-width: 0;
        border-style: solid;
        box-sizing: inherit;
      }

      body,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      dl,
      blockquote,
      ol,
      ul {
        margin: 0;
        padding: 0;
      }

      var,
      address,
      dfn,
      cite {
        font-style: italic;
      }

      img,
      video {
        border-style: none;
        max-width: 100%;
        height: auto;
      }

      img,
      fieldset {
        border: 0;
      }

      code,
      kbd,
      samp {
        font-family: monospace, monospace;
        font-size: 1em;
      }

      abbr {
        cursor: help;
      }

      small {
        font-size: 80%;
      }

      sub,
      sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
      }

      sub {
        bottom: -0.25em;
      }

      sup {
        top: -0.5em;
      }

      p {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      button,
      input,
      optgroup,
      select,
      textarea {
        font-family: inherit;
        font-size: 100%;
        margin: 0;
      }

      button::-moz-focus-inner,
      [type="button"]::-moz-focus-inner,
      [type="reset"]::-moz-focus-inner,
      [type="submit"]::-moz-focus-inner {
        border-style: none;
      }

      legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal;
      }

      textarea {
        overflow: auto;
      }

      [type="checkbox"],
      [type="radio"] {
        box-sizing: border-box;
        padding: 0;
      }

      [type="number"]::-webkit-inner-spin-button,
      [type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      [type="search"] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
      }

      [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none !important;
      }

      ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
      }

      hr {
        border-top-width: 1px;
      }

      textarea {
        resize: vertical;
      }

      button,
      [role="button"] {
        cursor: pointer;
      }

      button {
        background: transparent;
        padding: 0;
      }

      details {
        display: block;
      }


      button::-moz-focus-inner {
        border: 0 !important;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
      }

      td,
      th {
        border: none;
        text-align: left;
      }

      caption {
        text-align: left;
      }

      button,
      input,
      optgroup,
      select,
      textarea {
        padding: 0;
        line-height: inherit;
        color: inherit;
      }

      [hidden] {
        display: none !important;
      }

      pre,
      code,
      kbd,
      samp {
        font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
          monospace;
      }

      img,
      svg,
      video,
      canvas,
      audio,
      iframe,
      embed,
      object {
        display: block;
      }
      //
      // .js-focus-visible :focus:not([data-focus-visible-added]) {
      //    outline: none;
      //    box-shadow: none;
      //  }


       /* Make clicks pass-through */
      #nprogress {
        pointer-events: none;
      }

      #nprogress .bar {
        background: ${theme.colors.brand[500]};

        position: fixed;
        z-index: 999999;
        top: 0;
        left: 0;

        width: 100%;
        height: 2px;
      }

      /* Fancy blur effect */
      #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        opacity: 1;

        -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
        transform: rotate(3deg) translate(0px, -4px);
      }

      .nprogress-custom-parent {
        overflow: hidden;
        position: relative;
      }

      .nprogress-custom-parent #nprogress .bar {
        position: absolute;
      }

      :focus {
        outline: none;
      }

      .tippy-content {
        padding: 5px;
      }

      .tippy-popper:focus {
        outline: none;
      }

      .tippy-tooltip.light-theme {
        color: #26323d;
        box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
          0 4px 80px -8px rgba(36, 40, 47, 0.25),
          0 4px 4px -2px rgba(91, 94, 105, 0.15);
        background-color: #fff;
      }

      .tippy-tooltip.light-theme[x-placement^='top'] .tippy-arrow {
        border-top: 8px solid #fff;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
      }

      .tippy-tooltip.light-theme[x-placement^='bottom'] .tippy-arrow {
        border-bottom: 8px solid #fff;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
      }

      .tippy-tooltip.light-theme[x-placement^='left'] .tippy-arrow {
        border-left: 8px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }

      .tippy-tooltip.light-theme[x-placement^='right'] .tippy-arrow {
        border-right: 8px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }

      .tippy-tooltip.light-theme .tippy-backdrop {
        background-color: #fff;
      }

      .tippy-tooltip.light-theme .tippy-roundarrow {
        fill: #fff;
      }

      .tippy-tooltip.light-theme[data-animatefill] {
        background-color: transparent;
      }

    `}
    />
  );
}
