import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';

// Styles are specified in theme provider global styles
// search "#nprogress"
export function usePageLoader() {
  const routerEvents = useRouter().events;

  useEffect(() => {
    routerEvents.on('routeChangeStart', () => NProgress.start());
    routerEvents.on('routeChangeComplete', (pathname) => {
      NProgress.done();
    });
    routerEvents.on('routeChangeError', () => NProgress.done());
  }, [routerEvents]);
}

export { NProgress as PageLoader };
