import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isSameYear from 'date-fns/isSameYear';
import subDays from 'date-fns/subDays';

const empty = 'n/a';

export const makeDateFormat = (formatting: string) => (v: string | Date) => {
  if (!v) {
    return empty;
  }
  const date = new Date(v);
  // Eg. 'Nov 09'
  return format(date, formatting);
};

export const dateShort = makeDateFormat('MMM dd');
export const dateYearShort = makeDateFormat('MMM dd, yyyy');

export const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const dayNamesShort = [
  'Sun.',
  'Mon.',
  'Tue.',
  'Wed.',
  'Thurs.',
  'Fri.',
  'Sat.',
];

const today = new Date();

export const dateFullDayNoYear = (date: Date) => {
  return `${dayNames[date.getDay()]}, ${dateShort(date)}`;
};

export const dateFullDay = (v?: string | number | Date) => {
  if (!v) {
    return empty;
  }
  const date = new Date(v);
  const thisYear = isSameYear(today, date);
  // For whatever reason I cannot get date-fns to give me full day string, eg. 'Monday' :/
  return `${dayNames[date.getDay()]}, ${
    thisYear ? dateShort(date) : dateYearShort(date)
  }`;
};

export const dateFullDaySplitted = (v?: string | number | Date) => {
  if (!v) {
    return empty;
  }
  const date = new Date(v);
  const thisYear = isSameYear(today, date);
  const thisWeek = isBefore(subDays(today, 7), date);
  if (thisWeek) {
    return dayNamesShort[date.getDay()];
  }
  return thisYear ? dateShort(date) : dateYearShort(date);
};

export const dateRangeShort = (range: { from?: Date; to?: Date }) => {
  const { from = new Date(), to = new Date() } = range ?? {};

  const fromDate = new Date(from);
  const toDate = new Date(to);
  const sameYear = isSameYear(fromDate, toDate);
  const thisYear = isSameYear(today, fromDate);
  return sameYear && thisYear
    ? `${dateShort(fromDate)} - ${dateShort(toDate)}`
    : `${dateYearShort(fromDate)} - ${dateYearShort(toDate)}`;
};
