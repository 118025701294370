import { useEffect } from 'react';

import { Logger, SkipSentryError } from '@flick-tech/logger';
import {
  Constants,
  isMobile,
  isRunningOnServerSide,
  stripEmpties,
} from '@flick-tech/shared-common';

import { IntercomCommands } from './intercom.types';

interface IntercomPosition {
  horizontalPadding: number;
  verticalPadding: number;
  alignment: 'left' | 'right';
}

const canInitialiseIntercom = () =>
  !isRunningOnServerSide() && !window.ReactNativeWebView;

const canUseIntercom = () =>
  !isRunningOnServerSide() && window.Intercom && !window.ReactNativeWebView;

function updateIntercom(
  event: IntercomCommands = 'update',
  settings: any = null,
): void {
  canUseIntercom() && window.Intercom(event, settings);
}

function initIntercomWindow({ appId, ...otherProps }): void {
  const intercomProps = Object.assign(
    {},
    {
      app_id: appId,
    },
    otherProps,
  );

  if (canUseIntercom()) {
    if (window.intercomSettings && !window.intercomSettings.app_id) {
      window.intercomSettings = intercomProps;
    }

    window.Intercom('boot', intercomProps);
  }
}

export class Intercom {
  static isVisible = false;

  static defaultPosition: IntercomPosition = {
    horizontalPadding: 20,
    verticalPadding: 20,
    alignment: 'right',
  };

  static init(user?: any) {
    if (!canInitialiseIntercom()) {
      window.Intercom = () => {};
      return;
    }

    const appID = Constants.intercomAppId;

    if (typeof window.Intercom === 'function') {
      const updateProps = {
        appId: appID,
        alignment: isMobile() ? 'left' : 'right',
      };

      window.intercomSettings = {
        ...window.intercomSettings,
        alignment: isMobile() ? 'left' : 'right',
      };

      initIntercomWindow(updateProps);

      if (user) {
        this.setUserProperties(user);
      }
    }
  }

  static hideIntercomChat() {
    if (!window.Intercom) return;

    try {
      window.Intercom('update', {
        hide_default_launcher: true,
      });

      this.isVisible = false;
    } catch (error) {
      Logger.error(error);
    }
  }

  static showIntercomChat() {
    if (!canUseIntercom()) return;

    try {
      window.Intercom('update', {
        hide_default_launcher: false,
      });

      this.isVisible = true;
    } catch (error) {
      Logger.error(error);
    }
  }

  static openMessenger() {
    if (!canUseIntercom()) {
      return;
    }
    try {
      window.Intercom('show');
    } catch (error) {
      Logger.error(error);
    }
  }

  static openMessengerWithContent(content: string) {
    if (!canUseIntercom()) {
      return;
    }

    try {
      window.Intercom('showNewMessage', content);
    } catch (error) {
      Logger.error(error);
    }
  }

  static trackEvent(name: string, payload?: any) {
    try {
      if (canUseIntercom()) {
        if (payload && Object.entries(stripEmpties(payload)).length > 0) {
          window.Intercom('trackEvent', name, stripEmpties(payload));
        } else {
          window.Intercom('trackEvent', name);
        }
      }
    } catch (error) {
      Logger.error(error);
    }

    // SPA's dont trigger a message/product tour - we'll need to ping Intercom
    setTimeout(function () {
      try {
        window.Intercom('update', {
          last_request_at: new Date().getTime() / 1000,
        });
      } catch (error) {
        Logger.error(error);
      }
    }, 500);
  }

  static setUserProperties(user: any) {
    if (!canUseIntercom()) return;

    const { id, name, email, profilePicture, isTrialling } = user;

    if (!email) return;

    let intercomPayload: any = {
      id,
      name,
      email,
      isTrialling,
      user_id: id,
    };

    if (profilePicture && profilePicture !== '') {
      intercomPayload.avatar = {
        type: 'avatar',
        image_url: profilePicture,
      };
    }

    intercomPayload = stripEmpties(intercomPayload);

    if (window.Intercom) {
      window.intercomSettings = {
        ...window.intercomSettings,
        ...intercomPayload,
      };

      window.Intercom('update', intercomPayload);
    }
  }

  static resetMessengerPosition() {
    if (!canUseIntercom()) return;

    this.updateMessengerPosition(this.defaultPosition);
  }

  static updateMessengerPosition(props: Partial<IntercomPosition> = {}) {
    if (!canUseIntercom()) return;

    const payload: any = {};

    if (props?.alignment) {
      payload.alignment = props.alignment;
    }

    if (props?.horizontalPadding) {
      payload.horizontal_padding = props.horizontalPadding;
    }

    if (props?.verticalPadding) {
      payload.vertical_padding = props.verticalPadding;
    }

    window.intercomSettings = {
      ...window.intercomSettings,
      ...payload,
    };

    window.Intercom('update');
  }

  static setUserPropertiesCustom(payload: any) {
    if (!canUseIntercom()) return;

    const intercomPayload = stripEmpties(payload);

    window.intercomSettings = {
      ...window.intercomSettings,
      ...intercomPayload,
    };

    window.Intercom('update', intercomPayload);
  }
}

export function useResetIntercomPositionOnUnmount() {
  useEffect(() => {
    return () => Intercom.resetMessengerPosition();
  }, []);
}
