import { useRect } from '@reach/rect';
import {
  Children,
  cloneElement,
  forwardRef,
  Ref,
  useRef,
  useState,
} from 'react';
import * as React from 'react';

import { __DEV__, ariaAttr, cx, mergeRefs } from '@flick-tech/shared-utils';
import { chakra, PropsOf } from '@flick-tech/theme-new';

import { Transition, TransitionStyles } from '../transition';

export type CollapseProps = PropsOf<typeof chakra.div> & {
  /**
   * If `true`, the content will be visible
   */
  isOpen?: boolean;
  /**
   * The CSS `transition-duration` (in ms) to apply for the collapse animation
   *
   * @default
   * 150
   */
  timeout?: number;
};

export const Collapse = forwardRef(
  (props: CollapseProps, forwardedRef: React.Ref<any>) => {
    const {
      isOpen,
      children,
      timeout = 150,
      style: htmlStyle,
      className,
      ...rest
    } = props;

    const getStr = (property: string) => `${property} ${timeout}ms ease`;

    const transition = `${getStr('height')}, ${getStr('opacity')}, ${getStr(
      'transform',
    )}`;

    const [hidden, setHidden] = useState(!isOpen);

    type ChildElement = React.ReactElement<{ ref: Ref<any> }>;

    let child = children;

    if (typeof children === 'string') {
      child = <div>{children}</div>;
    }

    const _child = Children.only(child) as ChildElement;

    const ref = useRef<HTMLDivElement>(null);

    const rect = useRect(ref, true);
    const height = rect?.height ?? undefined;

    const styles: TransitionStyles = {
      entered: {
        height,
        opacity: 1,
        transform: 'translateY(0)',
      },
      entering: {
        height,
        opacity: 1,
        transform: 'translateY(0)',
      },
      exited: {
        height: 0,
        opacity: 1,
        transform: 'translateY(-0.5rem)',
      },
      exiting: {
        height: 0,
        opacity: 1,
        transform: 'translateY(-0.5rem)',
      },
    };

    return (
      <Transition
        appear={false}
        in={isOpen}
        styles={styles}
        onEntered={() => setHidden(false)}
        onExited={() => setHidden(true)}
        timeout={timeout}
        transition={transition}
        unmountOnExit={false}
      >
        {(styles) => (
          <chakra.div
            ref={forwardedRef}
            className={cx('chakra-collapse', className)}
            aria-hidden={ariaAttr(hidden)}
            {...rest}
            style={{
              ...styles,
              overflow: 'hidden',
              willChange: 'height, opacity, transform',
              ...htmlStyle,
            }}
          >
            {cloneElement(_child, {
              ref: mergeRefs(ref, _child.props.ref),
            })}
          </chakra.div>
        )}
      </Transition>
    );
  },
);

if (__DEV__) {
  Collapse.displayName = 'Collapse';
}
