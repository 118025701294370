import { Icon } from '../../../icon';
import { Box, Divider, Stack } from '../../../layout';

export function KeyboardControlsInfo() {
  return (
    <Stack
      as="span"
      direction="row"
      color="blackAlpha.500"
      divider={
        <Divider
          orientation="vertical"
          height="1em"
          borderColor="blackAlpha.300"
        />
      }
      spacing="4"
    >
      <Box as="span">
        <Icon icon="ImportExport" mr="2" />
        Select
      </Box>
      <Box as="span">
        <Icon icon="KeyboardReturn" mr="2" />
        Search
      </Box>
    </Stack>
  );
}
