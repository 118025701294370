import { AtomEffect, DefaultValue } from 'recoil';
import { isRunningOnServerSide } from '../shared';

type Key = string;

type StringStorageEffectOptions<T> = {
  key?: string;
  parse?: (str: string) => T;
  stringify?: (val: T) => string;
  ttlMs?: number;
};

const getRecoilLocalStorageKey = (key: string) => `flick|recoil|${key}`;

function setWithExpiry(key: string, value: string, ttlMs: number) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttlMs,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return undefined;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return undefined;
  }

  return item.value;
}

export const localStorageEffect =
  <T>(arg: StringStorageEffectOptions<T> | Key = {}): AtomEffect<T> =>
  ({ setSelf, onSet, node }) => {
    const options = typeof arg === 'string' ? { key: arg } : arg;
    const {
      key = getRecoilLocalStorageKey(node.key),
      parse = JSON.parse,
      stringify = JSON.stringify,
      ttlMs,
    } = options;

    if (isRunningOnServerSide()) {
      return;
    }

    const savedValue = ttlMs ? getWithExpiry(key) : localStorage.getItem(key);

    if (savedValue != null) {
      try {
        setSelf(parse(savedValue));
      } catch {}
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else if (ttlMs) {
        setWithExpiry(key, stringify(newValue), ttlMs);
      } else {
        localStorage.setItem(key, stringify(newValue));
      }
    });
  };

export const synchronizeStringWithSearchParamsEffect =
  <T>(arg: StringStorageEffectOptions<T> | Key = {}): AtomEffect<T> =>
  ({ setSelf, onSet, node }) => {
    const options = typeof arg === 'string' ? { key: arg } : arg;
    const { key = node.key, parse, stringify = String } = options;

    const savedValue = new URLSearchParams(window.location.search).get(key);

    if (savedValue) {
      if (parse) {
        setSelf(parse(savedValue));
      } else if (stringify === String) {
        // if `stringify` is String, we can assume `T` is just `string`
        setSelf(savedValue as unknown as T);
      }
    }

    onSet((newValue) => {
      const url = new URL(window.location.href);
      if (newValue instanceof DefaultValue || !newValue) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, stringify(newValue));
      }
      window.history.replaceState({}, '', url.toString());
    });
  };
