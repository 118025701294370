import * as cookie from 'cookie';
import add from 'date-fns/add';
import Cookies, { CookieAttributes } from 'js-cookie';

import { isNullOrUndefined, isObject } from '@flick-tech/shared-utils';

import { Constants } from '../constants';
import { isRunningOnServerSide } from '../shared';

export enum StoreKey {
  EXPERIENCE_LEVEL = 'experience_level',
  REFERRER = 'referrer',
  REFERRER_URL = 'referrerUrl',
  // Be aware to change this key. It is used in a few places.
  // CMD+F token in the whole frontend and replace all relevant occurrences first
  TOKEN = 'token',
  ACTIVE_IG_ACCOUNT = 'active_ig_account',
  DESKTOP_HASHTAGS_VIEW = 'desktop_hashtags_view',
  HIDDEN_FIELDS = 'hidden_fields',
  PLAN = 'plan',
  ADDONS = 'addons',
  REGISTER_STAGE = 'register_stage',
  SELECTED_HASHTAGS = 'selected_hashtags',
  SelectedHashtagsExperiment = 'SelectedHashtagsExperiment',
  NewPlansExperiment = 'NewPlansExperiment',
}

const getCookieKey = (key: string) => {
  const data = Cookies.get(key);

  if (!data) {
    return data;
  }

  try {
    return JSON.parse(data);
  } catch (_) {}

  return data;
};

const getCookieOptions = (): CookieAttributes &
  cookie.CookieSerializeOptions => {
  if (Constants.isStaging) {
    return {};
  }

  return {
    domain:
      process.env.NEXT_PUBLIC_COOKIE_DOMAIN ||
      (Constants.isDev ? 'localhost' : 'flick.tech'),
    expires: add(new Date(), { days: 90 }),
  };
};

export const flickCookieOptions: CookieAttributes &
  cookie.CookieSerializeOptions = getCookieOptions();

export class PersistentStore {
  static set(key: string, data: any) {
    if (isRunningOnServerSide()) return;

    const value = JSON.stringify(data);

    Cookies.set(key, value, flickCookieOptions);
    return localStorage.setItem(key, value);
  }

  static get<T>(key: string, defaultValue?: T): T | undefined {
    if (isRunningOnServerSide()) {
      return defaultValue;
    }

    const data = localStorage.getItem(key);

    let value: any;

    if (data === 'false' || data === 'true') {
      value = data === 'true';
    } else {
      try {
        value = JSON.parse(data ?? '');
      } catch (error) {}
    }

    if (isNullOrUndefined(value)) {
      value = data ?? defaultValue;
    }

    if (isNullOrUndefined(value)) {
      // Cookies work across sub-domains of flick.tech where localStorage doesn't
      // on app.flick.tech:
      //   localStorage.set('token', value)
      //   Cookies.set('token', value, { domain: 'flick.tech' })
      // on schedule.flick.tech
      //   localStorage.get('token') === undefined
      //   Cookies.get('token') === value
      return getCookieKey(key);
    } else if (value) {
      // Sync to cookies
      Cookies.set(
        key,
        isObject(value) ? JSON.stringify(value) : value,
        flickCookieOptions,
      );
    }

    return value;
  }

  static remove(key: string) {
    if (isRunningOnServerSide()) return;

    localStorage.removeItem(key);
    Cookies.remove(key, flickCookieOptions);
  }

  static clear() {
    if (isRunningOnServerSide()) return;

    localStorage.clear();
    [
      StoreKey.ACTIVE_IG_ACCOUNT,
      StoreKey.TOKEN,
      StoreKey.SELECTED_HASHTAGS,
      StoreKey.ACTIVE_IG_ACCOUNT,
    ].forEach((key) => {
      localStorage.removeItem(key);
      Cookies.remove(key, flickCookieOptions);
      // Unsure if this is necessary, just testing it out a min
      Cookies.remove(key);
    });
  }
}

export { Cookies };
