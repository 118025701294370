import styled from '@emotion/styled';
import { ComponentProps, useLayoutEffect, useRef, useState } from 'react';

import { isMobile, LayoutConstants } from '@flick-tech/shared-common';
import { useWindowSize } from '@flick-tech/shared-hooks';
import { borderColorBase } from '@flick-tech/theme-new';

import { IconName } from '../../icon';
import { Box, BoxProps, Flex } from '../../layout';
import { Spinner } from '../../spinner';
import { DropdownInput } from '../DropdownInput';
import { DropdownInputProps } from '../Select.types';

import { BaseOption } from './NavigationButton/NavigationButton';
import { NavigationButton } from './NavigationButton';

interface VisibleProps {
  visible?: boolean;
}

const NavbarDropdownWrapper = styled.div<VisibleProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  width: 100%;
  ${({ visible }) => !visible && `display: none;`};
  /* TODO: Fix this, use different component or pass styles down */
  .react-select-container > div:first-of-type {
    height: 46px;
  }
`;

export function NavbarDropdown<T>({
  visible,
  ...rest
}: VisibleProps & DropdownInputProps<T>) {
  return (
    <NavbarDropdownWrapper visible={visible}>
      <DropdownInput isSearchable={false} {...rest} />
    </NavbarDropdownWrapper>
  );
}

const navbarPadding = LayoutConstants.topbar.padding;
export const navbarHeight = LayoutConstants.topbar.height;

export const Navbar = (props: BoxProps) => (
  <Box
    display="flex"
    flexDirection="row"
    width="100%"
    overflow="visible"
    alignItems="center"
    justifyContent="space-between"
    backgroundColor="#fff"
    /* So it doesn't "jump" after loading */
    height={`${navbarHeight}px`}
    /* Fix for safari */
    minHeight={`${navbarHeight}px`}
    borderBottom="1px solid"
    borderBottomColor={borderColorBase}
    px={`${navbarPadding}px`}
    {...props}
  />
);

export const NavbarChipsWrapper = styled.div<VisibleProps>`
  justify-content: flex-start;
  z-index: 10;
  align-items: center;
  display: flex;
  position: absolute;

  height: 100%;

  /* We still need to be able to make width calculations */
  ${({ visible }) =>
    !visible &&
    `
    opacity: 0;
    pointer-events: none;
    // But also we don't want it to overflow the view
    position: fixed;
    max-width: 100vw;
    left: 0;
    top: 0;
    `}
`;

export const NavbarLoading = () => (
  <Navbar>
    <Spinner size="sm" />
  </Navbar>
);

export interface NavbarTabOption<T extends string | number = string>
  extends BaseOption<T> {
  icon?: IconName;
  disabled?: boolean;
  popoverContent?: string;
  options?: BaseOption<T>[];
}

export interface NavbarTabsProps<T extends string | number = string>
  extends Omit<ComponentProps<typeof Navbar>, 'onChange'> {
  activeOption: NavbarTabOption<T>;
  options: NavbarTabOption<T>[];
  onChange: (option: BaseOption<T>) => void;
  forceChipsVisible?: boolean;
}

function NavbarTabs<T extends string | number = string>({
  options = [],
  forceChipsVisible,
  activeOption,
  onChange,
}: NavbarTabsProps<T>) {
  const chipsWrapper = useRef<HTMLDivElement>();
  const lastChip = useRef<HTMLDivElement>();
  const [dropdownVisible, setDropdownVisible] = useState(isMobile());
  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    if (chipsWrapper.current && lastChip.current) {
      const wrapper = chipsWrapper.current;
      const chip = lastChip.current;

      // Timeout here is a dirty hack for Analytics, no time rn to investigate why it isnt working otherwise
      setTimeout(() => {
        const wrapperRight = wrapper.getBoundingClientRect().width;
        const chipRight = chip.getBoundingClientRect().width;

        const newDropdownVisible = chipRight > wrapperRight;
        if (newDropdownVisible !== dropdownVisible) {
          setDropdownVisible(newDropdownVisible);
        }
      }, 500);
    }
  }, [windowSize, options, dropdownVisible]);

  const isDropdownVisible = !forceChipsVisible && dropdownVisible;
  return (
    <Flex position="relative" align="center" w="100%" ref={chipsWrapper}>
      <NavbarDropdown
        visible={isDropdownVisible}
        value={activeOption as any}
        options={options as any}
        onChange={(option) => onChange(option as any)}
      />
      <NavbarChipsWrapper visible={!isDropdownVisible} ref={lastChip}>
        {options.map((option, index) => (
          <NavigationButton
            popoverContent={option.popoverContent}
            mr={index === options.length - 1 ? 0 : 2}
            disabled={option.disabled}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={!isDropdownVisible && onChange}
            key={option.label}
            text={option.label}
            option={option}
            activeOption={activeOption}
            icon={option.icon}
            suboptions={option.options}
            data-intercom-id={option.intercomId}
          />
        ))}
      </NavbarChipsWrapper>
    </Flex>
  );
}

export default NavbarTabs;
