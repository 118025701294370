import retry from 'async-retry';
import { memo, useEffect } from 'react';

import { useInitializeFlagsHandler } from './useInitializeFlagsHandler';

// Memo component to only run once
export const LaunchDarklyFlagsHandler = memo(() => {
  const initialize = useInitializeFlagsHandler();

  useEffect(() => {
    retry(initialize, {
      retries: 5,
      // TODO: remove
    } as any);
  }, [initialize]);

  return <></>;
});
