import { useCallback } from 'react';

import { ProfilePageSection, ROUTES } from '@flick-tech/shared-common';

import { useNavigate } from './useRouter';

export const profilePageRoutePrefix = '/profile/';

export function useNavigateToProfileSection() {
  const navigate = useNavigate();

  return (section: ProfilePageSection, urlParams = '') => {
    navigate(`${ROUTES.Profile.section(section)}${urlParams}`);
  };
}

interface UseNavigateToBilling {
  editing?: boolean;
  fromOldPlans?: boolean;
}

export function useNavigateToBilling() {
  const navigate = useNavigate();
  return useCallback(
    (payload: UseNavigateToBilling = {}) => {
      const urlParams = new URLSearchParams();

      Object.entries(payload).forEach(([k, v]) => {
        urlParams.set(k, v.toString());
      });

      navigate(
        `${profilePageRoutePrefix}${
          ProfilePageSection.Billing
        }?${urlParams.toString()}`,
      );
    },
    [navigate],
  );
}
