import { getBillingStatus } from '@flick-tech/shared-hooks';

import { useGetUser } from './useGetUser';

export function useHasActiveSubscription() {
  const { user } = useGetUser();
  const { hasActiveSubscription } = getBillingStatus(
    user?.activeWorkspace?.billing,
  );

  return hasActiveSubscription;
}

// https://www.notion.so/flicktech/No-Card-Trial-MVP-02034c9bcbf94c0a9e32c947e235c82c
export function useIsOnNoCardPlan() {
  const { user } = useGetUser();

  // Freemium is deprecated, migrate away from it to our AllAccess plan
  return user?.activeWorkspace?.billing?.isFreemium;
}

// https://www.notion.so/flicktech/Architectural-decisions-ba471a66f23d44529b848afb9006fd74#9f8e7809460d40c2b1d965007b125494
export function useCanUpgradeToPaidPlan() {
  return useIsOnNoCardPlan();
}

export function useIsComplimentary() {
  const { user } = useGetUser();
  const { isComplimentary } = getBillingStatus(user?.activeWorkspace?.billing);

  return isComplimentary;
}
