const base = 8;

const radii = {
  base,
  none: '0',
  sm: `${base / 2}px`,
  md: `${base}px`,
  lg: `${base * 2}px`,
  full: '9999px',
};

export type Radii = typeof radii;

export default radii;
