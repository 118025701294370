import { Intercom } from '@flick-tech/client-tracking';
import { SystemProps } from '@flick-tech/theme-new';
import { Button } from '../button';

export interface ReportIssueButtonProps extends SystemProps {
  initialContent?: string;
}
export function ReportIssueButton({
  initialContent = "Hey Flick team, I'm having an issue with: \n\n",
  ...rest
}) {
  return (
    <Button
      colorScheme="gray"
      onClick={() => {
        Intercom.openMessengerWithContent(initialContent);
      }}
      {...rest}
    >
      Report Issue
    </Button>
  );
}
