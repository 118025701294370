import deepEqual from 'fast-deep-equal';

import { Logger } from '@flick-tech/logger';
import {
  isNewPlan,
  setPersistedBasicUserInfo,
  stripEmpties,
} from '@flick-tech/shared-common';
import { IUserFieldsFragment } from '@flick-tech/shared-types';

import { Amplitude } from './amplitude';
import { CustomerIOBrowser } from './customer-io';
import { Intercom } from './intercom';
import { IdentifyProperties } from './itly';
import { ItlyWeb } from './itly-web';
import { Tracking } from './tracking';

/*
  TODO: Remove massive amount of duplication of tracking, after we build a better tracking plan.
 */

const transformUser = (user: IUserFieldsFragment) => {
  const transformed: any = {};

  if (!user) return transformed;

  const fields = ['id', 'email', 'name', 'profilePicture'];

  fields.forEach((field) => {
    if (field in user) {
      transformed[field] = user[field];
    }
  });

  transformed.Referrer = user.referrer;

  return stripEmpties(transformed);
};

let lastTrackedProperties: IdentifyProperties | undefined = undefined;

const identify = (
  user: IUserFieldsFragment,
  additionalProps: IdentifyProperties = {},
) => {
  if (!user) return;

  try {
    const hasAccessToAnalyticsPro =
      user?.activeWorkspace?.features.includes('AnalyticsPro');

    const addonIds = user?.activeWorkspace?.billing?.addons?.map(
      (addon) => addon.id,
    );

    const properties: Partial<IdentifyProperties> = {
      FullName: user.name,
      name: user.name,
      email: user.email,
      FirstSeen: user.createdAt,
      hasLinkedGoogle: user.hasLinkedGoogle,
      hasSetPassword: user.hasSetPassword,
      id: user.id,
      LastSeen: new Date().toString(),
      plan: user.activeWorkspace?.billing?.plan?.id,
      Plan: user.activeWorkspace?.billing?.plan?.id,
      SubscriptionStatus: user?.activeWorkspace?.billing?.subscription
        ?.status as any,
      hasAnalytics: hasAccessToAnalyticsPro,
      features: user?.activeWorkspace?.features,
      activeAddons: addonIds ?? [],
      ...additionalProps,
    };

    if (typeof user.profilePicture === 'string') {
      properties.ProfilePicture = user.profilePicture;
    }

    if (typeof user.referrer === 'string') {
      properties.referrer = user.referrer;
    }

    if (deepEqual(properties, lastTrackedProperties)) {
      return;
    }

    setPersistedBasicUserInfo({
      id: user.id,
      email: user.email,
      name: user.name,
    });

    if (user?.activeWorkspace?.billing?.hasCompletedRegistration) {
      ItlyWeb.identify(user.id, properties);
    } else {
      Tracking.setUserProperties(properties);
    }

    CustomerIOBrowser.identify(user.id, properties);

    lastTrackedProperties = properties;
  } catch (error) {
    Logger.error(error);
  }
};

export function identifyUser(
  user: IUserFieldsFragment,
  additionalProps_: Partial<IdentifyProperties> = {},
) {
  try {
    const userTransformed = transformUser(user);
    Amplitude.setUserProperties(userTransformed);
    Intercom.setUserProperties(user);

    const additionalProps: IdentifyProperties = {
      ...additionalProps_,
    };

    const planId = user?.activeWorkspace?.billing?.plan?.id;

    if (planId) {
      additionalProps.isOnNewPlan = isNewPlan(planId);
    }

    identify(user, additionalProps);
  } catch (error) {
    Logger.error(error);
  }
}
