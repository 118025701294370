import { useCallback, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';

import { ISOCountryCodes } from '@flick-tech/shared-common';
import { Logger } from '@flick-tech/logger';

const isLoadingState = atom({
  key: 'device-country-code/is-loading',
  default: false,
});

const hasErrorState = atom({
  key: 'device-country-code/has-error',
  default: false,
});

const countryCodeState = atom<ISOCountryCodes | undefined>({
  key: 'device-country-code/value',
  default: undefined,
});

// We store the values in recoil to persist across multiple uses of this hook
export function useDeviceCountryCode() {
  const [hasError, setHasError] = useRecoilState(hasErrorState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState);
  const [countryCode, setCountryCode] = useRecoilState(countryCodeState);

  const fetchCountry = useCallback(async () => {
    setIsLoading(true);

    try {
      const res = await fetch('https://ipapi.co/json/');

      const { country_code } = await res.json();

      setCountryCode(country_code);
    } catch (error) {
      Logger.error(error);
      setHasError(true);
    }

    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    if (isLoading || countryCode || hasError) return;

    fetchCountry();
  }, []);

  return {
    isLoading,
    countryCode,
  };
}
