import { useBoolean } from '@flick-tech/shared-hooks';
import { Box, BoxProps, Center, Flex, Spinner, Text } from '@flick-tech/ui';

import { WorkspaceWithImage } from './types';

export interface WorkspaceCardProps
  extends Omit<BoxProps, 'id' | 'onClick'>,
    WorkspaceWithImage {
  isActive?: boolean;
  onClick?: (workspaceId: string) => Promise<boolean>;
}

export function WorkspaceCard({
  id,
  name,
  image,
  isActive,
  numberOfMembers,
  onClick,
  ...rest
}: WorkspaceCardProps) {
  const [isLoading, { on, off }] = useBoolean();

  const handleClick = async () => {
    if (isLoading || !onClick) {
      return;
    }

    on();

    try {
      await onClick(id);
    } finally {
      off();
    }
  };

  return (
    <Box
      data-active={!!isActive}
      data-testid="ig-account-card"
      w="100%"
      borderRadius="md"
      border="1px solid"
      borderColor={isActive ? 'brand.500' : 'blackAlpha.200'}
      bg={isActive ? 'rgba(245, 249, 255, 0.5)' : 'white'}
      maxW={350}
      _hover={
        isActive
          ? {}
          : {
              bg: isActive ? 'brand.50' : 'gray.50',
              cursor: 'pointer',
            }
      }
      p="12px"
      onClick={isActive ? undefined : handleClick}
      {...rest}
    >
      <Flex align="center">
        <Center w="40px" h="40px" mr={2}>
          {isLoading ? (
            <Spinner size="md" />
          ) : (
            <Box
              w="100%"
              h="100%"
              backgroundSize="cover"
              backgroundImage={`url(${image})`}
            />
          )}
        </Center>

        <Box overflow="hidden">
          <Text
            textAlign="left"
            color={isActive ? 'brand.500' : 'gray.900'}
            fontWeight={600}
            lineHeight="1.25rem"
            maxW={200}
            ellipsisOverflow
          >
            {name} Workspace
          </Text>
          <Text
            textAlign="left"
            color="gray.500"
            lineHeight="1.25rem"
            ellipsisOverflow
          >
            {numberOfMembers} team member{numberOfMembers === 1 ? '' : 's'}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
