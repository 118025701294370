import { keyframes } from '@emotion/react';

import { getColor } from '../../color';
import {
  ComponentTheme,
  mode,
  Props,
} from './utils';

export interface SkeletonProps {
  startColor?: string;
  endColor?: string;
  speed?: number;
  duration?: number;
}

const frame = (start: string, end: string) => keyframes`
  from {
    border-color: ${start};
    background: ${start};
  }
  to {
    border-color: ${end};
    background: ${end};
  }
`;

type CustomProps = Props & SkeletonProps;

export function getBaseStyle(props: CustomProps) {
  const {
    startColor = mode('blackAlpha.100', 'blackAlpha.800')(props),
    endColor = mode('blackAlpha.400', 'blackAlpha.600')(props),
    speed,
    theme,
  } = props;

  const start = getColor(theme, startColor);
  const end = getColor(theme, endColor);

  return {
    borderColor: start,
    background: end,
    borderRadius: 'sm',
    animation: `${speed}s linear infinite alternate ${frame(start, end)}`,
  };
}

const SkeletonTheme: ComponentTheme<SkeletonProps> = {
  baseStyle: getBaseStyle,
};

export default SkeletonTheme;
