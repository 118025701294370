import { Picker } from 'emoji-mart';
import { FC, useEffect, useState } from 'react';

import { IconButton } from '../../button';
import { LazyPopover } from '../../popover';
import { Emoji } from '../Emoji';

import { EmojiPickerStyles } from './EmojiPicker.styles';
import { EmojiPickerProps } from './types';
import { getRandomEmoji } from './utils';

export const EmojiPicker: FC<EmojiPickerProps> = ({
  emojiSize = 35,
  initiallyOpen = true,
  onSelect,
  value,
  defaultValue: _defaultValue,
  shouldAutoSelectDefaultValue,
  ...props
}) => {
  // Default to a random emoji if one isn't specified
  const [defaultValue, setDefaultValue] = useState(
    _defaultValue || getRandomEmoji(),
  );
  const [isOpen, setIsOpen] = useState(initiallyOpen);

  useEffect(() => {
    setDefaultValue(_defaultValue || getRandomEmoji());
  }, [_defaultValue]);

  useEffect(() => {
    if (!value && shouldAutoSelectDefaultValue) {
      onSelect(defaultValue);
    }
  }, [value, defaultValue, onSelect, shouldAutoSelectDefaultValue]);

  return (
    <>
      <EmojiPickerStyles />
      <LazyPopover
        boundary="window"
        visible={isOpen}
        duration={0.05}
        onHidden={() => setIsOpen(false)}
        content={
          <Picker
            style={{ border: 0 }}
            title="Pick your emoji"
            emoji="point_up"
            onClick={(_, event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
            onSelect={({ id }) => {
              onSelect(id);
              setIsOpen(false);
            }}
            autoFocus
          />
        }
        interactive
      >
        <IconButton
          aria-label="Pick emoji"
          colorScheme="gray"
          onClick={() => setIsOpen(!isOpen)}
          icon={<Emoji emoji={value || defaultValue} size={emojiSize} />}
          {...props}
        />
      </LazyPopover>
    </>
  );
};
