import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useLatestRef } from './useLatestRef';

export const useDebounceCallback = <CallbackArgs extends any[]>(
  callback: (...args: CallbackArgs) => void,
  wait = 100,
  leading = false,
): ((...args: CallbackArgs) => void) => {
  const storedCallback = useLatestRef(callback);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const deps = [wait, leading, storedCallback];
  // Cleans up pending timeouts when the deps change
  useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = void 0;
    },
    deps,
  );

  return useCallback(function () {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const { current } = timeout;
    // Calls on leading edge
    if (current === void 0 && leading) {
      timeout.current = setTimeout(() => {
        timeout.current = void 0;
      }, wait);
      // eslint-disable-next-line prefer-spread
      return storedCallback.current.apply(null, args as any);
    }
    // Clear the timeout every call and start waiting again
    current && clearTimeout(current);
    // Waits for `wait` before invoking the callback
    timeout.current = setTimeout(() => {
      timeout.current = void 0;
      storedCallback.current.apply(null, args as any);
    }, wait);
  }, deps);
};

export const useDebounce = <State extends any>(
  initialState: State | (() => State),
  wait?: number,
  leading?: boolean,
): [State, Dispatch<SetStateAction<State>>] => {
  const state = useState(initialState);
  return [state[0], useDebounceCallback(state[1], wait, leading)];
};
