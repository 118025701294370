import { css } from '@emotion/react';

import { theme } from '../preset-base';

export const generalGlobalStyles = css`
  html,
  body {
    height: 100%;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
    font-feature-settings: 'liga', 'kern';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizelegibility;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    text-decoration-skip-ink: auto;

    @media (min-width: 768px) {
      -webkit-overflow-scrolling: touch;
      height: 100%;
    }
  }

  :focus {
    outline-width: 0 !important;
    outline-offset: 0 !important;
    -moz-outline-radius: 0 !important;
  }

  #root {
    background-color: rgb(249, 249, 249);
    height: 100%;
    min-height: 100vh;
    width: 100%;
    overflow-y: hidden;
    transition: opacity 0.2s ease; // For text tooltip

    @media (min-width: 768px) {
      -webkit-overflow-scrolling: touch;
      height: 100%;
      min-height: auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }

  p,
  label {
    color: ${theme.colors.text.base};
    line-height: 1.5em;
  }

  // react-joyride
  .__floater,
  .react-joyride__overlay {
    z-index: 12001 !important;
  }

  .graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
  }

  .html5-video-player {
    background: white;
  }

  .icon {
    color: #ddd;
    font-size: 150%;
  }

  .css-kj6f9i-menu {
    background-color: white;
  }

  .context-menu-item.icon {
    color: #fff;
    font-size: 100%;
  }

  input {
    font-family: -apple-system, BlinkMacSystemFont, San Francisco,
      Helvetica Neue, Roboto, Ubuntu, Droid Sans, Arial, sans-serif;
    outline: none;
  }

  /*** iPhone and iOS Form Input Zoom Fixes ***/
  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='select'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='select'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='select'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
      font-size: 16px;
    }
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='select'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='url'] {
      font-size: 16px;
    }
  }
`;
