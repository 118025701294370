import { useEffect, useState } from 'react';

import { useIsMounted } from './useIsMounted';

const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';

export interface ScriptProps {
  src: HTMLScriptElement['src'];
  onload?: HTMLScriptElement['onerror'];
  onerror?: HTMLScriptElement['onerror'];
  onLoad?: () => void;
}

type ErrorState = ErrorEvent | null;

interface UseScript {
  loading: boolean;
  error: ErrorState;
}

export function useScript({
  src,
  onLoad,
  ...attributes
}: ScriptProps): UseScript {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorState>(null);

  useEffect(() => {
    if (!isBrowser) return;

    const handleLoad = () => {
      if (isMounted()) {
        setLoading(false);
        if (onLoad) onLoad();
      }
    };

    if (document.querySelector(`script[src="${src}"]`)) {
      handleLoad();
      return;
    }

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', src);

    Object.keys(attributes).forEach((key) => {
      if (scriptEl[key] === undefined) {
        scriptEl.setAttribute(key, attributes[key]);
      } else {
        scriptEl[key] = attributes[key];
      }
    });

    const handleError = (error: ErrorEvent) => {
      if (isMounted()) {
        setError(error);
      }
    };

    scriptEl.addEventListener('load', handleLoad);
    scriptEl.addEventListener('error', handleError);

    document.body.appendChild(scriptEl);

    return () => {
      scriptEl.removeEventListener('load', handleLoad);
      scriptEl.removeEventListener('error', handleError);
    };
  }, [src, attributes, isMounted]);

  return { loading, error };
}
