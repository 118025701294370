import { Component, ErrorInfo } from 'react';

import { Logger } from '@flick-tech/logger';

import { Button } from '../button';
import { Flex, Link } from '../layout';

import { ChunkErrorIllustration, GenericErrorIllustration } from './components';
import { ErrorScreen } from './ErrorScreen';
import { ReportIssueButton } from './ReportIssueButton';

interface ErrorBoundaryProps {
  children?: any;
}

interface ErrorBoundaryState {
  hasError?: boolean;
  error?: Error;
  chunkError?: boolean;
  countdown: number;
}

const COUNTDOWN = 5;

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  timer?: number;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      chunkError: false,
      countdown: COUNTDOWN,
    };

    this.forceReloadFlick = this.forceReloadFlick.bind(this);
    this.tick = this.tick.bind(this);
  }

  forceReloadFlick() {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
    (window.location.reload as (force: boolean) => void)(true);
  }

  tick() {
    const current = this.state.countdown;

    if (current === 0) {
      this.forceReloadFlick();
    } else {
      this.setState({ countdown: current - 1 });
    }
  }

  componentWillUnmount() {
    if (!this.timer) return;

    window.clearInterval(this.timer);
  }

  componentDidCatch(error: Error, { componentStack }: ErrorInfo) {
    if (error?.name === 'ChunkLoadError' || error?.message?.includes('chunk')) {
      this.setState({ chunkError: true });
      this.timer = window.setInterval(() => this.tick(), 1000);
    } else {
      Logger.error(error, {
        sentry: {
          contexts: { react: { componentStack } },
        },
      });
    }

    this.setState({ hasError: true, error });
  }

  render() {
    const { hasError, chunkError, countdown } = this.state;

    if (hasError) {
      if (chunkError) {
        return (
          <ErrorScreen
            title="A new version of Flick is loading..."
            body={
              <ErrorScreen.BodyText>
                Please wait {countdown} seconds.
              </ErrorScreen.BodyText>
            }
            footerText={
              <>
                If your page doesn't refresh after a few seconds, please{' '}
                <Link
                  fontWeight="inherit"
                  color="inherit"
                  textDecoration="underline"
                  onClick={this.forceReloadFlick}
                >
                  click here
                </Link>
                .
              </>
            }
            illustration={<ChunkErrorIllustration />}
          />
        );
      } else {
        return (
          <ErrorScreen
            title="Oops, something went wrong..."
            body={
              <>
                <ErrorScreen.BodyText>
                  Please go back to where you were and try again. If the problem
                  persists, please report the issue so we can get it fixed.
                </ErrorScreen.BodyText>
                {this.state.error?.message && (
                  <ErrorScreen.ErrorText mt={2}>
                    {this.state.error.message}
                  </ErrorScreen.ErrorText>
                )}
                <Flex align="center" mt={6}>
                  <Button onClick={this.forceReloadFlick}>Go Back</Button>
                  <ReportIssueButton ml={4} />
                </Flex>
              </>
            }
            footerText={
              <>
                If you feel like you shouldn't be seeing this page, please
                report the bug and let us know.
              </>
            }
            illustration={<GenericErrorIllustration />}
          />
        );
      }
    }

    return this.props.children;
  }
}
