import SelectLib from 'react-select/async';

import { AsyncSelectProps } from '../Select.types';

import { useSelectCommonProps } from './utils';

export function AsyncSelect<O>({
  size,
  label,
  selectRef,
  hasError,
  error,
  loadOptions,
  dataTestId,
  ...rest
}: AsyncSelectProps<O>) {
  const props = useSelectCommonProps<O>({
    ...rest,
    hasError,
    size,
  });

  return (
    <SelectLib
      {...props}
      loadOptions={loadOptions}
      ref={(ref: any) => {
        if (ref && selectRef) {
          selectRef.current = ref;
        }
      }}
    />
  );
}

export default AsyncSelect;
