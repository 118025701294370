import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

import { chakra } from '@flick-tech/theme-new';
import { Slider, SliderProps, SliderValue } from '../slider';

import { NumericInputs } from './NumericInputs';

export type RangeValue = [number, number];

export interface RangeSliderProps
  extends Omit<SliderProps, 'defaultValue' | 'onChange' | 'value'> {
  initialValue?: RangeValue;
  onChange?: (values: RangeValue) => void;
  range?: { min: number; max: number };
  withInputs?: boolean;
  debounceMS?: number;
}

export function RangeSlider({
  valueSet = [],
  range: range_,
  initialValue: initialValue_,
  onChange,
  withInputs,
  debounceMS,
  ...rest
}: RangeSliderProps) {
  const range = range_ ?? {
    min: valueSet[0],
    max: valueSet[valueSet.length - 1],
  };
  const initialValue = initialValue_ ?? [range.min, range.max];
  const [localValue, setLocalValue] = useState<RangeValue>(initialValue);

  const [valueMin, valueMax] = localValue;

  const handleChange = useCallback(
    // Prevents constant re-renderings (of whatever uses RangeSlider)
    debounceMS ? debounce(onChange, debounceMS) : onChange,
    [onChange, debounceMS],
  );

  const handleInputsChange = useCallback(
    (values: RangeValue) => {
      setLocalValue(values);
      handleChange?.(values);
    },
    [handleChange],
  );

  const handleSliderChange = useCallback(
    (_, newValue: SliderValue) => {
      handleInputsChange(newValue as RangeValue);
    },
    [handleInputsChange],
  );

  const valuesEqual = Number.isFinite(valueMax) && valueMin === valueMax;

  return (
    <chakra.div pl={1} pr={1} width="100%">
      <Slider
        value={localValue}
        valueSet={valueSet}
        onChange={handleSliderChange}
        {...rest}
      />
      <chakra.div
        display="flex"
        justifyContent="space-between"
        color="blackAlpha.600"
      >
        <chakra.span>Min</chakra.span>
        <chakra.span>Max</chakra.span>
      </chakra.div>
      {withInputs && (
        <NumericInputs
          mt={1}
          min={range.min}
          max={range.max}
          values={localValue}
          onChange={handleInputsChange}
        />
      )}
      {valuesEqual && (
        <chakra.div color="red.700" fontSize="small" mt={2}>
          Min and max values cannot be equal
        </chakra.div>
      )}
    </chakra.div>
  );
}
