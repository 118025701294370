import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <path
    d="M3 6.5h11.333M3 10.75h11.333M7.25 2.25L5.833 15M11.5 2.25L10.083 15"
    stroke="currentColor"
    strokeWidth={1.75}
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  'Hashtag',
  { width: '18px', height: '18px', viewBox: '0 0 18 18', fill: 'none' },
);
