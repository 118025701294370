import CountryCode from 'iso-3166-1-alpha-2';

import country2to3 from './country2to3.json';
import worldCountries from './worldCountries.json';

const country3to2: Record<string, string> = Object.entries(country2to3).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  {},
);

export const countryFeatures = worldCountries.features.map((feature) => {
  const id = country3to2[feature.id] || feature.id;
  return {
    ...feature,
    id,
    properties: { name: CountryCode.countries[id] },
  };
});
