import { TooltipStat, TooltipStatsWrapper } from '../Tooltip';

interface MakePieChartTooltipProps {
  valuePostfix?: string;
  labelPrefix?: string;
  postfix: string;
}

export const makePieChartTooltip =
  ({ labelPrefix, valuePostfix = '%', postfix }: MakePieChartTooltipProps) =>
  ({ color, value, label }) => {
    return (
      <TooltipStatsWrapper>
        <TooltipStat
          label={labelPrefix ? `${labelPrefix} ${label}` : label}
          color={color}
          value={`${value}${valuePostfix}`}
          postfix={postfix}
          vertical
        />
      </TooltipStatsWrapper>
    );
  };
