import { Fragment } from 'react';
import * as React from 'react';

import { Chip } from '../chip';
import { Input } from '../input';
import { Box, InlineBox } from '../layout';

const chipSpacing = 2;
const boxPadding = 3;

type Chip = string;

export type Chips = Chip[];

type ChipInputProps = {
  /** Allows duplicate chips if set to true. */
  allowDuplicates?: boolean;
  /** Behavior when the chip input is blurred: `'clear'` clears the input, `'add'` creates a chip and `'ignore'` keeps the input. */
  blurBehavior?: 'clear' | 'add' | 'ignore';
  /** Whether the input value should be cleared if the `value` prop is changed. */
  clearInputValueOnChange?: boolean;
  /** The chips to display by default (for uncontrolled mode). */
  defaultValue?: Chips;
  /** Disables the chip input if set to true. */
  disabled?: boolean;
  /** The input value (enables controlled mode for the text input if set). */
  inputValue?: Chip;
  /** Callback function that is called when a new chip was added (only in controlled mode - meaning value prop is provided). */
  onAdd?: (value: Chip) => void;
  /** Callback function that is called with the chip to be added and should return true to add the chip or false to prevent the chip from being added without clearing the text input. */
  onBeforeAdd?: (value: Chip) => boolean;
  /** Callback function that is called when the chips change (in uncontrolled mode). */
  onChange?: (value: Chips) => void;
  /** Callback function that is called when a new chip was removed (in controlled mode). */
  onRemove?: (value: Chip, index: number) => void;
  /** Callback function that is called when the input changes. */
  onInputChange?: (value: string) => void;
  /** Callback function that is caleld on ChipInput blur, chips are provided only in uncontrolled mode */
  onBlur?: (value?: Chips) => void;
  /** A placeholder that is displayed if the input has no value. */
  placeholder?: string;
  /** Makes the chip input read-only if set to true. */
  readOnly?: boolean;
  /** The chips to display (enables controlled mode if set). */
  value?: Chips;
};

type ChipInputState = {
  chips: Chips;
  prevPropsValue: Chips;
  focusedChip: number | null;
  inputValue: string;
  isWrapperFocusable: boolean;
  isFocused: boolean;
  chipsUpdated: boolean;
};

export class ChipInput extends React.Component<ChipInputProps, ChipInputState> {
  state: ChipInputState = {
    chips: [],
    focusedChip: null,
    inputValue: '',
    isWrapperFocusable: true,
    isFocused: false,
    chipsUpdated: false,
    prevPropsValue: [],
  };

  actualInput: HTMLInputElement;

  static defaultProps: Partial<ChipInputProps> = {
    allowDuplicates: false,
    blurBehavior: 'add',
    clearInputValueOnChange: false,
  };

  constructor(props: ChipInputProps) {
    super(props);
    if (props.defaultValue) {
      this.state.chips = props.defaultValue;
    }
  }

  static getDerivedStateFromProps(
    props: ChipInputProps,
    state: ChipInputState,
  ): ChipInputState {
    let newState = null;

    if (props.value && props.value.length !== state.prevPropsValue.length) {
      newState = { prevPropsValue: props.value };
      if (props.clearInputValueOnChange) {
        newState.inputValue = '';
      }
    }

    // if change detection is only needed for clearInputValueOnChange
    if (
      props.clearInputValueOnChange &&
      props.value &&
      props.value.length !== state.prevPropsValue.length
    ) {
      newState = { prevPropsValue: props.value, inputValue: '' };
    }

    if (props.disabled) {
      newState = { ...newState, focusedChip: null };
    }

    if (!state.chipsUpdated && props.defaultValue) {
      newState = { ...newState, chips: props.defaultValue };
    }

    return newState;
  }

  handleWrapperBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    this.setState({ isFocused: false });
    if (this.state.focusedChip != null) {
      this.setState({ focusedChip: null });
    }
    const value = this.actualInput.value;
    let withValue = false;
    switch (this.props.blurBehavior) {
      case 'add':
        withValue = this.handleAddChip(value);
        break;
      case 'clear':
        this.clearInput();
        break;
      // Ignore by default
    }
    if (!this.props.onBlur) return;
    const isControlled = this.props.value !== undefined;
    if (isControlled) {
      this.props.onBlur();
    } else {
      // This colud cause issues if this.handleAddChip would manipulate the value in some way
      this.props.onBlur(
        withValue ? [...this.state.chips, value] : this.state.chips,
      );
    }
  };

  handleWrapperFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    this.setState({ isFocused: true });
    this.actualInput.focus();
  };

  _keyPressed: boolean;
  _preventChipCreation: boolean;
  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { focusedChip } = this.state;
    this._keyPressed = false;
    this._preventChipCreation = false;
    const chips = this.props.value || this.state.chips;

    const value = this.actualInput.value;

    if (event.shiftKey && event.key === 'Tab') {
      // Handle shift+tab, when input element is focused, natural previous element to focus its the wrapper (that has tabindex)
      // isWrapperFocusable=false set for a moment helps prevent that from happening (by removing tabindex)
      this.setState({ isWrapperFocusable: false });
      setTimeout(() => {
        this.setState({ isWrapperFocusable: true });
      });
      return;
    }

    switch (event.key) {
      case 'Enter':
        if (this.handleAddChip(value) !== false) {
          event.preventDefault();
        }
        return;
      case 'Backspace':
        if (value === '') {
          if (focusedChip != null) {
            this.handleDeleteChip(chips[focusedChip], focusedChip);
            if (focusedChip > 0) {
              this.setState({ focusedChip: focusedChip - 1 });
            }
          } else {
            this.setState({ focusedChip: chips.length - 1 });
          }
        }
        break;
      case 'Delete':
        if (value === '' && focusedChip != null) {
          this.handleDeleteChip(chips[focusedChip], focusedChip);
          if (focusedChip <= chips.length - 1) {
            this.setState({ focusedChip });
          }
        }
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        if (focusedChip == null && value === '' && chips.length) {
          this.setState({ focusedChip: chips.length - 1 });
        } else if (focusedChip != null && focusedChip > 0) {
          this.setState({ focusedChip: focusedChip - 1 });
        }
        break;
      case 'ArrowRight':
      case 'ArrowDown':
        if (focusedChip != null && focusedChip < chips.length - 1) {
          this.setState({ focusedChip: focusedChip + 1 });
        } else {
          this.setState({ focusedChip: null });
        }
        break;
      default:
        this.setState({ focusedChip: null });
        break;
    }
  };

  handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !this._preventChipCreation &&
      event.key === 'Enter' &&
      this._keyPressed
    ) {
      this.clearInput();
    } else {
      this.updateInput(this.actualInput.value);
    }
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    this._keyPressed = true;
  };

  handleUpdateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.inputValue == null) {
      this.updateInput(e.target.value);
    }

    if (this.props.onInputChange) {
      this.props.onInputChange(e.target.value);
    }
  };

  /**
   * Handles adding a chip.
   * @param {string|object} chip Value of the chip, either a string or an object (if dataSourceConfig is set)
   * @returns True if the chip was added (or at least `onAdd` was called), false if adding the chip was prevented
   */
  handleAddChip(chip: Chip) {
    if (this.props.onBeforeAdd && !this.props.onBeforeAdd(chip)) {
      this._preventChipCreation = true;
      return false;
    }
    this.clearInput();
    const chips = this.props.value || this.state.chips;

    if (chip.trim().length > 0) {
      if (this.props.allowDuplicates || chips.indexOf(chip) === -1) {
        if (this.props.value && this.props.onAdd) {
          this.props.onAdd(chip);
        } else {
          this.updateChips([...this.state.chips, chip]);
        }
      }
      return true;
    }
    return false;
  }

  handleDeleteChip(chip: Chip, i: number) {
    if (!this.props.value) {
      const chips = this.state.chips.slice();
      const changed = chips.splice(i, 1); // remove the chip at index i
      if (changed) {
        let focusedChip = this.state.focusedChip;
        // Not sure what is that for but the '!== 0' part is so you can keep deleting if delete first chip
        if (focusedChip === i && focusedChip !== 0) {
          focusedChip = null;
        } else if (focusedChip > i) {
          focusedChip = this.state.focusedChip - 1;
        }
        this.updateChips(chips, { focusedChip });
      }
    } else if (this.props.onRemove) {
      this.props.onRemove(chip, i);
    }
  }

  updateChips(chips: Chips, additionalUpdates: Partial<ChipInputState> = {}) {
    setTimeout(() => {
      if (this.props.onChange) {
        this.props.onChange(chips);
      }
      this.setState({
        chips,
        chipsUpdated: true,
        ...additionalUpdates,
      } as ChipInputState);
    });
  }

  /**
   * Clears the text field for adding new chips.
   * This only works in uncontrolled input mode, i.e. if the inputValue prop is not used.
   * @public
   */
  clearInput() {
    this.updateInput('');
  }

  updateInput(value: string) {
    this.setState({ inputValue: value });
  }

  /**
   * Set the reference to the actual input, that is the input of the Input.
   * @param {object} ref - The reference
   */
  setActualInputRef = (ref: HTMLInputElement) => {
    this.actualInput = ref;
  };

  render() {
    const { disabled, inputValue, placeholder, readOnly, value } = this.props;

    const chips = value || this.state.chips;
    const actualInputValue =
      inputValue != null ? inputValue : this.state.inputValue;

    // This is slowing things down significantly. TODO: memo this
    const chipComponents = chips.map((value, i) => {
      return (
        <Fragment key={value}>
          <Chip
            isFocused={this.state.focusedChip === i}
            size="sm"
            // Chips focus is controlled by state (via arrow keys)
            tabIndex={-1}
            onClick={() => {
              this.setState({ focusedChip: i });
            }}
            onRemove={() => this.handleDeleteChip(value, i)}
            variant="subdued"
            isDisabled={Boolean(disabled)}
            m={chipSpacing / 2}
          >
            {value}
          </Chip>
          <InlineBox
            pointerEvents="none"
            m={chipSpacing / 2}
            textAlign="center"
            display="flex"
            alignItems="center"
          >
            {' '}
            or{' '}
          </InlineBox>
        </Fragment>
      );
    });

    return (
      <Box
        my={3}
        p={boxPadding - chipSpacing / 2}
        border="solid 2px"
        borderRadius="md"
        borderColor={this.state.isFocused ? 'brand.500' : 'blackAlpha.50'}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        cursor="text"
        minHeight={`${34 + chipSpacing * 4}px`}
        boxSizing="content-box"
        tabIndex={this.state.isWrapperFocusable ? 0 : undefined}
        onFocus={this.handleWrapperFocus}
        onBlur={this.handleWrapperBlur}
      >
        {chipComponents}
        <Input
          display="inline-flex"
          flexWrap="wrap"
          flex="1"
          minWidth="70px"
          appearance="none"
          height="34px"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          float="left"
          margin={chipSpacing / 2}
          ref={this.setActualInputRef}
          variant="unstyled"
          value={actualInputValue}
          tabIndex={-1}
          onChange={this.handleUpdateInput}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          onKeyUp={this.handleKeyUp}
          isDisabled={disabled}
          placeholder={
            placeholder || chips.length
              ? ' +Add another keyword'
              : '+Add keyword'
          }
          isReadOnly={readOnly}
        />
      </Box>
    );
  }
}
