import { useEffect, useState } from 'react';

import { isRunningOnServerSide } from '@flick-tech/shared-common';

function getInnerDimensions(el: HTMLElement) {
  if (!el) {
    return {
      innerWidth: 0,
      innerHeight: 0,
    };
  }

  const computedStyle = getComputedStyle(el);

  const innerHeight =
    el.clientHeight -
    (parseFloat(computedStyle.paddingTop || '0') +
      parseFloat(computedStyle.paddingBottom || '0'));

  const innerWidth =
    el.clientWidth -
    (parseFloat(computedStyle.paddingLeft || '0') +
      parseFloat(computedStyle.paddingRight || '0'));

  return { innerWidth, innerHeight };
}

function getSize(el: HTMLElement) {
  if (!el) {
    return { width: 0, height: 0, innerWidth: 0, innerHeight: 0 };
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
    ...getInnerDimensions(el),
  };
}

export function useComponentSize(ref: any) {
  const [ComponentSize, setComponentSize] = useState(getSize(ref.current));

  function handleResize() {
    if (ref && ref.current) {
      setComponentSize(getSize(ref.current));
    }
  }

  useEffect(() => {
    if (isRunningOnServerSide()) {
      return () => {
        // @ts-disable
      };
    }

    handleResize();

    const win: any = window;

    if (win.ResizeObserver) {
      if (ref.current) {
        let resizeObserver = new win.ResizeObserver(() => handleResize());
        resizeObserver.observe(ref.current);

        return () => {
          resizeObserver.disconnect(ref.current);
          resizeObserver = null;
        };
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return ComponentSize;
}
