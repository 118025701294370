import * as React from 'react';

import { SVGIcon, SVGIconProps } from '../../../SVGIcon';

export default function createSvgIcon(
  path: React.ReactNode,
  displayName: string,
  defaultProps: SVGIconProps = {},
) {
  const Component = (props: SVGIconProps, ref: React.Ref<SVGSVGElement>) => (
    <SVGIcon fill="currentColor" ref={ref} {...defaultProps} {...props}>
      {path}
    </SVGIcon>
  );

  if (process.env.NODE_ENV !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  return React.memo(React.forwardRef(Component));
}
