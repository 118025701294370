import { differenceInDays, isSameMonth, isSameWeek } from 'date-fns';

// period size -> predicate for days aggregated into one datapoint
const aggregations = [
  { moreThan: 180, isSameChunk: isSameMonth },
  {
    moreThan: 90,
    isSameChunk: (left: Date | number, right: Date | number) =>
      differenceInDays(left, right) < 14,
  },
  { moreThan: 30, isSameChunk: isSameWeek },
];

export type AggregatedDatum<TDatum> = TDatum & {
  dateFrom?: Date;
};

export function aggregateLongDataPeriods<TDatum extends object>(
  data: TDatum[],
  getDate: (datum: TDatum) => Date | undefined,
  /**
   * for example: `average`, `pickLatest`, `sum`
   */
  reducer: (a: TDatum, b: TDatum) => TDatum,
): AggregatedDatum<TDatum>[] {
  const length = data.length - 1;

  if (length < 30) {
    return data;
  }

  const { isSameChunk, moreThan } = aggregations.find(
    (x) => length >= x.moreThan,
  );

  const aggregatedData: AggregatedDatum<TDatum>[] = [];
  let currentChunkDateFrom = getDate(data[0]);
  let lastOfCurrentChunk = data[0];

  for (const datum of data) {
    if (datum === data[0]) {
      continue;
    }

    const datumDate = getDate(datum);

    if (isSameChunk(datumDate, currentChunkDateFrom)) {
      lastOfCurrentChunk = reducer(lastOfCurrentChunk, datum);
    } else {
      aggregatedData.push({
        ...lastOfCurrentChunk,
        dateFrom: currentChunkDateFrom,
      });

      currentChunkDateFrom = datumDate;
      lastOfCurrentChunk = datum;
    }
  }

  aggregatedData[aggregatedData.length - 1] = {
    ...lastOfCurrentChunk,
    dateFrom: currentChunkDateFrom,
  };

  // HACK: This is passed straight to FlickBaseChart. It shouldn't be.
  //       It needs a fix right after I tame my backlog.
  if (moreThan === 180 && Boolean(aggregatedData[0])) {
    (
      aggregatedData[0] as any as {
        dateFormat: Intl.DateTimeFormatOptions;
      }
    ).dateFormat = { month: 'short' };
  }

  return aggregatedData;
}
