import * as React from 'react';

import { SVGIcon, SVGIconProps } from '../icon';

export default function createLogo(
  path,
  displayName: 'WhiteLogo' | 'BlackLogo' | 'DefaultLogo',
) {
  const Component = (
    {
      size = '34px',
      ...props
    }: Omit<SVGIconProps, 'size'> & {
      size?: number | string;
    },
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <SVGIcon
      width={size}
      height={size}
      viewBox="0 0 360 360"
      fill="none"
      ref={ref}
      {...props}
    >
      {path}
    </SVGIcon>
  );

  if (process.env.NODE_ENV !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    Component.displayName = `${displayName}Icon`;
  }

  return React.memo(React.forwardRef(Component));
}
