import { CSSProperties, forwardRef } from 'react';
import * as React from 'react';

import { SystemProps } from '@flick-tech/theme-new';

import { Box } from '../../../layout';
import { InputText, InputTextProps } from '../InputText';

type StyleProps = 'minWidth' | 'width' | 'maxWidth';

export interface SearchInputProps
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'size' | StyleProps
    >,
    Pick<InputTextProps, 'iconPosition'>,
    Pick<SystemProps, StyleProps> {
  loading?: boolean;
  onClickIcon?: () => void;
  placeholder?: string;
  wrapperStyle?: CSSProperties;
  large?: boolean;
}

export const SearchInput = forwardRef(function SearchInput(
  {
    loading,
    onClickIcon,
    placeholder,
    wrapperStyle,
    large,
    minWidth,
    width = '100%',
    maxWidth,
    ...rest
  }: SearchInputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  return (
    <Box
      position="relative"
      style={wrapperStyle}
      minWidth={minWidth}
      width={width}
      maxWidth={maxWidth}
      pt-id="search-input"
    >
      <InputText
        {...rest}
        ref={ref}
        large={large}
        autoComplete="off"
        disabled={loading}
        onClickIcon={onClickIcon}
        loading={loading}
        icon="SearchRounded"
        placeholder={placeholder || 'Search a hashtag'}
        opacity={loading ? 0.6 : 1}
      />
    </Box>
  );
});

export default SearchInput;
