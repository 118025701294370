import {
  useActiveIGUsername,
  useSetActiveIGUsername,
} from './useActiveIGAccount';
import {
  GetSocialGroupsOptions,
  useGetSocialGroups,
} from './useGetSocialGroups';
import { useMyActiveSocialGroup } from './useMyActiveSocialGroup';

export function useSocialGroups({
  linkedOnly,
  sortBy,
}: GetSocialGroupsOptions = {}) {
  const {
    socialGroups,
    socialGroupIGAccounts,
    isLoading,
    isFetched,
    isFetching,
    refetch,
  } = useGetSocialGroups({ sortBy, linkedOnly });

  const { activeSocialGroup } = useMyActiveSocialGroup();

  const { activeIGUsername } = useActiveIGUsername();
  const { setActiveIGUsername } = useSetActiveIGUsername();

  const igAccounts = socialGroupIGAccounts.map((acc) => ({
    ...acc.data,
    socialGroupId: acc.socialGroupId,
    linked: acc.linked,
  }));

  const linkedIGAccounts = igAccounts.filter(({ linked }) => linked);

  const hasLinkedSocialAccountsToActiveSocialGroup = [
    activeSocialGroup?.igAccount?.linked,
    activeSocialGroup?.facebook,
  ].some(Boolean);

  const activeIGAccount = linkedIGAccounts.find(
    ({ username }) => username === activeIGUsername,
  );

  return {
    refetch,
    loading: isLoading,
    igAccounts,
    linkedIGAccounts,
    activeIGAccount,
    hasLinkedIGAccounts: linkedIGAccounts.length > 0,
    hasLinkedSocialAccountsToActiveSocialGroup,
    setActiveUsername: setActiveIGUsername,
    activeUsername: activeIGUsername,
    activeSocialGroup,
    socialGroups,
    isFetched,
    isFetching,
    isLoading,
  };
}
