import { Constants } from '@flick-tech/shared-common';
import { IWorkspaceFeature } from '@flick-tech/shared-types';

import { useGetUser } from './useGetUser';

// For dev purposes
const overrides: { [K in IWorkspaceFeature]?: boolean } = Constants.isDev
  ? {
      NewAnalytics: true,
    }
  : {};

export function useHasAccessToFeature(feature: IWorkspaceFeature): boolean {
  const { user } = useGetUser();

  if (overrides[feature] !== undefined && Constants.isDev) {
    return overrides[feature];
  }

  const features = user?.activeWorkspace?.features ?? [];

  return features.includes(feature);
}
