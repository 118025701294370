import sortBy from 'lodash/sortBy';

import { useGetUser } from '@flick-tech/core-hooks';
import {
  IInstagramAccountFieldsFragment,
  ISocialGroupFieldsFragment,
  ISocialGroupIgAccountFieldsFragment,
} from '@flick-tech/shared-types';

export interface SocialGroupIGAccount
  extends ISocialGroupIgAccountFieldsFragment {
  linked: boolean;
  socialGroupId: string;
}

export interface SocialGroupIGAccountData
  extends IInstagramAccountFieldsFragment {
  linked: boolean;
  socialGroupId: string;
}

export interface GetSocialGroupsOptions {
  sortBy?: string;
  linkedOnly?: boolean;
}

export function useGetSocialGroups({
  sortBy: propSortBy,
  linkedOnly,
}: GetSocialGroupsOptions = {}) {
  const { user, ...rest } = useGetUser();

  const fetchedSocialGroups = user?.activeWorkspace?.socialGroups ?? [];

  let socialGroups = (
    linkedOnly
      ? fetchedSocialGroups.filter(
          (sg) =>
            (sg?.igAccount?.linked && sg?.igAccount?.data) ||
            sg?.facebook?.connection,
        )
      : fetchedSocialGroups
  ) as ISocialGroupFieldsFragment[];

  if (propSortBy?.length) {
    socialGroups = sortBy(socialGroups, [propSortBy]);
  }

  return {
    ...rest,
    socialGroups,
    // TODO: remove once we release social groups & refactor .linked out
    linkedIGAccounts: socialGroups.filter((sg) =>
      Boolean(sg.igAccount?.linked),
    ),
    socialGroupIGAccounts: socialGroups
      .map((sg) => ({
        ...sg.igAccount,
        socialGroupId: sg.id,
      }))
      .filter((item) => Boolean(item?.data)) as SocialGroupIGAccount[],
  };
}

useGetSocialGroups.getKey = () => useGetUser.getKey();
