import { forwardRef, Ref } from 'react';

import { FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { SystemStyleObject } from '@flick-tech/theme-new';

import { Box, BoxProps } from './Box';

export interface CenterProps extends BoxProps {}

/**
 * React component used to horizontally and vertically center its child.
 * It uses the popular `display: flex` centering technique.
 *
 * @see Docs https://chakra-ui.com/center
 */
export const Center: FC<CenterProps> = forwardRef(
  (props: CenterProps, ref: Ref<any>) => {
    const { children, ...rest } = props;

    return (
      <Box
        ref={ref}
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...rest}
      >
        {children}
      </Box>
    );
  },
);

if (__DEV__) {
  Center.displayName = 'Center';
}

/**
 * As a constraint, you can't pass size related props
 * Only `size` would be allowed
 */
type Omitted = 'size' | 'boxSize' | 'width' | 'height' | 'w' | 'h';

export interface SquareProps extends Omit<BoxProps, Omitted> {
  /**
   * The size (width and height) of the square
   */
  size?: BoxProps['width'];
  /**
   * If `true`, the content will be centered in the square
   */
  centerContent?: boolean;
}

export const Square = forwardRef((props: SquareProps, ref: Ref<any>) => {
  const { size, centerContent = true, ...rest } = props;

  const styles: BoxProps = centerContent
    ? { display: 'flex', alignItems: 'center', justifyContent: 'center' }
    : {};

  return (
    <Box
      ref={ref}
      boxSize={size}
      {...styles}
      flexShrink={0}
      flexGrow={0}
      {...rest}
    />
  );
});

if (__DEV__) {
  Square.displayName = 'Square';
}

export const Circle = forwardRef((props: SquareProps, ref) => {
  const { size, ...rest } = props;
  return (
    <Square size={size} ref={ref as any} borderRadius="9999px" {...rest} />
  );
});

if (__DEV__) {
  Circle.displayName = 'Circle';
}
