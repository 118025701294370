import { Box, BoxProps } from '../layout';

export interface ChartDotProps extends Omit<BoxProps, 'size'> {
  color?: string;
  size?: number;
}

const getSize = (size) => `${size}px`;

export const ChartDot = ({ size = 10, ...rest }: ChartDotProps) => (
  <Box
    width={getSize(size)}
    height={getSize(size)}
    backgroundColor={rest.color}
    borderRadius="50%"
    display="inline-block"
    flexShrink={0}
    {...rest}
  />
);
