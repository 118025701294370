import { FieldRenderProps } from 'react-final-form';

import { EmojiPicker } from './EmojiPicker';
import { EmojiPickerProps } from './types';

interface FinalFormEmojiPickerPropTypes
  extends FieldRenderProps<any>,
    EmojiPickerProps {}

export function FinalFormEmojiPicker({
  input: { onChange, value },
  ...rest
}: FinalFormEmojiPickerPropTypes) {
  return <EmojiPicker onSelect={onChange} value={value} {...rest} />;
}
