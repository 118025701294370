export function ChunkErrorIllustration() {
  return (
    <svg
      width="280px"
      height="265px"
      viewBox="0 0 280 265"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M221.527 215.161C233.014 206.516 343.71 115.745 225.192 29.6633C106.674 -56.418 91.3712 69.3363 32.1962 98.4599C-26.9787 127.584 26.5359 243.675 93.5807 254.637C153.341 264.409 197.177 233.488 221.527 215.161Z"
        fill="#3174E0"
      />
      <path
        d="M195.51 240.97C195.04 239.109 93.2936 303.978 31.9597 224.221"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M27.9499 219.045C27.9499 219.045 -28.6237 134.18 20.6643 100.567"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M168.26 174.932C168.26 174.932 213.733 180.615 225.59 166.899C237.446 153.183 184.626 141.81 178.745 141.223C172.865 140.635 144.304 161.999 144.304 161.999L168.26 174.932Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
      />
      <path
        d="M172.279 155.624C172.279 155.624 189.625 149.527 206.578 151.694C206.578 151.694 210.695 150.872 192.172 149.603C173.65 148.334 174.042 146.467 174.042 146.467L172.279 155.624Z"
        fill="#1D1D1D"
      />
      <path
        d="M155.618 161.946C155.618 161.946 161.646 160.55 168.334 163.122C175.022 165.695 177.962 168.782 176.933 173.338C175.904 177.895 165.393 176.94 149.371 174.439L155.618 161.946Z"
        fill="#1D1D1D"
      />
      <path
        d="M120.926 96.301C120.926 96.301 125.238 96.5959 128.145 95.3866C131.052 94.1772 127.044 110.544 122.401 111.782C117.757 113.02 104.053 115.303 100.476 112.501C100.476 112.501 104.089 109.316 103.688 107.797C103.286 106.277 120.926 96.301 120.926 96.301Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
      />
      <path
        d="M118.082 105.717C118.082 105.717 114.844 111.431 111.777 112.947C111.777 112.947 104.87 113.517 103.139 111.23C101.408 108.942 111.125 100.793 111.125 100.793"
        fill="#1D1D1D"
      />
      <path
        d="M94.8573 97.0206C94.8573 97.0206 99.1939 106.697 105.421 108.511C111.648 110.324 123.265 107.464 120.631 94.6922C117.946 81.6649 107.699 81.8794 107.699 81.8794L92.8029 91.0423C92.8029 91.0423 91.6728 94.1288 94.8573 97.0206Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
      />
      <path
        d="M101.196 93.2023C103.745 96.4363 108.528 88.6379 110.831 89.275C113.134 89.9122 114.948 90.3039 116.466 87.7554C117.985 85.207 119.448 90.7473 119.448 90.7473C119.448 90.7473 121.464 87.7554 119.7 85.306C117.936 82.8565 120.191 83.8832 119.652 81.875C119.113 79.8668 116.59 81.5086 116.075 79.8173C115.56 78.126 114.754 76.4854 112.595 76.8529C110.436 77.2204 109.215 80.2828 107.328 78.3725C105.441 76.4623 103.334 78.1029 102.036 79.8668C100.738 81.6307 98.0911 77.2446 95.788 79.3034C93.4849 81.3622 94.4489 87.7884 92.4231 87.9678C90.3973 88.1472 88.0458 91.0389 90.3984 93.6358C90.3984 93.6358 91.6473 94.3467 90.9376 95.4977C90.2278 96.6487 90.6922 97.8745 91.4515 98.4863C92.2107 99.0981 92.7257 99.1465 92.3097 100.521C91.8938 101.895 92.1865 104.342 96.8422 104.66C96.8422 104.66 98.6545 104.98 99.7835 104.579C99.7835 104.579 98.353 103.648 98.9406 102.505C100.28 99.9245 97.18 99.8871 97.7643 97.4431C98.3486 94.9992 97.0128 95.3348 96.4582 93.8669C95.9036 92.399 98.649 89.9716 101.196 93.2023Z"
        fill="#1D1D1D"
      />
      <path
        d="M93.2947 114.391C93.2947 114.391 90.6428 115.932 86.5274 122.991C82.412 130.049 75.3552 148.054 88.5851 156.801L102.845 163.122L172.083 147.54C172.083 147.54 170.906 126.371 169.289 118.874C169.289 118.874 182.618 118.091 186.439 116.767C186.439 116.767 188.988 138.058 196.563 137.177C196.563 137.177 197.795 141.48 198.253 142.656C198.253 142.656 204.59 142.884 206.158 140.762C206.158 140.762 205.342 135.241 205.473 134.82C205.473 134.82 209.499 133.137 206.706 121.818C203.913 110.498 203.545 98.1507 181.642 93.3729C159.739 88.595 139.893 89.5512 126.149 94.7692C126.149 94.7692 130.485 103.441 119.974 110.02C109.464 116.6 101.201 110.351 101.201 110.351L93.2947 114.391Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
      />
      <path
        d="M104.499 136.412C104.499 136.412 135.01 138.893 168.761 117.638"
        stroke="#3174E0"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M104.499 142.321C104.499 142.321 140.602 149.759 169.802 124.001"
        stroke="#3174E0"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M126.805 151.694C126.805 151.694 115.781 149.965 99.1695 152.832C99.1695 152.832 94.1936 159.01 87.6155 156.117C87.6155 156.117 74.0809 165.03 86.3303 173.559C98.5797 182.088 155.911 175.421 155.911 175.421C155.911 175.421 161.987 163.955 155.911 157.879C149.836 151.803 126.805 151.694 126.805 151.694Z"
        fill="#F5F5F5"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
      />
      <path
        d="M132.245 156.804C108.476 155.484 97.6257 157.882 97.6257 157.882C97.6257 157.882 93.9592 156.902 101.162 154.551C106.811 152.706 121.641 154.414 127.736 154.991C129.414 155.147 132.245 156.804 132.245 156.804Z"
        fill="#1D1D1D"
      />
      <path
        d="M257.651 175.904H50.6462C49.0297 175.904 47.7192 177.215 47.7192 178.831V200.417C47.7192 202.034 49.0297 203.344 50.6462 203.344H257.651C259.268 203.344 260.578 202.034 260.578 200.417V178.831C260.578 177.215 259.268 175.904 257.651 175.904Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.26"
        d="M252.433 181.24H57.2244C55.0152 181.24 53.2244 183.031 53.2244 185.24V193.717C53.2244 195.926 55.0152 197.717 57.2244 197.717H252.433C254.642 197.717 256.433 195.926 256.433 193.717V185.24C256.433 183.031 254.642 181.24 252.433 181.24Z"
        fill="#3174E0"
      />
      <path
        d="M70.7992 181.24H57.2244C55.0152 181.24 53.2244 183.031 53.2244 185.24V193.717C53.2244 195.926 55.0152 197.717 57.2244 197.717H70.7992V181.24Z"
        fill="#3174E0"
      />
      <path
        d="M154.753 188.891C154.753 191.285 153.865 192.607 152.305 192.607C150.929 192.607 149.994 191.318 149.975 188.988C149.975 186.626 150.994 185.327 152.423 185.327C153.908 185.327 154.753 186.648 154.753 188.891ZM150.929 189.001C150.929 190.832 151.493 191.872 152.36 191.872C153.336 191.872 153.801 190.734 153.801 188.936C153.801 187.203 153.361 186.065 152.371 186.065C151.535 186.063 150.929 187.082 150.929 188.999V189.001Z"
        fill="#1D1D1D"
      />
      <path
        d="M157.182 185.327C158.157 185.327 158.808 186.085 158.808 187.429C158.808 188.912 158.028 189.649 157.118 189.649C156.237 189.649 155.481 188.934 155.467 187.525C155.453 186.117 156.237 185.325 157.167 185.325L157.182 185.327ZM157.129 185.901C156.543 185.901 156.23 186.604 156.23 187.494C156.208 188.382 156.543 189.075 157.14 189.075C157.768 189.075 158.05 188.404 158.05 187.472C158.05 186.617 157.8 185.901 157.14 185.901H157.129ZM157.053 192.614L161.191 185.313H161.797L157.648 192.614H157.053ZM161.775 188.237C162.751 188.237 163.39 188.996 163.39 190.339C163.39 191.824 162.611 192.56 161.711 192.56C160.83 192.56 160.075 191.857 160.06 190.447C160.045 189.037 160.83 188.237 161.761 188.237H161.775ZM161.722 188.812C161.137 188.812 160.811 189.516 160.811 190.404C160.811 191.292 161.148 191.986 161.733 191.986C162.361 191.986 162.643 191.315 162.643 190.382C162.643 189.527 162.393 188.812 161.733 188.812H161.722Z"
        fill="#1D1D1D"
      />
      <path
        d="M198.925 142.663C198.925 142.663 199.229 144.644 198.836 146.184C198.443 147.725 203.083 152.293 207.95 154.122C207.95 154.122 209.747 154.416 209.323 153.242C208.898 152.068 206.578 151.249 205.991 149.877C205.403 148.505 211.663 150.237 211.663 150.237L214.785 151.575C214.785 151.575 214.706 149.567 213.585 148.342C211.819 146.411 208.735 144.586 207.192 143.736C205.904 143.026 205.629 141.277 205.629 141.277C203.48 142.052 201.232 142.519 198.953 142.663H198.925Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
      />
      <path
        d="M205.729 150.056C205.729 150.056 204.472 148.259 205.043 147.915C205.614 147.572 210.445 149.631 210.445 149.631C210.445 149.631 205.926 148.732 205.729 150.056Z"
        fill="#1D1D1D"
      />
      <path
        d="M177.991 125.931H128.934C127.596 125.931 126.512 127.015 126.512 128.353V155.457C126.512 156.795 127.596 157.879 128.934 157.879H177.991C179.328 157.879 180.413 156.795 180.413 155.457V128.353C180.413 127.015 179.328 125.931 177.991 125.931Z"
        fill="#1D1D1D"
      />
      <path
        d="M153.462 144.698C154.775 144.698 155.839 143.634 155.839 142.321C155.839 141.008 154.775 139.944 153.462 139.944C152.149 139.944 151.085 141.008 151.085 142.321C151.085 143.634 152.149 144.698 153.462 144.698Z"
        fill="white"
      />
      <path
        d="M99.1696 152.832C102.845 147.319 104.461 141.146 105.023 133.795C105.584 126.445 99.6307 125.686 99.6307 125.686C99.6307 125.686 99.9245 118.776 99.4348 119.144C98.9451 119.511 93.3343 119.487 92.5024 118.776C91.6706 118.065 91.3746 118.776 91.3746 118.776V125.929C89.3939 125.781 87.4792 126.149 85.2741 135.851C83.0689 145.553 88.9493 151.696 88.9493 151.696"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M167.477 110.079L168.971 118.874C168.971 118.874 179.592 118.018 181.215 117.784C182.838 117.549 173.601 116.728 171.396 113.778C169.412 111.123 167.035 107.188 167.477 110.079Z"
        fill="#1D1D1D"
      />
      <path
        d="M196.354 136.907C196.354 136.907 198.363 136.662 199.499 136.416C200.269 136.25 197.857 140.459 198.029 142.223L196.354 136.907Z"
        fill="#1D1D1D"
      />
      <path
        d="M205.473 134.816C205.473 134.816 204.501 135.666 202.565 135.96"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M185.606 112.995C185.606 112.995 186.439 114.905 186.439 116.767"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M105.023 133.428C105.023 133.428 106.667 136.809 105.564 142.836C104.461 148.863 109.827 151.694 109.827 151.694L99.1697 152.832C99.1697 152.832 104.707 144.526 105.023 133.428Z"
        fill="#1D1D1D"
      />
      <path
        d="M92.9116 118.976C92.9116 118.976 93.1427 107.604 95.1509 106.625C97.1591 105.645 101.957 107.516 103.087 108.736C104.217 109.957 103.922 112.014 100.737 113.191C100.737 113.191 100.482 118.746 97.7324 119.315C94.4984 119.985 92.9116 118.976 92.9116 118.976Z"
        fill="white"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
      />
      <path
        d="M100.476 112.995C100.824 111.032 100.476 109.793 100.476 109.793C100.476 109.793 101.509 110.201 101.852 110.091C102.196 109.981 101.068 110.654 101.082 111.034C101.096 111.414 100.89 113.133 100.89 113.133L100.476 112.995Z"
        fill="#1D1D1D"
      />
      <path
        d="M91.3777 125.931C91.3777 125.931 94.155 126.404 95.021 126.143"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M99.6285 125.994C99.6285 125.994 97.8316 126.175 97.3089 125.994C96.7863 125.812 99.721 121.434 99.721 121.434L99.6285 125.994Z"
        fill="#1D1D1D"
      />
      <path
        d="M128.71 93.8625C128.71 93.8625 135.474 117.329 103.688 115.418"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M104.453 100.851C104.772 100.622 104.82 100.142 104.56 99.7793C104.299 99.4162 103.83 99.3072 103.511 99.5358C103.192 99.7645 103.144 100.244 103.404 100.607C103.664 100.97 104.134 101.079 104.453 100.851Z"
        fill="#1D1D1D"
      />
      <path
        d="M113.886 95.6076C114.206 95.379 114.253 94.8993 113.993 94.5362C113.733 94.1731 113.263 94.0641 112.944 94.2927C112.625 94.5214 112.577 95.0011 112.837 95.3642C113.098 95.7273 113.567 95.8363 113.886 95.6076Z"
        fill="#1D1D1D"
      />
      <path
        d="M104.287 93.2023C104.287 93.2023 104.522 95.8432 101.386 97.4377"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M114.141 92.3947C114.141 92.3947 112.161 94.1552 109.011 92.5949"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M108.055 97.902C108.055 97.902 108.9 99.4602 108.86 100.184C108.765 101.862 109.394 103.155 111.518 101.124"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M114.744 102.66C114.744 102.66 114.5 105 112.084 104.383"
        stroke="#1D1D1D"
        strokeWidth="0.660226"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
