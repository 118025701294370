import CheckboxTheme, { CheckboxTokens } from './CheckboxTheme';
import { ComponentTheme } from './utils';

const baseStyle = CheckboxTheme.baseStyle as any;

const RadioTheme: ComponentTheme = {
  defaultProps: CheckboxTheme.defaultProps,
  baseStyle: (props) => ({
    Control: {
      ...baseStyle(props).Control,
      borderRadius: 'full',
      _checked: {
        ...baseStyle(props).Control['_checked'],
        _before: {
          content: `""`,
          display: 'inline-block',
          position: 'relative',
          width: '50%',
          height: '50%',
          borderRadius: '50%',
          bg: 'currentColor',
        },
      },
    },
    Label: baseStyle(props).Label,
  }),
  sizes: {
    ...CheckboxTheme.sizes,
    sm: {
      Control: { width: 3, height: 3 },
    },
  },
};

export const RadioTokens = CheckboxTokens;

export default RadioTheme;
