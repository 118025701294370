import { SVGProps } from 'react';
import { XAxisProps } from 'recharts';

import { colors } from '@flick-tech/theme-new';

import { tickTextStyle } from '../const';
import { getTickTextWidth } from '../utils';

type TickFormatter = XAxisProps['tickFormatter'];

export interface DateTickProps extends SVGProps<SVGTextElement> {
  index: number;
  tickFormatter: NonNullable<TickFormatter> | SkipTicks;
  payload: {
    value: Date | string;
  };
  visibleTicksCount: number;
}

export function DateTick({
  index,
  tickFormatter,
  payload,
  visibleTicksCount: _,
  className,
  ...svgProps
}: DateTickProps): JSX.Element {
  const { value } = payload;
  const formatted = tickFormatter(value, index);

  if (formatted === TickDisplay.Skip) {
    return null;
  } else if (
    formatted === TickDisplay.Show ||
    formatted === TickDisplay.ShowDayname
  ) {
    let bottom: string;
    let top: string;

    const date = new Date(value);

    if (formatted === TickDisplay.ShowDayname) {
      [top, bottom] = date
        .toLocaleDateString('en-US', {
          day: '2-digit',
          weekday: 'short',
        })
        .split(' ');
    } else {
      [bottom, top] = date
        .toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
        })
        .split(' ');
    }

    const topWidth = getTickTextWidth(top);
    const bottomWidth = getTickTextWidth(bottom);

    return (
      <text
        className={`recharts-cartesian-axis-tick-value ${className}`}
        style={tickTextStyle}
        {...svgProps}
      >
        <tspan dy="0.71em" fill={'#000000'}>
          {top}
        </tspan>
        <tspan
          dy="1.25em"
          dx={`${-bottomWidth + (bottomWidth - topWidth + 0.5) / 2}px`}
          fill={colors.text.light}
        >
          {bottom}
        </tspan>
      </text>
    );
  }

  return (
    <text
      className={`recharts-cartesian-axis-tick-value ${className}`}
      style={tickTextStyle}
      {...svgProps}
    >
      <tspan dy="0.71em" fill={colors.text.dark}>
        {formatted}
      </tspan>
    </text>
  );
}

enum TickDisplay {
  // Recharts measures the width of text, so we need equal length values
  Show = 'SHOW',
  Skip = 'SKIP',
  ShowDayname = 'SDAY',
}

type SkipTicks = (...args: Parameters<TickFormatter>) => TickDisplay | string;

/**
 * @example
 * <XAxis
 *   dataKey={indexBy}
 *   tick={DateTick}
 *   tickFormatter={DateTick.skipTicks(dataLength)}
 * />
 */
DateTick.skipTicks =
  (valuesCount: number): SkipTicks =>
  (value, index) => {
    const date = Date.parse(value);

    if (
      // if value is a date already
      value instanceof Date ||
      // or if value is isostring
      (typeof value === 'string' && value.length > 20 && isFinite(date))
    ) {
      if (valuesCount >= 90) {
        return TickDisplay.Show;
      } else if (valuesCount >= 30) {
        return index % 2 !== 0 ? TickDisplay.Skip : TickDisplay.Show;
      } else if (valuesCount <= 14) {
        return TickDisplay.ShowDayname;
      } else {
        return TickDisplay.Show;
      }
    }

    return value;
  };
