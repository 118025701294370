export const createStorage = (provider) => ({
  get(key, defaultValue) {
    const data = provider.getItem(key);
    let value;

    if (data === 'false') {
      value = false;
    } else {
      try {
        value = JSON.parse(data);
      } catch (error) {}
    }

    if (value === undefined || value === null) {
      if (typeof defaultValue === 'function') {
        value = defaultValue();
      } else {
        value = defaultValue;
      }
    }

    return value;
  },
  set(key, value) {
    provider.setItem(key, JSON.stringify(value));
  },
});
