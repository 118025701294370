import { useCallback } from 'react';

import { Logger } from '@flick-tech/logger';
import { useBoolean } from '@flick-tech/shared-hooks';

import { useToast } from './toast';

interface UseMutationHook<Payload = unknown> {
  func: (payload: Payload) => Promise<unknown>;
  skipErrorToast?: boolean;
  successToastDescription?: string;
  errorToastDescription?: string;
}

export function useMutationHook<Payload = unknown>({
  func,
  skipErrorToast,
  successToastDescription,
  errorToastDescription,
}: UseMutationHook<Payload>) {
  const [isLoading, { on, off }] = useBoolean();
  const toast = useToast();

  const handler = useCallback(
    async (payload?: Payload): Promise<boolean> => {
      on();

      let isSuccess = false;

      try {
        await func(payload);
        if (successToastDescription) {
          toast({
            title: 'Success!',
            status: 'success',
            description: successToastDescription,
          });
        }
        isSuccess = true;
      } catch (error) {
        Logger.error(error);

        if (!skipErrorToast) {
          toast({
            title: 'Error',
            status: 'error',
            description: errorToastDescription ?? error.message,
          });
        }
      }

      off();

      return isSuccess;
    },
    [
      func,
      off,
      on,
      skipErrorToast,
      toast,
      successToastDescription,
      errorToastDescription,
    ],
  );

  return {
    isLoading,
    handler,
  };
}
