import { Property } from 'csstype';

import { ThemedProperty } from '../types';
import { Config, Length, Prop } from '../utils';

/**
 * The parser configuration for common border properties
 */
export const borderConfig: Config = {
  border: {
    property: 'border',
    scale: 'borders',
  },
  borderWidth: {
    property: 'borderWidth',
    scale: 'borderWidths',
  },
  borderStyle: {
    property: 'borderStyle',
    scale: 'borderStyles',
  },
  borderColor: {
    property: 'borderColor',
    scale: 'colors',
  },
  borderRadius: {
    property: 'borderRadius',
    scale: 'radii',
  },
  borderTop: {
    property: 'borderTop',
    scale: 'borders',
  },
  borderTopLeftRadius: {
    property: 'borderTopLeftRadius',
    scale: 'radii',
  },
  borderTopRightRadius: {
    property: 'borderTopRightRadius',
    scale: 'radii',
  },
  borderRight: {
    property: 'borderRight',
    scale: 'borders',
  },
  borderBottom: {
    property: 'borderBottom',
    scale: 'borders',
  },
  borderBottomLeftRadius: {
    property: 'borderBottomLeftRadius',
    scale: 'radii',
  },
  borderBottomRightRadius: {
    property: 'borderBottomRightRadius',
    scale: 'radii',
  },
  borderLeft: {
    property: 'borderLeft',
    scale: 'borders',
  },
  borderX: {
    properties: ['borderLeft', 'borderRight'],
    scale: 'borders',
  },
  borderY: {
    properties: ['borderTop', 'borderBottom'],
    scale: 'borders',
  },
  borderTopWidth: {
    property: 'borderTopWidth',
    scale: 'borderWidths',
  },
  borderTopColor: {
    property: 'borderTopColor',
    scale: 'colors',
  },
  borderTopStyle: {
    property: 'borderTopStyle',
    scale: 'borderStyles',
  },
  borderBottomWidth: {
    property: 'borderBottomWidth',
    scale: 'borderWidths',
  },
  borderBottomColor: {
    property: 'borderBottomColor',
    scale: 'colors',
  },
  borderBottomStyle: {
    property: 'borderBottomStyle',
    scale: 'borderStyles',
  },
  borderLeftWidth: {
    property: 'borderLeftWidth',
    scale: 'borderWidths',
  },
  borderLeftColor: {
    property: 'borderLeftColor',
    scale: 'colors',
  },
  borderLeftStyle: {
    property: 'borderLeftStyle',
    scale: 'borderStyles',
  },
  borderRightWidth: {
    property: 'borderRightWidth',
    scale: 'borderWidths',
  },
  borderRightColor: {
    property: 'borderRightColor',
    scale: 'colors',
  },
  borderRightStyle: {
    property: 'borderRightStyle',
    scale: 'borderStyles',
  },
  borderTopRadius: {
    properties: ['borderTopLeftRadius', 'borderTopRightRadius'],
    scale: 'radii',
  },
  borderBottomRadius: {
    properties: ['borderBottomLeftRadius', 'borderBottomRightRadius'],
    scale: 'radii',
  },
  borderLeftRadius: {
    properties: ['borderTopLeftRadius', 'borderBottomLeftRadius'],
    scale: 'radii',
  },
  borderRightRadius: {
    properties: ['borderTopRightRadius', 'borderBottomRightRadius'],
    scale: 'radii',
  },
};

/**
 * The prop types for border properties listed above
 */
export interface BorderProps {
  /**
   * The CSS `border` property
   */
  border?: Prop<Property.Border<Length>>;
  /**
   * The CSS `border-width` property
   */
  borderWidth?: Prop<Property.BorderWidth<Length>>;
  /**
   * The CSS `border-style` property
   */
  borderStyle?: Prop<Property.BorderStyle>;
  /**
   * The CSS `border-color` property
   */
  borderColor?: Prop<ThemedProperty.Color>;
  /**
   * The CSS `border-radius` property
   */
  borderRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-top` property
   */
  borderTop?: Prop<Property.BorderTop<Length>>;
  /**
   * The CSS `border-top-width` property
   */
  borderTopWidth?: Prop<Property.BorderWidth<Length>>;
  /**
   * The CSS `border-bottom-width` property
   */
  borderBottomWidth?: Prop<Property.BorderWidth<Length>>;
  /**
   * The CSS `border-left-width` property
   */
  borderLeftWidth?: Prop<Property.BorderWidth<Length>>;
  /**
   * The CSS `border-right-width` property
   */
  borderRightWidth?: Prop<Property.BorderWidth<Length>>;
  /**
   * The CSS `border-top-style` property
   */
  borderTopStyle?: Prop<Property.BorderTopStyle>;
  /**
   * The CSS `border-bottom-style` property
   */
  borderBottomStyle?: Prop<Property.BorderBottomStyle>;
  /**
   * The CSS `border-left-style` property
   */
  borderLeftStyle?: Prop<Property.BorderLeftStyle>;
  /**
   * The CSS `border-right-styles` property
   */
  borderRightStyle?: Prop<Property.BorderRightStyle>;
  /**
   * The CSS `border-top-color` property
   */
  borderTopColor?: Prop<ThemedProperty.Color>;
  /**
   * The CSS `border-bottom-color` property
   */
  borderBottomColor?: Prop<ThemedProperty.Color>;
  /**
   * The CSS `border-left-color` property
   */
  borderLeftColor?: Prop<ThemedProperty.Color>;
  /**
   * The CSS `border-right-color` property
   */
  borderRightColor?: Prop<ThemedProperty.Color>;
  /**
   * The CSS `border-right` property
   */
  borderRight?: Prop<Property.BorderRight<Length>>;
  /**
   * The CSS `border-bottom` property
   */
  borderBottom?: Prop<Property.BorderBottom<Length>>;
  /**
   * The CSS `border-left` property
   */
  borderLeft?: Prop<Property.BorderLeft<Length>>;
  /**
   * The CSS `border-top-radius` property
   */
  borderTopRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-right-radius` property
   */
  borderRightRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-bottom-radius` property
   */
  borderBottomRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-left-radius` property
   */
  borderLeftRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-top-left-radius` property
   */
  borderTopLeftRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-top-right-radius` property
   */
  borderTopRightRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-bottom-left-radius` property
   */
  borderBottomLeftRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-bottom-right-radius` property
   */
  borderBottomRightRadius?: Prop<Property.BorderRadius<Length>>;
  /**
   * The CSS `border-right` and `border-left` property
   */
  borderX?: Prop<Property.Border<Length>>;
  /**
   * The CSS `border-top` and `border-bottom` property
   */
  borderY?: Prop<Property.Border<Length>>;
}
