import { useCallback } from 'react';

import { Box, BoxProps } from '../../layout';
import { NumberInput, NumberInputField } from '../../number-input';

interface NumericInputsProps extends Omit<BoxProps, 'onChange'> {
  values: [number?, number?];
  onChange: (values: [number, number]) => void;
  min?: number;
  max?: number;
}

function NumericInputs({
  values: [leftValue, rightValue] = [],
  onChange,
  min = 0,
  max = Infinity,
  ...rest
}: NumericInputsProps) {
  const handleOnChangeLeft = useCallback(
    (value) => onChange([Number(value), rightValue]),
    [onChange, rightValue],
  );

  const handleOnChangeRight = useCallback(
    (value) => onChange([leftValue, Number(value)]),
    [leftValue, onChange],
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <NumberInput
        min={min}
        max={Number.isFinite(rightValue) ? Math.min(max, rightValue) : max}
        ml={-1}
        onChange={handleOnChangeLeft}
        value={leftValue === min ? min || 0 : leftValue}
        data-intercom-id="numeric-inputs--min-input"
      >
        <NumberInputField textAlign="center" />
      </NumberInput>
      <Box mx={3}>to</Box>
      <NumberInput
        min={Number.isFinite(leftValue) ? Math.max(min, leftValue) : min}
        max={max}
        mr={-1}
        onChange={handleOnChangeRight}
        value={rightValue === max ? 'any' : rightValue}
        data-intercom-id="numeric-inputs--max-input"
      >
        <NumberInputField textAlign="center" />
      </NumberInput>
    </Box>
  );
}

export default NumericInputs;
