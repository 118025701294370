import { memo } from 'react';

import { useDisclosure } from '@flick-tech/shared-hooks';
import { Avatar } from '@flick-tech/ui';
import { Icon } from '@flick-tech/ui';
import { Divider, Flex, Spacer, Text } from '@flick-tech/ui';
import { FlickPopover } from '@flick-tech/ui';

import { ProfileNavigation, ProfileNavigationProps } from './ProfileNavigation';

const iconSize = {
  base: 5,
  xl: 6,
};

export interface ProfileNavigationItemProps
  extends Pick<ProfileNavigationProps, 'user'> {
  isLarge?: boolean;
  isLoading?: boolean;
}

export const ProfileNavigationItem = memo(function ProfileNavigationItem({
  user,
  isLarge,
  isLoading,
}: ProfileNavigationItemProps) {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const onOpenFeedback = () => {
    window.open('https://feedback.flick.tech/', '_blank');
  };

  const hoverStyle = isLoading ? {} : { backgroundColor: 'bg.base' };

  return (
    <>
      {isLarge && <Divider sx={{ '&&': { mt: 3, mb: 0 } }} />}
      <FlickPopover
        trigger="click"
        position="right-end"
        onHidden={onClose}
        enabled={Boolean(user)}
        content={
          user ? (
            <ProfileNavigation
              onRequestFeature={onOpenFeedback}
              onNavigate={onClose}
              user={user}
            />
          ) : (
            <></>
          )
        }
        visible={isOpen}
        wrapperProps={{ sx: { tabIndex: undefined } }}
        animation="fade"
        duration={0.05}
        interactive
        noPadding
      >
        <Flex
          as="button"
          role="group"
          onClick={isLoading ? undefined : onToggle}
          cursor={isLoading ? 'not-allowed' : 'pointer'}
          direction="row"
          align="center"
          {...(isLarge && {
            p: 2,
            mt: -2,
            mr: -2,
            mb: -2,
            borderRadius: '8px',
            w: '100%',
            sx: isOpen
              ? hoverStyle
              : { '&:hover, &:focus-visible': hoverStyle },
          })}
        >
          <Avatar
            size="sm"
            bg="bg.light"
            color="blackAlpha.600"
            name={user?.name}
            _hover={{
              cursor: 'pointer',
            }}
          />
          {isLarge && (
            <>
              <Text
                ml={3}
                color="text.light"
                fontSize={{
                  base: '16px',
                  xl: '18px',
                }}
                lineHeight={1.3}
                fontWeight="medium"
                whiteSpace="pre"
              >
                Settings
              </Text>
              <Spacer />
              <Icon
                icon="SettingsRounded"
                h={iconSize}
                w={iconSize}
                color={isOpen ? 'text.light' : 'text.lighter'}
                flexShrink={0}
                _groupHover={{ color: 'text.light' }}
              />
            </>
          )}
        </Flex>
      </FlickPopover>
    </>
  );
});
