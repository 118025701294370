import { Button, ButtonProps } from '../../button';
import { Icon, IconName } from '../../icon';
import { Flex } from '../../layout';

import { ShortcutKeys } from './ShortcutKeys';

interface ActionButtonProps extends Omit<ButtonProps, 'leftIcon'> {
  topIcon?: IconName;
  leftIcon?: IconName;
  shortcutKey: string;
}

export const ActionButton = ({
  children,
  topIcon,
  leftIcon,
  shortcutKey,
  ...rest
}: ActionButtonProps) => (
  <Button
    flex={1}
    bg="whiteAlpha.200"
    borderRadius="6px"
    height="auto"
    fontWeight="semibold"
    leftIcon={leftIcon && <Icon boxSize="1.5rem" icon={leftIcon} />}
    px={2}
    py={2}
    _hover={{
      bg: 'whiteAlpha.300',
    }}
    _focus={{
      bg: 'whiteAlpha.300',
    }}
    {...rest}
  >
    <ShortcutKeys position="absolute" top="6px" right="6px">
      {shortcutKey}
    </ShortcutKeys>
    <Flex
      wrap="wrap"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      whiteSpace="normal"
    >
      {topIcon && <Icon mb={2} boxSize="1.5rem" icon={topIcon} />}
      {children}
    </Flex>
  </Button>
);
