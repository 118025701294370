import { SystemProps } from '@flick-tech/theme-new';
import { Box, BoxProps } from '../layout';

import { Spinner } from './Spinner';

const absoluteStyles: SystemProps = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const overlayStyles: SystemProps = {
  ...absoluteStyles,
  zIndex: 999,
  bg: 'rgba(255,255,255,0.66)',
};

const fullPageStyles: SystemProps = {
  minHeight: '100vh',
};

const sizes = {
  xs: 20,
  sm: 30,
  md: 40,
  lg: 50,
};

interface ContentLoaderProps extends Omit<BoxProps, 'color' | 'size'> {
  color?: string;
  size?: keyof typeof sizes;
  fullPage?: boolean;
  absoluteStretch?: boolean;
  withCircleBg?: boolean;
  withOverlay?: boolean;
}

export function ContentLoader({
  color,
  size,
  fullPage,
  absoluteStretch,
  withCircleBg,
  withOverlay,
  ...rest
}: ContentLoaderProps) {
  const sizeInPx = sizes[size] ?? sizes.md;

  return (
    <Box
      minHeight="auto"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={fullPage ? 'full-page-loader' : ''}
      aria-busy={true}
      {...(absoluteStretch && absoluteStyles)}
      {...(withOverlay && overlayStyles)}
      {...(fullPage && fullPageStyles)}
      {...rest}
    >
      {withCircleBg && (
        <Box
          borderRadius="full"
          position="absolute"
          w={sizeInPx + 12}
          h={sizeInPx + 12}
          bg="white"
        />
      )}
      <Spinner color={color} w={`${sizeInPx}px`} h={`${sizeInPx}px`} />
    </Box>
  );
}

export default ContentLoader;
