import { isRunningOnServerSide } from '@flick-tech/shared-common';

import { CSSObject } from '../../css/css.types';
import { mode, Styles } from '../components/utils';

const getScrollbarStyles = (): CSSObject => {
  if (isRunningOnServerSide()) return {};

  return navigator.platform.includes('Mac')
    ? {}
    : {
        html: {
          scrollBehavior: 'smooth',
          '--scrollbar-color': '0, 0, 0',
        },
        '*': {
          '::-webkit-scrollbar': {
            width: '13px',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(var(--scrollbar-color), 0.16)',
            backgroundClip: 'padding-box',
            border: '3px solid rgba(var(--scrollbar-color), 0)',
            borderRadius: '100px',
            '&:hover': {
              borderWidth: '2px',
              backgroundColor: 'rgba(var(--scrollbar-color), 0.32)',
            },
          },
          '::-webkit-scrollbar-corner': {
            backgroundColor: 'rgba(var(--scrollbar-color), 0)',
          },
        },
      };
};

const styles: Styles = {
  global: {
    bodyProps: (props) => ({
      color: mode('text.base', 'whiteAlpha.900')(props),
      bg: mode('white', 'gray.800')(props),
      '*::placeholder': {
        color: mode('gray.500', 'whiteAlpha.400')(props),
      },
      '*, *::before, &::after': {
        borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      },
    }),
    css: getScrollbarStyles(),
  },
};

export default styles;
