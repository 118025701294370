export const AVAILABLE_SOCIALS = ['igAccount', 'facebook'] as const;

interface SocialGroupLike {
  igAccount?: unknown;
  facebook?: unknown;
}

export function getNumberOfLinkedSocials<T extends SocialGroupLike>(
  socialGroup: T,
) {
  return AVAILABLE_SOCIALS.reduce((sum, social) => {
    if (socialGroup[social]) return sum + 1;
    return sum;
  }, 0);
}

export function getLinkedSocials<T extends SocialGroupLike>(socialGroup: T) {
  return AVAILABLE_SOCIALS.filter((social) => Boolean(socialGroup[social]));
}
