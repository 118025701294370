import { Constants } from '@flick-tech/shared-common';

import { createBlitzClient } from '../blitz-client';

export const {
  invalidateQuery,
  useQuery: useScheduleQuery,
  useMutation: useScheduleMutation,
} = createBlitzClient({
  baseUrl: `${Constants.scheduleBase}/api/`,
});
