import { Constants, FlickAppType } from '@flick-tech/shared-common';
import { usePathname } from '@flick-tech/shared-hooks';
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from '@flick-tech/ui';
import { Icon, IconName } from '@flick-tech/ui';
import { BoxProps } from '@flick-tech/ui';
import { FlickPopover } from '@flick-tech/ui';

import { CrossAppNavLink } from './CrossAppNavLink';

const longButtonStyles: ButtonProps = {
  variant: 'ghost',
  colorScheme: 'gray',
  py: '8px',
  px: '8px',
  justifyContent: 'start',
  fontSize: {
    base: '16px',
    xl: '18px',
  },
  lineHeight: 1.3,
  fontWeight: 'medium',
  iconSpacing: '16px',
  color: 'text.light',
  _active: {
    bg: 'brand.50',
  },
  _hover: {
    bg: 'bg.light',
  },
  _activeLink: {
    bg: 'brand.50',
    color: 'brand.500',
  },
};

const iconButtonStyles: ButtonProps = {
  w: 10,
  h: 10,
  variant: 'ghost',
  colorScheme: 'brand',
  color: 'blackAlpha.500',
  _hover: {
    bg: 'brand.50',
    color: 'brand.500',
  },
  _active: {
    bg: 'brand.100',
    color: 'brand.500',
  },
  _activeLink: {
    bg: 'brand.100',
    color: 'brand.500',
  },
};

// TODO: Re-write this & add tests
const isNavigationRouteActive = (
  route: string | undefined,
  currentPathname: string,
) => {
  if (route.includes('schedule') && Constants.isSchedule) {
    return true;
  }

  return route?.split('/')?.[1] === currentPathname.split('/')?.[1];
};

export interface NavigationItemProps extends Omit<BoxProps, 'onClick'> {
  name: string;
  shortName?: string;
  icon: IconName;
  variant: 'icon' | 'button';
  route?: string;
  app: FlickAppType;
  isLoading?: boolean;
}

export function NavigationItem({
  name,
  shortName,
  route,
  icon,
  variant,
  app,
  isLoading,
}: NavigationItemProps) {
  const pathname = usePathname();
  const isActive = isNavigationRouteActive(route, pathname);

  const isIconButton = variant === 'icon';

  const buttonProps: ButtonProps & {
    href?: string;
    'data-intercom-id': string;
  } = {
    'aria-label': name,
    'data-intercom-id': `navigation-${name.toLowerCase()}`,
    as: 'a',
    textDecoration: 'none',
    w: '100%',
  };

  return isIconButton ? (
    <FlickPopover
      title={name}
      key={name}
      position="right"
      boundary="window"
      theme="dark"
      arrow={false}
    >
      <CrossAppNavLink app={app} route={route} isDisabled={isLoading}>
        <IconButton
          {...iconButtonStyles}
          {...(buttonProps as IconButtonProps)}
          fontSize="x1"
          borderRadius="12px"
          icon={<Icon icon={icon} fontSize="24px" />}
          aria-current={isActive ? 'page' : false}
        />
      </CrossAppNavLink>
    </FlickPopover>
  ) : (
    <CrossAppNavLink app={app} route={route} isDisabled={isLoading}>
      <Button
        {...longButtonStyles}
        {...(buttonProps as ButtonProps)}
        leftIcon={
          <Icon
            icon={icon}
            fontSize="24px"
            marginLeft="2px"
            marginRight="-4px"
          />
        }
        aria-current={isActive ? 'page' : false}
      >
        {shortName || name}
      </Button>
    </CrossAppNavLink>
  );
}
