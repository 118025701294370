import { Globals, Property } from 'csstype';

import { Config, Length, Prop } from '../utils';

export const flexboxConfig: Config = {
  alignItems: true,
  alignContent: true,
  justifyItems: true,
  justifyContent: true,
  flexWrap: true,
  flexDirection: true,
  flex: true,
  flexGrow: true,
  flexShrink: true,
  flexBasis: {
    property: 'flexBasis',
    scale: 'sizes',
  },
  justifySelf: true,
  alignSelf: true,
  order: true,
  flexDir: {
    property: 'flexDirection',
  },
};

/**
 * Types for flexbox related CSS properties
 */
export interface FlexboxProps {
  /**
   * The CSS `align-items` property
   */
  alignItems?: Prop<Property.AlignItems>;
  /**
   * The CSS `align-content` property
   */
  alignContent?: Prop<Property.AlignContent>;
  /**
   * The CSS `justify-items` property
   */
  justifyItems?: Prop<Property.JustifyItems>;
  /**
   * The CSS `justify-content` property
   */
  justifyContent?: Prop<Property.JustifyContent>;
  /**
   * The CSS `flex-wrap` property
   */
  flexWrap?: Prop<Property.FlexWrap>;
  /**
   * The CSS `flex-basis` property
   */
  flexBasis?: Prop<Property.FlexBasis<Length>>;
  /**
   * The CSS `flex-direction` property
   */
  flexDirection?: Prop<Property.FlexDirection>;
  /**
   * The CSS `flex-direction` property
   */
  flexDir?: Prop<Property.FlexDirection>;
  /**
   * The CSS `flex` property
   */
  flex?: Prop<Property.Flex<Length>>;
  /**
   * The CSS `justify-self` property
   */
  justifySelf?: Prop<Property.JustifySelf>;
  /**
   * The CSS `align-self` property
   */
  alignSelf?: Prop<Property.AlignSelf>;
  /**
   * The CSS `order` property
   */
  order?: Prop<Property.Order>;
  /**
   * The CSS `flex-grow` property
   */
  flexGrow?: Prop<Property.FlexGrow>;
  /**
   * The CSS `flex-shrink` property
   */
  flexShrink?: Prop<Property.FlexShrink>;
}
