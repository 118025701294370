import { get } from '@flick-tech/shared-utils';

import { ModifierStyleProps } from './types';

type DefaultModifiers = Omit<ModifierStyleProps, 'theme'>;

/**
 * Get the theming default props of a component from the theme
 */
export function getComponentDefaults(theme: any, themeKey: string) {
  return get(theme, `components.${themeKey}.defaultProps`) as
    | DefaultModifiers
    | undefined;
}
