import { PropsOf } from '@flick-tech/shared-types';
import { chakra } from '@flick-tech/theme-new';

const Wrapper = chakra.tr;

export type TableRowProps = PropsOf<typeof Wrapper>;

export function TableRow({ ...rest }: TableRowProps) {
  return <Wrapper {...rest} />;
}
