import {
  CSSObject as EmotionCSSObject,
  Global as EmotionGlobal,
} from '@emotion/react';

import { runIfFn } from '@flick-tech/shared-utils';

import { ColorMode, useColorMode } from '../color-mode';
import { css, CSSObject } from '../css';
import { Styles } from '../preset-base/components/utils';

import { generalGlobalStyles } from './GlobalStyles';

export interface GlobalProps {
  styles: (props: { theme: object; colorMode: ColorMode }) => CSSObject;
}

export function Global(props: GlobalProps) {
  const { styles } = props;
  const [colorMode] = useColorMode();
  return (
    <EmotionGlobal
      styles={(theme) => styles({ theme, colorMode }) as EmotionCSSObject}
    />
  );
}

export function GlobalStyle() {
  return (
    <>
      <EmotionGlobal styles={generalGlobalStyles} />
      <Global
        styles={(props): CSSObject => {
          const { theme = {}, colorMode } = props;
          const styles = (theme as { styles: Styles })?.styles;

          if (!styles) return {};

          const { global, ...rest } = styles;

          const body = runIfFn(global?.bodyProps, { colorMode });

          const chakraStyles = css({
            body,
            ...rest,
          })(theme);

          return {
            ...chakraStyles,
            ...global?.css,
          } as any;
        }}
      />
    </>
  );
}
