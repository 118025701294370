import { Text, TextProps } from '../../layout';

export const LearnStylesheet = {
  body: {
    color: '#1D1D1D',
  },
};

export const RteH1 = (props: TextProps) => (
  <Text
    fontWeight={700}
    mb={4}
    mt={8}
    fontSize={{
      base: '2xl',
      sm: '4xl',
    }}
    as="h1"
    _firstMe={{
      mt: 0,
    }}
    {...props}
  />
);

export const RteH2 = (props: TextProps) => (
  <RteH1
    fontSize={{
      base: 'xl',
      sm: '3xl',
    }}
    as="h2"
    {...props}
  />
);

export const RteBody = (props: TextProps) => (
  <Text
    fontWeight={400}
    fontSize={{
      base: 'lg',
      sm: 'xl',
    }}
    as="p"
    color={LearnStylesheet.body.color}
    mb={4}
    lineHeight={1.6}
    _first={{ mt: 0 }}
    _last={{ mb: 0 }}
    {...props}
  />
);
