import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { useMemo } from 'react';
import * as React from 'react';

import type { DeepMaybe } from '@flick-tech/shared-utils';

import { EmbeddedAssetInfo, renderRichText } from './RTETypography';

export interface InlineEntryHyperlinkInfo {
  __typename?: string;
  sys?: {
    id?: string;
  };
  slug?: string;
}

export interface UsePreprocessedRichTextOptions {
  makeLinkedEntryHref: (entry: DeepMaybe<InlineEntryHyperlinkInfo>) => {
    href: string;
    onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  };
}
export function usePreprocessedRichText(
  value:
    | undefined
    | null
    | {
        json?: Document | null;
        links?: DeepMaybe<{
          assets: {
            block: EmbeddedAssetInfo[];
          };
          entries: {
            hyperlink: InlineEntryHyperlinkInfo[];
          };
        }>;
      },
  { makeLinkedEntryHref: makeLinkedEntryProps }: UsePreprocessedRichTextOptions,
) {
  return useMemo((): React.ReactNode => {
    const { json, links } = value || {};

    if (json && links) {
      const linkedEntries = new Map(
        links.entries?.hyperlink?.map((entry) => {
          return [entry?.sys?.id, entry];
        }),
      );

      let i = 0;
      json.content.forEach((block) => {
        switch (block.nodeType) {
          case BLOCKS.EMBEDDED_ASSET:
            if (block.data.target?.fields === undefined) {
              block.data.asset = links?.assets?.block?.[i++];
            }
            break;
          case BLOCKS.PARAGRAPH:
            for (const node of block.content) {
              if (node.nodeType === INLINES.ENTRY_HYPERLINK) {
                const id = node.data.target?.sys?.id;
                const entry = linkedEntries.get(id);
                if (entry) {
                  node.data.props = makeLinkedEntryProps(entry);
                }
              }
            }
            break;
        }
      });
    }

    if (json) {
      return renderRichText(json);
    }
    return;
  }, [value]);
}
