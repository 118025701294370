import { AsProp, FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import {
  chakra,
  ChakraComponent,
  ChakraProps,
  PropsOf,
} from '@flick-tech/theme-new';

export const HeadingBase = chakra('h2', {
  baseStyle: {
    fontFamily: 'heading',
    lineHeight: 'shorter',
    fontWeight: 500,
  },
  // color is incorrect in `chakra` types
}) as FC<Omit<PropsOf<ChakraComponent<'h2', {}, ''>>, 'color'>>;

const headingSizeMap = {
  '2xl': { fontSize: { base: '3xl', md: '4xl' } },
  xl: { fontSize: { base: '2xl', md: '3xl' } },
  lg: { fontSize: { base: 'xl', md: '2xl' } },
  md: { fontSize: 'xl' },
  sm: { fontSize: 'md' },
  xs: { fontSize: 'sm' },
};

type HeadingSize = keyof typeof headingSizeMap;

export interface HeadingProps__deprecated
  extends Omit<PropsOf<'h1'>, 'color'>,
    AsProp,
    ChakraProps {
  size?: HeadingSize;
}

const getSizeStyles = (size: HeadingSize): HeadingProps__deprecated =>
  headingSizeMap[size];

export function Heading__deprecated({
  size = 'xl',
  ...props
}: HeadingProps__deprecated) {
  return <HeadingBase {...getSizeStyles(size)} {...props} />;
}

if (__DEV__) {
  Heading__deprecated.displayName = 'Heading';
}
