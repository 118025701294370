import Cookies from 'js-cookie';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

import { isObject } from '@flick-tech/shared-utils';

import { flickCookieOptions, StoreKey } from '../persistent-store';

import type { AuthTokens } from './auth.types';

const extractPrefix = (cookieString: string) => {
  // JSON cookies have a j: prefix when sent from cookie-parser https://github.com/expressjs/cookie-parser/blob/master/index.js#L84
  if (cookieString.startsWith('j:')) {
    return cookieString.replace('j:', '');
  }

  return cookieString;
};

export function getAccessTokensFromCookies(
  ...params: Parameters<typeof parseCookies>
): AuthTokens | undefined {
  const cookies = parseCookies(...params) ?? {};

  const tokenString = cookies[StoreKey.TOKEN];

  if (!tokenString) {
    return undefined;
  }

  try {
    const tokens = JSON.parse(extractPrefix(tokenString)) as AuthTokens;

    // Malformed
    if (!tokens?.accessToken) {
      return undefined;
    }

    return tokens;
    // eslint-disable-next-line no-empty
  } catch (_) {}

  return undefined;
}

export const setAccessTokenInCookies = (value: AuthTokens) => {
  // IMPORTANT TO NOT USE nookies -> speak to Andreas if interested. He's yet to document this
  // Server side issues in Blitz
  Cookies.set(
    StoreKey.TOKEN,
    (isObject(value) ? JSON.stringify(value) : value) as string,
    flickCookieOptions,
  );
};

export const setAccessTokenInCookiesServerSide = (
  res: Parameters<typeof setCookie>[0]['res'],
  token: AuthTokens,
) =>
  setCookie({ res }, StoreKey.TOKEN, JSON.stringify(token), flickCookieOptions);

export const clearAccessTokenInCookies = (
  res?: Parameters<typeof destroyCookie>[0]['res'],
) =>
  destroyCookie(res ? { res } : undefined, StoreKey.TOKEN, flickCookieOptions);
