import {
  MutateOptions,
  useMutation as useReactQueryMutation,
  UseMutationOptions,
  UseMutationResult,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from 'react-query';
import { serialize } from 'superjson';

import { useFlickAccessToken } from '../useGraphqlFetch';

import { CreateBlitzClientParams } from './createBlitzClient.types';
import { fetchBlitzAPI } from './fetchBlitzAPI';

/*
 * We have to override react-query's MutationFunction and MutationResultPair
 * types so because we have throwOnError:true by default. And by the RQ types
 * have the mutate function result typed as TData|undefined which isn't typed
 * properly with throwOnError.
 *
 * So this fixes that.
 */
export declare type MutateFunction<
  TData,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
> = (
  variables?: TVariables,
  config?: MutateOptions<TData, TError, TVariables, TContext>,
) => Promise<TData>;

export declare type MutationResultPair<TData, TError, TVariables, TContext> = [
  MutateFunction<TData, TError, TVariables, TContext>,
  Omit<UseMutationResult<TData, TError>, 'mutate' | 'mutateAsync'>,
];

export declare type MutationFunction<TData, TVariables = unknown> = (
  variables: TVariables,
  ctx?: any,
) => Promise<TData>;

export function createUseMutation({ baseUrl }: CreateBlitzClientParams) {
  return function useMutation<
    TData = unknown,
    TError = unknown,
    TVariables = any,
    TContext = unknown,
  >(
    path: string,
    config?: UseMutationOptions<TData, TError, TVariables, TContext>,
  ): MutationResultPair<TData, TError, TVariables, TContext> {
    const fullPath = `${baseUrl}${path}`;

    const { accessToken } = useFlickAccessToken();

    const { mutateAsync, ...rest } = useReactQueryMutation<
      TData,
      TError,
      TVariables,
      TContext
    >(
      (variables: TVariables) =>
        fetchBlitzAPI({
          path: fullPath,
          paramsSerialized: serialize(variables),
          accessToken,
        }),
      {
        throwOnError: false,
        ...config,
      } as any,
    );

    return [mutateAsync, rest] as MutationResultPair<
      TData,
      TError,
      TVariables,
      TContext
    >;
  };
}
