import _ from 'lodash';
import {
  IErrorObject,
  IErrorObjectStringifiable,
  ILogObject,
  Logger as TSLogger,
} from 'tslog';
import { TLogLevelName } from 'tslog/src/interfaces';

import { Constants } from '@flick-tech/shared-common';

import { shouldSkipErrorInSentry } from './sentry/sentry.utils';
import { getSentryUrlForEventId, Sentry } from './sentry';

const minLogLevel = process.env.MIN_LOG_LEVEL;

/*
  Allows us to do things like:

  Logger.info('My message', {
    username: 'andyasprou',
    somethingElseICareAbout: {
      code: 123,
    },
  });

  And have the fields indexed as top level fields in logs service
 */
const getMetaData = (
  argumentsArray: ILogObject['argumentsArray'],
): Record<string, any> => {
  if (argumentsArray?.length === 0) {
    return {};
  }

  const firstArg = argumentsArray[0];

  if (_.isObject(firstArg)) {
    return firstArg;
  }

  return {};
};

// https://docs.datadoghq.com/tracing/connect_logs_and_traces/nodejs/#manually-inject-trace-and-span-ids
function injectToDDogTracer(payload: Record<string, any>) {
  const record = {
    time: new Date().toISOString(),
    ...payload,
  };

  if (process.env.SHOULD_INJECT_DDOG_TRACER === 'true') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const tracer = require('dd-trace').tracer;

    const span = tracer.scope().active();

    if (span) {
      tracer.inject(span.context(), 'log', record);
    }
  }

  return record;
}

function persistLogsImpl(logObject: ILogObject) {
  const filePathWithoutDistPath = logObject.filePath?.split(':') ?? [];

  const payload: Partial<ILogObject> & {
    level: ILogObject['logLevel'];
    error?: Error;
    errorInfo?: IErrorObject;
    message?: string;
    appName: string;
    arguments: ILogObject['argumentsArray'];
    errorId?: string;
  } = {
    level: logObject.logLevel,
    hostname: logObject.hostname,
    functionName: logObject.functionName,
    typeName: logObject.typeName,
    instanceName: logObject.instanceName,
    appName: String(Constants.flickAppID),
    filePath:
      filePathWithoutDistPath.length >= 1
        ? filePathWithoutDistPath[1]
        : logObject.filePath,
    arguments: logObject.argumentsArray,
  };

  const firstArg = payload.arguments.shift();

  // Allows you to call Logger.error(error, { skipSentry: true }). Useful in some situation
  const { skipSentry, ...meta } = getMetaData(payload.arguments);

  if (logObject.logLevel === 'error') {
    if (typeof firstArg === 'string') {
      payload.error = new Error(firstArg);
    } else {
      payload.error = (firstArg as IErrorObjectStringifiable)?.nativeError;
    }

    const errorId =
      payload.error &&
      !skipSentry &&
      !shouldSkipErrorInSentry(payload.error) &&
      // Only send errors in sentry in prod
      Constants.isProdNotStaging
        ? Sentry.captureException(payload.error)
        : undefined;

    if (errorId) {
      payload.errorId = getSentryUrlForEventId(errorId);
    }

    payload.message = payload.error?.message ?? 'undefined-error';
  } else {
    payload.message = firstArg as any;
  }

  if (typeof payload.message !== 'string') {
    payload.message = JSON.stringify(payload.message);
  }

  try {
    const { message: msg, ...rest } = payload;

    const messageData = injectToDDogTracer({
      message: msg ?? 'empty',
      meta,
      ...rest,
    });

    // Datadog parses STOUT & STERR json logs
    if (Constants.isProd) {
      if (payload.level === 'error') {
        console.error(JSON.stringify(messageData));
      } else {
        console.log(JSON.stringify(messageData));
      }
    }
  } catch (error) {
    console.error(error);
  }
}

const persistLogs = (logObject: ILogObject) => {
  if (logObject.logLevel === 'debug') {
    return;
  }

  persistLogsImpl(logObject);
};

const getMinLevel = (): TLogLevelName => {
  if (minLogLevel) {
    return minLogLevel as TLogLevelName;
  }

  if (Constants.isProd) {
    return 'info';
  }

  return 'silly';
};

const baseLogger = () => {
  const tsLogger = new TSLogger({
    dateTimePattern: Constants.isProd
      ? 'year-month-day hour:minute:second.millisecond'
      : 'hour:minute:second.millisecond',
    displayFunctionName: false,
    displayFilePath: 'hidden',
    displayRequestId: false,
    dateTimeTimezone: Constants.isProd
      ? 'utc'
      : Intl.DateTimeFormat().resolvedOptions().timeZone,
    prettyInspectHighlightStyles: {
      name: 'yellow',
      number: 'blue',
      bigint: 'blue',
      boolean: 'blue',
    },
    // Hidden in prod as we handle logging ourselves above in persistLogsImpl
    type: Constants.isProd ? 'hidden' : 'pretty',
    exposeErrorCodeFrame: !Constants.isProd,
    /**
     * @see https://tslog.js.org/#/README?id=log-level
     */
    minLevel: getMinLevel(),
  });

  tsLogger.attachTransport(
    {
      silly: persistLogs,
      debug: persistLogs,
      trace: persistLogs,
      info: persistLogs,
      warn: persistLogs,
      error: persistLogs,
      fatal: persistLogs,
    },
    'debug',
  );

  return tsLogger;
};

export const Logger = baseLogger();

export * from './logger.shared';

export default Logger;
