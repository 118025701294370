import { FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, PropsOf } from '@flick-tech/theme-new';

import { BoxProps } from './Box';

export type SpacerProps = BoxProps;

/**
 * A flexible flex spacer that expands along the major axis of its containing flex layout.
 *
 * It renders a `div` by default, and takes up any available space.
 *
 * @see Docs https://chakra-ui.com/spacer
 */
export const Spacer: FC<SpacerProps> = chakra('div', {
  baseStyle: {
    flex: 1,
    justifySelf: 'stretch',
    alignSelf: 'stretch',
  },
});

if (__DEV__) {
  Spacer.displayName = 'Spacer';
}
