import { ReactNode, useCallback } from 'react';

import { Constants } from '@flick-tech/shared-common';
import { useFeatureFlags } from '@flick-tech/shared-hooks';
import { Button } from '../button';
import { useToast } from '../toast';

import FacebookIcon from './Facebook/FacebookIcon';
import FacebookProvider from './Facebook/FacebookProvider';
import Login from './Facebook/Login';
import { Logger } from '@flick-tech/logger';

export interface FacebookLoginButtonProps {
  onLogin: (accessToken: string) => void;
  ctaText?: string;
  scope?: string;
  loading?: boolean;
  appId?: string;
  renderButton?: (props: {
    loading: boolean;
    handleClick: () => void;
  }) => ReactNode;
}

export interface FacebookCallbackData {
  tokenDetail: {
    accessToken: string;
  };
}

export const defaultRequiredFacebookScopes = [
  'public_profile',
  'email',
  'pages_show_list',
  'pages_read_engagement',
  'pages_manage_metadata',
  'instagram_basic',
  'instagram_manage_insights',
];

export const requiredScopesForInstagramPublishing = [
  'public_profile',
  'email',
  'pages_show_list',
  'pages_read_engagement',
  'pages_manage_metadata',
  'instagram_basic',
  'instagram_manage_insights',
  'instagram_manage_comments',
  'instagram_content_publish',
];

// TODO merge this back into `requiredScopesForInstagramPublishing` once reviewed by facebook
export const requiredScopesForFacebookPublishing = [
  ...requiredScopesForInstagramPublishing,
  "pages_manage_posts"
]

export function FacebookLoginButton({
  onLogin,
  ctaText = 'Continue with Facebook',
  loading,
  appId = Constants.fbAppId,
  scope: scope_,
  renderButton,
}: FacebookLoginButtonProps) {
  const hasAccessToScheduling = useFeatureFlags().schedule;

  const defaultScopes = (
    hasAccessToScheduling
      ? requiredScopesForInstagramPublishing
      : defaultRequiredFacebookScopes
  ).join(',');

  const scope = scope_ ?? defaultScopes;

  const toast = useToast();

  const handleError = useCallback(
    (error) => {
      const userClosedPopup = error.message === 'Unauthorized user';

      if (userClosedPopup) {
        return;
      }

      Logger.error(error);

      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
      });
    },
    [toast],
  );

  const handleSuccess = useCallback(
    (data: FacebookCallbackData) => {
      const accessToken = data?.tokenDetail?.accessToken;

      if (!accessToken) {
        toast({
          title: 'Failed to connect to Facebook. Please try again.',
          status: 'warning',
        });
        return;
      }

      onLogin(data?.tokenDetail?.accessToken);
    },
    [onLogin, toast],
  );

  return (
    <FacebookProvider appId={appId}>
      <Login scope={scope} onCompleted={handleSuccess} onError={handleError}>
        {({ loading: fbLoading, handleClick }) =>
          renderButton ? (
            renderButton({ loading: loading || fbLoading, handleClick })
          ) : (
            <Button
              isLoading={loading || fbLoading}
              colorScheme="facebook"
              color="white"
              w="100%"
              size="lg"
              onClick={handleClick}
              leftIcon={<FacebookIcon fill="white" />}
            >
              {ctaText}
            </Button>
          )
        }
      </Login>
    </FacebookProvider>
  );
}
