import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

import { Constants, ROUTES } from '@flick-tech/shared-common';
import { Logger } from '@flick-tech/logger';

const isHandlingNewFlagsState = atom({
  key: 'ld/is-handling-new-flags',
  default: false,
});

const getUrl = () => {
  if (Constants.isWeb) {
    // relative path is fine!
    return ROUTES.API.AssignToExperiments;
  }

  // We might be in another app (e.g. schedule) so let's query the main
  // web app api as that's where this route is implemented.
  // base path is already included in ROUTES.API.AssignToExperiments
  return `${Constants.webBase.replace(Constants.basePath, '')}${
    ROUTES.API.AssignToExperiments
  }`;
};

export function useNewFlagsHandler() {
  const [isLoading, setIsLoading] = useRecoilState(isHandlingNewFlagsState);
  const ldClient = useLDClient();

  return useCallback(
    async (newFlags: Record<string, boolean>) => {
      // Prevent concurrent requests
      if (
        !ldClient ||
        isLoading ||
        Constants.isDev ||
        Constants.isCypress ||
        Constants.isTest
      ) {
        return;
      }

      setIsLoading(true);

      try {
        await fetch(getUrl(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            flags: Object.entries(newFlags).map(([name, value]) => ({
              name,
              value,
              reason: ldClient.variationDetail(name)?.reason?.kind,
            })),
          }),
          credentials: 'include',
        });
      } catch (error) {
        Logger.error(error);
      }

      setIsLoading(false);
    },
    [ldClient],
  );
}
