import { Component } from 'react';

import Initialise from './Initialise';

export interface Props {
  children: any;
}

interface State {
  api?: Record<string, any>;
  isProcessing?: boolean;
  error?: Error;
  data?: any;
  loading?: boolean;
}

export default class Process extends Component<Props, State> {
  state: State = {
    api: undefined,
  };

  handleProcess = async (
    fn: (api: any) => void,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSuccess = () => {},
  ): Promise<any> => {
    this.setState({
      data: undefined,
      error: undefined,
      loading: true,
    });

    try {
      const { api } = this.state;
      if (!api) {
        throw new Error('Facebook is not initialized. Wait for isReady');
      }

      const data = await fn(api);

      this.setState(
        {
          data,
          loading: false,
        },
        handleSuccess,
      );

      return data;
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });

      throw error;
    }
  };

  handleReady = (api: Record<string, any>): void => {
    this.setState({
      api,
    });
  };

  render() {
    const { children } = this.props;
    const { api, loading, data, error } = this.state;

    return (
      <Initialise onReady={this.handleReady}>
        {children({
          loading: !api || loading,
          handleProcess: this.handleProcess,
          data,
          error,
        })}
      </Initialise>
    );
  }
}
