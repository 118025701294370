import { Constants } from './constants';

export enum ProfilePageSection {
  AccountSettings = 'settings',
  TeamMembers = 'team',
  InstagramAccounts = 'ig-accounts',
  Billing = 'billing',
  Invoices = 'invoices',
  Notifications = 'notifications',
}

export enum RegisterStage {
  UserDetails = 'user',
  Questions = 'questions',
  ChoosePlan = 'plans',
  BillingDetails = 'billing',
  ConnectSocials = 'socials',
  Completed = 'completed',
}

export const ROUTES = {
  Logout: '/logout',
  Mobile: {
    Search: '/mobile/search',
  },
  Login: {
    Base: '/login',
    MagicLink: {
      Request: '/login/magic-link-request',
    },
  },
  Home: '/home',
  Freemium: {
    Signup: '/signup',
    Upgrade: '/signup/upgrade',
  },
  Referrals: '/referrals',
  Explore: '/explore',
  HashtagCollections: {
    Home: '/hashtag-collections/recently-ranked',
    Base: '/hashtag-collections',
    collection: (id: string) => `/hashtag-collections/${id}`,
  },
  Analytics: {
    Base: '/analytics',
    section: (id: string) => `/analytics/${id}`,
    Home: '/analytics/overview',
    Overview: '/analytics/overview',
    Content: '/analytics/content',
    Hashtags: '/analytics/hashtags',
    Account: '/analytics/account',
    Reports: '/analytics/reports',
    ReportsLinks: {
      Billing: '/analytics/reports/links/billing',
      Posts: '/analytics/reports/links/posts',
      Analytics: '/analytics/reports/links/analytics',
      post: (shortcode: string) => `/analytics/reports/links/post/${shortcode}`,
    },
  },
  Register: {
    Base: '/register',
    stage: (stage: RegisterStage) => `/register/${stage}`,
  },
  Reports: {
    Base: '/reports',
  },
  Profile: {
    Home: `/profile/${ProfilePageSection.AccountSettings}`,
    AccountSettings: `/profile/${ProfilePageSection.AccountSettings}`,
    InstagramAccounts: `/profile/${ProfilePageSection.InstagramAccounts}`,
    Billing: `/profile/${ProfilePageSection.Billing}`,
    Invoices: `/profile/${ProfilePageSection.Invoices}`,
    Notifications: `/profile/${ProfilePageSection.Notifications}`,
    section: (section: ProfilePageSection) => `/profile/${section}`,
  },
  Resources: {
    Base: '/resources',
    resource: (slug: string) => `/resources/${slug}`,
    Blog: {
      post: (slug: string) => `/resources/blog/posts/${slug}`,
      category: (slug: string) => `/resources/blog/${slug}`,
    },
    SmartGuides: {
      smartGuide: (slug: string) => `/resources/smart-guides/${slug}`,
    },
  },
  ViralPosts: '/viral-posts',
  API: {
    IGImageProxy: `${Constants.basePath}/api/ig-image`,
    Logout: `${Constants.basePath}/api/logout`,
    AssignToExperiments: `${Constants.basePath}/api/assign-to-experiments`,
    FBConversionAPI: `${Constants.basePath}/api/fbt`,
    PusherAuth: `${Constants.basePath}/api/pusher/beams-auth`,
  },
  Scheduling: {
    WaitList: '/scheduling/wait-list',
    Calendar: '/schedule/calendar',
  },
};

export const getWebApiEndpoint = (apiRouteKey: keyof typeof ROUTES.API) => {
  const apiRoute = ROUTES.API[apiRouteKey];

  return Constants.isWeb
    ? apiRoute
    : `${Constants.webBase}${apiRoute}`
        // The base path is added twice, once in webBase and once in ${apiRoute} (see ROUTES.API.X), but we
        // dont want to remove that in either places just for this use case.
        .replace(
          `${Constants.basePath}${Constants.basePath}`,
          Constants.basePath,
        );
};

export const QueryParams = {
  Analytics: {
    Content: {
      ToggledShortcode: 'shortcode',
      SectionType: 'type',
    },
    ReportId: 'id',
    BenchmarkDetails: 'benchmark',
  },
};
