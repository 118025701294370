import { useQueryClient } from 'react-query';

import { IGetLoggedInUserQuery, useGetUser } from '@flick-tech/core-hooks';

export function useMyActiveSocialGroup() {
  const { user, ...rest } = useGetUser();

  const queryClient = useQueryClient();

  const setActiveSocialGroupInCache = (
    socialGroup: IGetLoggedInUserQuery['me']['activeSocialGroup'],
  ) => {
    const key = useGetUser.getKey();

    const oldData = queryClient.getQueryData<IGetLoggedInUserQuery>(key)!;

    queryClient.setQueryData<IGetLoggedInUserQuery>(key, {
      ...oldData,
      me: {
        ...oldData?.me,
        activeSocialGroup: socialGroup,
      },
    });
  };

  const activeSocialGroup = user?.activeSocialGroup;

  const activeIGAccount = activeSocialGroup
    ? {
        ...activeSocialGroup?.igAccount?.data,
        socialGroupId: activeSocialGroup.id,
        linked: activeSocialGroup?.igAccount?.linked,
      }
    : undefined;

  return {
    ...rest,
    activeSocialGroup,
    activeIGAccount,
    setActiveSocialGroupInCache,
  };
}
