import { Constants } from '@flick-tech/shared-common';

import BadgeTheme from './BadgeTheme';
import { ComponentTheme } from './utils';

const TagTheme: ComponentTheme = {
  defaultProps: {
    size: 'lg',
    variant: 'subtle',
    colorScheme: Constants.defaultColorTheme,
  },
  baseStyle: {
    outline: 0,
    _focus: {
      boxShadow: 'outline',
    },
  },
  sizes: {
    sm: {
      minHeight: '1.25rem',
      minWidth: '1.25rem',
      fontSize: 'xs',
      paddingX: 1,
      borderRadius: 'sm',
    },
    md: {
      minHeight: '1.5rem',
      minWidth: '1.5rem',
      fontSize: 'sm',
      borderRadius: 'md',
      paddingX: 2,
    },
    lg: {
      minHeight: 8,
      minWidth: 8,
      fontSize: 'md',
      borderRadius: 'md',
    },
  },
  variants: BadgeTheme.variants,
};

export default TagTheme;
