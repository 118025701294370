import {
  Emoji as EmojiMartEmoji,
  EmojiProps as EmojiMartProps,
} from 'emoji-mart';
import { FC } from 'react';

import { Flex, FlexProps } from '../layout';

export interface EmojiProps
  extends Pick<EmojiMartProps, 'size' | 'emoji'>,
    Omit<FlexProps, 'size'> {
  set?: EmojiMartProps['set'];
}

export const Emoji: FC<EmojiProps> = ({
  size,
  emoji,
  set = 'apple',
  ...props
}) => (
  <Flex {...props}>
    <EmojiMartEmoji set={set} size={size} emoji={emoji} />
  </Flex>
);

export default Emoji;
