import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = String(
  process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://276511a7c3de41e3a0ec9f0f7feab32b@o509430.ingest.sentry.io/6535996',
);

const COMMIT_SHA =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
  process.env.VERCEL_GIT_COMMIT_SHA ||
  process.env.FC_GIT_COMMIT_SHA ||
  process.env.NEXT_PUBLIC_COMMIT_SHA ||
  process.env.COMMIT_SHA;

const isCypress = process.env.CYPRESS === 'true';
const isTest = process.env.NODE_ENV === 'test';

Sentry.init({
  enabled: !isCypress && !isTest,
  environment: process.env.NODE_ENV,
  dsn: SENTRY_DSN,
  // We don't want tracing for now
  tracesSampleRate: 0,
  release: COMMIT_SHA,
});
