import { FC } from '@flick-tech/shared-types';
import { chakra } from '@flick-tech/theme-new';
import { BoxProps } from '../layout';

export type CardProps = BoxProps;

export const Card: FC<CardProps> = chakra('div', {
  baseStyle: ({ colorMode }) => ({
    bg: colorMode === 'dark' ? 'whiteAlpha.300' : 'white',
    boxShadow: 'md',
    p: 4,
    borderRadius: '12px',
    border: '1px solid',
    // TODO: fix borderColor doesn't work
    borderTopColor: 'bg.light',
    borderLeftColor: 'bg.light',
    borderBottomColor: 'bg.light',
    borderRightColor: 'bg.light',
  }),
});
