import { chakra } from '@flick-tech/theme-new';
import { ChakraIcon, ChakraIconProps } from '../chakra-icons';

const IconBase = (props: ChakraIconProps) => (
  <ChakraIcon
    fill="inherit"
    fontSize="2xl"
    ml={6}
    _first={{
      ml: 0,
    }}
    _hover={{
      cursor: 'pointer',
    }}
    viewBox="0 0 24 24"
    {...props}
  />
);

interface IconProps extends Omit<ChakraIconProps, 'color'> {}

export function FacebookIcon(props: IconProps) {
  return (
    <IconBase viewBox="0 0 216 216" className="_5h0m" {...props}>
      <chakra.path
        d="
          M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
          11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
          11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
          15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
          11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"
      />
    </IconBase>
  );
}

export function ClipboardIcon(props: IconProps) {
  return (
    <IconBase {...props}>
      <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" />
      <path
        d="M15.3335 8.66675H13.3335C12.9668 8.66675 12.6668 8.96675 12.6668 9.33341C12.6668 9.70008 12.9668 10.0001 13.3335 10.0001H15.3335C16.4335 10.0001 17.3335 10.9001 17.3335 12.0001C17.3335 13.1001 16.4335 14.0001 15.3335 14.0001H13.3335C12.9668 14.0001 12.6668 14.3001 12.6668 14.6667C12.6668 15.0334 12.9668 15.3334 13.3335 15.3334H15.3335C17.1735 15.3334 18.6668 13.8401 18.6668 12.0001C18.6668 10.1601 17.1735 8.66675 15.3335 8.66675ZM9.3335 12.0001C9.3335 12.3667 9.6335 12.6667 10.0002 12.6667H14.0002C14.3668 12.6667 14.6668 12.3667 14.6668 12.0001C14.6668 11.6334 14.3668 11.3334 14.0002 11.3334H10.0002C9.6335 11.3334 9.3335 11.6334 9.3335 12.0001ZM10.6668 14.0001H8.66683C7.56683 14.0001 6.66683 13.1001 6.66683 12.0001C6.66683 10.9001 7.56683 10.0001 8.66683 10.0001H10.6668C11.0335 10.0001 11.3335 9.70008 11.3335 9.33341C11.3335 8.96675 11.0335 8.66675 10.6668 8.66675H8.66683C6.82683 8.66675 5.3335 10.1601 5.3335 12.0001C5.3335 13.8401 6.82683 15.3334 8.66683 15.3334H10.6668C11.0335 15.3334 11.3335 15.0334 11.3335 14.6667C11.3335 14.3001 11.0335 14.0001 10.6668 14.0001Z"
        fill="white"
      />
    </IconBase>
  );
}

export function FacebookRoundedIcon(props: IconProps) {
  return (
    <IconBase {...props}>
      <chakra.path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z" />
    </IconBase>
  );
}

export function InstagramIcon(props: IconProps) {
  return (
    <IconBase {...props}>
      <chakra.path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
    </IconBase>
  );
}

export function TwitterIcon(props: IconProps) {
  return (
    <IconBase {...props}>
      <chakra.path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
    </IconBase>
  );
}

export function BufferIcon(props: IconProps) {
  return (
    <IconBase {...props}>
      <chakra.path d="M23.784 18.24c.287.142.287.267 0 .374l-11.357 5.223c-.287.145-.57.145-.854 0L.213 18.614c-.284-.107-.284-.232 0-.375l2.722-1.23c.284-.14.57-.14.852 0l7.787 3.573c.285.14.57.14.854 0l7.787-3.574c.283-.14.568-.14.852 0l2.717 1.23zm0-6.454c.287.143.287.285 0 .426L12.427 17.44c-.287.104-.57.104-.854 0L.213 12.21c-.284-.143-.284-.284 0-.426l2.722-1.227c.284-.144.57-.144.852 0l7.787 3.57c.285.144.57.144.854 0l7.787-3.57c.283-.144.568-.144.852 0l2.717 1.226zM.214 5.76c-.285-.143-.285-.267 0-.375L11.574.16c.283-.14.57-.14.852 0l11.358 5.23c.287.107.287.232 0 .375l-11.357 5.223c-.287.143-.57.143-.854 0L.213 5.76z" />
    </IconBase>
  );
}

export function LinkedInIcon(props: IconProps) {
  return (
    <IconBase {...props}>
      <chakra.path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
    </IconBase>
  );
}
