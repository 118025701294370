import React, { ComponentPropsWithoutRef } from 'react';

// Passed by React.cloneElement in recharts
interface AreaChartClonedDotProps {
  value: [number, number] | number;
  index: number;
  points: Array<{
    x: number;
    y: number | null | undefined;
    payload: unknown /* datum */;
  }>;
}
export interface DotWhenAloneProps
  extends Omit<ComponentPropsWithoutRef<'circle'>, 'points'> {
  fill: string;
  size?: 'tiny' | 'small';
}
export function DotWhenAlone(props: DotWhenAloneProps) {
  type FinalProps = DotWhenAloneProps & AreaChartClonedDotProps;

  const {
    value,
    index,
    fill,
    size = 'small',
    points,
    ...rest
  } = props as FinalProps;

  if (Array.isArray(value) ? value[1] == null : value == null) {
    return null;
  }

  const neighbours = [points[index - 1], points[index + 1]]
    .map((neighbour) => neighbour?.y)
    .filter((x) => x != null).length;

  const isTiny = size === 'tiny';

  if (
    neighbours === 2 ||
    (neighbours === 1 && (index === 0 || index === points.length - 1 || isTiny))
  ) {
    return null;
  }

  let r = rest.r;
  let strokeWidth: number;
  if (size === 'tiny') {
    strokeWidth = 0;
    r = 2;
  } else {
    strokeWidth = neighbours === 0 ? 2 : 0;
  }

  return (
    <circle
      {...rest}
      r={r}
      fillOpacity={1}
      fill={
        fill === 'white' ? (strokeWidth === 0 ? rest.stroke : 'white') : fill
      }
      strokeWidth={strokeWidth}
    />
  );
}
