import { useContext, useRef } from 'react';

import { useClickAway } from '@flick-tech/shared-hooks';
import { Stack, StackProps } from '../../layout';

import { __FloatingActionsContext as FloatingActionsContext } from '../FloatingActionsContext';

export interface ActionsDisclosureProps extends StackProps {}

export const ActionsDisclosure = (stackProps: ActionsDisclosureProps) => {
  const { onClose } = useContext(FloatingActionsContext);

  const container = useRef<HTMLDivElement>(null);
  useClickAway(() => void onClose(), container.current);

  return (
    <Stack
      ref={container}
      alignItems="stretch"
      bg="brand.500"
      borderRadius="12px"
      width="457px"
      maxWidth="100%"
      spacing={4}
      p={5}
      boxShadow="1px 1px 12px rgba(0, 0, 0, 0.25)"
      animation="fade"
      data-intercom-id="selected-hashtags-actions-menu"
      pointerEvents="initial"
      {...stackProps}
    />
  );
};
