import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { Tracking } from '@flick-tech/client-tracking';
import { Logger } from '@flick-tech/logger';
import {
  clearPersistedBasicUserInfo,
  Constants,
  PersistentStore,
  ROUTES,
  wait,
} from '@flick-tech/shared-common';
import {
  clearFlickAccessToken,
  useScheduleMutation,
} from '@flick-tech/shared-hooks';
import { OneSignal } from '@flick-tech/web-notifications';

const noop = () => {};

export interface IOnLogoutProps {
  skipRedirect?: boolean;
}

export function useLogout() {
  const queryClient = useQueryClient();
  const [scheduleLogout] = useScheduleMutation('logout');

  const onLogout = useCallback(
    async (options?: IOnLogoutProps) => {
      PersistentStore.clear();

      clearFlickAccessToken();

      queryClient.clear();

      scheduleLogout().catch(noop);

      Tracking.trackEvent('Logged Out');

      OneSignal.removeExternalUserId().catch((e) => console.error(e));

      clearPersistedBasicUserInfo();

      if (!options?.skipRedirect) {
        await wait(1000);

        window.location.replace(
          `${Constants.webBase}${ROUTES.Login.Base}${window.location.search}`,
        );
      }
    },
    [queryClient, scheduleLogout],
  );

  return { onLogout };
}
