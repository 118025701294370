import { forwardRef, Ref } from 'react';

import { FC } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { SystemProps } from '@flick-tech/theme-new';

import { Box, BoxProps } from './Box';

export interface FlexOptions {
  /**
   * Shorthand for `alignItems` style prop
   */
  align?: SystemProps['alignItems'];
  /**
   * Shorthand for `justifyContent` style prop
   */
  justify?: SystemProps['justifyContent'];
  /**
   * Shorthand for `flexWrap` style prop
   */
  wrap?: SystemProps['flexWrap'];
  /**
   * Shorthand for `flexDirection` style prop
   */
  direction?: SystemProps['flexDirection'];
  /**
   * Shorthand for `flexBasis` style prop
   */
  basis?: SystemProps['flexBasis'];
  /**
   * Shorthand for `flexGrow` style prop
   */
  grow?: SystemProps['flexGrow'];
  /**
   * Shorthand for `flexShrink` style prop
   */
  shrink?: SystemProps['flexShrink'];
}

export interface FlexProps extends BoxProps, FlexOptions {}

/**
 * React component used to create flexbox layouts.
 *
 * It renders a `div` with `display: flex` and
 * comes with helpful style shorthand.
 *
 * @see Docs https://chakra-ui.com/flex
 */
export const Flex: FC<FlexProps> = forwardRef(
  (props: FlexProps, ref: Ref<any>) => {
    const { direction, align, justify, wrap, basis, grow, ...rest } = props;
    return (
      <Box
        ref={ref}
        display="flex"
        flexDirection={props.direction}
        alignItems={props.align}
        justifyContent={props.justify}
        flexWrap={props.wrap}
        flexBasis={props.basis}
        flexGrow={props.grow}
        {...rest}
      />
    );
  },
);

if (__DEV__) {
  Flex.displayName = 'Flex';
}
