import { TooltipProps as RechartsTooltipProps } from 'recharts';
import type { NameType } from 'recharts/types/component/DefaultTooltipContent';

import { dateFullDay, dateFullDayNoYear } from '@flick-tech/shared-common';

import { TooltipHeading, TooltipStat, TooltipStatsWrapper } from '../Tooltip';
import { StatInfo, TextTransform } from '../types';

const EMPTY_TEXT = 'missing data';

export interface TooltipContentProps<TValue, TName extends NameType = NameType>
  extends Omit<RechartsTooltipProps<any, TName>, 'payload'> {
  payload?: [{ payload?: TValue }];
}

interface TooltipPayload {
  timestamp: string | Date;
  dateFrom?: Date;
}

export const makeRechartTooltip =
  (stats: StatInfo[], { postfix }: { postfix?: TextTransform } = {}) =>
  ({ payload }: TooltipContentProps<TooltipPayload, NameType>) => {
    const data = payload?.[0]?.payload;

    if (!data) {
      return 'n/a';
    }

    return (
      <TooltipStatsWrapper>
        {data.timestamp && (
          <TooltipHeading>
            {data.dateFrom ? 'From ' : 'On '}
            <span style={{ fontWeight: 700 }}>
              {data.dateFrom && (
                <>
                  {dateFullDayNoYear(data.dateFrom)}
                  <span style={{ fontWeight: 400 }}> to </span>
                </>
              )}
              {dateFullDay(data.timestamp)}
            </span>
          </TooltipHeading>
        )}
        {stats.map(({ key, color, text, valueTransform }) => {
          // @ts-ignore That means this property was null and was filled by `fillGaps` prop of FlickBarChart
          const isMarkedEmpty = data._empty?.includes(key);
          const value = isMarkedEmpty
            ? EMPTY_TEXT
            : typeof valueTransform === 'function'
            ? valueTransform(data[key])
            : data[key];

          const isEmpty = isMarkedEmpty || value === null;

          return (
            <TooltipStat
              key={key}
              label={typeof text === 'function' ? text(data[key], data) : text}
              color={color}
              value={value ?? EMPTY_TEXT}
              postfix={isEmpty ? null : postfix}
            />
          );
        })}
      </TooltipStatsWrapper>
    );
  };
