import { AsProp, FC, PropsOf } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, ChakraComponent, ChakraProps } from '@flick-tech/theme-new';

export interface BoxProps
  extends Omit<PropsOf<'div'>, 'color'>,
    ChakraProps,
    AsProp {}

/**
 * Box is the most abstract component on top of which other chakra
 * components are built. It renders a `div` element by default.
 *
 * @see Docs https://chakra-ui.com/box
 */
export const Box: FC<BoxProps> = chakra.div as FC<
  // `color` from div props collides with ChakraProps["color"].
  Omit<PropsOf<ChakraComponent<'div', {}, ''>>, 'color'>
>;

if (__DEV__) {
  Box.displayName = 'Box';
}
