import { cloneElement, forwardRef, memo, ReactElement } from 'react';

import { dataAttr } from '@flick-tech/shared-utils';
import { InlineBox, InlineBoxProps } from '../layout';

type SliderRootProps = InlineBoxProps & {
  isDisabled: boolean;
  isMarked: boolean;
  isVertical: boolean;
};

export const SliderRoot = memo<SliderRootProps>(
  forwardRef(({ isDisabled, isMarked, isVertical, ...props }, ref) => (
    <InlineBox
      ref={ref as any}
      height="2px"
      width="100%"
      boxSizing="content-box"
      padding="13px 0"
      position="relative"
      cursor="pointer"
      color="brand.500"
      data-disabled={dataAttr(isDisabled)}
      _disabled={{
        pointerEvents: 'none',
        cursor: 'default',
        color: 'gray.400',
      }}
      {...(isVertical && {
        width: 2,
        height: '100%',
        padding: '0 13px',
      })}
      {...(isMarked && {
        marginBottom: 20,
      })}
      // prettier-ignore
      {...(isMarked && isVertical && {
          marginBottom: 'auto',
          marginRight: 20,
        })}
      _imprecisePointer={{
        // Reach 42px touch target, about ~8mm on a (touch) screen.
        padding: isVertical ? '0 20px' : '20px 0',
      }}
      _print={{
        // @ts-ignore
        colorAdjust: 'exact',
      }}
      WebkitTapHighlightColor="transparent"
      touchAction="none"
      {...props}
    />
  )),
);

export const SliderRail = memo<{ isVertical: boolean } & InlineBoxProps>(
  ({ isVertical, ...props }) => (
    <InlineBox
      display="block"
      position="absolute"
      borderRadius="1px"
      backgroundColor="currentColor"
      opacity={0.38}
      height={isVertical ? '100%' : '2px'}
      width={isVertical ? '2px' : '100%'}
      {...props}
    />
  ),
);

export const SliderTrack = memo<{ isVertical: boolean } & InlineBoxProps>(
  ({ isVertical, ...props }) => (
    <InlineBox
      display="block"
      position="absolute"
      height="2px"
      borderRadius="1px"
      backgroundColor="currentColor"
      width={isVertical ? '2px' : undefined}
      {...props}
    />
  ),
);

type SliderValueLabelProps = InlineBoxProps & {
  isOpen: boolean;
  isDisabled: boolean;
  value: string;
  children: ReactElement;
  /**
   * Controls when the value label is displayed:
   *
   * - `auto` the value label will display when the thumb is hovered or focused.
   * - `on` will display persistently.
   * - `off` will never display.
   */
  valueLabelMode: 'on' | 'auto' | 'off';
};

export const SliderValueLabel = memo<SliderValueLabelProps>(
  ({ isOpen, valueLabelMode, value, children, ...props }) => {
    if (valueLabelMode === 'off') {
      return children;
    }

    return cloneElement(
      children,
      {
        ...props,
      },
      <InlineBox
        fontWeight="normal"
        zIndex={1}
        fontSize="0.75rem"
        lineHeight={1.2}
        transition="transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        top="-34px"
        left="calc(-50% - 4px)"
        transform="scale(0)"
        transformOrigin="bottom center"
        position="absolute"
        {...(isOpen && {
          transform: 'scale(1) translateY(-10px)',
        })}
      >
        <InlineBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="32px"
          height="32px"
          borderRadius="50% 50% 50% 0"
          backgroundColor="currentColor"
          transform="rotate(-45deg)"
        >
          <InlineBox display="inline" color="white" transform="rotate(45deg)">
            {value}
          </InlineBox>
        </InlineBox>
      </InlineBox>,
    );
  },
);

type SliderThumbProps = {
  isVertical: boolean;
  isActive: boolean;
  isFocusVisible: boolean;
  isDisabled: boolean;
} & InlineBoxProps;

const sliderThumbHover = {
  boxShadow: `0px 0px 0px 8px rgba(50, 117, 224, 0.16)`,
  _touchDevice: {
    boxShadow: 'none',
  },
};

export const SliderThumb = memo<SliderThumbProps>(
  ({ isVertical, isActive, isFocusVisible, isDisabled, ...props }) => (
    <InlineBox
      position="absolute"
      width="12px"
      height="12px"
      marginLeft="-6px"
      marginTop="-5px"
      boxSizing="border-box"
      borderRadius="50%"
      outline={0}
      backgroundColor="currentColor"
      display="flex"
      alignItems="center"
      justifyContent="center"
      transition="box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      _after={{
        position: 'absolute',
        content: '""',
        borderRadius: '50%',
        // reach 42px hit target (2 * 15 + thumb diameter)
        left: '-15px',
        top: '-15px',
        right: '-15px',
        bottom: '-15px',
      }}
      _hover={sliderThumbHover}
      {...(isFocusVisible && sliderThumbHover)}
      {...(isVertical && {
        marginLeft: '-5px',
        marginBottom: '-6px',
      })}
      data-active={dataAttr(isActive)}
      _active={{
        boxShadow: `0px 0px 0px 14px rgba(50, 117, 224, 0.16)`,
        // boxShadow: `0px 0px 0px 14px brand.100`,
        // boxShadow: `0px 0px 0px 14px ${fade(theme.palette.primary.main, 0.16)}`,
      }}
      data-disabled={dataAttr(isDisabled)}
      _disabled={{
        width: '8px',
        height: '8px',
        marginLeft: '-4px',
        marginTop: '-3px',
        ...(isVertical && {
          marginLeft: '-3px',
          marginBottom: '-4px',
        }),
      }}
      {...props}
    />
  ),
);

export const SliderMark = memo<{ isActive: boolean } & InlineBoxProps>(
  ({ isActive, ...props }) => (
    <InlineBox
      position="absolute"
      width="2px"
      height="2px"
      borderRadius="1px"
      backgroundColor="currentColor"
      data-active={dataAttr(isActive)}
      _active={{
        backgroundColor: 'white',
        opacity: 0.8,
      }}
      {...props}
    />
  ),
);

export const SliderMarkLabel = memo<
  { isVertical: boolean; isActive: boolean } & InlineBoxProps
>(({ isVertical, isActive, ...props }) => (
  <InlineBox
    color="#868e96"
    fontSize="0.875rem"
    top="26px"
    position="absolute"
    transform="translateX(-50%)"
    whiteSpace="nowrap"
    data-active={dataAttr(isActive)}
    _active={{
      color: 'rgb(22, 17, 28)',
    }}
    {...(isVertical && {
      top: 'auto',
      left: '26px',
      transform: 'translateY(50%)',
    })}
    _imprecisePointer={{
      top: '40px',
      ...(isVertical && {
        left: '31px',
      }),
    }}
    {...props}
  />
));
