import { Component, forwardRef } from 'react';

import FacebookContext from './FacebookContext';

interface Props {
  children: any;
  onReady?: (api: any) => void;
  handleInit: () => any;
  isReady: boolean;
  api?: Record<string, any>;
}

class InitializeWithContext extends Component<Props> {
  private $isMounted: boolean;

  static defaultProps = {
    onReady: undefined,
    api: undefined,
  };

  componentDidMount() {
    this.$isMounted = true;
    this.prepare();
  }

  componentWillUnmount() {
    this.$isMounted = false;
  }

  async prepare() {
    const { onReady, handleInit } = this.props;
    const api = await handleInit();
    if (onReady && this.$isMounted) {
      onReady(api);
    }
  }

  render() {
    const { children, isReady, api } = this.props;

    const childrenProps = {
      isReady,
      api,
    };

    if (typeof children === 'function') {
      return children(childrenProps);
    }

    return children;
  }
}

const Initialise = forwardRef((props: any, ref) => (
  <FacebookContext.Consumer>
    {({ handleInit, isReady, api }: Props) => (
      <InitializeWithContext
        {...props}
        handleInit={handleInit}
        isReady={isReady}
        api={api}
        ref={ref as any}
      />
    )}
  </FacebookContext.Consumer>
));

export default Initialise;
