// Must be imported first
// eslint-disable-next-line simple-import-sort/imports
import '../utils/wdyr';
import '../utils/focus-manager';
import '../utils/ignore-recoil-warnings';
import './styles.css';

import { datadogLogs } from '@datadog/browser-logs';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SingletonHooksContainer } from 'react-singleton-hook';

import {
  useInitializeTracking,
  usePageViewTracker,
} from '@flick-tech/client-tracking';
import { isRunningOnServerSide } from '@flick-tech/shared-common';
import { usePageLoader, useRouteChanged } from '@flick-tech/shared-hooks';
import { FlickThemeProvider } from '@flick-tech/theme-new';
import { AsyncDialogProvider } from '@flick-tech/ui';
import { ErrorBoundary, FlickSEO } from '@flick-tech/ui';
import { RecoilProvider } from '@flick-tech/shared-hooks';

import { FullHeightAppWrapper } from '../components';
import { WithUser } from '../layouts/WithUser';
import type { FlickComponentStaticAttributes } from '../utils/flick-nextjs';
import {
  initDatadogRUM,
  startSessionRecording,
} from '@flick-tech/client-tracking';
import { AppSkeletonLoader } from '@flick-tech/app-shell';
import { Toaster } from 'react-hot-toast';
import { setupReactQueryFocusManager } from '../utils/focus-manager';

datadogLogs.init({
  clientToken: 'pub163838e2e62382d51b7effaaccc641f6',
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

datadogLogs.setLoggerGlobalContext({
  flickApp: process.env.NEXT_PUBLIC_FLICK_APP,
});

initDatadogRUM();
startSessionRecording();
setupReactQueryFocusManager();

const queryClient = new QueryClient();

interface CustomAppProps extends Omit<AppProps, 'Component'> {
  Component: AppProps['Component'] & FlickComponentStaticAttributes;
}

function App({ Component, pageProps }: CustomAppProps) {
  const getLayout = Component.getLayout || ((page) => page);
  const seoProps = Component.seo;

  const skeletonLoader = Component.skeletonLoader ?? <AppSkeletonLoader />;
  const withUser = Component.withUser ?? true;
  const disablePageViewTracking = Component.disablePageViewTracking;

  useRouteChanged(() => {
    if (!isRunningOnServerSide()) {
      window.scrollTo(0, 0); // Disable scroll restoration
    }
  });

  useInitializeTracking();
  usePageLoader();
  usePageViewTracker({
    disablePageViewTracking,
  });

  let content = getLayout(<Component {...pageProps} />);

  if (withUser) {
    content = <WithUser skeletonLoader={skeletonLoader}>{content}</WithUser>;
  }

  return (
    <>
      {seoProps?.title && (
        <FlickSEO {...seoProps} title={`${seoProps.title} - Flick`} />
      )}
      <QueryClientProvider client={queryClient}>
        <RecoilProvider>
          <FlickThemeProvider>
            <Toaster
              toastOptions={{
                className: '',
                style: {
                  border: 'none',
                  padding: 0,
                  backgroundColor: 'transparent',
                  borderRadius: 0,
                  maxWidth: '500px',
                  boxShadow: 'none',
                },
              }}
              position="top-center"
            />
            <ErrorBoundary>
              <AsyncDialogProvider>
                <SingletonHooksContainer />
                <FullHeightAppWrapper>{content}</FullHeightAppWrapper>
              </AsyncDialogProvider>
            </ErrorBoundary>
          </FlickThemeProvider>
        </RecoilProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
