import { IWorkspaceRole } from '@flick-tech/shared-types';

export function isRoleGreaterThan(left: IWorkspaceRole, right: IWorkspaceRole) {
  if (left === 'Owner' && right !== 'Owner') {
    return true;
  }

  if (right === 'Owner') {
    return false;
  }

  return left === 'Admin' && ['Owner', 'Admin'].includes('Owner');
}
