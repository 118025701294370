import { ReactNode } from 'react';

import { Image } from '../../image';
import { Box, BoxProps, Flex, FlexProps,  Text } from '../../layout';

import EmptyStateImage from './image.png';

interface RequiresActiveSubscriptionContentProps
  extends Omit<BoxProps, 'title'> {
  title: string | ReactNode;
  body?: string | ReactNode;
  footer?: ReactNode;
}

const Section = (props: FlexProps) => (
  <Flex
    direction="column"
    align="flex-start"
    justify="center"
    height="100%"
    {...props}
  />
);

export function AnalyticsAccessBody() {
  return <>Upgrade to use Flick's hashtag, scheduling & analytics features.</>;
}

export function RequiresActiveSubscriptionContent({
  title,
  body = <AnalyticsAccessBody />,
  footer,
  ...rest
}: RequiresActiveSubscriptionContentProps) {
  return (
    <Box
      minH="100vh"
      display={{
        base: 'flex',
        md: 'grid',
      }}
      gridTemplateColumns={{
        base: 'none',
        md: 'repeat(2, 1fr)',
      }}
      gridGap={{
        base: 0,
        md: '30px',
      }}
      flexDirection={{
        base: 'column',
        md: 'row',
      }}
      textAlign={{
        base: 'center',
        md: 'left',
      }}
      p={4}
      px={{
        base: 4,
        md: 8,
      }}
      {...rest}
    >
      <Section
        maxW={500}
        alignSelf={{
          base: 'center',
          md: 'auto',
        }}
      >
        <Text
          fontWeight={700}
          fontSize="3xl"
          lineHeight={1.3}
          fontFamily="heading"
        >
          {title}
        </Text>
        <Text
          fontSize="xl"
          fontFamily="heading"
          lineHeight={1.4}
          mt={2}
          color="gray.700"
          width={{
            base: '100%',
            md: 'auto',
          }}
        >
          {body}
        </Text>
        <Box mt={6}>{footer}</Box>
      </Section>
      <Section>
        <Image src={EmptyStateImage} />
      </Section>
    </Box>
  );
}
