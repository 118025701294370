import { useCallback, useReducer } from 'react';

type VoidFunction = () => void;

const reducer = (state: boolean, _action: null): boolean => !state;

export const useForceUpdate = (): VoidFunction => {
  // @ts-ignore
  const [, dispatch] = useReducer<boolean, null>(reducer, true);

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback((): void => {
    // @ts-ignore
    dispatch(null);
  }, [dispatch]);
  return memoizedDispatch;
};

export default useForceUpdate;
