import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { isRunningOnServerSide } from '@flick-tech/shared-common';

import { Tracking } from './tracking';

class PageViewTracker {
  private lastPathname = '';
  private static instance: PageViewTracker;

  private constructor() {
    if (isRunningOnServerSide()) return;
  }

  private static getInstance(): PageViewTracker {
    if (!this.instance) {
      this.instance = new PageViewTracker();
    }

    return this.instance;
  }

  static track(newPathname: string) {
    const instance = this.getInstance();

    if (instance.lastPathname !== newPathname) {
      const search = window.location.search;

      Tracking.trackPageView(newPathname, search);
    }

    instance.lastPathname = newPathname;
  }
}

interface UsePageViewTracker {
  disablePageViewTracking?: boolean;
}

export function usePageViewTracker({
  disablePageViewTracking,
}: UsePageViewTracker) {
  const routerEvents = useRouter().events;

  useEffect(() => {
    if (disablePageViewTracking) {
      return;
    }

    routerEvents.on('routeChangeComplete', (pathname) => {
      PageViewTracker.track(pathname);
    });
  }, [disablePageViewTracking, routerEvents]);
}
