import { memo, useCallback, useEffect } from 'react';

import { isRunningOnServerSide } from '@flick-tech/shared-common';
import { useDisclosure, useHasFeatureFlag } from '@flick-tech/shared-hooks';
import { ISocialGroupFieldsFragment } from '@flick-tech/shared-types';
import {
  useMyActiveSocialGroup,
  useSetActiveSocialGroup,
} from '@flick-tech/social-group-hooks';
import {
  Avatar,
  AvatarProps,
  Box,
  Flex,
  FlickPopover,
  Icon,
  Portal,
  SkeletonCircle,
  SkeletonText,
  Text,
  useMedia,
  usePopper,
} from '@flick-tech/ui';

import { SocialGroupCardProps } from '../../SocialGroupCard';

import { SocialGroupList } from './SocialGroupList';

export interface SocialGroupSwitcherProps
  extends Pick<
      SocialGroupCardProps,
      'hasAccessToHashtagTracking' | 'postsTrackedLimit'
    >,
    Omit<Partial<AvatarProps>, 'onChange'> {
  hideSecondary?: boolean;
  onChange?: (socialGroup: ISocialGroupFieldsFragment) => void;
  isLoading?: boolean;
}

export const SocialGroupSwitcher = memo(
  ({
    size = 'sm',
    hasAccessToHashtagTracking,
    postsTrackedLimit,
    onChange,
    isLoading,
    ...rest
  }: SocialGroupSwitcherProps) => {
    const {
      activeSocialGroup,
      isLoading: socialGroupsLoading,
      isFetched,
    } = useMyActiveSocialGroup();
    const hasAccessToSocialGroups = useHasFeatureFlag('socialGroups');

    const { igAccount, facebook } = activeSocialGroup ?? {};
    const { profilePicture } = igAccount?.data ?? facebook ?? {};

    const isLoadingActiveSocialGroup = socialGroupsLoading || !isFetched;

    const { isOpen, onClose, onToggle } = useDisclosure();

    const isLargeScreen = useMedia({ min: 'lg' });

    const showExpandedVersion = isRunningOnServerSide() ? true : isLargeScreen;

    const { popper, reference } = usePopper({
      placement: 'right-start',
      forceUpdate: isOpen,
    });

    const cardRef = popper.ref;

    // TODO: extract that out into a component/hook

    // Effect to close this filter card on outside click
    useEffect(() => {
      const handleDocumentClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        // if the card is not open, don't do anything
        if (!isOpen) return;

        // if the click is within the card, don't do anything
        if (
          cardRef.current?.contains(target) ||
          reference.ref.current?.contains(target)
        ) {
          return;
        }

        onClose();
      };
      // add the event listener for click
      document.addEventListener('click', handleDocumentClick, {
        capture: true,
      });

      return () => {
        // remove the event listener, to avoid memory leak
        document.removeEventListener('click', handleDocumentClick, {
          capture: true,
        });
      };
      // Missing deps are refs.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardRef, isOpen, onClose]);

    const { setActiveSocialGroup } = useSetActiveSocialGroup();

    const onSelect = useCallback(
      async (socialGroup: ISocialGroupFieldsFragment) => {
        try {
          await setActiveSocialGroup({ socialGroupId: socialGroup.id });

          if (onChange) {
            onChange(socialGroup);
          }

          onClose();
        } catch (_) {}
      },
      [onChange, onClose, setActiveSocialGroup],
    );

    const socialGroupAvatar = (
      <>
        {activeSocialGroup ? (
          <Avatar
            data-testid="layout--ig-switcher"
            size={size}
            name={activeSocialGroup?.name}
            src={profilePicture}
            _hover={{
              cursor: 'pointer',
            }}
            {...rest}
          />
        ) : (
          <Flex
            w="40px"
            h="40px"
            backgroundColor="bg.lighter"
            color="text.lighter"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
          >
            <Icon icon="AddRounded" fontSize="25px" />
          </Flex>
        )}
      </>
    );

    const loading =
      isLoadingActiveSocialGroup || socialGroupsLoading || isLoading;

    const featureName = hasAccessToSocialGroups ? 'Social Group' : 'IG Account';

    const subTitle = hasAccessToSocialGroups
      ? 'Social Group'
      : activeSocialGroup?.igAccount?.data?.username;

    const socialGroupName = hasAccessToSocialGroups
      ? activeSocialGroup?.name
      : activeSocialGroup?.igAccount?.data?.name ??
        activeSocialGroup?.igAccount?.data?.username;

    return (
      <>
        <Portal>
          <Box
            hidden={!isOpen}
            boxShadow="0 0 20px 4px rgba(154, 161, 177, 0.15),
      0 4px 80px -8px rgba(36, 40, 47, 0.25),
      0 4px 4px -2px rgba(91, 94, 105, 0.15)"
            zIndex="popover"
            bg="white"
            borderRadius="md"
            {...popper}
          >
            {isOpen && (
              <SocialGroupList
                postsTrackedLimit={postsTrackedLimit}
                hasAccessToHashtagTracking={hasAccessToHashtagTracking}
                onSelect={onSelect}
                onNavigateToWorkspace={onClose}
                onNavigateToLinkSocial={onClose}
              />
            )}
          </Box>
        </Portal>
        <FlickPopover
          title={activeSocialGroup ? socialGroupName : `Add ${featureName}`}
          enabled={!showExpandedVersion}
          key="IG Account"
          position="right"
          boundary="window"
          theme="dark"
          arrow={false}
          wrapperProps={{
            width: '100%',
          }}
        >
          <Flex
            as="button"
            justify="space-between"
            alignItems="center"
            w="100%"
            onClick={loading ? undefined : onToggle}
            ref={reference.ref as any}
            cursor={loading ? 'not-allowed' : 'pointer'}
          >
            {isLoadingActiveSocialGroup ? (
              <SkeletonCircle w="40px" h="40px" />
            ) : (
              socialGroupAvatar
            )}
            {showExpandedVersion &&
              (isLoadingActiveSocialGroup ? (
                <SkeletonText w="140px" noOfLines={2} />
              ) : (
                <>
                  <Flex
                    direction="column"
                    justifyContent="center"
                    textAlign="left"
                    lineHeight="1.3"
                    overflow="hidden"
                    marginLeft="12px"
                    grow={1}
                  >
                    <Text
                      color="text.darker"
                      fontSize="16px"
                      fontWeight="medium"
                      ellipsisOverflow
                    >
                      {socialGroupName || featureName}
                    </Text>
                    <Text color="text.light" fontSize="14px" ellipsisOverflow>
                      {activeSocialGroup ? subTitle : 'No Socials linked'}
                    </Text>
                  </Flex>
                  <Icon
                    icon="ChevronUpDown"
                    color="text.light"
                    fontSize="24px"
                  />
                </>
              ))}
          </Flex>
        </FlickPopover>
      </>
    );
  },
);
