import Link from 'next/link';
import React, { PropsWithChildren } from 'react';

import {
  Constants,
  FlickAppType,
  getBaseUrlForApp,
} from '@flick-tech/shared-common';

interface CrossAppNavLinkProps {
  app?: FlickAppType;
  route: string;
  isDisabled?: boolean;
}

const isRoutePartOfApp = (route: string, app: FlickAppType) => {
  if (app === 'learn') {
    return false;
  }

  if (app === 'schedule') {
    return route.includes('schedule') && Constants.isSchedule;
  }

  return Constants.isWeb;
};

export function CrossAppNavLink({
  app = 'web',
  route: routeWithPotentialBasePath,
  children,
  isDisabled,
}: PropsWithChildren<CrossAppNavLinkProps>) {
  const route = routeWithPotentialBasePath
    .replace('/schedule', '')
    .replace('/app', '')
    .replace('/learn', '');

  const canUseNextLink = isRoutePartOfApp(routeWithPotentialBasePath, app);

  if (canUseNextLink && !isDisabled) {
    return (
      <Link href={route} passHref>
        {children}
      </Link>
    );
  }

  if (React.Children.count(children) > 1) {
    throw new Error('You may only specify one child');
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(
            child,
            isDisabled
              ? {
                  cursor: 'not-allowed',
                }
              : {
                  as: 'a',
                  href: `${getBaseUrlForApp(app)}${route}`,
                },
          );
        }

        return child;
      })}
    </>
  );
}
