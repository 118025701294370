import { MutableRefObject, useCallback, useEffect, useState } from 'react';

export function useHover(ref: MutableRefObject<any>): boolean {
  const [value, setValue] = useState(false);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(() => {
    const node = ref.current;

    if (!node)
      return () => {
        // @ts-ignore
      };

    node.addEventListener('mouseenter', handleMouseOver);
    node.addEventListener('mouseleave', handleMouseOut);

    return () => {
      node.removeEventListener('mouseenter', handleMouseOver);
      node.removeEventListener('mouseleave', handleMouseOut);
    };
  }, []);

  return value;
}

export function useHoverFromSelector(selector: string): boolean {
  const [value, setValue] = useState(false);

  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  useEffect(() => {
    const node = document.querySelector(selector);

    if (!node)
      return () => {
        // @ts-ignore
      };

    node.addEventListener('mouseover', handleMouseOver, true);
    node.addEventListener('mouseout', handleMouseOut, true);

    return () => {
      node.removeEventListener('mouseover', handleMouseOver, true);
      node.removeEventListener('mouseout', handleMouseOut, true);
    };
  }, []);

  return value;
}
