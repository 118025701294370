import { Box, BoxProps } from '../layout';

export function LargeLogo(props: BoxProps) {
  return (
    <Box
      as="svg"
      width={140}
      height={40}
      fill="none"
      {...{ viewBox: '0 0 126 36' }}
      {...props}
    >
      <path
        d="M15.011 0h6.005c8.277 0 14.995 6.719 14.979 15.011v6.005c0 8.277-6.719 14.995-15.011 14.979h-6.005C6.702 35.995 0 29.276 0 21v-6.005C.016 6.702 6.735 0 15.011 0z"
        fill="#3174E0"
      />
      <path
        d="M16.034 9.412c-.893.082-1.818.163-2.71.244-.714.065-1.428.081-2.126.26a3.151 3.151 0 00-1.46.795c-1.234 1.233-1.234 3.23-.017 4.446.828.812 2.061.958 3.148 1.185 1.136.26 2.272.52 3.408.747a.147.147 0 00.081.016 3.417 3.417 0 012.564 2.564c0 .016 0 .016.017.016.26 1.152.503 2.272.762 3.424.244 1.088.39 2.354 1.201 3.165a3.169 3.169 0 004.463-.016c1.007-.99.958-2.353 1.071-3.668.098-1.185.211-2.386.309-3.57.097-1.169.21-2.32.308-3.49.049-.535.114-1.054.13-1.59.032-.324.016-.649-.016-.973a4.858 4.858 0 00-.195-.942 4.82 4.82 0 00-2.029-2.564 5.46 5.46 0 00-.99-.454 4.677 4.677 0 00-1.054-.211c-.504-.049-1.007.032-1.494.08M8.796 23.337c-.033-.974.324-1.802 1.055-2.516.714-.714 1.558-1.087 2.515-1.055.974-.016 1.866.406 2.662 1.218.811.81 1.217 1.687 1.25 2.612.032.925-.293 1.737-1.04 2.467-.746.747-1.622 1.136-2.563 1.136-.974.016-1.867-.406-2.662-1.217-.779-.812-1.2-1.688-1.217-2.645z"
        fill="#fff"
      />
      <path
        d="M45.131 31.056h5.537c.497 0 .887-.39.887-.887v-8.164h10.79c.497 0 .887-.39.887-.887v-3.94a.879.879 0 00-.887-.887H51.91a.356.356 0 01-.355-.355v-5.093c0-.195.16-.355.355-.355H63.89c.497 0 .887-.39.887-.887V5.66a.879.879 0 00-.887-.887H45.13a.879.879 0 00-.887.887V30.17c0 .497.39.887.887.887zM67.296 31.056h4.862c.497 0 .888-.39.888-.887V5.609a.879.879 0 00-.888-.888h-4.862a.879.879 0 00-.887.887v24.56c0 .498.39.888.887.888zM76.063 31.056h4.862c.497 0 .887-.39.887-.887V14.375a.879.879 0 00-.887-.888h-4.862a.879.879 0 00-.888.888v15.794c0 .497.39.887.888.887zM92.283 17.356a4.31 4.31 0 012.13-.532c.833 0 1.579.213 2.235.638.48.302.905.728 1.278 1.278a.871.871 0 001.136.284l3.62-1.88a.907.907 0 00.39-1.19c-.656-1.278-1.65-2.29-2.963-3.07-1.58-.923-3.46-1.384-5.661-1.384-2.112 0-3.993.426-5.661 1.26-1.669.834-2.982 2.023-3.922 3.531-.94 1.509-1.42 3.23-1.42 5.146 0 1.9.48 3.603 1.42 5.111.94 1.509 2.254 2.698 3.922 3.55 1.668.852 3.549 1.277 5.66 1.277 2.183 0 4.064-.461 5.644-1.384 1.313-.763 2.307-1.792 2.981-3.07.231-.426.054-.958-.39-1.189l-3.62-1.881a.871.871 0 00-1.136.284c-.373.55-.799.976-1.278 1.278-.656.426-1.402.639-2.236.639a4.31 4.31 0 01-2.13-.533c-.638-.355-1.153-.887-1.526-1.58-.372-.691-.567-1.525-.567-2.502 0-.975.195-1.81.567-2.502.373-.692.888-1.224 1.527-1.58zM119.31 31.056h5.803a.884.884 0 00.692-1.437l-7.365-9.352c-.106-.142-.106-.338.018-.462l6.903-7.08a.53.53 0 00-.372-.906h-6.3a.917.917 0 00-.621.249l-6.069 5.874c-.231.213-.604.053-.604-.249V5.608a.878.878 0 00-.887-.887h-4.863a.878.878 0 00-.887.887v24.56c0 .498.39.888.887.888h4.863c.497 0 .887-.39.887-.887v-3.691a.35.35 0 01.107-.249l1.668-1.686c.142-.16.39-.142.532.036l4.898 6.158c.195.195.444.32.71.32zM75.69 10.914c-.728-.692-1.083-1.561-1.083-2.608 0-1.047.355-1.935 1.083-2.61.71-.727 1.65-1.064 2.822-1.046 1.189 0 2.13.355 2.821.994.71.656 1.047 1.49 1.047 2.555 0 1.1-.355 2.023-1.047 2.715-.71.728-1.65 1.065-2.821 1.047-1.172-.018-2.112-.355-2.822-1.047z"
        fill="#1D1D1D"
      />
    </Box>
  );
}
