import { Axis as NivoAxis } from '@nivo/bar';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { formatNumberShort } from '@flick-tech/shared-formatters';
import { textColorRaw } from '@flick-tech/theme-new';

import { tickBackground } from '../const';

const tickPaddingX = 6;
const tickPaddingY = 4;

type Axis = NivoAxis & {
  useRenderTick?: ReactNode;
};

export const useTickAxis = ({
  offset: { x = 0, y = 0 } = {},
  position = 'left' as 'left' | 'bottom',
  onSizeChange = null as any as (value: {
    width: number;
    height: number;
  }) => void | null,
  format = null as ((value: any) => string) | null,
}): Axis => {
  const maxSize = useRef({ width: 0, height: 0 });
  const sizeChangeTimeout = useRef(-1);

  return {
    useRenderTick: (props) => {
      let { value } = props;
      if (typeof format === 'function') {
        value = format(value);
      }
      const textRef = useRef<SVGTextElement>();
      const [size, setSize] = useState({
        width:
          Math.round(
            (String(props.value).length * 5.33 + tickPaddingX * 2) / 2,
          ) * 2,
        height: 24,
      });
      if (typeof value === 'number') {
        // Remove the empty floating point (10.0k -> 10k)
        value = formatNumberShort(value);
      }

      useEffect(() => {
        if (typeof onSizeChange === 'function' && textRef.current) {
          const box = textRef.current.getBBox();
          const width = Math.round(box.width + tickPaddingX * 2);
          const height = Math.round(box.height + tickPaddingY * 2);
          setSize({
            width,
            height,
          });
          const newSize = { width: width - x, height: height + y };
          if (
            newSize.width > maxSize.current.width ||
            newSize.height > maxSize.current.height
          ) {
            maxSize.current = newSize;
            clearTimeout(sizeChangeTimeout.current);
            sizeChangeTimeout.current = setTimeout(() => {
              onSizeChange(maxSize.current);
              maxSize.current = { width: 0, height: 0 };
            }, 50) as any as number;
          }
        }
      }, [textRef, props.value]);

      let transform = '';
      switch (position) {
        case 'left':
          transform = `translate(${props.x - size.width / 2 + x}, ${
            props.y + y
          })`;
          break;
        case 'bottom':
          const translateY = props.y + size.height / 2 + y;
          transform = `translate(${props.x + x}, ${translateY})`;
          break;
      }

      return (
        <g transform={transform}>
          <rect
            rx={3}
            ry={3}
            y={-size.height / 2}
            x={-size.width / 2}
            width={size.width}
            height={size.height}
            fill={tickBackground}
          />
          <text
            ref={textRef || null}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fill: textColorRaw,
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            {typeof value === 'string' && value.includes('\n')
              ? value.split('\n').map((s, i) => (
                  <tspan x="0" y={-tickPaddingY + i * 12 - 1} key={i}>
                    {s}
                  </tspan>
                ))
              : value}
          </text>
        </g>
      );
    },
  };
};
