import * as React from 'react';

import { AsProp } from '@flick-tech/shared-types';
import { __DEV__, getValidChildren, omit } from '@flick-tech/shared-utils';
import {
  chakra,
  ChakraProps,
  PropsOf,
  SystemProps,
} from '@flick-tech/theme-new';
import { Flex } from '../layout';

import { useImage, UseImageProps } from './Image.hook';

interface ImageOptions {
  /**
   * Fallback image `src` to show if image is loading or image fails.
   *
   * Note 🚨: We recommend you use a local image
   */
  fallbackSrc?: string;
  /**
   * Fallback element to show if image is loading or image fails.
   */
  fallback?: React.ReactElement;
  /**
   * The native HTML `width` attribute to the passed to the `img`
   */
  htmlWidth?: string | number;
  /**
   * The native HTML `height` attribute to the passed to the `img`
   */
  htmlHeight?: string | number;
  /**
   * How the image to fit within it's bounds.
   * It maps to css `object-fit` property.
   */
  fit?: SystemProps['objectFit'];
  /**
   * How to align the image within its bounds.
   * It maps to css `object-position` property.
   */
  align?: SystemProps['objectPosition'];
  /**
   * If `true`, opt out of the `fallbackSrc` logic and use as `img`
   */
  ignoreFallback?: boolean;
}

const StyledImage = chakra.img;

export interface ImageProps
  extends UseImageProps,
    ImageOptions,
    Omit<PropsOf<typeof StyledImage>, keyof ImageOptions | keyof UseImageProps>,
    ChakraProps,
    AsProp {}

/**
 * React component that renders an image with support
 * for fallbacks
 *
 * @see Docs https://chakra-ui.com/image
 */
export const Image = React.forwardRef(
  (props: ImageProps, ref: React.Ref<HTMLImageElement>) => {
    const { fallbackSrc, fallback, src, align, fit, ignoreFallback, ...rest } =
      props;

    const status = useImage(props);

    const shared = {
      ref,
      objectFit: fit,
      objectPosition: align,
      ...(ignoreFallback ? rest : omit(rest, ['onError', 'onLoad'])),
    };

    if (status !== 'loaded') {
      /**
       * If user passed a custom fallback component,
       * let's render it here.
       */
      if (fallback) return fallback;

      return (
        <StyledImage
          data-chakra-image-placeholder=""
          src={fallbackSrc}
          {...shared}
        />
      );
    }

    return <StyledImage src={src} data-chakra-image="" {...shared} />;
  },
);

// LayoutMedia wraps children in Flex, centers and sets style on them
// Therefore it's recommended to pass props as width and height directly to LayoutMedia
// (if children are also passed) as it defines whether the Media is horizontal or vertical
export const LayoutMedia = ({ children = <Image />, ...props }: ImageProps) => {
  const isVertical =
    parseInt(props.height as string) >= parseInt(props.width as string);
  const validChildren = getValidChildren(children);
  return (
    <Flex marginBottom="16px" justify="center" width="100%">
      {validChildren.map((child) =>
        React.cloneElement(child, {
          ...props,
          style: {
            ...(isVertical ? { width: '500px', height: 'auto' } : {}),
            borderRadius: '16px',
            ...props.style,
          },
        }),
      )}
    </Flex>
  );
};

if (__DEV__) {
  Image.displayName = 'Image';
}
