import React, { ForwardedRef, forwardRef } from 'react';

import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  FlickPopover,
  Icon,
  Skeleton,
  SkeletonText,
  Text,
} from '@flick-tech/ui';

import { IWorkspace } from './hooks/useGetMyWorkspaces.generated';

interface WorkspaceSwitcherAvatarProps extends BoxProps {
  activeWorkspace?: Pick<IWorkspace, 'id' | 'name'> | undefined | null;
  showExpandedVersion: boolean;
  avatar: string;
  triggerProps: Partial<FlexProps>;
  isLoading: boolean;
}

const Wrapper = forwardRef(
  (props: FlexProps, ref: ForwardedRef<HTMLDivElement>) => (
    <Flex
      as="button"
      justify="space-between"
      alignItems="center"
      w="100%"
      ref={ref}
      {...props}
    />
  ),
);

export function WorkspaceSwitcherAvatar({
  activeWorkspace,
  showExpandedVersion,
  triggerProps,
  isLoading,
  avatar,
  ...rest
}: WorkspaceSwitcherAvatarProps) {
  if (isLoading) {
    return (
      <WorkspaceSwitcherLoader
        showExpandedVersion={showExpandedVersion}
        {...rest}
      />
    );
  }

  return (
    <Box {...rest}>
      <FlickPopover
        title={activeWorkspace?.name}
        enabled={!showExpandedVersion}
        key="WorkspaceSwitcher"
        position="right"
        boundary="window"
        theme="dark"
        arrow={false}
        wrapperProps={{
          width: '100%',
        }}
      >
        <Wrapper {...triggerProps}>
          <Box
            flexShrink={0}
            w="40px"
            h="40px"
            backgroundSize="cover"
            backgroundImage={avatar}
          />
          {showExpandedVersion && (
            <>
              <Flex
                direction="column"
                justifyContent="center"
                textAlign="left"
                lineHeight="1.3"
                overflow="hidden"
                marginLeft="12px"
                grow={1}
              >
                <Text
                  color="text.darker"
                  fontSize="16px"
                  fontWeight="medium"
                  ellipsisOverflow
                >
                  {activeWorkspace?.name}
                </Text>
                <Text color="text.lighter" fontSize="14px">
                  Workspace
                </Text>
              </Flex>
              <Icon icon="ChevronUpDown" color="text.lighter" fontSize="24px" />
            </>
          )}
        </Wrapper>
      </FlickPopover>
    </Box>
  );
}

export function WorkspaceSwitcherLoader({
  showExpandedVersion,
  ...rest
}: BoxProps & {
  showExpandedVersion: boolean;
}) {
  return (
    <Box {...rest}>
      <Wrapper>
        <Skeleton flexShrink={0} w="40px" h="40px" />
        {showExpandedVersion && <SkeletonText w="140px" noOfLines={2} />}
      </Wrapper>
    </Box>
  );
}
