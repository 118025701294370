import { ComponentTheme } from './utils';

const SpinnerTheme: ComponentTheme = {
  defaultProps: {
    size: 'md',
  },
  sizes: {
    xs: { width: '0.75rem', height: '0.75rem' },
    sm: { width: '1rem', height: '1rem' },
    md: { width: '1.5rem', height: '1.5rem' },
    lg: { width: '2rem', height: '2rem' },
    xl: { width: '3rem', height: '3rem' },
  },
};

export default SpinnerTheme;
