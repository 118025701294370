import { deserialize } from 'superjson';
import { SuperJSONResult } from 'superjson/src/types';

import { Logger } from '@flick-tech/logger';

interface FetchBlitzAPIParams {
  path: string;
  paramsSerialized: SuperJSONResult;
  accessToken: string;
}

const createGenericError = () =>
  new Error(
    'Failed to perform this action. Please try again or contact customer support.',
  );

export async function fetchBlitzAPI<TQueryFnData>({
  path,
  paramsSerialized,
  accessToken,
}: FetchBlitzAPIParams): Promise<TQueryFnData> {
  const params: RequestInit & {
    headers: Record<string, any>;
  } = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      // If Blitz cookies are not valid or initialised, the access token in headers will be used instead
      Authorization: `Bearer ${accessToken}`,
    },
    // Send cookies! Retains the Blitz session
    credentials: 'include',
    body: JSON.stringify({
      params: paramsSerialized.json,
      meta: {
        params: paramsSerialized.meta,
      },
      version: 1,
    }),
  };

  const response = await fetch(path, params);
  let responseText = '';

  try {
    responseText = await response.text();
  } catch (parseError) {
    Logger.error(parseError, {
      path,
      params,
    });
    throw createGenericError();
  }

  if (!response.ok) {
    const error = new Error(response.statusText);
    (error as any).statusCode = response.status;
    Logger.error(error, { path, response, paramsSerialized, responseText });
    throw createGenericError();
  }

  let payload;

  try {
    payload = JSON.parse(responseText);
  } catch (error) {
    Logger.error(error, {
      path,
    });
    throw createGenericError();
  }

  if (payload.error) {
    throw new Error(payload.error.message);
  }

  return deserialize({ json: payload.result, meta: payload.meta?.result });
}
