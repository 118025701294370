import { StringKey } from '@flick-tech/shared-types';

import type { FillGradientConfig } from './AreaFillGradients';

export interface ComputeAreaChartGradientsParams<TDatum extends object> {
  keys: StringKey<TDatum>[];
  data: TDatum[];
  uuid: string;
  colors: string[];
}

export function computeAreaFillGradients<TDatum extends object>({
  keys,
  data,
  uuid,
  colors,
}: ComputeAreaChartGradientsParams<TDatum>): FillGradientConfig<TDatum>[] {
  const averages = keys
    .map((key) => {
      const values = data
        .map((x) =>
          x && Number.isFinite(x[key]) ? (x[key] as any as number) : null,
        )
        .filter((x): x is number => x != null);

      const average =
        values.reduce((a, v) => Number(a) + Number(v), Number(values[0])) /
        values.length;

      return { key, average };
    })
    .sort((a, b) => (a.average < b.average ? 1 : -1));

  const gradients = averages.map(({ key }, i) => {
    const color = colors[keys.indexOf(key)];
    const currentValue = averages[i].average;
    const nextLowerValue = averages[i + 1]?.average || 0;

    return {
      key,
      id: `area-gradient-${key}-${uuid}`,
      color,
      ratio: currentValue / (nextLowerValue + currentValue),
    };
  });

  return gradients;
}
