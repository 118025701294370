// Credit goes to Diego Haz for this one
// https://github.com/reakit/reakit/blob/master/packages/reakit-utils/src/createOnKeyDown.ts

import * as React from 'react';

import { normalizeEventKey } from './dom';
import { runIfFn } from './function';

type ShortcutKeys = 'c' | 's' | 'a' | 'b' | 'x' | 'e' | 'k';

type EventKeys =
  | 'ArrowDown'
  | 'ArrowUp'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'Enter'
  | 'Space'
  | 'Tab'
  | 'Backspace'
  | 'Control'
  | 'Meta'
  | 'Home'
  | 'End'
  | 'PageDown'
  | 'PageUp'
  | 'Delete'
  | 'Escape'
  | ' '
  | 'Shift';

type KeyMapReturn<TKeyboardEvent> = (event: TKeyboardEvent) => any;
type KeyMap<TKeyboardEvent> = Partial<
  Record<EventKeys | ShortcutKeys, KeyMapReturn<TKeyboardEvent>>
>;

export interface CreateOnKeyDownOptions<
  TKeyboardEvent extends
    | React.KeyboardEvent
    | KeyboardEvent = React.KeyboardEvent,
> {
  keyMap?: KeyMap<TKeyboardEvent>;
  onKey?: (event: TKeyboardEvent) => any;
  preventDefault?: boolean | ((event: TKeyboardEvent) => boolean);
  stopPropagation?: boolean | ((event: TKeyboardEvent) => boolean);
  shouldKeyDown?: (event: TKeyboardEvent) => boolean;
}

export function createOnKeyDown<
  TKeyboardEvent extends
    | React.KeyboardEvent
    | KeyboardEvent = React.KeyboardEvent,
>(options: CreateOnKeyDownOptions<TKeyboardEvent>) {
  const {
    keyMap,
    onKey,
    stopPropagation,
    shouldKeyDown = () => true,
    preventDefault = true,
  } = options;

  return (event: TKeyboardEvent) => {
    if (!keyMap) return;

    const finalKeyMap = runIfFn(keyMap, event);
    const shouldPreventDefault = runIfFn(preventDefault, event);
    const shouldStopPropagation = runIfFn(stopPropagation, event);

    const eventKey = normalizeEventKey(event);

    if (eventKey in finalKeyMap) {
      const action = finalKeyMap[eventKey as EventKeys];

      if (typeof action === 'function' && shouldKeyDown(event)) {
        if (shouldPreventDefault) event.preventDefault();
        if (shouldStopPropagation) event.stopPropagation();
        onKey?.(event);
        action(event);
        return;
      }
    }
  };
}

export const isModifierKeyPressed = (
  event: React.KeyboardEvent | KeyboardEvent,
) => {
  const isMac = window.navigator.platform.toUpperCase().includes('MAC');

  return isMac ? event.metaKey : event.ctrlKey;
};

export const targetIsTextField = (
  event: React.KeyboardEvent | KeyboardEvent,
) => {
  const target = event.target as HTMLElement;

  return target.tagName === 'INPUT' || target.tagName === 'TEXTAREA';
};
