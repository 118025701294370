import { Property } from 'csstype';

import { Config, Length, Prop } from '../utils';

export const gridConfig: Config = {
  gap: {
    property: 'gap',
    scale: 'space',
  },
  gridGap: {
    property: 'gridGap',
    scale: 'space',
  },
  gridColumnGap: {
    property: 'gridColumnGap',
    scale: 'space',
  },
  gridRowGap: {
    property: 'gridRowGap',
    scale: 'space',
  },
  gridColumn: true,
  gridRow: true,
  gridAutoFlow: true,
  gridAutoColumns: true,
  gridAutoRows: true,
  gridTemplateColumns: true,
  gridTemplateRows: true,
  gridTemplateAreas: true,
  gridArea: true,
  placeItems: true,
};

/**
 * Types for grid related CSS properties
 */
export interface GridProps {
  /**
   * The CSS `gap` property
   */
  gap?: Prop<Property.Gap<Length>>;
  /**
   * The CSS `grid-gap` property
   */
  gridGap?: Prop<Property.GridGap<Length>>;
  /**
   * The CSS `grid-column-gap` property
   */
  gridColumnGap?: Prop<Property.GridColumnGap<Length>>;
  /**
   * The CSS `grid-row-gap` property
   */
  gridRowGap?: Prop<Property.GridRowGap<Length>>;
  /**
   * The CSS `grid-column` property
   */
  gridColumn?: Prop<Property.GridColumn>;
  /**
   * The CSS `grid-row` property
   */
  gridRow?: Prop<Property.GridRow>;
  /**
   * The CSS `grid-auto-flow` property
   */
  gridAutoFlow?: Prop<Property.GridAutoFlow>;
  /**
   * The CSS `grid-auto-columns` property
   */
  gridAutoColumns?: Prop<Property.GridAutoColumns<Length>>;
  /**
   * The CSS `grid-auto-rows` property
   */
  gridAutoRows?: Prop<Property.GridAutoRows<Length>>;
  /**
   * The CSS `grid-template-columns` property
   */
  gridTemplateColumns?: Prop<Property.GridTemplateColumns<Length>>;
  /**
   * The CSS `grid-template-rows` property
   */
  gridTemplateRows?: Prop<Property.GridTemplateRows<Length>>;
  /**
   * The CSS `grid-template-areas` property
   */
  gridTemplateAreas?: Prop<Property.GridTemplateAreas>;
  /**
   * The CSS `grid-areas` property
   */
  gridArea?: Prop<Property.GridArea>;
  /**
   * The CSS `place-items` property
   */
  placeItems?: Prop<Property.PlaceItems>;
}
