import { isRouteActive } from '@flick-tech/shared-common';
import { useNavigate, usePathname } from '@flick-tech/shared-hooks';
import { Box, BoxProps, Link } from '@flick-tech/ui';
import { LargeLogo, Logo } from '@flick-tech/ui';

const smallLogoStyles: BoxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  fontSize: '14px',
  fontWeight: 'semibold',
  letterSpacing: '0.1px',

  width: '80px',
  overflow: 'hidden',

  sx: {
    ':hover, [aria-current="page"]': {
      svg: {
        color: '#3174e0',
      },
    },
  },
};

interface FlickLogoRouteProps {
  size: 'small' | 'large';
}
function FlickLogoRoute({ size }: FlickLogoRouteProps) {
  const pathname = usePathname();
  const navigate = useNavigate();

  const ariaCurrent = isRouteActive('/home', pathname) ? 'page' : false;

  return (
    <Link
      as="a"
      href="/home"
      aria-current={ariaCurrent}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate('/home');
      }}
      _focus={{}}
      _focusVisible={{
        bgColor: 'bg.base',
      }}
    >
      {size === 'small' ? (
        <Box {...smallLogoStyles}>
          <Logo w="40px" h="40px" />
        </Box>
      ) : (
        <LargeLogo w="140px" h="40px" />
      )}
    </Link>
  );
}

export default FlickLogoRoute;
