import { ROUTES } from '@flick-tech/shared-common';
import { useNavigate } from '@flick-tech/shared-hooks';

import { Button } from '../../button';
import { Flex, Heading, Text } from '../../layout';
import { useToast } from '../../toast';
import { useMutationHook } from '../../useMutationHook';

import { useInitialiseFirstWorkspaceForUserMutation } from './useInitialiseFirstWorkspaceForUser.generated';

interface RequiresWorkspaceEmptyStateProps {
  onCreateNewWorkspace?: () => Promise<void>;
}

export function RequiresWorkspaceEmptyState({
  onCreateNewWorkspace,
}: RequiresWorkspaceEmptyStateProps) {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync } = useInitialiseFirstWorkspaceForUserMutation();

  const { handler: createNewWorkspace, isLoading } = useMutationHook({
    func: async () => {
      await mutateAsync({});

      toast({
        title: 'Please complete registration to use Flick',
        status: 'warning',
        isClosable: true,
      });

      if (onCreateNewWorkspace) {
        await onCreateNewWorkspace();
      }

      await navigate(ROUTES.Register.Base);
    },
  });

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      h="100%"
    >
      <Heading
        variant="h1"
        fontSize="28px"
        letterSpacing="0.005em"
        lineHeight="34px"
      >
        Access to this workspace has been lost.
      </Heading>
      <Text
        color="text.light"
        fontSize="22px"
        letterSpacing="0.005em"
        textAlign="center"
        lineHeight="27px"
        m="0"
      >
        Either you’ve been removed from the workspace, or <br /> it no longer
        exists.
      </Text>
      <Button
        marginTop="16px"
        size="lg"
        onClick={createNewWorkspace}
        isLoading={isLoading}
      >
        Create New Workspace
      </Button>
    </Flex>
  );
}
