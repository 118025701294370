import { DetailedHTMLProps, useEffect, useRef } from 'react';

import { useWindowSize } from './useWindowSize';

// 100vh is broken on mobile (Chrome, Safari):
// https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html

export function use100vh({
  isDisabled = false,
}: {
  isDisabled?: boolean;
} = {}) {
  const ref = useRef<DetailedHTMLProps<any, any>>();
  const { height } = useWindowSize();

  useEffect(() => {
    // Disable on desktop
    if (!ref.current || isDisabled) {
      return;
    }

    ref.current.style.height = height + 'px';
  }, [isDisabled, height]);

  return ref;
}
