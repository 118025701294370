import { useHasFeatureFlag } from '@flick-tech/shared-hooks';
import { Avatar, Box, FBAvatarBadge, IGAvatarBadge } from '@flick-tech/ui';

import { ErrorIconSvg } from './ErrorSvg';
import { PlusIcon } from './PlusIcon';

interface SocialAvatarProperties {
  lostConnection?: boolean;
  username?: string;
  profilePicture?: string;
}

interface SocialGroupsSummaryCardAvatarsProps {
  igAccount?: SocialAvatarProperties;
  facebook?: SocialAvatarProperties;
}

const getAvatarProps = (social?: SocialAvatarProperties) =>
  social
    ? {
        name: social?.username,
        src: social?.profilePicture,
      }
    : {
        borderColor: 'gray.200',
        showBorder: true,
        borderStyle: 'solid',
        borderWidth: '1px',
        backgroundColor: 'gray.100',
        icon: <PlusIcon />,
      };

export function SocialGroupsSummaryCardAvatars({
  igAccount,
  facebook,
}: SocialGroupsSummaryCardAvatarsProps) {
  const hasAccessToSocialGroups = useHasFeatureFlag('socialGroups');

  return (
    <Box
      position="relative"
      bg="bg.light"
      h="48px"
      borderRadius="24px"
      borderColor="bg.base"
      display="flex"
      flexDir="row"
    >
      <Avatar
        w="46px"
        h="46px"
        zIndex={2}
        mr={hasAccessToSocialGroups ? -3 : 0}
        {...getAvatarProps(igAccount)}
      >
        <IGAvatarBadge size={24} />
      </Avatar>
      {igAccount?.lostConnection ? (
        <Box
          top="0"
          left="0"
          w={5}
          h={5}
          borderRadius="10px"
          position="absolute"
          bg="white"
          zIndex={2}
        >
          <ErrorIconSvg fill="#E53E3E" w="21px" h="21px" />
        </Box>
      ) : null}
      {hasAccessToSocialGroups && (
        <Avatar w="46px" h="46px" zIndex={1} {...getAvatarProps(facebook)}>
          <FBAvatarBadge size={24} />
        </Avatar>
      )}
    </Box>
  );
}
