import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import ReactAutosuggest from 'react-autosuggest';

import { SystemProps } from '@flick-tech/theme-new';
import { Text } from '../layout';

import {
  SearchInput,
  SearchInputProps,
  SuggestionsContainer,
} from './components';
import {
  OnSubmit,
  Suggestion,
  SuggestionsFetchRequestedParams,
  SuggestionsSection,
} from './public-types';

const Wrapper = styled.div<{ maxWidth?: string | number }>`
  flex: 1;
  ${(props) => ({ maxWidth: props.maxWidth })}

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
  }

  .react-autosuggest__suggestion {
    display: flex;
    max-width: 100%;
  }
`;

function getSuggestionValue(suggestion: Suggestion): string {
  return suggestion.value;
}

function defaultRenderSuggestionsContainer(
  props: ReactAutosuggest.RenderSuggestionsContainerParams,
) {
  return <SuggestionsContainer {...props} />;
}

function defaultShouldRenderSuggestions(value = '') {
  return value.trim().length > 2;
}

function getSectionSuggestions<T extends Suggestion>(
  section: SuggestionsSection<T>,
) {
  return section.suggestions;
}

function loopingIncrement(x: number, direction: -1 | 1, n: number) {
  return (n + x + direction) % n;
}

const renderSectionTitle = (
  section: SuggestionsSection<Suggestion>,
): React.ReactNode => {
  return (
    <Text
      fontSize="lg"
      fontWeight="medium"
      color="blackAlpha.500"
      letterSpacing="0.02em"
      py="1"
      px="2"
    >
      {section.title}
    </Text>
  );
};

export interface AutoSuggestProps<T extends Suggestion>
  extends Pick<SystemProps, 'minWidth' | 'width' | 'flex'>,
    Pick<
      ReactAutosuggest.AutosuggestProps<T, unknown>,
      | 'renderSuggestion'
      | 'shouldRenderSuggestions'
      | 'alwaysRenderSuggestions'
      | 'renderSuggestionsContainer'
    >,
    Pick<SearchInputProps, 'iconPosition'> {
  onSubmit: OnSubmit<T>;
  onSuggestionsFetchRequested: (
    params: SuggestionsFetchRequestedParams,
  ) => Promise<SuggestionsSection<T>[]>;
  submitLoading?: boolean;
  clearOnBlur?: boolean;
  trapFocus?: boolean;
  placeholder?: string;
  maxWidth?: string | number;
}

export const AutoSuggest = function AutoSuggest<T extends Suggestion>({
  onSubmit,
  submitLoading,
  onSuggestionsFetchRequested,
  renderSuggestion,
  alwaysRenderSuggestions,
  shouldRenderSuggestions = defaultShouldRenderSuggestions,
  renderSuggestionsContainer = defaultRenderSuggestionsContainer,
  clearOnBlur,
  trapFocus,
  placeholder = 'Search a hashtag or topic',
  iconPosition,
  ...rest
}: AutoSuggestProps<T>) {
  const [inputText, setInputText] = useState('');
  const [sections, setSections] = useState<SuggestionsSection<T>[]>([]);
  const autosuggest = useRef<ReactAutosuggest>(null);

  const handleSubmit = useCallback(
    (value: Parameters<typeof onSubmit>[0] = { value: inputText }) => {
      const success = onSubmit(value);

      if (success) {
        setInputText('');
        setSections([]);
      }
    },
    [inputText, onSubmit],
  );

  const fetchSuggestions = useMemo(
    () =>
      debounce(
        async (arg: ReactAutosuggest.SuggestionsFetchRequestedParams) => {
          if (
            arg.reason === 'suggestions-revealed' ||
            arg.reason === 'suggestion-selected' ||
            arg.reason === 'escape-pressed'
          ) {
            return [];
          }

          if (!arg.value && !alwaysRenderSuggestions) return;

          const suggestions = await onSuggestionsFetchRequested(arg);

          setSections(
            suggestions.filter((section) => section.suggestions.length),
          );
        },
        250,
        { leading: true, trailing: true },
      ),
    [onSuggestionsFetchRequested, alwaysRenderSuggestions],
  );

  const onChange = useCallback(
    (_event, { newValue }) => setInputText(newValue),
    [],
  );
  const resetSuggestions = useCallback(() => setSections([]), []);

  const onBlur = useCallback(() => {
    if (clearOnBlur) {
      setInputText('');
    }
  }, [clearOnBlur]);

  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Tab':
        if (
          trapFocus &&
          (shouldRenderSuggestions(inputText) || alwaysRenderSuggestions)
        ) {
          event.preventDefault();
          const step = event.shiftKey ? -1 : 1;

          autosuggest.current?.setState((s) => {
            const { highlightedSectionIndex, highlightedSuggestionIndex } = s;
            const sectionsLength = sections.length;

            if (
              highlightedSectionIndex == null ||
              highlightedSuggestionIndex == null
            ) {
              if (step === -1) {
                return {
                  highlightedSectionIndex: sectionsLength - 1,
                  highlightedSuggestionIndex:
                    sections[sectionsLength - 1].suggestions.length - 1,
                };
              }
              return {
                highlightedSectionIndex: 0,
                highlightedSuggestionIndex: 0,
              };
            }

            const highlightedSectionLength =
              sections[highlightedSectionIndex].suggestions.length;

            if (step === -1 && highlightedSuggestionIndex === 0) {
              const newSectionIndex = loopingIncrement(
                highlightedSectionIndex,
                -1,
                sectionsLength,
              );

              return {
                highlightedSectionIndex: newSectionIndex,
                highlightedSuggestionIndex:
                  sections[newSectionIndex].suggestions.length - 1,
              };
            }

            if (
              step === 1 &&
              highlightedSuggestionIndex === highlightedSectionLength - 1
            ) {
              return {
                highlightedSectionIndex: loopingIncrement(
                  highlightedSectionIndex,
                  1,
                  sectionsLength,
                ),
                highlightedSuggestionIndex: 0,
              };
            }

            return {
              highlightedSectionIndex,
              highlightedSuggestionIndex:
                ((s.highlightedSuggestionIndex == null
                  ? -1
                  : s.highlightedSuggestionIndex) +
                  step) %
                highlightedSectionLength,
            };
          });
        }
        return;
      case 'Escape': {
        autosuggest.current?.setState({
          isFocused: false,
          isCollapsed: true,
        });
        const activeElement = document.activeElement;
        if (activeElement?.tagName === 'INPUT') {
          (activeElement as HTMLInputElement).blur();
        }
        onBlur();
        return;
      }
      case 'Enter':
        handleSubmit();
        return;
      default:
    }
  };

  const onClickIcon = useCallback(() => handleSubmit(), [handleSubmit]);

  const onSuggestionSelected: ReactAutosuggest.OnSuggestionSelected<T> =
    useCallback(
      (_event, { suggestionValue, suggestion }) =>
        handleSubmit({ value: suggestionValue, suggestion }),
      [handleSubmit],
    );

  return (
    <Wrapper maxWidth={rest.maxWidth}>
      <ReactAutosuggest<T, SuggestionsSection<T>>
        ref={autosuggest}
        multiSection
        getSectionSuggestions={getSectionSuggestions}
        suggestions={sections}
        onSuggestionsFetchRequested={fetchSuggestions}
        onSuggestionsClearRequested={resetSuggestions}
        getSuggestionValue={getSuggestionValue}
        shouldRenderSuggestions={shouldRenderSuggestions}
        renderSectionTitle={renderSectionTitle}
        inputProps={{
          placeholder: placeholder,
          value: inputText,
          onChange,
          autoCorrect: 'off',
          autoCapitalize: 'off',
          onKeyDown,
          onBlur,
        }}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestionsContainer={renderSuggestionsContainer}
        focusInputOnSuggestionClick={false}
        renderSuggestion={renderSuggestion}
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        renderInputComponent={(props) => (
          <SearchInput
            large
            minWidth={300}
            onClickIcon={onClickIcon}
            loading={submitLoading}
            data-intercom-id="search--search-input"
            iconPosition={iconPosition}
            {...props}
            // we could fix either react-autosuggest props or adapt our SearchInput
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            onChange={props.onChange as any}
            {...rest as any}
          />
        )}
      />
    </Wrapper>
  );
};
