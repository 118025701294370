import { useHasFeatureFlag } from '@flick-tech/shared-hooks';
import { Box, Button, Flex, Heading, Icon, Stack, Text } from '@flick-tech/ui';

export const SOCIAL_ACCOUNTS_LIST_WIDTH = 304;

export interface SocialGroupListEmptyStateProps {
  onLinkingSocials: () => void;
  renderBottomContent: () => React.ReactNode;
  numberOfSocialGroups?: number;
}

export const PERKS_LIST = [
  'Track hashtag performance',
  'Create and Schedule posts',
  'Store and manage hashtags',
  'See analytics & insights',
  'See best times to post',
];

export const SocialGroupListEmptyState = ({
  onLinkingSocials,
  renderBottomContent,
  numberOfSocialGroups = 0,
}: SocialGroupListEmptyStateProps) => {
  const hasAccessToSocialGroups = useHasFeatureFlag('socialGroups');

  return (
    <Stack
      p="12px"
      maxH="443px"
      minW={SOCIAL_ACCOUNTS_LIST_WIDTH}
      overflowY="auto"
      data-testid="layout--ig-switcher--list"
    >
      <Box>
        <Flex
          w="100%"
          justifyContent="space-between"
          marginBottom="1rem"
          alignItems="center"
        >
          <Heading
            paddingY="0px"
            paddingX="0px"
            variant="h4"
            fontSize="18px"
            fontWeight="medium"
          >
            {hasAccessToSocialGroups ? 'Social Groups' : 'IG Accounts'}
          </Heading>
          <Text
            fontSize="18px"
            fontWeight="medium"
            as="div"
            color="text.lighter"
          >
            {numberOfSocialGroups}
          </Text>
        </Flex>
        <Box marginBottom="12px" width="280px">
          <Text size="18px" lineHeight="23px" color="#858585">
            Save time and improve results by linking your Socials to Flick.
          </Text>
        </Box>
        <Box
          backgroundColor="bg.lighter"
          p="15px"
          marginTop="12px"
          borderRadius="8px"
        >
          {PERKS_LIST.map((perkText, i) => (
            <Flex
              key={perkText}
              marginTop={i > 0 ? '16px' : ''}
              alignItems="center"
              data-testid="account-perk-card"
            >
              <Icon icon="DoneRounded" color="blue.500" fontSize="20px" />
              <Text color="text.dark" as="span" marginLeft="10px">
                {perkText}
              </Text>
            </Flex>
          ))}
        </Box>
      </Box>
      <Button
        flex={1}
        width="100%"
        loadingText="Remove"
        leftIcon="LinkRounded"
        fontWeight="medium"
        backgroundColor="#3174E0"
        lineHeight={1.3}
        fontSize="md"
        textColor="text.white"
        border="1px solid #EBEBEB"
        whiteSpace="normal"
        onClick={onLinkingSocials}
      >
        Start Linking Accounts
      </Button>
      {renderBottomContent}
    </Stack>
  );
};
