import { AsProp, PropsOf } from '@flick-tech/shared-types';
import { __DEV__ } from '@flick-tech/shared-utils';
import { chakra, ChakraProps } from '@flick-tech/theme-new';

export interface TextProps
  extends Omit<PropsOf<'p'>, 'color'>,
    AsProp,
    ChakraProps {
  ellipsisOverflow?: boolean;
}

/**
 * Used to render texts or paragraphs.
 *
 * @see Docs https://chakra-ui.com/text
 */
const Wrapper = chakra('p', {
  baseStyle: {
    fontSize: 'md',
  },
  themeKey: 'Text',
});

export const Text = ({ ellipsisOverflow, ...props }: TextProps) => {
  const additionalProps: Partial<TextProps> = {};

  if (ellipsisOverflow) {
    additionalProps.overflow = 'hidden';
    additionalProps.textOverflow = 'ellipsis';
    additionalProps.whiteSpace = 'nowrap';
  }

  return <Wrapper {...additionalProps} {...props} />;
};

if (__DEV__) {
  Text.displayName = 'Text';
}
