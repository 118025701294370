import { LoggedInUser, useGetUser } from './useGetUser';

export const hasCompletedRegistration = (user: LoggedInUser | undefined) => {
  if (!user) return false;

  const hasCompletedRegistration = user.hasCompletedRegistration;

  const userHasWorkspaces = user.activeWorkspace && user.numberOfWorkspaces > 0;

  /**
   * We should count users without workspaces/active-workspace as registered
   * In this case, we will display an empty state for workspace
   * and ask the user to complete the billing registration (workspace for this user will be created too)
   */
  const shouldSkipRegistrationStep = !userHasWorkspaces;

  return hasCompletedRegistration || shouldSkipRegistrationStep;
};

export function useHasCompletedRegistration() {
  const { user, loading, refetch } = useGetUser();

  return {
    hasCompletedRegistration: hasCompletedRegistration(user),
    loading,
    refetch,
  };
}
