import {
  AuthTokens,
  getAccessTokensFromCookies,
} from '@flick-tech/shared-common';

import { graphqlFetch } from './graphql-fetch';

const mutation = `
  mutation RefreshAccessToken($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

export async function refreshAccessToken(): Promise<AuthTokens> {
  const tokens = getAccessTokensFromCookies();

  if (!tokens?.refreshToken) {
    return undefined;
  }

  const data = await graphqlFetch<
    {
      refresh: {
        token: AuthTokens;
      };
    },
    {
      refreshToken: string;
    }
  >({
    query: mutation,
    variables: { refreshToken: tokens.refreshToken },
  });

  return data.refresh.token;
}
