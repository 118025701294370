import { isRunningOnServerSide } from '@flick-tech/shared-common';

export const fade = (hex: string, opacity: number) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
};

let context: CanvasRenderingContext2D;

if (!isRunningOnServerSide()) {
  context = document.createElement('canvas').getContext('2d');
  context.font = `14px Inter`;
} else {
  context = new Proxy<CanvasRenderingContext2D>({} as any, {
    get(_target, _property) {
      throw new Error('rendering context cannot be used in Node');
    },
  });
}

export const getTickTextWidth = (text: string) =>
  context.measureText(text).width;
