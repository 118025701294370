/* eslint-disable @typescript-eslint/no-var-requires */

import { isRunningOnServerSide } from '@flick-tech/shared-common';

import { ILogger } from './logger.types';

export * from './logger.shared';

// The tsc compiler doesn't follow the imports below, so we need to make sure it does with an import
// If anyone knows how to do this without this if statement, pls let me know!
if (process.env.IS_BUILD) {
  import('./logger.server');
  import('./logger.client');
}

export const Logger: ILogger = isRunningOnServerSide()
  ? require('./logger.server').default
  : require('./logger.client').default;
