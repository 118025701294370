import { CSSProperties } from 'react';

import { Stack, StackProps } from '../../layout';
import { SlideFade } from '../../transition';

import { __FloatingActionsContext as FloatingActionsContext } from '../FloatingActionsContext';
import type {
  __DisclosureState as DisclosureState,
  FloatingActionsState,
} from '../useFloatingActionsDisclosure';

export interface ActionsContainerProps
  extends Omit<StackProps, 'children' | 'onAnimationEnd'>,
    FloatingActionsState {
  toggleVisible: boolean;
  renderDisclosure: (style: CSSProperties) => JSX.Element;
  renderToggle: (style: CSSProperties) => JSX.Element;
  onAnimationEnd?: (state: DisclosureState) => void;
}
export function ActionsContainer({
  toggleVisible,
  renderDisclosure,
  renderToggle,
  onAnimationEnd,

  state,
  dispatch,
  isOpen,
  onClose,
  onToggle,

  ...rest
}: ActionsContainerProps) {
  const handleAnimationEnd = () => {
    dispatch('animation-end');
    onAnimationEnd?.(state);
  };

  return (
    <Stack
      w="100%"
      position="fixed"
      bottom={6}
      left={0}
      justifyContent="center"
      spacing={3}
      zIndex="popover"
      pointerEvents="none"
      {...rest}
    >
      <FloatingActionsContext.Provider value={{ isOpen, onClose, onToggle }}>
        <SlideFade
          in={toggleVisible && isOpen}
          onEntered={handleAnimationEnd}
          onExited={handleAnimationEnd}
        >
          {renderDisclosure}
        </SlideFade>
        <SlideFade in={toggleVisible}>{renderToggle}</SlideFade>
      </FloatingActionsContext.Provider>
    </Stack>
  );
}
