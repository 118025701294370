import {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';

import { __DEV__, dataAttr, Dict, merge } from '@flick-tech/shared-utils';
import {
  chakra,
  forwardRef,
  PropsOf,
  pseudoSelectors,
  SystemProps,
  ThemeProps,
  useComponentStyle,
} from '@flick-tech/theme-new';

import { Icon, IconName } from '../icon';
import { BoxProps, Center, CenterProps, Flex } from '../layout';
import { Spinner } from '../spinner';

import { useButtonGroup } from './ButtonGroup';

const StyledButton = chakra('button', {
  themeKey: 'Button',
  baseStyle: {
    display: 'inline-flex',
    appearance: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 250ms',
    userSelect: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    maxWidth: '100%',
  },
});

export interface ButtonOptions {
  /**
   * If `true`, the button will show a spinner.
   */
  isLoading?: boolean;
  /**
   * If `true`, the button will be styled in it's active state.
   */
  isActive?: boolean;
  /**
   * If `true`, the button will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, the button will be styled in it's focus state.
   */
  isFocused?: boolean;
  /**
   * The label to show in the button when `isLoading` is true
   * If no text is passed, it only shows the spinner
   */
  loadingText?: string;
  /**
   * If `true`, the button will take up the full width of its container.
   */
  isFullWidth?: boolean;
  /**
   * The html button type to use.
   */
  type?: 'button' | 'reset' | 'submit';
  /**
   * If added, the button will show an icon before the button's label.
   */
  leftIcon?: IconName | ReactNode;
  /**
   * If added, the button will show an icon after the button's label.
   */
  rightIcon?: IconName | ReactNode;
  /**
   * The space between the button icon and label.
   */
  // iconSpacing?: SystemProps['marginRight'];
  iconSpacing?: string;
  /**
   * Replace the spinner component when `isLoading` is set to `true`
   */
  spinner?: ReactElement;

  variant?: ThemeProps['Button']['variant'];

  size?: 'xs' | 'sm' | 'md' | 'lg';

  hasError?: boolean;

  disabledTextOverflow?: boolean;

  isIconButton?: boolean;
}

export type ButtonProps = Omit<PropsOf<typeof StyledButton>, 'disabled'> &
  ButtonOptions;

type ButtonIconRendererProps = BoxProps & {
  icon: ButtonProps['leftIcon'];
  size: ButtonProps['size'] | string;
};

const getIconSize = (size: ButtonIconRendererProps['size']): string => {
  switch (size) {
    case 'lg':
      return '1.5em';
    case 'md':
      return '1.25em';
    case 'sm':
      return '1em';
    case 'xs':
      return '0.75em';
    default:
      return '1.25em';
  }
};

export const ButtonIconRenderer = ({
  icon,
  size,
  ...rest
}: ButtonIconRendererProps) => {
  if (!icon) return <></>;

  if (typeof icon === 'string') {
    return (
      <Icon icon={icon as IconName} boxSize={getIconSize(size)} {...rest} />
    );
  }

  return (
    <Flex align="center" {...rest}>
      {icon}
    </Flex>
  );
};

type Omitted = 'disabled';

export const Button = forwardRef<ButtonProps, 'button', Omitted>(
  (props: ButtonProps, ref: Ref<any>) => {
    const group = useButtonGroup();

    const {
      isDisabled,
      isLoading,
      isActive,
      isFullWidth,
      isFocused,
      children,
      leftIcon,
      rightIcon,
      loadingText,
      iconSpacing = '0.3rem',
      type = 'button',
      spinner,
      size = group?.size ?? 'md',
      hasError,
      disabledTextOverflow,
      isIconButton,
      ...rest
    } = props;

    let variant = props.variant ?? group?.variant;

    let { colorScheme = group?.colorScheme } = rest;

    if (hasError) {
      colorScheme = 'red';
    }

    // Create "gray" secondary flick button
    if (!variant && colorScheme === 'gray') {
      variant = 'light';
    }

    const styles = useComponentStyle({
      themeKey: 'Button',
      variant,
      size,
      colorScheme,
    }) as Dict;

    /**
     * When button is used within ButtonGroup (i.e flushed with sibling buttons),
     * it's important to add a `zIndex` when it's focused to it doesn't look funky.
     *
     * So let's read the component styles and then add `zIndex` to it.
     */
    const focusSelector = pseudoSelectors['_focus'];
    const _focus = merge(styles?.[focusSelector] ?? {}, { zIndex: 1 });

    return (
      <StyledButton
        disabled={isDisabled || isLoading}
        ref={ref}
        type={type}
        width={isFullWidth ? '100%' : undefined}
        data-active={dataAttr(isActive)}
        data-loading={dataAttr(isLoading)}
        data-disabled={dataAttr(isDisabled)}
        data-focus={dataAttr(isFocused)}
        variant={variant}
        size={size}
        {...(!!group && { _focus })}
        {...rest}
        colorScheme={colorScheme}
      >
        {leftIcon && !isLoading && (
          <ButtonIconRenderer
            icon={leftIcon}
            ml="-2px"
            mr={iconSpacing}
            size={size}
          />
        )}
        {isLoading && (
          <ButtonSpinner
            spacing={iconSpacing}
            label={loadingText}
            children={spinner}
            isIconButton={isIconButton}
          />
        )}
        {isLoading ? (
          loadingText || <chakra.span opacity={0} children={children} />
        ) : disabledTextOverflow ? (
          <>{children}</>
        ) : (
          <chakra.span
            display="flex"
            alignItems="center"
            overflow={disabledTextOverflow ? 'visible' : 'hidden'}
            textOverflow={disabledTextOverflow ? 'initial' : 'ellipsis'}
          >
            {children}
          </chakra.span>
        )}
        {rightIcon && !isLoading && (
          <ButtonIconRenderer size={size} icon={rightIcon} ml={iconSpacing} />
        )}
      </StyledButton>
    );
  },
);

if (__DEV__) {
  Button.displayName = 'Button';
}

const ButtonIcon = (props: CenterProps) => {
  const a11yProps = {
    'aria-hidden': true,
    focusable: false,
  };

  const children = isValidElement(props.children)
    ? cloneElement(props.children, a11yProps)
    : props.children;

  return <Center {...props}>{children}</Center>;
};

if (__DEV__) {
  ButtonIcon.displayName = 'ButtonIcon';
}

type ButtonSpinnerProps = PropsOf<typeof chakra.div> & {
  label?: string;
  spacing?: SystemProps['margin'];
  isIconButton?: boolean;
};

const ButtonSpinner = (props: ButtonSpinnerProps) => {
  const { label, spacing, children, isIconButton, ...rest } = props;

  const spinnerSize = isIconButton ? '0.75em' : '1em';

  return (
    <Center
      fontSize="1em"
      lineHeight="normal"
      position={label ? 'relative' : 'absolute'}
      mr={label ? spacing : 0}
      {...rest}
      children={
        children ?? (
          <Spinner
            color="currentColor"
            width={spinnerSize}
            height={spinnerSize}
          />
        )
      }
    />
  );
};

if (__DEV__) {
  ButtonSpinner.displayName = 'ButtonSpinner';
}
