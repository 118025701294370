// eslint-disable-next-line prefer-destructuring
export const NODE_ENV: string = process.env.NODE_ENV!;

const isDev = NODE_ENV === 'development' || NODE_ENV === 'storybook';
const isProd = NODE_ENV === 'production';
const isTest = NODE_ENV === 'test';

const isWindowDefined = typeof window !== 'undefined';

const isCypress =
  process.env.NEXT_PUBLIC_CYPRESS === 'true' ||
  (isWindowDefined && !!(window as any).Cypress);

export type FlickAppType = 'web' | 'learn' | 'schedule';

const flickAppID = (process.env.NEXT_PUBLIC_FLICK_APP ||
  process.env.APP_NAME) as FlickAppType | undefined;

const isLearn = flickAppID === 'learn';
const isSchedule = flickAppID === 'schedule';
const isWeb = flickAppID === 'web';

const isBuild = process.env.IS_BUILD;

// change this to `development` to use content written by developers for testing
const contentfulDevEnv: 'development' | 'production' = 'production';
const contentfulEnv = isDev ? contentfulDevEnv : 'production';

const isVercel =
  (isWindowDefined && window.location.hostname.includes('.vercel.app')) ||
  process.env.NEXT_PUBLIC_VERCEL;

const getOneSignalAppId = () => {
  if (isStaging) {
    return '66058243-0dc5-4f46-8506-387f8434e293';
  }

  return isDev
    ? '8f476f09-241d-4be3-9654-cc7a3a4df691'
    : '5f90a7be-3fcf-4d5f-b446-e7e7178220d6';
};

const basePath = process.env.NEXT_PUBLIC_BASE_PATH ?? '';
const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV as
  | 'production'
  | 'preview'
  | 'development'
  | undefined;
const vercelUrl = process.env.NEXT_PUBLIC_VERCEL_URL;

const isStaging =
  vercelEnv === 'preview' ||
  process.env.APP_ENV === 'staging' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'staging';

const isProdNotStaging = isProd && !isStaging;

const showContentfulDrafts = isDev || isStaging || isVercel;

const useLocalBackend = process.env.NEXT_PUBLIC_USE_LOCAL_BACKEND === 'true';

const getNewApiBase = () => {
  if ((isDev || isCypress) && process.env.NEXT_PUBLIC_GRAPHQL_API_BASE) {
    return process.env.NEXT_PUBLIC_GRAPHQL_API_BASE;
  }

  if (useLocalBackend) {
    return `https://localhost:${process.env.NEXT_PUBLIC_PORT ?? 4005}`;
  }

  return 'https://api.flick.tech';
};

const getScheduleBase = () => {
  const path = '/schedule';

  if (isDev) {
    return `https://localhost:${process.env.NEXT_PUBLIC_PORT ?? 4005}${path}`;
  }

  return `https://www.flick.tech${path}`;
};

export const Constants = {
  isLearn,
  isDev,
  isVercel,
  isProd,
  isProdNotStaging,
  isCypress,
  isBuild,
  isTest,
  isWeb,
  isStaging,
  popoverZIndex: 9999,
  stripePublicKey: isDev
    ? 'pk_test_DPOyZdHm0NfioRHVYHFSm5cE00DiGSykX1'
    : 'pk_live_2DqG7KLlmixNrSFyf1azyJLd00nVfLrIoT',
  braintreeClientAuthorization: isDev
    ? 'sandbox_zjnsy68p_srt9ggts9b65dckn'
    : 'production_tv4tr3vt_ywhkp3hgknhhsj6w',
  fbAppId: '2268541379864758',
  nodeEnv: NODE_ENV,
  apiBase: getNewApiBase(),
  scheduleBase: getScheduleBase(),
  wpEndpoint: 'https://blogflick.wpcomstaging.com/graphql',
  wpEndpointBase: 'https://blogflick.wpcomstaging.com/',
  contentfulContentApiUrl: `https://graphql.contentful.com/content/v1/spaces/hcs4a57l4fqo/environments/${contentfulEnv}`,
  contentfulAuthorizationHeader: showContentfulDrafts
    ? 'Bearer q3y8YRrj_jfh-Juzw-cB7o8iuO4E3o44ceRaXLGaRRM'
    : 'Bearer 0k8A4zjN_pNP4iUrXQCHuu6hAkuqyfimRM6ZDUoh684',
  showContentfulDrafts,
  defaultColorTheme: 'brand',
  chargebee: {
    publicKey: isDev
      ? 'test_OieaGB4EnPL7G8gYiVF12rg7N34jdaEZ'
      : 'live_2A0n7fFBS19VyR0a4kmdUQVB9VwPuyAn',
    // publicKey: 'live_2A0n7fFBS19VyR0a4kmdUQVB9VwPuyAn',
    site: isDev ? 'flick-tech-test' : 'flick-tech',
    // site: 'flick-tech',
  },
  googleClientId:
    '364660066462-7ptuu9l75dqtbsompjce1ql5csqofhgn.apps.googleusercontent.com',
  amplitudeId:
    process.env.NODE_ENV === 'production'
      ? '9eaf49286fab3eb7194388494974bfc0'
      : '606642a65d3b24888f7ea9dd381db3ff',
  basePath,
  intercomAppId: isProd ? 'xbx4np5v' : 'gg6214eo',
  next: {
    disableGSP: process.env.DISABLE_GSP,
  },
  isSchedule,
  webBase: (() => {
    if (vercelEnv === 'production' || !isWeb) {
      return 'https://www.flick.tech/app';
    }

    const base = isWindowDefined
      ? window.location.origin
      : `https://${vercelUrl}`;

    return `${base}${basePath}`;
  })(),
  learnBase: (() => {
    const base = isWindowDefined
      ? window.location.origin
      : `https://www.flick.tech`;

    return `${base}/learn`;
  })(),
  getScheduleAPIUrl: (pathname: string) =>
    `https://www.flick.tech/schedule/api/${pathname}`,
  getScheduleRPCAPIUrl: (pathname: string) =>
    `https://www.flick.tech/schedule/api/rpc/${pathname}`,
  vercelEnv,
  vercelUrl,
  fbPixelId: '208984939967955',
  oneSignal: {
    safariWebId: isDev
      ? 'web.onesignal.auto.0b3c1e09-f01e-4f75-a6ff-3f857f927766'
      : 'web.onesignal.auto.009a4322-838f-4341-bf26-c81fd75aa063',
    appId: getOneSignalAppId(),
  },
  flickAppID,
  isReactNative:
    typeof navigator != 'undefined' && navigator.product == 'ReactNative',
};

export const getBaseUrlForApp = (app: FlickAppType) => {
  switch (app) {
    case 'schedule':
      return Constants.scheduleBase;
    case 'learn':
      return Constants.learnBase;
    case 'web':
      return Constants.webBase;
  }

  return Constants.webBase;
};
