import { ThemeContext } from '@emotion/react';
import { useContext } from 'react';

import { Dict, get, StringOrNumber } from '@flick-tech/shared-utils';

import { getComponentStyles } from './component/get-component-style';
import { getComponentDefaults } from './component/get-default-props';
import { useColorMode } from './color-mode';
import { css, StyleObjectOrFn } from './css';
import { useTheme } from './theme-provider';

export function useChakra<T extends Dict = Dict>() {
  const [colorMode, setColorMode] = useColorMode();
  const theme: any = useTheme();
  return { colorMode, setColorMode, theme: theme as T };
}

interface ComponentStyleHookProps {
  size?: string;
  variant?: string;
  colorScheme?: string;
  themeKey: string;
}

export function useComponentStyle(props: ComponentStyleHookProps) {
  const { size, variant, colorScheme, themeKey } = props;
  const theme = useContext(ThemeContext);
  const [colorMode] = useColorMode();
  return getComponentStyles(
    { variant, size, theme, colorScheme, colorMode },
    { themeKey },
  );
}

export function useThemeDefaultProps(themeKey: string) {
  const theme = useContext(ThemeContext);
  return getComponentDefaults(theme, themeKey);
}

export function useCss(styles: StyleObjectOrFn) {
  const theme = useContext(ThemeContext);
  return css(styles)(theme);
}

export function useToken(
  scale: string,
  token: StringOrNumber,
  fallback?: StringOrNumber,
) {
  const theme = useTheme();
  const path = `${scale}.${token}`;
  return get(theme, path, fallback ?? token);
}
