import { useCallback, useMemo } from 'react';
import * as React from 'react';
import {
  toast as hotToast,
  ToastOptions as HotToastOptions,
} from 'react-hot-toast';

import { Logger } from '@flick-tech/logger';
import { merge } from '@flick-tech/shared-utils';
import { chakra } from '@flick-tech/theme-new';

import {
  Alert,
  ALERT_STATUSES,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '../alert';
import { CloseButton } from '../close-button';

import { ToastId, ToastOptions } from './Toast.types';

export type UseToastOptions = HotToastOptions & {
  /**
   * The delay before the toast hides (in milliseconds)
   * If set to `Infinity`, toast will never dismiss.
   *
   * @default 5000 ( = 5000ms )
   */
  duration?: HotToastOptions['duration'];
  /**
   * The title of the toast
   */
  title: string;
  /**
   * The description of the toast
   */
  description?: string;
  /**
   * If `true`, toast will show a close button
   */
  isClosable?: boolean;
  /**
   * The alert component `variant` to use
   */
  variant?: string;
  /**
   * The status of the toast.
   */
  status?: keyof typeof ALERT_STATUSES;
  /**
   * The `id` of the toast.
   *
   * Mostly used when you need to prevent duplicate.
   * By default, we generate a unique `id` for each toast
   */
  id?: ToastId;
};

interface ToastProps {
  status?: UseToastOptions['status'];
  variant?: UseToastOptions['variant'];
  title: UseToastOptions['title'];
  description?: UseToastOptions['description'];
  id: string;
  isClosable?: boolean;
}

const Toast = (props: ToastProps) => {
  const { status, variant, id, title, isClosable = true, description } = props;

  return (
    <Alert
      status={status}
      variant={variant}
      id={id}
      textAlign="left"
      borderRadius="md"
      alignItems="start"
      m={0}
      boxShadow="0 2px 12px 4px rgb(0 0 0 / 10%)"
      paddingRight={10}
    >
      <AlertIcon />
      <chakra.div flex="1" pr={2}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {description && (
          <AlertDescription marginTop="px" lineHeight="short">
            {description}
          </AlertDescription>
        )}
      </chakra.div>
      {isClosable && (
        <CloseButton
          size="md"
          onClick={() => hotToast.dismiss(id.toString())}
          position="absolute"
          right="4px"
          top="4px"
        />
      )}
    </Alert>
  );
};

const defaults = {
  duration: 5000,
  variant: 'solid',
  status: 'info',
} as const;

const formatMessage = (message: string) =>
  typeof message === 'string'
    ? message.replace('GraphQL error:', '').replace('Network error:', '')
    : message;

export const makeToast = (options: UseToastOptions) => {
  const opts: UseToastOptions = merge(defaults, options);

  if (opts.description) {
    opts.description = formatMessage(opts.description);
  }

  if (opts.title) {
    opts.title = formatMessage(opts.title);
  }

  return hotToast((t) => <Toast id={t.id} {...{ ...opts }} />, opts);
};

type UseToastReturn = typeof makeToast & {
  close: typeof hotToast.dismiss;
};

export function useToast(): UseToastReturn {
  const toaster = makeToast as UseToastReturn;

  toaster.close = hotToast.dismiss;

  return toaster;
}

export function useErrorToast<TError extends Error>() {
  const toast = useToast();
  const onError = useCallback(
    (error: TError) => {
      Logger.error(error);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
      });
    },
    [toast],
  );

  return onError;
}
