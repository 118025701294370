import * as React from 'react';

import { __DEV__ } from '@flick-tech/shared-utils';

import { IconNameOrElement, IconOrElement } from '../icon';

import { Button, ButtonProps } from './Button';

export type IconButtonProps = Omit<
  ButtonProps,
  'leftIcon' | 'isFullWidth' | 'rightIcon' | 'loadingText'
> & {
  icon?: IconNameOrElement;
  isRound?: boolean;
  'aria-label': string;
};

const md = {
  size: '37px',
  fontSize: '20px',
};

const getSize = (size: IconButtonProps['size'] = 'md') =>
  ({
    lg: {
      size: '41px',
      fontSize: '22px',
    },
    md,
    sm: {
      size: '30px',
      fontSize: '18px',
    },
    xs: {
      size: '26px',
      fontSize: '16px',
    },
  }[size] ?? md);

export const IconButton = React.forwardRef(
  (props: IconButtonProps, ref: React.Ref<any>) => {
    const { icon, children, isRound, 'aria-label': ariaLabel, ...rest } = props;

    /**
     * Passing the icon as prop or children should work
     */
    const btnIcon = icon ? <IconOrElement icon={icon} /> : children;

    const a11yProps = {
      'aria-hidden': true,
      focusable: false,
    };

    const { size, fontSize } = getSize(rest.size);

    return (
      <Button
        p={0}
        borderRadius={isRound ? 'full' : 'md'}
        ref={ref}
        aria-label={ariaLabel}
        fontSize={fontSize}
        w={size}
        h={size}
        disabledTextOverflow
        isIconButton
        {...rest}
      >
        {React.isValidElement(btnIcon)
          ? React.cloneElement(btnIcon, a11yProps)
          : null}
      </Button>
    );
  },
);

if (__DEV__) {
  IconButton.displayName = 'IconButton';
}
