import { forwardRef, Ref } from 'react';
import * as React from 'react';
import ReactAutosuggest from 'react-autosuggest';

import { Box, BoxProps, TextProps } from '../../../layout';

export interface SuggestionsContainerFooterProps extends TextProps {}
export function SuggestionsContainerFooter({
  children = 'Press Enter to search',
  ...rest
}: SuggestionsContainerFooterProps) {
  return (
    <Box
      as="footer"
      borderTop="1px solid"
      borderTopColor="gray.100"
      pt={2}
      mt={2}
      fontSize="md"
      {...rest}
    >
      {children}
    </Box>
  );
}

export interface SuggestionsContainerContent extends BoxProps {}
export function SuggestionsContainerContent(
  props: SuggestionsContainerContent,
) {
  return <Box overflow="auto" maxH="270px" {...props} />;
}

export interface SuggestionsContainerBoxProps extends BoxProps {
  children: React.ReactNode;
}
export const SuggestionsContainerBox = forwardRef(
  function SuggestionsContainerBox(
    props: SuggestionsContainerBoxProps,
    ref: Ref<HTMLDivElement>,
  ) {
    return (
      <Box
        borderRadius="md"
        w="100%"
        position="absolute"
        zIndex={9}
        top="51px"
        overflow="hidden"
        bg="white"
        boxShadow="0 0 0 1px rgba(136, 152, 170, 0.1), 0 15px 35px 0 rgba(49, 49, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important"
        p="2"
        {...props}
        ref={ref || props.ref}
      />
    );
  },
);

interface SuggestionsContainerProps
  extends ReactAutosuggest.RenderSuggestionsContainerParams {}
export function SuggestionsContainer({
  containerProps,
  children,
}: SuggestionsContainerProps) {
  return (
    <SuggestionsContainerBox {...containerProps}>
      <SuggestionsContainerContent>{children}</SuggestionsContainerContent>
      <SuggestionsContainerFooter />
    </SuggestionsContainerBox>
  );
}
