import { StringKey } from '@flick-tech/shared-types';

export interface FillGradientConfig<T extends object> {
  key: StringKey<T>;
  id: string;
  color: string;
  ratio: number;
}

export interface AreaFillGradientsProps {
  gradients: FillGradientConfig<any>[];
}
export function AreaFillGradients({ gradients }: AreaFillGradientsProps) {
  return gradients.length > 0 ? (
    <svg style={{ height: 0, position: 'absolute' }}>
      <defs>
        {gradients.map(({ color, id, ratio }, i) => {
          return (
            id && (
              <linearGradient
                key={id}
                id={id}
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor={color} />
                <stop offset="3%" stopColor={color} />
                <stop
                  offset={`${Number.isNaN(ratio) ? '100%' : ratio * 100}%`}
                  stopColor={color}
                  stopOpacity={0}
                />
                <stop offset="100%" stopColor={color} stopOpacity={0} />
              </linearGradient>
            )
          );
        })}
      </defs>
    </svg>
  ) : null;
}
