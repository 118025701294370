import { PropsOf } from '@flick-tech/shared-types';
import { chakra } from '@flick-tech/theme-new';

import { Flex, FlexProps } from '../layout';

function WhiteCircle(props: FlexProps) {
  return (
    <Flex
      borderRadius="full"
      bg="white"
      margin="0 auto"
      boxShadow="0px 2px 6px rgba(0, 0, 0, 0.06)"
      {...props}
    />
  );
}

function FacebookSvgLogo(props: PropsOf<typeof chakra['svg']>) {
  return (
    <chakra.svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.375 15.8967C5.1875 15.3342 2.75 12.5592 2.75 9.22168C2.75 5.50918 5.7875 2.47168 9.5 2.47168C13.2125 2.47168 16.25 5.50918 16.25 9.22168C16.25 12.5592 13.8125 15.3342 10.625 15.8967L10.25 15.5967H8.75L8.375 15.8967Z"
        fill="url(#paint0_linear_1705_29215)"
      />
      <path
        d="M12.125 11.0967L12.425 9.22168H10.625V7.90918C10.625 7.38418 10.8125 6.97168 11.6375 6.97168H12.5V5.24668C12.0125 5.17168 11.4875 5.09668 11 5.09668C9.4625 5.09668 8.375 6.03418 8.375 7.72168V9.22168H6.6875V11.0967H8.375V15.8592C8.75 15.9342 9.125 15.9717 9.5 15.9717C9.875 15.9717 10.25 15.9342 10.625 15.8592V11.0967H12.125Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1705_29215"
          x1="9.5"
          y1="15.5044"
          x2="9.5"
          y2="2.47168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0062E0" />
          <stop offset="1" stopColor="#19AFFF" />
        </linearGradient>
      </defs>
    </chakra.svg>
  );
}

export function FBAvatarBadge({ size }: { size: 24 | 18 }) {
  // Chakra will index into the theme for these values if we dont specify px
  const circleSizePx = `${size}px`;
  const iconSizePx = `${size}px`;

  return (
    <WhiteCircle
      position="absolute"
      align="center"
      justify="center"
      left="50%"
      right="0"
      top="50%"
      bottom="0"
      w={circleSizePx}
      h={circleSizePx}
    >
      <FacebookSvgLogo w={iconSizePx} h={iconSizePx} />
    </WhiteCircle>
  );
}
