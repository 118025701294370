import { opacity } from '../../color';

import { mode, Props } from './utils';

function getFocusStyles(props: Props) {
  const { colorScheme: c, theme, variant } = props;

  const lighterOutline = ['ghost', 'link'].includes(variant!);
  const themeColor = theme.colors?.[c][lighterOutline ? 200 : 300];

  return {
    boxShadow: `0 0 0 3px ${themeColor}`,
  };
}

const grayGhostStyle = (props: Props) => {
  const hoverStyles = {
    bg: mode(`gray.100`, `whiteAlpha.200`)(props),
  };

  return {
    bg: 'transparent',
    color: mode(`gray.500`, `whiteAlpha.900`)(props),
    _hover: hoverStyles,
    _focusVisible: hoverStyles,
    _active: {
      bg: mode(`gray.200`, `whiteAlpha.300`)(props),
    },
  };
};

const grayBlackStyle = (props: Props) => ({
  bg: 'transparent',
  color: 'blackAlpha.700',
  _hover: {
    bg: 'blackAlpha.50',
  },
  _active: {
    bg: 'blackAlpha.100',
  },
});

////////////////////////////////////////////////////////////

function getGhostStyle(props: Props) {
  const { colorScheme: c, theme: t } = props;
  if (c === 'gray') return grayGhostStyle(props);
  if (c === 'black') return grayBlackStyle(props);

  const darkHover = opacity(`${c}.200`, 0.12)(t);
  const darkActive = opacity(`${c}.200`, 0.24)(t);

  const hoverStyles = {
    bg: mode(`${c}.50`, darkHover)(props),
  };

  return {
    color: mode(`${c}.500`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: hoverStyles,
    _active: {
      bg: mode(`${c}.100`, darkActive)(props),
    },
    _focusVisible: hoverStyles,
  };
}
////////////////////////////////////////////////////////////

function getLightStyle(props: Props) {
  const { colorScheme: c, theme: t } = props;

  const bgBase = `${c}.500`;
  const hoverBg = mode(`${c}.200`, opacity(bgBase, 0.1)(t))(props);

  const color =
    c === 'brand' ? 'brand.500' : mode(`${c}.600`, `${c}.200`)(props);

  return {
    color,
    bg: mode(`${c}.100`, opacity(bgBase, 0.15)(t))(props),
    _hover: {
      bg: hoverBg,
    },
    _active: {
      bg: hoverBg,
    },
    _focus: {},
    _focusVisible: getFocusStyles(props),
  };
}

////////////////////////////////////////////////////////////

const outlineBorderWidth = '1px';

const getBorderColor = (color: string) => ({
  borderTopColor: color,
  borderBottomColor: color,
  borderLeftColor: color,
  borderRightColor: color,
});

function getOutlineGrayStyle() {
  return {
    bg: 'transparent',
    border: `${outlineBorderWidth} solid`,
    // If each edge color is not specified the border-color gets overrided
    ...getBorderColor('hsl(0, 0%, 92%)'),
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.07)',
    color: 'gray.500',
    _hover: {
      bg: 'gray.10',
      ...getBorderColor('hsl(0, 0%, 89%)'),
    },
    _active: {
      bg: 'gray.10',
      ...getBorderColor('hsl(0, 0%, 89%)'),
    },
    _focus: {
      bg: 'gray.50',
      ...getBorderColor('hsl(0, 0%, 85%)'),
    },
  };
}

function getOutlineStyle(props: Props) {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    return getOutlineGrayStyle();
  }

  return {
    border: `${outlineBorderWidth} solid`,
    borderColor: 'currentColor',
    ...getGhostStyle(props),
  };
}

function getSolidWhiteStyle(props: Props) {
  const hoverStyles = {
    bg: 'bg.lighter',
    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.06))',
  };

  return {
    bg: 'white',
    color: '#5C5C5C',
    border: '1px solid #EBEBEB',
    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.06))',
    _hover: hoverStyles,
    _focusVisible: hoverStyles,
    _active: {
      color: 'brand.500',
    },
    _focus: {},
  };
}

function getSolidBlackStyle(props: Props) {
  return {
    bg: 'blackAlpha.900',
    color: 'white',
    _hover: { bg: 'blackAlpha.800' },
    _active: { bg: 'blackAlpha.600' },
    _focus: getFocusStyles(props),
  };
}

function getSolidStyle(props: Props) {
  const { colorScheme: c } = props;

  if (c === 'white') return getSolidWhiteStyle(props);
  if (c === 'black') return getSolidBlackStyle(props);

  const hoverStyles = { bg: mode(`${c}.600`, `${c}.300`)(props) };

  return {
    bg: mode(`${c}.500`, `${c}.200`)(props),
    color: mode(`white`, `gray.800`)(props),
    _hover: hoverStyles,
    _focusVisible: hoverStyles,
    _active: { bg: mode(`${c}.700`, `${c}.400`)(props) },
    _focus: {},
  };
}

////////////////////////////////////////////////////////////

function getSbuduedStyle(props: Props) {
  const { colorScheme: c } = props;

  const isGray = c === 'gray';

  return {
    bg: mode(`gray.50`, `gray.800`)(props),
    color: mode(`gray.500`, `white`)(props),
    _hover: {
      bg: mode(`${c}.100`, `${c}.900`)(props),
      color: mode(isGray ? 'gray.600' : `${c}.500`, `${c}.200`)(props),
    },
    _active: { bg: mode(`${c}.100`, `${c}.700`)(props) },
    _focus: getFocusStyles(props),
  };
}

////////////////////////////////////////////////////////////

function getTextStyle(props: Props) {
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    color: mode(`gray.900`, `gray.50`)(props),
    _active: {
      color: mode(`gray.700`, `gray.200`)(props),
    },
    _focus: getFocusStyles(props),
  };
}
////////////////////////////////////////////////////////////

function getLinkStyle(props: Props) {
  const { colorScheme: c } = props;

  const borderSize =
    {
      sm: 1,
      md: 2,
    }[props.size!] ?? 2;

  return {
    padding: 0,
    height: 'auto',
    bg: 'transparent',
    borderRadius: 0,
    // Fixes loading state with no text
    minHeight: '1.25rem',
    lineHeight: 'normal',
    color: mode(`${c}.500`, `${c}.200`)(props),
    borderBottom: `${borderSize}px solid`,
    borderBottomColor: 'transparent',
    _hover: {
      bg: 'transparent',
      textDecoration: 'none',
      borderBottomColor: mode(`${c}.500`, `${c}.200`)(props),
    },
    _active: {
      bg: 'transparent',
      color: mode(`${c}.500`, `${c}.500`)(props),
      borderBottomColor: mode(`${c}.500`, `${c}.500`)(props),
    },
    _focus: getFocusStyles(props),
  };
}

////////////////////////////////////////////////////////////

const sizes = {
  lg: {
    minW: '41px',
    fontSize: 'md',
    paddingX: '16px',
    paddingY: '10px',
    borderRadius: '7px',
  },
  md: {
    minW: '37px',
    paddingY: '8px',
    fontSize: 'md',
    paddingX: '14px',
    lineHeight: 1.3,
    borderRadius: '6px',
  },
  sm: {
    minW: '30px',
    paddingY: '6px',
    minWidth: 8,
    fontSize: 'sm',
    paddingX: '12px',
    lineHeight: 1.3,
    borderRadius: '5px',
  },
  xs: {
    minW: '26px',
    fontSize: 'xs',
    paddingX: '10px',
    borderRadius: '4px',
  },
};

////////////////////////////////////////////////////////////

const unstyled = {
  bg: 'none',
  border: 0,
  color: 'inherit',
  display: 'inline',
  font: 'inherit',
  lineHeight: 'inherit',
  margin: 0,
  padding: 0,
};

////////////////////////////////////////////////////////////
// const Button: ComponentTheme = {
const ButtonTheme = {
  defaultProps: {
    variant: 'solid',
    size: 'sm',
    colorScheme: 'brand',
  },
  baseStyle: {
    // So all buttons take up the same amount of space
    borderWidth: outlineBorderWidth,
    borderColor: 'transparent',
    lineHeight: '1.2',
    borderRadius: 'md',
    fontWeight: 'medium',
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none',
      transform: 'none !important',
    },
  },
  sizes,
  variants: {
    unstyled,
    solid: getSolidStyle,
    subdued: getSbuduedStyle,
    ghost: getGhostStyle,
    light: getLightStyle,
    text: getTextStyle,
    link: getLinkStyle,
    outline: getOutlineStyle,
  },
};

export default ButtonTheme;
