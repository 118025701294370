import { Property } from 'csstype';

import { Config, Length, Prop } from '../utils';

/**
 * The parser configuration for common outline properties
 */
export const outlineConfig: Config = {
  outline: true,
  outlineOffset: true,
  outlineColor: {
    property: 'outlineColor',
    scale: 'colors',
  },
};

export interface OutlineProps {
  /**
   * The CSS `outline` property
   */
  outline?: Prop<Property.Outline<Length>>;
  /**
   * The CSS `outline-offset` property
   */
  outlineOffset?: Prop<Property.OutlineOffset<Length>>;
  /**
   * The CSS `outline-color` property
   */
  outlineColor?: Prop<Property.OutlineColor>;
}
