import { createElement, FunctionComponent, ReactElement } from 'react';

import { SystemProps } from '@flick-tech/theme-new';

import { materialIcons } from './MaterialIcon/materialIcons';
import { ValidMaterialIcons } from './MaterialIcon';

export type IconName = ValidMaterialIcons;

export type IconNameOrElement = IconName | ReactElement;

export interface IconOrNodeProps extends SystemProps {
  icon: IconNameOrElement;
}

export const IconOrElement: FunctionComponent<IconOrNodeProps> = (props) => {
  if (typeof props.icon !== 'string') {
    return props.icon;
  }

  return <Icon {...(props as IconProps)} />;
};

export interface IconProps extends SystemProps {
  icon: IconName;
  as?: any;
  onClick?: (e?: any) => void;
}

export const Icon = ({ icon, ...props }: IconProps) => {
  let resolved;

  try {
    resolved = materialIcons[icon];
  } catch (error) {
    console.log(error);
  }

  if (!resolved) {
    console.error(new Error(`Could not find icon ${icon}`));
    // @ts-ignore
    return createElement('span');
  }

  return createElement(resolved, {
    ...props,
  });
};
