import BadgeTheme from './BadgeTheme';
import { ComponentTheme } from './utils';

const CodeTheme: ComponentTheme = {
  defaultProps: BadgeTheme.defaultProps,
  baseStyle: {
    display: 'inline-block',
    fontFamily: 'mono',
    fontSize: 'sm',
    paddingX: '0.2em',
    borderRadius: 'sm',
  },
  variants: BadgeTheme.variants,
};

export default CodeTheme;
