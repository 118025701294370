import isMobileJs from 'ismobilejs';
import queryString from 'query-string';
import { createElement } from 'react';

import { ROUTES } from './constants';
import { IPlanId } from '@flick-tech/shared-types';

export const isRunningOnServerSide = () => typeof window === 'undefined';

export const wait = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

export const reportsPathName = 'reports';
export const homeRouteName = ROUTES.Home;

type Empty = null | undefined | '';

type StripEmpties<T extends object> = {
  [P in keyof T as T[P] extends Empty ? never : P]: T[P];
};

export const stripEmpties = <T extends object>(obj: T): StripEmpties<T> => {
  const clone = { ...obj };

  Object.entries(clone).forEach(([key, value]) => {
    if (value === undefined || value === null || value === '') {
      delete clone[key];
    }
  });

  return clone as StripEmpties<T>;
};

export const extractReferrer = (
  search: string,
): { referrer?: string; referrerUrl?: string } => {
  const values: { [key: string]: string | undefined } = queryString.parse(
    search,
  ) as any;

  if (!values) {
    return {};
  }

  return {
    referrer: values.referer || values.ref || values.referrer,
    referrerUrl: document.referrer,
  };
};

export const validateInputs = ({
  limit,
  mediaCount,
  averageLikes,
}: {
  limit: number;
  mediaCount: {
    min: number | string;
    max: number | string;
  };
  averageLikes: {
    min: number | string;
    max: number | string;
  };
}): string => {
  let errorMessage = '';

  const minAveLikes = averageLikes.min
    ? parseInt(averageLikes.min.toString(), 10)
    : null;
  const maxAveLikes = averageLikes.max
    ? parseInt(averageLikes.max.toString(), 10)
    : null;
  const minMediaCount = mediaCount.min
    ? parseInt(mediaCount.min.toString(), 10)
    : null;
  const maxMediaCount = mediaCount.max
    ? parseInt(mediaCount.max.toString(), 10)
    : null;

  if (isNaN(parseInt(limit.toString(), 10))) {
    errorMessage = 'Limit must be a number';
  } else if (parseInt(limit.toString(), 10) < 5) {
    errorMessage = 'Limit must be at least 5';
  }
  if (maxMediaCount && maxMediaCount < 5) {
    errorMessage = 'Max number of posts must be at least 5000';
  } else if (maxAveLikes && maxAveLikes < 5) {
    errorMessage = 'Max average likes must be at least 5';
  } else if (maxAveLikes && minAveLikes && maxAveLikes <= minAveLikes) {
    errorMessage =
      'Minimum average likes must be less than maximum average likes.';
  } else if (maxMediaCount && minMediaCount && maxMediaCount <= minMediaCount) {
    errorMessage = 'Minimum posts made must be less than maximum posts made.';
  }

  if (limit >= 100) {
    errorMessage = 'Please use a limit of 100 or less';
  }

  return errorMessage;
};

/**
 * We assume that all windows narrower than 768 pixels are mobile devices
 * with wrongly detected user agent.
 */
export const MOBILE_WINDOW_SIZE = 768;

export const extractQueryParameters = (
  search: string,
): { [key: string]: string } => {
  const urlParams = new URLSearchParams(search);

  const result = {};

  urlParams.forEach((v, k) => {
    result[k] = v;
  });

  return result;
};

export const isMobile = (userAgent?: string) => {
  if (isRunningOnServerSide()) {
    if (userAgent) {
      return isMobileJs(userAgent).phone;
    }

    return false;
  }

  const windowWidth = window.innerWidth;

  return (
    windowWidth <= MOBILE_WINDOW_SIZE ||
    isMobileJs(userAgent ?? window.navigator.userAgent).phone
  );
};

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export const shuffleArray = (a) => {
  const copy = [...a];
  for (let i = copy.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [copy[i], copy[j]] = [copy[j], copy[i]];
  }

  return copy;
};

export const getChromeVersion = (): number | undefined => {
  if (isRunningOnServerSide() || !window.navigator) return undefined;

  const raw = window.navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : undefined;
};

export function removeDuplicatesBy<T>(arr: T[], by: (item: T) => any) {
  return arr.reduce(
    (unique: T[], item) =>
      unique.filter((oldItem) => by(oldItem) === by(item)).length > 0
        ? unique
        : [...unique, item],
    [],
  );
}

export const computeHoursLeft = (date?: Date) => {
  if (!date) return 0;

  const dateScheduledToRemove = new Date(date);
  dateScheduledToRemove.setDate(dateScheduledToRemove.getDate() + 1);

  const msLeft =
    new Date(dateScheduledToRemove).getTime() - new Date().getTime();

  return msLeft / (60 * 60 * 1000);
};

export function parseAsUTC(date: Date | string) {
  const result = new Date(date);
  // date is parsed with local timezone atm, set it to GMT (UTC+00)
  // TODO: test if other shared/parseAsUTC works correctly across app
  // it seems like there should be (+) rather than (-)
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

export function daysBetween(startDate: Date, endDate: Date) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.ceil(
    (parseAsUTC(endDate).getTime() - parseAsUTC(startDate).getTime()) /
      millisecondsPerDay,
  );
}

export const formatDate = (dateStr) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const date = new Date(dateStr);

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear().toString().substr(-2);

  return `${day} ${monthNames[monthIndex]} ${year}`;
};

export const omitProps =
  (component, ...keys) =>
  (props) => {
    const newProps = { ...props };
    keys.forEach((key) => {
      delete newProps[key];
    });
    return createElement(component, props);
  };

export const isRouteActive = (
  route: string | undefined,
  pathname: string,
): boolean => route != null && pathname.includes(route);

export const stopPropagation = (event) => {
  if (!event) return;

  event.stopPropagation();
};

export const preventPassThrough = (event) => {
  if (!event) return;

  event.preventDefault();
  stopPropagation(event);
};

export const getFirstName = (
  name: string | null | undefined,
): string | undefined => {
  if (!name) return undefined;

  if (name.split(' ').length === 0) return undefined;

  return capitalize((name.split(' ')[0] ?? '').trim());
};

export function capitalize(value: string): string {
  if (!value || typeof value !== 'string') {
    return '';
  }

  return value.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export const getLastName = (
  name: string | null | undefined,
): string | undefined => {
  if (!name) return undefined;

  if (name.split(' ').length === 0) return undefined;

  return capitalize((name.split(' ').slice(-1)[0] ?? '').trim());
};

export const getFirstNamePluralised = (name): undefined | string => {
  const firstName = getFirstName(name);

  if (!firstName) return undefined;

  if (firstName.endsWith('s')) {
    return `${firstName}'`;
  }

  return `${firstName}'s`;
};

export const randomIntFromInterval = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const plural = (text: string) => (value: number) =>
  Math.abs(value) === 1 ? text : `${text}s`;

export function chunkArray<T = any>(myArray: T[], chunkSize: number): T[][] {
  const clone = [...myArray];
  const results: T[][] = [];

  while (clone.length) {
    results.push(clone.splice(0, chunkSize));
  }

  return results;
}

export const dateSort = (dateA: Date, dateB: Date): number => {
  if (!dateA) return 1;
  if (!dateB) return -1;

  if (dateA > dateB) return -1;
  if (dateA < dateB) return 1;

  return 0;
};


const newPlans: IPlanId[] = [
  'SoloYearly',
  'GrowthYearly',
  'ProYearly',
  'AgencyYearly',
  'SoloMonthly',
  'GrowthMonthly',
  'ProMonthly',
  'AgencyMonthly',
];

export const isNewPlan = (planId: IPlanId) => newPlans.includes(planId);
