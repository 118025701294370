import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  EndHandler,
  EnterHandler,
  ExitHandler,
  TransitionProps as TProps,
  TransitionStatus,
} from 'react-transition-group/Transition';

export interface TransitionProps {
  appear?: boolean | undefined;
  in?: boolean;
  addEndListener?: EndHandler<any>;
  onEnter?: EnterHandler<any>;
  onEntering?: EnterHandler<any>;
  onEntered?: EnterHandler<any>;
  onExit?: ExitHandler<any>;
  onExiting?: ExitHandler<any>;
  onExited?: ExitHandler<any>;
  unmountOnExit?: boolean;
  timeout?: TProps['timeout'];
  transition?: string;
  children: (styles: React.CSSProperties) => React.ReactNode;
  styles: TransitionStyles;
}

export type TransitionStyleState =
  | 'init'
  | 'entered'
  | 'entering'
  | 'exiting'
  | 'exited';

export type TransitionStyles = {
  [K in TransitionStyleState]?: React.CSSProperties;
};

export function Transition(props: TransitionProps) {
  const {
    styles,
    in: inProp,
    timeout = 200,
    transition = `all ${timeout}ms ease-in-out`,
    children,
    appear = true,
    ...rest
  } = props;

  const computedStyle = (state: TransitionStatus) => ({
    ...styles.init,
    transition,
    ...styles[state],
  });

  return (
    <CSSTransition
      appear={appear}
      unmountOnExit
      in={inProp}
      timeout={timeout}
      {...rest}
    >
      {(state) => children(computedStyle(state))}
    </CSSTransition>
  );
}
